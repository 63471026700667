import { useTranslation } from "react-i18next";
import { useAsyncCallback } from "react-use-async-callback";
import { ButtonGroup, Col, ListGroupItem, Row, Spinner } from "reactstrap";
import { ButtonAsync } from "reactstrap-buttonasync";
import { ScheduledClassForClassRegistersViewModelQuery } from "../../../api/main/generated/graphql";
import { ClassStage } from "../../../api/main/models/ClassStage";
import { ClassSubStage } from "../../../api/main/models/ClassSubStage";
import { ClassType } from "../../../api/main/models/ClassType";
import { PaymentState } from "../../../api/main/models/constants/PaymentState";
import { AlertOnErrors } from "../../../shared/alertOnErrors";
import { getScheduledClassSummary } from "../../scheduledClass/getScheduledClassSummary";
import { HappyIcon, HappyIconProps } from "../Utilities/HappyIcon";

export interface MoveChildModalItemProps {
    model: ScheduledClassForClassRegistersViewModelQuery['items'][number],
    moveChild: (scheduledClassId: string, options: { endOfTerm: boolean, }) => Promise<void>,

    classStage?: ClassStage | undefined,
    classSubStage?: ClassSubStage | undefined,
    classTypes?: Array<ClassType> | undefined;
}

/**
 * An model in the MoveChild modal.
 * 
 * This is a seperate component for cleaner UI management of executing tasks while we are moving.
 * @param props
 * @returns
 */
export const MoveChildModalItem = (props: MoveChildModalItemProps) => {
    const {
        model,
        moveChild,
        classStage,
        classSubStage,
        classTypes,
    } = props;

    const { t } = useTranslation();

    // Move immediatly (i.e. this term).
    const [moveImmediately, { isExecuting: isMovingImmediately, errors: moveImmediatelyErrors, }] = useAsyncCallback(async () => {
        await moveChild(model.id, { endOfTerm: false });
    }, [moveChild, model]);

    // Move next term
    const [moveEndOfTerm, { isExecuting: isMovingNextTerm, errors: moveEndOfTermErrors, }] = useAsyncCallback(async () => {
        await moveChild(model.id, { endOfTerm: true });
    }, [moveChild, model]);

    // UI
    //
    return (
        <ListGroupItem>
            <AlertOnErrors errors={[moveImmediatelyErrors, moveEndOfTermErrors,]} />
            <Row>
                <Col>
                    {getScheduledClassSummary(model, { classLocation: model.classLocation, classStage: classStage, classSubStage: classSubStage })}
                    <> </>
                    <HappyIcon icon={classTypes?.find(it => it.id === model?.classTypeId)?.name.toLowerCase().split(' ').join('-') as HappyIconProps["icon"]} />
                </Col>
                <Col>
                    {t('moveChildModalItem.register', 'Register')}: {model.scheduledClassChildren.filter(it => it.isOnWaitingList === false).length}
                    {t('moveChildModalItem.unpaid', '; Unpaid')}: {model.scheduledClassChildren.filter(it => it.paymentState !== PaymentState.Paid).length}
                    {t('moveChildModalItem.spaces', '; Spaces')}: {model.maximumClassSize - model.scheduledClassChildren.filter(it => it.isOnWaitingList === false).length} / {model.maximumClassSize}
                    {t('moveChildModalItem.waitingList', '; Waiting list')}: {model.scheduledClassChildren.filter(it => it.isOnWaitingList === true).length}
                </Col>
                <Col xs="auto" style={{ textAlign: 'right' }}>
                    <ButtonGroup>
                        <ButtonAsync color="primary" outline onClick={moveImmediately}
                            isExecuting={isMovingImmediately} executingChildren={<><Spinner size="sm" /><> </>{t('moveChildModalItem.moving', 'Moving...')}</>}
                        >
                            {t('moveChildModalItem.moveImmediately', 'Immediatly')}
                        </ButtonAsync>

                        <ButtonAsync color="primary" outline onClick={moveEndOfTerm}
                            isExecuting={isMovingNextTerm}
                            executingChildren={<><Spinner size="sm" /><> </>{t('moveChildModalItem.moving', 'Moving...')}</>}
                        >
                            {t('moveChildModalItem.moveEndOfTerm', 'End of term')}
                        </ButtonAsync>
                    </ButtonGroup>
                </Col>
            </Row>
        </ListGroupItem>
    );
};
