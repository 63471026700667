import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import { ConditionalFragment } from "react-conditionalfragment";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { useAsyncCallback } from "react-use-async-callback";
import { Button, Col, Form, FormGroup, Input, Label, Row, Spinner } from "reactstrap";
import { ButtonAsync } from "reactstrap-buttonasync";
import { useDeleteStaffAbsenceMutation } from "../../api/main/staffAbsences/useDeleteStaffAbsence";
import { useStaffAbsence } from "../../api/main/staffAbsences/useStaffAbsence";
import { AlertOnErrors } from "../../shared/alertOnErrors";
import { Banner } from "../shared/banner/Banner";
import { FormButtons } from "../shared/formButtons/FormButtons";
import { LoadingIndicator } from "../shared/loadingIndicator/LoadingIndicator";
import { MainContainer } from "../shared/mainContainer/MainContainer";

/**
 * Delete a StaffAbsence: A user is only allowed to delete a StaffAbsence that has the acceptanceState of Submitted.
 */
export const DeleteStaffAbsence = () => {
    const { t } = useTranslation();
    const { id } = useParams<{ id: string; }>();
    const navigate = useNavigate();

    // Load the data
    const {
        data: {
            model
        }, isLoading, errors: loadErrors,
    } = useStaffAbsence(id);

    // Delete the StaffAbsence
    const [remove, { errors: removeErrors }] = useDeleteStaffAbsenceMutation();
    
    // When we submit, delete the StaffAbsence
    const [onRemoveFormSubmit, { isExecuting: isRemoving, errors: removeFormErrors }] = useAsyncCallback(async () => {
        await remove(id ?? '');

        navigate(-1);
    }, [remove, navigate]);

    // Render the UI
    //
    return (
        <>
            <Banner>
                <Row>
                    <Col>
                        <h1>{t('deleteStaffAbsence.heading', 'Are you sure you want to delete this absence request?')}</h1>
                        <h3>{t('common.dateRange', '{{date, DD/MM/YYYY}}', { date: moment(model?.startDate) })} - {t('common.date', '{{date, DD/MM/YYYY}}', { date: moment(model?.endDate) })}</h3>
                    </Col>
                    <ConditionalFragment showIf={isLoading}>
                        <Col xs="auto">
                            <LoadingIndicator size="sm" />
                        </Col>
                    </ConditionalFragment>
                </Row>
            </Banner>

            <MainContainer>
                <AlertOnErrors errors={[loadErrors, removeFormErrors, removeErrors,]} />

                <Form onSubmit={e => { e.preventDefault(); onRemoveFormSubmit(); }}>
                    <FormGroup>
                        <Label htmlFor="name">{t('deleteStaffAbsence.name.label', "Dates")}</Label>
                        <Input name="name" type="text" readOnly value={`${t('common.date', '{{date, DD/MM/YYYY}}', { date: moment(model?.startDate) })} - ${t('common.date', '{{date, DD/MM/YYYY}}', { date: moment(model?.endDate) }) }`} />
                    </FormGroup>

                    <FormButtons>
                        <ButtonAsync color="danger" isExecuting={isRemoving}
                            executingChildren={<> <Spinner size="sm" /> {t('common.deleting', 'Deleting...')} </>}>
                            <FontAwesomeIcon icon="trash" />
                            <> </>
                            {t('common.delete', 'Delete')}
                        </ButtonAsync>
                        <Button type="button" color="primary" outline onClick={e => navigate(- 1)}>
                            {t('common.cancel', 'Cancel')}
                        </Button>
                    </FormButtons>
                </Form>
            </MainContainer>
        </>
    );
}