import { useMemo } from "react";
import { ConditionalFragment } from "react-conditionalfragment";
import { useCurrentUserProfile } from "../../api/main/profiles/useCurrentUserProfile";
import { useStaffQualificationChecksForNavigationAlert } from "../../api/main/staffQualificationChecks/viewModels/useStaffQualificationChecksForNavigationAlert";
import { LoadingIndicator } from "../shared/loadingIndicator/LoadingIndicator";

/**
 * Component that returns a Badge containing a Number representing the number of StaffQualifications for the current user are outstanding.
 * This is being used in the HeaderSubNavigation under Qualifications tab
 */
export const StaffQualificationsOverdueBadgeComponent = () => {

    // Load the CurrentUserProfile
    const {
        data: {
            model
        }, isLoading: _isLoading,
    } = useCurrentUserProfile();

    // Load the data
    const {
        data: {
            items
        }, isLoading: isLoadingData,
    } = useStaffQualificationChecksForNavigationAlert(model?.id);
    const isLoading = _isLoading || isLoadingData

    // Function to check whether a StaffQualification is due for review within the next 30 days
    const isDueWithinThirtyDays = (reviewDate: string) => {
        // First we can get the number of 
        const _millisecondsInADay = 24 * 3600 * 1000;

        // We can then calculate the difference in Milliseconds and then divide by the number of Milliseconds in a day to get the number of days
        const differenceInMilliseconds = new Date(reviewDate).getTime() - new Date().getTime();
        const differenceInDays = differenceInMilliseconds / _millisecondsInADay;

        // Floor the return to work with whole numbers
        return Math.floor(differenceInDays);
    };

    // Runs isDueWithinThirtyDays for each StaffQualificationCheck and returns the number that are overdue
    const checksForReview = useMemo(() => {
        const reviewDays = items?.map(item => {
            return isDueWithinThirtyDays(item.reviewDate);

        });

        return reviewDays?.filter(it => it <= 0).length;
    }, [items]);

    // Render the UI
    //
    return (
        <>
            <ConditionalFragment showIf={isLoading}>
                <LoadingIndicator />
            </ConditionalFragment>

            <div className="badge rounded-pill bg-danger" style={{ marginLeft: '2px' }}>
                {checksForReview}
            </div>
        </>
    )
}