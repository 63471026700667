import { useMutation } from "@apollo/react-hooks";
import { useAsyncCallback } from 'react-use-async-callback';
import gql from "graphql-tag";
import { AsyncActionStatus } from '../../../shared/abstractStore';
import { UpdateQueryMessageMutation, UpdateQueryMessageMutationVariables, QueryMessageUpdateInput } from '../generated/graphql';
import { queryMessageFieldsFragment } from '../models/QueryMessage';

/**
 * Get a callback to update a QueryMessage in the api.
 */
export function useUpdateQueryMessageMutation(): [(id: string, model: QueryMessageUpdateInput) => Promise<void>, AsyncActionStatus] {
    const [mutationAction, { error }] = useMutation<UpdateQueryMessageMutation, UpdateQueryMessageMutationVariables>(
        gql`
            mutation updateQueryMessageMutation ($model: QueryMessageUpdateInput!) {
                updateQueryMessage(model: $model) {
                    ...queryMessageFields
                }
            }

            ${queryMessageFieldsFragment}
        `
    );

    // Wrap the mutation in a standard callback.
    const [action, { isExecuting, errors: actionErrors }] = useAsyncCallback(async (id: string, model: QueryMessageUpdateInput) => {
        const result = await mutationAction({ variables: { model: { ...model, id: id } } });
        if (result.errors && result.errors.length) {
            throw result.errors;
        }
    }, [mutationAction]);

    return [action, { isExecuting: isExecuting, errors: error ? error.message : actionErrors }];
}
