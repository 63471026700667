import { Profile } from "../../api/main/models/Profile";
import { Member } from "../../api/main/models/Member";
import { useTranslation } from "react-i18next";
import { useMembersListViewModel } from "../../api/main/profiles/viewModels/useMembersListViewModel";
import React, { useState } from "react";
import { useToggleStateArray } from "use-toggle-state";
import { usePreferredListViewMode } from "../../globalState/preferredListViewMode/usePreferredListViewMode";
import { useNavigate } from "react-router";
import { useReplaceSearchParamsEffect, useSearchParams } from '../../shared/useURLSearchParams';
import { Banner } from "../shared/banner/Banner";
import { StickyToolbar } from "../shared/stickyToolbar/StickyToolbar";
import { Button, ButtonDropdown, ButtonGroup, CardSubtitle, CardTitle, Col, DropdownItem, DropdownMenu, DropdownToggle, Row } from "reactstrap";
import { ConditionalFragment } from "react-conditionalfragment";
import { LoadingIndicator } from "../shared/loadingIndicator/LoadingIndicator";
import { SearchInput } from "../shared/searchInput/SearchInput";
import { LinkContainer } from "react-router-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CardsOrTableToggle } from "../shared/cardsOrTable/CardsOrTableToggle";
import { MainContainer } from "../shared/mainContainer/MainContainer";
import { AlertOnErrors } from "../../shared/alertOnErrors";
import { CardsOrTable } from "../shared/cardsOrTable/CardsOrTable";
import { NoResultsFound } from "../shared/noResultsFound/NoResultsFound";
import { Waypoint } from "react-waypoint";
import { useMembersListSupportingData } from "../../api/main/profiles/viewModels/useMembersListSupportingData";

export interface ProfileWithMember extends Profile {
    member?: Member;
}

export interface MembersListProps {
    title?: string;
}

/**
 * List of all Members
 */
export const MembersList = (props: MembersListProps) => {
    const { title } = props;
    const { t } = useTranslation();
    const { search: searchParam } = useSearchParams();
    const [search, setSearch] = useState<string>(searchParam ?? '');

    // Load data
    const {
        data: {
            items: _allItems,
            children
        }, isLoading: _isLoading, errors: loadingErrors, fetchMore, hasMore
    } = useMembersListViewModel({ pageSize: undefined });

    const {
        data: {
            profiles,
        }, isLoading: isLoadingSupportingData, errors: supportingDataLoadErrors
    } = useMembersListSupportingData();
    const isLoading = _isLoading || isLoadingSupportingData;

    // Combine all the loaded data so each item includes its related details
    const allItems = React.useMemo(() => _allItems?.map(item => {
        //const member = members?.find(it => it.id === item.id);
        const profile = profiles?.find(it => it.id === item.id);
        const membersChildren = children?.filter(it => it.memberId === item.id);

        return {
            ...item,

            profile,
            children: membersChildren,
        };
    }), [_allItems, profiles, children]);

    // Use the preferred view mode for lists.
    const [viewMode] = usePreferredListViewMode();
    const [isMenuOpen, toggleMenuOpen] = useToggleStateArray();
    const navigate = useNavigate();

    // Filter by the member's search, client side, so it can work when offline as well as online
    const items = React.useMemo(() => {
        let ret = (allItems || []);

        let lowerSearch = search.toLocaleLowerCase();

        // Filter the items being displayed
        ret = ret.filter(item =>
            (item?.profile?.firstName.toLocaleLowerCase() ?? '').indexOf(lowerSearch) >= 0
            || (item?.profile?.lastName.toLocaleLowerCase() ?? '').indexOf(lowerSearch) >= 0
            || (item?.profile?.user.email.toLocaleLowerCase() ?? '').indexOf(lowerSearch) >= 0
            || (item?.profile?.primaryPhone.toLocaleLowerCase() ?? '').indexOf(lowerSearch) >= 0
            || item.children.find(child =>
                child.firstName.toLocaleLowerCase().indexOf(lowerSearch) >= 0
                || child.lastName.toLocaleLowerCase().indexOf(lowerSearch) >= 0
            )
        );

        return ret;

    }, [allItems, search]);

    useReplaceSearchParamsEffect({ search });

    // Render the UI
    //
    return (
        <>
            <Banner fluid>
                <StickyToolbar>
                    <Row>
                        <Col xs={12} md="auto">
                            <h1>
                                {title ?? t('membersList.title', 'Mama and papa bears')}
                            </h1>
                        </Col>
                        <ConditionalFragment showIf={isLoading}>
                            <Col xs="auto">
                                <LoadingIndicator size="sm" />
                            </Col>
                        </ConditionalFragment>
                    </Row>
                    <Row>
                        <Col>
                            <SearchInput value={search} onChange={e => setSearch(e.currentTarget.value)} />
                        </Col>
                        <Col xs="auto">
                            <Row>
                                <ButtonGroup>
                                    <LinkContainer to={'add'}>
                                        <Button color="primary">
                                            <FontAwesomeIcon icon="plus" /><> {t('memberList.add', 'Add')}</>
                                        </Button>
                                    </LinkContainer>
                                </ButtonGroup>
                            </Row>
                        </Col>
                        <Col xs={12} md="auto">
                            <CardsOrTableToggle />
                        </Col>
                    </Row>
                </StickyToolbar>
            </Banner>

            <MainContainer fluid>
                <AlertOnErrors errors={[
                    loadingErrors, supportingDataLoadErrors
                ]} />

                <CardsOrTable
                    viewMode={viewMode}
                    items={items}
                    tableHeadings={[
                        t('memberList.firstName', 'First name'),
                        t('memberList.lastName', 'Last name'),
                        t('memberList.email', 'Email'),
                        t('memberList.primaryPhone', 'Phone'),
                        t('memberList.receivesStaffDiscount', 'Receives staff discount')
                    ]}
                    columns={[
                        (item, view) => view === 'cards' ? (<CardTitle tag="h5">{item?.profile?.firstName + " " + item?.profile?.lastName}</CardTitle>) : null,
                        (item, view) => view === 'cards' ? null : item?.profile?.firstName,
                        (item, view) => view === 'cards' ? null : item?.profile?.lastName,
                        (item, view) =>
                            view === 'cards' ? (<CardSubtitle tag="h6" className="text-muted">{item?.profile?.user?.email ?? ''}</CardSubtitle>)
                                : item?.profile?.user?.email ?? '',
                        (item, view) =>
                            view === 'cards' ? (<CardSubtitle tag="h6" className="text-muted">{item?.profile?.primaryPhone ?? ''}</CardSubtitle>)
                                : item?.profile?.primaryPhone ?? '',
                        (item, view) =>
                            view === 'cards' ? null : item?.applyStaffDiscount ? t('common.yes', 'Yes') : t('common.no', 'No')
                    ]}
                    buttons={(item) => (
                        <ButtonGroup>
                            <LinkContainer to={`edit/${item.id}`}>
                                <Button color="primary">
                                    <FontAwesomeIcon icon="edit" />
                                    <> {t('common.edit', 'Edit')}</>
                                </Button>
                            </LinkContainer>
                            <ButtonDropdown isOpen={isMenuOpen(item.id)} toggle={() => toggleMenuOpen(item.id)}>
                                <DropdownToggle color="primary" caret>
                                    <span className="visually-hidden">{t('common.menuDropdown', 'More')}</span>
                                </DropdownToggle>
                                <DropdownMenu end>
                                    <LinkContainer to={`delete/${item.id}`}>
                                        <DropdownItem className="text-danger">
                                            <FontAwesomeIcon icon="trash" />
                                            <> {t('common.delete', 'Delete')}</>
                                        </DropdownItem>
                                    </LinkContainer>
                                </DropdownMenu>
                            </ButtonDropdown>
                        </ButtonGroup>
                    )}
                    onItemClick={item => navigate(`edit/${item.id}`)}
                />
                <ConditionalFragment showIf={isLoading && !items?.length}>
                    <LoadingIndicator fullWidth />
                </ConditionalFragment>
                <ConditionalFragment showIf={!isLoading && !items?.length}>

                    <NoResultsFound search={search} />

                </ConditionalFragment>
                <ConditionalFragment showIf={!isLoading && hasMore()}>

                    <Waypoint key={items?.length ?? 0} onEnter={fetchMore} />
                    <LoadingIndicator fullWidth />

                </ConditionalFragment>
            </MainContainer>
        </>
    );
};