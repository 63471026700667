import { useMutation } from "@apollo/client";
import gql from "graphql-tag";
import { AsyncActionStatus } from "../../../shared/abstractStore";
import { UpdateQueryMessagesMutation, UpdateQueryMessagesMutationVariables } from "../generated/graphql";
import { queryMessageFieldsFragment } from "../models/QueryMessage";
import { useAsyncCallback } from "react-use-async-callback";

/**
 * Get a callback to update QueryMessages in the api.
 * @param options
 */
export function useUpdateQueryMessagesMutation():[(ids: Array<string> | undefined | null, staffId: string) => Promise<void>, AsyncActionStatus] {
    const [mutationAction, { error }] = useMutation<UpdateQueryMessagesMutation, UpdateQueryMessagesMutationVariables>(
        gql`
        mutation updateQueryMessagesMutation ($ids: [ID!], $staffId: ID!) {
                updateQueryMessages(ids: $ids, staffId: $staffId) {
                    ...queryMessageFields
                }
            }

            ${queryMessageFieldsFragment}
        `
    );

    // Wrap the mutation in a standard callback.
    const [action, { isExecuting, errors: actionErrors }] = useAsyncCallback(async (ids: Array<string> | undefined | null, staffId: string) => {
        const result = await mutationAction({ variables: {  ids: ids, staffId: staffId } });
        if (result.errors && result.errors.length) {
            throw result.errors;
        }
    }, [mutationAction]);

    return [action, { isExecuting: isExecuting, errors: error ? error.message : actionErrors }];
};