import * as React from 'react';
import { Button, Row, Col, ButtonGroup, ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem, CardTitle, CardSubtitle } from 'reactstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { AlertOnErrors } from '../../shared/alertOnErrors';
import { LoadingIndicator } from '../shared/loadingIndicator/LoadingIndicator';
import { Waypoint } from 'react-waypoint';
import { useReplaceSearchParamsEffect, useSearchParams } from '../../shared/useURLSearchParams';
import { useTranslation } from 'react-i18next';
import { SearchInput } from '../shared/searchInput/SearchInput';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MainContainer } from '../shared/mainContainer/MainContainer';
import { NoResultsFound } from '../shared/noResultsFound/NoResultsFound';
import { StickyToolbar } from '../shared/stickyToolbar/StickyToolbar';
import { useNavigate } from 'react-router';
import { ConditionalFragment } from 'react-conditionalfragment';
import { Banner } from '../shared/banner/Banner';
import { useToggleStateArray } from 'use-toggle-state';
import { CardsOrTable } from '../shared/cardsOrTable/CardsOrTable';
import { usePreferredListViewMode } from '../../globalState/preferredListViewMode/usePreferredListViewMode';
import { useStaffsListViewModel } from '../../api/main/profiles/viewModels/useStaffsListViewModel';
import { CardsOrTableToggle } from '../shared/cardsOrTable/CardsOrTableToggle';
import { Profile } from '../../api/main/models/Profile';
import { Staff } from '../../api/main/models/Staff';
import { ClassType } from '../../api/main/models/ClassType';
import { ClassTypeSelector } from '../classTypes/ClassTypeSelector';


export interface ProfileWithStaff extends Profile {
    staff?: Staff,
}

export interface StaffsListProps {
    filterByRoleGroups?: Array<string>,
    filterByStaffType?: (item: ProfileWithStaff) => boolean,
    title?: string,
}

/**
 * List of all staff.
 */
export const StaffsList = (props: StaffsListProps) => {
    const {
        filterByRoleGroups,
        filterByStaffType,
        title,
    } = props;

    const { t } = useTranslation();
    const { search: searchParam } = useSearchParams();
    const [search, setSearch] = React.useState<string>(searchParam ?? '');
    const { data: {
        items: _allItems,
        staffs,
        //users,
        teacherClassTypes,
        classTypes,
    }, isLoading, errors: loadingErrors, fetchMore, hasMore
    } = useStaffsListViewModel({ pageSize: undefined, });

    // Combine all the loaded data so each item includes its related details.
    const allItems = React.useMemo(() => _allItems?.map(item => {
        const staff = staffs?.find(it => it.id === item.id);
        //const user = users?.find(it => it.id === item.userId);
        const teacherClassTypelinks = teacherClassTypes?.filter(it => it.staffId === item.id);
        return {
            ...item,

            classTypes: teacherClassTypelinks
                ?.map(link => classTypes.find(type => type.id === link.classTypeId))
                ?.filter(type => !!type) //remove nulls
                ?.map(link => link as ClassType)
                ,

            staff,
        };
    }).filter(item => !!item.staff) // Remove anything without a staff model.

        , [_allItems, staffs, teacherClassTypes, classTypes])

    const [isMenuOpen, toggleMenuOpen] = useToggleStateArray();
    const [viewMode] = usePreferredListViewMode();
    const navigate = useNavigate();

    // Filter by the staff's search client side so it can work when offline as well as online.
    const items = React.useMemo(() => {
        let ret = (allItems ?? []);

        // Filter by the role groups allowed.
        if (filterByRoleGroups) {
            ret = ret.filter(item => !!filterByRoleGroups?.find(it => it === item.user?.roleGroup?.id));
        }

        // Filter by the staff type.
        if (filterByStaffType) {
            ret = ret.filter(item => filterByStaffType(item));
        }

        let lowerSearch = search.toLocaleLowerCase();

        // Filter the items being displayed.
        ret = ret.filter(item =>
            item.firstName.toLocaleLowerCase().indexOf(lowerSearch) >= 0
            || item.lastName.toLocaleLowerCase().indexOf(lowerSearch) >= 0
            || item.user.email.toLocaleLowerCase().indexOf(lowerSearch) >= 0
            || (item.user?.roleGroup?.name?.toLocaleLowerCase()?.indexOf(lowerSearch) ?? -1) >= 0
            || `${item.firstName} ${item.lastName}`.toLocaleLowerCase().indexOf(lowerSearch) >= 0 // Full name in languages "first last"
            || `${item.lastName} ${item.firstName}`.toLocaleLowerCase().indexOf(lowerSearch) >= 0 // Full name in languages "last first"
            || !!item.classTypes.find(it => (it?.name?.toLocaleLowerCase() ?? '').indexOf(lowerSearch) >= 0)
        );

        return ret;
    }, [allItems, search, filterByRoleGroups, filterByStaffType]);

    useReplaceSearchParamsEffect({ search: search });


    return (
        <>
            <Banner fluid>
                <StickyToolbar>
                    <Row>
                        <Col xs={12} md="auto">
                            <h1>
                                {title ?? t('staffsList.title', 'Staff')}
                            </h1>
                        </Col>
                        <ConditionalFragment showIf={isLoading}>
                            <Col xs="auto">
                                <LoadingIndicator size="sm" />
                            </Col>
                        </ConditionalFragment>
                    </Row>
                    <Row>
                        <Col>
                            <SearchInput value={search} onChange={e => setSearch(e.currentTarget.value)} />
                        </Col>
                        <Col xs="auto">
                            <Row>
                                <ButtonGroup>
                                    <LinkContainer to={'add'}>
                                        <Button color="primary">
                                            <FontAwesomeIcon icon="plus" /><> {t('staffsListBase.add', 'Add')}</>
                                        </Button>
                                    </LinkContainer>
                                </ButtonGroup>
                            </Row>
                        </Col>
                        <Col xs={12} md="auto">
                            <CardsOrTableToggle />
                        </Col>
                    </Row>
                </StickyToolbar>
            </Banner>

            <MainContainer fluid>
                <AlertOnErrors errors={loadingErrors} />
                <CardsOrTable
                    viewMode={viewMode}
                    items={items}
                    tableHeadings={[
                        t('staffsListBase.firstName', 'First name'),
                        t('staffsListBase.lastName', 'Last name'),
                        t('staffsListBase.email', 'Email'),
                        t('staffsListBase.primaryJobTitle.heading', 'Primary job title'),
                        t('staffsListBase.teacherFor.heading', 'Teacher for'),
                        t('staffsListBase.roleGroup', 'Security'),
                    ]}
                    columns={[
                        (item, view) => view === 'cards' ? (<CardTitle tag="h5">{item.firstName + " " + item.lastName}</CardTitle>) : null,
                        (item, view) => view === 'cards' ? null : item.firstName,
                        (item, view) => view === 'cards' ? null : item.lastName,
                        (item, view) =>
                            view === 'cards' ? (<CardSubtitle tag="h6" className="text-muted">{item.user?.email ?? ''}</CardSubtitle>)
                                : item.user?.email ?? '',
                        (item) => {
                            return item.staff?.primaryJobTitle;
                        },
                        (item) => <ClassTypeSelector readOnly tags={item?.classTypes} />,
                        (item, view) => {
                            if (view !== 'table') {
                                return null;
                            }

                            return item.user?.roleGroup?.name;
                        },
                    ]}
                    buttons={(item) => (
                        <ButtonGroup>
                            <LinkContainer to={`edit/${item.id}`}>
                                <Button color="primary">
                                    <FontAwesomeIcon icon="edit" />
                                    <> {t('common.edit', 'Edit')}</>
                                </Button>
                            </LinkContainer>
                            <ButtonDropdown isOpen={isMenuOpen(item.id)} toggle={() => toggleMenuOpen(item.id)}>
                                <DropdownToggle color="primary" caret>
                                    <span className="visually-hidden">{t('common.menuDropdown', 'More')}</span>
                                </DropdownToggle>
                                <DropdownMenu end>
                                    <LinkContainer to={`delete/${item.id}`}>
                                        <DropdownItem className="text-danger">
                                            <FontAwesomeIcon icon="trash" />
                                            <> {t('common.delete', 'Delete')}</>
                                        </DropdownItem>
                                    </LinkContainer>
                                </DropdownMenu>
                            </ButtonDropdown>
                        </ButtonGroup>
                    )}
                    onItemClick={item => navigate(`edit/${item.id}`)}
                />
                <ConditionalFragment showIf={isLoading && !items?.length}>
                    <LoadingIndicator fullWidth />
                </ConditionalFragment>
                <ConditionalFragment showIf={!isLoading && !items?.length}>

                    <NoResultsFound search={search} />

                </ConditionalFragment>
                <ConditionalFragment showIf={!isLoading && hasMore()}>

                    <Waypoint key={items?.length ?? 0} onEnter={fetchMore} />
                    <LoadingIndicator fullWidth />

                </ConditionalFragment>
            </MainContainer>
        </>
    );
};
