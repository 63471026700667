import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useCallback } from "react";
import { ConditionalFragment } from "react-conditionalfragment";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { LinkContainer } from "react-router-bootstrap";
import { Waypoint } from "react-waypoint";
import { Button, ButtonDropdown, ButtonGroup, CardSubtitle, CardTitle, Col, DropdownItem, DropdownMenu, DropdownToggle, Row } from "reactstrap";
import { useToggleStateArray } from "use-toggle-state";
import { useClassStagesListViewModel } from "../../api/main/classStages/viewModels/useClassStagesListViewModel";
import { usePreferredListViewMode } from "../../globalState/preferredListViewMode/usePreferredListViewMode";
import { AlertOnErrors } from "../../shared/alertOnErrors";
import { useReplaceSearchParamsEffect, useSearchParams } from "../../shared/useURLSearchParams";
import { Banner } from "../shared/banner/Banner";
import { CardsOrTable } from "../shared/cardsOrTable/CardsOrTable";
import { CardsOrTableToggle } from "../shared/cardsOrTable/CardsOrTableToggle";
import { LoadingIndicator } from "../shared/loadingIndicator/LoadingIndicator";
import { MainContainer } from "../shared/mainContainer/MainContainer";
import { NoResultsFound } from "../shared/noResultsFound/NoResultsFound";
import { SearchInput } from "../shared/searchInput/SearchInput";
import { StickyToolbar } from "../shared/stickyToolbar/StickyToolbar";

/**
 * List of ClassStages
 */
export const ClassStagesList = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    // Load data
    const {
        data: {
            items: _allItems,
            classTypes,
        },
        isLoading, errors: loadingErrors, fetchMore, hasMore } = useClassStagesListViewModel({ pageSize: undefined, });
    const [isMenuOpen, toggleMenuOpen] = useToggleStateArray();

    // Combine all the loaded data so each item includes its related details
    const allItems = React.useMemo(() => _allItems?.map(item => {
        const classType = classTypes?.find(it => it.id === item.classTypeId);

        return {
            ...item,

            classType,
        };
    }), [_allItems, classTypes]);

    // Use the preferred view mode for lists.
    const [viewMode] = usePreferredListViewMode();

    // Search.
    const { search: searchParam } = useSearchParams();
    const [search, setSearch] = React.useState<string>(searchParam ?? '');

    // Keep the URL up to date with the search text.
    useReplaceSearchParamsEffect({ search: search });

    // Filter by the search.
    const items = React.useMemo(() => {
        let ret = (allItems ?? []);

        let lowerSearch = search.toLocaleLowerCase();
        if (lowerSearch) {
            // Just filtering by all string values that arent ids to start with. Most likely wont need them all for search.
            ret = ret.filter(item =>
                item.name.toLocaleLowerCase().indexOf(lowerSearch) >= 0
                || !(item.classType?.name ?? '').toLocaleLowerCase().indexOf(lowerSearch)
            );
        }

        return ret;

    }, [allItems, search,]);

    // Format the age for display.
    const formatAge = useCallback((years: number, months: number) => {
        const totalMonths = (years * 12) + months;

        if (totalMonths <= 18) {
            return `${totalMonths} months`;
        } else {
            const numberOfYears = Math.floor(totalMonths / 12);
            const remainingMonths = totalMonths - (numberOfYears * 12);

            return `${numberOfYears} years ${remainingMonths} ${remainingMonths === 1 ? 'month' : 'months' }`;
        }
    }, []);

    // Render the UI
    //
    return (
        <>
            <Banner fluid>
                <StickyToolbar>
                    <Row>
                        <Col>
                            <h1>
                                {t('classStagesList.title', 'Class types')}
                            </h1>
                        </Col>
                        <ConditionalFragment showIf={isLoading}>
                            <Col xs="auto">
                                <LoadingIndicator size="sm" />
                            </Col>
                        </ConditionalFragment>
                        <Col xs="auto">
                            <CardsOrTableToggle />
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <SearchInput value={search} onChange={e => setSearch(e.currentTarget.value)} />
                        </Col>
                        <Col xs="auto">
                            <Row>
                                <ButtonGroup>
                                    <LinkContainer to={'add'}>
                                        <Button color="primary">
                                            <FontAwesomeIcon icon="plus" /><> {t('classStagesList.add', 'Add')}</>
                                        </Button>
                                    </LinkContainer>
                                </ButtonGroup>
                            </Row>
                        </Col>
                    </Row>
                </StickyToolbar>
            </Banner>

            <MainContainer fluid>
                <AlertOnErrors errors={loadingErrors} />

                <CardsOrTable
                    viewMode={viewMode}
                    items={items}
                    tableHeadings={[
                        null, /* Handles the card specific function for which we don't want a table column. */
                        t('classStagesList.name.heading', 'Name'),
                        t('classStagesList.classTypeId.heading', 'Activity'),
                        t('classStagesList.startAge.heading', 'Start age'),
                        t('classStagesList.endAge.heading', 'End age'),
                    ]}
                    columns={[
                        // We handle the card layout as a specific column ignored from the table.
                        (item, view) => {
                            // This column doesn't show in the table.
                            if (view !== 'cards') {
                                return null;
                            }

                            return (
                                <>
                                    <CardTitle tag="h5">{item.name}</CardTitle>
                                    <CardSubtitle>
                                        {item.classType?.name}
                                        <Row>
                                            <Col>
                                                {t('classStagesList.startAge.formatted', '{{startAge}}', { startAge: formatAge(item.startAgeYears, item.startAgeMonths), })} - {t('classStagesList.startAge.formatted', '{{startAge}}', { startAge: formatAge(item.endAgeYears, item.endAgeMonths), })}
                                            </Col>
                                        </Row>
                                    </CardSubtitle>
                                </>
                            );
                        },

                        // Name
                        (item, view) => {
                            if (view !== 'table') {
                                return null;
                            }

                            return item.name;
                        },

                        // Activity -> formerly Class type
                        (item, view) => {
                            if (view !== 'table') {
                                return null;
                            }

                            return (
                                <>
                                    {item.classType?.name ?? ''}
                                    <> </>
                                    <FontAwesomeIcon icon="circle" color={(item.classType?.name ?? '') === 'Swim Cubs' ? "#fada00" : '#a6a6a6'} />
                                </>
                            );
                        },

                        // StartAge
                        (item, view) => {
                            if (view !== 'table') {
                                return null;
                            }

                            return t('classStagesList.startAge.formatted', '{{startAge}}', { startAge: formatAge(item.startAgeYears, item.startAgeMonths), });
                        },
                        // EndAge
                        (item, view) => {
                            if (view !== 'table') {
                                return null;
                            }

                            return t('classStagesList.endAge.formatted', '{{endAge}}', { endAge: formatAge(item.endAgeYears, item.endAgeMonths), });
                        }
                    ]}
                    buttons={(item) => (
                        <ButtonGroup>
                            <LinkContainer to={`edit/${item.id}`}>
                                <Button color="primary">
                                    <FontAwesomeIcon icon="edit" />
                                    <> {t('common.edit', 'Edit')}</>
                                </Button>
                            </LinkContainer>
                            <ButtonDropdown isOpen={isMenuOpen(item.id)} toggle={() => toggleMenuOpen(item.id)}>
                                <DropdownToggle color="primary" caret>
                                    <span className="visually-hidden">{t('common.menuDropdown', 'More')}</span>
                                </DropdownToggle>
                                <DropdownMenu end>
                                    <LinkContainer to={`delete/${item.id}`}>
                                        <DropdownItem className="text-danger">
                                            <FontAwesomeIcon icon="trash" />
                                            <> {t('common.delete', 'Delete')}</>
                                        </DropdownItem>
                                    </LinkContainer>
                                </DropdownMenu>
                            </ButtonDropdown>
                        </ButtonGroup>
                    )}
                    onItemClick={item => navigate(`edit/${item.id}`)}
                />

                <ConditionalFragment showIf={isLoading && !items?.length}>
                    <LoadingIndicator fullWidth />
                </ConditionalFragment>
                <ConditionalFragment showIf={!isLoading && !items?.length}>
                    <NoResultsFound search={search} />
                </ConditionalFragment>
                <ConditionalFragment showIf={!isLoading && hasMore()}>
                    <Waypoint key={items?.length ?? 0} onEnter={fetchMore} />
                    <LoadingIndicator fullWidth />
                </ConditionalFragment>
            </MainContainer>
        </>
    );
};