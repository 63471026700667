import { useTranslation } from "react-i18next";
import { StaffsList } from "./StaffsList";

/**
 * StaffList filtered to just show coordinators.
 */
export const CoordinatorsList = () => {
    const { t } = useTranslation();

    return (
        <StaffsList
            title={t('coordinatorsList.title', 'Cub Coordinators')}
            filterByStaffType={(item) => !!item.staff?.isCoordinator}
        />
    );
};