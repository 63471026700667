import { ValidationErrors } from "pojo-validator";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Button, FormGroup, Label, ListGroup } from "reactstrap";
import { ClassStage } from "../../../../api/main/models/ClassStage";
import { ClassSubStage, classSubStageDefaultValues } from "../../../../api/main/models/ClassSubStage";
import { ModelArrayChanges } from "../../../../shared/useChanges";
import { ClassStageSubStageComponent } from "./ClassStageSubStageComponent";

export interface ClassStageSubStagesTabProps {
    model: ClassStage | null | undefined,

    classSubStagesManager: ModelArrayChanges<ClassSubStage, string>,
    validateClassSubStage: (model: ClassSubStage) => boolean,
    classSubStageValidationErrors: (id: string) => ValidationErrors,
}

/**
 * ClassSubStagesTab for ClassStages
 */
export const ClassStageSubStagesTab = (props: ClassStageSubStagesTabProps) => {
    const { model, classSubStagesManager, validateClassSubStage, classSubStageValidationErrors } = props;
    const { t } = useTranslation();

    // Add a new ClassSubStage and link it to the parent model
    const addClassSubStage = useCallback(() => {
        classSubStagesManager.addFor({ ...classSubStageDefaultValues(), classStageId: model?.id, });
    }, [classSubStagesManager, model]);

    // Render the UI
    //
    return (
        <>
            <FormGroup>
                <Label htmlFor="classSubStages">
                    {t('editClassStage.classSubStageTab.headingLabel', 'Stages within the class type:')}
                </Label>

                <ListGroup id="classSubStages">
                    {
                        classSubStagesManager.model.map(item => (
                            <ClassStageSubStageComponent key={item.id}
                                model={item}
                                change={changes => classSubStagesManager.changeFor(item.id, changes)}
                                remove={() => classSubStagesManager.removeFor(item.id)}
                                validate={() => validateClassSubStage(item)}
                                validationErrors={classSubStageValidationErrors(item.id)}
                            />
                        ))
                    }
                </ListGroup>

                <div className="mt-2">
                    <Button color="primary" outline onClick={() => addClassSubStage()}>
                        {t('editClassStage.classSubStageTab.add', 'Add stage')}
                    </Button>
                </div>
            </FormGroup>
        </>
    );
}