import { useMutation } from "@apollo/react-hooks";
import { useAsyncCallback } from 'react-use-async-callback';
import gql from "graphql-tag";
import { AsyncActionStatus } from '../../../shared/abstractStore';
import { CreatePaymentMutation, CreatePaymentMutationVariables, PaymentCreateInput } from '../generated/graphql';
import { paymentFieldsFragment } from '../models/Payment';

/**
 * Get a callback to create a Payment in the api.
 */
export function useCreatePaymentMutation(): [(model: PaymentCreateInput) => Promise<void>, AsyncActionStatus] {
    const [mutationAction, { error }] = useMutation<CreatePaymentMutation, CreatePaymentMutationVariables>(
        gql`
            mutation createPaymentMutation ($model: PaymentCreateInput!) {
                createPayment(model: $model) {
                    ...paymentFields
                }
            }

            ${paymentFieldsFragment}
        `
    );

    // Wrap the mutation in a standard callback.
    const [action, { isExecuting, errors: actionErrors }] = useAsyncCallback(async (model: PaymentCreateInput) => {
        const result = await mutationAction({ variables: { model: model } });
        if (result.errors && result.errors.length) {
            throw result.errors;
        }
    }, [mutationAction]);

    return [action, { isExecuting: isExecuting, errors: error ? error.message : actionErrors }];
}
