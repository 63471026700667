import { IdentityRoles } from "../../configure/security/IdentityRoles";
import { RouteEntry } from "../../shared/routes";
import { DeleteGender } from "./DeleteGender";
import { CreateGender, EditGender } from "./EditGender";
import { GendersList } from "./GendersList";


/**
 * Routes that should be added to configuration/routeConfig.ts to provide access to these components via routing.
 */
export const gendersRoutes: Array<RouteEntry> = [
    { path: '/administration/children-management/genders/add', element: <CreateGender />, authorize: true, requireRole: IdentityRoles.Administration, },
    { path: '/administration/children-management/genders/edit/:id', element: <EditGender />, authorize: true, requireRole: IdentityRoles.Administration, },
    { path: '/administration/children-management/genders/delete/:id', element: <DeleteGender />, authorize: true, requireRole: IdentityRoles.Administration, },
    { path: '/administration/children-management/genders', element: <GendersList />, authorize: true, requireRole: IdentityRoles.Administration, },
];
