import { useTranslation } from "react-i18next";
import { ButtonGroup, Col, Form, FormGroup, Label, Row, Spinner } from "reactstrap";
import { ButtonAsync } from "reactstrap-buttonasync";
import { FileUploadButton } from "../../../shared/fileUploadButton/FileUploadButton";
import { UploadedImagePreview } from "../../../shared/uploadedImagePreview/UploadedImagePreview";

export interface PhotoComponentProps {
    saveForm: () => Promise<void>,

    isExecutingImageUpload: boolean | undefined,
    onImageUpload: (files: FileList | null) => Promise<void>,
    isExecutingClearImage: boolean,
    imageUploadClickHandler: () => Promise<void>,
    imageUrl: string | undefined | null,
}

/**
 * Component under EditStaff that lets the user edit a Photo.
 */
export const PhotoComponent = (props: PhotoComponentProps) => {
    const {
        saveForm,

        isExecutingImageUpload,
        onImageUpload,
        isExecutingClearImage,
        imageUploadClickHandler,
        imageUrl
    } = props;
    const { t } = useTranslation();

    // Render the UI
    //
    return (
        <>
            <Form onSubmit={e => { e.preventDefault(); saveForm(); }}>
                <Row>
                    <Col>
                        <FormGroup>
                            <Label htmlFor="name">{t('editStaff.image.label', 'Photo of staff member:')}</Label>
                            <UploadedImagePreview size="lg" src={imageUrl ?? ''} />
                            <Row>
                                <Col>
                                    <ButtonGroup>
                                        <FileUploadButton
                                            color={`primary`}
                                            isExecuting={isExecutingImageUpload}
                                            executingChildren={<><Spinner size="sm" /> {t('common.uploading', 'Uploading...')}</>}
                                            onUpload={onImageUpload}
                                            outline={false}>
                                            {t('editStaff.uploadButtonText', 'Upload an image')}
                                        </FileUploadButton>
                                        <ButtonAsync color="primary"
                                            outline
                                            isExecuting={isExecutingClearImage}
                                            type="button"
                                            onClick={imageUploadClickHandler}
                                            executingChildren={<><Spinner size="sm" /> {t('editStaff.clearingImage', 'Clearing image...')}</>}>
                                            {t('editStaff.clearImageButton', 'Clear image')}
                                        </ButtonAsync>
                                    </ButtonGroup>
                                </Col>
                            </Row>
                        </FormGroup>
                    </Col>
                </Row>
            </Form>
        </>
    );
};