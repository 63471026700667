import { gql, useMutation } from "@apollo/client";
import { AsyncActionStatus } from "../../../../shared/abstractStore";
import { GenerateScheduledClassesMutation, GenerateScheduledClassesMutationVariables } from '../../generated/graphql';
import { useAsyncCallback } from "react-use-async-callback";
import { termFieldsFragment } from "../../models/Term";

/**
 * Get a callback to Generate ScheduledClasses based on the currentTerm in the api. Used for when creating new terms and copying the classes over.
 */
export function useGenerateScheduledClassesMutation(): [(oldTermId: string, newTermId: string) => Promise<void>, AsyncActionStatus] {
    // Query data we need from the api.
    const [mutationAction, { error }] = useMutation<GenerateScheduledClassesMutation, GenerateScheduledClassesMutationVariables>(
        gql`
        mutation generateScheduledClassesMutation ($oldTermId: ID!, $newTermId: ID!) {
            generateScheduledClasses (oldTermId: $oldTermId, newTermId: $newTermId) {
                ...termFields
            }
        }
        ${termFieldsFragment}
        `
    );

    // Wrap the mutation in a standard callback.
    const [action, { isExecuting, errors: actionErrors }] = useAsyncCallback(async (oldTermId: string, newTermId: string) => {
        const result = await mutationAction({ variables: { oldTermId, newTermId } });
        if (result.errors && result.errors.length) {
            throw result.errors;
        }
    }, [mutationAction]);

    return [action, { isExecuting: isExecuting, errors: error ? error.message : actionErrors }];
};