import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import { ConditionalFragment } from "react-conditionalfragment";
import { useTranslation } from "react-i18next";
import { LinkContainer } from "react-router-bootstrap";
import { Alert, Badge, Button, ButtonDropdown, ButtonGroup, Card, CardBody, Col, DropdownItem, DropdownMenu, DropdownToggle, Row } from "reactstrap";
import { useToggleStateArray } from "use-toggle-state";
import { StaffSubmissionAcceptanceState } from "../../../api/main/models/constants/StaffSubmissonAcceptanceState";
import { AlertOnErrors } from "../../../shared/alertOnErrors";
import { HappyIcon } from "../../shared/Utilities/HappyIcon";
import { StaffQualificationWithEvidenceBlobReference } from "../StaffQualifcationsTab";
import { useStaffQualificationCheckStatusColorAndMessage } from "./useStaffQualificationCheckStatusColorAndMessage";

export interface StaffQualifcationCardProps {
    model: StaffQualificationWithEvidenceBlobReference | null | undefined,
    handleEditStaffQualification: (id: string) => void,
}

/**
 * ComponentCard used to render a single StaffQualifcationCheck
 */
export const StaffQualifcationCard = (props: StaffQualifcationCardProps) => {
    const { model, handleEditStaffQualification } = props;
    const { t } = useTranslation();

    // Get the color coding and message for better UI
    const { color } = useStaffQualificationCheckStatusColorAndMessage(model);

    // Handle the dropdown menu toggle
    const [isMenuOpen, toggleMenuOpen] = useToggleStateArray();

    // Function to check whether a StaffQualification is due for review within the next 30 days
    const isDueWithinThirtyDays = (reviewDate: string) => {
        // First we can get the number of 
        const _millisecondsInADay = 24 * 3600 * 1000;

        // We can then calculate the difference in Milliseconds and then divide by the number of Milliseconds in a day to get the number of days
        const differenceInMilliseconds = new Date(reviewDate).getTime() - new Date().getTime();
        const differenceInDays = differenceInMilliseconds / _millisecondsInADay;

        // Floor the return to work with whole numbers
        return Math.floor(differenceInDays);
    };

    // Render the UI
    //
    return (
        <>
            <Card color={color} outline style={{ marginBottom: '10px' }}>
                <CardBody>
                    <AlertOnErrors
                        errors={[
                        ]}
                    />

                    <Row>
                        <Col>
                            <h5>{t('staffQualifcationsTab.staffQualificationCard.name.value', '{{qualifcationName}}', { qualifcationName: model?.name })}</h5>
                        </Col>
                        <Col xs="auto">
                            <ConditionalFragment showIf={model?.acceptanceState !== StaffSubmissionAcceptanceState.Rejected}>
                                {/* Greater than 30 days = Green, less than 30 days but more than 0 = Amber, 0 days = Red */}
                                <Badge color={isDueWithinThirtyDays(model?.reviewDate) > 31 ? "success" : isDueWithinThirtyDays(model?.reviewDate) <= 0 ? "danger" : "warning"} >
                                    {t('staffQualifcationsTab.staffQualificationCard.reviewDate.value', 'Review due on {{date, DD/MM/YYYY}}', { date: moment(model?.reviewDate), })}
                                </Badge>
                            </ConditionalFragment>
                        </Col>
                    </Row>

                    <ConditionalFragment showIf={model?.acceptanceState === StaffSubmissionAcceptanceState.Rejected}>
                        <Alert color="danger">
                            <HappyIcon icon="info" />
                            <> </>
                            {t('staffQualifcationsTab.staffQualificationCard.infoMessage.value', 'This qualification was rejected by the admin team. Please feel free to contact them if you want to discuss this.')}
                        </Alert>
                    </ConditionalFragment>

                    <Row style={{ marginTop: '8px', marginBottom: '4px' }}>
                        <Col>
                            <ConditionalFragment showIf={!!model?.evidenceBlobReference}>
                                <FontAwesomeIcon icon="file" />
                                <> </>
                                <a href={model?.evidenceBlobReference?.url} target="_blank" rel="noreferrer">
                                    {t('staffQualifcationsTab.staffQualificationCard.viewDocument.value', 'View evidence')}
                                </a>
                            </ConditionalFragment>
                            <ConditionalFragment showIf={!model?.evidenceBlobReference}>
                                <HappyIcon icon="file" />
                                <> </>
                                {t('staffQualifcationsTab.staffQualificationCard.noDocument.value', 'No evidence uploaded')}
                            </ConditionalFragment>
                        </Col>
                        <Col xs="auto">
                            <ButtonGroup>
                                <Button color="primary" outline onClick={() => handleEditStaffQualification(model?.id ?? '')}>
                                    <HappyIcon icon="calendar" />
                                    <> </>
                                    {t('staffQualifcationsTab.staffQualificationCard.editOrRenew.value', 'Edit / renew')}
                                </Button>

                                <ButtonDropdown isOpen={isMenuOpen(model?.id ?? '')} toggle={() => toggleMenuOpen(model?.id ?? '')}>
                                    <DropdownToggle color="primary" caret>
                                        <span className="visually-hidden">{t('common.menuDropdown', 'More')}</span>
                                    </DropdownToggle>
                                    <DropdownMenu end>
                                        <LinkContainer to={`delete/${model?.id}`}>
                                            <DropdownItem className="text-danger">
                                                <FontAwesomeIcon icon="trash" />
                                                <> {t('common.delete', 'Delete')}</>
                                            </DropdownItem>
                                        </LinkContainer>
                                    </DropdownMenu>
                                </ButtonDropdown>
                            </ButtonGroup>
                        </Col>
                    </Row>
                </CardBody>
            </Card>
        </>
    );
};