import { useTranslation } from 'react-i18next';
import { MainContainer } from '../../shared/mainContainer/MainContainer';
import { Banner } from '../../shared/banner/Banner';
import { useParams } from 'react-router-dom';
import { AlertOnErrors } from '../../../shared/alertOnErrors';
import { ConditionalFragment } from 'react-conditionalfragment';
import { LoadingIndicator } from '../../shared/loadingIndicator/LoadingIndicator';
import { Alert, Button, Col, Row } from 'reactstrap';
import { StickyToolbar } from '../../shared/stickyToolbar/StickyToolbar';
import { LinkContainer } from 'react-router-bootstrap';
import { usePaymentCheckoutViewModel } from '../../../api/main/payments/viewModel/usePaymentCheckoutViewModel';
import { PaymentCheckoutDetails } from './PaymentCheckoutDetails';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

/**
 * Page for when a payment has been successfuly received through the checkout process.
 * @returns
 */
export const PaymentCheckoutSuccess = () => {
    const { id } = useParams<{ id: string | undefined; }>();

    //// Parse data from Stripe's redirect query string.
    //const {
    //    redirect_status,
    //} = useMemo(() => {
    //    const urlSearchParams = new URLSearchParams(window.location.search);

    //    return {
    //        payment_intent: urlSearchParams.get('payment_intent'),
    //        payment_intent_client_secret: urlSearchParams.get('payment_intent_client_secret'),
    //        redirect_status: urlSearchParams.get('redirect_status'),
    //    };
    //}, []);

    const { t } = useTranslation();

    // Load the payment.
    const { data: modelData, isLoading: _isLoading, errors: loadErrors, } = usePaymentCheckoutViewModel(id);
    const model = modelData?.model;

    // Load the supporting data (ECommerceLinks)
    //const {
    //    data: {
    //        items: eCommerceLinks,
    //    }, isLoading: isLoadingSupportingData, errors: supportingDataLoadErrors
    //} = useECommerceLinks(model?.scheduledClass?.currentClassStageId);
    const isLoading = _isLoading;

    // Work out where we would go next.
    // If we know the child the payment is for, we take them to the classes tab.  Otherwise we take them to
    // the Children tab.
    const continueUrl = !!model?.childId ? `/my/children/details/${model.childId}?tab=classes` : '/my/children';

    // Handle validating the ECommerceLink Url
    //const openECommerceUrl = (url: string | undefined) => {
    //    const fullUrl = getFullUrl(url);

    //    // If we don't have a URL to work with do nothing.
    //    if (!fullUrl) { return; }

    //    // Open in a new window.
    //    window.open(fullUrl);
    //};

    // UI
    return (
        <>
            <Banner>
                <StickyToolbar>
                    <Row>
                        <Col>
                            <h1>
                                {t('paymentCheckoutSuccess.title', 'Checkout complete')}
                            </h1>
                            <h3>{model?.name}</h3>
                        </Col>
                        <ConditionalFragment showIf={isLoading}>
                            <Col xs="auto">
                                <LoadingIndicator />
                            </Col>
                        </ConditionalFragment>
                    </Row>
                </StickyToolbar>
            </Banner>

            <MainContainer>
                <AlertOnErrors errors={[loadErrors]} />

                <Alert color="success">
                    <p>
                        <strong>
                            {t('paymentCheckoutSuccess.alert.text', 'Thank you for your booking. 🎉')}
                        </strong>
                    </p>

                    <p>
                        {t('paymentCheckout.classDetails.generic.value', 'Your payment receipt has been sent to your email.')}
                    </p>
                </Alert>

                <PaymentCheckoutDetails data={modelData} />

                <Row>
                    <Col></Col>
                    <Col xs="auto">
                        <ConditionalFragment showIf={(modelData?.model?.scheduledClass?.classType?.name ?? '') === 'Swim Cubs'}>
                            <a href={`https://www.happycubs.ie/swim-cubs/swimwear-shop/`} target="_blank" rel="noreferrer">
                                <Button color="secondary" outline>
                                    <FontAwesomeIcon icon="shop" />
                                    <> </>
                                    {t('common.shopSwimwear', 'Shop swimwear')}
                                </Button>
                            </a>
                            <> </>
                        </ConditionalFragment>

                        <LinkContainer to={continueUrl} >
                            <Button color="primary">
                                {t('paymentCheckout.continueButton.text', 'View my classes')}
                            </Button>
                        </LinkContainer>
                    </Col>
                </Row>

                {/*<ConditionalFragment showIf={!!eCommerceLinks?.length}>*/}
                {/*    <Row>*/}
                {/*        <h5>{t('paymentCheckout.eCommerce.header', 'While you get started, here are selection of our products that we think your cub will love.')}</h5>*/}

                {/*        {*/}
                {/*            eCommerceLinks?.map(eCommercelink => (*/}
                {/*                <Col xs={12} sm={3} style={{ margin: '0.5rem 0', cursor: 'pointer' }} onClick={() => openECommerceUrl(eCommercelink?.url)}>*/}
                {/*                    <Card body style={{ height: '100%' }}>*/}
                {/*                        <h6 style={{ minHeight: '30px', fontWeight: 'bold', }}>{eCommercelink.name}</h6>*/}

                {/*                        <UploadedImagePreview src={eCommercelink.photoBlobReference?.url ?? ''} className="img-fluid" />*/}

                {/*                        <HtmlDisplay html={eCommercelink?.description} />*/}

                {/*                        */}{/* Removed the click handler from the button as the containing Col is acting as the whole button, keeping the button so the user has a UI element to click, if they're unaware of cursor changes and their meaning */}
                {/*                        <Button color="primary" outline>*/}
                {/*                            <FontAwesomeIcon icon="link" />*/}
                {/*                            <> </>*/}
                {/*                            {t('paymentCheckout.viewInStore.button', 'View in store')}*/}
                {/*                        </Button>*/}
                {/*                    </Card>*/}
                {/*                </Col>*/}
                {/*            ))*/}
                {/*        }*/}
                {/*    </Row>*/}
                {/*</ConditionalFragment>*/}
            </MainContainer>
        </>
    );
};