import { ScheduledClass } from "../../api/main/models/ScheduledClass";
import i18next from "i18next";
import { dayOfWeekDisplayName } from "../../api/main/models/constants/DayOfWeek";

/**
 * Compile and return summary of a class based on its schedule, location, and class types.
 */
export function getScheduledClassSummary(
    model: ScheduledClass | undefined | null,
    options?: {
        classLocation?: { name: string, },
        classStage?: { name: string, },
        classSubStage?: { name: string, }
    }): string
{
    // Format a useful string, combining the information we have given, but still sensible if we ignore the information we don't have.
    let ret: string;
    if (!!options?.classLocation && !!options?.classStage) {
        ret = i18next.t(
            'getScheduledClassSummary.withEverything',
            '{{dayOfWeekName}} {{startTimeHours, 00}}:{{startTimeMinutes, 00}} {{classStageName}} {{classSubStageName}} at {{locationName}}',
            {
                dayOfWeekName: dayOfWeekDisplayName(model?.dayOfWeek ?? 99, ((k: any, s: any, m: any) => i18next.t(k, s, m)) as any),
                startTimeHours: model?.startTimeHours ?? 0,
                startTimeMinutes: model?.startTimeMinutes ?? 0,
                locationName: options?.classLocation?.name ?? '',
                classStageName: options?.classStage?.name ?? '',
                classSubStageName: options?.classSubStage?.name ?? '',
            }
        );
    } else if (!!options?.classLocation && !options?.classStage) {
        ret = i18next.t(
            'getScheduledClassSummary.withLocation',
            '{{dayOfWeekName}} {{startTimeHours, 00}}:{{startTimeMinutes, 00}} at {{locationName}}',
            {
                dayOfWeekName: dayOfWeekDisplayName(model?.dayOfWeek ?? 99, ((k: any, s: any, m: any) => i18next.t(k, s, m)) as any),
                startTimeHours: model?.startTimeHours ?? 0,
                startTimeMinutes: model?.startTimeMinutes ?? 0,
                locationName: options?.classLocation?.name ?? '',
                classStageName: options?.classStage?.name ?? '',
                classSubStageName: options?.classSubStage?.name ?? '',
            }
        );
    } else if (!options?.classLocation && !!options?.classStage) {
        ret = i18next.t(
            'getScheduledClassSummary.withClassStage',
            '{{dayOfWeekName}} {{startTimeHours, 00}}:{{startTimeMinutes, 00}} {{classStageName}} {{classSubStageName}}',
            {
                dayOfWeekName: dayOfWeekDisplayName(model?.dayOfWeek ?? 99, ((k: any, s: any, m: any) => i18next.t(k, s, m)) as any),
                startTimeHours: model?.startTimeHours ?? 0,
                startTimeMinutes: model?.startTimeMinutes ?? 0,
                locationName: options?.classLocation?.name ?? '',
                classStageName: options?.classStage?.name ?? '',
                classSubStageName: options?.classSubStage?.name ?? '',
            }
        );
    } else {
        ret = i18next.t(
            'getScheduledClassSummary.withNothing',
            '{{dayOfWeekName}} {{startTimeHours, 00}}:{{startTimeMinutes, 00}}',
            {
                dayOfWeekName: dayOfWeekDisplayName(model?.dayOfWeek ?? 99, ((k: any, s: any, m: any) => i18next.t(k, s, m)) as any),
                startTimeHours: model?.startTimeHours ?? 0,
                startTimeMinutes: model?.startTimeMinutes ?? 0,
                locationName: options?.classLocation?.name ?? '',
                classStageName: options?.classStage?.name ?? '',
                classSubStageName: options?.classSubStage?.name ?? '',
            }
        );
    }

    
    return ret;
}