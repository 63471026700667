import { ClassStage } from "../../../api/main/models/ClassStage";
import { useParams, useNavigate } from 'react-router';
import { useTranslation } from "react-i18next";
import { useChanges, useChangesArray } from "../../../shared/useChanges";
import { classStageDefaultValues } from "../../../api/main/models/ClassStage";
import { useSaveClassStageMutation } from "../../../api/main/classStages/useSaveClassStageMutation";
import { useValidatorCallback } from "pojo-validator-react";
import { useAsyncCallback } from "react-use-async-callback";
import { Banner } from "../../shared/banner/Banner";
import { StickyToolbar } from "../../shared/stickyToolbar/StickyToolbar";
import { Button, Col, NavItem, NavLink, Row, Spinner } from "reactstrap";
import { ConditionalFragment } from "react-conditionalfragment";
import { LoadingIndicator } from "../../shared/loadingIndicator/LoadingIndicator";
import { MainContainer } from "../../shared/mainContainer/MainContainer";
import { AlertOnErrors } from "../../../shared/alertOnErrors";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { MainTab } from "./MainTab";
import { PillsNavBar } from "../../shared/pillsNavBar/PillsNavBar";
import { useMemo, useState } from "react";
import { hasAnyValidationErrors } from "../../../utilities/hasAnyValidationErrors";
import { FormButtons } from "../../shared/formButtons/FormButtons";
import { ButtonAsync } from "reactstrap-buttonasync";
import { useEditClassStageSupportingData } from "../../../api/main/classStages/viewModels/useEditClassStageSupportingData";
import { ClassStageSubStagesTab } from "./ClassStageSubStagesTab/ClassStageSubStagesTab";
import { ClassSubStage } from '../../../api/main/models/ClassSubStage';
import { useSaveClassSubStageMutation } from "../../../api/main/classSubStages/useSaveClassSubStageMutation";
import { useDeleteClassSubStageMutation } from "../../../api/main/classSubStages/useDeleteClassSubStage";
import { useValidatorArrayCallback } from "../../../shared/validator-react-contrib/useValidatorArrayCallback";
import { useEditClassStageViewModel } from "../../../api/main/classStages/viewModels/useEditClassStageViewModel";
import { ClassStageLessonDocumentsTab } from "./classStageLessonDocumentsTab/ClassStageLessonDocumentsTab";
import { LessonDocument } from "../../../api/main/models/LessonDocument";
import { useSaveLessonDocumentMutation } from "../../../api/main/lessonDocuments/useSaveLessonDocumentMutation";
import { useDeleteLessonDocumentMutation } from "../../../api/main/lessonDocuments/useDeleteLessonDocument";
import { WelcomePackDocument } from "../../../api/main/models/WelcomePackDocument";
import { useSaveWelcomePackDocumentMutation } from "../../../api/main/welcomePackDocuments/useSaveWelcomePackDocumentMutation";
import { useDeleteWelcomePackDocumentMutation } from "../../../api/main/welcomePackDocuments/useDeleteWelcomePackDocument";
import { ClassStageWelcomePackDocumentsTab } from "./classStageWelcomePackDocumentsTab/ClassStageWelcomePackDocumentsTab";
import { useBlobReference } from "../../../api/main/blobReferences/useBlobReference";
import { BlobUploadService } from "../../../api/main/blobReferences/BlobUploadService";
import { ClassStagePhotoComponent } from "./classStagePhotosTab/ClassStagePhotoComponent";
import { useReplaceSearchParamsEffect, useSearchParams } from "../../../shared/useURLSearchParams";
import { ClassStageECommerceLinksTab } from "./classStageECommerceLinksTab/ClassStageECommerceLinksTab";
import { ECommerceLink } from "../../../api/main/models/ECommerceLink";
import { useSaveECommerceLinkMutation } from "../../../api/main/eCommerceLinks/useSaveECommerceLinkMutation";
import { useDeleteECommerceLinkMutation } from "../../../api/main/eCommerceLinks/useDeleteECommerceLinkMutation";

export interface EditClassStageProps {
    isCreate?: boolean,
    onCreateDefaultValues?: () => Partial<ClassStage>;
}

/**
 * Component to create a ClassStage
 */
export const CreateClassStage = (props: EditClassStageProps) => (<EditClassStage isCreate={true} {...props} />);

/**
 * Component to edit a ClassStage
 */
export const EditClassStage = (props: EditClassStageProps) => {
    const {
        isCreate,
        onCreateDefaultValues
    } = props;

    const { t } = useTranslation();
    const { id } = useParams<{ id: string | undefined; }>();
    const navigate = useNavigate();

    // Load all data
    const {
        data: {
            model: storeModel,
            classSubStages: storeClassSubStages,
            lessonDocuments: storeLessonDocuments,
            welcomePackDocuments: storeWelcomePackDocuments,
            eCommerceLinks: storeECommerceLinks,
        },
        errors: loadErrors, isLoading: _isLoading
    } = useEditClassStageViewModel(id);

    // Supporting data
    const {
        data: {
            classTypes,
            classStages: allClassStages,
        },
        errors: loadSupportingDataErrors,
        isLoading: isLoadingSupportingData
    } = useEditClassStageSupportingData();
    const isLoading = _isLoading || isLoadingSupportingData;

    // Model (ClassStage)
    const { model, change, changes, } = useChanges(storeModel, isCreate ? { ...classStageDefaultValues(), ...(onCreateDefaultValues ? onCreateDefaultValues() : {}) } : undefined);
    const [saveClassStage, { errors: saveErrors }] = useSaveClassStageMutation();

    // ClassStage image upload (photo)
    const { data: { model: image }, errors: imageLoadErrors } = useBlobReference(model?.photoBlobReferenceId);
    const [onUploadImage, { errors: imageUploadErrors, isExecuting: isUploadingImage }] = useAsyncCallback(async (files: FileList | null) => {
        if (!files) {
            return;
        }

        let uploadService: BlobUploadService = new BlobUploadService("/api/blobs");
        let result = await uploadService.upload(files);

        if (!!result) {
            change({ photoBlobReferenceId: result.id });
        }
    }, [change]);

    // Clear image functionality
    const [clearImage, { isExecuting: isClearingImage }] = useAsyncCallback(async () => {
        change({ photoBlobReferenceId: null });
    }, [change]);

    // Model (ClassSubStages)
    const classSubStagesManager = useChangesArray<ClassSubStage, string>(storeClassSubStages, item => item.id);
    const [saveClassSubStage] = useSaveClassSubStageMutation();
    const [removeClassSubStage] = useDeleteClassSubStageMutation();
    const [validateClassSubStage, classSubStageValidationErrors] = useValidatorArrayCallback<ClassSubStage>((myModel, validation, fieldsToCheck) => {
        const rules = {
            name: () => !myModel?.name ? t('editClassStage.classSubStage.errors.name', 'Name cannot be empty') : '',
            numberOfLessons: () => !myModel?.numberOfLessons ? t('editClassStage.classSubStage.errors.numberOfLessons', 'Number of lessons cannot be empty') : '',
        };

        validation.checkRules(rules, fieldsToCheck);
    }, []);

    // Model (LessonDocuments)
    const lessonDocumentsManager = useChangesArray<LessonDocument, string>(storeLessonDocuments, item => item.id);
    const [saveLessonDocument] = useSaveLessonDocumentMutation();
    const [removeLessonDocument] = useDeleteLessonDocumentMutation();
    const [validateLessonDocument, lessonDocumentValidationErrors] = useValidatorArrayCallback<LessonDocument>((myModel, validation, fieldsToCheck) => {
        const rules = {
            name: () => !myModel?.name ? t('editClassStage.lessonDocument.errors.name', 'Name cannot be empty') : '',
        };

        validation.checkRules(rules, fieldsToCheck);
    }, []);

    // Model (LessonDocuments)
    const welcomePackDocumentsManager = useChangesArray<WelcomePackDocument, string>(storeWelcomePackDocuments, item => item.id);
    const [saveWelcomePackDocument] = useSaveWelcomePackDocumentMutation();
    const [removeWelcomePackDocument] = useDeleteWelcomePackDocumentMutation();
    const [validateWelcomePackDocument, welcomePackDocumentValidationErrors] = useValidatorArrayCallback<WelcomePackDocument>((myModel, validation, fieldsToCheck) => {
        const rules = {
            name: () => !myModel?.name ? t('editClassStage.welcomePackDocument.errors.name', 'Name cannot be empty') : '',
        };

        validation.checkRules(rules, fieldsToCheck);
    }, []);

    // Model (ECommerceLinks)
    const eCommerceLinksManager = useChangesArray<ECommerceLink, string>(storeECommerceLinks, item => item.id);
    const [saveECommercelink] = useSaveECommerceLinkMutation();
    const [removeECommerceLink] = useDeleteECommerceLinkMutation();
    const [validateECommerceLink, eCommerceLinkValidationErrors] = useValidatorArrayCallback<ECommerceLink>((myModel, validation, fieldsToCheck) => {
        const rules = {
            name: () => !myModel?.name ? t('editClassStage.eCommerceLink.errors.name', 'Name cannot be empty') : '',
            description: () => !myModel.description ? t('editClassStage.eCommerceLink.errors.description', 'Description cannot be empty') : '',
            url: () => !myModel.description ? t('editClassStage.eCommerceLink.errors.url', 'Link to product cannot be empty') : '',
        };

        validation.checkRules(rules, fieldsToCheck);
    }, []);

    // Main model validation
    const [validate, validationErrors] = useValidatorCallback((validation, fieldsToCheck) => {
        const rules = {
            name: () => !model?.name ? t('editClassStage.errors.name', 'Name cannot be empty') : '',
            classTypeId: () => !model?.classTypeId ? t('editClassStage.errors.classTypeId', 'Activity cannot be empty') : '',

            // Any errors in the related object
            classSubStages: () => classSubStagesManager.model.filter(it => !validateClassSubStage(it)).length ? t('editClassStage.errors.classSubStages', 'One or more of the stages are invalid') : '',
            lessonDocuments: () => lessonDocumentsManager.model.filter(it => !validateLessonDocument(it)).length ? t('editClassStage.errors.lessonDocuments', 'One or more of the lesson documents are invalid') : '',
            //childDiscounts: () => childChildDiscountsManager.model.filter(it => !validateChildChildDiscount(it)).length ? t('editClassStage.errors.childDiscounts', 'One or more of the child discounts are invalid') : '',
            eCommerceLinks: () => eCommerceLinksManager.model.filter(it => !validateECommerceLink(it)).length ? t('editClassStage.errors.eCommerceLinks', 'One or more of the ecommerce links are invalid') : '',
        };
        validation.checkRules(rules, fieldsToCheck);
    }, [model, classSubStagesManager, validateClassSubStage]);

    // Save everything
    const [saveForm, { isExecuting: isSaving, errors: saveFormErrors }] = useAsyncCallback(async () => {
        if (!model) {
            return;
        }

        if (!validate()) {
            return;
        }

        // Save the main model.
        await saveClassStage(model.id, { ...changes }, isCreate ?? false);

        // Save changes to the ClassSubStage
        for (const item of classSubStagesManager.added) { await saveClassSubStage(item.id, classSubStagesManager.changesFor(item.id), true); }
        for (const item of classSubStagesManager.updated) { await saveClassSubStage(item.id, classSubStagesManager.changesFor(item.id), false); }
        for (const item of classSubStagesManager.removed) { await removeClassSubStage(item.id); }
        classSubStagesManager.markAsSaved();

        // Save changes to the LessonDocument
        for (const item of lessonDocumentsManager.added) {
            if (item.blobReferenceId === null) {
                continue;
            } else {
                await saveLessonDocument(item.id, lessonDocumentsManager.changesFor(item.id), true);
            }
        }
        for (const item of lessonDocumentsManager.updated) { await saveLessonDocument(item.id, lessonDocumentsManager.changesFor(item.id), false); }
        for (const item of lessonDocumentsManager.removed) { await removeLessonDocument(item.id); }
        lessonDocumentsManager.markAsSaved();

        // Save changes to the LessonDocument
        for (const item of welcomePackDocumentsManager.added) { await saveWelcomePackDocument(item.id, welcomePackDocumentsManager.changesFor(item.id), true); }
        for (const item of welcomePackDocumentsManager.updated) { await saveWelcomePackDocument(item.id, welcomePackDocumentsManager.changesFor(item.id), false); }
        for (const item of welcomePackDocumentsManager.removed) { await removeWelcomePackDocument(item.id); }
        welcomePackDocumentsManager.markAsSaved();

        // Save changes to the ECommerceLink
        for (const item of eCommerceLinksManager.added) { await saveECommercelink(item.id, eCommerceLinksManager.changesFor(item.id), true); }
        for (const item of eCommerceLinksManager.updated) { await saveECommercelink(item.id, eCommerceLinksManager.changesFor(item.id), false); }
        for (const item of eCommerceLinksManager.removed) { await removeECommerceLink(item.id); }
        eCommerceLinksManager.markAsSaved();

        // Go back to previous screen.
        navigate(-1);
    }, [validate, saveClassStage, model, changes, isCreate, id, navigate, classSubStagesManager, saveClassSubStage, removeClassSubStage]);

    // Manage the tab being displayed.  If we have tab= on the query string, default to it, otherwise default to the main tab.
    const { tab: tabParam } = useSearchParams();
    // Manage the tab being displayed
    const [activeTab, setActiveTab] = useState<'main' | 'photo' | 'substages' | 'lesson-documents' | 'welcome-packs' | 'shopLinks'>(tabParam as any || 'main');

    // Keep the URL up to date with the currentTab
    useReplaceSearchParamsEffect({ tab: activeTab === 'main' ? '' : activeTab });

    // Filter the stages available to only ones of the same ClassType.
    const classStages = useMemo(() => allClassStages?.filter(item => item.classTypeId === model?.classTypeId), [allClassStages, model]);

    // Render the UI
    //
    return (
        <>
            <Banner>
                <StickyToolbar>
                    <Row>
                        <Col xs={12} md="auto">
                            <h1>
                                {
                                    isCreate ? t('editClassStage.createHeading.default', 'Add class type')
                                        : t('editClassStage.editHeading.default', 'Edit class type')
                                }
                            </h1>
                            <h3>{model?.name}</h3>
                        </Col>
                        <Col>
                            <PillsNavBar>
                                <NavItem>
                                    <NavLink active={activeTab === 'main'} onClick={() => setActiveTab('main')}>
                                        {t('editClassStage.nav.main', 'Details')}

                                        {/* Show a warning triangle if any fields for this tab have validation errors */}
                                        <ConditionalFragment showIf={hasAnyValidationErrors([validationErrors['name'], validationErrors['startAgeYears'], validationErrors['startAgeMonths'], validationErrors['endAgeYears'], validationErrors['endAgeMonths'],])}>
                                            <> </><FontAwesomeIcon icon="exclamation-triangle" color="danger" />
                                        </ConditionalFragment>
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink active={activeTab === 'substages'} onClick={() => setActiveTab('substages')}>
                                        {t('editClassStage.nav.substages', 'Stages')}

                                        {/* Show a warning triangle if any fields for this tab have validation errors */}
                                        <ConditionalFragment showIf={hasAnyValidationErrors(validationErrors['classSubStages'])}>
                                            <> </>
                                            <FontAwesomeIcon icon="exclamation-triangle" color="danger" />
                                        </ConditionalFragment>
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink active={activeTab === 'photo'} onClick={() => setActiveTab('photo')}>
                                        {t('editClassStage.nav.photo', 'Photo')}

                                        {/* Show a warning triangle if any fields for this tab have validation errors */}
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink active={activeTab === 'lesson-documents'} onClick={() => setActiveTab('lesson-documents')}>
                                        {t('editClassStage.nav.lessonDocuments', 'Lesson documents')}


                                        {/* Show a warning triangle if any fields for this tab have validation errors */}
                                        <ConditionalFragment showIf={hasAnyValidationErrors(validationErrors['lessonDocuments'])}>
                                            <> </>
                                            <FontAwesomeIcon icon="exclamation-triangle" color="danger" />
                                        </ConditionalFragment>
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink active={activeTab === 'welcome-packs'} onClick={() => setActiveTab('welcome-packs')}>
                                        {t('editClassStage.nav.welcomePacks', 'Welcome packs')}


                                        {/* Show a warning triangle if any fields for this tab have validation errors */}
                                        <ConditionalFragment showIf={hasAnyValidationErrors(validationErrors['termHolidays'])}>
                                            <> </>
                                            <FontAwesomeIcon icon="exclamation-triangle" color="danger" />
                                        </ConditionalFragment>
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink active={activeTab === 'shopLinks'} onClick={() => setActiveTab('shopLinks')}>
                                        {t('editClassStage.nav.shopLinks', 'Links to the shop')}


                                        {/* Show a warning triangle if any fields for this tab have validation errors */}
                                        {/*<ConditionalFragment showIf={hasAnyValidationErrors(validationErrors['termHolidays'])}>*/}
                                        {/*    <> </>*/}
                                        {/*    <FontAwesomeIcon icon="exclamation-triangle" color="danger" />*/}
                                        {/*</ConditionalFragment>*/}
                                    </NavLink>
                                </NavItem>
                            </PillsNavBar>
                        </Col>
                        <ConditionalFragment showIf={isLoading}>
                            <Col xs="auto">
                                <LoadingIndicator size="sm" />
                            </Col>
                        </ConditionalFragment>
                    </Row>
                </StickyToolbar>
            </Banner>

            <MainContainer>
                <AlertOnErrors errors={[
                    loadErrors,
                    loadSupportingDataErrors,
                    saveFormErrors,
                    saveErrors,
                    imageUploadErrors,
                    imageLoadErrors,
                ]} />

                {/* Active tab */}
                {
                    activeTab === 'main' ? (
                        <MainTab
                            model={model}
                            change={change}
                            validate={validate}
                            validationErrors={validationErrors}
                            saveForm={saveForm}
                            classTypes={classTypes}
                            classStages={classStages}
                        />
                    ) : activeTab === 'photo' ? (
                        <ClassStagePhotoComponent
                            saveForm={saveForm}
                            isExecutingImageUpload={isUploadingImage}
                            onImageUpload={onUploadImage}
                            isExecutingClearImage={isClearingImage}
                            imageUploadClickHandler={clearImage}
                            imageUrl={image?.url}
                        />
                    ) : activeTab === 'substages' ? (
                        <ClassStageSubStagesTab
                            model={model}
                            classSubStagesManager={classSubStagesManager}
                            validateClassSubStage={validateClassSubStage}
                            classSubStageValidationErrors={classSubStageValidationErrors}
                        />
                    ) : activeTab === 'lesson-documents' ? (
                        <ClassStageLessonDocumentsTab
                            model={model}
                            lessonDocumentsManager={lessonDocumentsManager}
                            validateLessonDocument={validateLessonDocument}
                            lessonDocumentValidationErrors={lessonDocumentValidationErrors}
                            storeClassSubStages={storeClassSubStages}
                        />
                    ) : activeTab === 'welcome-packs' ? (
                        <ClassStageWelcomePackDocumentsTab
                            model={model}
                            welcomePackDocumentsManager={welcomePackDocumentsManager}
                            validateWelcomePackDocument={validateWelcomePackDocument}
                            welcomePackDocumentValidationErrors={welcomePackDocumentValidationErrors}
                        />
                    ) : activeTab === 'shopLinks' ? (
                        <ClassStageECommerceLinksTab
                            model={model}
                            eCommerceLinksManager={eCommerceLinksManager}
                            validateECommerceLink={validateECommerceLink}
                            eCommerceLinkValidationErrors={eCommerceLinkValidationErrors}
                        />
                    ) : null
                }

                {/* Save buttons at the bottom of all tabs. */}
                <FormButtons>
                    <ConditionalFragment showIf={!isLoading}>
                        <ButtonAsync color="primary" isExecuting={isSaving} onClick={() => saveForm()}
                            executingChildren={<><Spinner size="sm" /> {t('common.saving', 'Saving...')}</>}>
                            <FontAwesomeIcon icon="save" />
                            <> </>
                            {t('common.save', 'Save')}
                        </ButtonAsync>
                    </ConditionalFragment>

                    <Button type="button" color="primary" outline onClick={e => navigate(-1)}>
                        {t('common.cancel', 'Cancel')}
                    </Button>
                </FormButtons>
            </MainContainer>
        </>
    );
};