import gql from 'graphql-tag';
import { Guid } from 'guid-string';
import { WelcomePackDocumentFieldsFragment } from '../generated/graphql';

export const welcomePackDocumentFieldsFragment = gql`
    fragment welcomePackDocumentFields on WelcomePackDocument {
        id
        name
        archived
        classStageId
        classSubStageId
        blobReferenceId
        isWelcomePack
    }
`;

export type WelcomePackDocument = Omit<WelcomePackDocumentFieldsFragment, '__typename'>;

/**
 * Default values.
 */
export const welcomePackDocumentDefaultValues = (): Partial<WelcomePackDocument> => ({
    id: Guid.newGuid(),
    name: '',
    archived: false,
    classStageId: undefined,
    classSubStageId: null,
    blobReferenceId: null,
    isWelcomePack: false,
});