import gql from 'graphql-tag';
import { Guid } from 'guid-string';
import { GenderFieldsFragment } from '../generated/graphql';

export const genderFieldsFragment = gql`
    fragment genderFields on Gender {
        id
        name
        displayOrder
        archived
    }
`;

export type Gender = Omit<GenderFieldsFragment, '__typename'>;

/**
 * Default values.
 */
export const genderDefaultValues = (): Partial<Gender> => ({
    id: Guid.newGuid(),
    name: '',
    displayOrder: 99,
    archived: false,
});