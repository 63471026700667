import { TFunction } from "i18next";


/**
 * Known automation keys that can be used for general discounts to have specia discounts automatically applied to payments by the system.
 */
export enum GeneralDiscountAutomationKey {
    SiblingDiscount = "SiblingDiscount",
    StaffDiscount = "StaffDiscount",
    StaffSiblingDiscount = "StaffSiblingDiscount",
    DualLessonDiscount = "DualLessonDiscount",
}

/**
 * Returns all GeneralDiscountAutomationKey options, suitable for use in a list.
 */
export function getGeneralDiscountAutomationKeys(): Array<GeneralDiscountAutomationKey> {
    const ret = Object.keys(GeneralDiscountAutomationKey).map(key => key as GeneralDiscountAutomationKey);
    return ret;
}

/**
 * Convert a GeneralDiscountAutomationKey to its display name. 
 */
export function generalDiscountAutomationKeyDisplayName(name: GeneralDiscountAutomationKey | string, t: TFunction): string {
    switch (name) {
        case GeneralDiscountAutomationKey.SiblingDiscount: return t('generalDiscountAutomationKey.siblingDiscount.name', 'Sibling discount');
        case GeneralDiscountAutomationKey.StaffDiscount: return t('generalDiscountAutomationKey.staffDiscount.name', 'Staff discount');
        case GeneralDiscountAutomationKey.StaffSiblingDiscount: return t('generalDiscountAutomationKey.staffSiblingDiscount.name', 'Staff sibling discount');
        case GeneralDiscountAutomationKey.DualLessonDiscount: return t('generalDiscountAutomationKey.dualLessonDiscount.name', 'Dual lesson discount');
        default: return t('generalDiscountAutomationKey.unknown.name', 'No automation');
    }
}
