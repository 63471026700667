import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ValidationErrors } from "pojo-validator";
import { ValidateCallback } from "pojo-validator-react";
import { ValidatedInput } from "pojo-validator-reactstrap";
import { useTranslation } from "react-i18next";
import { Button, Col, FormGroup, Input, InputGroup, Label, ListGroupItem, Row } from "reactstrap";
import { CourtesyClassCredit } from "../../../../api/main/models/CourtesyClassCredit";
import { Term } from "../../../../api/main/models/Term";
import { getScheduledClassSummary } from "../../../scheduledClass/getScheduledClassSummary";
import { HappyIcon } from "../../../shared/Utilities/HappyIcon";
import { ScheduledClassWithClassLocation } from "./CourtesyClassCreditsTab";

export interface CourtesyClassCreditComponentProps {
    model: CourtesyClassCredit | undefined,
    change: (changes: Partial<CourtesyClassCredit>) => void;
    remove: () => void,

    validate: ValidateCallback,
    validationErrors: ValidationErrors,
    terms: Array<Term> | null | undefined,
    scheduledClasses: Array<ScheduledClassWithClassLocation> | undefined | null,
}

/**
 * Component under Children that lets the user create and edit CourtesyClassCredits
 */
export const CourtesyClassCreditComponent = (props: CourtesyClassCreditComponentProps) => {
    const {
        model,
        change,
        remove,
        validate,
        validationErrors,
        terms,
        scheduledClasses
    } = props;
    const { t } = useTranslation();

    //Render the UI
    //
    return (
        <>
            <ListGroupItem>
                <Row>
                    <Col>
                        <FormGroup>
                            <Label htmlFor="name">{t('editChild.courtesyClassCredit.creditName.label', 'Reason for credit: ')}</Label>
                            <ValidatedInput name="creditName" type="text" value={model?.name ?? ''} onChange={e => change({ name: e.currentTarget.value })} onBlur={e => validate('name')} validationErrors={validationErrors['name']}></ValidatedInput>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <FormGroup>
                            <Label htmlFor="financialValue">{t('editChild.courtesyClassCredit.creditValue.label', 'Financial value (if any): ')}</Label>
                            <InputGroup>
                                <Button color="secondary" onClick={(e) => e.preventDefault()}><HappyIcon icon="euro" /></Button>
                                <Input name="financialValue" type="number" value={Number(model?.financialValue ?? 0)} onChange={e => change({ financialValue: Number(e.currentTarget.value)})} />
                            </InputGroup>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <FormGroup>
                            <Label htmlFor="scheduledClassId">{t('editChild.courtesyClassCredit.scheduledClass.label', 'Class')}</Label>
                            <ValidatedInput name="scheduledClassId" type="select" value={model?.scheduledClassId ?? ''} onChange={e => change({ scheduledClassId: e.currentTarget.value })} onBlur={e => validate('scheduledClassId')} validationErrors={validationErrors['scheduledClassId']}>
                                <option value="">{t('editChild.courtesyClassCredit.pleaseSelectAClass', '(Please select a class)')}</option>
                                {
                                    scheduledClasses?.map(item => (
                                        <option key={item.id} value={item.id}>{getScheduledClassSummary(item, { classLocation: item.classLocation, classStage: item.classStage, classSubStage: item.classSubStage })}</option>
                                    ))
                                }
                            </ValidatedInput>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <FormGroup>
                            <Label htmlFor="termId">{t('editChild.courtesyClassCredit.term.label', 'Term')}</Label>
                            <ValidatedInput name="termId" type="select" value={model?.termId ?? ''} onChange={e => change({ termId: e.currentTarget.value })} onBlur={e => validate('termId')} validationErrors={validationErrors['termId']}>
                                <option value="">{t('editChild.courtesyClassCredit.pleaseSelect', '(Please select a term)')}</option>
                                {
                                    terms?.map(item => (
                                        <option key={item.id} value={item.id}>{item.name}</option>
                                    ))
                                }
                            </ValidatedInput>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col xs='auto'>
                        <FormGroup>
                            <Label htmlFor="name">&nbsp;</Label>
                            <div>
                                <Button color="danger" outline onClick={() => remove()}>
                                    <FontAwesomeIcon icon="trash-alt" />
                                    <> </>
                                    {t('editChild.courtesyClassCredit.delete', 'Delete credit')}
                                </Button>
                            </div>
                        </FormGroup>
                    </Col>
                </Row>
            </ListGroupItem>
        </>
    );
};