import { useMemo, useState } from "react";
import { ConditionalFragment } from "react-conditionalfragment";
import { useTranslation } from "react-i18next";
import { Button, ButtonGroup, Col, Nav, NavItem, Row, NavLink, TabContent, TabPane } from "reactstrap";
import { BlobReference } from "../../api/main/generated/graphql";
import { useConsumeLessonPlansList } from "../../api/main/lessonPlans/useConsumeLessonPlansList";
import { LessonDocument } from "../../api/main/models/LessonDocument";
import { AlertOnErrors } from "../../shared/alertOnErrors";
import { useSearchParams } from "../../shared/useURLSearchParams";
import { Banner } from "../shared/banner/Banner";
import { LoadingIndicator } from "../shared/loadingIndicator/LoadingIndicator";
import { MainContainer } from "../shared/mainContainer/MainContainer";
import { StickyToolbar } from "../shared/stickyToolbar/StickyToolbar";
import { LessonComponent } from "./LessonComponent";

export interface LessonPlanWithEvidenceBlobReference extends LessonDocument {
    blobReference?: BlobReference;
}

/**
 * List of all LessonDocuments for Staff
 */
export const LessonPlansList = () => {
    const { t } = useTranslation();

    // Load the data
    const {
        data: {
            lessonDocuments: _allItems,
            classTypes,
            classStages: allClassStages,
            classSubStages
        }, isLoading, errors: loadErrors,
    } = useConsumeLessonPlansList();

    // Combine all the loaded data so each item includes its related details.
    const allItems = useMemo(() => _allItems?.map(item => {
        const classStage = allClassStages.find(it => it.id === item.classStageId);
        const classSubStage = classSubStages.find(it => it.id === item.classSubStageId);
        const classType = classTypes.find(it => it.id === classStage?.classTypeId);

        return {
            ...item,

            classType,
            classStage,
            classSubStage
        };
    }), [_allItems, allClassStages, classSubStages, classTypes]);

    // Manage the tab, filter, and subStage being displayed
    const {
        classType: classTypeParam,
        stage: stageParam,
        subStage: subStageParam,
        lessonNumber: lessonNumberParam,
    } = useSearchParams();

    // Filters: ClassStages
    const [_filterClassTypes, setFilterClassTypes] = useState<string | null | undefined>(classTypeParam);
    const filterClassTypes = _filterClassTypes || classTypes?.find(it => true)?.id;

    // Filter stages to only those for the selected class type.
    const classStages = useMemo(() => allClassStages?.filter(item => item.classTypeId === filterClassTypes), [allClassStages, filterClassTypes]);

    // Handle changing between tabs
    const [_currentStage, setCurrentStage] = useState<string | undefined | null>(stageParam);
    const currentStage = _currentStage || classStages?.find(it => true)?.id;

    // Render the UI
    //
    return (
        <>
            <Banner>
                <StickyToolbar>
                    <Row>
                        <Col xs={12} sm="auto">
                            <h1>{t('staffLessonPlansList.title', 'Lesson plans')}</h1>
                        </Col>
                        <ConditionalFragment showIf={isLoading}>
                            <Col xs="auto">
                                <LoadingIndicator size="sm" />
                            </Col>
                        </ConditionalFragment>
                        <Col>
                            <></>
                        </Col>
                        <Col xs="auto">
                            <ButtonGroup>
                                {
                                    classTypes?.map(classType => (
                                        <Button key={classType.id} color="primary" outline={filterClassTypes !== classType.id} onClick={() => setFilterClassTypes(classType.id)}>
                                            {classType.name}
                                        </Button>
                                    ))
                                }
                            </ButtonGroup>
                        </Col>
                    </Row>
                </StickyToolbar>
            </Banner>
            <MainContainer>
                <AlertOnErrors
                    errors={loadErrors}
                />

                <Nav tabs>
                    {
                        classStages?.map(classStage => (
                            <NavItem key={classStage.id}>
                                <NavLink className={`${currentStage === classStage.id ? "active" : ""}`} onClick={() => setCurrentStage(classStage.id)}>
                                    {classStage.name}
                                </NavLink>
                            </NavItem>
                        ))
                    }
                </Nav>

                <TabContent activeTab={currentStage}>
                    {classStages?.map(classStage => (
                        <TabPane key={classStage.id} tabId={classStage?.id}>
                            {
                                classSubStages?.filter(it => it.classStageId === classStage.id).map(classSubStage => (
                                    
                                    <LessonComponent
                                        classStage={classStage}
                                        classSubStage={classSubStage}
                                        lessonDocuments={allItems.filter(it => it.classSubStageId === classSubStage?.id)}
                                        stageIsOpen={classSubStage?.id === subStageParam}
                                        key={classSubStage.id}
                                        scrollToLessonNumber={lessonNumberParam}
                                    />
                                ))
                            }
                        </TabPane>
                    ))}
                </TabContent>
            </MainContainer>
        </>
    );
};