import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ValidationErrors } from "pojo-validator";
import { ValidateCallback } from "pojo-validator-react";
import { ValidatedInput } from "pojo-validator-reactstrap";
import { useTranslation } from "react-i18next";
import { Button, Col, FormGroup, Label, ListGroupItem, Row } from "reactstrap";
import { RoleInClass, roleInClassDisplayName } from "../../../api/main/models/constants/RoleInClass";
import { Profile } from "../../../api/main/models/Profile";
import { ScheduledClassStaff } from "../../../api/main/models/ScheduledClassStaff";
import { ValidatedISODateTimeInput } from "../../shared/isoDateTimeInput/ValidatedISODateTimeInput";

export interface SubstituteTeacherComponentProps {
    model: ScheduledClassStaff | undefined,
    change: (changes: Partial<ScheduledClassStaff>) => void,
    remove: () => void,
    validate: ValidateCallback,
    validationErrors: ValidationErrors,
    profiles: Array<Profile> | undefined | null;
}

/**
 * Component used to Edit SubstituteTeachers for a ScheduledClass under ArrangeCover
 * @param props
 * @returns
 */
export const SubstituteTeacherComponent = (props: SubstituteTeacherComponentProps) => {
    ;
    const { model, change, remove, validate, validationErrors, profiles } = props;
    const { t } = useTranslation();

    // Render the UI
    //
    return (
        <>
            <ListGroupItem>
                <Row>
                    <Col>
                        <FormGroup>
                            <Label htmlFor="staffId">{t('common.name', 'Name')}</Label>
                            <ValidatedInput name="staffId" value={model?.staffId} type="select" onChange={e => change({ staffId: e.currentTarget.value })} onBlur={e => validate('staffId')} validationErrors={validationErrors["staffId"]} >
                                <option value="">{t('editScheduledClassStaffToArrangeCover.substituteTeacherComponent.primaryTeacher.pleaseSelect.select', '(Please select a substitue teacher)')}</option>
                                {profiles?.map(profile => (
                                    <option key={profile.id} value={profile.id}>{profile.firstName} {profile.lastName}</option>
                                ))}
                            </ValidatedInput>
                        </FormGroup>
                    </Col>

                    <Col>
                        <FormGroup>
                            <Label htmlFor="roleInClass">{t('editScheduledClassStaffToArrangeCover.substituteTeacherComponent.roleInClass.label', 'Role in class')}</Label>
                            <ValidatedInput name="roleInClass" value={model?.roleInClass} type="select" onChange={e => change({ roleInClass: e.currentTarget.value })} onBlur={e => validate('roleInClass')} validationErrors={validationErrors["roleInClass"]}>
                                <option value="">{t('editScheduledClassStaffToArrangeCover.substituteTeacherComponent.primaryTeacher.pleaseSelect.select', '(Please select a substitue teacher)')}</option>
                                <option value={RoleInClass.PrimaryTeacher}>{roleInClassDisplayName(RoleInClass.PrimaryTeacher, t)}</option>
                                <option value={RoleInClass.AdditionalTeacher}>{roleInClassDisplayName(RoleInClass.AdditionalTeacher, t)}</option>
                                <option value={RoleInClass.Coordinator}>{roleInClassDisplayName(RoleInClass.Coordinator, t)}</option>
                            </ValidatedInput>
                        </FormGroup>
                    </Col>

                    <Col md={2}>
                        <Label htmlFor="subsituteStartDate">{t('common.startDate', 'Start date')}</Label>
                        <ValidatedISODateTimeInput
                            name="subsituteStartDate"
                            type="date"
                            value={model?.subsituteStartDate}
                            onChange={e => change({ subsituteStartDate: e.currentTarget.value })}
                            onBlur={e => validate('substituteStartDate')}
                            validationErrors={validationErrors["subsituteStartDate"]} />
                    </Col>

                    <Col md={2}>
                        <Label htmlFor="subsituteEndDate">{t('common.endDate', 'End date')}</Label>
                        <ValidatedISODateTimeInput
                            name="subsituteEndDate"
                            type="date"
                            value={model?.subsituteEndDate}
                            onChange={e => change({ subsituteEndDate: e.currentTarget.value })}
                            onBlur={e => validate('subsituteEndDate')}
                            validationErrors={validationErrors["subsituteEndDate"]} />
                    </Col>
              
                    <Col xs='auto'>
                        <FormGroup>
                            <Label htmlFor="name">&nbsp;</Label>
                            <div>
                                <Button color="danger" outline onClick={() => remove()}>
                                    <FontAwesomeIcon icon="trash-alt" />
                                    <> </>
                                    {t('editScheduledClassStaffToArrangeCover.substituteTeacherComponent.delete', 'Remove substitute teacher')}
                                </Button>
                            </div>
                        </FormGroup>
                    </Col>
                </Row>
            </ListGroupItem>
        </>
    );
};