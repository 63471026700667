import * as React from 'react';
import { useLazyQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { AsyncLoadPagedResult, AsyncLoadPagedOptions } from '../../../../shared/abstractStore';
import { useFetchMoreCallback } from '../../../../shared/useFetchMoreCallback';
import { mainApiConfig } from '../../../../configure/mainApiConfig';
import { ChildAbsencesListViewModelQuery, ChildAbsencesListViewModelQueryVariables } from '../../generated/graphql';
import { classTypeFieldsFragment } from '../../models/ClassType';
import { classStageFieldsFragment } from '../../models/ClassStage';
import { classSubStageFieldsFragment } from '../../models/ClassSubStage';
import { classLocationFieldsFragment } from '../../models/ClassLocation';
import { termFieldsFragment } from '../../models/Term';
import { classLocationClassTypeFieldsFragment } from '../../models/ClassLocationClassType';
import { childAbsenceFieldsFragment } from '../../models/ChildAbsence';
import { scheduledClassFieldsFragment } from '../../models/ScheduledClass';
import { childFieldsFragment } from '../../models/Child';
import { childAbsenceTypeFieldsFragment } from '../../models/ChildAbsenceType';

export interface ChildAbsencesListViewModelAsyncLoadPagedOptions extends AsyncLoadPagedOptions {
}

/**
 * Get all data required for the ChildAbsencesList component from the api.
 * @param options
 */
export function useChildAbsencesListViewModel(options: ChildAbsencesListViewModelAsyncLoadPagedOptions = {}): AsyncLoadPagedResult<ChildAbsencesListViewModelQuery> {
    // Query data we need from the api.
    const [load, { data, loading, error, refetch, fetchMore: fetchMoreFromStore }] = useLazyQuery<ChildAbsencesListViewModelQuery, ChildAbsencesListViewModelQueryVariables>(
        gql`
        query childAbsencesListViewModelQuery ($offset: Int, $first: Int) {
            items: childAbsences (offset: $offset, first: $first) {
                ...childAbsenceFields
            }

            classTypes {
                ...classTypeFields
            }

            classStages {
                ...classStageFields
            }

            classSubStages {
                ...classSubStageFields
            }

            classLocations {
                ...classLocationFields
            }

            terms (restrictToTermsWithClasses: true) {
                ...termFields
            }

            classLocationClassTypes {
                ...classLocationClassTypeFields
            }

            scheduledClasses {
                ...scheduledClassFields
            }

            children {
                ...childFields
            }

            childAbsenceTypes {
                ...childAbsenceTypeFields
            }

            profiles {
                id
                userId
                firstName
                lastName
                archived
                primaryEmail
                secondaryEmail
                primaryPhone
                secondaryPhone
                addressLine1
                addressLine2
                city
                county
                postcode
                country
                genderId
                isStaff
                isMember
                
                user {
                  id
                  email
                }
            }
        }

        ${childAbsenceFieldsFragment}
        ${classTypeFieldsFragment}
        ${classStageFieldsFragment}
        ${classSubStageFieldsFragment}
        ${classLocationFieldsFragment}
        ${termFieldsFragment}
        ${classLocationClassTypeFieldsFragment}
        ${scheduledClassFieldsFragment}
        ${childFieldsFragment}
        ${childAbsenceTypeFieldsFragment}
        `,
        {

            variables: {
                offset: options.startOffset,
                first: options.pageSize,
            },
            fetchPolicy: options.fetchPolicy ?? mainApiConfig.defaultFetchPolicy,
            pollInterval: options.pollInterval,
            onCompleted: (data) => {
                if (options.onCompleted) {
                    options.onCompleted(data);
                }
            }
        }
    );

    // Run the query (unless we are being lazy).
    React.useEffect(() => {
        if (!options.lazy) {
            load();
        }
    }, [options.lazy, load]);

    // Get the functions used to manage paging.
    const { fetchMore, hasMore: _hasMore } = useFetchMoreCallback<ChildAbsencesListViewModelQuery>(
        fetchMoreFromStore,
        (options?.startOffset ?? 0) + (data?.items?.length ?? 0),
        (prevResults, newResults) => ({
            ...prevResults,
            ...newResults,

            items: [...(prevResults?.items ?? []), ...(newResults?.items ?? [])],
            classTypes: newResults?.classTypes ?? [],
            classStages: newResults?.classStages ?? [],
            classSubStages: newResults?.classSubStages ?? [],
            classLocations: newResults?.classLocations ?? [],
            terms: newResults?.terms ?? [],
            classLocationClassTypes: newResults?.classLocationClassTypes ?? [],
            scheduledClasses: newResults?.scheduledClasses ?? [],
            children: newResults?.children ?? [],
            childAbsenceTypes: newResults?.childAbsenceTypes ?? [],
            profiles: newResults?.profiles ?? [],
        }),
        (newResults) => newResults?.items?.length
    );
    const hasMore = React.useCallback(() => !options.pageSize ? false : _hasMore(), [options.pageSize, _hasMore]);

    // Return the results.
    const refresh = React.useCallback(async () => { !refetch ? await load() : await refetch(); }, [refetch, load]);
    return React.useMemo(() => ({
        data: data ?? ({} as any),
        refresh: refresh,
        isLoading: loading,
        errors: error,
        fetchMore: fetchMore,
        hasMore: hasMore,
    }), [data, refresh, loading, error, fetchMore, hasMore]);
}