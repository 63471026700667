import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import { useCallback, useEffect, useMemo, useState } from "react";
import { ConditionalFragment } from "react-conditionalfragment";
import { useTranslation } from "react-i18next";
import { LinkContainer } from "react-router-bootstrap";
import { useNavigate } from "react-router-dom";
import { Button, ButtonGroup, Card, CardBody, CardHeader, CardSubtitle, CardTitle, Col, Input, Row } from "reactstrap";
import { ClassLocation } from "../../api/main/models/ClassLocation";
import { ClassStage } from "../../api/main/models/ClassStage";
import { ClassSubStage } from "../../api/main/models/ClassSubStage";
import { ClassType } from "../../api/main/models/ClassType";
import { ScheduledClass } from "../../api/main/models/ScheduledClass";
import { ScheduledClassChild } from "../../api/main/models/ScheduledClassChild";
import { useScheduledClassesWithChildrenViewModel } from "../../api/main/scheduledClasses/viewModels/useScheduledClassesWithChildrenViewModel";
import { useCurrentTerm } from "../../api/main/terms/useCurrentTerm";
import { usePreferredListViewMode } from "../../globalState/preferredListViewMode/usePreferredListViewMode";
import { AlertOnErrors } from "../../shared/alertOnErrors";
import { useLocalStorage } from "../../shared/useLocalStorage";
import { useReplaceSearchParamsEffect, useSearchParams } from "../../shared/useURLSearchParams";
import { getScheduledClassSummary } from "../scheduledClass/getScheduledClassSummary";
import { ClassScheduleFilterState } from "../scheduledClass/schedule/ClassSchedule";
import { Banner } from "../shared/banner/Banner";
import { CardsOrTable } from "../shared/cardsOrTable/CardsOrTable";
import { CardsOrTableToggle } from "../shared/cardsOrTable/CardsOrTableToggle";
import { LoadingIndicator } from "../shared/loadingIndicator/LoadingIndicator";
import { MainContainer } from "../shared/mainContainer/MainContainer";
import { SearchInput } from "../shared/searchInput/SearchInput";
import { StickyToolbar } from "../shared/stickyToolbar/StickyToolbar";
import { TermSelector } from "../shared/termSelector/TermSelector";
import { HappyIcon } from "../shared/Utilities/HappyIcon";

export interface ScheduledClassWithLocationAndChildren extends ScheduledClass {
    classLocation?: ClassLocation,
    scheduledClassChildren?: Array<ScheduledClassChild>,
    classType?: ClassType,
    classStage?: ClassStage,
    classSubStage?: ClassSubStage,
}
/**
 * Component for ListingScheduledClasses for Cancellation
 */
export const CancelClassList = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    // Load the data
    const {
        data: {
            items: _items,
            classLocations,
            classTypes,
            classStages,
            classSubStages,
            terms
        }, isLoading: isLoadingData, errors: loadErrors
    } = useScheduledClassesWithChildrenViewModel();

    // Load the current term
    const {
        data: {
            model: currentTerm
        }, isLoading: isLoadingCurrentTerm, errors: currentTermLoadErrors
    } = useCurrentTerm();
    const isLoading = isLoadingData || isLoadingCurrentTerm;

    // Combine all the loaded data so that each item includes its related details
    const allItems = useMemo(() => _items?.map(item => {
        const classLocation = classLocations?.find(it => it.id === item.classLocationId);
        const classType = classTypes?.find(it => it.id === item.classTypeId);
        const classStage = classStages?.find(it => it.id === item.currentClassStageId);
        const classSubStage = classSubStages?.find(it => it.id === item.currentClassSubStageId);

        return {
            ...item,
            classLocation,
            classType,
            classStage,
            classSubStage,
        } as ScheduledClassWithLocationAndChildren;
    }), [_items, classLocations, classTypes, classStages, classSubStages]);

    // Use the preferred view mode for lists.
    const [viewMode] = usePreferredListViewMode();

    // Search.
    const { search: searchParam } = useSearchParams();
    const [search, setSearch] = useState<string>(searchParam ?? '');

    // Keep the URL up to date with the search text.
    useReplaceSearchParamsEffect({ search: search });

    // Allow filtering (persisted to local storage between sessions).
    const [filterState, setFilterState] = useLocalStorage<ClassScheduleFilterState>('classSchedule.filterState', {});
    const changeFilterState = useCallback((changes: Partial<ClassScheduleFilterState>) => setFilterState(prevState => ({
        ...prevState,
        ...changes,
    })), [setFilterState]);

    // Filter the items that can be filtered by other filter choices.
    const classStagesForFilter = useMemo(() => classStages?.filter(item => item.classTypeId === filterState.classTypeId), [classStages, filterState]);
    const classSubStagesForFilter = useMemo(() => classSubStages?.filter(item => item.classStageId === filterState.classStageId), [classSubStages, filterState]);

    // Filter by the search.
    const items = useMemo(() => {
        let ret = (allItems ?? []);

        let lowerSearch = search.toLocaleLowerCase();
        if (lowerSearch) {
            ret = ret.filter(item =>
                
                (item.classLocation?.name ?? '').toLocaleLowerCase().indexOf(lowerSearch) >= 0
                || (moment().day(item.dayOfWeek).format('dddd').toLocaleLowerCase() ?? '').indexOf(lowerSearch) >= 0
            );
        }

        if (filterState.classTypeId) {
            ret = ret?.filter(item => item.classTypeId === filterState.classTypeId);
        }

        if (filterState.classStageId) {
            ret = ret?.filter(item => item.currentClassStageId === filterState.classStageId);
        }

        if (filterState.classSubStageId) {
            ret = ret?.filter(item => item.currentClassSubStageId === filterState.classSubStageId);
        }

        if (filterState.classLocationId) {
            ret = ret?.filter(item => item.classLocationId === filterState.classLocationId);
        }

        if (filterState.termId) {
            ret = ret?.filter(item => item.termId === filterState.termId);
        }

        if (filterState.dayOfWeek) {
            ret = ret?.filter(item => item.dayOfWeek.toString() === filterState.dayOfWeek);
        }

        return ret;

    }, [allItems, search, filterState]);

    // Showing and hiding UI elements (persisted to local storage between sessions).
    const [isFiltersVisible, _setIsFiltersVisible] = useLocalStorage('cancelClasList.isFiltersVisible', false);
    const toggleFiltersVisible = useCallback(() => _setIsFiltersVisible(prevState => !prevState), [_setIsFiltersVisible]);
    const resetFilters = useCallback(() => setFilterState({ termId: currentTerm?.id }), [setFilterState, currentTerm?.id]);

    // Set the default filter state to the current term.
    useEffect(() => {
        if (!filterState.termId) {
            setFilterState({ termId: currentTerm?.id });
        }
    }, [filterState, currentTerm?.id, setFilterState]);

    // Render the UI
    //
    return (
        <>
            <Banner fluid>
                <StickyToolbar>
                    <Row>
                        <Col xs={12} sm="auto">
                            <h1>
                                {t('cancelClasList.title', 'Cancel a class')}
                            </h1>
                            <h4>{terms?.find(it => it.id === filterState?.termId)?.name}</h4>
                        </Col>
                        <Col>
                            <Button color="primary" outline={!isFiltersVisible} onClick={() => toggleFiltersVisible()}>
                                <FontAwesomeIcon icon="filter" />
                                <> </>
                                {t('cancelClasList.toggleFilters', 'Filter')}
                            </Button>
                        </Col>
                        <ConditionalFragment showIf={isLoading}>
                            <Col xs="auto">
                                <LoadingIndicator size="sm" />
                            </Col>
                        </ConditionalFragment>
                        <Col xs="auto">
                            <CardsOrTableToggle />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <SearchInput value={search} onChange={e => setSearch(e.currentTarget.value)} />
                        </Col>
                    </Row>
                </StickyToolbar>
            </Banner>

            <MainContainer fluid>
                <AlertOnErrors
                    errors={[
                        loadErrors,
                        currentTermLoadErrors
                    ]}
                />

                {/* Filters */}
                <ConditionalFragment showIf={isFiltersVisible}>
                    <Card className="mb-2">
                        <CardHeader>
                            <h5>{t('cancelClasList.filters.heading', 'Filter')}</h5>
                        </CardHeader>
                        <CardBody>
                            <Row>
                                <Col xs="auto">
                                    <TermSelector selectedTermId={filterState.termId} setFilterState={({ termId: value }) => setFilterState({ termId: value })} restrictToTermsWithClasses={true} />
                                </Col>
                                <Col xs="auto">
                                    <Input type="select" value={filterState.dayOfWeek ?? ''} onChange={e => changeFilterState({ dayOfWeek: e.currentTarget.value, })}>
                                        <option value="">{t('cancelClasList.filter.dayOfWeek.all', '(All days)')}</option>
                                        <option value="0">{t('cancelClasList.filter.dayOfWeek.sunday', 'Sunday')}</option>
                                        <option value="1">{t('cancelClasList.filter.dayOfWeek.monday', 'Monday')}</option>
                                        <option value="2">{t('cancelClasList.filter.dayOfWeek.tuesday', 'Tuesday')}</option>
                                        <option value="3">{t('cancelClasList.filter.dayOfWeek.wednesday', 'Wednesday')}</option>
                                        <option value="4">{t('cancelClasList.filter.dayOfWeek.thursday', 'Thursday')}</option>
                                        <option value="5">{t('cancelClasList.filter.dayOfWeek.friday', 'Friday')}</option>
                                        <option value="6">{t('cancelClasList.filter.dayOfWeek.saturday', 'Saturday')}</option>
                                    </Input>
                                </Col>
                                <Col>
                                    <Input type="select" value={filterState.classTypeId ?? ''} onChange={e => changeFilterState({ classTypeId: e.currentTarget.value, classStageId: '', classSubStageId: '', })}>
                                        <option value="">{t('cancelClasList.filter.classTypes.all', '(All activities)')}</option>
                                        {
                                            classTypes?.map(item => (
                                                <option key={item.id} value={item.id}>{item.name}</option>
                                            ))
                                        }
                                    </Input>
                                </Col>
                                <ConditionalFragment showIf={!!filterState.classTypeId}>
                                    <Col>
                                        <Input type="select" value={filterState.classStageId ?? ''} onChange={e => changeFilterState({ classStageId: e.currentTarget.value, classSubStageId: '', })}>
                                            <option value="">{t('cancelClasList.filter.classStages.all', '(All class types)')}</option>
                                            {
                                                classStagesForFilter?.map(item => (
                                                    <option key={item.id} value={item.id}>{item.name}</option>
                                                ))
                                            }
                                        </Input>
                                    </Col>
                                </ConditionalFragment>
                                <ConditionalFragment showIf={!!filterState.classStageId}>
                                    <Col>
                                        <Input type="select" value={filterState.classSubStageId ?? ''} onChange={e => changeFilterState({ classSubStageId: e.currentTarget.value })}>
                                            <option value="">{t('cancelClasList.filter.classSubStages.all', '(All stages)')}</option>
                                            {
                                                classSubStagesForFilter?.map(item => (
                                                    <option key={item.id} value={item.id}>{item.name}</option>
                                                ))
                                            }
                                        </Input>
                                    </Col>
                                </ConditionalFragment>
                                <Col>
                                    <Input type="select" value={filterState.classLocationId ?? ''} onChange={e => changeFilterState({ classLocationId: e.currentTarget.value })}>
                                        <option value="">{t('cancelClasList.filter.classLocations.all', '(All locations)')}</option>
                                        {
                                            classLocations?.map(item => (
                                                <option key={item.id} value={item.id}>{item.name}</option>
                                            ))
                                        }
                                    </Input>
                                </Col>
                            </Row>
                            <Button color="link" onClick={resetFilters}>
                                {t('cancelClasList.filters.reset', 'Reset filters')}
                            </Button>
                        </CardBody>
                    </Card>
                </ConditionalFragment>

                <CardsOrTable
                    viewMode={viewMode}
                    items={items}
                    tableHeadings={[
                        null /* Handles the card specific function for which we don't want a table column. */,
                        t('cancelClassList.name.heading', 'Class summary'),
                        t('cancelClassList.registered.heading', 'Registered'),
                        t('cancelClassList.classType.heading', 'Activity')
                    ]}
                    columns={[
                        (item, view) => {
                            // This column doesn't show in the table.
                            if (view !== 'cards') {
                                return null;
                            }

                            return (
                                <>
                                    <CardTitle>
                                        <HappyIcon icon="calendar" />
                                        <> </>
                                        {getScheduledClassSummary(item, { classLocation: item.classLocation, classStage: item.classStage, classSubStage: item.classSubStage })}
                                    </CardTitle>
                                    <CardSubtitle>
                                        <>
                                            <HappyIcon icon="people" />
                                            <> </>
                                            {item?.scheduledClassChildren?.filter(it => it.isOnWaitingList === false).length}
                                            /
                                            {item.maximumClassSize}
                                        </>
                                    </CardSubtitle>
                                </>
                            );
                        },

                        // The remaining columns are for table mode only.

                        // Name
                        (item, view) => {
                            if (view !== 'table') {
                                return null;
                            }

                            return getScheduledClassSummary(item, { classLocation: item.classLocation, classStage: item.classStage, classSubStage: item.classSubStage });
                        },

                        // Registered
                        (item, view) => {
                            if (view !== 'table') {
                                return null;
                            }

                            return (
                                <>
                                    {item?.scheduledClassChildren?.filter(it => it.isOnWaitingList === false).length}
                                    /
                                    {item.maximumClassSize}
                                </>
                            );

                        },

                        // ClassType
                        (item, view) => {
                            if (view !== 'table') {
                                return;
                            }

                            return item.classType?.name;
                        }
                    ]}

                    buttons={(item) => (
                        <ButtonGroup>
                            <LinkContainer to={`cancel/${item.id}`}>
                                <Button color="primary">
                                    <FontAwesomeIcon icon="calendar-xmark" />
                                    <> {t('common.cancel', 'Cancel')}</>
                                </Button>
                            </LinkContainer>
                        </ButtonGroup>
                    )}
                    onItemClick={item => navigate(`cancel/${item.id}`)}
                />
            </MainContainer>
        </>
    );
};