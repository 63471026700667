import { gql, useLazyQuery } from "@apollo/client";
import React, { useMemo } from "react";
import { mainApiConfig } from "../../../../configure/mainApiConfig";
import { AsyncLoadOptions, AsyncLoadResult } from "../../../../shared/abstractStore";
import { ReportDataSourceServiceForQueryMessagesViewModelQuery, ReportDataSourceServiceForQueryMessagesViewModelQueryVariables } from "../../generated/graphql";


export function useReportDataSourceServiceForQueryMessagesViewModel(startDate: string | undefined | null, endDate: string | undefined | null, options: AsyncLoadOptions = {}): AsyncLoadResult<ReportDataSourceServiceForQueryMessagesViewModelQuery> {
    // Query the data we need from the api
    const [load, { data, loading, error, refetch }] = useLazyQuery<ReportDataSourceServiceForQueryMessagesViewModelQuery, ReportDataSourceServiceForQueryMessagesViewModelQueryVariables>(
        gql`
        query reportDataSourceServiceForQueryMessagesViewModelQuery ($startDate: String!, $endDate: String!) {
            queryMessagesData: queryMessagesDashboardViewModel (startDate: $startDate, endDate: $endDate) {
                dateFrom
                dateTo
                numberOfDays
                repliedWithinOneDay
                repliedWithinTwoDays
                repliedWithinThreeDays
                repliedWithinSevenDays
                repliedLongerThanSevenDays
                noReply
            }
        }
        `,
        {
            variables: {
                startDate: startDate ?? '',
                endDate: endDate ?? ''
            },
            fetchPolicy: options.fetchPolicy ?? mainApiConfig.defaultFetchPolicy,
            pollInterval: options.pollInterval,
            onCompleted: (data) => {
                if (options.onCompleted) {
                    options.onCompleted(data);
                }
            }
        }
    );

    // Run the query (unless we are being lazy).
    React.useEffect(() => {
        if (!options.lazy) {
            load();
        }
    }, [options.lazy, load]);

    // Return the results.
    const refresh = React.useCallback(async () => { !refetch ? await load() : await refetch(); }, [refetch, load]);
    return useMemo(() => ({
        data: data ?? ({} as any),
        refresh: refresh,
        isLoading: loading,
        errors: error,
    }), [data, refresh, loading, error,]);
};