import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Col, NavItem, NavLink, Row } from "reactstrap";
import { IdentityRoles } from "../../configure/security/IdentityRoles";
import { AuthorizeContainer } from "../../shared/authorizeRoute";
import { useSearchParams } from "../../shared/useURLSearchParams";
import { Banner } from "../shared/banner/Banner";
import { MainContainer } from "../shared/mainContainer/MainContainer";
import { PillsNavBar } from "../shared/pillsNavBar/PillsNavBar";
import { StickyToolbar } from "../shared/stickyToolbar/StickyToolbar";
import { ClassSizesTab } from "./classSizesTab/ClassSizesTab";
import { PaymentsTab } from "./paymentsTab/PaymentsTab";
import { QueriesTab } from "./queriesTab/QueriesTab";
import { ReenrolmentsTab } from "./reenrolmentsTab/ReenrolmentsTab";

/**
 * Dashboard to display key metrics about the current and future terms
 */
export const Dashboard = () => {
    const { t } = useTranslation();

    // Manage the tab being displayed.  If we have tab= on the query string, default to it, otherwise default to the main tab.
    const { tab: tabParam } = useSearchParams();
    // Manage the tab being displayed
    const [activeTab, setActiveTab] = useState<'class-sizes' | 're-enrolments' | 'payments' | 'queries'>(tabParam as any || 'class-sizes');

    // Render the UI
    //
    return (
        <>
            <Banner fluid>
                <StickyToolbar>
                    <Row>
                        <Col>
                            <h1>
                                {t('dashboard.title', 'Dashboard')}
                            </h1>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <PillsNavBar>
                                <NavItem>
                                    <NavLink active={activeTab === 'class-sizes'} onClick={() => setActiveTab('class-sizes')}>
                                        {t('dashboard.nav.classSizes', "Class sizes")}
                                    </NavLink>
                                </NavItem>

                                <NavItem>
                                    <NavLink active={activeTab === 're-enrolments'} onClick={() => setActiveTab('re-enrolments')}>
                                        {t('dashboard.nav.reenrolments', "Enrolments / Re-enrolments")}
                                    </NavLink>
                                </NavItem>

                                <AuthorizeContainer requireRole={IdentityRoles.AdminAndFinance}>
                                    <NavItem>
                                        <NavLink active={activeTab === 'payments'} onClick={() => setActiveTab('payments')}>
                                            {t('dashboard.nav.payments', "Payments")}
                                        </NavLink>
                                    </NavItem>
                                </AuthorizeContainer>

                                <NavItem>
                                    <NavLink active={activeTab === 'queries'} onClick={() => setActiveTab('queries')}>
                                        {t('dashboard.nav.queries', "Queries")}
                                    </NavLink>
                                </NavItem>
                            </PillsNavBar>
                        </Col>
                    </Row>
                </StickyToolbar>
            </Banner>

            <MainContainer fluid>
                {/* Active tab */}
                {
                    activeTab === 'class-sizes' ? (
                        <ClassSizesTab />
                    ) : activeTab === 're-enrolments' ? (
                        <ReenrolmentsTab />
                    ) : activeTab === 'payments' ? (
                        <PaymentsTab />
                    ) : activeTab === 'queries' ? (
                        <QueriesTab />
                    ) : null
                }
            </MainContainer>
        </>
    );
};