import { ConditionalFragment } from "react-conditionalfragment";
import { useTranslation } from "react-i18next";
import { Button } from "reactstrap";
import { LessonPlanWithEvidenceBlobReference } from "./LessonPlansList";

export interface LessonDocumentComponentProps {
    lessonDocument: LessonPlanWithEvidenceBlobReference | null | undefined,
    isSupportingDocument?: boolean,
}

/**
 * Component used to render a single document url
 */
export const LessonDocumentComponent = (props: LessonDocumentComponentProps) => {
    const { lessonDocument, isSupportingDocument } = props;
    const { t } = useTranslation();

    // Open LessonDocument
    const openLessonDocument = (id: string | undefined) => {
        if (!id) { return; }

        // Open in a new window.
        window.open(`/api/blobs/redirectToUrl/${id}`);
    };
   
    // Render the UI
    //
    return (
        <>
            {/*  Renders any supporting documents */}
            <ConditionalFragment showIf={!!isSupportingDocument}>
                    <Button color="link" onClick={() => openLessonDocument(lessonDocument?.blobReference?.id)}>
                        {lessonDocument?.name}
                    </Button>
            </ConditionalFragment>

            {/* Render the lesson plan */}
            <ConditionalFragment showIf={!isSupportingDocument}>
                <Button className="fw-bold" color="link" onClick={() => openLessonDocument(lessonDocument?.blobReference?.id)}>
                    {t('staffLessonPlansList.lessonDocumentComponent.viewLessonPlan', 'View lesson plan')}
                </Button>
            </ConditionalFragment>
                   
        </>
    );
};