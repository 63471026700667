import gql from 'graphql-tag';
import { Guid } from 'guid-string';
import { QueryMessageTypeFieldsFragment } from '../generated/graphql';

export const queryMessageTypeFieldsFragment = gql`
    fragment queryMessageTypeFields on QueryMessageType {
        id
        name
        displayOrder
        archived
    }
`;

export type QueryMessageType = Omit<QueryMessageTypeFieldsFragment, '__typename'>;

/**
 * Default values.
 */
export const queryMessageTypeDefaultValues = (): Partial<QueryMessageType> => ({
    id: Guid.newGuid(),
    name: '',
    displayOrder: 99,
    archived: false,
});