import * as React from 'react';
import { Navigate } from 'react-router-dom';
import { isNullOrUndefined } from 'util';
import { useAuthenticatedState } from '../../api/api-authorization/useAuthenticatedState';
import { useCurrentUserRoles } from '../../api/account';
import { useTranslation } from 'react-i18next';

export interface AuthorizeRouteElementWrapperProps {
    requireRole?: string | Array<string>,
    element: any,
}

/**
 * Route component that will check for authorization before showing its component.
 * @param props
 */
export const AuthorizeRouteElementWrapper = (props: AuthorizeRouteElementWrapperProps) => {
    const {
        requireRole,
        element,
    } = props;

    const { isAuthenticated } = useAuthenticatedState();
    const { data: { roles: usersRoles } } = useCurrentUserRoles();
    const [hasRequiredRole, setHasRequiredRole] = React.useState<boolean | null>(); 

    const { t } = useTranslation();

    // Load the roles only if we need to.
    React.useEffect(() => {
        if (requireRole && !isNullOrUndefined(usersRoles)) {
            const requireRoles = [requireRole].flat(2);
            let hasRole = false;
            for (let roleToCheck of requireRoles) {
                if (usersRoles.find(it => it === roleToCheck)) {
                    hasRole = true;
                    break;
                }
            }
            setHasRequiredRole(hasRole);
        }
    }, [requireRole, usersRoles, setHasRequiredRole, isAuthenticated]);

    // Show nothing until we have a defined authenticated state.
    if (isNullOrUndefined(isAuthenticated)) {
        return (<></>);
    }
    
    // If we need to check a role, but can't yet return nothing.
    if (props.requireRole && isNullOrUndefined(usersRoles)) {
        return (<></>);
    }

    // If we don't have the role we require, show text saying so.
    if (hasRequiredRole === false && isAuthenticated) {
        return (<>{t('authorizeRoute.notAllowed', 'Sorry you do not have permission to access this area.')}</>);
    }

    // If we are not authenticated, send to the login page.
    if (!isAuthenticated) {
        const redirectUrl = `/authentication/login?returnUrl=${encodeURIComponent(window.location.href)}`
        return (<Navigate to={redirectUrl} replace />);
    }

    // Render the actual element.
    return element;
};
