import gql from "graphql-tag";
import { Guid } from "guid-string";
import { AmenityTypeFieldsFragment } from '../generated/graphql';

export const amenityTypeFieldsFragment = gql`
    fragment amenityTypeFields on AmenityType {
            id
            name
            displayOrder
            archived
        }
`;

export type AmenityType = Omit<AmenityTypeFieldsFragment, "__typename">;

/**
 * Default values.
 */
export const amenityTypeDefaultValues = (): Partial<AmenityType> => ({
    id: Guid.newGuid(),
    name: undefined,
    displayOrder: undefined,
    archived: false,
});