import { TFunction } from "i18next";


/**
 * State of a payment, used by the PaymentState field of the Payment model.
 */
export enum PaymentState {
    Unpaid = "Unpaid",
    PartialPaid = "PartialPaid",
    Paid = "Paid",
    Failed = "Failed",
}

/**
 * Returns all PaymentState options, suitable for use in a list.
 */
export function getPaymentStates(): Array<PaymentState> {
    const ret = Object.keys(PaymentState).map(key => key as PaymentState);
    return ret;
}

/**
 * Convert a PaymentState to its display name. 
 */
export function paymentStateDisplayName(name: PaymentState | string, t: TFunction): string {
    switch (name) {
        case PaymentState.Unpaid: return t('paymentState.unpaid.name', 'Not yet paid');
        case PaymentState.PartialPaid: return t('paymentState.partialPaid.name', 'Partially paid');
        case PaymentState.Paid: return t('paymentState.paid.name', 'Paid');
        case PaymentState.Failed: return t('paymentState.failed.name', 'Payment failed');
        default: return t('paymentState.unpaid.name', 'Not yet paid');
    }
}
