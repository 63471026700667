import gql from 'graphql-tag';
import { Guid } from 'guid-string';
import moment from 'moment';
import { EmergencyContactFieldsFragment } from '../generated/graphql';

export const emergencyContactFieldsFragment = gql`
    fragment emergencyContactFields on EmergencyContact {
        id
        targetId
        targetType
        firstName
        lastName
        archived
        displayOrder
        primaryEmail
        secondaryEmail
        primaryPhone
        secondaryPhone
        childsRelationshipId
        photoBlobReferenceId
    }
`;

export type EmergencyContact = Omit<EmergencyContactFieldsFragment, '__typename'>;

/**
 * Default values.
 */
export const emergencyContactDefaultValues = (): Partial<EmergencyContact> => ({
    id: Guid.newGuid(),
    firstName: '',
    lastName: '',
    archived: false,
    displayOrder: moment().unix(),
    primaryEmail: '',
    secondaryEmail: '',
    primaryPhone: '',
    secondaryPhone: '',
    childsRelationshipId: undefined,
    photoBlobReferenceId: null,
});