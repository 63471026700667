import { gql, useLazyQuery } from "@apollo/client";
import { Guid } from "guid-string";
import React, { useMemo } from "react";
import { mainApiConfig } from "../../../../configure/mainApiConfig";
import { AsyncLoadOptions, AsyncLoadResult } from "../../../../shared/abstractStore";
import { ReportDataSourceServiceForClassSizesViewModelQuery, ReportDataSourceServiceForClassSizesViewModelQueryVariables } from "../../generated/graphql";
import { classLocationFieldsFragment } from "../../models/ClassLocation";
import { classStageFieldsFragment } from "../../models/ClassStage";
import { classSubStageFieldsFragment } from "../../models/ClassSubStage";
import { classTypeFieldsFragment } from "../../models/ClassType";


export function useReportDataSourceServiceForClassSizesViewModel(termId: string | undefined | null, classTypeId?: string | undefined | null, classStageId?: string | undefined | null, classLocationId?: string | undefined | null, options: AsyncLoadOptions = {}): AsyncLoadResult<ReportDataSourceServiceForClassSizesViewModelQuery> {
    // Query the data we need from the api
    const [load, { data, loading, error, refetch }] = useLazyQuery<ReportDataSourceServiceForClassSizesViewModelQuery, ReportDataSourceServiceForClassSizesViewModelQueryVariables>(
        gql`
        query reportDataSourceServiceForClassSizesViewModelQuery ($termId: ID!, $classTypeId: ID, $classStageId: ID, $classLocationId: ID) {
            classSizesData: classSizesDashboardViewModel (termId: $termId, classTypeId: $classTypeId, classStageId: $classStageId, classLocationId: $classLocationId) {
                classDetails
                spaces
                attending
                waitingList
                classUtilizationPercentage
                classUtilization
            }

            classTypes {
                ...classTypeFields
            }

            classStages {
                ...classStageFields
            }

            classSubStages {
                ...classSubStageFields
            }

            classLocations {
                ...classLocationFields
            }
        }

        ${classTypeFieldsFragment}
        ${classStageFieldsFragment}
        ${classSubStageFieldsFragment}
        ${classLocationFieldsFragment}
        `,
        {
            variables: {
                termId: termId ?? Guid.empty,
                classTypeId: classTypeId,
                classStageId: classStageId,
                classLocationId: classLocationId
            },
            fetchPolicy: options.fetchPolicy ?? mainApiConfig.defaultFetchPolicy,
            pollInterval: options.pollInterval,
            onCompleted: (data) => {
                if (options.onCompleted) {
                    options.onCompleted(data);
                }
            }
        }
    );

    // Run the query (unless we are being lazy).
    React.useEffect(() => {
        if (!options.lazy) {
            load();
        }
    }, [options.lazy, load]);

    // Return the results.
    const refresh = React.useCallback(async () => { !refetch ? await load() : await refetch(); }, [refetch, load]);
    return useMemo(() => ({
        data: data ?? ({} as any),
        refresh: refresh,
        isLoading: loading,
        errors: error,
    }), [data, refresh, loading, error,]);
};