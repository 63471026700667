import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ValidationErrors } from "pojo-validator";
import { ValidateCallback } from "pojo-validator-react";
import { ValidatedInput } from "pojo-validator-reactstrap";
import { useTranslation } from "react-i18next";
import { useAsyncCallback } from "react-use-async-callback";
import { Button, ButtonGroup, Col, FormGroup, Label, ListGroupItem, Row, Spinner } from "reactstrap";
import { ButtonAsync } from "reactstrap-buttonasync";
import { BlobUploadService } from "../../../../api/main/blobReferences/BlobUploadService";
import { useBlobReference } from "../../../../api/main/blobReferences/useBlobReference";
import { LessonDocument } from "../../../../api/main/models/LessonDocument"
import { AlertOnErrors } from "../../../../shared/alertOnErrors";
import { FileUploadButton } from "../../../shared/fileUploadButton/FileUploadButton";
import { UploadedFilePreview } from "../../../shared/uploadedFilePreview/UploadedFilePreview";

export interface ClassStageLessonDocumentUploadComponentProps {
    lessonDocument: LessonDocument | null | undefined,
    change: (changes: Partial<LessonDocument>) => void;
    remove: () => void,

    validate: ValidateCallback,
    validationErrors: ValidationErrors;
}

/**
 * Component under ClassStages used for adding / updating LessonDocuments
 */
export const ClassStageLessonDocumentUploadComponent = (props: ClassStageLessonDocumentUploadComponentProps) => {
    const { lessonDocument, change, remove, validate, validationErrors } = props;
    const { t } = useTranslation();

    // Contents file uploading
    const { data: { model: contentsFile }, errors: contentsFileLoadErrors } = useBlobReference(lessonDocument?.blobReferenceId);

    const [onUploadContentsFile, { errors: contentsFileUploadErrors, isExecuting: isUploadingContentsFile, }] = useAsyncCallback(async (files: FileList | null) => {
        if (!files) {
            return;
        }

        let uploadService: BlobUploadService = new BlobUploadService("/api/blobs");
        let result = await uploadService.upload(files);

        if (!!result) {
            change({ blobReferenceId: result.id });
        }
    }, [change]);
    const [clearContentsFile, { isExecuting: isClearingContentsFile, }] = useAsyncCallback(async () => {
        change({ blobReferenceId: null });
    }, [change]);

    // Render the UI
    //
    return (
        <>
            <AlertOnErrors errors={[
                contentsFileLoadErrors,
                contentsFileUploadErrors
            ]} />

            <ListGroupItem>
                <Row>
                    <Col>
                        <FormGroup>
                            <Label htmlFor="name">{t('editClassStage.lessonDocumentUploadComponent.name.label', 'Name')}</Label>
                            <ValidatedInput name="name" type="text" value={lessonDocument?.isLessonPlan ? lessonDocument?.name : lessonDocument?.name ?? ''} onChange={e => change({ name: e.currentTarget.value })} disabled={lessonDocument?.isLessonPlan} onBlur={e => validate('name')} validationErrors={validationErrors['name']} />
                        </FormGroup>
                    </Col>

                    <Col>
                        <FormGroup>
                            <Label htmlFor="fileUpload">{t('editClassStage.lessonDocumentUploadComponent.fileUpload.label', 'Upload file')}</Label>
                            <Row>
                                <Col>
                                    <UploadedFilePreview blobReference={contentsFile} />
                                    <Row>
                                        <Col>
                                            <ButtonGroup>
                                                <FileUploadButton
                                                    color={`primary`}
                                                    isExecuting={isUploadingContentsFile}
                                                    executingChildren={<><Spinner size="sm" /> {t('common.uploading', 'Uploading...')}</>}
                                                    onUpload={onUploadContentsFile}
                                                    outline={false}>
                                                    {t('editClassStage.lessonDocumentUploadComponent.uploadButtonText', 'Upload a file')}
                                                </FileUploadButton>
                                                <ButtonAsync color="primary"
                                                    outline
                                                    isExecuting={isClearingContentsFile}
                                                    type="button"
                                                    onClick={clearContentsFile}
                                                    executingChildren={<><Spinner size="sm" /> {t('editClassStage.lessonDocumentUploadComponent.clearingFile', 'Clearing file...')}</>}>
                                                    {t('editClassStage.lessonDocumentUploadComponent.clearUploadButton', 'Clear file')}
                                                </ButtonAsync>
                                            </ButtonGroup>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col xs='auto'>
                                    <FormGroup>
                                        <Label htmlFor="name">&nbsp;</Label>
                                        <div>
                                            <Button color="danger" outline onClick={() => remove()}>
                                                <FontAwesomeIcon icon="trash-alt" />
                                                <> </>
                                                {t('editClassStage.lessonDocumentUploadComponent.delete', 'Delete document')}
                                            </Button>
                                        </div>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </FormGroup>
                    </Col>
                </Row>
            </ListGroupItem>
        </>
    );
}