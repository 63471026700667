import { useMemo, useState } from "react";
import { ConditionalFragment } from "react-conditionalfragment";
import { useTranslation } from "react-i18next"
import { Col, Row, Table } from "reactstrap";
import { useCurrentUserId } from "../../../api/account";
import { StaffSubmissionAcceptanceState } from "../../../api/main/models/constants/StaffSubmissonAcceptanceState";
import { Profile } from "../../../api/main/models/Profile";
import { StaffQualificationCheck } from "../../../api/main/models/StaffQualificationCheck";
import { useSaveStaffQualificationCheckMutation } from "../../../api/main/staffQualificationChecks/useSaveStaffQualificationCheckMutation";
import { useApproveStaffQualificationsListViewModel } from "../../../api/main/staffs/viewModels/useApproveStaffQualificationsListViewModel";
import { AlertOnErrors } from "../../../shared/alertOnErrors";
import { useChangesArray } from "../../../shared/useChanges";
import { useSearchParams } from '../../../shared/useURLSearchParams';
import { Banner } from "../../shared/banner/Banner";
import { LoadingIndicator } from "../../shared/loadingIndicator/LoadingIndicator";
import { MainContainer } from "../../shared/mainContainer/MainContainer";
import { NoResultsFound } from "../../shared/noResultsFound/NoResultsFound";
import { SearchInput } from "../../shared/searchInput/SearchInput";
import { StickyToolbar } from "../../shared/stickyToolbar/StickyToolbar";
import { ApproveQualificationItem } from "./ApproveQualificationItem";

export interface StaffQualificationCheckWithProfile extends StaffQualificationCheck {
    profile?: Profile;
}

/**
 * List of StaffQualifications awaiting approval
 */
export const ApproveQualificationsList = () => {
    const { t } = useTranslation();

    // Current userId - used for marking approvedByStaffUserId on StaffQualificationCheck requests
    const currentUserId = useCurrentUserId();

    // Load the data
    const {
        data: {
            items: storeItems,
            profiles,
        }, isLoading, errors: loadErrors,
    } = useApproveStaffQualificationsListViewModel(StaffSubmissionAcceptanceState.Submitted); // We can pass in an acceptanceState here: 'Submitted', 'Rejected', etc

    // Wrap a manager around _allItems so we can make changes to it in memory.
    const staffQualificationManager = useChangesArray<StaffQualificationCheck, string>(storeItems, item => item.id);
    const inMemoryItems = staffQualificationManager.model.filter(item => item.acceptanceState === StaffSubmissionAcceptanceState.Submitted);

    // Combine all the loaded data so that each item includes its related details
    const allItems = useMemo(() => inMemoryItems?.map(item => {
        const profile = profiles?.find(it => it.id === item.staffId);
        
        return {
            ...item,
            profile
        };
    }), [inMemoryItems, profiles]);

    // Search.
    const { search: searchParam } = useSearchParams();
    const [search, setSearch] = useState<string>(searchParam ?? '');

    // Filter by the search
    const items = useMemo(() => {
        let ret = (allItems ?? []);

        // Filter by the user's search
        let lowerSearch = search.toLocaleLowerCase();
        if (lowerSearch) {
            ret = ret.filter(item =>
                item.name.toLocaleLowerCase().indexOf(lowerSearch) >= 0
                || (item.profile?.firstName ?? '').toLocaleLowerCase().indexOf(lowerSearch) >= 0
                || (item.profile?.lastName ?? '').toLocaleLowerCase().indexOf(lowerSearch) >= 0
            )
        }

        // Sort the items by the SubmittedDate (Oldest at the top)
        ret = ret.sort((a, b) => {
            return new Date(a.submittedDate).getTime() - new Date(b.submittedDate).getTime();
        })

        return ret;
    }, [allItems, search]);

    // Update StaffQualification
    const [updateStaffQualificationCheck] = useSaveStaffQualificationCheckMutation();

    // Render the UI
    //
    return (
        <>
            <Banner fluid>
                <StickyToolbar>
                    <Row>
                        <Col>
                            <h1>
                                {t('approveQualificationsList.title', 'Approve qualifications')}
                            </h1>
                        </Col>
                        <ConditionalFragment showIf={isLoading}>
                            <Col xs="auto">
                                <LoadingIndicator size="sm" />
                            </Col>
                        </ConditionalFragment>
                    </Row>
                    <Row>
                        <Col>
                            <SearchInput value={search} onChange={e => setSearch(e.currentTarget.value)} />
                        </Col>
                    </Row>
                </StickyToolbar>
            </Banner>

            <MainContainer fluid>
                <AlertOnErrors
                    errors={[loadErrors]}
                />

                <Table responsive striped>
                    <thead>
                        <tr>
                            <th>{t('approveQualificationsList.qualification.heading', 'Qualification')}</th>
                            <th>{t('approveQualificationsList.submittedDate.heading', 'Submitted date')}</th>
                            <th>{t('approveQualificationsList.staffName.heading', 'Staff name')}</th>
                            <th>{t('approveQualificationsList.document.heading', 'Document')}</th>
                            <th>{t('approveQualificationsList.actions.heading', 'Actions')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            items?.map(item => (
                                <ApproveQualificationItem
                                    model={item}
                                    staffQualificationManager={staffQualificationManager}
                                    currentUserId={currentUserId}
                                    updateStaffQualificationCheck={updateStaffQualificationCheck}
                                    key={item.id}
                                />
                            ))
                        }
                    </tbody>
                </Table>

                <ConditionalFragment showIf={isLoading && !items?.length}>
                    <LoadingIndicator fullWidth />
                </ConditionalFragment>
                <ConditionalFragment showIf={!isLoading && !items?.length}>
                    <NoResultsFound search={search} />
                </ConditionalFragment>
            </MainContainer>
        </>
    );
}