import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import { useCallback, useMemo, useState } from "react";
import { ConditionalFragment } from "react-conditionalfragment";
import { useTranslation } from "react-i18next";
import { Badge, Button, ButtonGroup, ModalBody, ModalFooter, ModalHeader, Spinner } from "reactstrap";
import { ButtonAsync } from "reactstrap-buttonasync";
import { useAddToRegisterModalViewModel } from "../../../api/main/scheduledClassChildren/viewModels/useAddToRegisterModalViewModel";
import { AlertOnErrors } from "../../../shared/alertOnErrors";
import { getAgeFromDateOfBirth } from "../../../utilities/getAgeFromDateOfBirth";
import { CardsOrTable } from "../../shared/cardsOrTable/CardsOrTable";
import { LoadingIndicator } from "../../shared/loadingIndicator/LoadingIndicator";
import { NoResultsFound } from "../../shared/noResultsFound/NoResultsFound";
import { SearchInput } from "../../shared/searchInput/SearchInput";

export interface AddToRegisterModalCloseEventArgs {
    /**
     * Selected items.
     */
    selectedIds: Array<string>,

    cancelled: boolean,

    reflectChangesInFutureTerms: boolean,
}
export interface AddToRegisterModalProps {
    toggle: () => void,
    onClose: (event: AddToRegisterModalCloseEventArgs) => Promise<void>,
    isSaving: boolean,

    scheduledClassId: string,
    isForWaitingList: boolean, 
}

/**
 * Modal for adding people to register or a waiting list.
 */
export const AddToRegisterModal = (props: AddToRegisterModalProps) => {
    const {
        toggle,
        onClose,
        isSaving,
        scheduledClassId,
        isForWaitingList,
    } = props;
    const { t } = useTranslation();

    // Load all data.
    const {
        data: {
            items: _allItems,
            memberProfiles,
            scheduledClassChildren,
        },
        errors: loadErrors, isLoading,
    } = useAddToRegisterModalViewModel();

    // Combine the loaded data together to make easy a single easy to use modal.
    const allItems = useMemo(() => {
        return _allItems?.map(item => {
            const myClassRegisters = scheduledClassChildren?.filter(it => it.childId === item.id);

            return {
                ...item,
                member: memberProfiles?.find(it => it.id === item.memberId),
                thisClassRegister: myClassRegisters?.find(it => it.scheduledClassId === scheduledClassId),
                activeClassRegisters: myClassRegisters?.filter(it => !it.isOnWaitingList),
                waitingListClassRegisters: myClassRegisters?.filter(it => it.isOnWaitingList),
            };
        });
    }, [_allItems, memberProfiles, scheduledClassChildren, scheduledClassId]);

    // Filtering children
    const [search, setSearch] = useState<string>('');

    // Manage the selected children
    const [selectedIds, setSelectedIds] = useState<Array<string>>([]);
    const toggleSelection = useCallback((id: string) => {
        setSelectedIds(prevState => {
            const existing = prevState.find(it => it === id);
            if (existing) {
                return prevState.filter(it => it !== id);
            }

            return [
                ...prevState,
                id
            ];
        });
    }, [setSelectedIds]);
    const isSelected = useCallback((id: string) => !!selectedIds.find(it => it === id), [selectedIds]);

    // Filtering logic
    const items = useMemo(() => {
        if (!allItems) {
            return allItems;
        }

        // Filter out the Children already on the register
        let ret = allItems;
        if (isForWaitingList) {
            ret = ret.filter(item => !item.thisClassRegister);
        } else {
            ret = ret.filter(item => !item.thisClassRegister || item.thisClassRegister.isOnWaitingList);
        }
        

        // Filter by the searchInput
        let lowerSearch = search.toLocaleLowerCase();
        if (lowerSearch) {
            // Just filtering by all string values that arent ids to start with. Most likely wont need them all for search.
            ret = ret.filter(item =>
                t('common.fullName', '{{firstName}} {{lastName}}', { firstName: item.firstName, lastName: item.lastName }).toLocaleLowerCase().indexOf(lowerSearch) >= 0
                || t('common.fullName', '{{firstName}} {{lastName}}', { firstName: item.member?.firstName, lastName: item.member?.lastName }).toLocaleLowerCase().indexOf(lowerSearch) >= 0
            );
        }

        return ret;
    }, [allItems, isForWaitingList, search, t]);

    // Close the modal and return the results
    const closeModal = useCallback(async (event?: { selectedIds: Array<string>, cancelled?: boolean, reflectChangesInFutureTerms?: boolean, }) => {
        if (onClose) {
            const thisSelectedIds = event?.selectedIds ?? selectedIds;

            const externalEvent: AddToRegisterModalCloseEventArgs = {
                ...(event ?? {}),

                selectedIds: thisSelectedIds,
                cancelled: event?.cancelled ?? false,
                reflectChangesInFutureTerms: event?.reflectChangesInFutureTerms ?? true,
            };
            await onClose(externalEvent);
        }
        
        // Close the modal
        toggle();
    }, [selectedIds, toggle, onClose, ]);

    // Reflect changes in future terms -> REMOVED AS WE DECIDED THAT ALL ACTIONS WILL BE REFLECTED IN FUTURE TERMS. LEAVING IN CASE WE REVERT.
    //const [reflectChangesInFutureTerms, setReflectChangesInFutureTerms] = useState<boolean>(true);

    // Render the UI
    //
    return (
        <>
            <AlertOnErrors errors={[
                loadErrors
            ]} />

            <ModalHeader toggle={toggle}>
                {t('addToRegisterModal.modal.header', 'Select Cubs:')}
            </ModalHeader>

            <ModalBody>
                <SearchInput value={search} onChange={e => setSearch(e.currentTarget.value)} />
                
                <CardsOrTable
                    viewMode={"table"}
                    items={items}
                    tableHeadings={[
                        t('addToRegisterModal.selected.heading', 'Selected'),
                        t('addToRegisterModal.cub.heading', 'Cub'),
                        t('addToRegisterModal.member.heading', 'Mama / Papa bear'),
                        t('addToRegisterModal.dateOfBirth.heading', 'Date of birth'),
                        t('addToRegisterModal.dateOfRegistration.heading', 'Date of registration'),
                        isForWaitingList? null: t('addToRegisterModal.isOnWaitingList.heading', 'Waiting list?'),
                        t('addToRegisterModal.otherClasses.heading', 'Other classes'),
                        t('addToRegisterModal.otherWaitingLists.heading', 'Other waiting lists')
                    ]}
                    columns={[
                        // Selected
                        (item, view) => {
                            const itemIsSelected = isSelected(item.id);

                            return (
                                <>
                                    <ButtonGroup>
                                        <Button color="primary" outline={!itemIsSelected} onClick={() => toggleSelection(item.id)} >
                                            {
                                                itemIsSelected ? t('addToRegisterModal.child.selected', 'Selected') : t('addToRegisterModal.child.select', 'Select')
                                            }
                                        </Button>
                                        <ConditionalFragment showIf={itemIsSelected}>
                                            <Button color="primary" outline={!isSelected} onClick={() => toggleSelection(item.id)}>
                                                <FontAwesomeIcon icon="times" />
                                            </Button>
                                        </ConditionalFragment>
                                    </ButtonGroup>
                                </>
                            );
                        },

                        // Cub
                        (item, view) => {
                            return t('common.fullName', '{{firstName}} {{lastName}}', { firstName: item.firstName ?? '', lastName: item.lastName ?? '' });
                        },

                        // Member
                        (item, view) => {
                            return t('common.fullName', '{{firstName}} {{lastName}}', { firstName: item.member?.firstName ?? '', lastName: item.member?.lastName ?? '' });
                        },

                        // Date of birth
                        (item, view) => {
                            return (
                                <>
                                    <div>
                                        {t('common.date', '{{date, DD/MM/YYYY}}', { date: moment(item.dateOfBirth) })}
                                    </div>
                                    <div>
                                        <Badge>
                                            {t('common.ageForDateOfBirth', 'Age {{years}} years {{months}} months', getAgeFromDateOfBirth(item.dateOfBirth))}
                                        </Badge>
                                    </div>
                                </>
                            );
                        },

                        // Registered date
                        (item, view) => {
                            return t('common.date', '{{date, DD/MM/YYYY}}', { date: moment(item.registeredDate) });
                        },

                        // Waiting list?
                        (item, view) => {
                            if (isForWaitingList) {
                                return null;
                            }

                            return !!item.thisClassRegister?.isOnWaitingList ? t('common.yes', 'Yes') : t('common.no', 'No');
                        },

                        // Other classes
                        (item, view) => {
                            return item.activeClassRegisters.filter(it => it.id !== item.thisClassRegister?.id && !it.isOnWaitingList)?.length;
                        },

                        // Other waiting lists
                        (item, view) => {
                            return item.activeClassRegisters.filter(it => it.id !== item.thisClassRegister?.id && !it.isOnWaitingList)?.length;
                        },
                    ]}
                />

                <ConditionalFragment showIf={isLoading && !items?.length}>
                    <LoadingIndicator fullWidth />
                </ConditionalFragment>
                <ConditionalFragment showIf={!isLoading && !items?.length}>
                    <NoResultsFound search={search} />
                </ConditionalFragment>
            </ModalBody>

            <ModalFooter>
                {/*<FormGroup>*/}
                {/*    <Label htmlFor="scheduledClassId">{t('addToRegisterModal.reflectChangesInFutureTerms', 'Would you like this change to be reflected in future terms?')}</Label>*/}
                {/*    <TwoValueSwitch leftLabel="No" rightLabel="Yes" checked={reflectChangesInFutureTerms} onChange={(value) => setReflectChangesInFutureTerms(value)} />*/}
                {/*</FormGroup>*/}

                {
                    isForWaitingList ? (
                        <ButtonAsync color="primary" onClick={() => closeModal({ selectedIds: selectedIds, cancelled: false, })} isExecuting={isSaving ?? false}
                            executingChildren={<><Spinner size="sm" /> {t('common.adding', 'Adding...')}</>}>
                            <FontAwesomeIcon icon="save" />
                            <> </>
                            {t('addToRegisterModal.addSelected.addWaitingList', 'Add {{count}} selected Cubs to waiting list', { count: selectedIds.length })}
                        </ButtonAsync>
                    ) : (
                        <>
                                <ButtonAsync color="primary" onClick={() => closeModal({ selectedIds: selectedIds, cancelled: false, reflectChangesInFutureTerms: true, })} isExecuting={isSaving ?? false}
                                executingChildren={<><Spinner size="sm" /> {t('common.adding', 'Adding...')}</>}>
                                <FontAwesomeIcon icon="save" />
                                <> </>
                                {t('addToRegisterModal.addSelectedNow.add', 'Add {{count}} selected Cubs', { count: selectedIds.length })}
                            </ButtonAsync>
                        </>
                    )
                }

                
                <Button type="button" color="primary" outline onClick={() => closeModal({ selectedIds: [], cancelled: true })}>
                    {t('common.cancel', 'Cancel')}
                </Button>
            </ModalFooter>
        </>
    );
}

