import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ValidationErrors } from "pojo-validator";
import { ValidateCallback } from "pojo-validator-react";
import { ValidatedInput } from "pojo-validator-reactstrap";
import { useTranslation } from "react-i18next";
import { useAsyncCallback } from "react-use-async-callback";
import { Button, ButtonGroup, Col, FormGroup, Input, Label, ListGroupItem, Row, Spinner } from "reactstrap";
import { ButtonAsync } from "reactstrap-buttonasync";
import { BlobUploadService } from "../../../../api/main/blobReferences/BlobUploadService";
import { useBlobReference } from "../../../../api/main/blobReferences/useBlobReference";
import { ChildsRelationship } from "../../../../api/main/models/ChildsRelationship";
import { EmergencyContact } from "../../../../api/main/models/EmergencyContact";
import { AlertOnErrors } from "../../../../shared/alertOnErrors";
import { FileUploadButton } from "../../../shared/fileUploadButton/FileUploadButton";
import { UploadedImagePreview } from "../../../shared/uploadedImagePreview/UploadedImagePreview";

export interface EmergencyContactComponentProps {
    model: EmergencyContact | undefined,
    change: (changes: Partial<EmergencyContact>) => void;
    remove: () => void,

    validate: ValidateCallback,
    validationErrors: ValidationErrors,
    childsRelationships: Array<ChildsRelationship> | null | undefined;
}
/**
 * Component under EditMembersChild that lets the user edit an EmergencyContact
 */
export const EmergencyContactComponent = (props: EmergencyContactComponentProps) => {
    const {
        model,
        change,
        remove,
        validate,
        validationErrors,
        childsRelationships
    } = props;
    const { t } = useTranslation();

    // Contents file uploading
    const { data: { model: contentsFile }, errors: contentsFileLoadErrors } = useBlobReference(model?.photoBlobReferenceId);
    const [onUploadContentsFile, { errors: contentsFileUploadErrors, isExecuting: isUploadingContentsFile, }] = useAsyncCallback(async (files: FileList | null) => {
        if (!files) {
            return;
        }

        let uploadService: BlobUploadService = new BlobUploadService("/api/blobs");
        let result = await uploadService.upload(files);

        if (!!result) {
            change({ photoBlobReferenceId: result.id });
        }
    }, [change]);
    const [clearContentsFile, { isExecuting: isClearingContentsFile, }] = useAsyncCallback(async () => {
        change({ photoBlobReferenceId: null });
    }, [change]);

    // Render the UI
    //
    return (
        <>
            <AlertOnErrors errors={[
                contentsFileLoadErrors,
                contentsFileUploadErrors
            ]} />
            
            <ListGroupItem>
                <Row>
                    <Col>
                        <FormGroup>
                            <Label htmlFor="firstName">{t('editMembersChild.emergencyContact.firstName.label', 'First name')}</Label>
                            <ValidatedInput name="firstName" type="text" value={model?.firstName ?? ''} onChange={e => change({ firstName: e.currentTarget.value })} onBlur={e => validate('firstName')} validationErrors={validationErrors['firstName']} />
                        </FormGroup>
                    </Col>
                    <Col>
                        <FormGroup>
                            <Label htmlFor="lastName">{t('editMembersChild.emergencyContact.lastName.label', 'Last name')}</Label>
                            <ValidatedInput name="lastName" type="text" value={model?.lastName ?? ''} onChange={e => change({ lastName: e.currentTarget.value })} onBlur={e => validate('lastName')} validationErrors={validationErrors['lastName']} />
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <FormGroup>
                            <Label htmlFor="primaryPhone">{t('editMembersChild.emergencyContact.primaryPhone.label', 'Primary phone')}</Label>
                            <ValidatedInput name="primaryPhone" type="text" value={model?.primaryPhone ?? ''} onChange={e => change({ primaryPhone: e.currentTarget.value })} onBlur={e => validate('primaryPhone')} validationErrors={validationErrors['primaryPhone']} />
                        </FormGroup>
                    </Col>
                    <Col>
                        <FormGroup>
                            <Label htmlFor="primaryEmail">{t('editMembersChild.emergencyContact.primaryEmail.label', 'Primary email')}</Label>
                            <Input name="primaryEmail" type="text" value={model?.primaryEmail ?? ''} onChange={e => change({ primaryEmail: e.currentTarget.value })} />
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <FormGroup>
                            <Label htmlFor="secondaryPhone">{t('editMembersChild.emergencyContact.secondaryPhone.label', 'Secondary phone')}</Label>
                            <Input name="secondaryPhone" type="text" value={model?.secondaryPhone ?? ''} onChange={e => change({ secondaryPhone: e.currentTarget.value })} />
                        </FormGroup>
                    </Col>
                    <Col>
                        <FormGroup>
                            <Label htmlFor="secondaryEmail">{t('editMembersChild.emergencyContact.secondaryEmail.label', 'Secondary email')}</Label>
                            <Input name="secondaryEmail" type="text" value={model?.secondaryEmail ?? ''} onChange={e => change({ secondaryEmail: e.currentTarget.value })} />
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <FormGroup>
                        <Label htmlFor="childsRelationshipId">{t('editMembersChild.emergencyContact.childsRelationshipId.label', 'Relationship to child')}</Label>
                        <ValidatedInput name="childsRelationshipId" type="select" value={model?.childsRelationshipId ?? ''} onChange={e => change({ childsRelationshipId: e.currentTarget.value })} onBlur={e => validate('childsRelationshipId')} validationErrors={validationErrors}>
                            <option value="">{t('editMembersChild.emergencyContact.childsRelationshipId.pleaseSelect', '(Please select relationship to child)')}</option>
                            {
                                childsRelationships?.map(childRelationship => (
                                    <option key={childRelationship.id} value={childRelationship.id}>{t('editMembersChild.mainTab.emergencyContact.value', '{{ childRelationship }}', { childRelationship: childRelationship.name ?? '' })}</option>
                                ))
                            }
                        </ValidatedInput>
                    </FormGroup>
                </Row>
                <Row>
                    <Col>
                        <FormGroup>
                            <Label htmlFor="fileUpload">{t('editMembersChild.emergencyContact.fileUpload.label', 'Photo')}</Label>
                            <Row>
                                <Col>
                                    <UploadedImagePreview size="sm" src={contentsFile?.url ?? ''} />

                                    <Row>
                                        <Col>
                                            <ButtonGroup>
                                                <FileUploadButton
                                                    color={`primary`}
                                                    isExecuting={isUploadingContentsFile}
                                                    executingChildren={<><Spinner size="sm" /> {t('common.uploading', 'Uploading...')}</>}
                                                    onUpload={onUploadContentsFile}
                                                    outline={false}>
                                                    {t('editMembersChild.emergencyContact.uploadButtonText', 'Upload a photo')}
                                                </FileUploadButton>
                                                <ButtonAsync color="primary"
                                                    outline
                                                    isExecuting={isClearingContentsFile}
                                                    type="button"
                                                    onClick={clearContentsFile}
                                                    executingChildren={<><Spinner size="sm" /> {t('editMembersChild.clearingFile', 'Clearing photo...')}</>}>
                                                    {t('editMembersChild.emergencyContact.clearUploadButton', 'Clear photo')}
                                                </ButtonAsync>
                                            </ButtonGroup>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col xs='auto'>
                        <FormGroup>
                            <Label htmlFor="name">&nbsp;</Label>
                            <div>
                                <Button color="danger" outline onClick={() => remove()}>
                                    <FontAwesomeIcon icon="trash-alt" />
                                    <> </>
                                    {t('editMembersChild.emergencyContact.delete', 'Delete emergency contact')}
                                </Button>
                            </div>
                        </FormGroup>
                    </Col>
                </Row>
            </ListGroupItem>
        </>
    );
};