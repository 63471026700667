import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export interface HappyIconProps {
    icon:
    'health-notes'
    | 'staff-notes'
    | 'progress-notes'
    | 'birthday'
    | 'class-stage'
    | 'class-location'
    | 'lesson-number'
    | 'class-map'
    | 'class-role'
    | 'class-attendance-check'
    | 'scheduled-class-summary'
    | 'music-cubs'
    | 'swim-cubs'
    | 'info'
    | 'file'
    | 'no-file'
    | 'calendar'
    | 'sick-awol'
    | 'sick-notified'
    | 'annual-leave'
    | 'people'
    | 'child'
    | 'reply'
    | 'payment'
    | 'relationship'
    | 'phone'
    | 'no-phone'
    | 'email'
    | 'gift'
    | 'euro'
    | 'percent'
    clickHandler?: () => void,
}

/**
 * Component wrapper for FontAwesomeIcons
 * @param props // icon - Names related to HappyCubs resources, not FontAwesome. Takes an optional clickHandler, if the icon is being used as a link or button
 * @returns // A FontAwesomeIcon with the relevant color 
 */
export const HappyIcon = (props: HappyIconProps) => {
    const { icon, clickHandler, } = props;

    const isClickable = clickHandler ?? false;

    switch (icon) {
        case ('health-notes'):
            return <FontAwesomeIcon
                cursor="pointer"
                icon="file-medical"
                color="#fa7600"
                onClick={isClickable ? clickHandler : undefined} />;
        case ('staff-notes'):
            return <FontAwesomeIcon
                cursor="pointer"
                icon="sticky-note"
                color="#0076fa"
                onClick={isClickable ? clickHandler : undefined} />;
        case ('progress-notes'):
            return <FontAwesomeIcon
                cursor="pointer"
                icon="clipboard"
                color="#a700fa"
                onClick={isClickable ? clickHandler : undefined} />;
        case ('birthday'):
            return <FontAwesomeIcon
                cursor="pointer"
                icon="cake-candles"
                color="#008bfa"
                onClick={isClickable ? clickHandler : undefined} />;
        case ('class-stage'):
            return <FontAwesomeIcon
                icon="bars-staggered"
                onClick={isClickable ? clickHandler : undefined} />;
        case ('class-location'):
            return <FontAwesomeIcon
                icon="compass"
                onClick={isClickable ? clickHandler : undefined} />;
        case ('lesson-number'):
            return <FontAwesomeIcon
                icon="chalkboard-teacher"
                onClick={isClickable ? clickHandler : undefined} />;
        case ('class-map'):
            return <FontAwesomeIcon
                icon="map"
                onClick={isClickable ? clickHandler : undefined} />;
        case ('class-role'):
            return <FontAwesomeIcon
                icon="person"
                onClick={isClickable ? clickHandler : undefined} />;
        case ('class-attendance-check'):
            return <FontAwesomeIcon
                icon="check"
                onClick={isClickable ? clickHandler : undefined} />;
        case ('scheduled-class-summary'):
            return <FontAwesomeIcon
                icon="clock"
                onClick={isClickable ? clickHandler : undefined} />;
        case ('music-cubs'):
            return <FontAwesomeIcon
                icon="music"
                onClick={isClickable ? clickHandler : undefined} />;
        case ('swim-cubs'):
            return <FontAwesomeIcon
                icon="swimmer"
                onClick={isClickable ? clickHandler : undefined}
            />;
        case ('info'):
            return <FontAwesomeIcon
                icon="info-circle"
                onClick={isClickable ? clickHandler : undefined} />;
        case ('file'):
            return <FontAwesomeIcon
                icon="file"
                onClick={isClickable ? clickHandler : undefined} />;
        case ('no-file'):
            return <FontAwesomeIcon
                icon="file-circle-exclamation"
                onClick={isClickable ? clickHandler : undefined} />;
        case ('calendar'):
            return <FontAwesomeIcon
                icon="calendar"
                onClick={isClickable ? clickHandler : undefined} />;
        case ('sick-awol'):
            return <FontAwesomeIcon
                icon="syringe"
                onClick={isClickable ? clickHandler : undefined} />;
        case ('sick-notified'):
            return <FontAwesomeIcon
                icon="syringe"
                onClick={isClickable ? clickHandler : undefined} />;
        case ('annual-leave'):
            return <FontAwesomeIcon
                icon="plane"
                onClick={isClickable ? clickHandler : undefined} />;
        case ('people'):
            return <FontAwesomeIcon
                icon="people-line"
                onClick={isClickable ? clickHandler : undefined} />;
        case ('child'):
            return <FontAwesomeIcon
                icon="child"
                color="#737373"
                onClick={isClickable ? clickHandler : undefined} />;
        case ('reply'):
            return <FontAwesomeIcon
                icon="reply"
                onClick={isClickable ? clickHandler : undefined} />;
        case ('payment'):
            return <FontAwesomeIcon
                icon="money-bill"
                onClick={isClickable ? clickHandler : undefined} />
        case ('relationship'):
            return <FontAwesomeIcon
                icon="arrows-down-to-people"
                onClick={isClickable ? clickHandler : undefined} />
        case ('phone'):
            return <FontAwesomeIcon
                icon="phone"
                onClick={isClickable ? clickHandler : undefined} />
        case ('no-phone'):
            return <FontAwesomeIcon
                icon="phone-slash"
                onClick={isClickable ? clickHandler : undefined} />
        case ('email'):
            return <FontAwesomeIcon
                icon="envelope"
                onClick={isClickable ? clickHandler : undefined} />
        case ('gift'):
            return <FontAwesomeIcon
                icon="gift"
                onClick={isClickable ? clickHandler : undefined} />
        case ('euro'):
            return <FontAwesomeIcon
                icon={'euro-sign'}
                onClick={isClickable ? clickHandler : undefined} />
        case ('percent'):
            return <FontAwesomeIcon
                icon={'percent'}
                onClick={isClickable ? clickHandler : undefined} />
    }
};

