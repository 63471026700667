import { useTranslation } from 'react-i18next';
import { ClassLocation } from '../../../api/main/generated/graphql';
import { useParams, useNavigate } from 'react-router';
import { useChanges, useChangesArray } from '../../../shared/useChanges';
import { useSaveClassLocationMutation } from '../../../api/main/classLocations/useSaveClassLocationMutation';
import { classLocationDefaultValues } from '../../../api/main/models/ClassLocation';
import { useValidatorCallback } from 'pojo-validator-react';
import { useAsyncCallback } from 'react-use-async-callback';
import { useMemo, useState } from 'react';
import { Banner } from '../../shared/banner/Banner';
import { StickyToolbar } from '../../shared/stickyToolbar/StickyToolbar';
import { Badge, Button, Col, NavItem, NavLink, Row, Spinner } from 'reactstrap';
import { PillsNavBar } from '../../shared/pillsNavBar/PillsNavBar';
import { ConditionalFragment } from 'react-conditionalfragment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { hasAnyValidationErrors } from '../../../utilities/hasAnyValidationErrors';
import { LoadingIndicator } from '../../shared/loadingIndicator/LoadingIndicator';
import { MainContainer } from '../../shared/mainContainer/MainContainer';
import { AlertOnErrors } from '../../../shared/alertOnErrors';
import { MainTab } from './MainTab';
import { FormButtons } from '../../shared/formButtons/FormButtons';
import { ButtonAsync } from 'reactstrap-buttonasync';
import { ClassLocationRequirementCheck } from '../../../api/main/models/ClassLocationRequirementCheck';
import { useEditClassLocationViewModel } from '../../../api/main/classLocations/viewModels/useEditClassLocationViewModel';
import { useValidatorArrayCallback } from '../../../shared/validator-react-contrib/useValidatorArrayCallback';
import { RequirementChecksTab } from './requirementChecksTab/RequirementChecksTab';
import { useSaveClassLocationRequirementCheckMutation } from '../../../api/main/classLocationRequirementChecks/useSaveClassLocationRequirementCheckMutation';
import { useDeleteClassLocationRequirementCheckMutation } from '../../../api/main/classLocationRequirementChecks/useDeleteClassLocationRequirementCheck';
import { ClassLocationClassType } from '../../../api/main/models/ClassLocationClassType';
import { useSaveClassLocationClassTypeMutation } from '../../../api/main/classLocationClassTypes/useSaveClassLocationClassTypeMutation';
import { useDeleteClassLocationClassTypeMutation } from '../../../api/main/classLocationClassTypes/useDeleteClassLocationClassType';
import { useEditClassLocationSupportingData } from '../../../api/main/classLocations/viewModels/useEditClassLocationSupportingData';
import { hasDatePassed } from '../../../utilities/hasDatePassed';
import { StaffNote } from '../../../api/main/models/StaffNote';
import { useSaveStaffNoteMutation } from '../../../api/main/staffNotes/useSaveStaffNoteMutation';
import { useDeleteStaffNoteMutation } from '../../../api/main/staffNotes/useDeleteStaffNote';
import { findLastUpdatedStaffNote } from '../../../utilities/findLastUpdatedStaffNote';
import moment from 'moment';
import { useBlobReference } from '../../../api/main/blobReferences/useBlobReference';
import { BlobUploadService } from '../../../api/main/blobReferences/BlobUploadService';
import { PhotoComponent } from './photosTab/PhotoComponent';
import { useReplaceSearchParamsEffect, useSearchParams } from '../../../shared/useURLSearchParams';
import { StaffNotesTab } from '../../staffNotes/StaffNotesTab';
import { ClassLocationAmenityType } from '../../../api/main/models/ClassLocationAmenityType';
import { useSaveClassLocationAmenityTypeMutation } from '../../../api/main/classLocationAmenityTypes/useSaveClassLocationAmenityTypeMutation';
import { useDeleteClassLocationAmenityTypeMutation } from '../../../api/main/classLocationAmenityTypes/useDeleteClassLocationAmenityType';

export interface EditClassLocationProps {
    isCreate?: boolean,
    onCreateDefaultValues?: () => Partial<ClassLocation>;
}

/**
 * Component to create a ClassLocation
 */
export const CreateClassLocation = (props: EditClassLocationProps) => (<EditClassLocation isCreate={true} {...props} />);

/**
 * Component to edit a ClassLocation
 */
export const EditClassLocation = (props: EditClassLocationProps) => {
    const {
        isCreate,
        onCreateDefaultValues,
    } = props;

    const { t } = useTranslation();
    const { id } = useParams<{ id: string | undefined; }>();
    const navigate = useNavigate();


    // Load all data.
    const {
        data: {
            model: storeModel,
            classLocationRequirementChecks: storeRequirementChecks,
            classLocationClassTypes: storeClassLocationClassTypes,
            staffNotes,
            classLocationAmenityTypes: storeClassLocationAmenityTypes,
        },
        errors: loadErrors, isLoading: _isLoading,
    } = useEditClassLocationViewModel(id);

    // Supporting data
    const {
        data: {
            classTypes,
            staffProfiles,
            amenityTypes
        },
        errors: loadSupportingDataErrors, isLoading: isLoadingSupportingData,
    } = useEditClassLocationSupportingData();
    const isLoading = _isLoading || isLoadingSupportingData;

    // Model (ClassLocation)
    const { model, change, changes } = useChanges(storeModel, isCreate ? { ...classLocationDefaultValues(), ...(onCreateDefaultValues ? onCreateDefaultValues() : {}) } : undefined);
    const [saveClassLocation, { errors: saveErrors }] = useSaveClassLocationMutation();

    // ClassLocation image upload (photo)
    const { data: { model: image }, errors: imageLoadErrors } = useBlobReference(model?.photoBlobReferenceId);
    const [onUploadImage, { errors: imageUploadErrors, isExecuting: isUploadingImage }] = useAsyncCallback(async (files: FileList | null) => {
        if (!files) {
            return;
        }

        let uploadService: BlobUploadService = new BlobUploadService("/api/blobs");
        let result = await uploadService.upload(files);

        if (!!result) {
            change({ photoBlobReferenceId: result.id });
        }
    }, [change]);

    // Clear image functionality
    const [clearImage, { isExecuting: isClearingImage }] = useAsyncCallback(async () => {
        change({ photoBlobReferenceId: null });
    }, [change]);

    // Model (RequirementChecks)
    const requirementChecksManager = useChangesArray<ClassLocationRequirementCheck, string>(storeRequirementChecks, item => item.id);
    const [saveRequirementCheck] = useSaveClassLocationRequirementCheckMutation();
    const [removeRequirementCheck] = useDeleteClassLocationRequirementCheckMutation();

    // Class types that are linked to this location.
    const classLocationClassTypesManager = useChangesArray<ClassLocationClassType, string>(storeClassLocationClassTypes, item => item.id);
    const [saveClassLocationClassType] = useSaveClassLocationClassTypeMutation();
    const [removeClassLocationClassType] = useDeleteClassLocationClassTypeMutation();

    // Amenity types that are linked to this location.
    const classLocationAmenityTypesManager = useChangesArray<ClassLocationAmenityType, string>(storeClassLocationAmenityTypes, item => item.id);
    const [saveClassLocationAmenityType] = useSaveClassLocationAmenityTypeMutation();
    const [removeClassLocationAmenityType] = useDeleteClassLocationAmenityTypeMutation();

    // Model (StaffNotes)
    const classLocationStaffNotesManager = useChangesArray<StaffNote, string>(staffNotes, item => item.id);
    const [saveStaffNote] = useSaveStaffNoteMutation();
    const [removeStaffNote] = useDeleteStaffNoteMutation();

    const [validateRequirementCheck, requirementCheckValidationErrors] = useValidatorArrayCallback<ClassLocationRequirementCheck>((myModel, validation, fieldsToCheck) => {
        const rules = {
            name: () => !myModel?.name ? t('editClassLocation.requirementChecks.errors.name', 'Name cannot be empty') : '',
        };

        validation.checkRules(rules, fieldsToCheck);
    }, []);

    // StaffNotes validation
    const [validateClassLocationStaffNote, classLocationStaffNoteValidationErrors] = useValidatorArrayCallback<StaffNote>((myModel, validation, fieldsToCheck) => {
        const rules = {
            noteType: () => !myModel?.noteType ? t('editClassLocation.staffNote.errors.noteType', 'Note type is required') : '',
        };

        validation.checkRules(rules, fieldsToCheck);
    }, []);

    // Main model validation (including all related objects).
    const [validate, validationErrors] = useValidatorCallback((validation, fieldsToCheck) => {
        const rules = {
            name: () => !model?.name ? t('editClassLocation.errors.name', 'Name cannot be empty') : '',

            // Any errors in the related objects
            requirementChecks: () => requirementChecksManager.model.filter(it => !validateRequirementCheck(it)).length ? t('editClassLocation.errors.requirementCheck', 'One or more of the requirement checks are invalid') : '',
            staffNotes: () => classLocationStaffNotesManager.model.filter(it => !validateClassLocationStaffNote(it)).length ? t('editClassLocation.errors.staffNotes', 'One or more of the staff notes are invalid') : ''
        };
        validation.checkRules(rules, fieldsToCheck);
    }, [model, requirementChecksManager, validateRequirementCheck]);

    // Save everything.
    const [saveForm, { isExecuting: isSaving, errors: saveFormErrors }] = useAsyncCallback(async (options?: { dontNavigate?: boolean; }) => {
        if (!model) {
            return;
        }

        if (!validate()) {
            return;
        }

        // Save the main model.
        await saveClassLocation(model.id, { ...changes }, isCreate ?? false);

        // Save changes to the RequirementChecks
        for (const item of requirementChecksManager.added) { await saveRequirementCheck(item.id, requirementChecksManager.changesFor(item.id), true); }
        for (const item of requirementChecksManager.updated) { await saveRequirementCheck(item.id, requirementChecksManager.changesFor(item.id), false); }
        for (const item of requirementChecksManager.removed) { await removeRequirementCheck(item.id); }
        requirementChecksManager.markAsSaved();

        // Save changes to the ClassLocationClassTypes.
        for (const item of classLocationClassTypesManager.added) { await saveClassLocationClassType(item.id, classLocationClassTypesManager.changesFor(item.id), true); }
        for (const item of classLocationClassTypesManager.updated) { await saveClassLocationClassType(item.id, classLocationClassTypesManager.changesFor(item.id), false); }
        for (const item of classLocationClassTypesManager.removed) { await removeClassLocationClassType(item.id); }
        classLocationClassTypesManager.markAsSaved();

        // Saves changes to the StaffNote
        for (const item of classLocationStaffNotesManager.added) { await saveStaffNote(item.id, classLocationStaffNotesManager.changesFor(item.id), true); }
        for (const item of classLocationStaffNotesManager.updated) { await saveStaffNote(item.id, classLocationStaffNotesManager.changesFor(item.id), false); }
        for (const item of classLocationStaffNotesManager.removed) { await removeStaffNote(item.id); }
        classLocationStaffNotesManager.markAsSaved();

        // Save changes to the ClassLocationAmenityTypes.
        for (const item of classLocationAmenityTypesManager.added) { await saveClassLocationAmenityType(item.id, classLocationAmenityTypesManager.changesFor(item.id), true); }
        for (const item of classLocationAmenityTypesManager.updated) { await saveClassLocationAmenityType(item.id, classLocationAmenityTypesManager.changesFor(item.id), false); }
        for (const item of classLocationAmenityTypesManager.removed) { await removeClassLocationAmenityType(item.id); }
        classLocationAmenityTypesManager.markAsSaved();

        // Go back to previous screen.
        if (!options?.dontNavigate) {
            navigate(-1);
        }
    }, [validate, saveClassLocation, model, changes, isCreate, id, navigate,
        requirementChecksManager, saveRequirementCheck, removeRequirementCheck,
        classLocationClassTypesManager, saveClassLocationClassType, removeClassLocationClassType, classLocationStaffNotesManager, saveStaffNote, removeStaffNote,
    ]);

    // Manage the tab being displayed.  If we have tab= on the query string, default to it, otherwise default to the main tab.
    const { tab: tabParam } = useSearchParams();
    // Manage the tab being displayed.
    const [activeTab, setActiveTab] = useState<'main' | 'photo' | 'requirementChecks' | 'staff-notes'>(tabParam as any || 'main');

    // Keep the URL up to date with the currentTab
    useReplaceSearchParamsEffect({ tab: activeTab === 'main' ? '' : activeTab });

    // Count how many overdue requirement checks we have (if any).
    const overdueRequirementChecksCount = useMemo(() => requirementChecksManager.model.filter(item => hasDatePassed(item.reviewDate)).length, [requirementChecksManager]);

    // Call function to return the last updated note
    const lastNoteUpdate = useMemo(() => {
        return findLastUpdatedStaffNote(staffNotes);
    }, [staffNotes]);

    // Render the UI
    //
    return (
        <>
            <Banner>
                <StickyToolbar>
                    <Row>
                        <Col xs={12} md="auto">
                            <h1>
                                {
                                    isCreate ? t('editClassLocation.createHeading.default', 'Add location')
                                        : t('editClassLocation.editHeading.default', 'Edit location')
                                }
                            </h1>
                            <h3>{model?.name}</h3>
                        </Col>
                        <Col>
                            <PillsNavBar>
                                <NavItem>
                                    <NavLink active={activeTab === 'main'} onClick={() => setActiveTab('main')}>
                                        {t('editClassLocation.nav.main', 'Location details')}

                                        {/* Show a warning triangle if any fields for this tab have validation errors */}
                                        <ConditionalFragment showIf={hasAnyValidationErrors([validationErrors['name'], validationErrors['startDate'], validationErrors['endDate'],])}>
                                            <> </><FontAwesomeIcon icon="exclamation-triangle" color="danger" />
                                        </ConditionalFragment>
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink active={activeTab === 'photo'} onClick={() => setActiveTab('photo')}>
                                        {t('editClassLocation.nav.photo', 'Photo')}

                                        {/* Show a warning triangle if any fields for this tab have validation errors */}
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink active={activeTab === 'requirementChecks'} onClick={() => setActiveTab('requirementChecks')}>
                                        {t('editClassLocation.nav.requirementChecks.name', 'Requirements and checks')}
                                        <> </>
                                        {
                                            overdueRequirementChecksCount > 0 ? (
                                                <>
                                                    <Badge pill color="danger">
                                                        {t('editClassLocation.nav.requirementChecks.overdueCount', '{{count}} overdue', { count: overdueRequirementChecksCount, })}
                                                    </Badge>
                                                    <> </>
                                                    <Badge pill>
                                                        {t('editClassLocation.nav.requirementChecks.totalCount', '{{count}} total', { count: requirementChecksManager.model.length, })}
                                                    </Badge>
                                                </>
                                            ) : (
                                                <Badge pill>
                                                    {t('common.integer', '{{count}}', { count: requirementChecksManager.model.length, })}
                                                </Badge>
                                            )
                                        }

                                        {/* Show a warning triangle if any fields for this tab have validation errors */}
                                        <ConditionalFragment showIf={hasAnyValidationErrors(validationErrors['requirementChecks'])}>
                                            <> </>
                                            <FontAwesomeIcon icon="exclamation-triangle" color="danger" />
                                        </ConditionalFragment>
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink active={activeTab === 'staff-notes'} onClick={() => setActiveTab('staff-notes')}>
                                        {t('editClassLocation.nav.staffNotes.name', 'Notes on class')}
                                        <> </>
                                        {
                                            lastNoteUpdate !== null ? (
                                                <>
                                                    <Badge pill color="secondary">
                                                        {t('editClassLocation.nav.staffNotes.badgeUpdated', 'Updated: ')} {t('common.date', '{{date, DD/MM/YYYY}}', { date: moment(lastNoteUpdate.updatedDate) })}
                                                    </Badge>
                                                </>
                                            ) : null
                                        }

                                        {/* Show a warning triangle if any fields for this tab have validation errors */}
                                        <ConditionalFragment showIf={hasAnyValidationErrors([validationErrors['staffNotes'],])}>
                                            <> </><FontAwesomeIcon icon="exclamation-triangle" color="danger" />
                                        </ConditionalFragment>
                                    </NavLink>
                                </NavItem>
                            </PillsNavBar>
                        </Col>
                        <ConditionalFragment showIf={isLoading}>
                            <Col xs="auto">
                                <LoadingIndicator size="sm" />
                            </Col>
                        </ConditionalFragment>
                    </Row>
                </StickyToolbar>
            </Banner>
            <MainContainer>
                <AlertOnErrors errors={[
                    loadErrors,
                    saveFormErrors, saveErrors,
                    loadSupportingDataErrors,
                    imageLoadErrors,
                    imageUploadErrors
                ]} />

                {/* Active tab */}
                {
                    activeTab === 'main' ? (
                        <MainTab
                            model={model}
                            change={change}
                            validate={validate}
                            validationErrors={validationErrors}
                            saveForm={saveForm}
                            classLocationClassTypesManager={classLocationClassTypesManager}
                            classTypes={classTypes ?? []}
                            classLocationAmenityTypesManager={classLocationAmenityTypesManager}
                            amenityTypes={amenityTypes}
                        />
                    ) : activeTab === 'photo' ? (
                        <PhotoComponent
                            saveForm={saveForm}
                            isExecutingImageUpload={isUploadingImage}
                            onImageUpload={onUploadImage}
                            isExecutingClearImage={isClearingImage}
                            imageUploadClickHandler={clearImage}
                            imageUrl={image?.url}
                        />
                    ) : activeTab === 'requirementChecks' ? (
                        <RequirementChecksTab
                            model={model}
                            requirementChecksManager={requirementChecksManager}
                            validateRequirementCheck={validateRequirementCheck}
                            requirementCheckValidationErrors={requirementCheckValidationErrors}
                        />
                    ) : activeTab === 'staff-notes' ? (
                        <StaffNotesTab
                            model={{ targetId: model.id, targetType: 'ClassLocation', headingName: 'Location', modalHeading: model.name }}
                            staffNotesManager={classLocationStaffNotesManager}
                            validateStaffNote={validateClassLocationStaffNote}
                            staffNoteValidationErrors={classLocationStaffNoteValidationErrors}
                            staffProfiles={staffProfiles}
                            saveForm={saveForm}
                        />
                    ) : null
                }



                {/* Save buttons at the bottom of all tabs. */}
                <FormButtons>
                    <ConditionalFragment showIf={!isLoading}>
                        <ButtonAsync color="primary" isExecuting={isSaving} onClick={() => saveForm()}
                            executingChildren={<><Spinner size="sm" /> {t('common.saving', 'Saving...')}</>}>
                            <FontAwesomeIcon icon="save" />
                            <> </>
                            {t('common.save', 'Save')}
                        </ButtonAsync>
                    </ConditionalFragment>

                    <Button type="button" color="primary" outline onClick={e => navigate(-1)}>
                        {t('common.cancel', 'Cancel')}
                    </Button>
                </FormButtons>
            </MainContainer>
        </>
    );
};