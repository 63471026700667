import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useCallback, useMemo, useState } from "react";
import { ConditionalFragment } from "react-conditionalfragment";
import { useTranslation } from "react-i18next";
import { Button, Col, Row, FormGroup } from "reactstrap";
import { useToggleState } from "use-toggle-state";
import { StaffSubmissionAcceptanceState } from "../../api/main/models/constants/StaffSubmissonAcceptanceState";
import { useCurrentUserProfile } from "../../api/main/profiles/useCurrentUserProfile";
import { useStaffTimeOffTabViewModel } from "../../api/main/staffAbsences/viewModels/useStaffTimeOffViewModel";
import { AlertOnErrors } from "../../shared/alertOnErrors";
import { Banner } from "../shared/banner/Banner";
import { LoadingIndicator } from "../shared/loadingIndicator/LoadingIndicator";
import { MainContainer } from "../shared/mainContainer/MainContainer";
import { StickyToolbar } from "../shared/stickyToolbar/StickyToolbar";
import { StyledModal } from "../shared/styledModal/StyledModal";
import { HistoricalStaffAbsenceCard } from "./cards/HistoricalStaffAbsenceCard";
import { UpcomingStaffAbsenceCard } from "./cards/UpcomingStaffAbsenceCard";
import { CreateStaffAbsenceModal, EditStaffAbsenceModal } from "./edit/EditStaffAbsenceModal";

/**
 * StaffTimeOff management
 */
export const StaffTimeOffTab = () => {
    const { t } = useTranslation();

    // Load the data - Current UserProfile
    const {
        data: {
            model
        }, isLoading: _isLoading, errors: loadErrors,
    } = useCurrentUserProfile();

    // Get the current User's StaffId
    const currentUserStaffId = model?.id;

    // Get all supporting data using the StaffId
    const {
        data: {
            staffAbsences,
            staffAbsenceTypes,
        }, isLoading: isLoadingSupportingData, errors: supportingDataLoadErrors,
        refresh: refreshStaffAbsences,
    } = useStaffTimeOffTabViewModel(currentUserStaffId);
    const isLoading = _isLoading || isLoadingSupportingData;

    // Separate the StaffAbsences into Approved, Submitted, Rejected, and SelfAccepted
    const allItems = useMemo(() => {
        // We want to cut off for the day before today, so the user can see any leave starting today
        const today = new Date();
        today.setDate(today.getDate() - 1);

        // Filtering StaffAbsence requests that are upcoming (not historical)
        const upcoming = staffAbsences?.filter(({ endDate }) => new Date(endDate) >= today);

        // Filtering StaffAbsence requests with an EndDate before today (historical). We also don't want to capture any Submitted (pending) requests here.
        const historical = staffAbsences?.filter(it => it.acceptanceState !== StaffSubmissionAcceptanceState.Submitted).filter(({ endDate }) => new Date(endDate) <= today);

        return {
            upcoming,
            historical
        };
    }, [staffAbsences]);

    // Handle toggling any historical StaffAbsences
    const [isShowingHistoricalData, toggleShowingHistoricalData] = useToggleState(false);

    // Showing the Create modal
    const [staffAbsenceModalIsOpen, _toggleStaffAbsenceModal] = useToggleState();
    const toggleStaffAbsenceModal = useCallback(() => {
        _toggleStaffAbsenceModal();
        refreshStaffAbsences(); // Trigger a refresh of the StaffAbsences
    }, [_toggleStaffAbsenceModal, refreshStaffAbsences]);

    // Showing the Edit modal
    const [idToEdit, setIdToEdit] = useState('');
    const [staffAbsenceEditModalIsOpen, _toggleStaffAbsenceEditModal] = useToggleState();
    const toggleStaffAbsenceEditModal = useCallback(() => {
        _toggleStaffAbsenceEditModal();
    }, [_toggleStaffAbsenceEditModal]);

    // Handles setting the ID for the StaffAbsence we are looking to Edit. Taken this approach due to not having params due to using Modals.
    const handleEditStaffAbsence = (id: string) => {
        setIdToEdit(id);
        toggleStaffAbsenceEditModal();
    };

    // Render the UI
    //
    return (
        <>
            <Banner>
                <StickyToolbar>
                    <Row>
                        <Col>
                            <h1>{t('staffTimeOffTab.title', 'Upcoming time off')}</h1>
                        </Col>
                        <ConditionalFragment showIf={isLoading}>
                            <Col xs="auto">
                                <LoadingIndicator size="sm" />
                            </Col>
                        </ConditionalFragment>
                    </Row>
                </StickyToolbar>
            </Banner>

            <MainContainer>
                <AlertOnErrors
                    errors={[
                        loadErrors,
                        supportingDataLoadErrors
                    ]}
                />

                {/* Section for upcoming StaffAbsences */}
                {allItems?.upcoming?.map(item => (
                    <UpcomingStaffAbsenceCard
                        model={item}
                        staffAbsenceTypes={staffAbsenceTypes}
                        handleEditStaffAbsence={handleEditStaffAbsence}
                        key={item.id}
                    />
                ))}

                <FormGroup className="text-center">
                    <Button color="primary" onClick={() => toggleStaffAbsenceModal()}>
                        <FontAwesomeIcon icon="plus" />
                        <> </>
                        {t('staffTimeOffTab.requestTimeOff.button', 'Request time off')}
                    </Button>
                </FormGroup>

                <Row>
                    <Col>
                        <h2>{t('staffTimeOffTab.subtitle', 'Historical time off')}</h2>
                    </Col>
                </Row>

                <Row style={{ marginBottom: '10px' }}>
                    <Col>
                        <Button color="primary" onClick={e => toggleShowingHistoricalData()}>
                            {isShowingHistoricalData ? (
                                <>
                                    <FontAwesomeIcon icon='eye-slash' />
                                    <> </>
                                    {t('staffTimeOffTab.hideHistorical.button', 'Hide')}
                                </>
                            ) : (
                                <>
                                    <FontAwesomeIcon icon="eye" />
                                    <> </>
                                    {t('staffTimeOffTab.showHistorical.button', 'Show')}
                                </>
                            )}
                        </Button>
                    </Col>
                </Row>

                {/* Section for historical StaffAbsences */}
                <ConditionalFragment showIf={!!isShowingHistoricalData}>

                    {allItems?.historical?.map(item => (
                        <HistoricalStaffAbsenceCard
                            model={item}
                            staffAbsenceTypes={staffAbsenceTypes}
                            key={item.id}
                        />
                    ))}
                </ConditionalFragment>

                {/* Modals for Create / Edit */}
                <ConditionalFragment showIf={staffAbsenceModalIsOpen}>
                    <StyledModal
                        isOpen={staffAbsenceModalIsOpen}
                        toggle={toggleStaffAbsenceModal}
                        size="md"                    >
                        <CreateStaffAbsenceModal
                            toggle={toggleStaffAbsenceModal}
                            staffId={currentUserStaffId ?? ''}
                        />
                    </StyledModal>
                </ConditionalFragment>

                <ConditionalFragment showIf={staffAbsenceEditModalIsOpen}>
                    <StyledModal
                        isOpen={staffAbsenceEditModalIsOpen}
                        toggle={toggleStaffAbsenceEditModal}>
                        <EditStaffAbsenceModal
                            toggle={toggleStaffAbsenceEditModal}
                            staffId={currentUserStaffId ?? ''}
                            id={idToEdit}
                        />
                    </StyledModal>
                </ConditionalFragment>
            </MainContainer>
        </>
    );
};