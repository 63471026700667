import * as React from 'react';
import { useSaveInStore, SaveInStoreOptions } from '../../../shared/useSaveInStore';
import { useUpdateAmenityTypeMutation } from './useUpdateAmenityTypeMutation';
import { AmenityType } from '../models/AmenityType';
import { useCreateAmenityTypeMutation } from './useCreateAmenityTypeMutation';
import { AmenityTypeCreateInput, AmenityTypeUpdateInput } from '../generated/graphql';

/**
 * Get a callback to save a AmenityType in the api using either a create or update.
 */
export function useSaveAmenityTypeMutation(options: SaveInStoreOptions<AmenityType, string> = {}) {
    const [_create, createStatus] = useCreateAmenityTypeMutation();
    const create = React.useCallback((model: Partial<AmenityType>) => _create(model as AmenityTypeCreateInput), [_create]);

    const [_update, updateStatus] = useUpdateAmenityTypeMutation();
    const update = React.useCallback((id: string, changes: Partial<AmenityType>) => _update(id, changes as AmenityTypeUpdateInput), [_update]);

    return useSaveInStore(
        [create, createStatus],
        [update, updateStatus],
        options
    );
}
