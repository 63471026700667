import * as React from 'react';
import { useLazyQuery } from "@apollo/client";
import { gql } from 'graphql-tag';
import { Guid } from "guid-string";
import { mainApiConfig } from "../../../../configure/mainApiConfig";
import { EditClassStageViewModelQuery, EditClassStageViewModelQueryVariables } from '../../generated/graphql';
import { classStageFieldsFragment } from "../../models/ClassStage";
import { AsyncLoadOptions, AsyncLoadResult } from '../../../../shared/abstractStore';
import { classSubStageFieldsFragment } from '../../models/ClassSubStage';
import { lessonDocumentFieldsFragment } from '../../models/LessonDocument';
import { welcomePackDocumentFieldsFragment } from '../../models/WelcomePackDocument';
import { eCommerceLinkFieldsFragment } from '../../models/ECommerceLink';

/**
 * Load a ClassStage from the api.
 */
export function useEditClassStageViewModel(id: string | undefined | null, options: AsyncLoadOptions = {}): AsyncLoadResult<EditClassStageViewModelQuery> {
    const [load, { data, loading, error, refetch }] = useLazyQuery<EditClassStageViewModelQuery, EditClassStageViewModelQueryVariables>(
        gql`
        query editClassStageViewModelQuery ($id: ID!) {
            model: classStage(id: $id) {
                ...classStageFields
            }

            classSubStages (classStageId: $id) {
                ...classSubStageFields
            }

            lessonDocuments (classStageId: $id) {
                ...lessonDocumentFields

                blobReference {
                    id
                    url
                }
            }

            welcomePackDocuments (classStageId: $id) {
                ...welcomePackDocumentFields
            }

            eCommerceLinks (classStageId: $id) {
                ...eCommerceLinkFields
            }
        }

        ${classStageFieldsFragment}
        ${classSubStageFieldsFragment}
        ${lessonDocumentFieldsFragment}
        ${welcomePackDocumentFieldsFragment}
        ${eCommerceLinkFieldsFragment}
        `,
        {
            variables: {
                id: id ?? Guid.empty
            },
            fetchPolicy: options.fetchPolicy ?? mainApiConfig.defaultFetchPolicy,
            pollInterval: options.pollInterval,
            onCompleted: (data) => {
                if (options.onCompleted) {
                    options.onCompleted(data);
                }
            }
        }
    );

    // Run the query (unless we are being lazy).
    React.useEffect(() => {
        if (!options.lazy) {
            load();
        }
    }, [options.lazy, load]);

    // Return the results.
    const refresh = React.useCallback(async () => { !refetch ? await load() : await refetch(); }, [refetch, load]);
    return React.useMemo(() => ({
        data: data ?? ({} as any),
        refresh: refresh,
        isLoading: loading,
        errors: error
    }), [data, refresh, loading, error]);
}