import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ValidationErrors } from 'pojo-validator';
import { ValidateCallback } from 'pojo-validator-react';
import { ValidatedInput } from 'pojo-validator-reactstrap';
import { useTranslation } from 'react-i18next';
import { Button, Col, FormGroup, Label, ListGroupItem, Row } from 'reactstrap';
import { ClassSubStage } from '../../../../api/main/models/ClassSubStage';

export interface ClassStageSubStageComponentProps {
    model: ClassSubStage | undefined,
    change: (changes: Partial<ClassSubStage>) => void;
    remove: () => void,

    validate: ValidateCallback,
    validationErrors: ValidationErrors;
}

/**
 * Component under ClassStages that lets the user edit a ClassSubStage
 */
export const ClassStageSubStageComponent = (props: ClassStageSubStageComponentProps) => {
    const { model, change, remove, validate, validationErrors } = props;

    const { t } = useTranslation();

    // Render the UI
    //
    return (
        <ListGroupItem>
            <Row>
                <Col>
                    <FormGroup>
                        <Label htmlFor="name">{t('editClassStage.classSubStageComponent.name.label', 'Name')}</Label>
                        <ValidatedInput name="name" type="text" value={model?.name ?? ''} onChange={e => change({ name: e.currentTarget.value })} onBlur={e => validate('name')} validationErrors={validationErrors['name']} />
                    </FormGroup>
                </Col>
                <Col xs={2}>
                    <FormGroup>
                        <Label htmlFor="numberOfLessons">{t('editClassStage.classSubStageComponent.numberOfLessons.label', 'Number of lessons')}</Label>
                        <ValidatedInput name="numberOfLessons" tyep="number" value={model?.numberOfLessons ?? ''} onChange={e => change({ numberOfLessons: e.currentTarget.valueAsNumber })} onBlur={e => validate('numberOfLessons')} validationErrors={validationErrors['numberOfLessons']} />
                    </FormGroup>
                </Col>
                <Col xs={2}>
                    <FormGroup>
                        <Label htmlFor="displayOrder">{t('editClassStage.classSubStageComponent.displayOrder.label', 'Order')}</Label>
                        <ValidatedInput name="displayOrder" type="number" value={model?.displayOrder ?? ''} onChange={e => change({ displayOrder: e.currentTarget.valueAsNumber })} onBlur={e => validate('displayOrder')} validationErrors={validationErrors['displayOrder']} />
                    </FormGroup>
                </Col>
                <Col xs='auto'>
                    <FormGroup>
                        <Label htmlFor="name">&nbsp;</Label>
                        <div>
                            <Button color="danger" outline onClick={() => remove()}>
                                <FontAwesomeIcon icon="trash-alt" />
                                <> </>
                                {t('editClassStage.classSubStageComponent.delete', 'Delete class type')}
                            </Button>
                        </div>
                    </FormGroup>
                </Col>
            </Row>
        </ListGroupItem>
    );
};