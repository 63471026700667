import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import { ConditionalFragment } from "react-conditionalfragment";
import { useTranslation } from "react-i18next";
import { useAsyncCallback } from "react-use-async-callback";
import { Button, FormGroup, Label, ModalBody, ModalFooter, ModalHeader, Row, Spinner } from "reactstrap";
import { ButtonAsync } from "reactstrap-buttonasync";
import { useChild } from "../../../api/main/children/useChild";
import { useDeleteStaffNoteMutation } from "../../../api/main/staffNotes/useDeleteStaffNote";
import { useSaveStaffNoteMutation } from "../../../api/main/staffNotes/useSaveStaffNoteMutation";
import { useStaffNote } from "../../../api/main/staffNotes/useStaffNote";
import { AlertOnErrors } from "../../../shared/alertOnErrors";
import { HtmlEditor } from "../../../shared/htmlEditor";
import { useChanges } from "../../../shared/useChanges";
import { FormButtons } from "../../shared/formButtons/FormButtons";
import { ValidatedISODateTimeInput } from "../../shared/isoDateTimeInput/ValidatedISODateTimeInput";

export interface EditProgressNoteModalProps {
    id: string | undefined | null;
    toggleEditProgressNoteIsOpen: () => void;
}

export const EditProgressNoteModal = (props: EditProgressNoteModalProps) => {
    const {
        id,
        toggleEditProgressNoteIsOpen
    } = props;
    const { t } = useTranslation();

    // Load the data
    const {
        data: {
            model: progressNote
        }, isLoading: _isLoading, errors: loadErrors
    } = useStaffNote(id);

    // Load the supporting data
    const {
        data: {
            model: child,
        }, isLoading: isLoadingSupportingData, errors: supportingDataLoadErrors
    } = useChild(progressNote?.targetId);
    const isLoading = _isLoading || isLoadingSupportingData;

    // Model (StaffNote -> ProgressNote)
    const { model, change, changes } = useChanges(progressNote);
    const [saveProgressNote, { errors: saveErrors }] = useSaveStaffNoteMutation();

    // Delete a progress note
    const [removeProgressNote, { errors: removeErrors }] = useDeleteStaffNoteMutation();

    // Save
    const [saveForm, { isExecuting: isSaving, errors: saveFormErrors }] = useAsyncCallback(async () => {
        if (!model) return;

        // Save the progress note
        await saveProgressNote(model.id, { ...changes }, false);

        // Close the modal
        toggleEditProgressNoteIsOpen();
    }, [model, saveProgressNote, changes]);

    // Remove form
    const [onRemoveProgressNote, { isExecuting: isRemoving, errors: removeFormErrors }] = useAsyncCallback(async () => {
        if (!model) return;

        await removeProgressNote(model.id);

        // Close the modal
        toggleEditProgressNoteIsOpen();
    }, [model, removeProgressNote]);

    // Render the UI
    //
    return (
        <>
            <ConditionalFragment showIf={!isLoading}>
                <ModalHeader toggle={() => toggleEditProgressNoteIsOpen()}>
                    <h2>
                        {t('editProgressNoteModal.title', 'Progress note')}
                    </h2>

                    <p>
                        {t('common.fullName', '{{firstName}} {{lastName}}', { firstName: child?.firstName, lastName: child?.lastName })}
                    </p>

                    <AlertOnErrors errors={[
                        loadErrors, supportingDataLoadErrors,
                        saveErrors, saveFormErrors,
                        removeErrors, removeFormErrors
                    ]} />
                </ModalHeader>

                <ModalBody>
                    <Row>
                        <FormGroup>
                            <Label htmlFor="updatedDate">{t('common.updated', 'Updated')}</Label>
                            <ValidatedISODateTimeInput
                                name="updatedDate"
                                type="date"
                                value={model.updatedDate}
                                onBlur={() => null}
                                validationErrors={[]}
                                disabled={true} />
                        </FormGroup>
                    </Row>

                    <HtmlEditor value={model.contentsHtml} onChange={text => change({ contentsHtml: text })} />
                </ModalBody>

                <ModalFooter>
                    <FormButtons>
                        <ConditionalFragment showIf={!isLoading}>
                            <ButtonAsync color="primary" isExecuting={isSaving} onClick={() => saveForm()}
                                executingChildren={<><Spinner size="sm" /> {t('common.saving', 'Saving...')}</>}>
                                <FontAwesomeIcon icon="save" />
                                <> </>
                                {t('common.save', 'Save')}
                            </ButtonAsync>

                            <Button type="button" color="primary" outline onClick={() => toggleEditProgressNoteIsOpen()}>
                                {t('common.cancel', 'Cancel')}
                            </Button>

                            <ButtonAsync color="danger" isExecuting={isRemoving} onClick={() => onRemoveProgressNote()}
                                executingChildren={<> <Spinner size="sm" /> {t('common.deleting', 'Deleting...')} </>}>
                                <FontAwesomeIcon icon="trash" />
                                <> </>
                                {t('common.delete', 'Delete')}
                            </ButtonAsync>
                        </ConditionalFragment>
                    </FormButtons>
                </ModalFooter>
            </ConditionalFragment>
        </>
    );
};