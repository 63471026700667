import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useValidatorCallback } from "pojo-validator-react";
import { ConditionalFragment } from "react-conditionalfragment";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { useAsyncCallback } from "react-use-async-callback";
import { Col, Row, Spinner, Button } from "reactstrap";
import { ButtonAsync } from "reactstrap-buttonasync";
import { BlobUploadService } from "../../../api/main/blobReferences/BlobUploadService";
import { useBlobReference } from "../../../api/main/blobReferences/useBlobReference";
import { useSaveChildMutation } from "../../../api/main/children/useSaveChildMutation";
import { useMembersChildAddChildSupportingData } from "../../../api/main/children/viewModels/useMembersChildAddChildSupportingData";
import { Child, childDefaultValues } from "../../../api/main/models/Child";
import { useProfile } from "../../../api/main/profiles/useProfile";
import { AlertOnErrors } from "../../../shared/alertOnErrors";
import { useChanges } from "../../../shared/useChanges";
import { Banner } from "../../shared/banner/Banner";
import { FormButtons } from "../../shared/formButtons/FormButtons";
import { LoadingIndicator } from "../../shared/loadingIndicator/LoadingIndicator";
import { MainContainer } from "../../shared/mainContainer/MainContainer";
import { StickyToolbar } from "../../shared/stickyToolbar/StickyToolbar";
import { MainTab } from "./MainTab";
import "./membersChildrenAddChild.scss";

/**
 * Component under MembersChildrenList that allows a user to add a new Child
 */
export const MembersChildrenAddChild = () => {
    const {
        memberId
    } = useParams<{ memberId: string | undefined, }>();
    const { t } = useTranslation();
    const navigate = useNavigate();

    // Load the data
    const {
        data: {
            model: memberProfile
        }, isLoading: _isLoading, errors: loadErrors
    } = useProfile(memberId);

    // Load supporting data
    const {
        data: {
            childsRelationships,
            genders
        }, isLoading: isLoadingSupportingData, errors: supportingDataLoadErrors
    } = useMembersChildAddChildSupportingData();
    const isLoading = _isLoading || isLoadingSupportingData;

    // Model (Child)
    const { model, change, changes } = useChanges({} as Child, { ...childDefaultValues(), memberId: memberId });
    const [saveChild, { errors: saveErrors }] = useSaveChildMutation();

    // Child image upload (photo)
    const { data: { model: image }, errors: imageLoadErrors } = useBlobReference(model?.photoBlobReferenceId);
    const [onUploadImage, { errors: imageUploadErrors, isExecuting: isUploadingImage }] = useAsyncCallback(async (files: FileList | null) => {
        if (!files) {
            return;
        }

        let uploadService: BlobUploadService = new BlobUploadService("/api/blobs");
        let result = await uploadService.upload(files);

        if (!!result) {
            change({ photoBlobReferenceId: result.id });
        }
    }, [change]);
    // Clear image functionality
    const [clearImage, { isExecuting: isClearingImage }] = useAsyncCallback(async () => {
        change({ photoBlobReferenceId: null });
    }, [change]);

    // Main model validation
    const [validate, validationErrors] = useValidatorCallback((validation, fieldsToCheck) => {
        const rules = {
            firstName: () => !model?.firstName ? t('editChild.errors.firstNameRequired', 'First name is required') : '',
            lastName: () => !model?.lastName ? t('editChild.errors.lastNameRequired', 'Last name is required') : '',
            memberId: () => !model?.memberId ? t('editChild.errors.memberIdRequired', 'Member is required') : '',
            genderId: () => !model?.genderId ? t('editChild.errors.genderId', 'Gender is required') : '',
            memberChildsRelationshipId: () => !model?.memberChildsRelationshipId ? t('editChild.memberChildsRelationshipId', 'Relationship to child is required') : '',
            dateOfBirth: () => !model?.dateOfBirth ? t('editChild.dateOfBirth.errors.startDate', 'Date of birth is required') : '',
        };
        validation.checkRules(rules, fieldsToCheck);
    }, [model]);

    // Save everything
    const [saveForm, { isExecuting: isSaving, errors: saveFormErrors }] = useAsyncCallback(async () => {
        if (!model) {
            return;
        }

        if (!validate()) {
            return;
        }

        // Save the main model.
        await saveChild(model.id, { ...changes }, true);

        // Navigate to the ClassFinder for the new Child
        navigate(`/my/class-finder/${model.id}`);
    }, [validate, saveChild, model, changes, navigate,]);

    // Return the UI
    //
    return (
        <>
            <Banner fluid>
                <StickyToolbar>
                    <Row>
                        <Col xs={12} md="">
                            <h1>
                                {
                                    t('membersChildrenAddChild.createHeading.default', 'Add cub')
                                }
                            </h1>
                            <h3>{t('common.fullName', '{{firstName}} {{lastName}}', { firstName: model?.firstName ?? '', lastName: model?.lastName ?? '' })}</h3>
                        </Col>

                        <ConditionalFragment showIf={isLoading}>
                            <Col xs="auto">
                                <LoadingIndicator size="sm" />
                            </Col>
                        </ConditionalFragment>
                    </Row>
                </StickyToolbar>
            </Banner>

            <MainContainer fluid>
                <AlertOnErrors
                    errors={[
                        loadErrors,
                        supportingDataLoadErrors,
                        imageLoadErrors,
                        imageUploadErrors,
                        saveErrors,
                        saveFormErrors
                    ]}
                />
                <div className="members-children-add-child">
                    <MainTab
                        model={model}
                        change={change}
                        validate={validate}
                        validationErrors={validationErrors}
                        genders={genders}
                        isExecutingImageUpload={isUploadingImage}
                        onImageUpload={onUploadImage}
                        isExecutingClearImage={isClearingImage}
                        imageUploadClickHandler={clearImage}
                        imageUrl={image?.url}
                        childsRelationships={childsRelationships}
                        memberProfile={memberProfile}
                    />
                </div>

                {/*Save buttons at the bottom of all tabs. */}
                <FormButtons>
                    <ConditionalFragment showIf={!isLoading}>
                        <ButtonAsync color="primary" isExecuting={isSaving} onClick={() => saveForm()}
                            executingChildren={<><Spinner size="sm" /> {t('common.saving', 'Saving...')}</>}>
                            <FontAwesomeIcon icon="save" />
                            <> </>
                            {t('common.save', 'Save')}
                        </ButtonAsync>
                    </ConditionalFragment>

                    <Button type="button" color="primary" outline onClick={e => navigate(-1)}>
                        {t('common.cancel', 'Cancel')}
                    </Button>
                </FormButtons>
            </MainContainer>
        </>
    );
};