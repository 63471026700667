import * as React from 'react';
import { useSaveInStore, SaveInStoreOptions } from '../../../shared/useSaveInStore';
import { useUpdateGeneralDiscountMutation } from './useUpdateGeneralDiscountMutation';
import { GeneralDiscount } from '../models/GeneralDiscount';
import { useCreateGeneralDiscountMutation } from './useCreateGeneralDiscountMutation';
import { GeneralDiscountCreateInput, GeneralDiscountUpdateInput } from '../generated/graphql';

/**
 * Get a callback to save a GeneralDiscount in the api using either a create or update.
 */
export function useSaveGeneralDiscountMutation(options: SaveInStoreOptions<GeneralDiscount, string> = {}) {
    const [_create, createStatus] = useCreateGeneralDiscountMutation();
    const create = React.useCallback((model: Partial<GeneralDiscount>) => _create(model as GeneralDiscountCreateInput), [_create]);

    const [_update, updateStatus] = useUpdateGeneralDiscountMutation();
    const update = React.useCallback((id: string, changes: Partial<GeneralDiscount>) => _update(id, changes as GeneralDiscountUpdateInput), [_update]);

    return useSaveInStore(
        [create, createStatus],
        [update, updateStatus],
        options
    );
}
