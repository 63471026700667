import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import { Row } from "reactstrap";
import { HorizontalRule } from "../shared/horizontalRule/HorizontalRule";
import { MainContainer } from "../shared/mainContainer/MainContainer";
import './membersWelcomeScreen.scss';

/**
 * WelcomeScreen for Members, this page has no UI links and will only be hit on login.
 */
export const MembersWelcomeScreen = () => {
    const { t } = useTranslation();

    // Redner the UI
    //
    return (
        <>
            <MainContainer >
                <Row className="members-welcome-screen">
                    <Row className="members-welcome-screen-header">
                        <h1 className="members-welcome-screen-header-title">{t('membersWelcomeScreen.title.p1', 'Hello. Welcome to')}</h1>
                        <h1 className="members-welcome-screen-header-title">{t('membersWelcomeScreen.title.p2', 'Your Happy Hub')}</h1>
                        <p className="members-welcome-screen-header-subtitle">{t('membersWelcomeScreen.subtitle', 'Use the tabs above to navigate Your Happy Hub')}</p>
                    </Row>

                    <Row>
                        <p>{t('membersWelcomeScreen.clickThe.p1', 'Click the ')} <strong>{t('membersWelcomeScreen.myBearCubs', 'My Bear Cubs')}</strong> {t('membersWelcomeScreen.tabToCubsParagraph', 'tab to view, add, edit your child\'s profile, and book & manage your classes.')}</p>
                    </Row>

                    <Row>
                        <HorizontalRule />
                    </Row>

                    <Row>
                        <p>{t('membersWelcomeScreen.clickThe.p2', 'Click the')} <strong>{t('membersWelcomeScreen.payments', 'Payments')}</strong> {t('membersWelcomeScreen.tabToPaymentsParagraph', 'tab to check your booking history, outstanding payments, class credits, and discounts.')}</p>
                    </Row>

                    <Row>
                        <HorizontalRule />
                    </Row>

                    <Row>
                        <p>{t('membersWelcomeScreen.needSupportParagraph', 'Need support or have questions? Click the')} <strong>{t('membersWelcomeScreen.messages', 'Messages')}</strong> {t('membersWelcomeScreen.tabToMessagesParagraph', 'tab to send us a message. Our team will reply soon.')}</p>
                    </Row>

                    <Row>
                        <HorizontalRule />
                    </Row>

                    <Row>
                        <p>{t('membersWelcomeScreen.updateYourParagraph', 'Update your account details or sign out of your account on the')} <strong>{t('membersWelcomeScreen.profileIcon', 'Profile Icon')}</strong> (<FontAwesomeIcon icon="user" />)</p>
                    </Row>
                </Row>
            </MainContainer>
        </>
    );
};