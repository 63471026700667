import gql from 'graphql-tag';
import { Guid } from 'guid-string';
import { LessonDocumentFieldsFragment } from '../generated/graphql';

export const lessonDocumentFieldsFragment = gql`
    fragment lessonDocumentFields on LessonDocument {
        id
        name
        archived
        classStageId
        classSubStageId
        termLessonNumber
        blobReferenceId
        isLessonPlan
    }
`;

export type LessonDocument = Omit<LessonDocumentFieldsFragment, '__typename'>;

/**
 * Default values.
 */
export const lessonDocumentDefaultValues = (): Partial<LessonDocument> => ({
    id: Guid.newGuid(),
    name: '',
    archived: false,
    classStageId: undefined,
    classSubStageId: null,
    termLessonNumber: 0,
    blobReferenceId: null,
    isLessonPlan: false,
});