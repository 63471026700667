import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import { useCallback, useMemo } from "react";
import { ConditionalFragment } from "react-conditionalfragment";
import { useTranslation } from "react-i18next";
import { Button, CardImg, CardImgOverlay, CardTitle, Col, Modal, ModalBody, ModalFooter, Row } from "reactstrap";
import { useBlobReferenceForAnoymousUser } from "../../api/main/blobReferences/viewModels/useBlobReferenceForAnoymousUser";
import { dayOfWeekDisplayName } from "../../api/main/models/constants/DayOfWeek";
import { AlertOnErrors } from "../../shared/alertOnErrors";
import { LoadingIndicator } from "../shared/loadingIndicator/LoadingIndicator";
import { ScheduledClassWithRelationships } from "./ClassFinder";
import "./classDetailsModal.scss";

interface ClassDetailsModalProps {
    scheduledClass: ScheduledClassWithRelationships | undefined | null;
    isOpen: boolean;
    toggle: () => void;
}

export const ClassDetailsModal = (props: ClassDetailsModalProps) => {
    const {
        scheduledClass,
        isOpen,
        toggle
    } = props;
    const { t } = useTranslation();

    // Load the image
    const {
        data: {
            model: image,
        }, isLoading: _isLoading, errors: loadErrors,
    } = useBlobReferenceForAnoymousUser(scheduledClass?.classStage?.photoBlobReferenceId);
    const isLoading = _isLoading;

    // Calculate the class duration
    const classDuration = useMemo(() => {
        if (!scheduledClass) return 0;

        const start = scheduledClass.startTimeHours * 60 + scheduledClass.startTimeMinutes;
        const end = scheduledClass.endTimeHours * 60 + scheduledClass.endTimeMinutes;

        return end - start;
    }, [scheduledClass]);

    // Format the age for display.
    const formatAge = useCallback((years: number, months: number) => {
        const totalMonths = (years * 12) + months;

        if (totalMonths <= 18) {
            return `${totalMonths} months`;
        } else {
            const numberOfYears = Math.floor(totalMonths / 12);
            const remainingMonths = totalMonths - (numberOfYears * 12);

            if (remainingMonths >= 6) {
                return `${numberOfYears}.5 ${numberOfYears === 1 ? 'yr' : 'yrs'}`;
            }

            if (remainingMonths === 0) {
                return `${numberOfYears} ${numberOfYears === 1 ? 'yr' : 'yrs'}`;
            } else {
                return `${numberOfYears}.${remainingMonths} ${numberOfYears === 1 ? 'yr' : 'yrs'}`;
            }
        }
    }, []);

    // Lesson dates
    const classDates = useMemo(() => {
        if (!scheduledClass) return;

        // Arrange dates so that if we had dates across several months we output like: 10th, 17th, 24th May, 7th, 14th, 21st, 28th June, 5th, 12th, 19th, 26th July.
        // We can do this by grouping by month and then sorting by date.
        const months = scheduledClass.lessonDatesForCurrentTerm?.reduce((acc, curr) => {
            const monthName = moment(curr).format('MMMM');

            const monthDates = acc.find(it => it.monthName === monthName);

            if (monthDates) {
                monthDates.dates.push(new Date(curr));
            } else {
                acc.push({ monthName, dates: [new Date(curr)] });
            }

            return acc;
        }, [] as { monthName: string, dates: Date[]; }[]);

        // Sort the dates
        months?.forEach(it => {
            it.dates.sort((a, b) => a.getDate() - b.getDate());
        });

        return months;
    }, [scheduledClass]);

    // Render the UI
    //
    return (
        <>
            <Modal
                isOpen={isOpen}
                toggle={() => toggle()}
                className="class-details-modal"
            >

                <AlertOnErrors errors={[loadErrors]} />

                <Row className="class-details-modal-header justify-items-between align-items-baseline">
                    <Col xs={12} sm={6} className="class-details-modal-header-location">
                        {scheduledClass?.classLocation?.name}
                    </Col>

                    <Col xs={12} sm={6} className="class-details-modal-header-class">
                        {t('classFinderClass.startTime.formmated.dayName', '{{dow}} @ {{hours, 00}}:{{minutes, 00}}{{amOrPm}}', { dow: dayOfWeekDisplayName(scheduledClass?.dayOfWeek ?? 0, t), hours: scheduledClass?.startTimeHours, minutes: scheduledClass?.startTimeMinutes, amOrPm: scheduledClass?.startTimeHours ?? 0 < 12 ? 'am' : 'pm' })}
                    </Col>
                </Row>

                <Row className="class-details-modal-sub-header align-items-baseline">
                    <Col xs={1} className="class-details-modal-sub-header-icon">
                        <FontAwesomeIcon icon="location-pin" />
                    </Col>

                    <Col xs={11} className="class-details-modal-sub-header-address">
                        <p>{scheduledClass?.classLocation?.address}</p>
                    </Col>
                </Row>

                <ModalBody className="class-details-modal-details">
                    <div className="class-details-modal-details-card">
                        <ConditionalFragment showIf={!isLoading && !!image}>
                            <CardImg
                                className="class-details-modal-details-card-image"
                                alt="Card image cap"
                                src={image?.url}
                                top
                                style={{ marginLeft: '-16px', marginRight: '-16px', width: 'calc(100% + 32px)' }}
                            />

                            <CardImgOverlay>
                                <CardTitle tag="h5" className="class-details-modal-details-card-overlay">
                                    <Row>
                                        <Col style={{ marginBottom: '0' }}>
                                            {scheduledClass?.classStage?.name}
                                        </Col>
                                    </Row>
                                </CardTitle>
                            </CardImgOverlay>
                        </ConditionalFragment>

                        <ConditionalFragment showIf={!!isLoading}>
                            <LoadingIndicator />
                        </ConditionalFragment>

                        <Row className="mt-2 justify-content-between">
                            <Col className="p-0">
                                <FontAwesomeIcon icon="clock" className="class-details-modal-details-card-body-time-icon" />
                                <> </>
                                {t('classFinderClass.startTime.formmated.noDay', '{{hours, 00}}:{{minutes, 00}}', { hours: scheduledClass?.startTimeHours, minutes: scheduledClass?.startTimeMinutes })}
                                <> - </>
                                {t('classFinderClass.endTime.formmated.noDay', '{{hours, 00}}:{{minutes, 00}}', { hours: scheduledClass?.endTimeHours, minutes: scheduledClass?.endTimeMinutes })}
                            </Col>

                            <Col xs="auto" className="class-details-modal-details-card-body-time-duration">
                                {t('classFinderClass.class.duration', '{{minutes}} min class', { minutes: classDuration })}
                            </Col>
                        </Row>

                        <Row>
                            <Col className="p-0">
                                <img src="/img/originals/Cub-Cropped.png" alt="Happy Cubs Bear Cub" className="class-details-modal-details-card-body-stage-icon" />
                                <> </>
                                {scheduledClass?.classStage?.name} {scheduledClass?.classSubStage?.name}
                            </Col>

                            <Col xs="auto" className="class-details-modal-details-card-body-stage-ages">
                                {t('classDetailsModal.class.ages', 'ages')}
                                <> </>
                                {formatAge(scheduledClass?.classStage?.startAgeYears ?? 0, scheduledClass?.classStage?.startAgeMonths ?? 0)}
                                <> - </>
                                {formatAge(scheduledClass?.classStage?.endAgeYears ?? 0, scheduledClass?.classStage?.endAgeMonths ?? 0)}
                            </Col>
                        </Row>

                        <Row>
                            <Col className="p-0">
                                <FontAwesomeIcon icon="euro-sign" className="class-details-modal-details-card-body-price-icon" />
                                <> </>
                                {t('common.money.euro', '€{{value, 0.00}}', { value: (scheduledClass?.lessonsPerTerm ?? 0) * (scheduledClass?.costPerLesson ?? 0) })}
                                <> </>
                                {t('classFinderClass.price.weeks', 'for {{weeks}} weeks', { weeks: scheduledClass?.lessonsPerTerm })}
                            </Col>

                            <Col xs="auto">
                                <a href={`/stages-information?classType=${scheduledClass?.classTypeId}`} target="_blank" rel="noreferrer" className="class-details-modal-details-card-body-price-stages-info">
                                    <FontAwesomeIcon icon="info-circle" />
                                    <span style={{ margin: 0, marginLeft: '8px' }}>{t('common.stagesInfo', 'Stage info')}</span>
                                </a>
                            </Col>
                        </Row>

                        <Row>
                            <Col className="p-0">
                                <FontAwesomeIcon icon="calendar-days" className="class-details-modal-details-card-body-dates-icon" />
                                <> </>
                                <span>{t('common.termDates', 'Term dates')}</span>
                            </Col>
                        </Row>
                        {
                            classDates?.map(it => {
                                const currentDates = it.dates;

                                return (
                                    <Col className="p-0" style={{ marginLeft: '26px' }}>
                                        <>
                                            {currentDates?.map(item => (
                                                <span>{t('common.dayOnly', '{{date, Do}}, ', { date: moment(item) })}</span>
                                            ))}
                                        </>
                                        <> </>
                                        {it.monthName}
                                    </Col>
                                );
                            })
                        }
                    </div>
                </ModalBody>

                <ModalFooter className="class-details-modal-details-footer">
                    <Col className="class-details-modal-details-footer-type">
                        <img src={`/img/originals/${scheduledClass?.classType?.name.split(' ').join('')}Logo.png`} alt="Happy Cubs Bear Cub" style={{ height: '60px' }} />
                    </Col>

                    <Col xs="auto">
                        <Button color="secondary" onClick={() => toggle()}>{t('common.close', 'Close')}</Button>
                    </Col>
                </ModalFooter>
            </Modal >
        </>
    );
};