import * as React from 'react';
import { useSaveInStore, SaveInStoreOptions } from '../../../shared/useSaveInStore';
import { useUpdateScheduledClassStaffMutation } from './useUpdateScheduledClassStaffMutation';
import { ScheduledClassStaff } from '../models/ScheduledClassStaff';
import { useCreateScheduledClassStaffMutation } from './useCreateScheduledClassStaffMutation';
import { ScheduledClassStaffCreateInput, ScheduledClassStaffUpdateInput } from '../generated/graphql';

/**
 * Get a callback to save a ScheduledClassStaff in the api using either a create or update.
 */
export function useSaveScheduledClassStaffMutation(options: SaveInStoreOptions<ScheduledClassStaff, string> = {}) {
    const [_create, createStatus] = useCreateScheduledClassStaffMutation();
    const create = React.useCallback((model: Partial<ScheduledClassStaff>) => _create(model as ScheduledClassStaffCreateInput), [_create]);

    const [_update, updateStatus] = useUpdateScheduledClassStaffMutation();
    const update = React.useCallback((id: string, changes: Partial<ScheduledClassStaff>) => _update(id, changes as ScheduledClassStaffUpdateInput), [_update]);

    return useSaveInStore(
        [create, createStatus],
        [update, updateStatus],
        options
    );
}
