import React, { useMemo } from "react";
import { ScheduledCalendarViewDimension, ScheduledClassWithRelationships } from "./ScheduledCalendarViewDimensions";
import "./scheduleCalendarView.scss";
import { CalendarEvent } from "./CalendarEvent";
import { ConditionalFragment } from "react-conditionalfragment";
import { ScheduledClass } from "../../../api/main/models/ScheduledClass";

/**
 * Props for ScheduleCalendarView.
 */
export interface ScheduleCalendarViewProps {
    items: Array<ScheduledClassWithRelationships>,
    groupDimension?: ScheduledCalendarViewDimension,
    rowDimension: ScheduledCalendarViewDimension,
    columnDimension: ScheduledCalendarViewDimension,
    colorDimension?: ScheduledCalendarViewDimension,

    onEventClick?: (event: ScheduledClass) => void,
}

/**
 * Component that renders a flexible calendar like view of classes.
 * @param props
 */
export const ScheduleCalendarView = (props: ScheduleCalendarViewProps) => {
    const {
        items,
        groupDimension,
        rowDimension,
        columnDimension,
        colorDimension,
        onEventClick,
    } = props;

    // Get the groups for the groups, rows, and columns.
    const groupGroups = useMemo(() => {
        // If no secondaryRowDimension we need to return a single item array that we'll know not to render the UI for.
        if (!groupDimension) {
            return [{ id: '', name: '', }];
        }

        // Otherwise return the actual row groups.
        return groupDimension.groupsFunction(items);
    }, [groupDimension, items]);
    const rowGroups = useMemo(() => rowDimension.groupsFunction(items), [rowDimension, items]);
    const columnGroups = useMemo(() => columnDimension.groupsFunction(items), [columnDimension, items]);

    // For colors, we work through a fix length color wheel (which have associated CSS classes) to assign colors based on this grouping, so lets
    // construct the lookup table now.
    const colorIndexMap = useMemo((): { [key: string]: number; } => {
        if (!colorDimension) {
            return {};
        }

        // Go through each group and assign a colour from the map to it.
        // NOTE we work as if an unlimited number of colours are available.  It is up to CalendarEvent to wrap the colours if needed.
        let ret: { [key: string]: number; } = {};
        const groups = colorDimension.groupsFunction(items);
        for (let i = 0; i < groups.length; ++i) {
            ret[groups[i].id] = i;
        }

        return ret;
    }, [colorDimension, items]);
    
    // Render the UI.
    return (
        <div className="schedule-calendar-view">
            <div className="schedule-calendar-view-headings">
                <div className="schedule-calendar-view-headings-row-and-column-label">
                    {/* This is to create a space in the top left above the row names. */}
                </div>
                {
                    columnGroups.map(columnGroup => (
                        <div key={columnGroup.id} className="schedule-calendar-view-column-label">
                            {columnGroup.name}
                        </div>
                    ))
                }
            </div>
            <div className="schedule-calendar-view-body">
                {
                    groupGroups.map(groupGroup => (
                        <React.Fragment key={groupGroup.id}>
                            <ConditionalFragment showIf={!!groupGroup.id} key={groupGroup.id}>
                                <div className="schedule-calendar-view-row-group-heading" key={groupGroup.id}>
                                    {groupGroup.name}
                                </div>
                            </ConditionalFragment>

                            {
                                rowGroups.map(rowGroup => {
                                    // If this row is going to have no items in it, then skip the row.  This prevents us having
                                    // rows within a group where they are empty or logically don't belong from a user's perspective.
                                    if (!!groupDimension) {
                                        const rowEvents = items.filter(item =>
                                            rowDimension.filterFunction(rowGroup.id, item)
                                            && groupDimension.filterFunction(groupGroup.id, item)
                                        );
                                        if (!rowEvents.length) {
                                            return null;
                                        }
                                    }

                                    // Render the row.
                                    return (
                                        <div key={rowGroup.id} className="schedule-calendar-view-row">
                                            <div className="schedule-calendar-view-row-label">
                                                {rowGroup.name}
                                            </div>

                                            {
                                                columnGroups.map(columnGroup => {
                                                    // Get the events that match the row, secondary row, and column.
                                                    let events: Array<ScheduledClassWithRelationships>;
                                                    if (!groupDimension) {
                                                        events = items.filter(item =>
                                                            rowDimension.filterFunction(rowGroup.id, item)
                                                            && columnDimension.filterFunction(columnGroup.id, item)
                                                        );
                                                    } else {
                                                        events = items.filter(item =>
                                                            rowDimension.filterFunction(rowGroup.id, item)
                                                            && columnDimension.filterFunction(columnGroup.id, item)
                                                            && groupDimension.filterFunction(groupGroup.id, item)
                                                        );
                                                    }

                                                    // UI.
                                                    return (
                                                        <div key={columnGroup.id} className="schedule-calendar-view-cell">
                                                            {
                                                                events.map(event => {
                                                                    // Look up our colorIndex from the color map.
                                                                    let colorIndex = 0;
                                                                    if (colorDimension) {
                                                                        const colorValueId = colorDimension?.getIdFunction(event);
                                                                        const myColorIndex = colorIndexMap[colorValueId];
                                                                        if (myColorIndex !== undefined) {
                                                                            colorIndex = myColorIndex;
                                                                        }
                                                                    }

                                                                    return (
                                                                        <CalendarEvent key={event.id + columnGroup.id}
                                                                            event={event}
                                                                            onClick={() => onEventClick?.(event)}
                                                                            colorIndex={colorIndex}
                                                                        />
                                                                    );
                                                                })
                                                            }
                                                        </div>
                                                    );
                                                })
                                            }
                                        </div>
                                    );
                                })
                            }
                        </React.Fragment>
                    ))
                }
            </div>
        </div>
    );
};