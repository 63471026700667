import { IdentityRoles } from "../../configure/security/IdentityRoles";
import { RouteEntry } from "../../shared/routes";
import { DeleteGeneralDiscount } from "./DeleteGeneralDiscount";
import { CreateGeneralDiscount, EditGeneralDiscount } from "./EditGeneralDiscount";
import { GeneralDiscountsList } from "./GeneralDiscountsList";


/**
 * Routes that should be added to configuration/routeConfig.ts to provide access to these components via routing.
 */
export const generalDiscountsRoutes: Array<RouteEntry> = [
    { path: '/administration/children-management/general-discounts/add', element: <CreateGeneralDiscount />, authorize: true, requireRole: IdentityRoles.Administration, },
    { path: '/administration/children-management/general-discounts/edit/:id', element: <EditGeneralDiscount />, authorize: true, requireRole: IdentityRoles.Administration, },
    { path: '/administration/children-management/general-discounts/delete/:id', element: <DeleteGeneralDiscount />, authorize: true, requireRole: IdentityRoles.Administration, },
    { path: '/administration/children-management/general-discounts', element: <GeneralDiscountsList />, authorize: true, requireRole: IdentityRoles.Administration, },
];
