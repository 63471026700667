import { useLazyQuery } from "@apollo/client";
import gql from "graphql-tag";
import React, { useMemo } from "react";
import { mainApiConfig } from "../../../../configure/mainApiConfig";
import { AsyncLoadOptions, AsyncLoadResult } from "../../../../shared/abstractStore";
import { StaffTimeOffTabViewModelQuery, StaffTimeOffTabViewModelQueryVariables } from "../../generated/graphql";
import { Guid } from "guid-string";
import { staffAbsenceFieldsFragment } from "../../models/StaffAbsence";
import { staffAbsenceTypeFieldsFragment } from "../../models/StaffAbsenceType";

/**
 * Get all data required for the StaffTimeOffTabViewModel component from the api.
 * @param options
 */
export function useStaffTimeOffTabViewModel(userId: string | undefined | null, options: AsyncLoadOptions = {}): AsyncLoadResult<StaffTimeOffTabViewModelQuery> {
    // Query data we need from the api.
    const [load, { data, loading, error, refetch, }] = useLazyQuery<StaffTimeOffTabViewModelQuery, StaffTimeOffTabViewModelQueryVariables>(
        gql`
        query staffTimeOffTabViewModelQuery ($userId: ID!) {
            staffAbsenceTypes {
                ...staffAbsenceTypeFields
            }

            staffAbsences (staffId: $userId) {
                ...staffAbsenceFields
            }
        }

        ${staffAbsenceFieldsFragment}
        ${staffAbsenceTypeFieldsFragment}
        `,
        {
            variables: {
                userId: userId ?? Guid.empty,
            },
            fetchPolicy: options.fetchPolicy ?? mainApiConfig.defaultFetchPolicy,
            pollInterval: options.pollInterval,
            onCompleted: (data) => {
                if (options.onCompleted) {
                    options.onCompleted(data);
                }
            }
        }
    );

    // Run the query (unless we are being lazy).
    React.useEffect(() => {
        if (!options.lazy) {
            load();
        }
    }, [options.lazy, load]);

    // Return the results.
    const refresh = React.useCallback(async () => { !refetch ? await load() : await refetch(); }, [refetch, load]);
    return useMemo(() => ({
        data: data ?? ({} as any),
        refresh: refresh,
        isLoading: loading,
        errors: error,
    }), [data, refresh, loading, error,]);
};