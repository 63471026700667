import * as React from 'react';
import { useSaveInStore, SaveInStoreOptions } from '../../../shared/useSaveInStore';
import { useUpdateProfileMutation } from './useUpdateProfileMutation';
import { Profile } from '../models/Profile';
import { useCreateProfileMutation } from './useCreateProfileMutation';
import { ProfileCreateInput, ProfileUpdateInput } from '../generated/graphql';

/**
 * Get a callback to save a Profile in the api using either a create or update.
 */
export function useSaveProfileMutation(options: SaveInStoreOptions<Profile, string> = {}) {
    const [_create, createStatus] = useCreateProfileMutation();
    const create = React.useCallback((model: Partial<Profile>) => _create(model as ProfileCreateInput), [_create]);

    const [_update, updateStatus] = useUpdateProfileMutation();
    const update = React.useCallback((id: string, changes: Partial<Profile>) => _update(id, changes as ProfileUpdateInput), [_update]);

    return useSaveInStore(
        [create, createStatus],
        [update, updateStatus],
        options
    );
}
