import { useTranslation } from "react-i18next";
import { Col, FormGroup, Label, ModalBody, ModalHeader, Row, Form, Spinner, Button, ModalFooter } from "reactstrap";
import { AlertOnErrors } from "../../../shared/alertOnErrors";
import { useEditStaffAbsenceViewModel } from "../../../api/main/staffAbsences/viewModels/useEditStaffAbsenceViewModel";
import { useChanges } from "../../../shared/useChanges";
import { StaffAbsence, staffAbsenceDefaultValues } from "../../../api/main/models/StaffAbsence";
import moment from "moment";
import { useSaveStaffAbsenceMutation } from "../../../api/main/staffAbsences/useSaveStaffAbsenceMutation";
import { ValidatedISODateTimeInput } from "../../shared/isoDateTimeInput/ValidatedISODateTimeInput";
import { ValidatedInput } from "pojo-validator-reactstrap";
import { ConditionalFragment } from "react-conditionalfragment";
import { ButtonAsync } from "reactstrap-buttonasync";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
import { useValidatorCallback } from "pojo-validator-react";
import { useAsyncCallback } from "react-use-async-callback";

export interface EditStaffAbsenceModalProps {
    toggle: () => void,

    isCreate?: boolean,
    onCreateDefaultValues?: () => Partial<StaffAbsence>,
    id?: string | null | undefined,
    staffId: string,
}

/**
 * ComponentModal to Create a StaffAbsence 
 */
export const CreateStaffAbsenceModal = (props: EditStaffAbsenceModalProps) => (<EditStaffAbsenceModal isCreate={true} {...props} />);

/**
 * ComponentModal to Edit a StaffAbsence 
 */
export const EditStaffAbsenceModal = (props: EditStaffAbsenceModalProps) => {
    const {
        toggle,
        isCreate,
        onCreateDefaultValues,
        id,
        staffId
    } = props;
    const { t } = useTranslation();
    const navigate = useNavigate(); 

    // Load the data
    const {
        data: {
            model: storeModel,
            staffAbsenceTypes
        }, isLoading, errors: loadErrors
    } = useEditStaffAbsenceViewModel(id);

    // Model (StaffAbsence)
    const { model, change, changes, } = useChanges(storeModel, isCreate ? { ...staffAbsenceDefaultValues(), staffId: staffId, submittedDate: moment(Date.now()).toISOString(), acceptanceState: 'Submitted', ...(onCreateDefaultValues ? onCreateDefaultValues() : {}) } : undefined);
    const [saveStaffAbsence, { errors: saveErrors }] = useSaveStaffAbsenceMutation();
   
    // Main model validation
    const [validate, validationErrors] = useValidatorCallback((validation, fieldsToCheck) => {
        const rules = {
            startDate: () => !model?.startDate ? t('editStaffAbsenceModal.errors.startDate', 'Start date is required') : '',
            endDate: () => !model?.endDate ? t('editStaffAbsenceModal.errors.endDate', 'End date is required') : '',
            staffAbsenceTypeId: () => !model?.staffAbsenceTypeId ? t('editStaffAbsenceModal.errors.staffAbsenceType', 'Absence type is required') : '',
        };

        validation.checkRules(rules, fieldsToCheck);
    }, [model]);

    // Save everything
    const [saveForm, { isExecuting: isSaving, errors: saveFormErrors }] = useAsyncCallback(async () => {
        if (!model) {
            return;
        }

        if (!validate()) {
            return;
        }

        if (!isCreate) {
            changes.acceptanceState = 'Submitted'
        }

        // Save the main model.
        await saveStaffAbsence(model.id, { ...changes }, isCreate ?? false);

        // Close the modal.
        toggle();
    }, [model, changes, isCreate, id, navigate]);

    // Calculate StaffAbsence duration
    const calculateDaysBetweenTwoDates = () => {
        // First we can get the number of 
        const _millisecondsInADay = 24 * 3600 * 1000;

        // We can then calculate the difference in Milliseconds and then divide by the number of Milliseconds in a day to get the number of days
        const differenceInMilliseconds = new Date(model?.endDate).getTime() - new Date(model?.startDate).getTime();
        const differenceInDays = differenceInMilliseconds / _millisecondsInADay;

        return Math.ceil(differenceInDays === 0 ? 1 : differenceInDays); // If days is 0 it should really be one in terms of an absence
    }

    // Render the UI
    //
    return (
        <>
            <AlertOnErrors
                errors={[
                    loadErrors,
                    saveErrors,
                    saveFormErrors
                ]}
            />

            <ModalHeader toggle={toggle}>
                <Row>
                    <Col >
                        <h1>
                            {
                                isCreate ? t('editStaffAbsenceModal.create.title', 'Request time off') : t('editStaffAbsenceModal.edit.title', 'Edit time off')
                            }
                        </h1>
                    </Col>
                </Row>
            </ModalHeader>

            <ModalBody>
                <Form onSubmit={e => { e.preventDefault(); }}>
                    <Row>
                        <Col>
                            <FormGroup>
                                <Label htmlFor="startDate">{t('editStaffAbsenceModal.startDate.label', 'Start date')}</Label>
                                <ValidatedISODateTimeInput
                                    name="startDate"
                                    type="date"
                                    value={model?.startDate ?? ''}
                                    onChange={e => change({ startDate: e.currentTarget.value })}
                                    onBlur={e => validate('startDate')}
                                    validationErrors={validationErrors['startDate']} />
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup>
                                <Label htmlFor="endDate">{t('editStaffAbsenceModal.endDate.label', 'End date')}</Label>
                                <ValidatedISODateTimeInput
                                    name="endDate"
                                    type="date"
                                    value={model?.endDate ?? ''}
                                    onChange={e => change({ endDate: e.currentTarget.value })}
                                    onBlur={e => validate('endDate')}
                                    validationErrors={validationErrors['endDate']} />
                            </FormGroup>
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <FormGroup>
                                <Label htmlFor="absenceType">{t('editStaffAbsenceModal.absenceType.label', 'Absence type')}</Label>
                                <ValidatedInput
                                    name="absenceType"
                                    type="select"
                                    value={model?.staffAbsenceTypeId ?? ''}
                                    onChange={e => change({ staffAbsenceTypeId: e.currentTarget.value })}
                                    onBlur={e => validate('staffAbsenceTypeId')}
                                    validationErrors={validationErrors['staffAbsenceTypeId']}>
                                    {/* Options */}
                                    <option value="">{t('editStaffAbsenceModal.absenceType.pleaseSelect', 'Please select an absence type')}</option>
                                    {
                                        staffAbsenceTypes?.map(item => (

                                            <option key={item.id} value={item.id}>
                                                {t('editStaffAbsenceModal.absenceType.value', '{{absenceTypeName}}', { absenceTypeName: item.name })}
                                            </option>
                                        ))
                                    }
                                </ValidatedInput>
                            </FormGroup>
                        </Col>
                    </Row>

                    <ConditionalFragment showIf={calculateDaysBetweenTwoDates() >= 0}>
                        <Row>
                            <Col>
                                {t('editStaffAbsenceModal.totalDays.message', 'The current request if for {{numberOfDays}} days', { numberOfDays: calculateDaysBetweenTwoDates() })}
                            </Col>
                        </Row>
                    </ConditionalFragment>

                    <ConditionalFragment showIf={!isCreate}>
                        <Row>
                            <Col>
                                <p style={{ color: 'red', fontWeight: "bold" }}>
                                    {t('editStaffAbsenceModal.isEdit.amendingMessage', 'Amending a time off request will send the request for approval again.')}
                                </p>
                            </Col>
                        </Row>
                    </ConditionalFragment>

                   
                </Form>
            </ModalBody>

            <ModalFooter>
                <ConditionalFragment showIf={!isLoading}>
                    <ButtonAsync color="primary" isExecuting={isSaving} onClick={() => saveForm()}
                        executingChildren={<><Spinner size="sm" /> {t('common.submitting', 'Submitting request...')}</>}>
                        <FontAwesomeIcon icon="save" />
                        <> </>
                        {t('editStaffAbsenceModal.submitRequest.button', 'Submit request')}
                    </ButtonAsync>
                    <Button type="button" color="primary" outline onClick={e => toggle()}>
                        {t('common.cancel', 'Cancel')}
                    </Button>
                </ConditionalFragment>
            </ModalFooter>
        </>
    );
}