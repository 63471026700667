import * as React from 'react';
import { useSaveInStore, SaveInStoreOptions } from '../../../shared/useSaveInStore';
import { useUpdateCourtesyClassCreditMutation } from './useUpdateCourtesyClassCreditMutation';
import { CourtesyClassCredit } from '../models/CourtesyClassCredit';
import { useCreateCourtesyClassCreditMutation } from './useCreateCourtesyClassCreditMutation';
import { CourtesyClassCreditCreateInput, CourtesyClassCreditUpdateInput } from '../generated/graphql';

/**
 * Get a callback to save a CourtesyClassCredit in the api using either a create or update.
 */
export function useSaveCourtesyClassCreditMutation(options: SaveInStoreOptions<CourtesyClassCredit, string> = {}) {
    const [_create, createStatus] = useCreateCourtesyClassCreditMutation();
    const create = React.useCallback((model: Partial<CourtesyClassCredit>) => _create(model as CourtesyClassCreditCreateInput), [_create]);

    const [_update, updateStatus] = useUpdateCourtesyClassCreditMutation();
    const update = React.useCallback((id: string, changes: Partial<CourtesyClassCredit>) => _update(id, changes as CourtesyClassCreditUpdateInput), [_update]);

    return useSaveInStore(
        [create, createStatus],
        [update, updateStatus],
        options
    );
}
