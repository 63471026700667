import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Col, FormGroup, Label, Form, Row, Spinner } from 'reactstrap';
import { StaffAbsenceType, staffAbsenceTypeDefaultValues } from '../../api/main/models/StaffAbsenceType';
import { Banner } from '../shared/banner/Banner';
import { StickyToolbar } from '../shared/stickyToolbar/StickyToolbar';
import { useStaffAbsenceType } from '../../api/main/staffAbsenceTypes/useStaffAbsenceType';
import { useChanges } from '../../shared/useChanges';
import { useValidatorCallback } from 'pojo-validator-react';
import { useAsyncCallback } from 'react-use-async-callback';
import { useSaveStaffAbsenceTypeMutation } from '../../api/main/staffAbsenceTypes/useSaveStaffAbsenceTypeMutation';
import { ConditionalFragment } from 'react-conditionalfragment';
import { LoadingIndicator } from '../shared/loadingIndicator/LoadingIndicator';
import { MainContainer } from '../shared/mainContainer/MainContainer';
import { AlertOnErrors } from '../../shared/alertOnErrors';
import { ValidatedInput } from 'pojo-validator-reactstrap';
import { FormButtons } from '../shared/formButtons/FormButtons';
import { ButtonAsync } from 'reactstrap-buttonasync';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export interface EditStaffAbsenceTypeProps {
    isCreate?: boolean,
    onCreateDefaultValues?: () => Partial<StaffAbsenceType>;
}

/**
 * Component to create a StaffAbsenceType
 */
export const CreateStaffAbsenceType = (props: EditStaffAbsenceTypeProps) => (<EditStaffAbsenceType isCreate={true} {...props} />);

/**
 * Component to edit a StaffAbsenceType
 */
export const EditStaffAbsenceType = (props: EditStaffAbsenceTypeProps) => {
    const {
        isCreate,
        onCreateDefaultValues
    } = props;

    const { t } = useTranslation();
    const { id } = useParams<{ id: string | undefined; }>();
    const navigate = useNavigate();

    // Load all data
    const {
        data: {
            model: storeModel,
        },
        errors: loadErrors, isLoading
    } = useStaffAbsenceType(id);

    // Model (StaffAbsenceType)
    const { model, change, changes } = useChanges(storeModel, isCreate ? { ...staffAbsenceTypeDefaultValues(), ...(onCreateDefaultValues ? onCreateDefaultValues() : {}) } : undefined);
    const [saveStaffAbsenceType, { errors: saveErrors }] = useSaveStaffAbsenceTypeMutation();

    // Main model validation
    const [validate, validationErrors] = useValidatorCallback((validation, fieldsToCheck) => {
        const rules = {
            name: () => !model?.name ? t('editStaffAbsenceType.errors.name', 'Name cannot be empty') : '',
        };
        validation.checkRules(rules, fieldsToCheck);
    }, [model]);

    // Save everything
    const [saveForm, { isExecuting: isSaving, errors: saveFormErrors }] = useAsyncCallback(async () => {
        if (!model) {
            return;
        }

        if (!validate()) {
            return;
        }

        // Save the main model.
        await saveStaffAbsenceType(model.id, { ...changes }, isCreate ?? false);

        // Go back to previous screen.
        navigate(-1);
    }, [validate, saveStaffAbsenceType, model, changes, isCreate, id, navigate]);

    return (
        <>
            <Banner>
                <StickyToolbar>
                    <Row>
                        <Col>
                            <h1>
                                {
                                    isCreate ? t('editStaffAbsenceType.createHeading.default', 'Add staff absence type')
                                        : t('editStaffAbsenceType.editHeading.default', 'Edit staff absence type')
                                }
                            </h1>

                            <h3>{model?.name}</h3>
                        </Col>

                        <ConditionalFragment showIf={isLoading}>
                            <Col xs="auto">
                                <LoadingIndicator size="sm" />
                            </Col>
                        </ConditionalFragment>
                    </Row>
                </StickyToolbar>
            </Banner>

            <MainContainer>
                <AlertOnErrors errors={[
                    loadErrors,
                    saveFormErrors,
                    saveErrors
                ]} />

                <Form onSubmit={e => { e.preventDefault(); saveForm(); }}>
                    <FormGroup>
                        <Label htmlFor="name">{t('editStaffAbsenceType.name.label', 'Name')}</Label>
                        <ValidatedInput name="name" type="text" value={model?.name ?? ''} onChange={e => change({ name: e.currentTarget.value })} onBlur={e => validate('name')} validationErrors={validationErrors['name']} />
                    </FormGroup>

                    <FormGroup>
                        <Label htmlFor="name">{t('editStaffAbsenceType.displayOrder.label', 'Order')}</Label>
                        <ValidatedInput name="displayOrder" type="number" value={model?.displayOrder ?? ''} onChange={e => change({ displayOrder: e.currentTarget.valueAsNumber })} onBlur={e => validate('displayOrder')} validationErrors={validationErrors['displayOrder']} />
                    </FormGroup>

                    <FormButtons>
                        <ConditionalFragment showIf={!isLoading}>
                            <ButtonAsync color="primary" isExecuting={isSaving} onClick={() => saveForm()}
                                executingChildren={<><Spinner size="sm" /> {t('common.saving', 'Saving...')}</>}>
                                <FontAwesomeIcon icon="save" />
                                <> </>
                                {t('common.save', 'Save')}
                            </ButtonAsync>
                        </ConditionalFragment>

                        <Button type="button" color="primary" outline onClick={e => navigate(-1)}>
                            {t('common.cancel', 'Cancel')}
                        </Button>
                    </FormButtons>
                </Form>
            </MainContainer>
        </>
    );
};