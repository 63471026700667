import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useCallback, useMemo } from "react";
import { ConditionalFragment } from "react-conditionalfragment";
import { useTranslation } from "react-i18next";
import { Badge, Button, ButtonGroup, Col, Collapse, Row } from "reactstrap";
import { useToggleState } from "use-toggle-state";
import { Child } from "../../../../api/main/models/Child";
import { PaymentState } from "../../../../api/main/models/constants/PaymentState";
import { useChildPaymentsTabViewModel } from "../../../../api/main/payments/viewModel/useChildPaymentsTabViewModel";
import { AlertOnErrors } from "../../../../shared/alertOnErrors";
import { LoadingIndicator } from "../../../shared/loadingIndicator/LoadingIndicator";
import { NoResultsFound } from "../../../shared/noResultsFound/NoResultsFound";
import { StyledModal } from "../../../shared/styledModal/StyledModal";
import { CreateAdHocPaymentModal } from "./CreateAdHocPaymentModal";
import { PaymentComponent } from "./PaymentComponent";
import "./paymentsTab.scss";

export interface PaymentsTabProps {
    model: Child | null | undefined,
    startWithHistoryCollapsed?: boolean,
    isChildView?: boolean,
}

/**
 * PaymentsTab for mebersChild
 */
export const PaymentsTab = (props: PaymentsTabProps) => {
    const {
        model,
        startWithHistoryCollapsed,
        isChildView = false,
    } = props;

    const { t } = useTranslation();

    // Load the data
    const {
        data: {
            items: allItems,
        }, isLoading, errors: loadErrors, refresh
    } = useChildPaymentsTabViewModel(model?.id);

    // Want the paid items seperate to everything else.
    const { dueItems, historyItems } = useMemo(() => {
        return {
            dueItems: allItems?.filter(item => item.paymentState !== PaymentState.Paid),
            historyItems: allItems?.filter(item => item.paymentState === PaymentState.Paid).reverse(), // Want the newest paid items first.
        };
    }, [allItems]);

    // Toggling of the payment history.
    const [historyIsOpen, toggleHistoryOpen] = useToggleState(!!startWithHistoryCollapsed ? false : true);

    // Handle showing of the Ad-hoc payment modal
    const [adHocPaymentModalIsOpen, _toggleAdHocPaymentModalOpen] = useToggleState();
    const toggleAdHocPaymentModalOpen = useCallback(() => {
        _toggleAdHocPaymentModalOpen();
    }, [_toggleAdHocPaymentModalOpen]);

    if (isLoading) {
        return (<LoadingIndicator fullWidth />);
    }

    // Open Url to view all payments for member 
    const openUrl = (id: string | undefined) => {
        if (!id) { return; }

        // Open in a new window.
        window.open(`/administration/children-management/members/edit/${id}?tab=payments`);
    };

    // Render the UI
    //
    return (
        <div className="payments-tab">
            <AlertOnErrors errors={[loadErrors]} />

            <Row>
                <Col>
                </Col>
                <Col xs="auto">
                    <ButtonGroup>
                        <Button color="primary" outline onClick={() => toggleAdHocPaymentModalOpen()}>
                            <FontAwesomeIcon icon="add" />
                            <> </>
                            {t('paymentsTab.adHocPayment.button', 'Add a one-off payment')}
                        </Button>
                        <ConditionalFragment showIf={!!isChildView}>
                            <Button color="primary" outline onClick={() => openUrl(model?.memberId)}>
                                <FontAwesomeIcon icon="eye" />
                                <> </>
                                {t('common.viewAllPayments', 'View all payments for member')}
                            </Button>
                        </ConditionalFragment>
                    </ButtonGroup>
                </Col>
            </Row>
            <h6>
                {t('paymentsTab.due.title', 'Outstanding payments')}
                <> </>
                <Badge pill>
                    {t('paymentsTab.due.count', '{{count}}', { count: dueItems?.length })}
                </Badge>
            </h6>
            <div>
                {
                    dueItems?.map(item => (
                        <PaymentComponent
                            payment={item}
                            key={item.id}
                        />
                    ))
                }

                <ConditionalFragment showIf={!dueItems?.length}>
                    <NoResultsFound icon={['far', 'face-smile']}>
                        {t('paymentsTab.due.noItemsText', 'There are no outstanding payments for {{firstName}}.', { firstName: model?.firstName })}
                    </NoResultsFound>
                </ConditionalFragment>
            </div>


            <ConditionalFragment showIf={!!historyItems?.length}>
                <h6 onClick={() => toggleHistoryOpen()} style={{ cursor: 'pointer' }}>
                    {t('paymentsTab.history.title', 'Payments history')}
                    <> </>
                    <Badge pill color="success">
                        {t('paymentsTab.history.count', '{{count}}', { count: historyItems?.length })}
                    </Badge>
                    <> </>
                    <FontAwesomeIcon icon={historyIsOpen ? 'caret-up' : 'caret-down'} />
                </h6>
                <Collapse isOpen={historyIsOpen}>
                    {
                        historyItems?.map(item => (
                            <PaymentComponent
                                payment={item}
                                key={item.id}
                            />
                        ))
                    }
                </Collapse>
            </ConditionalFragment>

            <ConditionalFragment showIf={!!adHocPaymentModalIsOpen}>
                <StyledModal
                    isOpen={adHocPaymentModalIsOpen}
                    toggle={toggleAdHocPaymentModalOpen}
                    size="lg"
                >
                    <CreateAdHocPaymentModal
                        child={model}
                        toggle={toggleAdHocPaymentModalOpen}
                        refreshPaymentList={refresh}
                    />
                </StyledModal>
            </ConditionalFragment>
        </div>
    );
};