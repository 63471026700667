/**
 * Returns the full URL for url, prefixing https:// if there is no protocol specified.
 * @param url
 */
export function getFullUrl(url: string | undefined | null): string {
    // If we don't have a URL to work with return an empty string.
    if (!url) {
        return '';
    }

    // Make sure we have a full URL, adding https:// to the front if needed.
    let fullUrl = url;
    if (fullUrl.indexOf('://') === -1) {
        fullUrl = `https://${fullUrl}`;
    }

    return fullUrl;
}