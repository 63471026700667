import gql from 'graphql-tag';
import { Guid } from 'guid-string';
import { GeneralDiscountFieldsFragment } from '../generated/graphql';

export const generalDiscountFieldsFragment = gql`
    fragment generalDiscountFields on GeneralDiscount {
        id
        name
        automationKey
        discountAmount
        archived
        discountPercentage
    }
`;

export type GeneralDiscount = Omit<GeneralDiscountFieldsFragment, '__typename'>;

/**
 * Default values.
 */
export const generalDiscountDefaultValues = (): Partial<GeneralDiscount> => ({
    id: Guid.newGuid(),
    name: '',
    automationKey: '',
    discountAmount: 0,
    archived: false,
    discountPercentage: 0,
});