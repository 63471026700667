import { Modal } from "reactstrap";
import './styledModal.scss';

export interface StyledModalProps {
    isOpen: boolean,
    toggle: () => void,
    children: any,
    size?: string,
}

/**
 * StyledModal Component - HappyCubs Yellow BorderTop Styling. Optional size, defaults to small
 */
export const StyledModal = (props: StyledModalProps) => {
    const {
        isOpen,
        toggle,
        children,
        size,
    } = props;

    return <Modal isOpen={isOpen} toggle={toggle} className="styled-modal modal-content" size={size ?? 'sm'}>
        {children}
    </Modal>;
}