import * as React from 'react';
import { Button, Row, Col, Form, Label, FormGroup, Spinner, FormText, Alert } from 'reactstrap';
import { AlertOnErrors } from '../../shared/alertOnErrors';
import { LoadingIndicator } from '../shared/loadingIndicator/LoadingIndicator';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MainContainer } from '../shared/mainContainer/MainContainer';
import { useParams, useNavigate } from 'react-router';
import { useChanges } from '../../shared/useChanges';
import { useValidatorCallback } from 'pojo-validator-react';
import { ValidatedInput } from 'pojo-validator-reactstrap';
import { FormButtons } from '../shared/formButtons/FormButtons';
import { ButtonAsync } from 'reactstrap-buttonasync';
import { useInviteCallback, useChangeAccountEmailCallback, useResendInviteEmailCallback } from '../../api/account';
import { useAsyncCallback } from 'react-use-async-callback';
import { ConditionalFragment } from 'react-conditionalfragment';
import { Banner } from '../shared/banner/Banner';
import { Profile, profileDefaultValues } from '../../api/main/models/Profile';
import { useSaveProfileMutation } from '../../api/main/profiles/useSaveProfileMutation';
import { useChangeUserRoleGroupMutation } from '../../api/main/users/useChangeUserRoleGroupMutation';
import { useProfileSupportingData } from '../../api/main/profiles/viewModels/useProfileSupportingData';
import { userDefaultValues } from '../../api/main/models/User';
import { useEditProfileViewModel } from '../../api/main/profiles/viewModels/useEditProfileViewModel';

export interface EditUserProps {
    isCreate?: boolean,
    onCreateDefaultValues?: () => Partial<Profile>,
    defaultRoleGroup?: string,
    filterRoleGroups?: (groups: Array<{ id: string, name: string }>) => Array<{ id: string, name: string }>,
}


/**
 * Create and invite a new user.
 */
export const CreateUser = (props: EditUserProps) => (<EditUser isCreate={true} {...props} />);

/**
 * Edit a user (actually a profile).
 */
export const EditUser = (props: EditUserProps) => {
    const {
        isCreate,
        onCreateDefaultValues,
        defaultRoleGroup,
        filterRoleGroups,
    } = props;

    const { t } = useTranslation();
    const { id } = useParams<{ id: string | undefined }>();
    const { data: { model: storeModel }, isLoading: _isLoading, errors: loadErrors } = useEditProfileViewModel(id);
    const { model, change, changes } = useChanges(storeModel, isCreate ? { ...profileDefaultValues(), ...(onCreateDefaultValues ? onCreateDefaultValues() : {}) } : undefined);
    const { model: userModel, change: changeUserModel } = useChanges(storeModel?.user, isCreate ? { ...userDefaultValues(), } : {});
    const { model: roleGroupModel, change: changeRoleGroupModel } = useChanges<{ id: string }>({ id: storeModel?.user?.roleGroup?.id ?? '' }, isCreate ? { id: defaultRoleGroup ?? 'User' } : undefined);
    const [save, { errors: saveErrors }] = useSaveProfileMutation();
    const [invite, { errors: inviteErrors }] = useInviteCallback();
    const [resendInviteEmail, { isExecuting: isResendingInviteEmail, errors: resendInviteEmailErrors }] = useResendInviteEmailCallback();
    const [hasResentInviteEmail, setHasResentInviteEmail] = React.useState<boolean>(false);
    const [changeAccountEmail, { errors: changeEmailErrors }] = useChangeAccountEmailCallback();
    const [changeUserRoleGroupInStore, { errors: changeUserRoleGroupInStoreErrors }] = useChangeUserRoleGroupMutation();
    const navigate = useNavigate();

    // Supporting data (dependant on the current user's subscription).
    const { data: { roleGroups: storeRoleGroups, }, isLoading: isLoadingSupportingData, errors: loadSupportingDataErrors } = useProfileSupportingData();
    const isLoading = _isLoading || isLoadingSupportingData;

    const roleGroups = React.useMemo(() => {
        if (!filterRoleGroups) {
            return storeRoleGroups;
        }

        return filterRoleGroups(storeRoleGroups ?? []);
    }, [storeRoleGroups, filterRoleGroups]);

    const [validate, validationErrors] = useValidatorCallback((validation, fieldsToCheck) => {
        const rules = {
            firstName: () => !model?.firstName ? t('editUserBase.firstNameRequired', 'First name is required') : '',
            lastName: () => !model?.lastName ? t('editUserBase.lastNameRequired', 'Last name is required') : '',
            email: () => !userModel?.email ? t('editUserBase.emailRequired', 'Email is required') : '',
        };

        validation.checkRules(rules, fieldsToCheck);
    }, [model, userModel]);

    const [saveForm, { isExecuting: isSaving, errors: saveFormErrors }] = useAsyncCallback(async (options?: { navigateTo?: string, }) => {
        if (!validate()) {
            return;
        }

        // If we are creating this user, make sure we save the user and get its id before continuing with saving the profile.
        let userId = model.userId;
        if (isCreate) {
            // Create an invited user but don't send the invite yet, we'll send it after the profile has been saved to so we can include their name
            // and make it more personal for higher engagement.
            const result = await invite({ ...userModel, sendEmail: false });
            userId = result.userId;
            changeUserModel({ id: userId });
            change({ userId: userId });
        }

        await save(model.id, { ...changes, userId: userId }, !!isCreate);

        // If we created a user invite, send the email now the profile has been saved so it can include more personal information (e.g. their name).
        if (isCreate) {
            await resendInviteEmail(userModel.email);
        }

        // Handle changes to the email address of existing users.
        if (!isCreate && storeModel && storeModel.user.email !== userModel.email) {
            await changeAccountEmail(storeModel.user.email, userModel.email);
        }

        // Handle the role group change.
        if (isCreate || storeModel?.user.roleGroup?.id !== roleGroupModel.id) {
            await changeUserRoleGroupInStore({ userId: userId, roleGroupId: roleGroupModel.id });
        }

        // Navigate to the next screen.
        if (options?.navigateTo) {
            navigate(options.navigateTo);
        } else {
            navigate(-1);
        }
    }, [validate, save, model, changes, isCreate, navigate, userModel, invite, changeUserModel, change,
        changeAccountEmail, resendInviteEmail, ]);


    return (
        <>
            <Banner>
                <Row>
                    <Col xs={12} md="auto">
                        <h1>
                            {
                                isCreate ? t('editUserBase.editUser.createHeading.default', 'Add user')
                                    : t('editUserBase.editUser.editHeading.default', 'Edit user')
                            }
                        </h1>
                    </Col>
                    <ConditionalFragment showIf={isLoading}>
                        <Col xs="auto">
                            <LoadingIndicator size="sm" />
                        </Col>
                    </ConditionalFragment>
                </Row>
            </Banner>

            <MainContainer>
                <AlertOnErrors errors={[
                    loadErrors, loadSupportingDataErrors,
                    saveFormErrors, saveErrors,
                    inviteErrors, resendInviteEmailErrors,
                    changeEmailErrors, changeUserRoleGroupInStoreErrors,
                ]} />

                {
                    hasResentInviteEmail ? (
                        <Alert color="success" >
                            <>{t('editUserBase.confirmationEmailHasBeenResent', 'Invite email for this user has been resent.  Please ask the user to check their email to confirm.')} </>
                            <ButtonAsync type="button" color="success" onClick={async e => { e.preventDefault(); await resendInviteEmail(storeModel?.user?.email ?? userModel.email); }}
                                isExecuting={isResendingInviteEmail}
                                executingChildren={<><Spinner size="sm" />{t('common.sending', 'Sending...')}</>}>
                                {t('common.resendEmail', 'Resend email')}
                            </ButtonAsync>
                        </Alert>
                    ) : null
                }

                <Form onSubmit={e => { e.preventDefault(); saveForm(); }}>
                    <Row>
                        <Col>
                            <FormGroup>
                                <Label htmlFor="firstName">{t('editUserBase.firstName', 'First name')}</Label>
                                <ValidatedInput name="firstName" type="text" value={model.firstName ?? ''} onChange={e => change({ firstName: e.currentTarget.value })} onBlur={e => validate('firstName')} validationErrors={validationErrors['firstName']} />
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup>
                                <Label htmlFor="lastName">{t('editUserBase.lastName', 'Last name')}</Label>
                                <ValidatedInput name="lastName" type="text" value={model.lastName ?? ''} onChange={e => change({ lastName: e.currentTarget.value })} onBlur={e => validate('lastName')} validationErrors={validationErrors['lastName']} />
                            </FormGroup>
                        </Col>
                    </Row>

                    <FormGroup>
                        <Label htmlFor="email">{t('editUserBase.email', 'Email')}</Label>
                        <Row>
                            <Col>
                                <ValidatedInput name="email" type="email" value={userModel.email ?? ''} onChange={e => changeUserModel({ email: e.currentTarget.value })} onBlur={e => validate('email')} validationErrors={validationErrors['email']} />
                            </Col>
                            {
                                storeModel && !storeModel.user.emailConfirmed && userModel.email === storeModel.user.email ? (
                                    <Col xs="auto">
                                        <ButtonAsync type="button" color="primary" outline onClick={async e => { e.preventDefault(); await resendInviteEmail(userModel.email); setHasResentInviteEmail(true); }}
                                            isExecuting={isResendingInviteEmail}
                                            executingChildren={<><Spinner size="sm" />{t('editUserBase.resendingInvite', 'Resending invite...')}</>}>
                                            <FontAwesomeIcon icon="envelope" />
                                            <> {t('editUserBase.resendInvite', 'Resend invite')}</>
                                        </ButtonAsync>
                                    </Col>
                                ) : null
                            }
                        </Row>
                        {
                            storeModel && !storeModel.user.emailConfirmed && userModel.email === storeModel.user.email ? (
                                <FormText>
                                    {t('editUserBase.userHasNotAcceptedInvite', 'This user has not accepted the invite.  You can change their email if required or resend the invite.')}
                                </FormText>
                            ) : storeModel && !storeModel.user.emailConfirmed && userModel.email !== storeModel.user.email ? (
                                <FormText>
                                    {t('editUserBase.userHasNotAcceptedInviteAndEmailChanged', 'This user has not yet accepted the invite, a new invite will be sent to the new email you have supplied.')}
                                </FormText>
                            ) : storeModel && storeModel.user.emailConfirmed && userModel.email !== storeModel.user.email ? (
                                <FormText color="warning">
                                    {t('editUserBase.userHasAcceptedInviteAndEmailChanged', 'This user will be asked to confirm the email change before it takes affect.  The old email address will be shown in the system until the change has been confirmed.')}
                                </FormText>
                            ) : storeModel && storeModel.user.emailConfirmed && userModel.email === storeModel.user.email ? (
                                <FormText>
                                    {t('editUserBase.userHasAcceptedInviteAndEmailNotChanged', 'If you change this user\'s email they will be sent an email to confirm the change before it takes affect.')}
                                </FormText>
                            ) : null
                        }
                    </FormGroup>

                    <ConditionalFragment showIf={roleGroups?.length > 1}>
                        <FormGroup>
                            <Label htmlFor="roleGroup">{t('editUserBase.roleGroup', 'Security group')}</Label>
                            <ValidatedInput name="roleGroup" type="select" value={roleGroupModel?.id ?? ''} onChange={e => changeRoleGroupModel({ id: e.currentTarget.value })} onBlur={e => validate('roleGroup')} validationErrors={validationErrors['roleGroup']}>
                                {
                                    roleGroups?.map(item => (
                                        <option key={item.id} value={item.id}>{item.name}</option>
                                    ))
                                }
                            </ValidatedInput>
                        </FormGroup>
                    </ConditionalFragment>

                    <FormButtons>
                        <ConditionalFragment showIf={!isLoading}>
                            <ButtonAsync color="primary" isExecuting={isSaving}
                                executingChildren={<><Spinner size="sm" /> {t('common.saving', 'Saving...')}</>}>
                                <FontAwesomeIcon icon="save" />
                                <> {t('common.save', 'Save')}</>
                            </ButtonAsync>
                        </ConditionalFragment>
                        <Button type="button" color="primary" outline onClick={e => navigate(-1)}>
                            {t('common.cancel', 'Cancel')}
                        </Button>
                    </FormButtons>
                </Form>
            </MainContainer>
        </>
    );
};
