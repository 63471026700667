import { Button } from "reactstrap";
import "./colouredTagSelector.scss";

export interface TagLikeItem {
    id: string,
    name: string,
}

export interface ColouredTagSelectorProps {
    tags: Array<TagLikeItem> | undefined | null,
    // Function to decide if a tag is selected.  If not passed, everything is considered to be selected.
    isSelected?: (item: TagLikeItem) => boolean,
    toggle?: (item: TagLikeItem) => void,
    className?: string,
    colors?: Array<string>,
    // When set we will display like an input, when not set, it will display without an input border.
    input?: boolean,
    readOnly?: boolean,
}

/**
 * Component for selecting and deselecting tag like items.
 */
export const ColouredTagSelector = (props: ColouredTagSelectorProps) => {
    const {
        tags,
        isSelected,
        toggle,
        className,
        colors = ["primary", "secondary"],
        input = false,
        readOnly = false,
    } = props;
    
    return (
        <div className={`tag-selector ${input ? 'tag-selector-input' : ''} ${readOnly ? 'tag-selector-readonly' : ''} ${className ?? ''}`}>
            {
                tags?.map((item, index) => {
                    const selected = !isSelected ? true : isSelected(item);
                    return (
                        <Button key={item.id} size="sm"
                            className={`tag-selector-tag ${selected ? 'tag-selector-tag-selected' : 'tag-selector-tag-not-selected'}`}
                            color={colors[index]} outline={!selected}
                            onClick={() => toggle?.(item)} >
                            {item.name}
                        </Button>
                    );
                })
            }
        </div>
    );
};