import { useTranslation } from "react-i18next";
import { Child, childDefaultValues } from "../../../api/main/models/Child";
import { useParams, useNavigate } from 'react-router';
import { useEditChildSupportingData } from "../../../api/main/children/viewModels/useEditChildSupportingData";
import { Banner } from "../../shared/banner/Banner";
import { StickyToolbar } from "../../shared/stickyToolbar/StickyToolbar";
import { Col, NavItem, Row, NavLink, Spinner, Button, Badge } from "reactstrap";
import { PillsNavBar } from "../../shared/pillsNavBar/PillsNavBar";
import { ConditionalFragment } from "react-conditionalfragment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LoadingIndicator } from "../../shared/loadingIndicator/LoadingIndicator";
import { MainContainer } from "../../shared/mainContainer/MainContainer";
import { AlertOnErrors } from "../../../shared/alertOnErrors";
import { FormButtons } from "../../shared/formButtons/FormButtons";
import { ButtonAsync } from "reactstrap-buttonasync";
import { MainTab } from "./MainTab";
import { useEditChildViewModel } from "../../../api/main/children/viewModels/useEditChildViewModel";
import { useValidatorCallback } from "pojo-validator-react";
import { useChanges, useChangesArray } from "../../../shared/useChanges";
import { useAsyncCallback } from "react-use-async-callback";
import { useSaveChildMutation } from "../../../api/main/children/useSaveChildMutation";
import { EmergencyContactsTab } from "./emergencyContactsTab/EmergencyContactsTab";
import { EmergencyContact } from "../../../api/main/models/EmergencyContact";
import { useSaveEmergencyContactMutation } from "../../../api/main/emergencyContacts/useSaveEmergencyContactMutation";
import { useDeleteEmergencyContactMutation } from "../../../api/main/emergencyContacts/useDeleteEmergencyContact";
import { useValidatorArrayCallback } from "../../../shared/validator-react-contrib/useValidatorArrayCallback";
import { hasAnyValidationErrors } from "../../../utilities/hasAnyValidationErrors";
import { StaffNote } from "../../../api/main/models/StaffNote";
import { useSaveStaffNoteMutation } from "../../../api/main/staffNotes/useSaveStaffNoteMutation";
import { useDeleteStaffNoteMutation } from "../../../api/main/staffNotes/useDeleteStaffNote";
import moment from "moment";
import { findLastUpdatedStaffNote } from "../../../utilities/findLastUpdatedStaffNote";
import { useMemo, useState } from "react";
import { DiscountsTab } from "./discountsTab/DiscountsTab";
import { ChildDiscount } from "../../../api/main/models/ChildDiscount";
import { useSaveChildDiscountMutation } from "../../../api/main/childDiscounts/useSaveChildDiscountMutation";
import { useDeleteChildDiscountMutation } from "../../../api/main/childDiscounts/useDeleteChildDiscount";
import { useBlobReference } from "../../../api/main/blobReferences/useBlobReference";
import { BlobUploadService } from "../../../api/main/blobReferences/BlobUploadService";
import { PhotoComponent } from "./photosTab/PhotoComponent";
import { useReplaceSearchParamsEffect, useSearchParams } from "../../../shared/useURLSearchParams";
import { ClassesTab } from "./classesTab/ClassesTab";
import { PaymentsTab } from "./paymentsTab/PaymentsTab";
import { CourtesyClassCredit } from "../../../api/main/models/CourtesyClassCredit";
import { useSaveCourtesyClassCreditMutation } from "../../../api/main/courtesyClassCredits/useSaveCourtesyClassCreditMutation";
import { useDeleteCourtesyClassCreditMutation } from "../../../api/main/courtesyClassCredits/useDeleteCourtesyClassCredit";
import { CourtesyClassCreditsTab } from "./courtesyClassCreditsTab/CourtesyClassCreditsTab";
import { StaffNotesTab } from "../../staffNotes/StaffNotesTab";
import { EventsTab } from "./eventsTab/EventsTab";

export interface EditChildProps {
    isCreate?: boolean,
    onCreateDefaultValues?: () => Partial<Child>;
}

/**
 * Component to create a Child
 */
export const CreateChild = (props: EditChildProps) => (<EditChild isCreate={true} {...props} />);

/**
 * Component to edit a Child
 */
export const EditChild = (props: EditChildProps) => {
    const {
        isCreate,
        onCreateDefaultValues
    } = props;

    const { t } = useTranslation();
    const {
        id,
        memberId // If a child is added under a Member, we use the route to pass this extra information so we can default it.
    } = useParams<{ id: string | undefined, memberId: string | undefined, }>();
    const navigate = useNavigate();

    // Load all data
    const {
        data: {
            model: storeModel,
            courtesyClassCredits,
            emergencyContacts,
            staffNotes,
            childDiscounts,
            scheduledClassChildEvents
        },
        errors: childLoadErrors, isLoading: _isLoading
    } = useEditChildViewModel(id);
    // Supporting data
    const {
        data: {
            memberProfiles,
            staffProfiles,
            childsRelationships,
            genders,
            terms,
        },
        errors: loadSupportingDataErrors, isLoading: isLoadingSupportingData
    } = useEditChildSupportingData();
    const isLoading = _isLoading || isLoadingSupportingData;

    // Model (Child)
    const { model, change, changes } = useChanges(storeModel, isCreate ? { ...childDefaultValues(), memberId: memberId, ...(onCreateDefaultValues ? onCreateDefaultValues() : {}) } : undefined);
    const [saveChild, { errors: saveErrors }] = useSaveChildMutation();

    // Child image upload (photo)
    const { data: { model: image }, errors: imageLoadErrors } = useBlobReference(model?.photoBlobReferenceId);
    const [onUploadImage, { errors: imageUploadErrors, isExecuting: isUploadingImage }] = useAsyncCallback(async (files: FileList | null) => {
        if (!files) {
            return;
        }

        let uploadService: BlobUploadService = new BlobUploadService("/api/blobs");
        let result = await uploadService.upload(files);

        if (!!result) {
            change({ photoBlobReferenceId: result.id });
        }
    }, [change]);

    // Clear image functionality
    const [clearImage, { isExecuting: isClearingImage }] = useAsyncCallback(async () => {
        change({ photoBlobReferenceId: null });
    }, [change]);

    // Model (EmergencyContacts)
    const childEmergencyContactsManager = useChangesArray<EmergencyContact, string>(emergencyContacts, item => item.id);
    const [saveEmergencyContact] = useSaveEmergencyContactMutation();
    const [removeEmergencyContact] = useDeleteEmergencyContactMutation();

    // Model (CourtesyClassCredits)
    const courtesyClassCreditsManager = useChangesArray<CourtesyClassCredit, string>(courtesyClassCredits, item => item.id);
    const [saveCourtesyClassCredit] = useSaveCourtesyClassCreditMutation();
    const [removeCourtesyClassCredit] = useDeleteCourtesyClassCreditMutation();

    // Model (StaffNotes)
    const childStaffNotesManager = useChangesArray<StaffNote, string>(staffNotes, item => item.id);
    const [saveStaffNote] = useSaveStaffNoteMutation();
    const [removeStaffNote] = useDeleteStaffNoteMutation();

    // Model (ChildDiscounts)
    const childChildDiscountsManager = useChangesArray<ChildDiscount, string>(childDiscounts, item => item.id);
    const [saveChildDiscount] = useSaveChildDiscountMutation();
    const [removeChildDiscount] = useDeleteChildDiscountMutation();

    // ChildDiscounts validation
    const [validateChildChildDiscount, childChildDiscountValidationErrors] = useValidatorArrayCallback<ChildDiscount>((myModel, validation, fieldsToCheck) => {
        const rules = {
            name: () => !myModel?.name ? t('editChild.childDiscount.errors.name', 'Name expiryDate be empty') : '',
            expiryDate: () => !myModel?.expiryDate ? t('editChild.childDiscount.errors.startDate', 'Expiry date cannot be empty') : '',
        };

        validation.checkRules(rules, fieldsToCheck);
    }, []);

    // CourtesyClassCredits validation
    const [validateCourtesyClassCredit, courtesyClassCreditValidationErrors] = useValidatorArrayCallback<CourtesyClassCredit>((myModel, validation, fieldsToCheck) => {
        const rules = {
            name: () => !myModel?.name ? t('editChild.courtesyClassCredit.errors.name', 'Credit reason may not be empty') : '',
            scheduledClassId: () => !myModel?.scheduledClassId ? t('editChild.courtesyClassCredit.errors.scheduledClassId', 'Class cannot be empty') : '',
            termId: () => !myModel?.termId ? t('editChild.courtesyClassCredit.errors.termId', 'Term cannot be empty') : '',
        };

        validation.checkRules(rules, fieldsToCheck);
    }, []);

    // StaffNotes validation
    const [validateChildStaffNote, childStaffNoteValidationErrors] = useValidatorArrayCallback<StaffNote>((myModel, validation, fieldsToCheck) => {
        const rules = {
            noteType: () => !myModel?.noteType ? t('editChild.staffNote.errors.noteType', 'Note type is required') : '',
        };

        validation.checkRules(rules, fieldsToCheck);
    }, []);

    // EmergencyContacts validation
    const [validateEmergencyContact, emergencyContactValidationErrors] = useValidatorArrayCallback<EmergencyContact>((myModel, validation, fieldsToCheck) => {
        const rules = {
            firstName: () => !myModel?.firstName ? t('editChild.emergencyContact.errors.firstName', 'First name is required') : '',
            lastName: () => !myModel?.lastName ? t('editChild.emergencyContact.errors.lastName', 'Last name is required') : '',
            primaryPhone: () => !myModel?.primaryPhone ? t('editChild.emergencyContact.errors.primaryPhone', 'A primary phone number is required') : '',
        };

        validation.checkRules(rules, fieldsToCheck);
    }, []);

    // Main model validation
    const [validate, validationErrors] = useValidatorCallback((validation, fieldsToCheck) => {
        const rules = {
            firstName: () => !model?.firstName ? t('editChild.errors.firstNameRequired', 'First name is required') : '',
            lastName: () => !model?.lastName ? t('editChild.errors.lastNameRequired', 'Last name is required') : '',
            memberId: () => !model?.memberId ? t('editChild.errors.memberIdRequired', 'Member is required') : '',
            genderId: () => !model?.genderId ? t('editChild.errors.genderId', 'Gender is required') : '',
            memberChildsRelationshipId: () => !model?.memberChildsRelationshipId ? t('editChild.memberChildsRelationshipId', 'Relationship to child is required') : '',
            dateOfBirth: () => !model?.dateOfBirth ? t('editChild.dateOfBirth.errors.startDate', 'Date of birth is required') : '',

            // Any errors in the related object
            emergencyContacts: () => childEmergencyContactsManager.model.filter(it => !validateEmergencyContact(it)).length ? t('editChild.errors.emergegencyContacts', 'One or more of the emergency contacts are invalid') : '',
            staffNotes: () => childStaffNotesManager.model.filter(it => !validateChildStaffNote(it)).length ? t('editChild.errors.staffNotes', 'One or more of the staff notes are invalid') : '',
            childDiscounts: () => childChildDiscountsManager.model.filter(it => !validateChildChildDiscount(it)).length ? t('editChild.errors.childDiscounts', 'One or more of the child discounts are invalid') : '',
            courtesyClassCredits: () => courtesyClassCreditsManager.model.filter(it => !validateCourtesyClassCredit(it)).length ? t('editChild.errors.courtesyClassCredits', 'One or more of the courtesy class credits are invalid') : '',
        };
        validation.checkRules(rules, fieldsToCheck);
    }, [
        model,
        childEmergencyContactsManager,
        validateEmergencyContact,
        childStaffNotesManager,
        validateChildStaffNote,
        childChildDiscountsManager,
        validateChildChildDiscount,
        courtesyClassCreditsManager,
        validateCourtesyClassCredit
    ]);

    // Save everything
    const [saveForm, { isExecuting: isSaving, errors: saveFormErrors }] = useAsyncCallback(async (options?: { dontNavigate?: boolean; }) => {
        if (!model) {
            return;
        }

        if (!validate()) {
            return;
        }

        // Save the main model.
        await saveChild(model.id, { ...changes }, isCreate ?? false);

        // Saves changes to the EmergencyContact
        for (const item of childEmergencyContactsManager.added) { await saveEmergencyContact(item.id, childEmergencyContactsManager.changesFor(item.id), true); }
        for (const item of childEmergencyContactsManager.updated) { await saveEmergencyContact(item.id, childEmergencyContactsManager.changesFor(item.id), false); }
        for (const item of childEmergencyContactsManager.removed) { await removeEmergencyContact(item.id); }
        childEmergencyContactsManager.markAsSaved();

        // Saves changes to the StaffNote
        for (const item of childStaffNotesManager.added) { await saveStaffNote(item.id, childStaffNotesManager.changesFor(item.id), true); }
        for (const item of childStaffNotesManager.updated) { await saveStaffNote(item.id, childStaffNotesManager.changesFor(item.id), false); }
        for (const item of childStaffNotesManager.removed) { await removeStaffNote(item.id); }
        childStaffNotesManager.markAsSaved();

        // Saves changes to the ChildDiscounts
        for (const item of childChildDiscountsManager.added) { await saveChildDiscount(item.id, childChildDiscountsManager.changesFor(item.id), true); }
        for (const item of childChildDiscountsManager.updated) { await saveChildDiscount(item.id, childChildDiscountsManager.changesFor(item.id), false); }
        for (const item of childChildDiscountsManager.removed) { await removeChildDiscount(item.id); }
        childChildDiscountsManager.markAsSaved();

        // Saves changes to the courtesyClassCredits
        for (const item of courtesyClassCreditsManager.added) { await saveCourtesyClassCredit(item.id, courtesyClassCreditsManager.changesFor(item.id), true); }
        for (const item of courtesyClassCreditsManager.updated) { await saveCourtesyClassCredit(item.id, courtesyClassCreditsManager.changesFor(item.id), false); }
        for (const item of courtesyClassCreditsManager.removed) { await removeCourtesyClassCredit(item.id); }

        // Go back to previous screen.
        if (!options?.dontNavigate) {
            navigate(-1);
        }
    }, [
        validate,
        saveChild,
        model,
        changes,
        isCreate,
        id,
        navigate,
        childEmergencyContactsManager,
        saveEmergencyContact,
        removeEmergencyContact,
        childStaffNotesManager,
        saveStaffNote,
        removeStaffNote,
        childChildDiscountsManager,
        saveChildDiscount,
        removeChildDiscount
    ]);

    // Manage the tab being displayed.  If we have tab= on the query string, default to it, otherwise default to the main tab.
    const { tab: tabParam } = useSearchParams();
    // Manage the tab being displayed
    const [activeTab, setActiveTab] = useState<'main' | 'photo' | 'emergency-contacts' | 'classes' | 'payments' | 'discounts' | 'staff-notes' | 'credits' | 'events'>(tabParam as any || 'main');

    // Keep the URL up to date with the currentTab
    useReplaceSearchParamsEffect({ tab: activeTab === 'main' ? '' : activeTab });

    // Call function to return the last updated note
    const lastNoteUpdate = useMemo(() => {
        return findLastUpdatedStaffNote(staffNotes);
    }, [staffNotes]);

    // Render the UI
    //
    return (
        <>
            <Banner>
                <StickyToolbar>
                    <Row>
                        <Col xs={12} md="auto">
                            <h1>
                                {
                                    isCreate ? t('editChild.createHeading.default', 'Add cub')
                                        : t('editChild.editHeading.default', 'Edit cub')
                                }
                            </h1>
                            <h3>{t('editChild.subheading.name', '{{ firstName }} {{ lastName }}', { firstName: model?.firstName ?? '', lastName: model?.lastName ?? '' })}</h3>
                        </Col>
                        <Col>
                            <PillsNavBar>
                                <NavItem>
                                    <NavLink active={activeTab === 'main'} onClick={() => setActiveTab('main')}>
                                        {t('editChild.nav.main', "Bear cub's details")}

                                        {/* Show a warning triangle if any fields for this tab have validation errors */}
                                        <ConditionalFragment showIf={hasAnyValidationErrors([validationErrors['firstName'], validationErrors['lastName'], validationErrors['genderId'], validationErrors['memberId'], validationErrors['memberChildsRelationshipId'], validationErrors['dateOfBirth'],])}>
                                            <> </><FontAwesomeIcon icon="exclamation-triangle" color="danger" />
                                        </ConditionalFragment>
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink active={activeTab === 'photo'} onClick={() => setActiveTab('photo')}>
                                        {t('editChild.nav.photo', 'Photo')}

                                        {/* Show a warning triangle if any fields for this tab have validation errors */}
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink active={activeTab === 'emergency-contacts'} onClick={() => setActiveTab('emergency-contacts')}>
                                        {t('editChild.nav.emergencyContacts', 'Emergency contacts')}

                                        {/* Show a warning triangle if any fields for this tab have validation errors */}
                                        <ConditionalFragment showIf={hasAnyValidationErrors([validationErrors['emergencyContacts'],])}>
                                            <> </><FontAwesomeIcon icon="exclamation-triangle" color="danger" />
                                        </ConditionalFragment>
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink active={activeTab === 'staff-notes'} onClick={() => setActiveTab('staff-notes')}>
                                        {t('editChild.nav.staffNotes.name', 'Notes on cub')}
                                        <> </>
                                        {
                                            lastNoteUpdate !== null ? (
                                                <>
                                                    <Badge pill color="secondary">
                                                        {t('editChild.nav.staffNotes.badgeUpdated', 'Updated: ')} {t('common.date', '{{date, DD/MM/YYYY}}', { date: moment(lastNoteUpdate.updatedDate) })}
                                                    </Badge>
                                                </>
                                            ) : null
                                        }

                                        {/* Show a warning triangle if any fields for this tab have validation errors */}
                                        <ConditionalFragment showIf={hasAnyValidationErrors([validationErrors['staffNotes'],])}>
                                            <> </><FontAwesomeIcon icon="exclamation-triangle" color="danger" />
                                        </ConditionalFragment>
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink active={activeTab === 'classes'} onClick={() => setActiveTab('classes')}>
                                        {t('editChild.nav.classes', 'Classes')}

                                        {/* Show a warning triangle if any fields for this tab have validation errors */}
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink active={activeTab === 'payments'} onClick={() => setActiveTab('payments')}>
                                        {t('editChild.nav.payments', 'Payments')}

                                        {/* Show a warning triangle if any fields for this tab have validation errors */}
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink active={activeTab === 'discounts'} onClick={() => setActiveTab('discounts')}>
                                        {t('editChild.nav.discounts', 'Discounts')}

                                        {/* Show a warning triangle if any fields for this tab have validation errors */}
                                        <ConditionalFragment showIf={hasAnyValidationErrors([validationErrors['childDiscounts'],])}>
                                            <> </><FontAwesomeIcon icon="exclamation-triangle" color="danger" />
                                        </ConditionalFragment>
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink active={activeTab === 'credits'} onClick={() => setActiveTab('credits')}>
                                        {t('editChild.nav.credits', 'Credits ')}
                                        <ConditionalFragment showIf={!!(courtesyClassCredits?.filter(it => it.childId === model.id && !it.archived && !it.isConsumed).length > 0)}>
                                            <Badge color="secondary" pill style={{ paddingLeft: "5px" }}>
                                                {courtesyClassCredits?.filter(it => it.childId === model.id && !it.archived && !it.isConsumed).length}
                                            </Badge>
                                        </ConditionalFragment>

                                        {/* Show a warning triangle if any fields for this tab have validation errors */}
                                        <ConditionalFragment showIf={hasAnyValidationErrors([validationErrors['courtesyClassCredits'],])}>
                                            <> </><FontAwesomeIcon icon="exclamation-triangle" color="danger" />
                                        </ConditionalFragment>
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink active={activeTab === 'events'} onClick={() => setActiveTab('events')}>
                                        {t('editChild.nav.events', 'Events')}
                                        {' '}
                                        <ConditionalFragment showIf={!!(scheduledClassChildEvents?.length > 0)}>
                                            <Badge color="secondary" pill style={{ paddingLeft: "5px" }}>
                                                {scheduledClassChildEvents?.length}
                                            </Badge>
                                        </ConditionalFragment>
                                    </NavLink>
                                </NavItem>
                            </PillsNavBar>
                        </Col>
                        <ConditionalFragment showIf={isLoading}>
                            <Col xs="auto">
                                <LoadingIndicator size="sm" />
                            </Col>
                        </ConditionalFragment>
                    </Row>
                </StickyToolbar>
            </Banner>

            <MainContainer>
                <AlertOnErrors errors={[
                    loadSupportingDataErrors,
                    saveFormErrors,
                    saveErrors,
                    childLoadErrors,
                    imageLoadErrors,
                    imageUploadErrors
                ]} />

                {/* Active tab */}
                {
                    activeTab === 'main' ? (
                        <MainTab
                            model={model}
                            change={change}
                            memberProfiles={memberProfiles}
                            validate={validate}
                            validationErrors={validationErrors}
                            genders={genders}
                            childsRelationships={childsRelationships}
                        />
                    ) : activeTab === 'photo' ? (
                        <PhotoComponent
                            saveForm={saveForm}
                            isExecutingImageUpload={isUploadingImage}
                            onImageUpload={onUploadImage}
                            isExecutingClearImage={isClearingImage}
                            imageUploadClickHandler={clearImage}
                            imageUrl={image?.url}
                        />
                    ) : activeTab === 'emergency-contacts' ? (
                        <EmergencyContactsTab
                            model={model}
                            childEmergencyContactsManager={childEmergencyContactsManager}
                            validateChildEmergencyContact={validateEmergencyContact}
                            childEmergencyContactValidationErrors={emergencyContactValidationErrors}
                            childsRelationships={childsRelationships}

                        />
                    ) : activeTab === 'staff-notes' ? (
                        <StaffNotesTab
                            model={{ targetId: model.id, targetType: 'Child', headingName: 'Child', modalHeading: t('common.fullName', '{{firstName}} {{lastName}}', { firstName: model.firstName, lastName: model.lastName }) }}
                            staffNotesManager={childStaffNotesManager}
                            validateStaffNote={validateChildStaffNote}
                            staffNoteValidationErrors={childStaffNoteValidationErrors}
                            staffProfiles={staffProfiles}
                            saveForm={saveForm}
                        />
                    ) : activeTab === 'classes' ? (
                        <ClassesTab
                            model={model}
                        />
                    ) : activeTab === 'payments' ? (
                        <PaymentsTab
                            model={model}
                            isChildView={true} />
                    ) : activeTab === 'discounts' ? (
                        <DiscountsTab
                            model={model}
                            childChildDiscountsManager={childChildDiscountsManager}
                            validateChildChildDiscount={validateChildChildDiscount}
                            childChildDiscountValidationErrors={childChildDiscountValidationErrors}
                        />
                    ) : activeTab === 'credits' ? (
                        <CourtesyClassCreditsTab
                            model={model}
                            courtesyClassCreditsManager={courtesyClassCreditsManager}
                            validateCourtesyClassCredit={validateCourtesyClassCredit}
                            courtesyClassCreditValidationErrors={courtesyClassCreditValidationErrors}
                            terms={terms}
                        />
                    ) : activeTab === 'events' ? (
                        <EventsTab
                            events={scheduledClassChildEvents}
                        />
                    ) : null
                }

                {/*Save buttons at the bottom of all tabs. */}
                <FormButtons>
                    <ConditionalFragment showIf={!isLoading}>
                        <ButtonAsync color="primary" isExecuting={isSaving} onClick={() => saveForm()}
                            executingChildren={<><Spinner size="sm" /> {t('common.saving', 'Saving...')}</>}>
                            <FontAwesomeIcon icon="save" />
                            <> </>
                            {t('common.save', 'Save')}
                        </ButtonAsync>
                    </ConditionalFragment>

                    <Button type="button" color="primary" outline onClick={e => navigate(-1)}>
                        {t('common.cancel', 'Cancel')}
                    </Button>
                </FormButtons>
            </MainContainer>
        </>
    );
};