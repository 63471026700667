import { useLazyQuery } from "@apollo/client";
import { AsyncLoadOptions, AsyncLoadResult } from "../../../../shared/abstractStore";
import gql from "graphql-tag";
import { EditMemberViewModelQuery, EditMemberViewModelQueryVariables } from '../../generated/graphql';
import { profileFieldsFragment } from "../../models/Profile";
import { memberFieldsFragment } from "../../models/Member";
import { mainApiConfig } from "../../../../configure/mainApiConfig";
import { Guid } from "guid-string";
import React from "react";
import { staffNoteFieldsFragment } from "../../models/StaffNote";
import { childFieldsFragment } from "../../models/Child";
import { scheduledClassChildEventFieldsFragment } from "../../models/ScheduledClassChildEvent";

/**
 * Get all model data required for the EditMember component
 * @param id
 * @param options
 */
export function useEditMemberViewModel(id: string | undefined | null, options: AsyncLoadOptions = {}): AsyncLoadResult<EditMemberViewModelQuery> {
    // Query the data we need from the api
    const [load, { data, loading, error, refetch }] = useLazyQuery<EditMemberViewModelQuery, EditMemberViewModelQueryVariables>(
        gql`
        query editMemberViewModelQuery ($id: ID!) {
            model: profile (id: $id) {
                ...profileFields
            }

            member (id: $id) {
                ...memberFields
            }

            staffNotes (targetId: $id) {
                ...staffNoteFields
            }

            children (memberId: $id) {
                ...childFields
            }

            scheduledClassChildEvents (memberId: $id) {
                ...scheduledClassChildEventFields
            }
        }

        ${profileFieldsFragment}
        ${memberFieldsFragment}
        ${staffNoteFieldsFragment}
        ${childFieldsFragment}
        ${scheduledClassChildEventFieldsFragment}
        `,
        {
            variables: {
                id: id ?? Guid.empty,
            },
            fetchPolicy: options.fetchPolicy ?? mainApiConfig.defaultFetchPolicy,
            pollInterval: options.pollInterval,
            onCompleted: (data) => {
                if (options.onCompleted) {
                    options.onCompleted(data);
                }
            }
        }
    );

    // Run the query (unless we are being lazy).
    React.useEffect(() => {
        if (!options.lazy) {
            load();
        }
    }, [options.lazy, load]);

    // Return the results.
    const refresh = React.useCallback(async () => { !refetch ? await load() : await refetch(); }, [refetch, load]);
    return React.useMemo(() => ({
        data: data ?? ({} as any),
        refresh: refresh,
        isLoading: loading,
        errors: error,
    }), [data, refresh, loading, error]);
}
