import { NavItem } from 'reactstrap';
import { AuthorizeContainer } from '../../shared/authorizeRoute';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IdentityRoles } from '../../configure/security/IdentityRoles';
import { NavTitle } from '../shared/navTitle/NavTitle';
import { SidebarRequired } from '../layout/sidebar/sidebarRequired/SidebarRequired';
import { NavLinkTrackActive } from '../shared/navLinkTrackActive/NavLinkTrackActive';

/**
 * Staff management Administration navigation menu for the app. 
 */
export const StaffManagementNavigation = () => {
    const { t } = useTranslation();

    return (
        <>
            <SidebarRequired />
            <AuthorizeContainer requireRole={IdentityRoles.Administration}>
                <NavTitle>
                    {t('staffManagementNavigation.staffManagement.heading', 'Staff management')}
                </NavTitle>
                <NavItem>
                    <NavLinkTrackActive to="/administration/staff-management/staff">
                        <FontAwesomeIcon icon="people-group" className="nav-icon" />
                        {t('staffManagementNavigation.allStaff.name', 'All staff')}
                    </NavLinkTrackActive>
                </NavItem>
                <NavItem>
                    <NavLinkTrackActive to="/administration/staff-management/teachers">
                        <FontAwesomeIcon icon="person-chalkboard" className="nav-icon" />
                        {t('staffManagementNavigation.teachers.name', 'Teachers')}
                    </NavLinkTrackActive>
                </NavItem>
                <NavItem>
                    <NavLinkTrackActive to="/administration/staff-management/coordinators">
                        <FontAwesomeIcon icon="person-shelter" className="nav-icon" />
                        {t('staffManagementNavigation.coordinators.name', 'Cub coordinators')}
                    </NavLinkTrackActive>
                </NavItem>
                <NavItem>
                    <NavLinkTrackActive to="/administration/staff-management/office-staff">
                        <FontAwesomeIcon icon="user-gear" className="nav-icon" />
                        {t('staffManagementNavigation.officeStaff.name', 'Office staff')}
                    </NavLinkTrackActive>
                </NavItem>

                <NavTitle>
                    {t('staffManagementNavigation.absenceManagement.heading', 'Absence management')}
                </NavTitle>
                <NavItem>
                    <NavLinkTrackActive to="/administration/staff-management/absences">
                        <FontAwesomeIcon icon="mountain-city" className="nav-icon" />
                        {t('staffManagementNavigation.absences.name', 'Holidays and sickness')}
                    </NavLinkTrackActive>
                </NavItem>
                <NavItem>
                    <NavLinkTrackActive to="/administration/staff-management/arrange-cover">
                        <FontAwesomeIcon icon="people-arrows" className="nav-icon" />
                        {t('staffManagementNavigation.arrangeCover.name', 'Arrange cover')}
                    </NavLinkTrackActive>
                </NavItem>

                <NavTitle>
                    {t('staffManagementNavigation.staffQualifications.heading', 'Staff qualifications')}
                </NavTitle>
                <NavItem>
                    <NavLinkTrackActive to="/administration/staff-management/approve-qualifications">
                        <FontAwesomeIcon icon="stamp" className="nav-icon" />
                        {t('staffManagementNavigation.approveQualifications.name', 'Approve qualifications')}
                    </NavLinkTrackActive>
                </NavItem>
                <NavItem>
                    <NavLinkTrackActive to="/administration/staff-management/expiring-qualifications">
                        <FontAwesomeIcon icon="certificate" className="nav-icon" />
                        {t('staffManagementNavigation.expiringQualifications.name', 'Expiring qualifications')}
                    </NavLinkTrackActive>
                </NavItem>

                <NavTitle>
                    {t('staffManagementNavigation.lookupManagement.heading', 'Lookup management')}
                </NavTitle>
                <NavItem>
                    <NavLinkTrackActive to="/administration/staff-management/absence-types">
                        <FontAwesomeIcon icon="tags" className="nav-icon" />
                        {t('staffManagementNavigation.saveAbsenceTypes.name', 'Staff absence types')}
                    </NavLinkTrackActive>
                </NavItem>
            </AuthorizeContainer>
        </>
    );
};
