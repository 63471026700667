import placeholderImage from './happyCubsPlaceholderImage.png';
import memberPlaceholderImage from './happyCubsMemberPlaceholderImage.png';
import "./profileImageComponent.scss";

export interface ProfileImageComponentProps {
    imageUrl?: string | undefined | null,
    small?: boolean,
    tiny?: boolean,
    isReplyFromStaff?: boolean,
}

/**
 * Component used to render a Profile or Location image. If the record does not have one we will render the HappyCubs placeholder image
 */
export const ProfileImageComponent = (props: ProfileImageComponentProps) => {
    const { imageUrl, small, tiny, isReplyFromStaff } = props;

    // Render the UI
    //
    return (
        <>
            <img src={isReplyFromStaff ? placeholderImage : imageUrl ?? memberPlaceholderImage} alt="profile" className={`profile-image-component ${small ? 'profile-image-component-small' : tiny ? 'profile-image-component-tiny' : ''}`} />
        </>
    );
};