import { useTranslation } from "react-i18next";
import { StaffsList } from "./StaffsList";

/**
 * StaffList filtered to just show admin staff.
 */
export const AdminStaffsList = () => {
    const { t } = useTranslation();

    return (
        <StaffsList
            title={t('administratorsList.title', 'Office Staff')}
            filterByStaffType={(item) => !!item.staff?.isAdminStaff}
        />
    );
};