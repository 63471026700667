import { Button, Row, Col, Form, Label, FormGroup, Spinner, FormText, InputGroup, Input } from 'reactstrap';
import { AlertOnErrors } from '../../shared/alertOnErrors';
import { LoadingIndicator } from '../shared/loadingIndicator/LoadingIndicator';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MainContainer } from '../shared/mainContainer/MainContainer';
import { useParams, useNavigate } from 'react-router';
import { useChanges } from '../../shared/useChanges';
import { useValidatorCallback } from 'pojo-validator-react';
import { ValidatedInput } from 'pojo-validator-reactstrap';
import { FormButtons } from '../shared/formButtons/FormButtons';
import { ButtonAsync } from 'reactstrap-buttonasync';
import { useAsyncCallback } from 'react-use-async-callback';
import { ConditionalFragment } from 'react-conditionalfragment';
import { Banner } from '../shared/banner/Banner';
import { StickyToolbar } from '../shared/stickyToolbar/StickyToolbar';
import { useGeneralDiscount } from '../../api/main/generalDiscounts/useGeneralDiscount';
import { GeneralDiscount, generalDiscountDefaultValues } from '../../api/main/models/GeneralDiscount';
import { useSaveGeneralDiscountMutation } from '../../api/main/generalDiscounts/useSaveGeneralDiscountMutation';
import { generalDiscountAutomationKeyDisplayName, getGeneralDiscountAutomationKeys } from '../../api/main/models/constants/GeneralDiscountAutomationKey';
import { useEffect, useState } from 'react';
import { HappyIcon } from '../shared/Utilities/HappyIcon';

export interface EditGeneralDiscountProps {
    isCreate?: boolean,
    onCreateDefaultValues?: () => Partial<GeneralDiscount>
}

/**
 * Component to create an GeneralDiscount.
 */
export const CreateGeneralDiscount = (props: EditGeneralDiscountProps) => (<EditGeneralDiscount isCreate={true} {...props} />);

/**
 * Component to edit an GeneralDiscount.
 */
export const EditGeneralDiscount = (props: EditGeneralDiscountProps) => {
    const {
        isCreate,
        onCreateDefaultValues,
    } = props

    const { t } = useTranslation();
    const { id } = useParams<{ id: string | undefined }>();
    const navigate = useNavigate();
    const [discountType, setDiscountType] = useState('');


    // Load all data.
    const {
        data: {
            model: storeModel,
        },
        errors: loadErrors, isLoading,
    } = useGeneralDiscount(id);


    // Model (GeneralDiscount)
    const { model, change, changes } = useChanges(storeModel, isCreate ? { ...generalDiscountDefaultValues(), ...(onCreateDefaultValues ? onCreateDefaultValues() : {}) } : undefined);
    const [saveGeneralDiscount, { errors: saveErrors }] = useSaveGeneralDiscountMutation();

    // UseEffect - Checks whether the DiscountAmount is non-zero, if it is show amount, else show percentage
    useEffect(() => {
        model?.discountAmount > 0 ? setDiscountType('monetary') : setDiscountType('percentage');

    }, [model?.discountAmount])

    // Main model validation.
    const [validate, validationErrors] = useValidatorCallback((validation, fieldsToCheck) => {
        const rules = {
            name: () => !model?.name ? t('editGeneralDiscount.errors.name', 'Name cannot be empty') : '',
        };
        validation.checkRules(rules, fieldsToCheck);
    }, [model]);

    // Save everything.
    const [saveForm, { isExecuting: isSaving, errors: saveFormErrors }] = useAsyncCallback(async () => {
        if (!model) {
            return;
        }

        if (!validate()) {
            return;
        }

        // Save the main model.
        await saveGeneralDiscount(model.id, { ...changes }, isCreate ?? false);

        // Go back to previous screen.
        navigate(-1)
    }, [validate, saveGeneralDiscount, model, changes, isCreate, id, navigate]);
    // Edit form
    /////////////
    return (
        <>
            <Banner>
                <StickyToolbar>
                    <Row>
                        <Col>
                            <h1>
                                {
                                    isCreate ? t('editGeneralDiscount.createHeading.default', 'Add General Discount')
                                        : t('editGeneralDiscount.editHeading.default', 'Edit General Discount')
                                }
                            </h1>
                            <h3>{model?.name}</h3>
                        </Col>
                        <ConditionalFragment showIf={isLoading}>
                            <Col xs="auto">
                                <LoadingIndicator size="sm" />
                            </Col>
                        </ConditionalFragment>
                    </Row>
                </StickyToolbar>
            </Banner>
            <MainContainer>
                <AlertOnErrors errors={[
                    loadErrors,
                    saveFormErrors, saveErrors,
                ]} />

                <Form onSubmit={e => { e.preventDefault(); saveForm(); }}>
                    <FormGroup>
                        <Label htmlFor="name">{t('editGeneralDiscount.name.label', 'Name')}</Label>
                        <ValidatedInput name="name" type="text" value={model?.name ?? ''} onChange={e => change({ name: e.currentTarget.value })} onBlur={e => validate('name')} validationErrors={validationErrors['name']} />
                    </FormGroup>
                    
                    <Row>
                        <Col>
                            <FormGroup>
                                <Label htmlFor="discountType">{t('editGeneralDiscount.discountType.label', 'Type')}</Label>
                                <Input name="discountType" type="select" value={discountType} onChange={e => setDiscountType(e.currentTarget.value)}>
                                    <option value="">
                                        {t('editGeneralDiscount.discountType.pleaseSelect', '(Please select a discount type to apply)')}
                                    </option>
                                    <option value="monetary">{t('editGeneralDiscount.discountType.monetary', 'Monetary')}</option>
                                    <option value="percentage">{t('editGeneralDiscount.discountType.percentage', 'Percentage')}</option>
                                </Input>
                            </FormGroup>
                        </Col>
                      
                        <Col>
                            {/* Selected discount type */}
                            {/* FormGroup and Label placed outside of conditional due to the input element being the only dynamic content */}
                            {/* If the user user changes the value of either Monetary or Percentage Amounts, then the other will be set to zero */}
                            <FormGroup>
                                <Label htmlFor="discountAmount">
                                    {t('editGeneralDiscount.amount.discountAmount.label', 'Discount amount')}
                                </Label>
                                {
                                    discountType === 'monetary' ? (
                                        <InputGroup>
                                            <Button color="secondary" onClick={(e) => e.preventDefault()}><HappyIcon icon="euro" /></Button>
                                            <Input name="discountAmount" type="number" value={model?.discountAmount ?? 0} onChange={e => change({ discountAmount: e.currentTarget.valueAsNumber, discountPercentage: 0 })} />
                                        </InputGroup>
                                    ) : discountType === 'percentage' ? (
                                        <InputGroup>
                                                <Input name="discountPercentage" type="number" value={model?.discountPercentage ?? 0} onChange={e => change({ discountPercentage: e.currentTarget.valueAsNumber, discountAmount: 0 })} max={100} />
                                                <Button color="secondary" onClick={(e) => e.preventDefault()}><HappyIcon icon="percent" /></Button>
                                        </InputGroup>
                                    ) : (
                                        <Input name="friendlyMessage" value="Please select a discount type" disabled />
                                    )
                                }
                            </FormGroup>
                        </Col>
                    </Row>

                    <FormGroup>
                        <Label htmlFor="automationKey">{t('editGeneralDiscount.automationKey.label', 'Automation')}</Label>
                        <ValidatedInput name="automationKey" type="select" value={model?.automationKey ?? ''} onChange={e => change({ automationKey: e.currentTarget.value })} onBlur={e => validate('automationKey')} validationErrors={validationErrors['automationKey']}>
                            {
                                getGeneralDiscountAutomationKeys().map(item => (
                                    <option key={item} value={item}>{generalDiscountAutomationKeyDisplayName(item, t)}</option>
                                ))
                            }
                        </ValidatedInput>
                        <FormText>
                            {t('editGeneralDiscount.automationKey.formText', 'The automation key is used by the system to decide when to automatically apply this discount.  You will generally want only one general discount for each automation key.')}
                        </FormText>
                    </FormGroup>
                    



                    <FormButtons>
                        <ConditionalFragment showIf={!isLoading}>
                            <ButtonAsync color="primary" isExecuting={isSaving} onClick={() => saveForm()}
                                executingChildren={<><Spinner size="sm" /> {t('common.saving', 'Saving...')}</>}>
                                <FontAwesomeIcon icon="save" />
                                <> </>
                                {t('common.save', 'Save')}
                            </ButtonAsync>
                        </ConditionalFragment>

                        <Button type="button" color="primary" outline onClick={e => navigate(-1)}>
                            {t('common.cancel', 'Cancel')}
                        </Button>
                    </FormButtons>
                </Form>
            </MainContainer>
        </>
    );
}
