import { gql, useLazyQuery } from "@apollo/client";
import { Guid } from "guid-string";
import React from "react";
import { mainApiConfig } from "../../../../configure/mainApiConfig";
import { AsyncLoadOptions, AsyncLoadResult } from "../../../../shared/abstractStore";
import { ConsumeStaffScheduledClassesListViewModelQuery, ConsumeStaffScheduledClassesListViewModelQueryVariables } from '../../generated/graphql';
import { childFieldsFragment } from "../../models/Child";
import { classLocationFieldsFragment } from "../../models/ClassLocation";
import { classStageFieldsFragment } from "../../models/ClassStage";
import { classSubStageFieldsFragment } from "../../models/ClassSubStage";
import { classTypeFieldsFragment } from "../../models/ClassType";
import { scheduledClassFieldsFragment } from "../../models/ScheduledClass";
import { scheduledClassCancellationFieldsFragment } from "../../models/ScheduledClassCancellation";
import { scheduledClassChildFieldsFragment } from "../../models/ScheduledClassChild";
import { scheduledClassStaffFieldsFragment } from "../../models/ScheduledClassStaff";
import { termFieldsFragment } from "../../models/Term";

export function useConsumeStaffScheduledClassesListViewModel(id: string | undefined | null, options: AsyncLoadOptions = {}): AsyncLoadResult<ConsumeStaffScheduledClassesListViewModelQuery> {
    const [load, { data, loading, error, refetch }] = useLazyQuery<ConsumeStaffScheduledClassesListViewModelQuery, ConsumeStaffScheduledClassesListViewModelQueryVariables>(
        gql`
        query consumeStaffScheduledClassesListViewModelQuery ($id: ID!) {
            items: scheduledClassStaffs (staffId: $id) {
                ...scheduledClassStaffFields

                scheduledClass {
                    ...scheduledClassFields

                    classLocation {
                        ...classLocationFields
                    }

                    scheduledClassChildren (isOnWaitingList: false) {
                        ...scheduledClassChildFields

                        child {
                            ...childFields
                        }
                    }
                }
            }

            classStages {
                ...classStageFields
            }

            classSubStages {
                ...classSubStageFields
            }

            classTypes {
                ...classTypeFields
            }

            scheduledClassCancellations {
                ...scheduledClassCancellationFields
            }

            terms {
                ...termFields
            }
        }

        ${scheduledClassStaffFieldsFragment}
        ${scheduledClassFieldsFragment}
        ${classLocationFieldsFragment}
        ${classStageFieldsFragment}
        ${classSubStageFieldsFragment}
        ${scheduledClassChildFieldsFragment}
        ${classTypeFieldsFragment}
        ${childFieldsFragment}
        ${scheduledClassCancellationFieldsFragment}
        ${termFieldsFragment}
        `,
        {
            variables: {
                id: id ?? Guid.empty,
            },
            fetchPolicy: options.fetchPolicy ?? mainApiConfig.defaultFetchPolicy,
            pollInterval: options.pollInterval,
            onCompleted: (data) => {
                if (options.onCompleted) {
                    options.onCompleted(data);
                }
            }
        }
    );

    // Run the query (unless we are being lazy).
    React.useEffect(() => {
        if (!options.lazy) {
            load();
        }
    }, [options.lazy, load]);

    // Return the results.
    const refresh = React.useCallback(async () => { !refetch ? await load() : await refetch(); }, [refetch, load]);
    return React.useMemo(() => ({
        data: data ?? ({} as any),
        refresh: refresh,
        isLoading: loading,
        errors: error
    }), [data, refresh, loading, error]);
};