import { ScrollTo } from "@scottbamford/react-scrollto";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Card, CardBody, CardHeader, Col, Collapse, Row, TabPane } from "reactstrap";
import { useToggleState } from "use-toggle-state";
import { ClassStage } from "../../api/main/models/ClassStage";
import { ClassSubStage } from "../../api/main/models/ClassSubStage";
import { LessonDocument } from "../../api/main/models/LessonDocument";
import { CardHeaderCollapse } from "../shared/cardHeaderCollapse/CardHeaderCollapse";
import { LessonDocumentComponent } from "./LessonDocumentComponent";
import { LessonPlanWithEvidenceBlobReference } from "./LessonPlansList";

export interface LessonComponentProps {
    classStage: ClassStage | null | undefined,
    classSubStage: ClassSubStage | null | undefined,
    lessonDocuments: Array<LessonDocument> | null | undefined,
    stageIsOpen: boolean,
    scrollToLessonNumber?: string | null | undefined,
};

/**
 * LessonDocuments for a single SubStage
 */
export const LessonComponent = (props: LessonComponentProps) => {
    const {
        classStage,
        classSubStage,
        lessonDocuments,
        stageIsOpen,
        scrollToLessonNumber
    } = props;
    const { t } = useTranslation();

    // Handle toggling the CardCollapse
    const [isOpen, toggleOpen] = useToggleState(stageIsOpen);

    // Extract LessonPlans and SupportingDocuments
    const lessonPlans = useMemo(() => {
        return lessonDocuments?.filter(it => it.isLessonPlan === true);
    }, [lessonDocuments])
    const supportingDocuments = useMemo(() => {
        return lessonDocuments?.filter(it => it.isLessonPlan === false);
    }, [lessonDocuments]);

    // Redner UI
    //
    return (
        <TabPane tabId={classStage?.name}>
            <Card style={{ margin: '10px 0' }}>
                <CardHeaderCollapse isOpen={isOpen} toggle={toggleOpen}>
                    <Row>
                        <Col>
                            {classSubStage?.name}
                        </Col>
                    </Row>
                </CardHeaderCollapse>
                 
                <Collapse isOpen={isOpen}>
                    <CardBody>
                        {
                            // Generate an Array of length ClassSubStage.numberOfLessons, take the index and add 1 (zero indexed, but no lesson zero) in the callback. Then we can map over for each lessonNumber
                            Array.from({ length: classSubStage?.numberOfLessons }, (_, index) => index + 1).map(lessonNumber => (
                                <ScrollTo key={lessonNumber} shouldScrollTo={lessonNumber.toString() === scrollToLessonNumber}>
                                    <Card style={{ margin: '10px 0' }} key={lessonNumber}>
                                        <CardHeader>
                                            {t('staffLessonPlansList.lessonComponent.lessonNumber.value', 'Lesson {{lessonNumber}}', { lessonNumber })}
                                        </CardHeader>

                                        <CardBody>
                                            {
                                                lessonPlans?.filter(it => it.termLessonNumber === lessonNumber).map(item => (
                                                    <LessonDocumentComponent
                                                        lessonDocument={item as LessonPlanWithEvidenceBlobReference}
                                                        key={item.id}
                                                    />
                                                )
                                            )}
                                            {
                                                supportingDocuments?.filter(it => it.termLessonNumber === lessonNumber).map(item => (
                                                    <LessonDocumentComponent
                                                        lessonDocument={item}
                                                        isSupportingDocument={true}
                                                        key={item.id}
                                                        />
                                                )
                                            )}
                                        </CardBody>
                                    </Card>
                                </ScrollTo>
                            ))
                        }
                    </CardBody>
                </Collapse>
            </Card>
        </TabPane>
    );
};