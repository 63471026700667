import { NavItem } from 'reactstrap';
import { AuthorizeContainer } from '../../shared/authorizeRoute';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IdentityRoles } from '../../configure/security/IdentityRoles';
import { NavTitle } from '../shared/navTitle/NavTitle';
import { SidebarRequired } from '../layout/sidebar/sidebarRequired/SidebarRequired';
import { NavLinkTrackActive } from '../shared/navLinkTrackActive/NavLinkTrackActive';

/**
 * Children Management Administration navigation menu for the app. 
 */
export const ChildrenManagementNavigation = () => {
    const { t } = useTranslation();

    return (
        <>
            <SidebarRequired />
            <AuthorizeContainer requireRole={IdentityRoles.Administration}>
                <NavTitle>
                    {t('childrenManagementNavigation.children.heading', 'Member management')}
                </NavTitle>
                <NavItem>
                    <NavLinkTrackActive to="/administration/children-management/children">
                        <FontAwesomeIcon icon="child-reaching" className="nav-icon" />
                        {t('childrenManagementNavigation.children.name', 'Bear cubs')}
                    </NavLinkTrackActive>
                </NavItem>
                <NavItem>
                    <NavLinkTrackActive to="/administration/children-management/members">
                        <FontAwesomeIcon icon="person-breastfeeding" className="nav-icon" />
                        {t('childrenManagementNavigation.members.name', 'Mama and papa bears')}
                    </NavLinkTrackActive>
                </NavItem>
                <NavItem>
                    <NavLinkTrackActive to="/administration/children-management/child-absences">
                        <FontAwesomeIcon icon="clipboard" className="nav-icon" />
                        {t('childrenManagementNavigation.childAbsences.name', 'Child absences')}
                    </NavLinkTrackActive>
                </NavItem>

                <NavTitle>
                    {t('childrenManagementNavigation.activeAndWaiting.heading', 'Active and waiting')}
                </NavTitle>
                <NavItem>
                    <NavLinkTrackActive to="/administration/children-management/register">
                        <FontAwesomeIcon icon="person-swimming" className="nav-icon" />
                        {t('childrenManagementNavigation.classParticipation.name', 'Class registers')}
                    </NavLinkTrackActive>
                </NavItem>
                <NavItem>
                    <NavLinkTrackActive to="/administration/children-management/waiting-lists">
                        <FontAwesomeIcon icon="stopwatch" className="nav-icon" />
                        {t('childrenManagementNavigation.waitingLists.name', 'Waiting lists')}
                    </NavLinkTrackActive>
                </NavItem>

                <NavTitle>
                    {t('childrenManagementNavigation.unpaid.heading', 'Unpaid enrolments')}
                </NavTitle>
                <NavItem>
                    <NavLinkTrackActive to="/administration/children-management/unpaid">
                        <FontAwesomeIcon icon="money-bill" className="nav-icon" />
                        {t('childrenManagementNavigation.classParticipationUnpaid.name', 'Unpaid registers')}
                    </NavLinkTrackActive>
                </NavItem>

                <NavTitle>
                    {t('childrenManagementNavigation.financial.heading', 'Payments')}
                </NavTitle>
                <NavItem>
                    <NavLinkTrackActive to="/administration/children-management/outstanding-payments">
                        <FontAwesomeIcon icon="money-bill-1-wave" className="nav-icon" />
                        {t('childrenManagementNavigation.outstandingPayments.name', 'Outstanding payments')}
                    </NavLinkTrackActive>
                </NavItem>
                <NavItem>
                    <NavLinkTrackActive to="/administration/children-management/payment-history">
                        <FontAwesomeIcon icon="check" className="nav-icon" />
                        {t('childrenManagementNavigation.paymentsHistory.name', 'Payment history')}
                    </NavLinkTrackActive>
                </NavItem>

                <NavTitle>
                    {t('childrenManagementNavigation.discounts.heading', 'Discounts')}
                </NavTitle>
                <NavItem>
                    <NavLinkTrackActive to="/administration/children-management/general-discounts">
                        <FontAwesomeIcon icon="percent" className="nav-icon" />
                        {t('childrenManagementNavigation.generalDiscounts.name', 'General discounts')}
                    </NavLinkTrackActive>
                </NavItem>
                <NavItem>
                    <NavLinkTrackActive to="/administration/children-management/gift-vouchers">
                        <FontAwesomeIcon icon="gift" className="nav-icon" />
                        {t('childrenManagementNavigation.giftVouchers.name', 'Gift Vouchers')}
                    </NavLinkTrackActive>
                </NavItem>

                <NavTitle>
                    {t('childrenManagementNavigation.marketingExports.heading', 'Marketing exports')}
                </NavTitle>
                <NavItem>
                    <NavLinkTrackActive to="/administration/children-management/exports">
                        <FontAwesomeIcon icon="file-export" className="nav-icon" />
                        {t('childrenManagementNavigation.exportChildren.name', 'Export to Excel')}
                    </NavLinkTrackActive>
                </NavItem>

                <NavTitle>
                    {t('childrenManagementNavigation.lookupManagement.heading', 'Lookup management')}
                </NavTitle>
                <NavItem>
                    <NavLinkTrackActive to="/administration/children-management/genders">
                        <FontAwesomeIcon icon="person-half-dress" className="nav-icon" />
                        {t('childrenManagementNavigation.genders.name', 'Genders')}
                    </NavLinkTrackActive>
                </NavItem>
                <NavItem>
                    <NavLinkTrackActive to="/administration/children-management/childs-relationships">
                        <FontAwesomeIcon icon="link" className="nav-icon" />
                        {t('childrenManagementNavigation.childsRelationships.name', 'Relationships')}
                    </NavLinkTrackActive>
                </NavItem>
                <NavItem>
                    <NavLinkTrackActive to="/administration/children-management/absence-types">
                        <FontAwesomeIcon icon="tags" className="nav-icon" />
                        {t('childrenManagementNavigation.saveAbsenceTypes.name', 'Child absence types')}
                    </NavLinkTrackActive>
                </NavItem>
            </AuthorizeContainer>
        </>
    );
};
