import { useMutation } from "@apollo/react-hooks";
import { useAsyncCallback } from 'react-use-async-callback';
import gql from "graphql-tag";
import { CancelExpiredBookingMutation, CancelExpiredBookingMutationVariables } from '../../generated/graphql';
import { AsyncActionStatus } from "../../../../shared/abstractStore";
import { scheduledClassChildFieldsFragment } from "../../models/ScheduledClassChild";

/**
 * Get a callback to create a Payment in the api.
 */
export function useCancelExpiredBookingMutation(): [(childId: string, scheduledClassId: string, paymentId: string) => Promise<void>, AsyncActionStatus] {
    const [mutationAction, { error }] = useMutation<CancelExpiredBookingMutation, CancelExpiredBookingMutationVariables>(
        gql`
            mutation cancelExpiredBookingMutation ($childId: ID!, $scheduledClassId: ID!, $paymentId: ID!) {
                cancelExpiredBooking(childId: $childId, scheduledClassId: $scheduledClassId, paymentId: $paymentId) {
                    ...scheduledClassChildFields
                }
            }

            ${scheduledClassChildFieldsFragment}
        `
    );

    // Wrap the mutation in a standard callback.
    const [action, { isExecuting, errors: actionErrors }] = useAsyncCallback(async (childId: string, scheduledClassId: string, paymentId: string) => {
        const result = await mutationAction({ variables: { childId, scheduledClassId, paymentId } });
        if (result.errors && result.errors.length) {
            throw result.errors;
        }
    }, [mutationAction]);

    return [action, { isExecuting: isExecuting, errors: error ? error.message : actionErrors }];
}
