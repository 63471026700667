import { ConditionalFragment } from "react-conditionalfragment";
import { useTranslation } from "react-i18next";
import { Col, Row } from "reactstrap";
import { useConsumeMembersChildrenViewModel } from "../../api/main/children/viewModels/useConsumeMembersChildrenViewModel";
import { useCurrentUserProfile } from "../../api/main/profiles/useCurrentUserProfile";
import { AlertOnErrors } from "../../shared/alertOnErrors";
import { PaymentsTab } from "../membersChildren/membersChild/paymentsTab/PaymentsTab";
import { NoticeBoard } from "../noticeBoard/NoticeBoard";
import { Banner } from "../shared/banner/Banner";
import { LoadingIndicator } from "../shared/loadingIndicator/LoadingIndicator";
import { MainContainer } from "../shared/mainContainer/MainContainer";
import { NoResultsFound } from "../shared/noResultsFound/NoResultsFound";
import { StickyToolbar } from "../shared/stickyToolbar/StickyToolbar";

/**
 * List all payments for a member.  To show this we load all children and let them load their own payments each using the PaymentsTab component,
 * this ensures the display is the same regardless of if the user is viewing a full list or child specific list, without having to maintain code
 * in both places to match.
 * 
 * 
 */
export const MyPayments = () => {
    const { t } = useTranslation();

    // Load the current user Id
    const {
        data: {
            model
        }, isLoading: isLoadingUser, errors: userLoadErrors
    } = useCurrentUserProfile();

    // Load the data
    // NOTE we use useConsumeMembersChildrenViewModel here because its likely already cached a result from loading the home page, so it
    // seems silly to make it do it again with a slightly different query that is not cached just because we don't want all the information.
    const {
        data: {
            items: children
        }, isLoading: _isLoading, errors: loadErrors,
    } = useConsumeMembersChildrenViewModel(model?.id);

    const isLoading = _isLoading || isLoadingUser;

    // Render the UI
    //
    return (
        <>
            <Banner fluid>
                <Row>
                    <NoticeBoard />
                </Row>
                <StickyToolbar>
                    <Row>
                        <Col>
                            <h1>{t('myPayments.title', 'Payments')}</h1>
                        </Col>
                        <ConditionalFragment showIf={isLoading}>
                            <Col xs="auto">
                                <LoadingIndicator size="sm" />
                            </Col>
                        </ConditionalFragment>
                    </Row>
                </StickyToolbar>
            </Banner>

            <MainContainer fluid>
                <AlertOnErrors
                    errors={[
                        loadErrors,
                        userLoadErrors
                    ]}
                />

                <div>
                    {
                        children?.map(child => (
                            <div className="mb-4" key={child.id}>
                                <h5>{t('common.fullName', '{{firstName}} {{lastName}}', { firstName: child.firstName, lastName: child.lastName, })}</h5>

                                <PaymentsTab
                                    model={child}
                                />
                            </div>
                        ))
                    }

                    <ConditionalFragment showIf={!isLoading && !children?.length}>
                        <NoResultsFound icon={['far', 'face-smile']}>
                            {t('myPayments.noItemsText', 'There are no outstanding payments.')}
                        </NoResultsFound>
                    </ConditionalFragment>
                </div>
            </MainContainer>
        </>
    );
};