import { useMutation } from "@apollo/react-hooks";
import { useAsyncCallback } from 'react-use-async-callback';
import gql from "graphql-tag";
import { AsyncActionStatus } from '../../../shared/abstractStore';
import { CreateCourtesyClassCreditMutation, CreateCourtesyClassCreditMutationVariables, CourtesyClassCreditCreateInput } from '../generated/graphql';
import { courtesyClassCreditFieldsFragment } from '../models/CourtesyClassCredit';

/**
 * Get a callback to create a CourtesyClassCredit in the api.
 */
export function useCreateCourtesyClassCreditMutation(): [(model: CourtesyClassCreditCreateInput) => Promise<void>, AsyncActionStatus] {
    const [mutationAction, { error }] = useMutation<CreateCourtesyClassCreditMutation, CreateCourtesyClassCreditMutationVariables>(
        gql`
            mutation createCourtesyClassCreditMutation ($model: CourtesyClassCreditCreateInput!) {
                createCourtesyClassCredit(model: $model) {
                    ...courtesyClassCreditFields
                }
            }

            ${courtesyClassCreditFieldsFragment}
        `
    );

    // Wrap the mutation in a standard callback.
    const [action, { isExecuting, errors: actionErrors }] = useAsyncCallback(async (model: CourtesyClassCreditCreateInput) => {
        const result = await mutationAction({ variables: { model: model } });
        if (result.errors && result.errors.length) {
            throw result.errors;
        }
    }, [mutationAction]);

    return [action, { isExecuting: isExecuting, errors: error ? error.message : actionErrors }];
}
