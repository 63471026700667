import gql from 'graphql-tag';
import { Guid } from 'guid-string';
import { CourtesyClassCreditFieldsFragment } from '../generated/graphql';

export const courtesyClassCreditFieldsFragment = gql`
    fragment courtesyClassCreditFields on CourtesyClassCredit {
        id
        childId
        termId
        childAbsenceId
        financialValue
        isConsumed
        consumedOnDate
        consumedForScheduledClassId
        consumedForDate
        archived
        name
        scheduledClassId
    }
`;

export type CourtesyClassCredit = Omit<CourtesyClassCreditFieldsFragment, '__typename'>;

/**
 * Default values.
 */
export const courtesyClassCreditDefaultValues = (): Partial<CourtesyClassCredit> => ({
    id: Guid.newGuid(),
    childId: undefined,
    termId: undefined,
    childAbsenceId: null,
    financialValue: 0,
    isConsumed: false,
    consumedOnDate: null,
    consumedForScheduledClassId: null,
    consumedForDate: null,
    archived: false,
    name: '',
    scheduledClassId: undefined
});