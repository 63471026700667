import { ValidationErrors } from "pojo-validator";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Button, FormGroup, Label, ListGroup } from "reactstrap";
import { Child } from "../../../../api/main/models/Child";
import { ChildsRelationship } from "../../../../api/main/models/ChildsRelationship";
import { EmergencyContact, emergencyContactDefaultValues } from "../../../../api/main/models/EmergencyContact";
import { ModelArrayChanges } from "../../../../shared/useChanges";
import { EmergencyContactComponent } from "./EmergencyContactComponent";

export interface EmergencyContactsTabProps {
    model: Child | null | undefined,

    childEmergencyContactsManager: ModelArrayChanges<EmergencyContact, string>,
    validateChildEmergencyContact: (model: EmergencyContact) => boolean,
    childEmergencyContactValidationErrors: (id: string) => ValidationErrors,
    childsRelationships: Array<ChildsRelationship> | null | undefined;
}

/**
 * EmergencyContacts for Children
 */
export const EmergencyContactsTab = (props: EmergencyContactsTabProps) => {
    const { model, childEmergencyContactsManager, validateChildEmergencyContact, childEmergencyContactValidationErrors, childsRelationships} = props;
    const { t } = useTranslation();

    // Add a new EmergencyContact and link it to the parent model
    const addChildEmergencyContact = useCallback(() => {
        childEmergencyContactsManager.addFor({ ...emergencyContactDefaultValues(), childsRelationshipId: model?.memberChildsRelationshipId, targetId: model?.id, targetType: 'Child' });
    }, [childEmergencyContactsManager, model]);

    // Render the UI
    //
    return (
        <>
            <FormGroup>
                <Label htmlFor="childEmergencyContacts">
                    {t('editChild.childEmergencyContactsTab.headingLabel', 'Emergency contacts for this cub:')}
                </Label>

                <ListGroup id="childEmergencyContacts">
                    {
                        childEmergencyContactsManager.model.map(item => (
                            <EmergencyContactComponent key={item.id}
                                model={item}
                                change={changes => childEmergencyContactsManager.changeFor(item.id, changes)}
                                remove={() => childEmergencyContactsManager.removeFor(item.id)}
                                validate={() => validateChildEmergencyContact(item)}
                                validationErrors={childEmergencyContactValidationErrors(item.id)}
                                childsRelationships={childsRelationships}
                            />
                        ))
                    }
                </ListGroup>

                <div className="mt-2">
                    <Button color="primary" outline onClick={() => addChildEmergencyContact()}>
                        {t('editChild.childEmergencyContactsTab.add', 'Add emergency contact')}
                    </Button>
                </div>
            </FormGroup>
        </>
    );
};