import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { StaffSubmissionAcceptanceState } from "../../../api/main/models/constants/StaffSubmissonAcceptanceState";
import { StaffQualificationCheck } from "../../../api/main/models/StaffQualificationCheck";

export function useStaffQualificationCheckStatusColorAndMessage(model: StaffQualificationCheck | null | undefined) {
    const { t } = useTranslation();
    return useMemo(() => {
        switch (model?.acceptanceState) {
            case StaffSubmissionAcceptanceState.Approved: return { color: undefined, statusMessage: t('useStaffQualificationCheckStatusColorAndMessage.approved.message', 'Approved') };
            case StaffSubmissionAcceptanceState.SelfAccepted: return { color: undefined, statusMessage: t('useStaffQualificationCheckStatusColorAndMessage.selfApproved.message', 'Self approved') };
            case StaffSubmissionAcceptanceState.Rejected: return { color: 'danger', statusMessage: t('useStaffQualificationCheckStatusColorAndMessage.rejected.message', 'Rejected') };
            case StaffSubmissionAcceptanceState.Submitted: return { color: undefined, statusMessage: t('useStaffQualificationCheckStatusColorAndMessage.pending.message', 'Pending') };
        }

        // If we hit here we don't know, so return some defaults.
        return { color: undefined, statusMessage: undefined };
    }, [model, t]);
}