import { IdentityRoles } from '../../configure/security/IdentityRoles';
import { RouteEntry } from '../../shared/routes';
import { StaffAbsenceTypesList } from './StaffAbsenceTypesList';
import { CreateStaffAbsenceType, EditStaffAbsenceType } from './EditStaffAbsenceType';
import { DeleteStaffAbsenceType } from './DeleteStaffAbsenceType'
/**
 * Routes that should be added to configuration/routeConfig.ts to provide access to these components via routing.
 */
export const staffAbsenceTypesRoutes: Array<RouteEntry> = [
    { path: '/administration/staff-management/absence-types/add', element: <CreateStaffAbsenceType />, authorize: true, requireRole: IdentityRoles.Administration },
    { path: '/administration/staff-management/absence-types/edit/:id', element: <EditStaffAbsenceType />, authorize: true, requireRole: IdentityRoles.Administration },
    { path: '/administration/staff-management/absence-types/delete/:id', element: <DeleteStaffAbsenceType />, authorize: true, requireRole: IdentityRoles.Administration },
    { path: '/administration/staff-management/absence-types', element: <StaffAbsenceTypesList />, authorize: true, requireRole: IdentityRoles.Administration }
];
