import { useMemo, useState } from "react";
import { ConditionalFragment } from "react-conditionalfragment";
import { useTranslation } from "react-i18next";
import { Button, ButtonGroup, Col, Row, Table } from "reactstrap";
import { useStaffAbsenceViewModel } from "../../api/main/staffAbsences/viewModels/useStaffAbsenceViewModel";
import { AlertOnErrors } from "../../shared/alertOnErrors";
import { Banner } from "../shared/banner/Banner";
import { LoadingIndicator } from "../shared/loadingIndicator/LoadingIndicator";
import { MainContainer } from "../shared/mainContainer/MainContainer";
import { SearchInput } from "../shared/searchInput/SearchInput";
import { StickyToolbar } from "../shared/stickyToolbar/StickyToolbar";
import { useReplaceSearchParamsEffect, useSearchParams } from '../../shared/useURLSearchParams';
import { useChangesArray } from "../../shared/useChanges";
import { StaffAbsence } from "../../api/main/models/StaffAbsence";
import { useSaveStaffAbsenceMutation } from "../../api/main/staffAbsences/useSaveStaffAbsenceMutation";
import { Profile } from "../../api/main/models/Profile";
import { StaffAbsenceItemHistorical } from "./StaffAbsenceItemHistorical";
import { StaffAbsenceType } from "../../api/main/models/StaffAbsenceType";
import { useCurrentUserId } from "../../api/account";
import { StaffSubmissionAcceptanceState } from "../../api/main/models/constants/StaffSubmissonAcceptanceState";
import { StaffAbsenceItemOutstanding } from "./StaffAbsenceItemOutstanding";

export interface StaffAbsenceWithProfileAndAbsenceType extends StaffAbsence {
    profile?: Profile,
    absenceType?: StaffAbsenceType,
}

/**
 * List of all StaffAbsences
 */
export const StaffAbsenceList = () => {
    const { t } = useTranslation();

    // Current userId - used for marking approvedByStaffUserId on StaffAbsence requests
    const currentUserId = useCurrentUserId();

    // Load data
    const {
        data: {
            staffAbsences: storeStaffAbsences,
            staffAbsenceTypes,
            profiles
        }, isLoading, errors: loadErrors
    } = useStaffAbsenceViewModel();

    // Wrap a manager around _allItems so we can makes changes to it in memory
    const staffAbsenceManager = useChangesArray<StaffAbsence, string>(storeStaffAbsences, item => item.id);

    // Combine all the loaded data so tat each item includes its related details
    const allItems = useMemo(() => staffAbsenceManager?.model.map(item => {
        const profile = profiles?.find(it => it.id === item.staffId);
        const absenceType = staffAbsenceTypes?.find(it => it.id === item.staffAbsenceTypeId);

        return {
            ...item,

            profile,
            absenceType
        };
    }), [staffAbsenceManager, profiles, staffAbsenceTypes]);

    // Search.
    const { search: searchParam } = useSearchParams();
    const [search, setSearch] = useState<string>(searchParam ?? '');

    // Keep the URL up to date with the search text.
    useReplaceSearchParamsEffect({ search: search });

    // Toggle between outstand StaffAbsence requests and historical ones
    const [staffAbsenceFilter, setStaffAbsenceFilter] = useState<'outstanding' | 'historical'>('outstanding');

    // Filtering
    const items = useMemo(() => {
        let ret = (allItems ?? []);

        // Filter based on search
        let lowerSearch = search.toLocaleLowerCase();
        if (lowerSearch) {
            ret = ret.filter(item =>
                (item.profile?.firstName ?? '').toLocaleLowerCase().indexOf(lowerSearch) >= 0
                || (item.profile?.lastName ?? '').toLocaleLowerCase().indexOf(lowerSearch) >= 0
                || (item.acceptanceState ?? '').toLocaleLowerCase().indexOf(lowerSearch) >= 0
                || (item.absenceType?.name ?? '').toLocaleLowerCase().indexOf(lowerSearch) >= 0
            );
        }

        // Filter based on staffAbsenceFilter
        if (staffAbsenceFilter === 'outstanding') {
            ret = ret?.filter(it => it.acceptanceState === StaffSubmissionAcceptanceState.Submitted);
        }
        if (staffAbsenceFilter === 'historical') {
            ret = ret?.filter(it => it.acceptanceState !== StaffSubmissionAcceptanceState.Submitted);
        }

        return ret;
    }, [allItems, search, staffAbsenceFilter]);

    // Update StaffAbsence
    const [updateStaffAbsence] = useSaveStaffAbsenceMutation();

    // Render the UI
    //
    return (
        <>
            <Banner fluid>
                <StickyToolbar>
                    <Row>
                        <Col>
                            <h1>
                                {t('staffAbsenceList.title', 'Staff absences')}
                            </h1>
                        </Col>
                        <ConditionalFragment showIf={isLoading}>
                            <Col xs="auto">
                                <LoadingIndicator size="sm" />
                            </Col>
                        </ConditionalFragment>
                    </Row>
                    <Row>
                        <Col>
                            <SearchInput value={search} onChange={e => setSearch(e.currentTarget.value)} />
                        </Col>
                        <Col xs="auto">
                            <ButtonGroup>
                                <Button color="secondary" outline={staffAbsenceFilter !== 'outstanding'} onClick={() => setStaffAbsenceFilter('outstanding')}>
                                    {t('common.outstanding', 'Outstanding')}
                                </Button>
                                <Button color="secondary" outline={staffAbsenceFilter !== 'historical'} onClick={() => setStaffAbsenceFilter('historical')}>
                                    {t('common.historical', 'Historical')}
                                </Button>
                            </ButtonGroup>
                        </Col>
                    </Row>
                </StickyToolbar>
            </Banner>

            <MainContainer fluid>
                <AlertOnErrors
                    errors={[
                        loadErrors,
                    ]}
                />

                {/* Outstanding StaffAbsence requests */}
                <ConditionalFragment showIf={staffAbsenceFilter === 'outstanding'}>
                    <Table responsive striped>
                        <thead>
                            <tr>
                                <th>{t('staffAbsenceList.name.heading', 'Name')}</th>
                                <th>{t('staffAbsenceList.absenceType.heading', 'Type')}</th>
                                <th>{t('staffAbsenceList.startDate.heading', 'Start date')}</th>
                                <th>{t('staffAbsenceList.endDate.heading', 'End date')}</th>
                                <th>{t('staffAbsenceList.submittedDate.heading', 'Submitted date')}</th>
                                <th>{t('staffAbsenceList.acceptanceState.heading', 'Status')}</th>
                                <th>{t('staffAbsenceList.actions.heading', 'Actions')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                items?.map((item) => (
                                    <StaffAbsenceItemOutstanding
                                        model={item}
                                        staffAbsenceManager={staffAbsenceManager}
                                        updateStaffAbsence={updateStaffAbsence}
                                        currentUserId={currentUserId}
                                        key={item.id}
                                    />
                                ))
                            }
                        </tbody>
                    </Table>
                </ConditionalFragment>

                {/* Historical StaffAbsence requests */}
                <ConditionalFragment showIf={staffAbsenceFilter === 'historical'}>
                    <Table responsive striped>
                        <thead>
                            <tr>
                                <th>{t('staffAbsenceList.name.heading', 'Name')}</th>
                                <th>{t('staffAbsenceList.absenceType.heading', 'Type')}</th>
                                <th>{t('staffAbsenceList.startDate.heading', 'Start date')}</th>
                                <th>{t('staffAbsenceList.endDate.heading', 'End date')}</th>
                                <th>{t('staffAbsenceList.submittedDate.heading', 'Submitted date')}</th>
                                <th>{t('staffAbsenceList.acceptanceState.heading', 'Status')}</th>
                                <th>{t('staffAbsenceList.confirmedDate.heading', 'Confirmed date')}</th>
                                <th>{t('staffAbsenceList.approvedBy.heading', 'Actioned by')}</th>
                                <th>{t('staffAbsenceList.undo.heading', 'Undo')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                items?.map((item) => (
                                    <StaffAbsenceItemHistorical
                                        model={item}
                                        staffAbsenceManager={staffAbsenceManager}
                                        updateStaffAbsence={updateStaffAbsence}
                                        currentUserId={currentUserId}
                                        key={item.id}
                                    />
                                ))
                            }
                        </tbody>
                    </Table>
                </ConditionalFragment>
            </MainContainer>
        </>
    )
}