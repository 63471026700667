import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ValidationErrors } from "pojo-validator";
import { ValidateCallback } from "pojo-validator-react";
import { ValidatedInput } from "pojo-validator-reactstrap";
import { useEffect, useState } from "react";
import { ConditionalFragment } from "react-conditionalfragment";
import { useTranslation } from "react-i18next";
import { Badge, Button, Col, FormGroup, Input, InputGroup, Label, ListGroupItem, Row } from "reactstrap";
import { ChildDiscount } from "../../../../api/main/models/ChildDiscount";
import { ValidatedISODateTimeInput } from "../../../shared/isoDateTimeInput/ValidatedISODateTimeInput";
import { TwoValueSwitch } from "../../../shared/twoValueSwitch/TwoValueSwitch";
import { HappyIcon } from "../../../shared/Utilities/HappyIcon";

export interface DiscountComponentProps {
    model: ChildDiscount | null | undefined,
    change: (changes: Partial<ChildDiscount>) => void;
    remove: () => void,

    validate: ValidateCallback,
    validationErrors: ValidationErrors,
}

/**
 * Component under EditChild that lets the user edit ChildDiscounts.
 */
export const DiscountComponent = (props: DiscountComponentProps) => {
    const { model, change, remove, validate, validationErrors, } = props;
    const { t } = useTranslation();
    const [discountType, setDiscountType] = useState('');

    // UseEffect - Checks whether the DiscountAmount is non-zero, if it is show amount, else show percentage
    useEffect(() => {
        model?.discountAmount > 0 ? setDiscountType('monetary') : setDiscountType('percentage');

    }, [model?.discountAmount]);

    // Render the UI
    //
    return (
        <>
            <ListGroupItem>
                <ConditionalFragment showIf={!!model?.isSingleUse}>
                    <Row>
                        <Col>
                        </Col>
                        <Col xs="auto">
                            <Badge color="primary" pill>
                                {t('common.singleUse', 'Single use')}
                            </Badge>
                        </Col>
                    </Row>
                </ConditionalFragment>

                <Row>
                    <Col>
                        <FormGroup>
                            <Label htmlFor="firstName">{t('editChild.childDiscountComponent.name.label', 'Name')}</Label>
                            <ValidatedInput name="firstName" type="text" value={model?.name ?? ''} onChange={e => change({ name: e.currentTarget.value })} onBlur={e => validate('name')} validationErrors={validationErrors['name']} />
                        </FormGroup>
                    </Col>
                    <Col xs="auto">
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <FormGroup>
                            <Label htmlFor="discountType">{t('editChild.childDiscountComponent.discountType.label', 'Type')}</Label>
                            <Input name="discountType" type="select" value={discountType} onChange={e => setDiscountType(e.currentTarget.value)}>
                                <option value="">
                                    {t('editChild.childDiscountComponent.discountType.pleaseSelect', '(Please select a discount type to apply)')}
                                </option>
                                <option value="monetary">{t('editChild.childDiscountComponent.discountType.monetary', 'Monetary')}</option>
                                <option value="percentage">{t('editChild.childDiscountComponent.discountType.percentage', 'Percentage')}</option>
                            </Input>
                        </FormGroup>
                    </Col>
                    <Col>
                        {/* Selected discount type */}
                        {/* FormGroup and Label placed outside of conditional due to the input element being the only dynamic content */}
                        {/* If the user user changes the value of either Monetary or Percentage Amounts, then the other will be set to zero */}
                        <FormGroup>
                            <Label htmlFor="discountAmount">
                                {t('editChild.childDiscountComponent.discountAmount.label', 'Discount amount')}
                            </Label>
                            {
                                discountType === 'monetary' ? (
                                    <InputGroup>
                                        <Button color="secondary" onClick={(e) => e.preventDefault()}><HappyIcon icon="euro" /></Button>
                                        <Input name="discountAmount" type="number" value={model?.discountAmount ?? 0} onChange={e => change({ discountAmount: e.currentTarget.valueAsNumber, discountPercentage: 0 })} />
                                    </InputGroup>
                                ) : discountType === 'percentage' ? (
                                    <InputGroup>
                                        <Input name="discountPercentage" type="number" value={model?.discountPercentage ?? 0} onChange={e => change({ discountPercentage: e.currentTarget.valueAsNumber, discountAmount: 0 })} max={100} />
                                        <Button color="secondary" onClick={(e) => e.preventDefault()}><HappyIcon icon="percent" /></Button>
                                    </InputGroup>
                                ) : (
                                    <Input name="friendlyMessage" value="Please select a discount type" disabled={model?.isSingleUse} onChange={e => setDiscountType(e.currentTarget.value)} />
                                )
                            }
                        </FormGroup>
                    </Col>
                    <Col>
                        <FormGroup>
                            <Label htmlFor="expiryDate">{t('editChild.childDiscountComponent.expiryDate.label', 'Expiry date')}</Label>
                            <ValidatedISODateTimeInput name="expiryDate" type="date" value={model?.expiryDate ?? ''} onChange={e => change({ expiryDate: e.currentTarget.value })} onBlur={e => validate('expiryDate')} validationErrors={validationErrors['expiryDate']} />
                        </FormGroup>
                    </Col>
                </Row>
                <FormGroup>
                    <TwoValueSwitch
                        leftLabel={t('editChild.childDiscountComponent.isSingleUse.leftLaebel', 'Multiple use')} rightLabel={t('editChild.childDiscountComponent.isSingleUse.rightLabel', 'Single use')}
                        checked={model?.isSingleUse ?? false} onChange={checked => change({ isSingleUse: checked, })}
                    />
                </FormGroup>
                <Row>
                    <Col xs='auto'>
                        <FormGroup>
                            <Label htmlFor="name">&nbsp;</Label>
                            <div>
                                <Button color="danger" outline onClick={() => remove()}>
                                    <FontAwesomeIcon icon="trash-alt" />
                                    <> </>
                                    {t('editChild.childDiscountComponent.delete', 'Delete child discount')}
                                </Button>
                            </div>
                        </FormGroup>
                    </Col>
                </Row>
            </ListGroupItem>
        </>
    );
};