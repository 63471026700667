import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ValidationErrors } from 'pojo-validator';
import { ValidateCallback } from 'pojo-validator-react';
import { ValidatedInput } from 'pojo-validator-reactstrap';
import { useTranslation } from 'react-i18next';
import { useAsyncCallback } from 'react-use-async-callback';
import { Button, ButtonGroup, Col, FormGroup, Input, Label, ListGroupItem, Row, Spinner } from 'reactstrap';
import { ButtonAsync } from 'reactstrap-buttonasync';
import { BlobUploadService } from '../../../../api/main/blobReferences/BlobUploadService';
import { useBlobReference } from '../../../../api/main/blobReferences/useBlobReference';
import { EmergencyContact } from '../../../../api/main/models/EmergencyContact';
import { AlertOnErrors } from '../../../../shared/alertOnErrors';
import { FileUploadButton } from '../../../shared/fileUploadButton/FileUploadButton';
import { UploadedImagePreview } from '../../../shared/uploadedImagePreview/UploadedImagePreview';

export interface EmergencyContactComponentProps {
    model: EmergencyContact | undefined,
    change: (changes: Partial<EmergencyContact>) => void;
    remove: () => void,

    validate: ValidateCallback,
    validationErrors: ValidationErrors,
}

/**
 * Component under Staff that lets the user edit an EmergencyContact
 */
export const EmergencyContactComponent = (props: EmergencyContactComponentProps) => {
    const { model, change, remove, validate, validationErrors } = props;
    const { t } = useTranslation();

    // Contents file uploading
    const { data: { model: contentsFile }, errors: contentsFileLoadErrors } = useBlobReference(model?.photoBlobReferenceId);
    const [onUploadContentsFile, { errors: contentsFileUploadErrors, isExecuting: isUploadingContentsFile, }] = useAsyncCallback(async (files: FileList | null) => {
        if (!files) {
            return;
        }

        let uploadService: BlobUploadService = new BlobUploadService("/api/blobs");
        let result = await uploadService.upload(files);

        if (!!result) {
            change({ photoBlobReferenceId: result.id });
        }
    }, [change]);
    const [clearContentsFile, { isExecuting: isClearingContentsFile, }] = useAsyncCallback(async () => {
        change({ photoBlobReferenceId: null });
    }, [change]);

    // Render the UI
    //
    return (
        <>
            <AlertOnErrors errors={[
                contentsFileLoadErrors,
                contentsFileUploadErrors
            ]} />

            <ListGroupItem>
                <Row>
                    <Col>
                        <FormGroup>
                            <Label htmlFor="firstName">{t('editStaff.emergencyContact.firstName.label', 'First name')}</Label>
                            <ValidatedInput name="firstName" type="text" value={model?.firstName ?? ''} onChange={e => change({ firstName: e.currentTarget.value })} onBlur={e => validate('firstName')} validationErrors={validationErrors['firstName']} />
                        </FormGroup>
                    </Col>
                    <Col>
                        <FormGroup>
                            <Label htmlFor="lastName">{t('editStaff.emergencyContact.lastName.label', 'Last name')}</Label>
                            <ValidatedInput name="lastName" type="text" value={model?.lastName ?? ''} onChange={e => change({ lastName: e.currentTarget.value })} onBlur={e => validate('lastName')} validationErrors={validationErrors['lastName']} />
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <FormGroup>
                            <Label htmlFor="primaryPhone">{t('editStaff.emergencyContact.primaryPhone.label', 'Primary phone number')}</Label>
                            <ValidatedInput name="primaryPhone" type="text" value={model?.primaryPhone ?? ''} onChange={e => change({ primaryPhone: e.currentTarget.value })} onBlur={e => validate('primaryPhone')} validationErrors={validationErrors['primaryPhone']} />
                        </FormGroup>
                    </Col>
                    <Col>
                        <FormGroup>
                            <Label htmlFor="primaryEmail">{t('editStaff.emergencyContact.primaryEmail.label', 'Primary email')}</Label>
                            <ValidatedInput name="primaryEmail" type="text" value={model?.primaryEmail ?? ''} onChange={e => change({ primaryEmail: e.currentTarget.value })} onBlur={e => validate('primaryEmail')} validationErrors={validationErrors['primaryEmail']} />
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <FormGroup>
                            <Label htmlFor="secondaryPhone">{t('editStaff.emergencyContact.secondaryPhone.label', 'Secondary phone number')}</Label>
                            <Input name="secondaryPhone" type="text" value={model?.secondaryPhone ?? ''} onChange={e => change({ secondaryPhone: e.currentTarget.value })} />
                        </FormGroup>
                    </Col>
                    <Col>
                        <FormGroup>
                            <Label htmlFor="secondaryEmail">{t('editStaff.emergencyContact.secondaryEmail.label', 'Secondary email')}</Label>
                            <Input name="secondaryEmail" type="text" value={model?.secondaryEmail ?? ''} onChange={e => change({ secondaryEmail: e.currentTarget.value })} />
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <FormGroup>
                            <Label htmlFor="fileUpload">{t('editStaff.emergencyContact.fileUpload.label', 'Upload file')}</Label>
                            <Row>
                                <Col>
                                    <UploadedImagePreview size="lg" src={contentsFile?.url ?? ''} />

                                    <Row>
                                        <Col>
                                            <ButtonGroup>
                                                <FileUploadButton
                                                    color={`primary`}
                                                    isExecuting={isUploadingContentsFile}
                                                    executingChildren={<><Spinner size="sm" /> {t('common.uploading', 'Uploading...')}</>}
                                                    onUpload={onUploadContentsFile}
                                                    outline={false}>
                                                    {t('editStaff.emergencyContact.uploadButtonText', 'Upload a file')}
                                                </FileUploadButton>
                                                <ButtonAsync color="primary"
                                                    outline
                                                    isExecuting={isClearingContentsFile}
                                                    type="button"
                                                    onClick={clearContentsFile}
                                                    executingChildren={<><Spinner size="sm" /> {t('editStaff.clearingFile', 'Clearing file...')}</>}>
                                                    {t('editStaff.emergencyContact.clearUploadButton', 'Clear file')}
                                                </ButtonAsync>
                                            </ButtonGroup>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col xs='auto'>
                        <FormGroup>
                            <Label htmlFor="name">&nbsp;</Label>
                            <div>
                                <Button color="danger" outline onClick={() => remove()}>
                                    <FontAwesomeIcon icon="trash-alt" />
                                    <> </>
                                    {t('editStaff.emergencyContact.delete', 'Delete emergency contact')}
                                </Button>
                            </div>
                        </FormGroup>
                    </Col>
                </Row>
            </ListGroupItem>
        </>
    );
};