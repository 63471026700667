import { useMutation } from "@apollo/react-hooks";
import { useAsyncCallback } from 'react-use-async-callback';
import gql from "graphql-tag";
import { AsyncActionStatus } from '../../../../shared/abstractStore';
import { memberFieldsFragment } from "../../models/Member";
import { ChildCreateInput, MemberCreateInput, CreateMemberWithChildForAnonymousUserMutation, CreateMemberWithChildForAnonymousUserMutationVariables, ScheduledClassChildCreateInput } from "../../generated/graphql";

/**
 * Get a callback to process a Registration for a new Member when adding thier prospective Child to a class as an anonymous user
 */
export function useCreateMemberWithChildForAnonymousUserMutation(): [(memberModel: MemberCreateInput, childModel: ChildCreateInput, scheduledClassChildModel: ScheduledClassChildCreateInput) => Promise<void>, AsyncActionStatus] {
    const [mutationAction, { error }] = useMutation<CreateMemberWithChildForAnonymousUserMutation, CreateMemberWithChildForAnonymousUserMutationVariables>(
        gql`
            mutation createMemberWithChildForAnonymousUserMutation ($memberModel: MemberCreateInput!, $childModel: ChildCreateInput!, $scheduledClassChildModel: ScheduledClassChildCreateInput!) {
                createMemberWithChildForAnonymousUser(memberModel: $memberModel, childModel: $childModel, scheduledClassChildModel: $scheduledClassChildModel) {
                    ...memberFields
                }
            }

            ${memberFieldsFragment}
        `
    );

    // Wrap the mutation in a standard callback.
    const [action, { isExecuting, errors: actionErrors }] = useAsyncCallback(async (memberModel: MemberCreateInput, childModel: ChildCreateInput, scheduledClassChildModel: ScheduledClassChildCreateInput) => {
        const result = await mutationAction({ variables: { memberModel: memberModel, childModel: childModel, scheduledClassChildModel: scheduledClassChildModel } });
        if (result.errors && result.errors.length) {
            throw result.errors;
        }
    }, [mutationAction]);

    return [action, { isExecuting: isExecuting, errors: error ? error.message : actionErrors }];
}