import * as React from 'react';
import { useSaveInStore, SaveInStoreOptions } from '../../../shared/useSaveInStore';
import { useUpdateScheduledClassMutation } from './useUpdateScheduledClassMutation';
import { ScheduledClass } from '../models/ScheduledClass';
import { useCreateScheduledClassMutation } from './useCreateScheduledClassMutation';
import { ScheduledClassCreateInput, ScheduledClassUpdateInput } from '../generated/graphql';

/**
 * Get a callback to save a ScheduledClass in the api using either a create or update.
 */
export function useSaveScheduledClassMutation(options: SaveInStoreOptions<ScheduledClass, string> = {}) {
    const [_create, createStatus] = useCreateScheduledClassMutation();
    const create = React.useCallback((model: Partial<ScheduledClass>) => _create(model as ScheduledClassCreateInput), [_create]);

    const [_update, updateStatus] = useUpdateScheduledClassMutation();
    const update = React.useCallback((id: string, changes: Partial<ScheduledClass>) => _update(id, changes as ScheduledClassUpdateInput), [_update]);

    return useSaveInStore(
        [create, createStatus],
        [update, updateStatus],
        options
    );
}
