import { useMemo, useState } from "react";
import { ConditionalFragment } from "react-conditionalfragment";
import { useTranslation } from "react-i18next";
import { Col, Input, ListGroup, Row } from "reactstrap";
import { useCurrentUserProfile } from "../../api/main/profiles/useCurrentUserProfile";
import { useStaffQueryMessagesForAllMembersViewModel } from "../../api/main/queryMessages/viewModels/useStaffQueryMessageForAllMembersViewModel";
import { AlertOnErrors } from "../../shared/alertOnErrors";
import { useReplaceSearchParamsEffect, useSearchParams } from "../../shared/useURLSearchParams";
import { MembersWithMessagesType } from "../adminQueryMessages/AdminQueryMessagesOverview";
import { QueryMessageThreadList } from "../queryMessages/QueryMessageThreadList";
import { Banner } from "../shared/banner/Banner";
import { LoadingIndicator } from "../shared/loadingIndicator/LoadingIndicator";
import { MainContainer } from "../shared/mainContainer/MainContainer";
import { NoResultsFound } from "../shared/noResultsFound/NoResultsFound";
import { SearchInput } from "../shared/searchInput/SearchInput";
import { StickyToolbar } from "../shared/stickyToolbar/StickyToolbar";

export const StaffQueryMessagesOverview = () => {
    const { t } = useTranslation();

    // Load the currentUserProfile
    const {
        data: {
            model: userProfile
        }, isLoading: _isLoading, errors: loadErrors,
    } = useCurrentUserProfile();

    // Filtering for All, Open, or Closed
    const [messageFilter, setMessageFilter] = useState<'all' | 'closed' | 'open'>('open');

    // Filtering for timespan of messages to get
    const [_timespanFilter, setTimespanFilter] = useState<number>(1);
    const timespanFilter = useMemo(() => messageFilter === 'open' ? 0 : _timespanFilter, [messageFilter, _timespanFilter]);

    // Load the data
    const {
        data: {
            items,
            queryMessageTypes,
            profiles
        }, isLoading: isLoadingData, errors: dataLoadErrors
    } = useStaffQueryMessagesForAllMembersViewModel(userProfile?.id, { numberOfMonths: timespanFilter });
    const isLoading = _isLoading || isLoadingData;

    // Filtering for subjects
    const [subjectFilter, setSubjectFilter] = useState<string | undefined>();

    // Search.
    const { memberSearch: memberSearchParam, messageSearch: messageSearchParam } = useSearchParams();
    const [memberSearch, setMemberSearch] = useState<string>(memberSearchParam ?? '');
    const [messageSearch, setMessageSearch] = useState<string>(messageSearchParam ?? '');

    // Keep the URL up to date with the search text.
    useReplaceSearchParamsEffect({ memberSearch, messageSearch });

    // Generate an Array of threads;
    const allThreadsWithMember = useMemo(() => {
        if (!items) return;

        let result: Array<MembersWithMessagesType> = [];

        // Here i was going to filter the messages with !it.threadKey, then add all the related messages for each thread. Each thread should contain the member details 
        items?.filter(it => !it.threadKey).forEach(item => {
            const memberId = item.memberId;
            const memberName = `${item.member.user.profile?.firstName} ${item.member.user.profile?.lastName}`;
            const photoBlobReferenceId = item.member.photoBlobReferenceId;
            const allMessagesForThread = [item, ...items?.filter(it => it.threadKey === item.id)];

            result.push({
                memberId,
                memberName,
                photoBlobReferenceId,
                messages: [allMessagesForThread.flat()]
            });
        });

        // Sort threads so the one with the most recent reply from staff is top
        result = result.sort((a, b) => {
            const aLastMessage = a.messages[0].slice(-1)[0];
            const bLastMessage = b.messages[0].slice(-1)[0];

            if (!aLastMessage || !bLastMessage) return 0;

            return new Date(bLastMessage.submittedDate).getTime() - new Date(aLastMessage.submittedDate).getTime();
        });

        // Sort the threads by the resolved status so that those with resolved false are at the top
        result = result.sort((a, b) => {
            const aLastMessage = a.messages[0].slice(-1)[0];
            const bLastMessage = b.messages[0].slice(-1)[0];

            if (!aLastMessage || !bLastMessage) return 0;

            return Number(aLastMessage.resolved) - Number(bLastMessage.resolved);
        });


        return result;
    }, [items]);

    // Filter based on the state dropdown.
    const threadsWithMembers = useMemo(() => {
        let ret = allThreadsWithMember;

        // Filter by message filter.
        switch (messageFilter) {
            // Return items that have one or more open messages.
            case 'open':
                ret = ret?.map(item => {
                    // Get the threads with at least one unresolved message.  These are considered open.
                    const openThreads = item.messages.filter(thread => !!thread.find(message => !message.resolved));
                    return {
                        ...item,
                        messages: openThreads,
                    } as MembersWithMessagesType;
                }).filter(item => item.messages.length > 0);
                break;

            // Return items that do not have any open messags.
            case 'closed':
                ret = ret?.map(item => {
                    // Get the threads with zero unresolved message.  These are considered closed.
                    const closedThreads = item.messages.filter(thread => !thread.find(message => !message.resolved));
                    return {
                        ...item,
                        messages: closedThreads,
                    } as MembersWithMessagesType;
                }).filter(item => item.messages.length > 0);
                break;

            // Return everything, no filtering needed.
            case 'all':
            default:
                break; // Do nothing.
        }

        // Filter by the subject.
        if (!!subjectFilter && subjectFilter !== 'all') {
            ret = ret?.map(item => {
                // Get the threads with at least one message matching the subject (we know they will all match in practice but this is how we filter to be similar to the state filter above).
                const matchingSubjectsThreads = item.messages.filter(thread => !!thread.find(message => message.queryMessageTypeId === subjectFilter));
                return {
                    ...item,
                    messages: matchingSubjectsThreads,
                } as MembersWithMessagesType;
            }).filter(item => item.messages.length > 0);
        }

        // Filtering by the search text -> Message Content
        let lowerMessageSearch = messageSearch.toLocaleLowerCase();
        if (lowerMessageSearch) {
            ret = ret?.map(item => {
                // Get the threads with at least one message matching the search (we know they will all match in practice but this is how we filter to be similar to the state filter above).
                const matchingSearchThreads = item.messages.filter(thread => thread.some(message => {
                    return message.contentsHtml.toLocaleLowerCase().indexOf(lowerMessageSearch) >= 0;
                }));

                return {
                    ...item,
                    messages: matchingSearchThreads,
                } as MembersWithMessagesType;
            }).filter(item => item.messages.length > 0);
        }

        // Filtering by the search text -> Member Name
        let lowerMemberSearch = memberSearch.toLocaleLowerCase();
        if (lowerMemberSearch) {
            ret = ret?.filter(item =>
                item.memberName.toLocaleLowerCase().indexOf(lowerMemberSearch) >= 0
            );
        }

        // Return the filtered results.
        return ret;
    }, [allThreadsWithMember, messageFilter, subjectFilter, messageSearch, memberSearch]);

    // Render the UI
    //
    return (
        <div className="staff-query-messages-for-member">
            <Banner>
                <StickyToolbar>
                    <Row>
                        <Col>
                            <h1>
                                {t('staffQueryMessagesOverview.title', 'All Messages')}
                            </h1>
                        </Col>
                        <ConditionalFragment showIf={isLoading}>
                            <Col xs="auto">
                                <LoadingIndicator size="sm" />
                            </Col>
                        </ConditionalFragment>
                    </Row>
                    <Row>
                        <Col>
                            <SearchInput value={memberSearch} onChange={e => setMemberSearch(e.currentTarget.value)} placeholder={t('common.memberSearch', 'Search member details')} />
                        </Col>
                        <Col>
                            <SearchInput value={messageSearch} onChange={e => setMessageSearch(e.currentTarget.value)} placeholder={t('common.messageSearch', 'Search message content')} />
                        </Col>
                        <Col xs="auto">
                            <Input type="select" value={subjectFilter} onChange={e => setSubjectFilter(e.currentTarget.value)}>
                                <>
                                    <option value="">{t('staffQueryMessagesOverview.subjectFilter.all', 'All subjects')}</option>
                                    {queryMessageTypes?.map(queryMessageType => (
                                        <option value={queryMessageType.id} key={queryMessageType.id}>{queryMessageType.name}</option>
                                    ))}
                                </>
                            </Input>
                        </Col>
                        <Col xs="auto">
                            <Input type="select" value={messageFilter} onChange={e => setMessageFilter(e.currentTarget.value as any)}>
                                <option value="open">{t('staffQueryMessagesOverview.messageFilter.open', 'Open')}</option>
                                <option value="closed">{t('staffQueryMessagesOverview.messageFilter.closed', 'Closed')}</option>
                                <option value="all">{t('staffQueryMessagesOverview.messageFilter.all', 'All messages')}</option>
                            </Input>
                        </Col>
                        <ConditionalFragment showIf={messageFilter !== 'open'}>
                            <Col xs="auto">
                                <Input type="select" value={timespanFilter} onChange={e => setTimespanFilter(parseInt(e.currentTarget.value))}>
                                    <option value="0">{t('staffQueryMessagesOverview.timespanFilter.all', 'All time')}</option>
                                    <option value="1">{t('staffQueryMessagesOverview.timespanFilter.oneMonth', 'One month')}</option>
                                    <option value="3">{t('staffQueryMessagesOverview.timespanFilter.twoMonths', 'Three months')}</option>
                                    <option value="6">{t('staffQueryMessagesOverview.timespanFilter.sixMonths', 'Six months')}</option>
                                    <option value="12">{t('staffQueryMessagesOverview.timespanFilter.twelveMonths', 'Twelve months')}</option>
                                </Input>
                            </Col>
                        </ConditionalFragment>
                    </Row>
                </StickyToolbar>
            </Banner>

            <MainContainer>
                <AlertOnErrors
                    errors={[
                        loadErrors,
                        dataLoadErrors
                    ]}
                />

                <ListGroup>
                    {threadsWithMembers?.map(member => (
                        <QueryMessageThreadList isStaffView={true} staffId={userProfile?.id} member={member} profiles={profiles} queryMessageTypes={queryMessageTypes} />
                    ))}
                </ListGroup>

                <ConditionalFragment showIf={!isLoading && !threadsWithMembers?.length}>
                    <NoResultsFound />
                </ConditionalFragment>
            </MainContainer>
        </div>
    );
};