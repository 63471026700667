import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import { useValidatorCallback } from "pojo-validator-react";
import { ValidatedInput } from "pojo-validator-reactstrap";
import { ConditionalFragment } from "react-conditionalfragment";
import { useTranslation } from "react-i18next";
import { LinkContainer } from "react-router-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { useAsyncCallback } from "react-use-async-callback";
import { Button, Col, Form, FormGroup, InputGroup, Label, Row, Spinner } from "reactstrap";
import { ButtonAsync } from "reactstrap-buttonasync";
import { useGiftVoucher } from "../../../api/main/giftVouchers/useGiftVoucher";
import { useSaveGiftVoucherMutation } from "../../../api/main/giftVouchers/useSaveGiftVoucherMutation";
import { useEditGiftVoucherSupportingDataViewModel } from "../../../api/main/giftVouchers/viewModels/useEditGiftVoucherSupportingDataViewModel";
import { GiftVoucher, giftVoucherDefaultValues } from "../../../api/main/models/GiftVoucher";
import { AlertOnErrors } from "../../../shared/alertOnErrors";
import { useChanges } from "../../../shared/useChanges";
import { Banner } from "../../shared/banner/Banner";
import { FormButtons } from "../../shared/formButtons/FormButtons";
import { ValidatedISODateTimeInput } from "../../shared/isoDateTimeInput/ValidatedISODateTimeInput";
import { LoadingIndicator } from "../../shared/loadingIndicator/LoadingIndicator";
import { MainContainer } from "../../shared/mainContainer/MainContainer";
import { StickyToolbar } from "../../shared/stickyToolbar/StickyToolbar";
import { TwoValueSwitch } from "../../shared/twoValueSwitch/TwoValueSwitch";
import { HappyIcon } from "../../shared/Utilities/HappyIcon";

export interface EditGiftVoucherProps {
    isCreate?: boolean,
    onCreateDefaultValues?: () => Partial<GiftVoucher>;
}

/**
 * Component to create a GiftVoucher - Create in this context is referring to adding a gift voucher manually from the E-Commerce Store
 */
export const CreateGiftVoucher = (props: EditGiftVoucherProps) => (<EditGiftVoucher isCreate={true} {...props} />);

/**
 * Component to edit a GiftVoucher
 */
export const EditGiftVoucher = (props: EditGiftVoucherProps) => {
    const { isCreate, onCreateDefaultValues } = props;
    const { id } = useParams<{ id: string | undefined; }>();
    const navigate = useNavigate();
    const { t } = useTranslation();

    // Load the data
    const {
        data: {
            model: storeModel
        }, isLoading: _isLoading, errors: loadErrors
    } = useGiftVoucher(id);

    // Load the supporting data
    const {
        data: {
            giftVouchers
        }, isLoading: isLoadingSupportingData, errors: supportingDataLoadErrors
    } = useEditGiftVoucherSupportingDataViewModel();
    const isLoading = _isLoading || isLoadingSupportingData;

    // Model (GiftVoucher)
    const { model, change, changes } = useChanges(storeModel, isCreate ? { ...giftVoucherDefaultValues(), ...(onCreateDefaultValues ? onCreateDefaultValues() : {}) } : undefined);
    const [saveGiftVoucher, { errors: saveErrors }] = useSaveGiftVoucherMutation();

    // Main model validation
    const [validate, validationErrors] = useValidatorCallback((validation, fieldsToCheck) => {
        const rules = {
            value: () => !model?.value ? t('editGiftVoucher.errors.valueRequired', 'A value is required') : '',
            dateAdded: () => !model?.dateAdded ? t('editGiftVoucher.errors.dateAddedRequired', 'Date added is required') : '',
            expiryDate: () => !model?.expiryDate ? t('editGiftVoucher.errors.expiryDateRequired', 'Expiry date is required') : '',
            serialNumber: () => giftVouchers?.some(item => item.serialNumber === model?.serialNumber) ? 'Serial number is a dupliate' : ''
        };
        validation.checkRules(rules, fieldsToCheck);
    }, [model]);

    // Save the GiftVoucher
    const [saveForm, { isExecuting: isSaving, errors: saveFormErrors }] = useAsyncCallback(async () => {
        if (!model) {
            return;
        }

        if (!validate) {
            return;
        }

        // If the voucher isn't consumed, we want to set the consumedOnDate back to null
        if (changes.isConsumed === false) {
            changes.consumedOnDate = null;
        }

        // Save the GiftVoucher
        await saveGiftVoucher(model.id, { ...changes }, isCreate ?? false);

        // Navigate to the previous screen
        navigate(-1);
    }, [model, validate, changes, isCreate]);

    // Render the UI
    //
    return (
        <>
            <Banner>
                <StickyToolbar>
                    <Row>
                        <Col xs={12} md="auto">
                            <h1>
                                {
                                    isCreate ? t('editGiftVoucher.createHeading.default', 'Add gift voucher')
                                        : t('editGiftVoucher.editHeading.default', 'Edit gift voucher')
                                }
                            </h1>
                        </Col>
                        <ConditionalFragment showIf={isLoading}>
                            <Col xs="auto">
                                <LoadingIndicator size="sm" />
                            </Col>
                        </ConditionalFragment>
                    </Row>
                </StickyToolbar>
            </Banner>

            <MainContainer>
                <AlertOnErrors
                    errors={[
                        loadErrors,
                        supportingDataLoadErrors,
                        saveErrors,
                        saveFormErrors
                    ]}
                />

                <Form onSubmit={e => { e.preventDefault(); }}>
                    <h4>{t('editGiftVoucher.giftVoucherDetails.subheading', 'Gift voucher details')}</h4>

                    <Row>
                        <Col>
                            <FormGroup>
                                <Label htmlFor="serialNumber">{t('common.serialNumber', 'Serial number')}</Label>
                                <ValidatedInput name="serialNumber" type="text" value={model?.serialNumber ?? ''} onChange={e => change({ serialNumber: e.currentTarget.value })} onBlur={e => validate("serialNumber")} validationErrors={validationErrors["serialNumber"]} />
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup>
                                <Label htmlFor="value">{t('editGiftVoucher.value.label', 'Value')}</Label>
                                <InputGroup>
                                    <Button color="secondary" onClick={e => e.preventDefault()}><HappyIcon icon="euro" /></Button>
                                    <ValidatedInput name="value" type="number" value={model?.value ?? 0.00} onChange={e => change({ value: e.currentTarget.valueAsNumber })} onBlur={e => validate("value")} validationErrors={validationErrors["value"]} />
                                </InputGroup>
                            </FormGroup>
                        </Col>

                        <Col xs="auto">
                            <FormGroup>
                                <Label htmlFor="isMultiUse">{t('editGiftVoucher.isMultiUse.label', 'Multi-use?')}</Label>
                                <TwoValueSwitch checked={model?.isMultiUse ?? false} leftLabel={t('common.no', 'No')} rightLabel={t('common.yes', 'Yes')} onChange={e => change({ isMultiUse: e })}></TwoValueSwitch>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <FormGroup>
                                <Label htmlFor="dateAdded">{t('common.dateAdded', 'Date added')}</Label>
                                <ValidatedISODateTimeInput name="dateAdded" type="date" value={model?.dateAdded ?? moment()} onChange={e => change({ dateAdded: e.currentTarget.value })} onBlur={e => validate("dateAdded")} validationErrors={validationErrors["dateAdded"]} />
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup>
                                <Label htmlFor="expiryDate">{t('common.expiryDate', 'Expiry date')}</Label>
                                <ValidatedISODateTimeInput name="expiryDate" type="date" value={model?.expiryDate ?? moment()} onChange={e => change({ expiryDate: e.currentTarget.value })} onBlur={e => validate("expiryDate")} validationErrors={validationErrors["expiryDate"]} />
                            </FormGroup>
                        </Col>
                    </Row>

                    <h4>{t('editGiftVoucher.giftVoucherUsage.subheading', 'Consumption details')}</h4>

                    <Row>
                        <Col xs="auto">
                            <FormGroup>
                                <Label htmlFor="isConsumed">{t('editGiftVoucher.isConsumed.label', 'Consumed?')}</Label>
                                <TwoValueSwitch checked={model?.isConsumed ?? false} leftLabel={t('common.no', 'No')} rightLabel={t('common.yes', 'Yes')} onChange={e => change({ isConsumed: e })}></TwoValueSwitch>
                            </FormGroup>
                        </Col>
                        <ConditionalFragment showIf={!!model?.isConsumed}>
                            <Col>
                                <FormGroup>
                                    <Label htmlFor="consumedOnDate">{t('editGiftVoucher.consumedOnDate.label', 'Consumed date')}</Label>
                                    <Row>
                                        <Col>
                                            <ValidatedISODateTimeInput name="consumedOnDate" type="date" value={model?.consumedOnDate ?? moment()} onChange={e => change({ consumedOnDate: e.currentTarget.value })} onBlur={e => validate("consumedOnDate")} validationErrors={validationErrors["consumedOnDate"]} />
                                        </Col>
                                        <ConditionalFragment showIf={!!model?.consumedOnPaymentId}>
                                            <Col xs="auto">
                                                <LinkContainer to={`/administration/children-management/payments/view/${model?.consumedOnPaymentId}`}>
                                                    <Button type="button" color="secondary" outline>
                                                        {t('editGiftVoucher.viewPaymentButton.text', 'View payment')}
                                                    </Button>
                                                </LinkContainer>
                                            </Col>
                                        </ConditionalFragment>
                                    </Row>
                                </FormGroup>

                            </Col>
                        </ConditionalFragment>
                    </Row>

                    <FormButtons>
                        <ConditionalFragment showIf={!isLoading}>
                            <ButtonAsync color="primary" isExecuting={isSaving} onClick={() => saveForm()}
                                executingChildren={<><Spinner size="sm" /> {t('common.saving', 'Saving...')}</>}>
                                <FontAwesomeIcon icon="save" />
                                <> </>
                                {t('common.save', 'Save')}
                            </ButtonAsync>
                        </ConditionalFragment>

                        <Button type="button" color="primary" outline onClick={e => navigate(-1)}>
                            {t('common.cancel', 'Cancel')}
                        </Button>
                    </FormButtons>

                </Form>
            </MainContainer>
        </>
    );
};

