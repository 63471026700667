import gql from 'graphql-tag';
import { Guid } from 'guid-string';
import moment from 'moment';
import { StaffQualificationCheckFieldsFragment } from '../generated/graphql';
import { StaffSubmissionAcceptanceState } from './constants/StaffSubmissonAcceptanceState';

export const staffQualificationCheckFieldsFragment = gql`
    fragment staffQualificationCheckFields on StaffQualificationCheck {
        id
        name
        staffId
        reviewDate
        archived
        evidenceBlobReferenceId
        acceptanceState
        submittedDate
        confirmedDate
    }
`;

export type StaffQualificationCheck = Omit<StaffQualificationCheckFieldsFragment, '__typename'>;

/**
 * Default values.
 */
export const staffQualificationCheckDefaultValues = (): Partial<StaffQualificationCheck> => ({
    id: Guid.newGuid(),
    name: '',
    staffId: undefined,
    reviewDate: null,
    archived: false,
    evidenceBlobReferenceId: null,
    acceptanceState: StaffSubmissionAcceptanceState.Submitted,
    submittedDate: moment().toISOString(),
    confirmedDate: null,
});