import { useMutation } from "@apollo/react-hooks";
import { useAsyncCallback } from 'react-use-async-callback';
import gql from "graphql-tag";
import { AsyncActionStatus } from '../../../shared/abstractStore';
import { CreateWelcomePackDocumentMutation, CreateWelcomePackDocumentMutationVariables, WelcomePackDocumentCreateInput } from '../generated/graphql';
import { welcomePackDocumentFieldsFragment } from '../models/WelcomePackDocument';

/**
 * Get a callback to create a WelcomePackDocument in the api.
 */
export function useCreateWelcomePackDocumentMutation(): [(model: WelcomePackDocumentCreateInput) => Promise<void>, AsyncActionStatus] {
    const [mutationAction, { error }] = useMutation<CreateWelcomePackDocumentMutation, CreateWelcomePackDocumentMutationVariables>(
        gql`
            mutation createWelcomePackDocumentMutation ($model: WelcomePackDocumentCreateInput!) {
                createWelcomePackDocument(model: $model) {
                    ...welcomePackDocumentFields
                }
            }

            ${welcomePackDocumentFieldsFragment}
        `
    );

    // Wrap the mutation in a standard callback.
    const [action, { isExecuting, errors: actionErrors }] = useAsyncCallback(async (model: WelcomePackDocumentCreateInput) => {
        const result = await mutationAction({ variables: { model: model } });
        if (result.errors && result.errors.length) {
            throw result.errors;
        }
    }, [mutationAction]);

    return [action, { isExecuting: isExecuting, errors: error ? error.message : actionErrors }];
}
