import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import Chart from "react-apexcharts";
import { ConditionalFragment } from "react-conditionalfragment";

export type ReenrolmentViewModelData = {
    newStarters: number,
    renewed: number,
    changedClass: number,
    leaving: number;
};

export interface ReenrolmentsDonutChartProps {
    chartData: ReenrolmentViewModelData | null | undefined;
};

export const ReenrolmentsDonutChart = (props: ReenrolmentsDonutChartProps) => {
    const { chartData } = props;
    const { t } = useTranslation();

    // Data formatted for the chart
    const formattedData = useMemo(() => {
        if (!chartData) {
            return [];
        }

        return [
            { label: t('reenrolmentsDonutChart.dataLabels.newStarters', 'New starters'), value: chartData.newStarters },
            { label: t('reenrolmentsDonutChart.dataLabels.renewed', 'Renewals'), value: chartData.renewed },
            { label: t('reenrolmentsDonutChart.dataLabels.changedClass', 'Class moves'), value: chartData.changedClass },
            { label: t('reenrolmentsDonutChart.dataLabels.leaving', 'Leavers'), value: chartData.leaving },
        ];
    }, [chartData, t]);

    // Chart options
    const options = useMemo(() => ({
        chart: {
            toolbar: {
                show: false,
            },
        },

        title: {
            text: t('reenrolmentsDonutChart.title', 'Enrolments'),
        },
        labels: formattedData.map(item => item.label),
        colors: ['#106b3c', '#198754', '#fd7e14', '#a82232', '#dc3545'],
        plotOptions: {
            pie: {
                donut: {
                    labels: {
                        show: true,
                        total: {
                            show: true,
                        }
                    }
                }
            }
        },
    }), [formattedData, t]);

    // Chart data
    const series = useMemo(() => {
        return  formattedData.map(item => item.value);
    }, [formattedData]);

    // Tracking the total, if the total re-enrolments is zero, then we don't really want to show an empty chart
    const total = useMemo(() => series?.reduce((accumulator, currentValue) => accumulator + currentValue, 0), [series]);

    // Render the UI
    //
    return (
        <>
            <ConditionalFragment showIf={total > 0}>
                <Chart options={options} series={series} type="donut" height={500} />
            </ConditionalFragment>
        </>
    );
};