import { gql, useLazyQuery } from "@apollo/client";
import { Guid } from "guid-string";
import React from "react";
import { mainApiConfig } from "../../../../configure/mainApiConfig";
import { AsyncLoadOptions, AsyncLoadResult } from "../../../../shared/abstractStore";
import {
    ConsumeStaffScheduledClassesSupportingDataViewModelQuery,
    ConsumeStaffScheduledClassesSupportingDataViewModelQueryVariables
} from "../../generated/graphql";
import { courtesyClassCreditFieldsFragment } from "../../models/CourtesyClassCredit";
import { staffNoteFieldsFragment } from "../../models/StaffNote";


export function useConsumeStaffScheduledClassesSupportingDataViewModel(targetIds: Array<string> | null | undefined, scheduledClassId: string | null | undefined, classLocationId?: string | null | undefined, uniqueMemberIds?: Array<string> | undefined | null, options: AsyncLoadOptions = {}):
    AsyncLoadResult<ConsumeStaffScheduledClassesSupportingDataViewModelQuery> {
    // Query the date we need from the api
    const [load, { data, loading, error, refetch }] = useLazyQuery<
        ConsumeStaffScheduledClassesSupportingDataViewModelQuery,
        ConsumeStaffScheduledClassesSupportingDataViewModelQueryVariables>(
            gql`
        query consumeStaffScheduledClassesSupportingDataViewModelQuery ($targetIds: [ID!], $scheduledClassId: ID!, $classLocationId: ID!, $uniqueMemberIds: [ID!]) {
            staffNotes (targetIds: $targetIds) {
                ...staffNoteFields
            }

            profiles {
                id
                userId
                firstName
                lastName
                archived
                primaryEmail
                secondaryEmail
                primaryPhone
                secondaryPhone
                addressLine1
                addressLine2
                city
                county
                postcode
                country
                genderId
                isStaff
                isMember
                
                user {
                  id
                  email
                }
            }

            courtesyClassCredits (scheduledClassId: $scheduledClassId) {
                ...courtesyClassCreditFields
            }

            locationNotes: staffNotes (targetId: $classLocationId) {
                ...staffNoteFields
            }

            memberNotes: staffNotes (targetIds: $uniqueMemberIds) {
                ...staffNoteFields
            }

            scheduledClassNotes: staffNotes (targetId: $scheduledClassId) {
                ...staffNoteFields
            }
        }
        ${staffNoteFieldsFragment}
        ${courtesyClassCreditFieldsFragment}
        `,
            {

                variables: {
                    targetIds: targetIds ?? [],
                    scheduledClassId: scheduledClassId ?? Guid.empty,
                    classLocationId: classLocationId ?? Guid.empty,
                    uniqueMemberIds: uniqueMemberIds ?? [],
                },
                fetchPolicy: options.fetchPolicy ?? mainApiConfig.defaultFetchPolicy,
                pollInterval: options.pollInterval,
                onCompleted: (data) => {
                    if (options.onCompleted) {
                        options.onCompleted(data);
                    }
                }
            }
        );

    // Run the query (unless we are being lazy).
    React.useEffect(() => {
        if (!options.lazy) {
            load();
        }
    }, [options.lazy, load]);

    // Return the results.
    const refresh = React.useCallback(async () => { !refetch ? await load() : await refetch(); }, [refetch, load]);
    return React.useMemo(() => ({
        data: data ?? ({} as any),
        refresh: refresh,
        isLoading: loading,
        errors: error,
    }), [data, refresh, loading, error,]);
}