/**
 * Render a HorizontalRule <hr /> branded for a site. 
 * Takes in an image URL and alt text, and a color for the rule and background (background should be the body background).
 */
export const HorizontalRule = () => {
    const horizontalRulesValues = {
        imageUrl: "/img/originals/Cub-Cropped.png",
        imageAltText: "Happy Cubs Cub",
        ruleColor: "#fada00",
        backgroundColor: '#fff',
    };

    // Render the UI
    //
    return (
        <>
            <div style={{ position: 'relative', margin: '1rem 12px' }}>
                <hr style={{ border: 'none', borderBottom: `2px solid ${horizontalRulesValues.ruleColor}`, width: '100%' }} />
                <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', background: horizontalRulesValues.backgroundColor }}>
                    <img src={horizontalRulesValues.imageUrl} alt={horizontalRulesValues.imageAltText} style={{ borderRadius: '50%', width: '30px', height: '30px', objectFit: 'cover' }} />
                </div>
            </div>
        </>
    );
};