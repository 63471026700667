import { ValidationErrors } from "pojo-validator";
import { useCallback, useMemo } from "react";
import { ConditionalFragment } from "react-conditionalfragment";
import { useTranslation } from "react-i18next";
import { Button, FormGroup, Label, ListGroup } from "reactstrap";
import { ClassLocation, ClassSubStage, ScheduledClass } from "../../../../api/main/generated/graphql";
import { Child } from "../../../../api/main/models/Child";
import { ClassStage } from "../../../../api/main/models/ClassStage";
import { CourtesyClassCredit, courtesyClassCreditDefaultValues } from "../../../../api/main/models/CourtesyClassCredit";
import { Term } from "../../../../api/main/models/Term";
import { useScheduledClassesForChildrenCourtesyClassCreditsTabViewModel } from "../../../../api/main/scheduledClasses/viewModels/useScheduledClassesForChildrenCourtesyClassCreditsTabViewModel";
import { AlertOnErrors } from "../../../../shared/alertOnErrors";
import { ModelArrayChanges } from "../../../../shared/useChanges";
import { LoadingIndicator } from "../../../shared/loadingIndicator/LoadingIndicator";
import { CourtesyClassCreditComponent } from "./CourtesyClassCreditsComponent";

export interface ScheduledClassWithClassLocation extends ScheduledClass {
    classLocation: ClassLocation,
    classStage: ClassStage,
    classSubStage: ClassSubStage;
}

export interface CourtesyClassCreditsTabProps {
    model: Child | null | undefined,

    courtesyClassCreditsManager: ModelArrayChanges<CourtesyClassCredit, string>,
    validateCourtesyClassCredit: (model: CourtesyClassCredit) => boolean,
    courtesyClassCreditValidationErrors: (id: string) => ValidationErrors,
    terms: Array<Term> | null | undefined,
}

/**
 * CourtesyClassCredits for Children
 */
export const CourtesyClassCreditsTab = (props: CourtesyClassCreditsTabProps) => {
    const { model, courtesyClassCreditsManager, validateCourtesyClassCredit, courtesyClassCreditValidationErrors, terms} = props;
    const { t } = useTranslation();

    // Load the scheduledClasses
    const {
        data: {
            items: _items,
            classStages,
            classSubStages,
        }, isLoading, errors: loadErrors
    } = useScheduledClassesForChildrenCourtesyClassCreditsTabViewModel();

    // Add a new CourtesyClassCredit and link it back to the parent model
    const addChildCourtesyClassCredit = useCallback(() => {
        courtesyClassCreditsManager.addFor({ ...courtesyClassCreditDefaultValues(), childId: model?.id, });
    }, [courtesyClassCreditsManager, model]);

    const items = useMemo(() => _items?.map(item => {
        const classStage = classStages?.find(it => it.id === item.currentClassStageId);
        const classSubStage = classSubStages?.find(it => it.id === item.currentClassSubStageId);

        return {
            ...item,

            classStage,
            classSubStage
        };
    }), [_items, classStages, classSubStages]);

    // Render the UI
    //
    return (
        <>
            <ConditionalFragment showIf={!!isLoading}>
                <LoadingIndicator />
            </ConditionalFragment>

            <AlertOnErrors
                errors={[
                    loadErrors
                ]}
            />

            <FormGroup>
                <Label htmlFor="childCourtesyClassCredits">
                    {t('editChild.childCourtesyClassCreditsTab.headingLabel', 'Courtesy class credits for this cub: ')}
                </Label>

                <ListGroup id="childCourtesyClassCredits">
                    {
                        courtesyClassCreditsManager.model.filter(it => !it.archived && !it.isConsumed).map(item => (
                            <CourtesyClassCreditComponent key={item.id}
                                model={item}
                                change={changes => courtesyClassCreditsManager.changeFor(item.id, changes)}
                                remove={() => courtesyClassCreditsManager.removeFor(item.id)}
                                validate={() => validateCourtesyClassCredit(item)}
                                validationErrors={courtesyClassCreditValidationErrors(item.id)}
                                terms={terms}
                                scheduledClasses={items as Array<ScheduledClassWithClassLocation>}
                            />
                        ))
                    }
                </ListGroup>

                <div className="mt-2">
                    <Button color="primary" outline onClick={() => addChildCourtesyClassCredit()}>
                        {t('editChild.childCourtesyClassCreditsTab.add', 'Add courtesy class credit')}
                    </Button>
                </div>
            </FormGroup>
        </>
    );
};