import moment from "moment";
import { useMemo } from "react";
import { ConditionalFragment } from "react-conditionalfragment";
import { Alert } from "reactstrap";
import { useToggleState } from "use-toggle-state";
import { useNoticeBoardItems } from "../../api/main/noticeBoardItems/useNoticeBoardItems";
import { AlertOnErrors } from "../../shared/alertOnErrors";
import { HtmlDisplay } from "../../shared/htmlEditor";
import { LoadingIndicator } from "../shared/loadingIndicator/LoadingIndicator";
import "./noticeBoard.scss";

/**
 * Notice board component
 */
export const NoticeBoard = () => {
    // Load the data
    const {
        data: {
            items: noticeBoardItems,
        }, isLoading, errors: loadErrors
    } = useNoticeBoardItems();

    // Notice board items
    const activeNoticeBoardItems = useMemo(() => {
        let ret = (noticeBoardItems as any[] || []);

        ret = ret.filter(it => moment(it.endDate) >= moment());

        return ret;
    }, [noticeBoardItems]);

    // Handle toggling the notice board
    const [showAlert, toggleShowAlert] = useToggleState(true);

    // Render the UI
    //
    return (
        <>
            <ConditionalFragment showIf={!!isLoading}>
                <LoadingIndicator />
            </ConditionalFragment>
            
            <ConditionalFragment showIf={!isLoading && !!activeNoticeBoardItems.length}>
                <Alert color="info" toggle={() => toggleShowAlert()} isOpen={showAlert}>
                    <AlertOnErrors error={loadErrors} />

                    <div className="notice-board">
                        {activeNoticeBoardItems?.map(noticeBoardItem => (
                            <div className="notice-board-item">
                                <div className="notice-board-item-content">
                                    <div>
                                        <span className="notice-board-item-content-title">
                                            {noticeBoardItem.title}
                                        </span>
                                    </div>
                                    <HtmlDisplay html={noticeBoardItem.contentHtml} />
                                </div>
                            </div>
                        ))}
                    </div>
                </Alert>
            </ConditionalFragment>
        </>
    );
};