import gql from 'graphql-tag';
import { Guid } from 'guid-string';
import { StaffAbsenceTypeFieldsFragment } from '../generated/graphql';

export const staffAbsenceTypeFieldsFragment = gql`
    fragment staffAbsenceTypeFields on StaffAbsenceType {
        id
        name
        displayOrder
        archived
    }
`;

export type StaffAbsenceType = Omit<StaffAbsenceTypeFieldsFragment, '__typename'>;

/**
 * Default values.
 */
export const staffAbsenceTypeDefaultValues = (): Partial<StaffAbsenceType> => ({
    id: Guid.newGuid(),
    name: '',
    displayOrder: 99,
    archived: false,
});