import { HappyIcon } from "../../../../components/shared/Utilities/HappyIcon";

/**
 * Takes in a ClassType and returns the matching icon. Utilizing the HappyIcon wrapper for FontAwesomeIcons
 */
export const useClassTypeForIcons = (classType: string) => {
    switch (classType) {
        case "Swim Cubs": return (<><HappyIcon icon="swim-cubs" /><> </></>);
        case "Music Cubs": return (<><HappyIcon icon="music-cubs" /><> </></>)
    }

    // If we arrive here then we don't know, so return undefined
    return undefined;
}