import * as React from 'react';
import { useLazyQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { AsyncLoadOptions, AsyncLoadResult } from '../../../../shared/abstractStore';
import { EditClassLocationViewModelQuery, EditClassLocationViewModelQueryVariables } from '../../generated/graphql';
import { mainApiConfig } from '../../../../configure/mainApiConfig';
import { Guid } from 'guid-string';
import { classLocationFieldsFragment } from '../../models/ClassLocation';
import { classLocationClassTypeFieldsFragment } from '../../models/ClassLocationClassType';
import { classLocationRequirementCheckFieldsFragment } from '../../models/ClassLocationRequirementCheck';
import { staffNoteFieldsFragment } from '../../models/StaffNote';
import { classLocationAmenityTypeFieldsFragment } from '../../models/ClassLocationAmenityType';

/**
 * Get all model date required for the EditClassLocation component from the api.
 * @param id
 * @param options
 */
export function useEditClassLocationViewModel(id: string | undefined | null, options: AsyncLoadOptions = {}): AsyncLoadResult<EditClassLocationViewModelQuery> {
    // Query data we need from the api.
    const [load, { data, loading, error, refetch, }] = useLazyQuery<EditClassLocationViewModelQuery, EditClassLocationViewModelQueryVariables>(
        gql`
        query editClassLocationViewModelQuery ($id: ID!) {
            model: classLocation (id: $id) {
                ...classLocationFields
            }

            classLocationClassTypes (classLocationId: $id) {
                ...classLocationClassTypeFields
            }

            classLocationRequirementChecks (classLocationId: $id) {
                ...classLocationRequirementCheckFields
            }

            staffNotes (targetId: $id) {
                ...staffNoteFields
            }

            classLocationAmenityTypes (classLocationId: $id) {
                ...classLocationAmenityTypeFields
            }
        }

        ${classLocationFieldsFragment}
        ${classLocationClassTypeFieldsFragment}
        ${classLocationRequirementCheckFieldsFragment}
        ${staffNoteFieldsFragment}
        ${classLocationAmenityTypeFieldsFragment}
        `,
        {

            variables: {
                id: id ?? Guid.empty,
            },
            fetchPolicy: options.fetchPolicy ?? mainApiConfig.defaultFetchPolicy,
            pollInterval: options.pollInterval,
            onCompleted: (data) => {
                if (options.onCompleted) {
                    options.onCompleted(data);
                }
            }
        }
    );

    // Run the query (unless we are being lazy).
    React.useEffect(() => {
        if (!options.lazy) {
            load();
        }
    }, [options.lazy, load])

    // Return the results.
    const refresh = React.useCallback(async () => { !refetch ? await load() : await refetch(); }, [refetch, load]);
    return React.useMemo(() => ({
        data: data ?? ({} as any),
        refresh: refresh,
        isLoading: loading,
        errors: error
    }), [data, refresh, loading, error]);
}
