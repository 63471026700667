import { ConditionalFragment } from "react-conditionalfragment";
import { useTranslation } from "react-i18next";
import { Button, CardImg, Col, Row, Spinner, } from "reactstrap";
import { useToggleState } from "use-toggle-state";
import { getFullUrl } from "../../../../utilities/getFullUrl";
import { ChildAttendanceWithLocationAndStaff, ScheduledClassChildWithLocationAndStaff } from "./classesTab";
import { useCallback, useMemo } from "react";
import { StyledModal } from "../../../shared/styledModal/StyledModal";
import { LoadingIndicator } from "../../../shared/loadingIndicator/LoadingIndicator";
import { useBlobReference } from "../../../../api/main/blobReferences/useBlobReference";
import { AlertOnErrors } from "../../../../shared/alertOnErrors";
import { StaffModal } from "./StaffModal";
import { LinkContainer } from "react-router-bootstrap";
import { useWelcomePackDocumentsViewModel } from "../../../../api/main/welcomePackDocuments/viewModels/useWelcomePackDocumentsViewModel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LessonDatesModal } from "./LessonDatesModal";
import { ClassStage } from "../../../../api/main/models/ClassStage";
import { ClassSubStage } from "../../../../api/main/models/ClassSubStage";
import { dayOfWeekDisplayName } from "../../../../api/main/models/constants/DayOfWeek";
import "./classesComponent.scss";
import { HorizontalRule } from "../../../shared/horizontalRule/HorizontalRule";
import moment from "moment";
import { useLeaveScheduledClassWaitingListMutation } from "../../../../api/main/scheduledClassChildren/useLeaveScheduledClassWaitingListMutation";
import { useAsyncCallback } from "react-use-async-callback";
import { ButtonAsync } from "reactstrap-buttonasync";
import { Guid } from "guid-string";
import { Term } from "../../../../api/main/models/Term";

export interface ClassComponentProps {
    model: ScheduledClassChildWithLocationAndStaff | ChildAttendanceWithLocationAndStaff | undefined,
    //classTypes: Array<ClassType> | undefined | null,
    classType: string | undefined | null;
    classStages: Array<ClassStage> | undefined | null,
    classSubStages: Array<ClassSubStage> | undefined | null,
    refresh?: () => Promise<void>,
    //terms: Array<Term> | undefined | null,
    term?: Term | undefined | null,
    //myClasses?: Array<ScheduledClassChildWithLocationAndStaff> | undefined | null,

    isCourtesyClass?: boolean;
    courtesyClassLessonDate?: string;

    isWaitingList?: boolean;
}

/**
 * Component under ClassesTab that renders an individual class.
 */
export const ClassesComponent = (props: ClassComponentProps) => {
    const {
        model,
        classType,
        classStages,
        classSubStages,
        refresh,
        //terms,
        term,
        //myClasses,
        isCourtesyClass,
        courtesyClassLessonDate,
        isWaitingList
    } = props;
    const { t } = useTranslation();

    const stage = useMemo(() => {
        if (!model) return;

        return classStages?.find(it => it.id === model.scheduledClass?.currentClassStageId);
    }, [classStages, model]);

    const subStage = useMemo(() => {
        if (!model) return;

        return classSubStages?.find(it => it.id === model.scheduledClass?.currentClassSubStageId);
    }, [classSubStages, model]);

    // Load the data
    const {
        data: {
            model: image
        }, isLoading: _isLoading, errors: imageLoadErrors
    } = useBlobReference(stage?.photoBlobReferenceId);

    // Load the supporting data
    const {
        data: {
            items: welcomePacks
        }, isLoading: isLoadingWelcomePacks, errors: welcomePackLoadErrors
    } = useWelcomePackDocumentsViewModel(model?.scheduledClass?.currentClassStageId);
    const isLoading = _isLoading || isLoadingWelcomePacks;

    // Handle showing the teachers badges
    const [isShowingTeachers, _toggleIsShowingTeachers] = useToggleState();
    const toggleIsShowingTeachers = useCallback(() => {
        _toggleIsShowingTeachers();
    }, [_toggleIsShowingTeachers]);

    // Handle showing the lesson dates modal
    const [isShowingLessonDatesModal, _toggleIsShowingLessonDatesModal] = useToggleState();
    const toggleIsShowingLessonDatesModal = useCallback(() => {
        _toggleIsShowingLessonDatesModal();
    }, [_toggleIsShowingLessonDatesModal]);

    // Handle opening the MapUrl
    const openMapUrl = (url: string | undefined) => {
        const fullUrl = getFullUrl(url);

        // If we don't have a URL to work with do nothing.
        if (!fullUrl) { return; }

        // Open in a new window.
        window.open(fullUrl);
    };

    // Handling toggling of the Leave class modal
    const [leaveClassModalIsOpen, _toggleLeaveClassModal] = useToggleState();
    const toggleLeaveClassModal = useCallback(() => {
        _toggleLeaveClassModal();
    }, [_toggleLeaveClassModal]);

    // Calculate the class duration
    const classDuration = useMemo(() => {
        if (!model || !model?.scheduledClass) return 0;

        const start = model?.scheduledClass.startTimeHours * 60 + model?.scheduledClass.startTimeMinutes;
        const end = model?.scheduledClass.endTimeHours * 60 + model?.scheduledClass.endTimeMinutes;

        return end - start;
    }, [model]);

    // Format the age for display.;
    const formatAge = useCallback((years: number, months: number) => {
        const totalMonths = (years * 12) + months;

        if (totalMonths <= 18) {
            return `${totalMonths} months`;
        } else {
            const numberOfYears = Math.floor(totalMonths / 12);
            const remainingMonths = totalMonths - (numberOfYears * 12);

            if (remainingMonths >= 6) {
                return `${numberOfYears}.5 ${numberOfYears === 1 ? 'yr' : 'yrs'}`;
            }

            return `${numberOfYears}.${remainingMonths} ${numberOfYears === 1 ? 'yr' : 'yrs'}`;
        }
    }, []);

    const [leaveWaitingListMutation, { errors: leaveWaitingListMutationErrors }] = useLeaveScheduledClassWaitingListMutation();
    const [leaveWaitingList, { isExecuting: isLeavingWaitingList, errors: leaveWaitingListErrors }] = useAsyncCallback(async () => {
        if (!model) {
            return;
        }

        // Leave the waiting list.
        await leaveWaitingListMutation(model.id);

        // Because the refresh of the list can take some time, lets hide ourselves so we give a more responsive UI experience.
        //setIsHidden(true);

        // Refresh the list we belong to.
        if (refresh) {
            refresh();
        }
    }, [leaveWaitingListMutation, model, refresh]);

    const { data: { model: welcomePackBlobReference } } = useBlobReference(
        !welcomePacks || !welcomePacks.length ? Guid.empty : welcomePacks[0].blobReferenceId
    );

    // URL of the welcome pack download.
    const welcomePackUrl = useMemo(() => {
        if (welcomePackBlobReference) {
            return welcomePackBlobReference.url;
        }

        if (!welcomePacks || !welcomePacks.length) {
            return 'https://www.happycubs.ie/';
        }

        const ret = `/api/blobs/redirectToUrl/${welcomePacks[0].blobReferenceId ?? Guid.empty}`;
        return ret;
    }, [welcomePacks, welcomePackBlobReference]);

    // Render the UI
    //
    return (
        <>
            <AlertOnErrors errors={[
                imageLoadErrors,
                welcomePackLoadErrors,
                leaveWaitingListMutationErrors,
                leaveWaitingListErrors
            ]} />

            <Row className="class-component-header">
                <Col xs={12} sm={6} className="class-component-header-location">
                    {model?.location?.name}
                </Col>

                <Col xs={12} sm={6} className="class-component-header-class">
                    <ConditionalFragment showIf={!!isCourtesyClass && !!courtesyClassLessonDate}>
                        {t('TODO', '{{date, dddd Do MMMM }} @ {{hours, 00}}:{{minutes, 00}}{{amOrPm}}', { date: moment(courtesyClassLessonDate), hours: model?.scheduledClass?.startTimeHours, minutes: model?.scheduledClass?.startTimeMinutes, amOrPm: model?.scheduledClass?.startTimeHours ?? 0 < 12 ? 'am' : 'pm' })}
                    </ConditionalFragment>

                    <ConditionalFragment showIf={!isCourtesyClass}>
                        {t('classFinderClass.startTime.formmated.dayName', '{{dow}} @ {{hours, 00}}:{{minutes, 00}}{{amOrPm}}', { dow: dayOfWeekDisplayName(model?.scheduledClass?.dayOfWeek ?? 0, t), hours: model?.scheduledClass?.startTimeHours, minutes: model?.scheduledClass?.startTimeMinutes, amOrPm: model?.scheduledClass?.startTimeHours ?? 0 < 12 ? 'am' : 'pm' })}
                    </ConditionalFragment>
                </Col>
            </Row>

            <Row className="class-component-details">
                <Col className="class-component-details-card">
                    <ConditionalFragment showIf={!isLoading && !!image}>
                        <CardImg
                            className="class-component-details-card-image"
                            alt="Card image cap"
                            src={image?.url}
                            top
                        />
                    </ConditionalFragment>

                    <ConditionalFragment showIf={!!isLoading}>
                        <LoadingIndicator />
                    </ConditionalFragment>

                    <Row className="mt-2 justify-content-between class-component-details-card-body-time" style={{ margin: '0 0.5rem' }}>
                        <Col className="p-0 ">
                            <FontAwesomeIcon icon="clock" className="class-component-details-card-body-time-icon" />
                            <> </>
                            {t('classFinderClass.startTime.formmated.noDay', '{{hours, 00}}:{{minutes, 00}}', { hours: model?.scheduledClass?.startTimeHours, minutes: model?.scheduledClass?.startTimeMinutes })}
                            <> - </>
                            {t('classFinderClass.endTime.formmated.noDay', '{{hours, 00}}:{{minutes, 00}}', { hours: model?.scheduledClass?.endTimeHours, minutes: model?.scheduledClass?.endTimeMinutes })}
                        </Col>

                        <Col xs="auto" className="class-component-details-card-body-time-duration">
                            {t('classFinderClass.class.duration', '{{minutes}} min class', { minutes: classDuration })}
                        </Col>
                    </Row>

                    <Row className="class-component-details-card-body-stage" style={{ margin: '0 0.5rem' }}>
                        <Col className="p-0">
                            <img src="/img/originals/Cub-Cropped.png" alt="Happy Cubs Bear Cub" className="class-component-details-card-body-stage-icon" />
                            <> </>
                            {stage?.name} {subStage?.name}
                        </Col>

                        <Col xs="auto" className="class-component-details-card-body-stage-ages">
                            {t('classFinderClass.class.ages', 'ages')}
                            <> </>
                            {formatAge(stage?.startAgeYears ?? 0, stage?.startAgeMonths ?? 0)}
                            <> - </>
                            {formatAge(stage?.endAgeYears ?? 0, stage?.endAgeMonths ?? 0)}
                        </Col>
                    </Row>

                    <Row className="class-component-details-card-body-price" style={{ margin: '0 0.5rem' }}>
                        <Col className="p-0">
                            <FontAwesomeIcon icon="euro-sign" className="class-component-details-card-body-price-icon" />
                            <> </>

                            <ConditionalFragment showIf={!!isCourtesyClass}>
                                {t('classFinderClass.courestClass.field', 'Courtesy class')}
                            </ConditionalFragment>

                            <ConditionalFragment showIf={!isCourtesyClass}>
                                {t('common.money.euro', '€{{value, 0.00}}', { value: (model?.scheduledClass?.lessonsPerTerm ?? 0) * (model?.scheduledClass?.costPerLesson ?? 0) })}
                                <> </>
                                {t('classFinderClass.price.weeks', 'for {{weeks}} weeks', { weeks: model?.scheduledClass?.lessonsPerTerm })}
                            </ConditionalFragment>
                        </Col>

                        <Col xs="auto" className="class-component-details-card-body-price-info">
                            <a href={`/stages-information?classType=${classType}`} target="_blank" rel="noreferrer">
                                <FontAwesomeIcon icon="info-circle" />
                                <span style={{ margin: 0, marginLeft: '8px' }}>{t('common.stagesInfo', 'Stage info')}</span>
                            </a>
                        </Col>
                    </Row>

                    <ConditionalFragment showIf={!!term && !!isWaitingList}>
                        <Row className="class-component-details-card-body-price" style={{ margin: '0 0.5rem' }}>
                            <Col className="p-0">
                                <FontAwesomeIcon icon="sign" className="class-component-details-card-body-price-icon" />
                                <> </>
                                {term?.name}
                            </Col>
                        </Row>
                    </ConditionalFragment>

                    <ConditionalFragment showIf={!isCourtesyClass && !isWaitingList && !!welcomePacks && welcomePacks?.length > 0}>
                        <Row className="class-component-details-card-body-button class-component-details-card-body-button-top">
                            <a href={welcomePackUrl} className="btn btn-primary" download={welcomePackBlobReference?.filename}>
                                Welcome Bear Pack
                            </a>
                        </Row>
                    </ConditionalFragment>

                    <ConditionalFragment showIf={!isCourtesyClass && !isWaitingList}>
                        <Row className="class-component-details-card-body-button">
                            <Button style={{ background: '#d9d9d9', borderColor: 'transparent' }} onClick={() => toggleIsShowingLessonDatesModal()}>View term dates</Button>
                        </Row>
                    </ConditionalFragment>

                    <ConditionalFragment showIf={!isWaitingList}>
                        <Row className="class-component-details-card-body-button">
                            <Button color="#a5ebed" style={{ background: '#a5ebed', borderColor: 'transparent', color: 'white' }} onClick={() => toggleIsShowingTeachers()}>Meet your teacher</Button>
                        </Row>
                    </ConditionalFragment>

                    <ConditionalFragment showIf={!isCourtesyClass && !isWaitingList}>
                        <Row className="class-component-details-card-body-button">
                            <LinkContainer to={`/my/children/absence/${model?.scheduledClassId}/${model?.childId}`}>
                                <Button color="secondary">Log absence</Button>
                            </LinkContainer>
                        </Row>
                    </ConditionalFragment>

                    <ConditionalFragment showIf={!!isWaitingList}>
                        <Row className="class-component-details-card-body-button">
                            <ButtonAsync color="danger" outline
                                isExecuting={isLeavingWaitingList} executingChildren={<><Spinner size="sm" /><> </>{t('membersChildTab.waitingListsTab.waitingListsComponent.leaveingWaitingList', 'Leaving...')}</>}
                                onClick={() => leaveWaitingList()}
                            >Leave waitlist</ButtonAsync>
                        </Row>
                    </ConditionalFragment>

                    <ConditionalFragment showIf={!isWaitingList}>
                        <Row className="class-component-details-card-body-button class-component-details-card-body-button-bottom">
                            <Button color="link" style={{ fontSize: '1.5rem', fontWeight: '600', fontFamily: 'museosansrounded' }} onClick={() => openMapUrl(model?.location?.mapUrl)}>Directions Google Maps</Button>
                        </Row>
                    </ConditionalFragment>
                </Col>
            </Row>

            <HorizontalRule />

            {/* Modal used to display ScheduledClassStaff */}
            <StyledModal isOpen={isShowingTeachers} toggle={toggleIsShowingTeachers} size="md">
                <StaffModal
                    model={model}
                    toggle={toggleIsShowingTeachers}
                    classType={classType}
                />
            </StyledModal>

            {/* Modal used to display lesson dates */}
            <StyledModal isOpen={isShowingLessonDatesModal} toggle={toggleIsShowingLessonDatesModal} size="md">
                <LessonDatesModal
                    model={model}
                    toggle={toggleIsShowingLessonDatesModal}
                    lessonDates={model?.scheduledClass?.lessonDatesForCurrentTerm ?? []}
                />
            </StyledModal>

            {/* Modal used to confirm a class leave */}
            <StyledModal isOpen={leaveClassModalIsOpen} toggle={toggleLeaveClassModal} size="md">
                {/*<LeaveClassModal*/}
                {/*    model={model}*/}
                {/*    toggle={toggleLeaveClassModal}*/}
                {/*    refresh={refresh}*/}
                {/*    classStages={classStages}*/}
                {/*/>*/}
            </StyledModal>
        </>
    );
};