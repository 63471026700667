import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import { useCallback, useMemo, useState } from "react";
import { ConditionalFragment } from "react-conditionalfragment";
import { useTranslation } from "react-i18next";
import { Button, Col, Input, ListGroup, Row } from "reactstrap";
import { useToggleState } from "use-toggle-state";
import { Child } from "../../api/main/models/Child";
import { Profile } from "../../api/main/models/Profile";
import { QueryMessage } from "../../api/main/models/QueryMessage";
import { Staff } from "../../api/main/models/Staff";
import { useCurrentUserProfile } from "../../api/main/profiles/useCurrentUserProfile";
import { useMembersQueryMessagesViewModel } from "../../api/main/queryMessages/viewModels/useMembersQueryMessagesViewModel";
import { AlertOnErrors } from "../../shared/alertOnErrors";
import { MembersWithMessagesType } from "../adminQueryMessages/AdminQueryMessagesOverview";
import { MessageModal } from "../membersChildren/MessageModal";
import { NoticeBoard } from "../noticeBoard/NoticeBoard";
import { QueryMessageThreadList } from "../queryMessages/QueryMessageThreadList";
import { Banner } from "../shared/banner/Banner";
import { LoadingIndicator } from "../shared/loadingIndicator/LoadingIndicator";
import { MainContainer } from "../shared/mainContainer/MainContainer";
import { NoResultsFound } from "../shared/noResultsFound/NoResultsFound";
import { StickyToolbar } from "../shared/stickyToolbar/StickyToolbar";
import { StyledModal } from "../shared/styledModal/StyledModal";
import "./membersQueryMessagesList.scss";

export interface QueryMessageWithChildMemberAndStaffProfile extends QueryMessage {
    child?: Partial<Child>,
    member?: {
        photoBlobReferenceId: string,
        user?: {
            profile?: Partial<Profile>;
        };
    };
    staffDetails?: Staff,
    staffProfile?: Profile;
}

/**
 * Component for displaying a list of QueryMessages for Members
 */
export const MembersQueryMessagesList = () => {
    const { t } = useTranslation();

    // Load the currentUserProfile
    const {
        data: {
            model: userProfile
        }, isLoading: _isLoading, errors: loadErrors,
    } = useCurrentUserProfile();

    // Load the data
    const {
        data: {
            items: _allItems,
            staffs,
            profiles,
            queryMessageTypes
        }, isLoading: isLoadingData, errors: dataLoadErrors, refresh: refreshMessages
    } = useMembersQueryMessagesViewModel(userProfile?.id);
    const isLoading = _isLoading || isLoadingData;

    // Combine the loaded data so that each item has the required data
    const items = useMemo(() => _allItems?.map(item => {
        const staffDetails = staffs?.find(it => it.id === item.assignedToStaffUserId); // Find the StaffId from the UserId
        const staffProfile = profiles?.find(it => it.id === staffDetails?.id && item.isReplyFromStaff === true); // Find the matching StaffProfile

        return {
            ...item,
            staffDetails,
            staffProfile
        };
    }), [_allItems, profiles, staffs]);

    // Filtering for All, Open, or Closed
    const [messageFilter, setMessageFilter] = useState<'all' | 'replied' | 'awaiting reply'>('all');

    // Filtering for subjects
    const [subjectFilter, setSubjectFilter] = useState<string | undefined>();

    // New thread modal
    const [isOpen, _setIsOpen] = useToggleState();
    const setIsOpen = useCallback(() => {
        _setIsOpen(!isOpen);
    }, [_setIsOpen, isOpen]);

    // Generate an Array of threads
    const allThreadsWithMember = useMemo(() => {
        if (!items) return;

        let result: Array<MembersWithMessagesType> = [];

        // Here i was going to filter the messages with !it.threadKey, then add all the related messages for each thread. Each thread should contain the member details 
        items?.filter(it => !it.threadKey).forEach(item => {
            const memberId = item.memberId;
            const memberName = `${item.member.user.profile?.firstName} ${item.member.user.profile?.lastName}`;
            const photoBlobReferenceId = item.member.photoBlobReferenceId;
            const allMessagesForThread = [item, ...items?.filter(it => it.threadKey === item.id)];

            result.push({
                memberId,
                memberName,
                photoBlobReferenceId,
                messages: [allMessagesForThread.flat()]
            });
        });

        // Sort threads so the one with the most recent reply from staff is top by checking .isReplyFromStaff
        result = result.sort((a, b) => {
            const aLastMessage = a.messages[0][a.messages[0].length - 1];
            const bLastMessage = b.messages[0][b.messages[0].length - 1];

            if (aLastMessage.isReplyFromStaff && !bLastMessage.isReplyFromStaff) {
                return 1;
            } else if (!aLastMessage.isReplyFromStaff && bLastMessage.isReplyFromStaff) {
                return -1;
            } else {
                return 0;
            }
        });

        // Sort so that threads awaiting reply are at the top, then sort by the message .submittedDate
        result = result.sort((a, b) => {
            const aLastMessage = a.messages[0][a.messages[0].length - 1];
            const bLastMessage = b.messages[0][b.messages[0].length - 1];

            if (!aLastMessage.isReplyFromStaff && bLastMessage.isReplyFromStaff) {
                return -1;
            } else if (aLastMessage.isReplyFromStaff && !bLastMessage.isReplyFromStaff) {
                return 1;
            } else {
                return moment(bLastMessage.submittedDate).diff(moment(aLastMessage.submittedDate));
            }
        });


        return result;
    }, [items]);

    // Filter based on the state dropdown.
    const threadsWithMember = useMemo(() => {
        let ret = allThreadsWithMember;

        // Filter by message filter.
        switch (messageFilter) {
            // Return items that have one or more open messages.
            case 'awaiting reply':
                ret = ret?.map(item => {
                    // Get the threads with at least one unresolved message.  These are considered open.
                    const openThreads = item.messages?.filter(thread => !thread[thread.length - 1].isReplyFromStaff);

                    return {
                        ...item,
                        messages: openThreads,
                    } as MembersWithMessagesType;
                }).filter(item => item.messages.length > 0);
                break;

            // Return items that do not have any open messags.
            case 'replied':
                ret = ret?.map(item => {
                    // Get the threads with zero unresolved message.  These are considered closed.
                    const closedThreads = item.messages.filter(thread => thread[thread.length - 1].isReplyFromStaff);

                    return {
                        ...item,
                        messages: closedThreads,
                    } as MembersWithMessagesType;
                }).filter(item => item.messages.length > 0);
                break;

            // Return everything, no filtering needed.
            case 'all':
            default:
                break; // Do nothing.
        }

        // Filter by the subject.
        if (!!subjectFilter && subjectFilter !== 'all') {
            ret = ret?.map(item => {
                // Get the threads with at least one message matching the subject (we know they will all match in practice but this is how we filter to be similar to the state filter above).
                const matchingSubjectsThreads = item.messages.filter(thread => !!thread.find(message => message.queryMessageTypeId === subjectFilter));

                return {
                    ...item,
                    messages: matchingSubjectsThreads,
                } as MembersWithMessagesType;
            }).filter(item => item.messages.length > 0);
        }

        // Return the filtered results.
        return ret;
    }, [messageFilter, subjectFilter, allThreadsWithMember]);


    // Render the UI
    //
    return (
        <>
            <Banner>
                <Row>
                    <NoticeBoard />
                </Row>
                <StickyToolbar>
                    <Row>
                        <Col>
                            <h1>
                                {t('membersQueryMessagesList.title', 'All messages')}
                            </h1>
                        </Col>
                        <ConditionalFragment showIf={isLoading}>
                            <Col xs="auto">
                                <LoadingIndicator size="sm" />
                            </Col>
                        </ConditionalFragment>
                    </Row>
                    <Row>
                        <Col>
                            <Input type="select" value={subjectFilter} onChange={e => setSubjectFilter(e.currentTarget.value)}>
                                <>
                                    <option value="">{t('membersQueryMessagesList.subjectFilter.all', 'All subjects')}</option>
                                    {queryMessageTypes?.map(queryMessageType => (
                                        <option value={queryMessageType.id} key={queryMessageType.id}>{queryMessageType.name}</option>
                                    ))}
                                </>
                            </Input>
                        </Col>
                        <Col>
                            <Input type="select" value={messageFilter} onChange={e => setMessageFilter(e.currentTarget.value as any)}>
                                <option value="all">{t('membersQueryMessagesList.messageFilter.all', 'All messages')}</option>
                                <option value="replied">{t('membersQueryMessagesList.messageFilter.open', 'Replies')}</option>
                                <option value="awaiting reply">{t('membersQueryMessagesList.messageFilter.closed', 'Awaiting reply')}</option>
                            </Input>
                        </Col>
                    </Row>
                </StickyToolbar>
            </Banner>

            <MainContainer>
                <AlertOnErrors errors={[
                    loadErrors,
                    dataLoadErrors,
                ]} />

                <ListGroup>
                    {threadsWithMember?.map(member => (
                        <QueryMessageThreadList isMemberView={true} member={member} profiles={profiles} queryMessageTypes={queryMessageTypes} />
                    ))}
                </ListGroup>

                <ConditionalFragment showIf={!isLoading && !threadsWithMember?.length}>
                    <NoResultsFound icon="comment">
                        {t('membersQueryMessagesList.noItemsText', 'If you have any questions, feel free to send us a message below and we\'ll get back to you as soon as we can.')}
                    </NoResultsFound>
                </ConditionalFragment>

                <Row style={{ marginTop: '1rem', textAlign: 'center' }}>
                    <Col>
                        <Button color="primary" onClick={e => setIsOpen()}>
                            <FontAwesomeIcon icon="message" />
                            <> </>
                            {t('common.newMessage', 'New message')}
                        </Button>
                    </Col>
                </Row>

                <StyledModal isOpen={isOpen} toggle={setIsOpen} size="lg">
                    <MessageModal toggle={setIsOpen} refreshMessages={() => refreshMessages()} />
                </StyledModal>
            </MainContainer>
        </>
    );
};