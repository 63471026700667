import { ApexOptions } from "apexcharts";
import { useMemo } from "react";
import Chart from "react-apexcharts";

export type ClassSizeViewModelData = {
    attending: number;
    classDetails: string;
    classUtilization: string;
    classUtilizationPercentage: number;
    spaces: number;
    waitingList: number;
};

export interface ClassSizesBarChartProps {
    chartData: Array<ClassSizeViewModelData> | undefined | null,
};

/**
 * ClassSizes BarChart for the ClassSizes Report
 * @param props
 * @returns
 */
export const ClassSizesBarChart = (props: ClassSizesBarChartProps) => {
    const { chartData } = props;

    // Chart options
    const options = useMemo(() => (
        {
            chart: {
                height: 800,
                type: 'bar',
                stacked: true
            },
            plotOptions: {
                bar: {
                    horizontal: true,
                }
            },
            colors: ['#00E396'],
            dataLabels: {
                formatter: function (val: any, opt: any) {
                    const goals =
                        opt.w.config.series[opt.seriesIndex].data[opt.dataPointIndex]
                            .goals;

                    if (goals && goals.length) {
                        return `${val} / ${goals[0].value}`;
                    }
                    return val;
                }
            },
            legend: {
                show: true,
                showForSingleSeries: true,
                customLegendItems: ['Attending', 'Waiting list'],
            }
        } as ApexOptions
    ), []);

    // Chart data
    const series = useMemo(() => {
        const ret: any = {
            data: [
                {
                    name: "Attending",
                    data: [],
                    color: '#198754'
                },
                {
                    name: "Waiting list",
                    data: [],
                    color: '#dc3545'
                }
            ],
        };

        // For each class we want to add them to the data array, the one inside the array above, not the object key that is an array
        chartData?.forEach(item => {
            ret.data[0].data.push({
                x: item?.classDetails,
                y: item?.attending,

                goals: [
                    {
                        name: "Capacity",
                        value: ((item?.spaces ?? 0) + (item?.attending ?? 0)),
                        strokeColor: '#000000'
                    }
                ]
            });

            ret.data[1].data.push({
                x: item?.classDetails,
                y: item?.waitingList,
            });
        });

        return ret;
    }, [chartData]);

    // Render the UI
    //
    return (
        <>
            <Chart options={options} series={series?.data} type="bar" height={2000} />
        </>
    );
};