import { ValidationErrors } from "pojo-validator";
import { ValidateCallback } from "pojo-validator-react";
import { ValidatedInput } from "pojo-validator-reactstrap";
import { useTranslation } from "react-i18next";
import { ButtonGroup, Col, Form, FormGroup, Input, Label, Row, Spinner } from "reactstrap";
import { ButtonAsync } from "reactstrap-buttonasync";
import { Child } from "../../../api/main/models/Child";
import { ChildsRelationship } from "../../../api/main/models/ChildsRelationship";
import { Gender } from "../../../api/main/models/Gender";
import { Profile } from "../../../api/main/models/Profile";
import { HtmlEditor } from "../../../shared/htmlEditor";
import { FileUploadButton } from "../../shared/fileUploadButton/FileUploadButton";
import { ValidatedISODateTimeInput } from "../../shared/isoDateTimeInput/ValidatedISODateTimeInput";
import { UploadedImagePreview } from "../../shared/uploadedImagePreview/UploadedImagePreview";

export interface MainTabProps {
    model: Child | undefined | null,
    change: (changes: Partial<Child>) => void,
    validate: ValidateCallback,
    validationErrors: ValidationErrors,
    genders: Array<Gender> | undefined | null,

    // Profile Image
    isExecutingImageUpload: boolean | undefined,
    onImageUpload: (files: FileList | null) => Promise<void>,
    isExecutingClearImage: boolean,
    imageUploadClickHandler: () => Promise<void>,
    imageUrl: string | undefined | null,

    // Supporting data
    childsRelationships: Array<ChildsRelationship> | undefined | null,
    memberProfile: Profile | undefined | null,
}

/**
 * MainTab for MembersChildrenAddChild
 */
export const MainTab = (props: MainTabProps) => {
    const {
        model,
        change,
        validate,
        validationErrors,
        genders,
        isExecutingImageUpload,
        onImageUpload,
        isExecutingClearImage,
        imageUploadClickHandler,
        imageUrl,
        childsRelationships,
        memberProfile
    } = props;
    const { t } = useTranslation();

    // Return the UI
    //
    return (
        <>
            <Form onSubmit={e => { e.preventDefault(); }}>
                <h4>{t('membersChildrenAddChild.mainTab.childDetails.heading', 'Cub details (child details)')}</h4>
                <Row>
                    <Col>
                        <FormGroup>
                            <Label htmlFor="firstName">{t('membersChildrenAddChild.mainTab.firstName.label', 'First name')}</Label>
                            <ValidatedInput name="firstName" type="text" value={model?.firstName ?? ''} onChange={e => change({ firstName: e.currentTarget.value })} onBlur={e => validate('firstName')} validationErrors={validationErrors['firstName']} />
                        </FormGroup>
                    </Col>
                    <Col>
                        <FormGroup>
                            <Label htmlFor="lastName">{t('membersChildrenAddChild.mainTab.lastName.label', 'Last name')}</Label>
                            <ValidatedInput name="lastName" type="text" value={model?.lastName ?? ''} onChange={e => change({ lastName: e.currentTarget.value })} onBlur={e => validate('lastName')} validationErrors={validationErrors['lastName']} />
                        </FormGroup>
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <FormGroup>
                            <Label htmlFor="dateOfBirth">{t('membersChildrenAddChild.mainTab.dateOfBirth.label', 'Date of birth')}</Label>
                            <ValidatedISODateTimeInput type="date" value={model?.dateOfBirth ?? ''} onChange={e => change({ dateOfBirth: e.currentTarget.value })} onBlur={e => validate('dateOfBirth')} validationErrors={validationErrors['dateOfBirth']}></ValidatedISODateTimeInput>
                        </FormGroup>
                    </Col>
                    <Col>
                        <FormGroup>
                            <Label htmlFor="genderId">{t('membersChildrenAddChild.mainTab.genderId.label', 'Gender')}</Label>
                            <ValidatedInput name="genders" type="select" value={model?.genderId ?? ''} onChange={e => change({ genderId: e.currentTarget.value })} onBlur={e => validate('genderId')} validationErrors={validationErrors['genderId']}>
                                <option value="">{t('membersChildrenAddChild.mainTab.genderId.pleaseSubmit', '(Please select a gender)')}</option>
                                {
                                    genders?.map(item => (
                                        <option key={item.id} value={item.id}>{t('membersChildrenAddChild.mainTab.genderId.value', '{{ gender }}', { gender: item.name })}</option>
                                    ))
                                }
                            </ValidatedInput>
                        </FormGroup>
                    </Col>
                </Row>

                <h4>{t('membersChildrenAddChild.mainTab.memberDetails.heading', 'Member details')}</h4>
                <Row>
                    <Col>
                        <FormGroup>
                            <Label htmlFor="memberName">{t('common.name', 'Name')}</Label>
                            <Input name="memberName" disabled value={memberProfile?.firstName + ' ' + memberProfile?.lastName} />
                        </FormGroup>
                    </Col>
                    <Col>
                        <FormGroup>
                            <Label htmlFor="memberChildsRelationshipId">{t('membersChildrenAddChild.mainTab.memberChildsRelationshipId.label', 'Relationship to child')}</Label>
                            <ValidatedInput name="memberChildsRelationshipId" type="select" value={model?.memberChildsRelationshipId ?? ''} onChange={e => change({ memberChildsRelationshipId: e.currentTarget.value })} onBlur={e => validate('memberChildsRelationshipId')} validationErrors={validationErrors}>
                                <option value="">{t('membersChildrenAddChild.mainTab.memberChildsRelationshipId.pleaseSelect', '(Please select relationship to child)')}</option>
                                {
                                    childsRelationships?.map(childRelationship => (
                                        <option key={childRelationship.id} value={childRelationship.id}>{t('membersChildrenAddChild.mainTab.memberChildsRelationshipId.value', '{{ childRelationship }}', { childRelationship: childRelationship.name ?? '' })}</option>
                                    ))
                                }
                            </ValidatedInput>
                        </FormGroup>
                    </Col>
                </Row>


                <h4>{t('membersChildrenAddChild.mainTab.healthNotes.heading', 'Health')}</h4>
                <Row>
                    <Row>
                        <FormGroup>
                            <Label htmlFor="HealthNoteHtml">{t('membersChildrenAddChild.mainTab.healthNotes.label', 'Health notes')}</Label>
                            <HtmlEditor size="sm" value={model?.healthNoteHtml ?? ''} onChange={text => change({ healthNoteHtml: text })} />
                        </FormGroup>
                    </Row>
                </Row>

                <Row>
                    <Col>
                        <FormGroup>
                            <Label htmlFor="name">{t('membersChildrenAddChild.image.label', 'Photo')}</Label>
                            <UploadedImagePreview src={imageUrl ?? ''} />
                            <Row className="">
                                <Col>
                                    <ButtonGroup>
                                        <FileUploadButton
                                            color={`primary`}
                                            isExecuting={isExecutingImageUpload}
                                            executingChildren={<><Spinner size="sm" /> {t('common.uploading', 'Uploading...')}</>}
                                            onUpload={onImageUpload}
                                            outline={false}>
                                            {t('membersChildrenAddChild.uploadButtonText', 'Upload a photo')}
                                        </FileUploadButton>
                                        <ButtonAsync color="primary"
                                            outline
                                            isExecuting={isExecutingClearImage}
                                            type="button"
                                            onClick={imageUploadClickHandler}
                                            executingChildren={<><Spinner size="sm" /> {t('membersChildrenAddChild.clearingImage', 'Clearing photo...')}</>}>
                                            {t('membersChildrenAddChild.clearImageButton', 'Clear photo')}
                                        </ButtonAsync>
                                    </ButtonGroup>
                                </Col>
                            </Row>
                        </FormGroup>
                    </Col>
                </Row>
            </Form>
        </>
    );
};