import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import { useCallback } from "react";
import { ConditionalFragment } from "react-conditionalfragment";
import { useTranslation } from "react-i18next";
import { Col, ModalBody, ModalHeader, Row } from "reactstrap";
import { useClassTypes } from "../../../../api/main/classTypes/useClassTypes";
import { DayOfWeek, dayOfWeekDisplayName } from "../../../../api/main/models/constants/DayOfWeek";
import { getScheduledClassSummary } from "../../../scheduledClass/getScheduledClassSummary";
import { ChildAttendanceWithLocationAndStaff, ScheduledClassChildWithLocationAndStaff } from "./classesTab";

export interface LessonDatesModalProps {
    model: ScheduledClassChildWithLocationAndStaff | ChildAttendanceWithLocationAndStaff | undefined | null,
    toggle: () => void,
    lessonDates: Array<string>;
}

/**
 * ComponentModal used to display LessonDates for a single ScheduledClass under membersChild classesTab classesComponent
 */
export const LessonDatesModal = (props: LessonDatesModalProps) => {
    const { model, toggle, lessonDates } = props;
    const { t } = useTranslation();

    // Load the classTypes
    const {
        data: {
            items: classTypes
        }
    } = useClassTypes();

    // Is given date in the past
    const isPast = useCallback((date: string) => moment(date).isBefore(moment()), []);

    // Render the UI
    //
    return (
        <>
            <ModalHeader toggle={toggle}>
                <span style={{ color: '#fada00' }}>
                    <h3 style={{ fontSize: '3rem', fontWeight: '600' }}>{t('common.termDates', 'Term dates')}</h3>
                    <h6 style={{ fontSize: '1.5rem' }}>
                        {classTypes?.find(it => it.id === model?.scheduledClass?.classTypeId)?.name}
                        <> - </>
                        {getScheduledClassSummary(model?.scheduledClass, { classLocation: model?.location })}</h6>
                </span>
            </ModalHeader>

            <ModalBody>
                {
                    lessonDates.map((date, index) => (
                        <Row style={{ color: isPast(date) ? '' : '', fontFamily: 'museosansrounded', marginBottom: '0.75rem' }}>
                            <Col xs={3} style={{ color: '#fada00', fontWeight: '600' }}>
                                {t('common.week.no', 'Week {{number}}', { number: index + 1 })}
                            </Col>

                            <Col>
                                {t('common.dateTimeWithDayOfWeek', '{{dayOfWeek}} {{date, Do MMMM}}', { date: moment(date), dayOfWeek: dayOfWeekDisplayName(model?.scheduledClass?.dayOfWeek as DayOfWeek, t) })}
                                <> </>
                                <ConditionalFragment showIf={!!isPast(date)}>
                                    <FontAwesomeIcon icon="check" color="#fada00" />
                                </ConditionalFragment>
                            </Col>
                        </Row>
                    ))
                }
            </ModalBody>
        </>
    );
};