import * as React from "react";
import { Row, Col, Form, FormGroup, Label, Spinner, Alert, Button, FormText } from "reactstrap";
import { useChanges } from "../../shared/useChanges";
import { useValidatorCallback } from "pojo-validator-react";
import { ValidatedInput } from "pojo-validator-reactstrap";
import { ButtonAsync } from "reactstrap-buttonasync"
import { useAuthenticatedState } from "../../api/api-authorization/useAuthenticatedState";
import { LoadingIndicator } from "../shared/loadingIndicator/LoadingIndicator";
import { Guid } from "guid-string";
import { useProfile } from "../../api/main/profiles/useProfile";
import { AlertOnErrors } from "../../shared/alertOnErrors";
import { useTranslation } from "react-i18next";
import { FormButtons } from "../shared/formButtons/FormButtons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAsyncCallback } from "react-use-async-callback";
import { MainContainer } from "../shared/mainContainer/MainContainer";
import { ConditionalFragment } from "react-conditionalfragment";
import { useSaveProfileMutation } from "../../api/main/profiles/useSaveProfileMutation";
import { useNavigate } from 'react-router';



/**
 * Allow the user to change their personal details on their profile.
 */
export const ProfileDetails = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [hasSaved, setHasSaved] = React.useState<boolean>(false);
    const [isCreate, setIsCreate] = React.useState<boolean>(false);
    const { user } = useAuthenticatedState({ includeUser: true});
    const { data: { model: storeModel, }, isLoading, errors: loadErrors, refresh: refreshProfile } = useProfile(user?.sub ?? '', {
        isUserId: true,
        lazy: true,
        onCompleted: (data) => {
            // If we don't find a record in the database, create one ready to save.
            if (!data) {
                setIsCreate(true);
                change({ id: Guid.newGuid(), userId: user?.sub ?? '' });
            }
        } });
    const { model, change, changes } = useChanges(storeModel);
    const [save, { isExecuting: isSaving, errors: saveErrors }] = useSaveProfileMutation({ afterSaving: () => setIsCreate(false) })

    const [validate, validationErrors] = useValidatorCallback((validation, fieldsToCheck) => {
        if (!model) {
            return;
        }

        const rules = {
            firstName: () => !model.firstName ? t('profileDetails.firstNameRequired', 'First name is required') : '',
            lastName: () => !model.lastName ? t('profileDetails.lastNameRequired', 'Last name is required') : '',
        };

        validation.checkRules(rules, fieldsToCheck);
    }, [model]);

    // Only load the profile once we know the user.
    React.useEffect(() => {
        if (!user) {
            return;
        }

        refreshProfile();
    }, [user, refreshProfile]);

    const [saveForm] = useAsyncCallback(async () => {
        if (!validate()) {
            return;
        }

        await save(model.id, changes, isCreate);

        setHasSaved(true);
    }, [save, model, setHasSaved, validate, isCreate, changes]);

    // Render the UI.
    //

    return (
        <MainContainer>
            <ConditionalFragment showIf={isLoading}>
                <LoadingIndicator fullWidth />
            </ConditionalFragment>

            <Form onSubmit={async e => { e.preventDefault(); await saveForm(); }}>
                <AlertOnErrors errors={[loadErrors, saveErrors]} />

                {
                    hasSaved ? (
                        <Alert color="success">
                            {t('profileDetails.hasSaved', 'Your details have been updated.')}
                        </Alert>
                        ): null
                }

                <Row>
                    <Col xs={12} md="">
                        <FormGroup>
                            <Label htmlFor="firstName">{t('profileDetails.firstName', 'First name')}</Label>
                            <ValidatedInput name="firstName" type="text" value={model.firstName ?? ''} onChange={e => change({ firstName: e.currentTarget.value })} onBlur={e => validate('firstName')} validationErrors={validationErrors['firstName']} />
                        </FormGroup>
                    </Col>
                    <Col>
                        <FormGroup>
                            <Label htmlFor="lastName">{t('profileDetails.lastName', 'Last name')}</Label>
                            <ValidatedInput name="lastName" type="text" value={model.lastName ?? ''} onChange={e => change({ lastName: e.currentTarget.value })} onBlur={e => validate('lastName')} validationErrors={validationErrors['lastName']} />
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                {/*Phones and emails on the left*/}
                    <Col>
                        <FormGroup>
                            <Label htmlFor="email">{t('profileDetails.primaryEmail.label', 'Primary email')}</Label>
                            <Row>
                                <Col>
                                    <ValidatedInput readOnly name="email" type="email" value={model?.primaryEmail ?? ''}
                                        onChange={e => {
                                        }}
                                        onBlur={e => validate('email')} validationErrors={validationErrors['email']} />
                                    <FormText>{t('profileDetails.primaryEmail.text', 'Primary email cannot be changed here, click the button or go to the email tab to validate your new primary email.')}</FormText>

                                </Col>
                                <Col xs="auto">
                                    <Button type="button" color="primary" outline onClick={e => navigate("/profile/change-email")}>
                                        {t('profileDetails.navToEmailChange', 'Change email')}
                                    </Button>
                                </Col>

                            </Row>
                        </FormGroup>
                        {/*<FormGroup>*/}
                        {/*    <Label htmlFor="email">{t('profileDetails.seondaryEmail', 'Secondary email')}</Label>*/}
                        {/*    <Row>*/}
                        {/*        <Col>*/}
                        {/*            <ValidatedInput name="email" type="email" value={model?.secondaryEmail ?? ''}*/}
                        {/*                onChange={e => {*/}
                        {/*                }}*/}
                        {/*                onBlur={e => validate('email')} validationErrors={validationErrors['email']} />*/}
                        {/*        </Col>*/}
                        {/*    </Row>*/}
                        {/*</FormGroup>*/}
                        <FormGroup>
                            <Label htmlFor="primaryPhone">{t('profileDetails.primaryPhone', 'Primary phone number')}</Label>
                            <ValidatedInput name="primaryPhone" type="tel" value={model?.primaryPhone ?? ''} onChange={e => change({ primaryPhone: e.currentTarget.value })} onBlur={e => validate('primaryPhone')} validationErrors={validationErrors['primaryPhone']} />
                        </FormGroup>
                        {/*<FormGroup>*/}
                        {/*    <Label htmlFor="secondaryPhone">{t('profileDetails.secondaryPhone', 'Secondary phone number')}</Label>*/}
                        {/*    <ValidatedInput name="secondaryPhone" type="tel" value={model?.secondaryPhone ?? ''} onChange={e => change({ secondaryPhone: e.currentTarget.value })} onBlur={e => validate('secondaryPhone')} validationErrors={validationErrors['secondaryPhone']} />*/}
                        {/*</FormGroup>*/}
                    </Col>
                   {/* Address on the right*/}
                    <Col xs={12} md="">
                        <FormGroup>
                            <Label htmlFor="addressLine1">{t('profileDetails.addressLine1', 'Address line 1')}</Label>
                            <ValidatedInput name="addressLine1" type="text" value={model?.addressLine1 ?? ''} onChange={e => change({ addressLine1: e.currentTarget.value })} onBlur={e => validate('addressLine1')} validationErrors={validationErrors['addressLine1']} />
                        </FormGroup>
                        <FormGroup>
                            <Label htmlFor="addressLine2">{t('profileDetails.addressLine2', 'Address line 2')}</Label>
                            <ValidatedInput name="addressLine2" type="text" value={model?.addressLine2 ?? ''} onChange={e => change({ addressLine2: e.currentTarget.value })} onBlur={e => validate('addressLine2')} validationErrors={validationErrors['addressLine2']} />
                        </FormGroup>
                        <FormGroup>
                            <Label htmlFor="city">{t('profileDetails.city', 'City')}</Label>
                            <ValidatedInput name="city" type="text" value={model?.city ?? ''} onChange={e => change({ city: e.currentTarget.value })} onBlur={e => validate('city')} validationErrors={validationErrors['city']} />
                        </FormGroup>
                        <FormGroup>
                            <Label htmlFor="county">{t('profileDetails.county', 'County')}</Label>
                            <ValidatedInput name="county" type="text" value={model?.county ?? ''} onChange={e => change({ county: e.currentTarget.value })} onBlur={e => validate('county')} validationErrors={validationErrors['county']} />
                        </FormGroup>
                        <FormGroup>
                            <Label htmlFor="postcode">{t('profileDetails.postcode', 'Postcode')}</Label>
                            <ValidatedInput name="postcode" type="text" value={model?.postcode ?? ''} onChange={e => change({ postcode: e.currentTarget.value })} onBlur={e => validate('postcode')} validationErrors={validationErrors['postcode']} />
                        </FormGroup>
                        <FormGroup>
                            <Label htmlFor="country">{t('profileDetails.country', 'Country')}</Label>
                            <ValidatedInput name="country" type="text" value={model?.country ?? ''} onChange={e => change({ country: e.currentTarget.value })} onBlur={e => validate('country')} validationErrors={validationErrors['country']} />
                        </FormGroup>
                    </Col>
                </Row>
                
                <FormButtons>
                    <ButtonAsync color="primary" isExecuting={isSaving}
                        executingChildren={<><Spinner size="sm" /> {t('common.saving', 'Saving...')}</>}>
                        <FontAwesomeIcon icon="save" />
                        <> {t('common.save', 'Save')}</>
                    </ButtonAsync>
                </FormButtons>
            </Form>
        </MainContainer>
    );
};
