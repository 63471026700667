import { useLazyQuery } from "@apollo/client";
import gql from "graphql-tag";
import React, { useMemo } from "react";
import { mainApiConfig } from "../../../../configure/mainApiConfig";
import { AsyncLoadOptions, AsyncLoadResult } from "../../../../shared/abstractStore";
import { EditMemberChildrenTabViewModelQuery, EditMemberChildrenTabViewModelQueryVariables } from "../../generated/graphql";
import { Guid } from "guid-string";
import { scheduledClassChildFieldsFragment } from "../../models/ScheduledClassChild";
import { scheduledClassFieldsFragment } from "../../models/ScheduledClass";
import { classLocationFieldsFragment } from "../../models/ClassLocation";
import { termFieldsFragment } from "../../models/Term";
import { classTypeFieldsFragment } from "../../models/ClassType";
import { paymentFieldsFragment } from "../../models/Payment";

/**
 * Get all data required for the EditMemberChildrenTab component from the api.
 * @param options
 */
export function useEditMemberChildrenTabViewModel(childId: string | undefined | null, options: AsyncLoadOptions = {}): AsyncLoadResult<EditMemberChildrenTabViewModelQuery> {
    // Query data we need from the api.
    const [load, { data, loading, error, refetch, }] = useLazyQuery<EditMemberChildrenTabViewModelQuery, EditMemberChildrenTabViewModelQueryVariables>(
        gql`
        query editMemberChildrenTabViewModelQuery ($childId: ID) {
            items: scheduledClassChildren (childId: $childId, includeArchived: true) {
                ...scheduledClassChildFields

                scheduledClass {
                    ...scheduledClassFields
                }
            }

            classLocations {
                ...classLocationFields
            }

            terms {
                ...termFields
            }

            classTypes {
                ...classTypeFields
            }

            payments (childId: $childId) {
                ...paymentFields
            }
        }

        ${scheduledClassChildFieldsFragment}
        ${scheduledClassFieldsFragment}
        ${classLocationFieldsFragment}
        ${termFieldsFragment}
        ${classTypeFieldsFragment}
        ${paymentFieldsFragment}
        `,
        {
            variables: {
                childId: childId ?? Guid.empty,
            },
            fetchPolicy: options.fetchPolicy ?? mainApiConfig.defaultFetchPolicy,
            pollInterval: options.pollInterval,
            onCompleted: (data) => {
                if (options.onCompleted) {
                    options.onCompleted(data);
                }
            }
        }
    );

    // Run the query (unless we are being lazy).
    React.useEffect(() => {
        if (!options.lazy) {
            load();
        }
    }, [options.lazy, load]);

    // Return the results.
    const refresh = React.useCallback(async () => { !refetch ? await load() : await refetch(); }, [refetch, load]);
    return useMemo(() => ({
        data: data ?? ({} as any),
        refresh: refresh,
        isLoading: loading,
        errors: error,
    }), [data, refresh, loading, error,]);
};