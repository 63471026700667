import * as React from 'react';
import { useLazyQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { AsyncLoadOptions, AsyncLoadResult } from '../../../../shared/abstractStore';
import { EditStaffViewModelQuery, EditStaffViewModelQueryVariables } from '../../generated/graphql';
import { mainApiConfig } from '../../../../configure/mainApiConfig';
import { profileFieldsFragment } from '../../models/Profile';
import { Guid } from 'guid-string';
import { staffFieldsFragment } from '../../models/Staff';
import { staffQualificationCheckFieldsFragment } from '../../models/StaffQualificationCheck';
import { teacherClassTypeFieldsFragment } from '../../models/TeacherClassType';
import { emergencyContactFieldsFragment } from '../../models/EmergencyContact';

/**
 * Get a all model data required for the EditProfile component.
 * @param id
 * @param options
 */
export function useEditStaffViewModel(id: string | undefined | null, options: AsyncLoadOptions = {}): AsyncLoadResult<EditStaffViewModelQuery> {
    // Query data we need from the api.
    const [load, { data, loading, error, refetch, }] = useLazyQuery<EditStaffViewModelQuery, EditStaffViewModelQueryVariables>(
        gql`
        query editStaffViewModelQuery ($id: ID!) {
            model: profile (id: $id) {
                ...profileFields
            }

            staff (id: $id) {
                ...staffFields
            }

            staffQualificationChecks (staffId: $id) {
                ...staffQualificationCheckFields
            }

            teacherClassTypes (staffId: $id) {
                ...teacherClassTypeFields
            }

            emergencyContacts (targetId: $id) {
                ...emergencyContactFields
            }
        }

        ${profileFieldsFragment}
        ${staffFieldsFragment}
        ${staffQualificationCheckFieldsFragment}
        ${teacherClassTypeFieldsFragment}
        ${emergencyContactFieldsFragment}
        `,
        {
            variables: {
                id: id ?? Guid.empty,
            },
            fetchPolicy: options.fetchPolicy ?? mainApiConfig.defaultFetchPolicy,
            pollInterval: options.pollInterval,
            onCompleted: (data) => {
                if (options.onCompleted) {
                    options.onCompleted(data);
                }
            }
        }
    );

    // Run the query (unless we are being lazy).
    React.useEffect(() => {
        if (!options.lazy) {
            load();
        }
    }, [options.lazy, load])

    // Return the results.
    const refresh = React.useCallback(async () => { !refetch ? await load() : await refetch(); }, [refetch, load]);
    return React.useMemo(() => ({
        data: data ?? ({} as any),
        refresh: refresh,
        isLoading: loading,
        errors: error
    }), [data, refresh, loading, error]);
}
