import * as React from 'react';
import { useSaveInStore, SaveInStoreOptions } from '../../../shared/useSaveInStore';
import { useUpdateClassTypeMutation } from './useUpdateClassTypeMutation';
import { ClassType } from '../models/ClassType';
import { useCreateClassTypeMutation } from './useCreateClassTypeMutation';
import { ClassTypeCreateInput, ClassTypeUpdateInput } from '../generated/graphql';

/**
 * Get a callback to save a ClassType in the api using either a create or update.
 */
export function useSaveClassTypeMutation(options: SaveInStoreOptions<ClassType, string> = {}) {
    const [_create, createStatus] = useCreateClassTypeMutation();
    const create = React.useCallback((model: Partial<ClassType>) => _create(model as ClassTypeCreateInput), [_create]);

    const [_update, updateStatus] = useUpdateClassTypeMutation();
    const update = React.useCallback((id: string, changes: Partial<ClassType>) => _update(id, changes as ClassTypeUpdateInput), [_update]);

    return useSaveInStore(
        [create, createStatus],
        [update, updateStatus],
        options
    );
}
