import { useMemo } from "react";
import { ScheduledClassChildEvent } from "../../../../api/main/models/ScheduledClassChildEvent";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { getScheduledClassSummary } from "../../../scheduledClass/getScheduledClassSummary";
import { ConditionalFragment } from "react-conditionalfragment";
import { NoResultsFound } from "../../../shared/noResultsFound/NoResultsFound";
import { AlertOnErrors } from "../../../../shared/alertOnErrors";
import { Badge } from "reactstrap";
import { useEditMemberEventsTabSupportingData } from "../../../../api/main/members/viewModels/useEditMemberEventsTabSupportingData";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export interface EventsTabProps {
    events: Array<ScheduledClassChildEvent> | undefined | null;
}

/**
 * EventsTab for Children
 */
export const EventsTab = (props: EventsTabProps) => {
    const { events } = props;
    const { t } = useTranslation();

    const {
        data: {
            scheduledClasses,
            profiles,
            classLocations,
            classStages,
            classSubStages,
            children,
            terms
        }, isLoading, errors: loadErrors
    } = useEditMemberEventsTabSupportingData();

    // Combine the data
    const allEvents = useMemo(() => events?.map(event => {
        const scheduledClass = scheduledClasses?.find(it => it.id === event.scheduledClassId);
        const oldScheduledClass = scheduledClasses?.find(it => it.id === event.oldScheduledClassId);
        const child = children?.find(it => it.id === event.childId);
        const staff = profiles?.find(it => it.userId === event.userId);
        const term = terms?.find(it => it.id === event.termId);

        return {
            ...event,

            scheduledClass,
            oldScheduledClass,
            child,
            staff,
            term
        };
    }), [events, scheduledClasses, profiles, children, terms]);

    return (
        <>
            <h4>{t('common.events', 'Events')}</h4>

            <AlertOnErrors errors={[loadErrors]} />

            <ConditionalFragment showIf={!isLoading && (!!allEvents && allEvents?.length > 0)}>
                <table className="table table-striped">
                    <thead>
                        <tr>
                            <th>{t('common.dateLabel', 'Date')}</th>
                            <th>{t('common.type', 'Type')}</th>
                            <th>{t('common.isWaitingList', 'Waiting list?')}</th>
                            <th>{t('common.child', 'Child')}</th>
                            <th>{t('common.term', 'Term')}</th>
                            <th>{t('common.scheduledClass', 'Scheduled Class')}</th>
                            <th>{t('common.oldScheduledClass', 'Old Scheduled Class')}</th>
                            <th>{t('common.actionedBy', 'Actioned by')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {allEvents?.map((event, index) => (
                            <tr key={index}>
                                <td>{t('common.dateTime', '{{dateTime, DD/MM/YYYY HH:mm}}', { dateTime: moment(event.eventDate) })}</td>
                                <td>
                                    <Badge color={event.eventType === 'Add' || event.eventType === 'Join' ? 'success' : event.eventType === 'Remove' || event.eventType === 'Leave' ? 'danger' : 'warning'}>{event.eventType}</Badge>
                                </td>
                                <td>{event.isWaitlist ? <FontAwesomeIcon icon="check" color="green" /> : <FontAwesomeIcon icon="xmark" color="red" />}</td>
                                <td>{t('common.fullName', '{{firstName}} {{lastName}}', { firstName: event.child?.firstName, lastName: event.child?.lastName })}</td>
                                <td>{event.term?.name}</td>
                                <td>{getScheduledClassSummary(event.scheduledClass, { classLocation: classLocations?.find(it => it.id === event.scheduledClass?.classLocationId), classStage: classStages?.find(it => it.id === event.scheduledClass?.currentClassStageId), classSubStage: classSubStages?.find(it => it.id === event.scheduledClass?.currentClassSubStageId) })}</td>
                                <td>{event.oldScheduledClass ? getScheduledClassSummary(event.oldScheduledClass, { classLocation: classLocations?.find(it => it.id === event.oldScheduledClass?.classLocationId), classStage: classStages?.find(it => it.id === event.oldScheduledClass?.currentClassStageId), classSubStage: classSubStages?.find(it => it.id === event.oldScheduledClass?.currentClassSubStageId) }) : null}</td>
                                <td>{t('common.fullName', '{{firstName}} {{lastName}}', { firstName: event.staff?.firstName, lastName: event.staff?.lastName })}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </ConditionalFragment>

            <ConditionalFragment showIf={!isLoading && (!allEvents || allEvents?.length === 0)}>
                <NoResultsFound />
            </ConditionalFragment>
        </>
    );
};