import moment from "moment";
import React, { useMemo } from "react";
import { ConditionalFragment } from "react-conditionalfragment";
import { useTranslation } from "react-i18next";
import { Child } from "../../../../api/main/models/Child";
import { dayOfWeekDisplayName } from "../../../../api/main/models/constants/DayOfWeek";
import { PaymentState } from "../../../../api/main/models/constants/PaymentState";
import { useEditChildClassesTabViewModel } from "../../../../api/main/scheduledClassChildren/viewModels/useEditChildClassesTabViewModel";
import { AlertOnErrors } from "../../../../shared/alertOnErrors";
import { CardsOrTable } from "../../../shared/cardsOrTable/CardsOrTable";
import { LoadingIndicator } from "../../../shared/loadingIndicator/LoadingIndicator";
import { NoResultsFound } from "../../../shared/noResultsFound/NoResultsFound";
import { getPaymentStateBadge } from "../../../shared/Utilities/getPaymentStateBadge";

export interface ClassesTabProps {
    model: Child | null | undefined,
}

/**
 * ClassesTab for Children
 */
export const ClassesTab = (props: ClassesTabProps) => {
    const { model } = props;
    const { t } = useTranslation();

    // Load the data
    const {
        data: {
            items: _allItems,
            classLocations,
            classTypes,
            terms,
            payments
        }, errors: loadErrors, isLoading,
    } = useEditChildClassesTabViewModel(model?.id);

    // Combine the data
    const allItems = useMemo(() => _allItems?.map(item => {
        const location = classLocations?.find(it => it.id === item.scheduledClass.classLocationId);
        const term = terms?.find(it => it.id === item.scheduledClass.termId);
        const payment = payments?.find(it => it.scheduledClassId === item.scheduledClassId);

        return {
            ...item,
            location,
            term,
            payment
        };
    }), [classLocations, _allItems, terms, payments]);

    // Render the UI
    //
    return (
        <>
            <AlertOnErrors errors={loadErrors} />

            <h4>{t('editChild.classesTab.heading', 'Classes')}</h4>

            <ConditionalFragment showIf={!!allItems?.length}>
                {
                    classTypes?.map(classType => (
                        <React.Fragment key={classType.id}>
                            <h5>{classType.name}</h5>

                            <CardsOrTable
                                viewMode={"table"}
                                items={allItems.filter(it => it.scheduledClass.classTypeId === classType.id)}
                                tableHeadings={[
                                    t('editChild.classesTab.term.heading', 'Term'),
                                    t('editChild.classesTab.name.heading', 'Location'),
                                    t('editChild.classesTab.dayOfWeek.heading', 'Day of week'),
                                    t('editChild.classesTab.startTime.heading', 'Start time'),
                                    t('editChild.classesTab.endTime.heading', 'End time'),
                                    t('editChild.classesTab.waitingList.heading', 'Waiting list'),
                                    t('editChild.classesTab.joinedClass.heading', 'Joined class'),
                                    t('editChild.classesTab.firstLesson.heading', 'First lesson'),
                                    t('editChild.classesTab.lastLesson.heading', 'Last lesson'),
                                    t('editChild.childrenTab.paymentState.heading', 'Payment state'),
                                    t('editChild.childrenTab.totalAmount.heading', 'Total amount'),
                                    t('editChild.childrenTab.paidAmount.heading', 'Paid amount'),
                                ]}
                                columns={[
                                    // Term
                                    (item, view) => {
                                        return item.term?.name;
                                    },

                                    // Location
                                    (item, view) => {
                                        return item.location?.name;
                                    },

                                    // Day of week
                                    (item, view) => {
                                        return dayOfWeekDisplayName(item?.scheduledClass?.dayOfWeek, t);
                                    },

                                    // Start time
                                    (item, view) => {
                                        return t('editChild.classesTab.timespan.start', '{{startTimeHours, 00}}:{{startTimeMinutes, 00}}', { startTimeHours: item.scheduledClass?.startTimeHours, startTimeMinutes: item.scheduledClass?.startTimeMinutes, });
                                    },

                                    // End time
                                    (item, view) => {
                                        return t('editChild.classesTab.timespan.end', '{{endTimeHours, 00}}:{{endTimeMinutes, 00}}', { endTimeHours: item.scheduledClass?.endTimeHours, endTimeMinutes: item.scheduledClass?.endTimeMinutes, });
                                    },

                                    // Is on WaitingList
                                    (item, view) => {
                                        return item.isOnWaitingList ? "Yes" : "No";
                                    },

                                    // Joined class date
                                    (item, view) => {
                                        return (
                                            <ConditionalFragment showIf={!!item.joinedClassDate}>
                                                {t('common.date', '{{date, DD/MM/YYYY}}', { date: moment(item.joinedClassDate) })}
                                            </ConditionalFragment>
                                        );
                                    },

                                    // First lesson date
                                    (item, view) => {
                                        return (
                                            <ConditionalFragment showIf={!!item.firstLessonDate}>
                                                {t('common.date', '{{date, DD/MM/YYYY}}', { date: moment(item.firstLessonDate) })}
                                            </ConditionalFragment>
                                        );
                                    },

                                    // Last lesson date
                                    (item, view) => {
                                        return (
                                            <ConditionalFragment showIf={!!item.lastLessonDate}>
                                                {t('common.date', '{{date, DD/MM/YYYY}}', { date: moment(item.lastLessonDate) })}
                                            </ConditionalFragment>
                                        );
                                    },

                                    // Payment State
                                    (item, view) => {
                                        return getPaymentStateBadge(item?.paymentState as PaymentState, t);
                                    },

                                    // Total Amount
                                    (item, view) => {
                                        return t('common.euroCurrency', '€{{value}}', { value: item.payment?.totalAmountGross.toFixed(2) });
                                    },

                                    // Paid Amount
                                    (item, view) => {
                                        return t('common.euroCurrency', '€{{value}}', { value: item.payment?.paidAmountGross.toFixed(2) });
                                    },
                                ]}
                            />
                        </React.Fragment>
                    ))
                }

            </ConditionalFragment>

            <ConditionalFragment showIf={!allItems?.length}>
                <NoResultsFound />
            </ConditionalFragment>

            <ConditionalFragment showIf={isLoading}>
                <LoadingIndicator fullWidth />
            </ConditionalFragment>
        </>
    );
};