import { useMutation } from "@apollo/react-hooks";
import { useAsyncCallback } from 'react-use-async-callback';
import gql from "graphql-tag";
import { AsyncActionStatus } from '../../../shared/abstractStore';
import { StopReenrolmentMutation, StopReenrolmentMutationVariables } from '../generated/graphql';

/**
 * Get a callback to stop a background task on the server that will run a reenrolment process.
 */
export function useStopReenrolmentMutation(): [(termId: string) => Promise<{success: boolean} | undefined | null>, AsyncActionStatus] {
    const [mutationAction, { error }] = useMutation<StopReenrolmentMutation, StopReenrolmentMutationVariables>(
        gql`
            mutation stopReenrolmentMutation ($termId: ID!) {
                stopReenrolment(termId: $termId) {
                    success
                }
            }

        `
    );

    // Wrap the mutation in a standard callback.
    const [action, { isExecuting, errors: actionErrors }] = useAsyncCallback(async (termId: string): Promise<{success: boolean} | undefined | null> => {
        const result = await mutationAction({ variables: { termId: termId } });
        if (result.errors && result.errors.length) {
            throw result.errors;
        }
        return result?.data?.stopReenrolment;
    }, [mutationAction]);

    return [action, { isExecuting: isExecuting, errors: error ? error.message : actionErrors }];
}
