import { ValidationErrors } from "pojo-validator"
import { useCallback } from "react"
import { useTranslation } from "react-i18next"
import { Button, FormGroup, Label, ListGroup } from "reactstrap"
import { ClassStage } from "../../../../api/main/models/ClassStage"
import { ECommerceLink, eCommerceLinkDefaultValues } from "../../../../api/main/models/ECommerceLink"
import { ModelArrayChanges } from "../../../../shared/useChanges"
import { ClassStageECommerceLinkComponent } from "./ClassStageECommerceLinkComponent"

export interface ClassStageECommerceLinksTabProps {
    model: ClassStage | null | undefined,

    eCommerceLinksManager: ModelArrayChanges<ECommerceLink, string>,
    validateECommerceLink: (model: ECommerceLink) => boolean,
    eCommerceLinkValidationErrors: (id: string) => ValidationErrors,
}

/**
 * ECommerceLinksTab for ClassStages
 */
export const ClassStageECommerceLinksTab = (props: ClassStageECommerceLinksTabProps) => {
    const {
        model,
        eCommerceLinksManager,
        validateECommerceLink,
        eCommerceLinkValidationErrors
    } = props;
    const { t } = useTranslation();

    // Add a new ECommerceLink and link it to the parent model
    const addECommerceLink = useCallback(() => {
        eCommerceLinksManager.addFor({ ...eCommerceLinkDefaultValues(), classStageId: model?.id });
    }, [eCommerceLinksManager, model]);

    // Render the UI
    //
    return (
        <>
            <FormGroup>
                <Label htmlFor="eCommerceLinks">
                    {t('editClassStage.eCommerceLinksTab.headingLabel', 'Store links') }
                </Label>

                <ListGroup id="eCommerceLinks">
                    {
                        eCommerceLinksManager.model.map(item => (
                            <ClassStageECommerceLinkComponent
                                model={item}
                                change={changes => eCommerceLinksManager.changeFor(item.id, changes)}
                                remove={() => eCommerceLinksManager.removeFor(item.id)}
                                validate={() => validateECommerceLink(item)}
                                validationErrors={eCommerceLinkValidationErrors(item.id)}
                                key={item.id}
                            />
                        ))
                    }
                </ListGroup>

                <div className="mt-2">
                    <Button color="primary" outline onClick={() => addECommerceLink()}>
                        {t('editClassStage.eCommerceLinksTab.add', 'Add store product link') }
                    </Button>
                </div>
            </FormGroup>
        </>
    )
}