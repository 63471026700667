import gql from 'graphql-tag';
import { Guid } from 'guid-string';
import { ScheduledClassChildFieldsFragment } from '../generated/graphql';
import { PaymentState } from './constants/PaymentState';

export const scheduledClassChildFieldsFragment = gql`
    fragment scheduledClassChildFields on ScheduledClassChild {
        id
        scheduledClassId
        childId
        isOnWaitingList
        firstLessonDate
        lastLessonDate
        joinedClassDate
        joinedWaitingListDate
        isPreferredClass
        paymentState
        offeredPlaceDate
        acceptedOfferedPlaceDate
        archived
        isSelfService
    }
`;

export type ScheduledClassChild = Omit<ScheduledClassChildFieldsFragment, '__typename'>;

/**
 * Default values.
 */
export const scheduledClassChildDefaultValues = (): Partial<ScheduledClassChild> => ({
    id: Guid.newGuid(),
    scheduledClassId: undefined,
    childId: undefined,
    isOnWaitingList: false,
    firstLessonDate: null,
    lastLessonDate: null,
    joinedClassDate: null,
    joinedWaitingListDate: null,
    isPreferredClass: false,
    paymentState: PaymentState.Unpaid,
    offeredPlaceDate: null,
    acceptedOfferedPlaceDate: null,
    archived: false,
    isSelfService: false
});