import * as React from 'react';
import { useSaveInStore, SaveInStoreOptions } from '../../../shared/useSaveInStore';
import { useUpdateScheduledClassCancellationMutation } from './useUpdateScheduledClassCancellationMutation';
import { ScheduledClassCancellation } from '../models/ScheduledClassCancellation';
import { useCreateScheduledClassCancellationMutation } from './useCreateScheduledClassCancellationMutation';
import { ScheduledClassCancellationCreateInput, ScheduledClassCancellationUpdateInput } from '../generated/graphql';

/**
 * Get a callback to save a ScheduledClassCancellation in the api using either a create or update.
 */
export function useSaveScheduledClassCancellationMutation(options: SaveInStoreOptions<ScheduledClassCancellation, string> = {}) {
    const [_create, createStatus] = useCreateScheduledClassCancellationMutation();
    const create = React.useCallback((model: Partial<ScheduledClassCancellation>) => _create(model as ScheduledClassCancellationCreateInput), [_create]);

    const [_update, updateStatus] = useUpdateScheduledClassCancellationMutation();
    const update = React.useCallback((id: string, changes: Partial<ScheduledClassCancellation>) => _update(id, changes as ScheduledClassCancellationUpdateInput), [_update]);

    return useSaveInStore(
        [create, createStatus],
        [update, updateStatus],
        options
    );
}
