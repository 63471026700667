import * as React from 'react';
import { useLazyQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { AsyncLoadPagedResult, AsyncLoadPagedOptions } from '../../../../shared/abstractStore';
import { useFetchMoreCallback } from '../../../../shared/useFetchMoreCallback';
import { mainApiConfig } from '../../../../configure/mainApiConfig';
import { scheduledClassFieldsFragment } from '../../models/ScheduledClass';
import { ClassTimetableViewModelQuery, ClassTimetableViewModelQueryVariables } from '../../generated/graphql';
import { classTypeFieldsFragment } from '../../models/ClassType';
import { classStageFieldsFragment } from '../../models/ClassStage';
import { classSubStageFieldsFragment } from '../../models/ClassSubStage';
import { classLocationFieldsFragment } from '../../models/ClassLocation';
import { scheduledClassStaffFieldsFragment } from '../../models/ScheduledClassStaff';
import { Guid } from 'guid-string';
import { classLocationClassTypeFieldsFragment } from '../../models/ClassLocationClassType';
import { scheduledClassCancellationFieldsFragment } from '../../models/ScheduledClassCancellation';
import { termHolidayFieldsFragment } from '../../models/TermHoliday';
import { staffAbsenceFieldsFragment } from '../../models/StaffAbsence';

/**
 * Get all data required for the ClassTimetable component from the api.
 * @param options
 */
export function useClassTimetableViewModel(termId: string | undefined | null, options: AsyncLoadPagedOptions = {}): AsyncLoadPagedResult<ClassTimetableViewModelQuery> {
    // Query data we need from the api.
    const [load, { data, loading, error, refetch, fetchMore: fetchMoreFromStore }] = useLazyQuery<ClassTimetableViewModelQuery, ClassTimetableViewModelQueryVariables>(
        gql`
        query classTimetableViewModelQuery ($offset: Int, $first: Int, $termId: ID!) {
            items: scheduledClasses (offset: $offset, first: $first, termId: $termId) {
                ...scheduledClassFields

                numberOfSpacesForCurrentTerm
            }

            classTypes {
                ...classTypeFields
            }

            classStages {
                ...classStageFields
            }

            classSubStages {
                ...classSubStageFields
            }

            classLocations {
                ...classLocationFields
            }

            scheduledClassStaffs {
                ...scheduledClassStaffFields
            }

            profiles (isStaff: true) {
                id
                userId
                firstName
                lastName
                archived
                primaryEmail
                secondaryEmail
                primaryPhone
                secondaryPhone
                addressLine1
                addressLine2
                city
                county
                postcode
                country
                genderId
                isStaff
                isMember
            }

            classLocationClassTypes {
                ...classLocationClassTypeFields
            }

            scheduledClassCancellations {
                ...scheduledClassCancellationFields
            }

            termHolidays {
                ...termHolidayFields
            }

            staffAbsences (isApproved: true) {
                ...staffAbsenceFields
            }
        }

        ${scheduledClassFieldsFragment}
        ${classTypeFieldsFragment}
        ${classStageFieldsFragment}
        ${classSubStageFieldsFragment}
        ${classLocationFieldsFragment}
        ${scheduledClassStaffFieldsFragment}
        ${classLocationClassTypeFieldsFragment}
        ${scheduledClassCancellationFieldsFragment}
        ${termHolidayFieldsFragment}
        ${staffAbsenceFieldsFragment}
        `,
        {

            variables: {
                offset: options.startOffset,
                first: options.pageSize,
                termId: termId ?? Guid.empty
            },
            fetchPolicy: options.fetchPolicy ?? mainApiConfig.defaultFetchPolicy,
            pollInterval: options.pollInterval,
            onCompleted: (data) => {
                if (options.onCompleted) {
                    options.onCompleted(data);
                }
            }
        }
    );

    // Run the query (unless we are being lazy).
    React.useEffect(() => {
        if (!options.lazy) {
            load();
        }
    }, [options.lazy, load]);

    // Get the functions used to manage paging.
    const { fetchMore, hasMore: _hasMore } = useFetchMoreCallback<ClassTimetableViewModelQuery>(
        fetchMoreFromStore,
        (options?.startOffset ?? 0) + (data?.items?.length ?? 0),
        (prevResults, newResults) => ({
            ...prevResults,
            ...newResults,

            items: [...(prevResults?.items ?? []), ...(newResults?.items ?? [])],
            classTypes: newResults?.classTypes ?? [],
            classStages: newResults?.classStages ?? [],
            classSubStages: newResults?.classSubStages ?? [],
            classLocations: newResults?.classLocations ?? [],
            profiles: newResults?.profiles ?? [],
            scheduledClassStaffs: newResults?.scheduledClassStaffs ?? [],
            classLocationClassTypes: newResults?.classLocationClassTypes ?? [],
            scheduledClassCancellations: newResults?.scheduledClassCancellations ?? [],
            termHolidays: newResults?.termHolidays ?? [],
            staffAbsences: newResults?.staffAbsences ?? [],
        }),
        (newResults) => newResults?.items?.length
    );
    const hasMore = React.useCallback(() => !options.pageSize ? false : _hasMore(), [options.pageSize, _hasMore]);

    // Return the results.
    const refresh = React.useCallback(async () => { !refetch ? await load() : await refetch(); }, [refetch, load]);
    return React.useMemo(() => ({
        data: data ?? ({} as any),
        refresh: refresh,
        isLoading: loading,
        errors: error,
        fetchMore: fetchMore,
        hasMore: hasMore,
    }), [data, refresh, loading, error, fetchMore, hasMore]);
}