import { IdentityRoles } from "../../../configure/security/IdentityRoles";
import { RouteEntry } from "../../../shared/routes";
import { ClassTimetable } from "./ClassTimetable";


/**
 * Routes that should be added to configuration/routeConfig.ts to provide access to these components via routing.
 */
export const scheduledClassesClassTimetableRoutes: Array<RouteEntry> = [
    { path: '/administration/class-management/timetable', element: <ClassTimetable />, authorize: true, requireRole: IdentityRoles.Administration, },
];
