import gql from 'graphql-tag';
import { Guid } from 'guid-string';
import { ClassTypeFieldsFragment } from '../generated/graphql';

export const classTypeFieldsFragment = gql`
    fragment classTypeFields on ClassType {
        id
        name
        displayOrder
        archived
    }
`;

export type ClassType = Omit<ClassTypeFieldsFragment, '__typename'>;

/**
 * Default values.
 */
export const classTypeDefaultValues = (): Partial<ClassType> => ({
    id: Guid.newGuid(),
    name: '',
    displayOrder: 99,
    archived: false,
});