import { useMutation } from "@apollo/react-hooks";
import { useAsyncCallback } from 'react-use-async-callback';
import gql from "graphql-tag";
import { AsyncActionStatus } from '../../../shared/abstractStore';
import { UpdateClassStageMutation, UpdateClassStageMutationVariables, ClassStageUpdateInput } from '../generated/graphql';
import { classStageFieldsFragment } from '../models/ClassStage';

/**
 * Get a callback to create a ClassStage in the api.
 */
export function useUpdateClassStageMutation(): [(id: string, model: ClassStageUpdateInput) => Promise<void>, AsyncActionStatus] {
    const [mutationAction, { error }] = useMutation<UpdateClassStageMutation, UpdateClassStageMutationVariables>(
        gql`
            mutation updateClassStageMutation ($model: ClassStageUpdateInput!) {
                updateClassStage(model: $model) {
                    ...classStageFields
                }
            }

            ${classStageFieldsFragment}
        `
    );

    // Wrap the mutation in a standard callback.
    const [action, { isExecuting, errors: actionErrors }] = useAsyncCallback(async (id: string, model: ClassStageUpdateInput) => {
        const result = await mutationAction({ variables: { model: { ...model, id: id } } });
        if (result.errors && result.errors.length) {
            throw result.errors;
        }
    }, [mutationAction]);

    return [action, { isExecuting: isExecuting, errors: error ? error.message : actionErrors }];
}
