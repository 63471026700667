import { useMutation } from "@apollo/react-hooks";
import { useAsyncCallback } from 'react-use-async-callback';
import gql from "graphql-tag";
import { AsyncActionStatus } from '../../../shared/abstractStore';
import { RemoveFromScheduledClassWaitingListMutation, RemoveFromScheduledClassWaitingListMutationVariables } from '../generated/graphql';
import { scheduledClassChildFieldsFragment } from "../models/ScheduledClassChild";

/**
 * Get a callback to remove a child from the ScheduledClass as an admin.
 */
export function useRemoveFromScheduledClassWaitingListMutation(): [(scheduledClassChildId: string) => Promise<void>, AsyncActionStatus] {
    const [mutationAction, { error }] = useMutation<RemoveFromScheduledClassWaitingListMutation, RemoveFromScheduledClassWaitingListMutationVariables>(
        gql`
            mutation removeFromScheduledClassWaitingListMutation ($scheduledClassChildId: ID!) {
                removeFromScheduledClassWaitingList(scheduledClassChildId: $scheduledClassChildId) {
                    ...scheduledClassChildFields
                }
            }

            ${scheduledClassChildFieldsFragment}
        `
    );

    // Wrap the mutation in a standard callback.
    const [action, { isExecuting, errors: actionErrors }] = useAsyncCallback(async (scheduledClassChildId: string) => {
        const result = await mutationAction({ variables: { scheduledClassChildId, } });
        if (result.errors && result.errors.length) {
            throw result.errors;
        }
    }, [mutationAction]);

    return [action, { isExecuting: isExecuting, errors: error ? error.message : actionErrors }];
}
