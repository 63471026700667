import { Form, Label, FormGroup, Row, Col } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { ValidateCallback } from 'pojo-validator-react';
import { ValidatedInput } from 'pojo-validator-reactstrap';
import { ClassStage } from '../../../api/main/models/ClassStage';
import { ValidationErrors } from 'pojo-validator';
import { ClassType } from '../../../api/main/models/ClassType';
import { TwoValueSwitch } from '../../shared/twoValueSwitch/TwoValueSwitch';
export interface MainTabProps {
    model: ClassStage | null | undefined,
    change: (changes: Partial<ClassStage>) => void,
    validate: ValidateCallback,
    validationErrors: ValidationErrors,
    saveForm: () => Promise<void>,

    classTypes: Array<ClassType> | undefined | null,
    classStages: Array<ClassStage> | undefined | null,
}

/**
 * MainTab for ClassStage.
 */
export const MainTab = (props: MainTabProps) => {
    const {
        model,
        change,
        validate,
        validationErrors,
        saveForm,

        classTypes,
        classStages,
    } = props;
    const { t } = useTranslation();

    // Render the UI
    //
    return (
        <>
            <Form onSubmit={e => { e.preventDefault(); saveForm(); }}>
                <Row>
                    <Col>
                        <FormGroup>
                            <Label htmlFor="name">{t('editClassStage.name.label', 'Name')}</Label>
                            <ValidatedInput name="name" type="text" value={model?.name ?? ''} onChange={e => change({ name: e.currentTarget.value })} onBlur={e => validate('name')} validationErrors={validationErrors['name']} />
                        </FormGroup>
                    </Col>
                    <Col xs={12} sm="2">
                        <FormGroup>
                            <Label htmlFor="displayOrder">{t('editClassStage.displayOrder.label', 'Order')}</Label>
                            <ValidatedInput name="displayOrder" type="number" value={model?.displayOrder ?? ''} onChange={e => change({ displayOrder: e.currentTarget.valueAsNumber })} onBlur={e => validate('displayOrder')} validationErrors={validationErrors['displayOrder']} />
                        </FormGroup>
                    </Col>
                </Row>
                

                <FormGroup>
                    <Label htmlFor="classTypeId">{t('editClassStage.mainTab.classTypeId.label', 'Activity')}</Label>
                    <ValidatedInput name="classTypeId" type="select" value={model?.classTypeId ?? ''} onChange={e => change({ classTypeId: e.currentTarget.value })} onBlur={e => validate('classTypeId')} validationErrors={validationErrors['classTypeId']}>
                        <option value="">{t('editClassStage.mainTab.classTypeId.pleaseSelect', '(Please select an activity)')}</option>
                        {
                            classTypes?.map(item => (
                                <option key={item.id} value={item.id}>{item.name}</option>
                            ))
                        }
                    </ValidatedInput>
                </FormGroup>

                {/* Start and end dates*/}
                <h4>{t('editClassStage.mainTab.ages.heading', 'Ages')}</h4>
                <Row>
                    <Col md="auto">
                        <FormGroup>
                            <Label htmlFor="name">{t('editClassStage.startAgeYears.label', 'Start age')}</Label>
                            <div>
                                <ValidatedInput name="startAgeYears" type="number" value={model?.startAgeYears ?? ''} onChange={e => change({ startAgeYears: e.currentTarget.valueAsNumber })} onBlur={e => validate('startAgeYears')} validationErrors={validationErrors['startAgeYears']} style={{ display: 'inline-block', maxWidth: '60px', textAlign: 'right', }} />
                                <> </>
                                {t('editClassStage.mainTab.yearsText', 'years')}
                                <> </>
                                <ValidatedInput name="startAgeMonths" type="number" value={model?.startAgeMonths ?? ''} onChange={e => change({ startAgeMonths: e.currentTarget.valueAsNumber })} onBlur={e => validate('startAgeMonths')} validationErrors={validationErrors['startAgeMonths']} style={{ display: 'inline-block', maxWidth: '60px', textAlign: 'right', }} min={0} max={11} />
                                <> </>
                                {t('editClassStage.mainTab.monthsText', 'months')}
                            </div>
                        </FormGroup>
                    </Col>

                    <Col md="auto">
                        <FormGroup>
                            <Label htmlFor="name">{t('editClassStage.endAgeMonths.label', 'End age')}</Label>
                            <div>
                                <ValidatedInput name="endAgeYears" type="number" value={model?.endAgeYears ?? ''} onChange={e => change({ endAgeYears: e.currentTarget.valueAsNumber })} onBlur={e => validate('endAgeYears')} validationErrors={validationErrors['endAgeYears']} style={{ display: 'inline-block', maxWidth: '60px', textAlign: 'right', }} min={0} max={11} />
                                <> </>
                                {t('editClassStage.mainTab.yearsText', 'years')}
                                <> </>
                                <ValidatedInput name="endAgeMonths" type="number" value={model?.endAgeMonths ?? ''} onChange={e => change({ endAgeMonths: e.currentTarget.valueAsNumber })} onBlur={e => validate('endAgeMonths')} validationErrors={validationErrors['endAgeMonths']} style={{ display: 'inline-block', maxWidth: '60px', textAlign: 'right', }} />
                                <> </>
                                {t('editClassStage.mainTab.monthsText', 'months')}
                            </div>
                        </FormGroup>
                    </Col>
                </Row>

                <h4>{t('editClassStage.mainTab.nextStepsHeading', 'What happens next?')}</h4>
                <FormGroup>
                    <Label htmlFor="nextClassStageId">{t('editClassStage.nextClassStageId.label', 'Progress to the next class type')}</Label>
                    <ValidatedInput name="nextClassStageId" type="select" value={model?.nextClassStageId ?? ''} onChange={e => change({ nextClassStageId: e.currentTarget.value })} onBlur={e => validate('nextClassStageId')} validationErrors={validationErrors['nextClassStageId']}>
                        <option value="">{t('editClassStage.mainTab.nextClassStageId.noNextStage', '(No next class type)')}</option>
                        {
                            classStages?.map(item => (
                                <option key={item.id} value={item.id}>{item.name}</option>
                            ))
                        }
                    </ValidatedInput>
                </FormGroup>

                <FormGroup>
                    <Label htmlFor="wrapBackToStartOnCompletion">{t('editClassStage.wrapBackToStartOnCompletion.label', 'Reset back to the first stage automatically each time we complete the final stage?')}</Label>
                    <TwoValueSwitch leftLabel="No" rightLabel="Yes" checked={model?.wrapBackToStartOnCompletion ?? false} onChange={(value) => change({ wrapBackToStartOnCompletion: value })} />
                </FormGroup>
            </Form>
        </>
    );
};
