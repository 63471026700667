import * as React from 'react';
import { Button, Row, Col, Spinner, Alert, NavItem, NavLink, Badge } from 'reactstrap';
import { AlertOnErrors } from '../../../shared/alertOnErrors';
import { LoadingIndicator } from '../../shared/loadingIndicator/LoadingIndicator';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MainContainer } from '../../shared/mainContainer/MainContainer';
import { useParams, useNavigate } from 'react-router';
import { useChanges, useChangesArray } from '../../../shared/useChanges';
import { useValidatorCallback } from 'pojo-validator-react';
import { FormButtons } from '../../shared/formButtons/FormButtons';
import { ButtonAsync } from 'reactstrap-buttonasync';
import { useInviteCallback, useChangeAccountEmailCallback, useResendInviteEmailCallback, useCurrentUserRoles } from '../../../api/account';
import { useAsyncCallback } from 'react-use-async-callback';
import { ConditionalFragment } from 'react-conditionalfragment';
import { Banner } from '../../shared/banner/Banner';
import { Profile, profileDefaultValues } from '../../../api/main/models/Profile';
import { useSaveProfileMutation } from '../../../api/main/profiles/useSaveProfileMutation';
import { useChangeUserRoleGroupMutation } from '../../../api/main/users/useChangeUserRoleGroupMutation';
import { useProfileSupportingData } from '../../../api/main/profiles/viewModels/useProfileSupportingData';
import { userDefaultValues } from '../../../api/main/models/User';
import { useEditStaffViewModel } from '../../../api/main/profiles/viewModels/useEditStaffViewModel';
import { Staff, staffDefaultValues } from '../../../api/main/models/Staff';
import { useSaveStaffMutation } from '../../../api/main/staffs/useSaveStaffMutation';
import { MainTab } from './MainTab';
import { BiosTab } from './biosTab/BiosTab';
import { CPDRecommendationsTab } from './cPDRecommendationsTab/CPDRecommendationsTab';
import { PillsNavBar } from '../../shared/pillsNavBar/PillsNavBar';
import { hasAnyValidationErrors } from '../../../utilities/hasAnyValidationErrors';
import { QualificationChecksTab } from './qualificationsChecksTab/QualificationChecksTab';
import { StaffQualificationCheck } from '../../../api/main/models/StaffQualificationCheck';
import { useSaveStaffQualificationCheckMutation } from '../../../api/main/staffQualificationChecks/useSaveStaffQualificationCheckMutation';
import { useDeleteStaffQualificationCheckMutation } from '../../../api/main/staffQualificationChecks/useDeleteStaffQualificationCheck';
import { useValidatorArrayCallback } from '../../../shared/validator-react-contrib/useValidatorArrayCallback';
import { TeacherClassType } from '../../../api/main/models/TeacherClassType';
import { useSaveTeacherClassTypeMutation } from '../../../api/main/teacherClassTypes/useSaveTeacherClassTypeMutation';
import { useDeleteTeacherClassTypeMutation } from '../../../api/main/teacherClassTypes/useDeleteTeacherClassType';
import { EmploymentTab } from './employmentTab/EmploymentTab';
import { hasDatePassed } from '../../../utilities/hasDatePassed';
import { EmergencyContactsTab } from './emergencyContactsTab/EmergencyContactsTab';
import { EmergencyContact } from "../../../api/main/models/EmergencyContact";
import { useSaveEmergencyContactMutation } from "../../../api/main/emergencyContacts/useSaveEmergencyContactMutation";
import { useDeleteEmergencyContactMutation } from "../../../api/main/emergencyContacts/useDeleteEmergencyContact";
import { useBlobReference } from '../../../api/main/blobReferences/useBlobReference';
import { BlobUploadService } from '../../../api/main/blobReferences/BlobUploadService';
import { PhotoComponent } from './photosTab/PhotoComponent';
import { useReplaceSearchParamsEffect, useSearchParams } from '../../../shared/useURLSearchParams';
import { ClassesTab } from './classesTab/ClassesTab';

export interface EditUserProps {
    isCreate?: boolean,
    onCreateDefaultValues?: () => Partial<Profile>,
    defaultRoleGroup?: string,
    filterRoleGroups?: (groups: Array<{ id: string, name: string }>) => Array<{ id: string, name: string }>,
}

/**
 * Create and invite a new staff memeber.
 */
export const CreateStaff = (props: EditUserProps) => (<EditStaff isCreate={true} {...props} />);

/**
 * Edit a staff member (actually a profile).
 */
export const EditStaff = (props: EditUserProps) => {
    const {
        isCreate,
        onCreateDefaultValues,
        defaultRoleGroup,
        filterRoleGroups,
    } = props;

    const { t } = useTranslation();
    const { id } = useParams<{ id: string | undefined; }>();
    const navigate = useNavigate();
    const {
        data: {
            roles: currentUserRoles,
        }
    } = useCurrentUserRoles();

    // Load all data
    const { data: {
        model: storeModel,
        staff: storeStaffModel,
        staffQualificationChecks: storeStaffQualificationChecks,
        teacherClassTypes: storeTeacherClassTypes,
        emergencyContacts,
    }, isLoading: _isLoading, errors: loadErrors } = useEditStaffViewModel(id);

    // Supporting data (dependant on the current user's subscription).
    const { data: {
        roleGroups: storeRoleGroups,
        genders,
        classTypes,
    }, isLoading: isLoadingSupportingData, errors: loadSupportingDataErrors } = useProfileSupportingData();
    const isLoading = _isLoading || isLoadingSupportingData;

    // Model (Profile)
    const { model, change, changes } = useChanges(storeModel, isCreate ? { ...profileDefaultValues(), isStaff: true, ...(onCreateDefaultValues ? onCreateDefaultValues() : {}) } : undefined);
    const [save, { errors: saveErrors }] = useSaveProfileMutation();

    // Model (User)
    const { model: userModel, change: changeUserModel } = useChanges(storeModel?.user, isCreate ? { ...userDefaultValues(), } : {});

    // Model (Staff)
    const { model: staffModel, change: changeStaffModel, changes: staffModelChanges } = useChanges<Staff>(storeStaffModel, isCreate ? { ...staffDefaultValues() } : undefined);
    const [saveStaff, { errors: saveStaffErrors }] = useSaveStaffMutation();

    // RoleGroups
    const { model: roleGroupModel, change: changeRoleGroupModel } = useChanges<{ id: string }>({ id: storeModel?.user?.roleGroup?.id ?? '' }, isCreate ? { id: defaultRoleGroup ?? 'Staff' } : undefined);
    const [changeUserRoleGroupInStore, { errors: changeUserRoleGroupInStoreErrors }] = useChangeUserRoleGroupMutation();
    const roleGroups = React.useMemo(() => {
        if (!filterRoleGroups) {
            return storeRoleGroups;
        }

        return filterRoleGroups(storeRoleGroups ?? []);
    }, [storeRoleGroups, filterRoleGroups]);

    // Invites
    const [invite, { errors: inviteErrors }] = useInviteCallback();

    // Verification
    const [resendInviteEmail, { isExecuting: isResendingInviteEmail, errors: resendInviteEmailErrors }] = useResendInviteEmailCallback();
    const [hasResentInviteEmail, setHasResentInviteEmail] = React.useState<boolean>(false);
    const [changeAccountEmail, { errors: changeEmailErrors }] = useChangeAccountEmailCallback();

    // Staff image upload (photo)
    const { data: { model: image }, errors: imageLoadErrors } = useBlobReference(staffModel?.photoBlobReferenceId);
    const [onUploadImage, { errors: imageUploadErrors, isExecuting: isUploadingImage }] = useAsyncCallback(async (files: FileList | null) => {
        if (!files) {
            return;
        }

        let uploadService: BlobUploadService = new BlobUploadService("/api/blobs");
        let result = await uploadService.upload(files);

        if (!!result) {
            changeStaffModel({ photoBlobReferenceId: result.id });
        }
    }, [change]);

    // Clear image functionality
    const [clearImage, { isExecuting: isClearingImage }] = useAsyncCallback(async () => {
        changeStaffModel({ photoBlobReferenceId: null });
    }, [change])

    // Model TeacherClassTypes 
    const teacherClassTypeManager = useChangesArray<TeacherClassType, string>(storeTeacherClassTypes, item => item.id);
    const [saveTeacherClassType] = useSaveTeacherClassTypeMutation();
    const [removeTeacherClassType] = useDeleteTeacherClassTypeMutation();

    // Model (EmergencyContacts)
    const staffEmergencyContactsManager = useChangesArray<EmergencyContact, string>(emergencyContacts, item => item.id);
    const [saveEmergencyContact] = useSaveEmergencyContactMutation();
    const [removeEmergencyContact] = useDeleteEmergencyContactMutation();

    // StaffQualification validation
    const staffQualificationChecksManager = useChangesArray<StaffQualificationCheck, string>(storeStaffQualificationChecks, item => item.id);
    const [saveStaffQualificationCheck] = useSaveStaffQualificationCheckMutation();
    const [removeStaffQualificationCheck] = useDeleteStaffQualificationCheckMutation();
    const [validateStaffQualificationCheck, staffQualificationCheckValidationErrors] = useValidatorArrayCallback<StaffQualificationCheck>((myModel, validation, fieldsToCheck) => {
        const rules = {
            name: () => !myModel?.name ? t('editStaff.qualificationChecks.errors.name', 'Name cannot be empty') : '',
        };

        validation.checkRules(rules, fieldsToCheck);
    }, []);

    // EmergencyContact validation
    const [validateEmergencyContact, emergencyContactValidationErrors] = useValidatorArrayCallback<EmergencyContact>((emergencyContactModel, validation, fieldsToCheck) => {
        const rules = {
            firstName: () => !emergencyContactModel?.firstName ? t('editStaff.emergencyContact.errors.firstName', 'First name cannot be empty') : '',
            lastName: () => !emergencyContactModel?.lastName ? t('editStaff.emergencyContact.errors.lastName', 'Last name cannot be empty') : '',
            primaryPhone: () => !emergencyContactModel?.primaryPhone ? t('editStaff.emergencyContact.errors.primaryPhone', 'Primary phone number cannot be empty') : '',
            primaryEmail: () => !emergencyContactModel?.primaryEmail ? t('editStaff.emergencyContact.errors.primaryEmail', 'A primary email is required') : '',
        };

        validation.checkRules(rules, fieldsToCheck);
    }, []);

    // Main model validation
    const [validate, validationErrors] = useValidatorCallback((validation, fieldsToCheck) => {
        const rules = {
            firstName: () => !model?.firstName ? t('editUserBase.firstNameRequired', 'First name is required') : '',
            lastName: () => !model?.lastName ? t('editUserBase.lastNameRequired', 'Last name is required') : '',
            email: () => !userModel?.email ? t('editUserBase.emailRequired', 'Email is required') : '',
            genderId: () => !model?.genderId ? t('editUserBase.genderRequired', 'Gender is required') : '',
        };

        validation.checkRules(rules, fieldsToCheck);
    }, [model, userModel]);

    // Save everything
    const [saveForm, { isExecuting: isSaving, errors: saveFormErrors }] = useAsyncCallback(async (options?: { navigateTo?: string, }) => {
        if (!validate()) {
            return;
        }

        // If we are creating this staff, make sure we save the staff and get its id before continuing with saving the profile.
        let userId = model.userId;
        if (isCreate) {
            // Create an invited staff but don't send the invite yet, we'll send it after the profile has been saved to so we can include their name
            // and make it more personal for higher engagement.
            const result = await invite({ ...userModel, sendEmail: false });
            userId = result.userId;
            changeUserModel({ id: userId });
            change({ userId: userId });
            changeStaffModel({ userId: userId });
        }

        // Make sure the id of the Staff record always matches the id of the Profile record.
        if (staffModel.id !== model.id) {
            changeStaffModel({ id: model.id });
        }

        // Save the profile.
        await save(model.id, { ...changes, userId: userId }, !!isCreate);

        // Save the staff record.
        // NOTE we always want the id to match the profile's id so we handle this here.
        // We also will create a staff record  if one doesn't exist,n even if we are not isCreate == true.  To identify this we look for when the staffModel.id is not the same as the model.id.
        await saveStaff(staffModel.id, { ...staffModelChanges, id: model.id, userId: userId }, (!!isCreate || staffModel.id !== model.id));

        //Save changes to StaffQualificationChecks
        for (const item of staffQualificationChecksManager.added) { await saveStaffQualificationCheck(item.id, staffQualificationChecksManager.changesFor(item.id), true); }
        for (const item of staffQualificationChecksManager.updated) { await saveStaffQualificationCheck(item.id, staffQualificationChecksManager.changesFor(item.id), false); }
        for (const item of staffQualificationChecksManager.removed) { await removeStaffQualificationCheck(item.id); }
        staffQualificationChecksManager.markAsSaved();

        //Save changes to teacherClassType
        for (const item of teacherClassTypeManager.added) { await saveTeacherClassType(item.id, teacherClassTypeManager.changesFor(item.id), true); }
        for (const item of teacherClassTypeManager.updated) { await saveTeacherClassType(item.id, teacherClassTypeManager.changesFor(item.id), false); }
        for (const item of teacherClassTypeManager.removed) { await removeTeacherClassType(item.id); }

        // Saves changes to the EmergencyContact
        for (const item of staffEmergencyContactsManager.added) { await saveEmergencyContact(item.id, staffEmergencyContactsManager.changesFor(item.id), true); }
        for (const item of staffEmergencyContactsManager.updated) { await saveEmergencyContact(item.id, staffEmergencyContactsManager.changesFor(item.id), false); }
        for (const item of staffEmergencyContactsManager.removed) { await removeEmergencyContact(item.id); }
        staffEmergencyContactsManager.markAsSaved();

        // If we created a staff invite, send the email now the profile has been saved so it can include more personal information (e.g. their name).
        if (isCreate) {
            await resendInviteEmail(userModel.email);
        }

        // Handle changes to the email address of existing users.
        if (!isCreate && storeModel && storeModel.user.email !== userModel.email) {
            await changeAccountEmail(storeModel.user.email, userModel.email);
        }

        // Handle the role group change.
        if (isCreate || storeModel?.user.roleGroup?.id !== roleGroupModel.id) {
            await changeUserRoleGroupInStore({ userId: userId, roleGroupId: roleGroupModel.id });
        }

        // Navigate to the next screen.
        if (options?.navigateTo) {
            navigate(options.navigateTo);
        } else {
            navigate(-1);
        }
    }, [validate, save, model, changes, isCreate, navigate, userModel, invite, changeUserModel, change,
        changeAccountEmail, resendInviteEmail, staffModelChanges, staffModel, saveStaff]);

    // Manage the tab being displayed.  If we have tab= on the query string, default to it, otherwise default to the main tab.
    const { tab: tabParam } = useSearchParams();
    // Manage the tab being displayed.
    const [activeTab, setActiveTab] = React.useState<'main' | 'employment' | 'photo' | 'bio' | 'cpdRecommendations' | 'qualifications' | 'classes' | 'emergencyContacts'>(tabParam as any || 'main');

    // Keep the URL up to date with the currentTab
    useReplaceSearchParamsEffect({ tab: activeTab === 'main' ? '' : activeTab });

    // Count how many overdue requirement checks we have (if any).
    const overdueStaffQualificationChecksCount = React.useMemo(() => staffQualificationChecksManager.model.filter(item => hasDatePassed(item.reviewDate)).length, [staffQualificationChecksManager]);

    // Render the UI
    //
    return (
        <>
            <Banner>
                <Row>
                    <Col xs={12} md="auto">
                        <h1>
                            {
                                isCreate ? t('editUserBase.editStaffcreateHeading.default', 'Add staff')
                                    : t('editUserBase.editStaff.editHeading.default', 'Edit staff')
                            }
                        </h1>
                    </Col>
                    <Col>
                        <PillsNavBar>
                            <NavItem>
                                <NavLink active={activeTab === 'main'} onClick={() => setActiveTab('main')}>
                                    {t('editStaff.nav.main', 'Staff details')}

                                    {/* Show a warning triangle if any fields for this tab have validation errors */}
                                    <ConditionalFragment showIf={hasAnyValidationErrors([validationErrors['firstName'], validationErrors['lastName'], validationErrors['gender'],])}>
                                        <> </><FontAwesomeIcon icon="exclamation-triangle" color="danger" />
                                    </ConditionalFragment>
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink active={activeTab === 'photo'} onClick={() => setActiveTab('photo')}>
                                    {t('editStaff.nav.photo', 'Photo')}

                                    {/* Show a warning triangle if any fields for this tab have validation errors */}
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink active={activeTab === 'employment'} onClick={() => setActiveTab('employment')}>
                                    {t('editStaff.nav.employment', 'Employment details')}

                                    {/* Show a warning triangle if any fields for this tab have validation errors */}
                                    <ConditionalFragment showIf={hasAnyValidationErrors([validationErrors['startDate'], validationErrors['endDate'],])}>
                                        <> </><FontAwesomeIcon icon="exclamation-triangle" color="danger" />
                                    </ConditionalFragment>
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink active={activeTab === 'bio'} onClick={() => setActiveTab('bio')}>
                                    {t('editStaff.nav.bio', 'Staff bio')}

                                    {/* Show a warning triangle if any fields for this tab have validation errors */}
                                    <ConditionalFragment showIf={hasAnyValidationErrors([validationErrors['name'], validationErrors['startDate'], validationErrors['endDate'],])}>
                                        <> </><FontAwesomeIcon icon="exclamation-triangle" color="danger" />
                                    </ConditionalFragment>
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink active={activeTab === 'cpdRecommendations'} onClick={() => setActiveTab('cpdRecommendations')}>
                                    {t('editStaff.nav.cpdRecommendations', 'CPD')}

                                    {/* Show a warning triangle if any fields for this tab have validation errors */}
                                    <ConditionalFragment showIf={hasAnyValidationErrors([validationErrors['name'], validationErrors['startDate'], validationErrors['endDate'],])}>
                                        <> </><FontAwesomeIcon icon="exclamation-triangle" color="danger" />
                                    </ConditionalFragment>
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink active={activeTab === 'qualifications'} onClick={() => setActiveTab('qualifications')}>
                                    {t('editStaff.nav.qualifications', 'Qualifications')}
                                    <> </>
                                    {
                                        overdueStaffQualificationChecksCount > 0 ? (
                                            <>
                                                <Badge pill color="danger">
                                                    {t('editClassLocation.nav.requirementChecks.overdueCount', '{{count}} overdue', { count: overdueStaffQualificationChecksCount, })}
                                                </Badge>
                                                <> </>
                                                <Badge pill>
                                                    {t('editClassLocation.nav.requirementChecks.totalCount', '{{count}} total', { count: staffQualificationChecksManager.model.length, })}
                                                </Badge>
                                            </>
                                        ) : (
                                            <Badge pill>
                                                    {t('common.integer', '{{count}}', { count: staffQualificationChecksManager.model.length, })}
                                            </Badge>
                                        )
                                    }

                                    {/* Show a warning triangle if any fields for this tab have validation errors */}
                                    <ConditionalFragment showIf={hasAnyValidationErrors([validationErrors['name'], validationErrors['startDate'], validationErrors['endDate'],])}>
                                        <FontAwesomeIcon icon="exclamation-triangle" color="danger" />
                                    </ConditionalFragment>
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink active={activeTab === 'classes'} onClick={() => setActiveTab('classes')}>
                                    {t('editStaff.nav.classes', 'Classes')}

                                    {/* Show a warning triangle if any fields for this tab have validation errors */}
                                    <ConditionalFragment showIf={hasAnyValidationErrors([validationErrors['name'], validationErrors['startDate'], validationErrors['endDate'],])}>
                                        <> </><FontAwesomeIcon icon="exclamation-triangle" color="danger" />
                                    </ConditionalFragment>
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink active={activeTab === 'emergencyContacts'} onClick={() => setActiveTab('emergencyContacts')}>
                                    {t('editStaff.nav.emergencyContacts', 'Emergency contacts')}

                                    {/* Show a warning triangle if any fields for this tab have validation errors */}
                                    <ConditionalFragment showIf={hasAnyValidationErrors([validationErrors['firstName'], validationErrors['lastName'], validationErrors['primaryPhone'], validationErrors['primaryEmail']])}>
                                        <> </><FontAwesomeIcon icon="exclamation-triangle" color="danger" />
                                    </ConditionalFragment>
                                </NavLink>
                            </NavItem>
                        </PillsNavBar>
                    </Col>
                    <ConditionalFragment showIf={isLoading}>
                        <Col xs="auto">
                            <LoadingIndicator size="sm" />
                        </Col>
                    </ConditionalFragment>
                </Row>
            </Banner>

            <MainContainer>
                <AlertOnErrors errors={[
                    loadErrors, loadSupportingDataErrors,
                    saveFormErrors, saveErrors, saveStaffErrors,
                    inviteErrors, resendInviteEmailErrors,
                    changeEmailErrors, changeUserRoleGroupInStoreErrors,
                    imageLoadErrors, imageUploadErrors
                ]} />

                {
                    hasResentInviteEmail ? (
                        <Alert color="success" >
                            <>{t('editUserBase.confirmationEmailHasBeenResent', 'Invite email for this user has been resent.  Please ask the user to check their email to confirm.')} </>
                            <ButtonAsync type="button" color="success" onClick={async e => { e.preventDefault(); await resendInviteEmail(storeModel?.user?.email ?? userModel.email); }}
                                isExecuting={isResendingInviteEmail}
                                executingChildren={<><Spinner size="sm" />{t('common.sending', 'Sending...')}</>}>
                                {t('common.resendEmail', 'Resend email')}
                            </ButtonAsync>
                        </Alert>
                    ) : null
                }

                {
                    activeTab === 'main' ? (
                        <MainTab
                            model={model}
                            change={change}
                            validate={validate}
                            validationErrors={validationErrors}
                            saveForm={saveForm}
                            resendInviteEmail={resendInviteEmail}
                            setHasResentInviteEmail={setHasResentInviteEmail}
                            isResendingInviteEmail={isResendingInviteEmail}
                            genders={genders}
                            userModel={userModel}
                            changeUserModel={changeUserModel}
                            isCreate={isCreate}
                            storeModel={storeModel}
                            key={null} 
                            roleGroups={roleGroups}
                            roleGroupModel={roleGroupModel}
                            changeRoleGroupModel={changeRoleGroupModel}
                            currentUserRoles={currentUserRoles}

                        />
                    ) : activeTab === 'photo' ? (
                        <PhotoComponent
                            saveForm={saveForm}
                            isExecutingImageUpload={isUploadingImage}
                            onImageUpload={onUploadImage}
                            isExecutingClearImage={isClearingImage}
                            imageUploadClickHandler={clearImage}
                            imageUrl={image?.url}
                        />
                    ) : activeTab === 'employment' ? (
                        <EmploymentTab
                            validate={validate}
                            validationErrors={validationErrors}
                            saveForm={saveForm}
                            staffModel={staffModel}
                            model={model}
                            changeStaffModel={changeStaffModel}
                            roleGroupModel={roleGroupModel}
                            isLoading={isLoading}
                            isSaving={isSaving}
                            navigate={navigate}
                            userModel={userModel}
                            changeUserModel={changeUserModel}
                            isCreate={isCreate}
                            changeRoleGroupModel={changeRoleGroupModel}
                            roleGroups={roleGroups}
                            classTypes={classTypes}
                            teacherClassTypeManager={teacherClassTypeManager}
                            key={null}

                        />
                    ): activeTab === 'bio' ? (
                        <BiosTab
                            saveForm={saveForm}
                            staffModel={staffModel}
                            changeStaffModel={changeStaffModel}
                            isCreate={isCreate}
                            key={null}

                            />
                        ) : activeTab === 'cpdRecommendations' ? (
                            <CPDRecommendationsTab
                                saveForm={saveForm}
                                staffModel={staffModel}
                                changeStaffModel={changeStaffModel}
                                userModel={userModel}
                                changeUserModel={changeUserModel}
                                isCreate={isCreate}
                                key={null}

                                />
                            ) : activeTab === 'qualifications' ? (
                                    <QualificationChecksTab
                                        model={model} 
                                        staffQualificationChecksManager={staffQualificationChecksManager}
                                        validateStaffQualificationCheck={validateStaffQualificationCheck}
                                        staffQualificationCheckValidationErrors={staffQualificationCheckValidationErrors}
                                        key={null}
                                        />
                                        ) : activeTab === 'emergencyContacts' ? (
                                            <EmergencyContactsTab
                                                model={staffModel}
                                                staffEmergencyContactsManager={staffEmergencyContactsManager}
                                                validateStaffEmergencyContact={validateEmergencyContact}
                                                staffEmergencyContactValidationErrors={emergencyContactValidationErrors}
                                                key={null}
                                            />
                                            ) : activeTab === 'classes' ? (
                                                    <ClassesTab
                                                        model={staffModel}
                                                    />
                                    ) : null
                }
                <FormButtons>
                    <ConditionalFragment showIf={!isLoading}>
                        <ButtonAsync color="primary" isExecuting={isSaving} onClick={() => saveForm()}
                            executingChildren={<><Spinner size="sm" /> {t('common.saving', 'Saving...')}</>}>
                            <FontAwesomeIcon icon="save" />
                            <> {t('common.save', 'Save')}</>
                        </ButtonAsync>
                    </ConditionalFragment>
                    <Button type="button" color="primary" outline onClick={e => navigate(-1)}>
                        {t('common.cancel', 'Cancel')}
                    </Button>
                </FormButtons>
            </MainContainer>
        </>
    );
};
