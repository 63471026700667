import { ValidationErrors } from "pojo-validator";
import { useTranslation } from "react-i18next";
import { FormGroup, ListGroup } from "reactstrap";
import { ClassStage } from "../../../../api/main/models/ClassStage";
import { ClassSubStage } from "../../../../api/main/models/ClassSubStage";
import { LessonDocument } from "../../../../api/main/models/LessonDocument";
import { ModelArrayChanges } from "../../../../shared/useChanges";
import { ClassStageSubStageLessonDocumentTab } from "./ClassStageSubStageLessonDocumentTab";

export interface ClassStageLessonDocumentsTabProps {
    model: ClassStage | null | undefined,

    lessonDocumentsManager: ModelArrayChanges<LessonDocument, string>,
    validateLessonDocument: (model: LessonDocument) => boolean,
    lessonDocumentValidationErrors: (id: string) => ValidationErrors,

    storeClassSubStages: Array<ClassSubStage> | undefined | null,
}

/**
 * LessonDocumentsTab for ClassStages
 */
export const ClassStageLessonDocumentsTab = (props: ClassStageLessonDocumentsTabProps) => {
    const { model, lessonDocumentsManager, validateLessonDocument, lessonDocumentValidationErrors, storeClassSubStages } = props;
    const { t } = useTranslation();

    // Render the UI
    //
    return (
        <>
            <FormGroup>
                <h4>
                    {t('editClassStage.lessonDocumentsTab.lessonDocuments.heading', 'Class types')}
                </h4>

                <ListGroup id="lessonDocuments">
                    {
                        storeClassSubStages?.map(item => (
                            <ClassStageSubStageLessonDocumentTab key={item.id}
                                model={item}
                                lessonDocumentsManager={lessonDocumentsManager}
                                classStage={model}
                                validateLessonDocument={validateLessonDocument}
                                lessonDocumentValidationErrors={lessonDocumentValidationErrors}
                            />
                        ))
                    }
                </ListGroup>
            </FormGroup>
        </>
    );
};