import moment from "moment";
import { ConditionalFragment } from "react-conditionalfragment";
import { useTranslation } from "react-i18next";
import { Button, ButtonGroup } from "reactstrap";
import { StaffSubmissionAcceptanceState } from "../../api/main/models/constants/StaffSubmissonAcceptanceState";
import { StaffAbsence } from "../../api/main/models/StaffAbsence";
import { ModelArrayChanges } from "../../shared/useChanges";
import { useStaffAbsenceTypeForIcons } from "../staffTimeOff/cards/useStaffAbsenceTypeForIcons";
import { StaffAbsenceWithProfileAndAbsenceType } from "./StaffAbsenceList";

export interface StaffAbsenceItemOutstandingProps {
    model: StaffAbsenceWithProfileAndAbsenceType | null | undefined,
    staffAbsenceManager: ModelArrayChanges<StaffAbsence, string>,
    updateStaffAbsence: (id: string, changes: Partial<StaffAbsence>, isCreate: boolean) => Promise<void>,
    currentUserId: string | null | undefined,
}

/**
 * Individual StaffAbsence item, for Approving / Denying requests
 */
export const StaffAbsenceItemOutstanding = (props: StaffAbsenceItemOutstandingProps) => {
    const { model, staffAbsenceManager, updateStaffAbsence, currentUserId } = props;
    const { t } = useTranslation();

    // Handle approving a StaffAbsence request
    const handleUpdatingAStaffAbsence = (acceptanceState: string) => {
        if (!model || !acceptanceState || !currentUserId) {
            return;
        }

        let changes = {
            acceptanceState: model?.acceptanceState,
            approvedByStaffUserId: currentUserId,
            confirmedDate: moment(Date.now()).toISOString()
        };

        if (acceptanceState === 'approve') {
            changes.acceptanceState = StaffSubmissionAcceptanceState.Approved;
        }

        if (acceptanceState === 'reject') {
            changes.acceptanceState = StaffSubmissionAcceptanceState.Rejected;
        }

        staffAbsenceManager.changeFor(model?.id, changes);

        updateStaffAbsence(model?.id, changes, false);
    };

    // Get the icon to display along with the absenceType
    const icon = useStaffAbsenceTypeForIcons(model?.absenceType?.name ?? '');

    // Render the UI
    //
    return (
        <>
            <tr key={model?.id}>
                <td>
                    {t('common.fullName', '{{firstName}} {{lastName}}', { firstName: model?.profile?.firstName ?? '', lastName: model?.profile?.lastName ?? '' })}
                </td>
                <td>
                    <ConditionalFragment showIf={!!icon}>
                        {icon}
                    </ConditionalFragment>
                    {t('staffAbsenceItemOutstanding.absenceType.value', '{{absenceType}}', { absenceType: model?.absenceType?.name })}
                </td>
                <td>
                    {t('common.date', '{{date, DD/MM/YYYY}}', { date: moment(model?.startDate) })}
                </td>
                <td>
                    {t('common.date', '{{date, DD/MM/YYYY}}', { date: moment(model?.endDate) })}
                </td>
                <td>
                    {t('common.date', '{{date, DD/MM/YYYY}}', { date: moment(model?.submittedDate) })}
                </td>
                <td>
                    {t('staffAbsenceItemOutstanding.acceptanceState.value', '{{acceptanceState}}', { acceptanceState: model?.acceptanceState })}
                </td>
                <td>
                    <ButtonGroup>
                        <Button color="success" onClick={() => handleUpdatingAStaffAbsence("approve")}>
                            {t('common.approve', 'Approve')}
                        </Button>
                        <Button color="danger" onClick={() => handleUpdatingAStaffAbsence("reject")} >
                            {t('common.deny', 'Deny')}
                        </Button>
                    </ButtonGroup>
                </td>
            </tr>
        </>
    );
};