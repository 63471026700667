import gql from 'graphql-tag';
import { Guid } from 'guid-string';
import moment from 'moment';
import { ChildAttendanceFieldsFragment } from '../generated/graphql';

export const childAttendanceFieldsFragment = gql`
    fragment childAttendanceFields on ChildAttendance {
        id
        childId
        scheduledClassId
        termLessonNumber
        lessonDate
        isCourtesyClassBooking
        attended
        attendanceMarkedByUserId
        archived
    }
`;

export type ChildAttendance = Omit<ChildAttendanceFieldsFragment, '__typename'>;

/**
 * Default values.
 */
export const childAttendanceDefaultValues = (): Partial<ChildAttendance> => ({
    id: Guid.newGuid(),
    childId: undefined,
    scheduledClassId: undefined,
    termLessonNumber: 0,
    lessonDate: moment().toISOString(),
    isCourtesyClassBooking: false,
    attended: false,
    attendanceMarkedByUserId: '',
    archived: false,
});