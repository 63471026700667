import { IdentityRoles } from '../../configure/security/IdentityRoles';
import { RouteEntry } from '../../shared/routes';
import { DeletePayment } from './administration/DeletePayment';
import { OutstandingPaymentsList } from './administration/OutstandingPaymentsList';
import { PaymentHistoryList } from './administration/PaymentHistoryList';
import { PaymentReceipt } from './administration/PaymentReceipt';
import { PaymentCheckout } from './checkout/PaymentCheckout';
import { PaymentCheckoutReceipt } from './checkout/PaymentCheckoutReceipt';
import { PaymentCheckoutSuccess } from './checkout/PaymentCheckoutSuccess';
import { MyPayments } from './MyPayments';

/**
 * Routes that should be added to configuration/routeConfig.ts to provide access to these components via routing.
 */
export const paymentsRoutes: Array<RouteEntry> = [
    // Member payment access.
    { path: '/my/payments', element: <MyPayments />, authorize: true, },

    { path: '/my/checkout/:id', element: <PaymentCheckout />, authorize: false, },
    { path: '/my/checkout-complete/:id', element: <PaymentCheckoutSuccess />, authorize: false, },
    { path: '/my/checkout-receipt/:id', element: <PaymentCheckoutReceipt />, authorize: false, },

    // Administrator payment access.
    { path: '/administration/children-management/payment-receipt/:id', element: <PaymentReceipt />, authorize: true, requireRole: IdentityRoles.Administration, },
    { path: '/administration/children-management/payment-breakdown/:id', element: <PaymentReceipt />, authorize: true, requireRole: IdentityRoles.Administration, },
    { path: '/administration/children-management/outstanding-payments/delete/:id', element: <DeletePayment />, authorize: true, requireRole: IdentityRoles.Administration, },
    { path: '/administration/children-management/outstanding-payments', element: <OutstandingPaymentsList />, authorize: true, requireRole: IdentityRoles.Administration, },
    { path: '/administration/children-management/payment-history', element: <PaymentHistoryList />, authorize: true, requireRole: IdentityRoles.Administration, },
];
