import { useTranslation } from 'react-i18next';
import { ConditionalFragment } from 'react-conditionalfragment';
import { Alert, Col, Input, Row, Spinner } from 'reactstrap';
import moment from 'moment';
import { dayOfWeekDisplayName } from '../../../api/main/models/constants/DayOfWeek';
import { PaymentCheckoutViewModelQuery } from '../../../api/main/generated/graphql';
import { useMemo, useState } from 'react';
import { useAsyncCallback } from 'react-use-async-callback';
import { useApplyGiftVoucher } from '../../../api/main/giftVouchers/useApplyGiftVoucher';
import { AlertOnErrors } from '../../../shared/alertOnErrors';
import { ButtonAsync } from 'reactstrap-buttonasync';
import { useTerms } from '../../../api/main/terms/useTerms';
import { ScheduledClassChild } from '../../../api/main/models/ScheduledClassChild';

export interface PaymentCheckoutDetailsProps {
    data: PaymentCheckoutViewModelQuery,
    allowVoucherAdd?: boolean,
    recalculatePayment?: () => Promise<void>,
    scheduledClassChild?: ScheduledClassChild | undefined | null;
}

/**
 * Component that works out the details of the checkout.  This lets us share the layout and calculations between PaymentCheckout and PaymentCheckoutSuccess.
 */
export const PaymentCheckoutDetails = (props: PaymentCheckoutDetailsProps) => {
    const {
        data,
        allowVoucherAdd = false,
        recalculatePayment,
        scheduledClassChild
    } = props;

    const { t } = useTranslation();

    const model = data?.model;

    const {
        data: {
            items: terms
        }, 
    } = useTerms();

    // Callback to apply a voucher to the payment.
    const [giftVoucherSerialNumber, setGiftVoucherSerialNumber] = useState<string>('');
    const [giftVoucherErrorMessage, setGiftVoucherErrorMessage] = useState<string | undefined>();
    const [applyGiftVoucherMutation, { errors: applyGiftVoucherMutationErrors }] = useApplyGiftVoucher();
    const [applyVoucher, { isExecuting: isApplyingVoucher, errors: applyVoucherErrors }] = useAsyncCallback(async () => {
        if (!giftVoucherSerialNumber) {
            return;
        }

        if (!model) {
            return;
        }

        // Try apply the gift voucher.
        const result = await applyGiftVoucherMutation(model?.id, giftVoucherSerialNumber);
        if (!result.success) {
            setGiftVoucherErrorMessage(result.message);
            return;
        }

        // Reset the voucher field, ready for another voucher.
        setGiftVoucherSerialNumber('');
        setGiftVoucherErrorMessage(undefined);

        // Reclaulate the payment as the amount will have changed.
        await recalculatePayment?.();
    }, [model, recalculatePayment, applyGiftVoucherMutation, giftVoucherSerialNumber, setGiftVoucherErrorMessage, setGiftVoucherSerialNumber]);

    const firstLessonDate = useMemo(() => {
        if (!model?.scheduledClass) {
            return undefined;
        }

        // If we have a first lesson date from the scheduled class child, use that.
        if (scheduledClassChild && scheduledClassChild.firstLessonDate) {
            return moment(scheduledClassChild.firstLessonDate);
        } 

        const term = terms?.find(it => it.id === model?.scheduledClass?.termId);

        const startDate = moment(term?.startDate);
       
        // Starting at the start date, work out when the first lesson would be.
        let date = moment(startDate);
        while (date.day() !== model?.scheduledClass?.dayOfWeek) {
            date.add(1, 'day');
        }

        return date;
    }, [model, terms, scheduledClassChild]);

    const lastLessonDate = useMemo(() => {
        if (!firstLessonDate) {
            return undefined;
        }

        const lessonsInTerm = model?.scheduledClass?.lessonsPerTerm ?? 1;

        return moment(firstLessonDate).add(lessonsInTerm - 1, 'weeks');
    }, [firstLessonDate, model]);

    // UI
    return (
        <>
            <Row className="mb-4">
                <ConditionalFragment showIf={!!model?.scheduledClass}>
                    <Col>
                        <h4>{t('paymentCheckout.classDetails.title', 'Class details')}</h4>
                        <div>
                            <ConditionalFragment showIf={!!model?.child}>
                                <div>
                                    <strong>{t('paymentCheckout.classDetails.bearCub.label', 'Bear Cub:')}</strong>
                                    <> </>
                                    {t('common.fullName', '{{firstName}} {{lastName}}', { firstName: model?.child?.firstName, lastName: model?.child?.lastName, })}
                                </div>
                            </ConditionalFragment>
                            <div>
                                <strong>{t('paymentCheckout.classDetails.classType.label', 'Activity:')}</strong>
                                <> </>
                                {model?.scheduledClass?.classType?.name}
                            </div>
                            <div>
                                <strong>{t('paymentCheckout.classDetails.classStage.label', 'Class Stage:')}</strong>
                                <> </>
                                {t('paymentCheckout.classDetails.classStage.value', '{{stage}} {{subStage}}', { stage: model?.scheduledClass?.currentClassStage?.name, subStage: model?.scheduledClass?.currentClassSubStage?.name, })}
                            </div>
                            <div>
                                <strong>{t('paymentCheckout.classDetails.location.label', 'Location:')}</strong>
                                <> </>
                                {model?.scheduledClass?.classLocation?.name}
                            </div>
                            <div>
                                <strong>{t('paymentCheckout.classDetails.day.label', 'Class Day:')}</strong>
                                <> </>
                                {dayOfWeekDisplayName(model?.scheduledClass?.dayOfWeek ?? 0, t)}
                            </div>
                            <div>
                                <strong>{t('paymentCheckout.classDetails.time.label', 'Class Time:')}</strong>
                                <> </>
                                {t('paymentCheckout.classDetails.time.value', '{{startTimeHours, 00}}:{{startTimeMinutes, 00}}', { startTimeHours: model?.scheduledClass?.startTimeHours, startTimeMinutes: model?.scheduledClass?.startTimeMinutes, })}
                            </div>
                            <div>
                                <strong>{t('paymentCheckout.classDetails.term.label', 'Term:')}</strong>
                                <> </>
                                {model?.term?.name}
                            </div>
                            <div>
                                <strong>{t('paymentCheckout.classDetails.termDates.label', 'Term dates:')}</strong>
                                <> </>
                                {t('common.dateLong', '{{date, Do MMMM YYYY}}', { date: moment(firstLessonDate) })} - {t('common.dateLong', '{{date, Do MMMM YYYY}}', { date: moment(lastLessonDate) })}
                            </div>
                            <ConditionalFragment showIf={!!firstLessonDate}>
                                <div>
                                    <strong>{t('paymentCheckout.classDetails.firstLessonDate.label', '{{value}}:', { value: model?.name.includes('Trial') ? 'Trial date' : 'First lesson' })}</strong>
                                    <> </>
                                    {t('paymentCheckout.classDetails.firstLessonDate.value', '{{weekday}} {{date, DD/MM/YYYY}}', { weekday: dayOfWeekDisplayName(model?.scheduledClass?.dayOfWeek ?? 0, t), date: firstLessonDate, })}
                                </div>
                            </ConditionalFragment>
                        </div>
                    </Col>
                </ConditionalFragment>
                <Col>
                    <h4>{t('paymentCheckout.price.title', 'Price: €{{amount, 0.00}}', { amount: model?.totalAmountGross })}</h4>
                    <div>
                        <strong>
                            {
                                !!model?.scheduledClass ? t('paymentCheckout.price.classPrice.label', 'Class price:')
                                    : t('paymentCheckout.price.otherPrice.label', 'Price:')
                            }
                        </strong>
                        <> </>
                        {t('common.euroMoney.amount', '€{{amount, 0.00}}', { amount: model?.totalPreDiscountAmountGross })}
                    </div>
                    {
                        model?.paymentDiscounts?.map(discount => (
                            <div key={discount.id}>
                                <strong>
                                    {
                                        !!discount.generalDiscount ? discount.generalDiscount?.name
                                            : discount.childDiscount ? discount.childDiscount?.name
                                                : t('paymentCheckout.price.discount.unknown', 'Discount')
                                    }<>:</>
                                </strong>
                                <> </>
                                {t('common.euroMoney.amount', '€{{amount, 0.00}}', { amount: 0 - discount.discountAmount })}
                            </div>
                        ))
                    }
                    {
                        model?.giftVouchers?.map(giftVoucher => (
                            <div key={giftVoucher.id}>
                                <strong>
                                    {t('paymentCheckout.giftVoucher.labelWithSerial', 'Gift voucher [{{serialNumber}}]', { serialNumber: giftVoucher.serialNumber })}<>:</>
                                </strong>
                                <> </>
                                {t('common.euroMoney.amount', '€{{amount, 0.00}}', { amount: 0 - giftVoucher.value })}
                            </div>
                        ))
                    }
                    <ConditionalFragment showIf={allowVoucherAdd}>

                        <div className="mt-2">
                            <div>
                                {t('paymentCheckout.giftVoucher.label', 'Have a gift voucher?')}
                            </div>
                            <Row>
                                <Col>
                                    <Input placeholder={t('paymentCheckout.giftVoucher.placholder', 'Enter code')}
                                        value={giftVoucherSerialNumber} onChange={e => setGiftVoucherSerialNumber(e.currentTarget.value)}
                                        onKeyDown={e => {
                                            if (e.key === 'Enter') {
                                                applyVoucher();
                                            }
                                        }}
                                    />
                                </Col>
                                <Col xs="auto">
                                    <ButtonAsync color="secondary" outline
                                        isExecuting={isApplyingVoucher} executingChildren={<><Spinner size="sm" /><> </>{t('paymentCheckout.giftVoucher.addButton.executingText', 'Checking...')}</>}
                                        onClick={applyVoucher}
                                    >
                                        {t('paymentCheckout.giftVoucher.addButton.text', 'Apply gift voucher')}
                                    </ButtonAsync>
                                </Col>
                            </Row>
                            <AlertOnErrors errors={[applyVoucherErrors, applyGiftVoucherMutationErrors,]} />
                            <ConditionalFragment showIf={!!giftVoucherErrorMessage}>
                                <Alert color="danger">
                                    {giftVoucherErrorMessage}
                                </Alert>
                            </ConditionalFragment>
                        </div>
                    </ConditionalFragment>
                </Col>
            </Row>
        </>
    );
};