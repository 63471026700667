import * as React from 'react';
import { useSaveInStore, SaveInStoreOptions } from '../../../shared/useSaveInStore';
import { useUpdateQueryMessageTypeMutation } from './useUpdateQueryMessageTypeMutation';
import { QueryMessageType } from '../models/QueryMessageType';
import { useCreateQueryMessageTypeMutation } from './useCreateQueryMessageTypeMutation';
import { QueryMessageTypeCreateInput, QueryMessageTypeUpdateInput } from '../generated/graphql';

/**
 * Get a callback to save a QueryMessageType in the api using either a create or update.
 */
export function useSaveQueryMessageTypeMutation(options: SaveInStoreOptions<QueryMessageType, string> = {}) {
    const [_create, createStatus] = useCreateQueryMessageTypeMutation();
    const create = React.useCallback((model: Partial<QueryMessageType>) => _create(model as QueryMessageTypeCreateInput), [_create]);

    const [_update, updateStatus] = useUpdateQueryMessageTypeMutation();
    const update = React.useCallback((id: string, changes: Partial<QueryMessageType>) => _update(id, changes as QueryMessageTypeUpdateInput), [_update]);

    return useSaveInStore(
        [create, createStatus],
        [update, updateStatus],
        options
    );
}
