import * as React from 'react';
import { Button, Row, Col, ButtonGroup, ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem, CardTitle, CardSubtitle, Input, Card, CardHeader, CardBody } from 'reactstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { AlertOnErrors } from '../../../shared/alertOnErrors';
import { LoadingIndicator } from '../../shared/loadingIndicator/LoadingIndicator';
import { Waypoint } from 'react-waypoint';
import { useReplaceSearchParamsEffect, useSearchParams } from '../../../shared/useURLSearchParams';
import { useTranslation } from 'react-i18next';
import { SearchInput } from '../../shared/searchInput/SearchInput';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MainContainer } from '../../shared/mainContainer/MainContainer';
import { NoResultsFound } from '../../shared/noResultsFound/NoResultsFound';
import { StickyToolbar } from '../../shared/stickyToolbar/StickyToolbar';
import { useNavigate } from 'react-router';
import { ConditionalFragment } from 'react-conditionalfragment';
import { Banner } from '../../shared/banner/Banner';
import { useToggleStateArray } from 'use-toggle-state';
import { CardsOrTable } from '../../shared/cardsOrTable/CardsOrTable';
import { usePreferredListViewMode } from '../../../globalState/preferredListViewMode/usePreferredListViewMode';
import { CardsOrTableToggle } from '../../shared/cardsOrTable/CardsOrTableToggle';
import { getScheduledClassSummary } from '../getScheduledClassSummary';
import { useScheduledClassesListViewModel } from '../../../api/main/scheduledClasses/viewModels/useScheduledClassesListViewModel';
import { dayOfWeekDisplayName } from '../../../api/main/models/constants/DayOfWeek';
import { useCurrentTerm } from '../../../api/main/terms/useCurrentTerm';
import { useLocalStorage } from '../../../shared/useLocalStorage';
import { ClassScheduleFilterState } from '../schedule/ClassSchedule';
import { TermSelector } from '../../shared/termSelector/TermSelector';

/**
 * List of ScheduledClasses.
 */
export const ScheduledClassesList = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    // Load data.
    const {
        data: {
            items: _allItems,
            classTypes,
            classLocations,
            classStages,
            classSubStages,
            terms,
            classLocationClassTypes
        },
        isLoading: isLoadingData, errors: loadingErrors, fetchMore, hasMore } = useScheduledClassesListViewModel({ pageSize: undefined, });

    // Load the current term
    const {
        data: {
            model: currentTerm
        }, isLoading: isLoadingCurrentTerm, errors: currentTermLoadErrors
    } = useCurrentTerm();
    const isLoading = isLoadingData || isLoadingCurrentTerm;

    // Combine all the loaded data so each item includes its related details.
    const allItems = React.useMemo(() => _allItems?.map(item => {
        const classType = classTypes?.find(it => it.id === item.classTypeId);
        const classLocation = classLocations?.find(it => it.id === item.classLocationId);
        const currentClassStage = classStages?.find(it => it.id === item.currentClassStageId);
        const currentClassSubStage = classSubStages?.find(it => it.id === item.currentClassSubStageId);
        const nextClassStage = classStages?.find(it => it.id === item.nextClassStageId);
        const nextClassSubStage = classSubStages?.find(it => it.id === item.nextClassSubStageId);
        const term = terms?.find(it => it.id === item.termId);

        return {
            ...item,

            classType,
            classLocation,
            currentClassStage,
            currentClassSubStage,
            nextClassStage,
            nextClassSubStage,
            term
        };
    }), [_allItems, classTypes, classLocations, classStages, classSubStages, terms]);

    const [isMenuOpen, toggleMenuOpen] = useToggleStateArray();

    // Use the preferred view mode for lists.
    const [viewMode] = usePreferredListViewMode();

    // Search.
    const { search: searchParam } = useSearchParams();
    const [search, setSearch] = React.useState<string>(searchParam ?? '');

    // Keep the URL up to date with the search text.
    useReplaceSearchParamsEffect({ search: search });

    // Allow filtering (persisted to local storage between sessions).
    const [filterState, setFilterState] = useLocalStorage<ClassScheduleFilterState>('classSchedule.filterState', {});
    const changeFilterState = React.useCallback((changes: Partial<ClassScheduleFilterState>) => setFilterState(prevState => ({
        ...prevState,
        ...changes,
    })), [setFilterState]);

    // Filter the items that can be filtered by other filter choices.
    const classStagesForFilter = React.useMemo(() => classStages?.filter(item => item.classTypeId === filterState.classTypeId), [classStages, filterState]);
    const classSubStagesForFilter = React.useMemo(() => classSubStages?.filter(item => item.classStageId === filterState.classStageId), [classSubStages, filterState]);

    // Filter by the search.
    const items = React.useMemo(() => {
        let ret = (allItems ?? []);

        let lowerSearch = search.toLocaleLowerCase();
        if (lowerSearch) {
            // Just filtering by all string values that arent ids to start with. Most likely wont need them all for search.
            ret = ret.filter(item =>
                (item.classType?.name ?? '').toLocaleLowerCase().indexOf(lowerSearch) >= 0
                || (item.classLocation?.name ?? '').toLocaleLowerCase().indexOf(lowerSearch) >= 0
                || (item.currentClassStage?.name ?? '').toLocaleLowerCase().indexOf(lowerSearch) >= 0
                || (item.currentClassSubStage?.name ?? '').toLocaleLowerCase().indexOf(lowerSearch) >= 0
                || dayOfWeekDisplayName(item.dayOfWeek, t).toLocaleLowerCase().indexOf(lowerSearch) >= 0
                || t('scheduledClassesList.startTime.formmated', '{{hours, 00}}:{{minutes, 00}}', { hours: item.startTimeHours, minutes: item.startTimeMinutes }).toLocaleLowerCase().indexOf(lowerSearch) >= 0
                || t('scheduledClassesList.endTime.formmated', '{{hours, 00}}:{{minutes, 00}}', { hours: item.endTimeHours, minutes: item.endTimeMinutes }).toLocaleLowerCase().indexOf(lowerSearch) >= 0
                || (item.term?.name ?? '').toLocaleLowerCase().indexOf(lowerSearch) >= 0
            );
        }

        if (filterState.classTypeId) {
            ret = ret?.filter(item => item.classTypeId === filterState.classTypeId);
        }

        if (filterState.classStageId) {
            ret = ret?.filter(item => item.currentClassStageId === filterState.classStageId);
        }

        if (filterState.classSubStageId) {
            ret = ret?.filter(item => item.currentClassSubStageId === filterState.classSubStageId);
        }

        if (filterState.classLocationId) {
            ret = ret?.filter(item => item.classLocationId === filterState.classLocationId);
        }

        if (filterState.termId) {
            ret = ret?.filter(item => item.termId === filterState.termId);
        }

        if (filterState.dayOfWeek) {
            ret = ret?.filter(item => item.dayOfWeek.toString() === filterState.dayOfWeek);
        }

        return ret;

    }, [allItems, search, t, filterState]);

    // Showing and hiding UI elements (persisted to local storage between sessions).
    const [isFiltersVisible, _setIsFiltersVisible] = useLocalStorage('classSchedule.isFiltersVisible', false);
    const toggleFiltersVisible = React.useCallback(() => _setIsFiltersVisible(prevState => !prevState), [_setIsFiltersVisible]);
    const resetFilters = React.useCallback(() => setFilterState({ termId: currentTerm?.id }), [setFilterState, currentTerm?.id]);

    // Filtering the ClassLocations -> If viewing a specific class type, only show the locations that have that class type.
    const filteredClassLocations = React.useMemo(() => {
        if (!classLocations || !classLocationClassTypes) {
            return;
        }

        // If we aren't filtering by class type, return all class locations.
        if (!filterState?.classTypeId) {
            return classLocations;
        }

        // Otherwise, return only the class locations that have the class type.
        return classLocations.filter(classLocation => {
            const matchingClassTypes = classLocationClassTypes.filter(classLocationClassType => {
                return classLocationClassType.classLocationId === classLocation.id && classLocationClassType.classTypeId === filterState.classTypeId;
            });

            return matchingClassTypes.length > 0;
        });
    }, [filterState, classLocations, classLocationClassTypes]);

    // Set the default filter state to the current term.
    React.useEffect(() => {
        if (!filterState.termId) {
            setFilterState({ termId: currentTerm?.id });
        }
    }, [filterState, currentTerm?.id, setFilterState]);

    // Render the UI.
    //
    return (
        <>
            <Banner fluid>
                <StickyToolbar>
                    <Row>
                        <Col xs={12} sm="auto">
                            <h1>
                                {t('scheduledClassesList.title', 'Classes')}
                            </h1>
                            <h4>{terms?.find(it => it.id === filterState?.termId)?.name}</h4>
                        </Col>
                        <Col>
                            <Button color="primary" outline={!isFiltersVisible} onClick={() => toggleFiltersVisible()}>
                                <FontAwesomeIcon icon="filter" />
                                <> </>
                                {t('scheduledClassesList.toggleFilters', 'Filter')}
                            </Button>
                        </Col>
                        <ConditionalFragment showIf={isLoading}>
                            <Col xs="auto">
                                <LoadingIndicator size="sm" />
                            </Col>
                        </ConditionalFragment>
                        <Col xs="" md="auto">
                            <ButtonGroup>
                                <LinkContainer to={'add'}>
                                    <Button color="primary">
                                        <FontAwesomeIcon icon="plus" /><> {t('scheduledClassesList.add', 'Add a new class')}</>
                                    </Button>
                                </LinkContainer>
                            </ButtonGroup>
                        </Col>

                    </Row>

                    {/* Filters */}
                    <ConditionalFragment showIf={isFiltersVisible}>
                        <Card className="mb-2">
                            <CardHeader>
                                <h5>{t('scheduledClassesList.filters.heading', 'Filter')}</h5>
                            </CardHeader>
                            <CardBody>
                                <Row>
                                    <Col xs="auto">
                                        <TermSelector selectedTermId={filterState.termId} setFilterState={({ termId: value }) => setFilterState({ termId: value })} restrictToTermsWithClasses={true} showPreviousTerms={true} />
                                    </Col>
                                    <Col xs="auto">
                                        <Input type="select" value={filterState.dayOfWeek ?? ''} onChange={e => changeFilterState({ dayOfWeek: e.currentTarget.value, })}>
                                            <option value="">{t('scheduledClassesList.filter.dayOfWeek.all', '(All days)')}</option>
                                            <option value="0">{t('scheduledClassesList.filter.dayOfWeek.sunday', 'Sunday')}</option>
                                            <option value="1">{t('scheduledClassesList.filter.dayOfWeek.monday', 'Monday')}</option>
                                            <option value="2">{t('scheduledClassesList.filter.dayOfWeek.tuesday', 'Tuesday')}</option>
                                            <option value="3">{t('scheduledClassesList.filter.dayOfWeek.wednesday', 'Wednesday')}</option>
                                            <option value="4">{t('scheduledClassesList.filter.dayOfWeek.thursday', 'Thursday')}</option>
                                            <option value="5">{t('scheduledClassesList.filter.dayOfWeek.friday', 'Friday')}</option>
                                            <option value="6">{t('scheduledClassesList.filter.dayOfWeek.saturday', 'Saturday')}</option>
                                        </Input>
                                    </Col>
                                    <Col>
                                        <Input type="select" value={filterState.classTypeId ?? ''} onChange={e => changeFilterState({ classTypeId: e.currentTarget.value, classStageId: '', classSubStageId: '', })}>
                                            <option value="">{t('scheduledClassesList.filter.classTypes.all', '(All activities)')}</option>
                                            {
                                                classTypes?.map(item => (
                                                    <option key={item.id} value={item.id}>{item.name}</option>
                                                ))
                                            }
                                        </Input>
                                    </Col>
                                    <ConditionalFragment showIf={!!filterState.classTypeId}>
                                        <Col>
                                            <Input type="select" value={filterState.classStageId ?? ''} onChange={e => changeFilterState({ classStageId: e.currentTarget.value, classSubStageId: '', })}>
                                                <option value="">{t('scheduledClassesList.filter.classStages.all', '(All class types)')}</option>
                                                {
                                                    classStagesForFilter?.map(item => (
                                                        <option key={item.id} value={item.id}>{item.name}</option>
                                                    ))
                                                }
                                            </Input>
                                        </Col>
                                    </ConditionalFragment>
                                    <ConditionalFragment showIf={!!filterState.classStageId}>
                                        <Col>
                                            <Input type="select" value={filterState.classSubStageId ?? ''} onChange={e => changeFilterState({ classSubStageId: e.currentTarget.value })}>
                                                <option value="">{t('scheduledClassesList.filter.classSubStages.all', '(All stages)')}</option>
                                                {
                                                    classSubStagesForFilter?.map(item => (
                                                        <option key={item.id} value={item.id}>{item.name}</option>
                                                    ))
                                                }
                                            </Input>
                                        </Col>
                                    </ConditionalFragment>
                                    <Col>
                                        <Input type="select" value={filterState.classLocationId ?? ''} onChange={e => changeFilterState({ classLocationId: e.currentTarget.value })}>
                                            <option value="">{t('scheduledClassesList.filter.classLocations.all', '(All locations)')}</option>
                                            {
                                                filteredClassLocations?.map(item => (
                                                    <option key={item.id} value={item.id}>{item.name}</option>
                                                ))
                                            }
                                        </Input>
                                    </Col>
                                </Row>
                                <Button color="link" onClick={resetFilters}>
                                    {t('scheduledClassesList.filters.reset', 'Reset filters')}
                                </Button>
                            </CardBody>
                        </Card>
                    </ConditionalFragment>

                    <Row>
                        <Col>
                            <SearchInput value={search} onChange={e => setSearch(e.currentTarget.value)} />
                        </Col>
                        <Col xs="auto">
                            <CardsOrTableToggle />
                        </Col>
                    </Row>
                </StickyToolbar>
            </Banner>
            <MainContainer fluid>
                <AlertOnErrors errors={[
                    loadingErrors,
                    currentTermLoadErrors
                ]} />

                <CardsOrTable
                    viewMode={viewMode}
                    items={items}
                    tableHeadings={[
                        null, /* Handles the card specific function for which we don't want a table column. */
                        t('scheduledClassesList.dayOfWeek.heading', 'Day'),
                        t('scheduledClassesList.startTime.heading', 'Start time'),
                        t('scheduledClassesList.endTime.heading', 'End time'),
                        t('scheduledClassesList.classLocationId.heading', 'Location'),
                        t('scheduledClassesList.classTypeId.heading', 'Activity'),
                        t('scheduledClassesList.currentClassStageId.heading', 'Class type'),
                        t('scheduledClassesList.currentClassSubStageId.heading', 'Stage'),
                        t('scheduledClassesList.currentLessonNumber.heading', 'Current lesson no.'),
                        t('scheduledClassesList.termFee.heading', 'Term fee'),
                    ]}
                    columns={[
                        // We handle the card layout as a specific column ignored from the table.
                        (item, view) => {
                            // This column doesn't show in the table.
                            if (view !== 'cards') {
                                return null;
                            }

                            return (
                                <>
                                    <CardTitle tag="h5">{getScheduledClassSummary(item, { classLocation: item.classLocation, })}</CardTitle>
                                    <CardSubtitle>
                                        {t(
                                            'scheduledClassesList.subtitle',
                                            '{{classTypeName}} / {{currentClassStageName}} / {{currentClassSubStageName}}',
                                            {
                                                classTypeName: item.classType?.name,
                                                currentClassStageName: item.currentClassStage?.name,
                                                currentClassSubStageName: item.currentClassSubStage?.name,
                                            }
                                        )}
                                    </CardSubtitle>
                                    <div>
                                        {t(
                                            'scheduledClassesList.timespan',
                                            '{{startTimeHours, 00}}:{{startTimeMinutes, 00}} to {{endTimeHours, 00}}:{{endTimeMinutes, 00}}',
                                            {
                                                startTimeHours: item.startTimeHours,
                                                startTimeMinutes: item.startTimeMinutes,
                                                endTimeHours: item.endTimeHours,
                                                endTimeMinutes: item.endTimeMinutes,
                                            }
                                        )}
                                    </div>

                                    <div>
                                        {t('common.money.euro', '€{{value, 0.00}}', { value: item.lessonsPerTerm * item.costPerLesson })}
                                        <> </>
                                        {t('common.perTerm', 'per term')}
                                    </div>
                                </>
                            );
                        },

                        // The remaining columns are for table mode only.

                        // Day
                        (item, view) => {
                            if (view !== 'table') {
                                return null;
                            }

                            return dayOfWeekDisplayName(item.dayOfWeek, t);
                        },

                        // Start time
                        (item, view) => {
                            if (view !== 'table') {
                                return null;
                            }

                            return t('scheduledClassesList.startTime.formmated', '{{hours, 00}}:{{minutes, 00}}', { hours: item.startTimeHours, minutes: item.startTimeMinutes });
                        },

                        // End time
                        (item, view) => {
                            if (view !== 'table') {
                                return null;
                            }

                            return t('scheduledClassesList.endTime.formmated', '{{hours, 00}}:{{minutes, 00}}', { hours: item.endTimeHours, minutes: item.endTimeMinutes });
                        },

                        // Location
                        (item, view) => {
                            if (view !== 'table') {
                                return null;
                            }

                            return item.classLocation?.name;
                        },

                        // Activity -> formerly Class type
                        (item, view) => {
                            if (view !== 'table') {
                                return null;
                            }

                            return item.classType?.name;
                        },

                        // Class Type -> formerly Stage
                        (item, view) => {
                            if (view !== 'table') {
                                return null;
                            }

                            return item.currentClassStage?.name ?? '';
                        },


                        // Stage -> formerly Sub stage
                        (item, view) => {
                            if (view !== 'table') {
                                return null;
                            }

                            return item.currentClassSubStage?.name ?? '';
                        },

                        // Current lesson number
                        (item, view) => {
                            if (view !== 'table') {
                                return null;
                            }

                            return item.currentLessonNumber;
                        },

                        // Term fee.
                        (item, view) => {
                            if (view !== 'table') {
                                return null;
                            }

                            return t('common.money.euro', '€{{value, 0.00}}', { value: item.lessonsPerTerm * item.costPerLesson });
                        },
                    ]}

                    buttons={(item) => (
                        <ButtonGroup>
                            <LinkContainer to={`edit/${item.id}`}>
                                <Button color="primary">
                                    <FontAwesomeIcon icon="edit" />
                                    <> {t('common.edit', 'Edit')}</>
                                </Button>
                            </LinkContainer>
                            <ButtonDropdown isOpen={isMenuOpen(item.id)} toggle={() => toggleMenuOpen(item.id)}>
                                <DropdownToggle color="primary" caret>
                                    <span className="visually-hidden">{t('common.menuDropdown', 'More')}</span>
                                </DropdownToggle>
                                <DropdownMenu end>
                                    <LinkContainer to={`delete/${item.id}`}>
                                        <DropdownItem className="text-danger">
                                            <FontAwesomeIcon icon="trash" />
                                            <> {t('common.delete', 'Delete')}</>
                                        </DropdownItem>
                                    </LinkContainer>
                                </DropdownMenu>
                            </ButtonDropdown>
                        </ButtonGroup>
                    )}
                    onItemClick={item => navigate(`edit/${item.id}`)}
                />

                <ConditionalFragment showIf={isLoading && !items?.length}>
                    <LoadingIndicator fullWidth />
                </ConditionalFragment>
                <ConditionalFragment showIf={!isLoading && !items?.length}>
                    <NoResultsFound search={search} />
                </ConditionalFragment>
                <ConditionalFragment showIf={!isLoading && hasMore()}>
                    <Waypoint key={items?.length ?? 0} onEnter={fetchMore} />
                    <LoadingIndicator fullWidth />
                </ConditionalFragment>
            </MainContainer>
        </>
    );
};