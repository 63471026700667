import * as React from 'react';
import { useSaveInStore, SaveInStoreOptions } from '../../../shared/useSaveInStore';
import { useUpdateChildAttendanceMutation } from './useUpdateChildAttendanceMutation';
import { ChildAttendance } from '../models/ChildAttendance';
import { useCreateChildAttendanceMutation } from './useCreateChildAttendanceMutation';
import { ChildAttendanceCreateInput, ChildAttendanceUpdateInput } from '../generated/graphql';

/**
 * Get a callback to save a ChildAttendance in the api using either a create or update.
 */
export function useSaveChildAttendanceMutation(options: SaveInStoreOptions<ChildAttendance, string> = {}) {
    const [_create, createStatus] = useCreateChildAttendanceMutation();
    const create = React.useCallback((model: Partial<ChildAttendance>) => _create(model as ChildAttendanceCreateInput), [_create]);

    const [_update, updateStatus] = useUpdateChildAttendanceMutation();
    const update = React.useCallback((id: string, changes: Partial<ChildAttendance>) => _update(id, changes as ChildAttendanceUpdateInput), [_update]);

    return useSaveInStore(
        [create, createStatus],
        [update, updateStatus],
        options
    );
}
