import { useMutation } from "@apollo/react-hooks";
import { useAsyncCallback } from 'react-use-async-callback';
import gql from "graphql-tag";
import { AsyncActionStatus } from '../../../shared/abstractStore';
import { userFieldsFragment } from "../models/User";
import { ChangeUserRoleGroupMutationVariables, ChangeUserRoleGroupMutation, ChangeUserRoleGroupInput } from "../generated/graphql";
import { useResetCurrentUserRolesCallback } from "../../account";

/**
 * Get a callback to change the role grup of a user in the api.
 */
export function useChangeUserRoleGroupMutation(): [(model: ChangeUserRoleGroupInput) => Promise<void>, AsyncActionStatus] {
    const resetCurrentUserRoles = useResetCurrentUserRolesCallback();

    const [mutationAction, { error }] = useMutation<ChangeUserRoleGroupMutation, ChangeUserRoleGroupMutationVariables>(
        gql`
            mutation changeUserRoleGroupMutation ($model: ChangeUserRoleGroupInput!) {
                changeUserRoleGroup(model: $model) {
                    ...userFields
                }
            }

            ${userFieldsFragment}
        `,
        {
            update: () => resetCurrentUserRoles()
        }
    );

    // Wrap the mutation in a standard callback.
    const [action, { isExecuting, errors: actionErrors }] = useAsyncCallback(async (model: ChangeUserRoleGroupInput) => {
        const result = await mutationAction({ variables: { model: model } });
        if (result.errors && result.errors.length) {
            throw result.errors;
        }
    }, [mutationAction]);

    return [action, { isExecuting: isExecuting, errors: error ? error.message : actionErrors }];
}
