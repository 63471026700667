import * as React from 'react';
import { useSaveInStore, SaveInStoreOptions } from '../../../shared/useSaveInStore';
import { useUpdateWelcomePackDocumentMutation } from './useUpdateWelcomePackDocumentMutation';
import { WelcomePackDocument } from '../models/WelcomePackDocument';
import { useCreateWelcomePackDocumentMutation } from './useCreateWelcomePackDocumentMutation';
import { WelcomePackDocumentCreateInput, WelcomePackDocumentUpdateInput } from '../generated/graphql';

/**
 * Get a callback to save a WelcomePackDocument in the api using either a create or update.
 */
export function useSaveWelcomePackDocumentMutation(options: SaveInStoreOptions<WelcomePackDocument, string> = {}) {
    const [_create, createStatus] = useCreateWelcomePackDocumentMutation();
    const create = React.useCallback((model: Partial<WelcomePackDocument>) => _create(model as WelcomePackDocumentCreateInput), [_create]);

    const [_update, updateStatus] = useUpdateWelcomePackDocumentMutation();
    const update = React.useCallback((id: string, changes: Partial<WelcomePackDocument>) => _update(id, changes as WelcomePackDocumentUpdateInput), [_update]);

    return useSaveInStore(
        [create, createStatus],
        [update, updateStatus],
        options
    );
}
