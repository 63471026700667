import { RouteEntry } from "../../shared/routes";
import { IdentityRoles } from "../../configure/security/IdentityRoles";
import { AdministrationIndex } from "./AdministrationIndex";
import { ClassManagementNavigation } from "./ClassManagementNavigation";
import { ChildrenManagementNavigation } from "./ChildrenManagementNavigation";
import { StaffManagementNavigation } from "./StaffManagementNavigation";
import { ClassManagementIndex } from "./ClassManagementIndex";
import { ChildrenManagementIndex } from "./ChildrenManagementIndex";
import { StaffManagementIndex } from "./StaffManagementIndex";

export const administrationRoutes: Array<RouteEntry> = [
    { path: '/administration', element: <AdministrationIndex />, authorize: true, requireRole: IdentityRoles.Administration },
    { path: '/administration/class-management', element: <ClassManagementIndex />, authorize: true, requireRole: IdentityRoles.Administration },
    { path: '/administration/children-management', element: <ChildrenManagementIndex />, authorize: true, requireRole: IdentityRoles.Administration },
    { path: '/administration/staff-management', element: <StaffManagementIndex />, authorize: true, requireRole: IdentityRoles.Administration },
];

export const administrationSidebarRoutes: Array<RouteEntry> = [
    { path: '/administration/class-management/*', element: (<ClassManagementNavigation />), authorize: true, requireRole: IdentityRoles.Administration },
    { path: '/administration/children-management/*', element: (<ChildrenManagementNavigation />), authorize: true, requireRole: IdentityRoles.Administration },
    { path: '/administration/staff-management/*', element: (<StaffManagementNavigation />), authorize: true, requireRole: IdentityRoles.Administration },
];
