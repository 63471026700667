import { Button, Form, Label, FormGroup, Spinner, Input, Row, Col } from 'reactstrap';
import { AlertOnErrors } from '../../shared/alertOnErrors';
import { LoadingIndicator } from '../shared/loadingIndicator/LoadingIndicator';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MainContainer } from '../shared/mainContainer/MainContainer';
import { useParams, useNavigate } from 'react-router';
import { FormButtons } from '../shared/formButtons/FormButtons';
import { ButtonAsync } from 'reactstrap-buttonasync';
import { useAsyncCallback } from 'react-use-async-callback';
import { ConditionalFragment } from 'react-conditionalfragment';
import { Banner } from '../shared/banner/Banner';
import { useDeleteGeneralDiscountMutation } from '../../api/main/generalDiscounts/useDeleteGeneralDiscount';
import { useGeneralDiscount } from '../../api/main/generalDiscounts/useGeneralDiscount';

/**
 * Delete a GeneralDiscount
 */
export const DeleteGeneralDiscount = () => {
    const { t } = useTranslation();
    const { id } = useParams<{ id: string }>();
    const navigate = useNavigate();

    // Load the data
    const { data: { model, }, errors: loadErrors, isLoading, } = useGeneralDiscount(id);

    // Delete the GeneralDiscount.
    const [remove, { errors: removeErrors }] = useDeleteGeneralDiscountMutation();

    // When we submit, delete the GeneralDiscount.
    const [onRemoveFormSubmit, { isExecuting: isRemoving, errors: removeFormErrors }] = useAsyncCallback(async () => {
        await remove(id ?? '');
        navigate(-1);
    }, [remove, navigate]);

    // Render the UI.
    //
    return (
        <>
            <Banner>
                <Row>
                    <Col>
                        <h1>{t('deleteGeneralDiscount.heading', 'Are you sure you want to delete this discount?')}</h1>
                        <h3>{model?.name}</h3>
                    </Col>
                    <ConditionalFragment showIf={isLoading}>
                        <Col xs="auto">
                            <LoadingIndicator size="sm" />
                        </Col>
                    </ConditionalFragment>
                </Row>
            </Banner>

             <MainContainer>
                <AlertOnErrors errors={[loadErrors, removeFormErrors, removeErrors, ]} />

                <Form onSubmit={e => { e.preventDefault(); onRemoveFormSubmit(); }}>
                    <FormGroup>
                        <Label htmlFor="name">{t('deleteGeneralDiscount.name.label', 'Name')}</Label>
                        <Input name="name" type="text" readOnly value={model?.name ?? ''} />
                    </FormGroup>

                    <FormButtons>
                            <ButtonAsync color="danger" isExecuting={isRemoving}
                                executingChildren={<> <Spinner size="sm" /> {t('common.deleting', 'Deleting...')} </>}>
                                <FontAwesomeIcon icon="trash" />
                                <> </>
                                {t('common.delete', 'Delete')}
                            </ButtonAsync>
                        <Button type="button" color="primary" outline onClick={e => navigate(- 1)}>
                            {t('common.cancel', 'Cancel')}
                        </Button>
                    </FormButtons>
                </Form>
            </MainContainer>
        </>
    );

}
