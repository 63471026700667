import moment, { Moment } from "moment";

/**
 * Returns true if the passed in date is in the past.
 */
export function hasDatePassed(date: Moment | string | null | undefined): boolean {
    if (!date) {
        return false;
    }

    const now = moment();
    if (now > moment(date)) {
        return true;
    }

    return false;
}
