import * as React from 'react';
import { Button, Row, Col, ButtonGroup, ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem, CardTitle, Badge, Input, Spinner, Alert } from 'reactstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { AlertOnErrors } from '../../shared/alertOnErrors';
import { LoadingIndicator } from '../shared/loadingIndicator/LoadingIndicator';
import { Waypoint } from 'react-waypoint';
import { useReplaceSearchParamsEffect, useSearchParams } from '../../shared/useURLSearchParams';
import { useTranslation } from 'react-i18next';
import { SearchInput } from '../shared/searchInput/SearchInput';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MainContainer } from '../shared/mainContainer/MainContainer';
import { NoResultsFound } from '../shared/noResultsFound/NoResultsFound';
import { StickyToolbar } from '../shared/stickyToolbar/StickyToolbar';
import { useNavigate } from 'react-router';
import { ConditionalFragment } from 'react-conditionalfragment';
import { Banner } from '../shared/banner/Banner';
import { useToggleStateArray } from 'use-toggle-state';
import { CardsOrTable } from '../shared/cardsOrTable/CardsOrTable';
import { usePreferredListViewMode } from '../../globalState/preferredListViewMode/usePreferredListViewMode';
import { CardsOrTableToggle } from '../shared/cardsOrTable/CardsOrTableToggle';
import { useTerms } from '../../api/main/terms/useTerms';
import moment from 'moment';
import { Term } from '../../api/main/models/Term';
import { useMemo, useState } from 'react';
import { useGenerateScheduledClassesMutation } from '../../api/main/scheduledClasses/viewModels/useGenerateScheduledClassesMutation';
import { ButtonAsync } from 'reactstrap-buttonasync';
import { useAsyncCallback } from 'react-use-async-callback';

/**
 * List of Terms.
 */
export const TermsList = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    // Load data.
    const { data: { items: allItems, }, isLoading, errors: loadingErrors, fetchMore, hasMore } = useTerms({ pageSize: undefined, });
    const [isMenuOpen, toggleMenuOpen] = useToggleStateArray();

    // We want to know today to do some colour coding.
    const today = useMemo(() => moment().startOf('day').toISOString(), []);

    // Work out the current and next term so we can highlight them.
    const { currentTerm, nextTerm } = useMemo(() => {
        // The current term is the most recent term that started today or before.
        const startedTerms = allItems?.filter(it => it.startDate <= today);
        let currentTerm: Term | null | undefined = null;
        if (startedTerms?.length) {
            currentTerm = startedTerms[startedTerms.length - 1];
        }

        // The next term is the term that has not started by today, but will next start.
        const nextTerm = allItems?.find(it => it.startDate > today);

        return { currentTerm, nextTerm };
    }, [allItems, today]);

    // Use the preferred view mode for lists.
    const [viewMode] = usePreferredListViewMode();

    // Search.
    const { search: searchParam } = useSearchParams();
    const [search, setSearch] = React.useState<string>(searchParam ?? '');

    // Keep the URL up to date with the search text.
    useReplaceSearchParamsEffect({ search: search });

    // Preset filter
    const [presetFilter, setPresetFilter] = useState<'future' | 'past' | 'all'>('future');

    // Filter by the search.
    const items = React.useMemo(() => {
        let ret = (allItems ?? []);

        // Apply the preset filter.
        switch (presetFilter) {
            case 'future':
                ret = ret.filter(item => item.endDate >= today);
                break;
            case 'past':
                ret = ret.filter(item => item.endDate <= today);
                break;
            case 'all':
            default:
                // Do nothing, we want to include everything.
                break;
        }

        // Apply the search.
        let lowerSearch = search.toLocaleLowerCase();
        if (lowerSearch) {
            // Just filtering by all string values that arent ids to start with. Most likely wont need them all for search.
            ret = ret.filter(item =>
                item.name.toLocaleLowerCase().indexOf(lowerSearch) >= 0
                || item.startDate.toString().indexOf(lowerSearch) >= 0
                || item.endDate.toString().indexOf(lowerSearch) >= 0
            );
        }

        return ret;
    }, [allItems, search, presetFilter, today]);

    // Generate classes from the previous term.
    const [generateClasses, { isExecuting: isGeneratingClasses, errors: generateClassesErrors }] = useGenerateScheduledClassesMutation();
    const [handleGenerateClasses] = useAsyncCallback(async (termId: string) => {
        const thisTermIndex = items.findIndex(it => it.id === termId);

        // Get the Term prior to this term
        const previousTerm = items[thisTermIndex - 1];

        await generateClasses(previousTerm?.id, termId);
    }, [items]);

    // Render the UI.
    //
    return (
        <>
            <Banner fluid>
                <StickyToolbar>
                    <Row>
                        <Col>
                            <h1>
                                {t('termsList.title', 'Terms')}
                            </h1>
                        </Col>
                        <ConditionalFragment showIf={isLoading}>
                            <Col xs="auto">
                                <LoadingIndicator size="sm" />
                            </Col>
                        </ConditionalFragment>
                        <Col xs="auto">
                            <CardsOrTableToggle />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <SearchInput value={search} onChange={e => setSearch(e.currentTarget.value)} />
                        </Col>
                        <Col xs={12} md="auto">
                            <Input type="select" value={presetFilter} onChange={e => setPresetFilter(e.currentTarget.value as any)}>
                                <option value="future">{t('termsList.presetFilters.future', 'Future terms')}</option>
                                <option value="past">{t('termsList.presetFilters.past', 'Past terms')}</option>
                                <option value="all">{t('termsList.presetFilters.all', 'All terms')}</option>
                            </Input>
                        </Col>
                        <Col xs="auto">
                            <Row>
                                <ButtonGroup>
                                    <LinkContainer to={'add'}>
                                        <Button color="primary">
                                            <FontAwesomeIcon icon="plus" /><> {t('termsList.add', 'Add')}</>
                                        </Button>
                                    </LinkContainer>
                                </ButtonGroup>
                            </Row>
                        </Col>
                    </Row>
                </StickyToolbar>
            </Banner>

            <MainContainer fluid>
                <AlertOnErrors errors={[
                        loadingErrors,
                        generateClassesErrors
                    ]} />

                <ConditionalFragment showIf={isGeneratingClasses}>
                    <Alert color="warning">
                        {t('termsList.generatingClasses.warningMessage', 'Generating classes based on the current term. This process may take some time.')}
                    </Alert>
                </ConditionalFragment>

                <CardsOrTable
                    viewMode={viewMode}
                    items={items}
                    tableHeadings={[
                        null, /* Handles the card specific function for which we don't want a table column. */
                        t('termsList.name.heading', 'Name'),
                        t('termsList.startDate.heading', 'Start date'),
                        t('termsList.endDate.heading', 'End date'),
                        t('termsList.canSelfRegister.heading', 'Registration open'),
                        t('termsList.copiedClassesOnDate.heading', 'Classes copied on')
                    ]}
                    columns={[
                        // We handle the card layout as a specific column ignored from the table.
                        (item, view) => {
                            // This column doesn't show in the table.
                            if (view !== 'cards') {
                                return null;
                            }
                            return (
                                <>
                                    <CardTitle tag="h5" style={{ textDecoration: item.endDate < today ? 'line-through' : '' }}>
                                        {item.name}
                                        <> </>
                                        {
                                            item.id === currentTerm?.id ? (
                                                <Badge color="primary">
                                                    {t('termsList.badge.currentTerm', 'Current term')}
                                                </Badge>
                                            ) : item.id === nextTerm?.id ? (
                                                <Badge color="dark">
                                                    {t('termsList.badge.nextTerm', 'Next term')}
                                                </Badge>
                                            ) : null
                                        }
                                    </CardTitle>
                                    <div>
                                        {t('termsList.date.summary', '{{startDate, DD/MM/YYYY}} to {{endDate, DD/MM/YYYY}}', { startDate: moment(item.startDate), endDate: moment(item.endDate), })}
                                    </div>
                                </>
                            );
                        },

                        // The remaining columns are for table mode only.

                        // Site - a combination of the name and the summary.
                        (item, view) => {
                            if (view !== 'table') {
                                return null;
                            }

                            return (
                                <div style={{ textDecoration: item.endDate < today ? 'line-through' : '' }}>
                                    {item.name}
                                    <> </>
                                    {
                                        item.id === currentTerm?.id ? (
                                            <Badge color="primary">
                                                {t('termsList.badge.currentTerm', 'Current term')}
                                            </Badge>
                                        ) : item.id === nextTerm?.id ? (
                                            <Badge color="dark">
                                                {t('termsList.badge.nextTerm', 'Next term')}
                                            </Badge>
                                        ) : null
                                    }
                                </div>
                            );
                        },

                        // Start date
                        (item, view) => {
                            if (view !== 'table') {
                                return null;
                            }

                            return t('common.date', '{{date, DD/MM/YYYY}}', { date: moment(item.startDate), });
                        },

                        // End date
                        (item, view) => {
                            if (view !== 'table') {
                                return null;
                            }

                            return t('common.date', '{{date, DD/MM/YYYY}}', { date: moment(item.endDate), });
                        },

                        // Can self register
                        (item, view) => {
                            if (view !== 'table') {
                                return null;
                            }

                            return item.canSelfRegister ? t('common.yes', 'Yes') : t('common.no', 'No');
                        },

                        // Copied classes on date
                        (item, view) => {
                            if (view !== 'table') {
                                return null;
                            }

                            return item.copiedClassesOnDate ? t('common.date', '{{date, DD/MM/YYYY}}', { date: moment(item.copiedClassesOnDate), }) : t('common.notCopied', 'Not copied');
                        },
                    ]}

                    buttons={(item) => (
                        <ButtonGroup>
                            <LinkContainer to={`edit/${item.id}`}>
                                <Button color="primary">
                                    <FontAwesomeIcon icon="edit" />
                                    <> {t('common.edit', 'Edit')}</>
                                </Button>
                            </LinkContainer>
                            <ButtonDropdown isOpen={isMenuOpen(item.id)} toggle={() => toggleMenuOpen(item.id)}>
                                <DropdownToggle color="primary" caret>
                                    <span className="visually-hidden">{t('common.menuDropdown', 'More')}</span>
                                </DropdownToggle>
                                <DropdownMenu end>
                                    <ConditionalFragment showIf={nextTerm?.id === item.id && !item.copiedClassesOnDate}>
                                        <DropdownItem className="text-warning">
                                            <ButtonAsync color="link" isExecuting={isGeneratingClasses}
                                                onClick={() => handleGenerateClasses(item.id)}
                                                executingChildren={<><Spinner size="sm" /> {t('termsList.generatingClasses.button', 'Generating classes...')}</>}
                                                style={{ padding: '0', textDecoration: 'none' }}
                                            >
                                                <FontAwesomeIcon icon="copy" />
                                                <> {t('termsList.generateClasses.button', 'Generate classes')}</>
                                            </ButtonAsync>
                                        </DropdownItem>
                                    </ConditionalFragment>
                                    <LinkContainer to={`delete/${item.id}`}>
                                        <DropdownItem className="text-danger">
                                            <FontAwesomeIcon icon="trash" />
                                            <> {t('common.delete', 'Delete')}</>
                                        </DropdownItem>
                                    </LinkContainer>
                                </DropdownMenu>
                            </ButtonDropdown>
                        </ButtonGroup>
                    )}
                    onItemClick={item => navigate(`edit/${item.id}`)}
                />

                <ConditionalFragment showIf={isLoading && !items?.length}>
                    <LoadingIndicator fullWidth />
                </ConditionalFragment>
                <ConditionalFragment showIf={!isLoading && !items?.length}>
                    <NoResultsFound search={search} />
                </ConditionalFragment>
                <ConditionalFragment showIf={!isLoading && hasMore()}>
                    <Waypoint key={items?.length ?? 0} onEnter={fetchMore} />
                    <LoadingIndicator fullWidth />
                </ConditionalFragment>
            </MainContainer>
        </>
    );
};