import { useMemo } from "react";
import { ConditionalFragment } from "react-conditionalfragment";
import { useTranslation } from "react-i18next";
import { dayOfWeekDisplayName } from "../../../../api/main/models/constants/DayOfWeek";
import { Staff } from "../../../../api/main/models/Staff";
import { useEditStaffClassesTabViewModel } from "../../../../api/main/scheduledClassStaffs/viewModels/useEditStaffClassesTabViewModel";
import { AlertOnErrors } from "../../../../shared/alertOnErrors";
import { CardsOrTable } from "../../../shared/cardsOrTable/CardsOrTable";
import { LoadingIndicator } from "../../../shared/loadingIndicator/LoadingIndicator";
import { NoResultsFound } from "../../../shared/noResultsFound/NoResultsFound";

export interface ClassesTabProps {
    model: Staff | null | undefined,
}

export const ClassesTab = (props: ClassesTabProps) => {
    const { model } = props;
    const { t } = useTranslation();

    // Load the data
    const {
        data: {
            items: _allItems,
            classLocations,
        }, errors: loadErrors, isLoading,
    } = useEditStaffClassesTabViewModel(model?.id);

    // Combine the data
    const allItems = useMemo(() => _allItems?.map(item => {
        const location = classLocations?.find(it => it.id === item.scheduledClass.classLocationId);

        return {
            ...item,
            location,
        };
    }), [classLocations, _allItems]);

    // Render the UI
    //
    return (
        <>
            <AlertOnErrors errors={loadErrors} />

            <h4>{t('editStaff.classesTab.heading', 'Classes')}</h4>

            <ConditionalFragment showIf={!!allItems?.length}>
                <CardsOrTable
                    viewMode={"table"}
                    items={allItems}
                    tableHeadings={[
                        t('editStaff.classesTab.name.heading', 'Location'),
                        t('editStaff.classesTab.dayOfWeek.heading', 'Day of week'),
                        t('editStaff.classesTab.startTime.heading', 'Start time'),
                        t('editStaff.classesTab.endTime.heading', 'End time'),
                        t('editStaff.classesTab.roleInClass.heading', 'Role'),
                    ]}
                    columns={[
                        // Name
                        (item, view) => {
                            return item.location?.name;
                        },

                        // Day of week
                        (item, view) => {
                            return dayOfWeekDisplayName(item?.scheduledClass?.dayOfWeek, t);
                        },

                        // Start time
                        (item, view) => {
                            return t('editStaff.classesTab.timespan.start', '{{startTimeHours, 00}}:{{startTimeMinutes, 00}}', { startTimeHours: item?.scheduledClass?.startTimeHours, startTimeMinutes: item?.scheduledClass?.startTimeMinutes, });
                        },

                        // End time
                        (item, view) => {
                            return t('editStaff.classesTab.timespan.end', '{{endTimeHours, 00}}:{{endTimeMinutes, 00}}', { endTimeHours: item?.scheduledClass?.endTimeHours, endTimeMinutes: item?.scheduledClass?.endTimeMinutes, });
                        },

                        // Role in class
                        (item, view) => {
                            return t('editStaff.classesTab.roleInClass.value', '{{roleInClass}}', { roleInClass: item.roleInClass, });
                        },
                    ]}
                />
            </ConditionalFragment>

            <ConditionalFragment showIf={!allItems?.length}>
                <NoResultsFound />
            </ConditionalFragment>

            <ConditionalFragment showIf={isLoading}>
                <LoadingIndicator fullWidth />
            </ConditionalFragment>
        </>
    );
};