import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useMemo } from "react";
import { ConditionalFragment } from "react-conditionalfragment";
import { useTranslation } from "react-i18next";
import { Badge, Collapse } from "reactstrap";
import { useToggleState } from "use-toggle-state";
import { Child } from "../../../../api/main/models/Child";
import { ClassLocation } from "../../../../api/main/models/ClassLocation";
import { ClassStage } from "../../../../api/main/models/ClassStage";
import { ClassSubStage } from "../../../../api/main/models/ClassSubStage";
import { ClassType } from "../../../../api/main/models/ClassType";
import { PaymentState } from "../../../../api/main/models/constants/PaymentState";
import { Payment } from "../../../../api/main/models/Payment";
import { ScheduledClass } from "../../../../api/main/models/ScheduledClass";
import { ScheduledClassChild } from "../../../../api/main/models/ScheduledClassChild";
import { Term } from "../../../../api/main/models/Term";
import { useMembersChildPaymentsTabViewModel } from "../../../../api/main/payments/viewModel/useMembersChildPaymentsTabViewModel";
import { AlertOnErrors } from "../../../../shared/alertOnErrors";
import { LoadingIndicator } from "../../../shared/loadingIndicator/LoadingIndicator";
import { NoResultsFound } from "../../../shared/noResultsFound/NoResultsFound";
import { PaymentComponent } from "./PaymentComponent";
import "./paymentsTab.scss";

export interface PaymentWithDetails extends Payment {
    term: Term | undefined | null;
    child: Child | undefined | null;
    activity: ClassType | undefined | null;
    classStage: ClassStage | undefined | null;
    classSubStage: ClassSubStage | undefined | null;
    scheduledClass: ScheduledClass | undefined | null;
    location: ClassLocation | undefined | null;
    scheduledClassChild: ScheduledClassChild | undefined | null;
}

export interface PaymentsTabProps {
    model: Child | null | undefined,
}

/**
 * PaymentsTab for mebersChild
 */
export const PaymentsTab = (props: PaymentsTabProps) => {
    const { model } = props;

    const { t } = useTranslation();

    // Load the data
    const {
        data: {
            items: _allItems,
            terms,
            children,
            scheduledClasses,
            classTypes,
            classStages,
            classSubStages,
            classLocations,
            scheduledClassChildren
        }, isLoading, errors: loadErrors, refresh
    } = useMembersChildPaymentsTabViewModel(model?.id);

    // Combine the data into a single model
    const allItems = useMemo(() => _allItems?.map(item => {
        const term = terms?.find(t => t.id === item.termId);
        const child = children?.find(p => p.id === item.childId);
        const scheduledClass = scheduledClasses?.find(sc => sc.id === item.scheduledClassId);
        const activity = classTypes?.find(ct => ct.id === scheduledClass?.classTypeId);
        const classStage = classStages?.find(cs => cs.id === scheduledClass?.currentClassStageId);
        const classSubStage = classSubStages?.find(css => css.id === scheduledClass?.currentClassSubStageId);
        const location  = classLocations?.find(cl => cl.id === scheduledClass?.classLocationId);
        const scheduledClassChild = scheduledClassChildren?.find(scc => scc.scheduledClassId === item.scheduledClassId);

        return {
            ...item,
            term,
            child,
            activity,
            classStage,
            classSubStage,
            scheduledClass,
            location,
            scheduledClassChild
        };
    }), [_allItems, terms, children, scheduledClasses, classTypes, classStages, classSubStages, classLocations, scheduledClassChildren]);
    
    // Want the paid items seperate to everything else.
    const { dueItems, historyItems } = useMemo(() => {
        return {
            dueItems: allItems?.filter(item => item.paymentState !== PaymentState.Paid),
            historyItems: allItems?.filter(item => item.paymentState === PaymentState.Paid).reverse(), // Want the newest paid items first.
        };
    }, [allItems]);

    // Toggling of the payment history.
    const [historyIsOpen, toggleHistoryOpen] = useToggleState();

    if (isLoading) {
        return (<LoadingIndicator fullWidth />);
    }

    // Render the UI
    //
    return (
        <div className="payments-tab">
            <AlertOnErrors errors={[loadErrors]} />

            <h6>
                {t('paymentsTab.due.title', 'Outstanding payments')}
                <> </>
                <Badge pill>
                    {t('paymentsTab.due.count', '{{count}}', { count: dueItems?.length })}
                </Badge>
            </h6>
            <div>
                {
                    dueItems?.map(item => (
                        <PaymentComponent
                            payment={item}
                            key={item.id}
                            refresh={refresh}
                        />
                    ))
                }

                <ConditionalFragment showIf={!dueItems?.length}>
                    <NoResultsFound icon={['far', 'face-smile']}>
                        {t('paymentsTab.due.noItemsText', 'There are no outstanding payments for {{firstName}}.', { firstName: model?.firstName })}
                    </NoResultsFound>
                </ConditionalFragment>
            </div>


            <ConditionalFragment showIf={!!historyItems?.length}>
                <h6 onClick={() => toggleHistoryOpen()} style={{ cursor: 'pointer' }}>
                    {t('paymentsTab.history.title', 'Payments history')}
                    <> </>
                    <Badge pill color="success">
                        {t('paymentsTab.history.count', '{{count}}', { count: historyItems?.length })}
                    </Badge>
                    <> </>
                    <FontAwesomeIcon icon={historyIsOpen ? 'caret-up' : 'caret-down'} />
                </h6>
                <Collapse isOpen={historyIsOpen}>
                    {
                        historyItems?.map(item => (
                            <PaymentComponent
                                payment={item}
                                key={item.id}
                                refresh={refresh}
                            />
                        ))
                    }
                </Collapse>
            </ConditionalFragment>
        </div>
    );
};