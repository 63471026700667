import * as React from 'react';
import { useLazyQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { AsyncLoadPagedResult, AsyncLoadPagedOptions } from '../../../../shared/abstractStore';
import { useFetchMoreCallback } from '../../../../shared/useFetchMoreCallback';
import { mainApiConfig } from '../../../../configure/mainApiConfig';
import { queryMessageFieldsFragment } from '../../models/QueryMessage';
import { MembersQueryMessagesViewModelQuery, MembersQueryMessagesViewModelQueryVariables } from '../../generated/graphql';
import { Guid } from 'guid-string';
import { staffFieldsFragment } from '../../models/Staff';
import { profileFieldsFragment } from '../../models/Profile';
import { queryMessageTypeFieldsFragment } from '../../models/QueryMessageType';

/**
 * Get a list of QueryMessages from the api.
 * @param options
 */
export function useMembersQueryMessagesViewModel(id: string | undefined | null, options: AsyncLoadPagedOptions = {}): AsyncLoadPagedResult<MembersQueryMessagesViewModelQuery> {
    // Query data we need from the api.
    const [load, { data, loading, error, refetch, fetchMore: fetchMoreFromStore }] = useLazyQuery<MembersQueryMessagesViewModelQuery, MembersQueryMessagesViewModelQueryVariables>(
        gql`
        query membersQueryMessagesViewModelQuery ($offset: Int, $first: Int, $id: ID!) {
            items: queryMessages (offset: $offset, first: $first, memberId: $id) {
                ...queryMessageFields

                member {
                    photoBlobReferenceId

                    user {
                        profile {
                            firstName
                            lastName
                        }
                    }
                }
            }

            staffs {
                ...staffFields
            }

            profiles (isStaff: true) {
                ...profileFields
            }

            queryMessageTypes {
                ...queryMessageTypeFields
            }
        }

        ${queryMessageFieldsFragment}
        ${staffFieldsFragment}
        ${profileFieldsFragment}
        ${queryMessageTypeFieldsFragment}
        `,
        {

            variables: {
                offset: options.startOffset,
                first: options.pageSize,
                id: id ?? Guid.empty
            },
            fetchPolicy: options.fetchPolicy ?? mainApiConfig.defaultFetchPolicy,
            pollInterval: options.pollInterval,
            onCompleted: (data) => {
                if (options.onCompleted) {
                    options.onCompleted(data);
                }
            }
        }
    );

    // Run the query (unless we are being lazy).
    React.useEffect(() => {
        if (!options.lazy) {
            load();
        }
    }, [options.lazy, load]);

    // Get the functions used to manage paging.
    const { fetchMore, hasMore: _hasMore } = useFetchMoreCallback<MembersQueryMessagesViewModelQuery>(
        fetchMoreFromStore,
        (options?.startOffset ?? 0) + (data?.items?.length ?? 0),
        (prevResults, newResults) => ({
            ...prevResults,
            ...newResults,

            items: [...(prevResults?.items ?? []), ...(newResults?.items ?? [])],
            staffs: [...(prevResults?.staffs ?? []), ...(newResults?.staffs ?? [])],
            profiles: [...(prevResults?.profiles ?? []), ...(newResults?.profiles ?? [])],
            queryMessageTypes: [...(prevResults?.queryMessageTypes ?? []), ...(newResults?.queryMessageTypes ?? [])],
        }),
        (newResults) => newResults?.items?.length
    );
    const hasMore = React.useCallback(() => !options.pageSize ? false : _hasMore(), [options.pageSize, _hasMore]);

    // Return the results.
    const refresh = React.useCallback(async () => { !refetch ? await load() : await refetch(); }, [refetch, load]);
    return React.useMemo(() => ({
        data: data ?? ({} as any),
        refresh: refresh,
        isLoading: loading,
        errors: error,
        fetchMore: fetchMore,
        hasMore: hasMore,
    }), [data, refresh, loading, error, fetchMore, hasMore]);
}