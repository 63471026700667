import { useLazyQuery } from "@apollo/client";
import { AsyncLoadPagedOptions, AsyncLoadPagedResult } from "../../../../shared/abstractStore";
import gql from "graphql-tag";
import React from "react";
import { mainApiConfig } from "../../../../configure/mainApiConfig";
import { useFetchMoreCallback } from "../../../../shared/useFetchMoreCallback";
import { MembersListViewModelQuery, MembersListViewModelQueryVariables } from '../../generated/graphql';
import { memberFieldsFragment } from "../../models/Member";
import { childFieldsFragment } from "../../models/Child";


/**
 * Get a list of profiles from the api for the MembersListView component 
 * @param id
 * @param options
 */
export function useMembersListViewModel(options: AsyncLoadPagedOptions = {}): AsyncLoadPagedResult<MembersListViewModelQuery> {
    // Query the data we need from the api
    const [load, { data, loading, error, refetch, fetchMore: fetchMoreFromStore }] = useLazyQuery<MembersListViewModelQuery, MembersListViewModelQueryVariables>(
        gql`
            query membersListViewModelQuery($offset: Int, $first: Int) {
                items: members (offset: $offset, first: $first) {
                    ...memberFields
                }

                children {
                   ...childFields
                }
            }

            ${memberFieldsFragment}
            ${childFieldsFragment}
        `,
        {

            variables: {
                offset: options.startOffset,
                first: options.pageSize,
            },
            fetchPolicy: options.fetchPolicy ?? mainApiConfig.defaultFetchPolicy,
            pollInterval: options.pollInterval,
            onCompleted: (data) => {
                if (options.onCompleted) {
                    options.onCompleted(data);
                }
            }
        }
    );

    // Run the query (unless we are being lazy).
    React.useEffect(() => {
        if (!options.lazy) {
            load();
        }
    }, [options.lazy, load]);

    //Get the functions used to manage paging.
    const { fetchMore, hasMore: _hasMore } = useFetchMoreCallback<MembersListViewModelQuery>(
        fetchMoreFromStore,
        (options?.startOffset ?? 0) + (data?.items?.length ?? 0),
        (prevResults, newResults) => ({
            ...prevResults,
            ...newResults,

            items: [...(prevResults?.items ?? []), ...(newResults?.items ?? [])],
            children: [...(prevResults?.children ?? []), ...(newResults?.children ?? [])],
        }),
        (newResults) => newResults?.items?.length
    );
    const hasMore = React.useCallback(() => !options.pageSize ? false : _hasMore(), [options.pageSize, _hasMore]);

    // Return the results.
    const refresh = React.useCallback(async () => { !refetch ? await load() : await refetch(); }, [refetch, load]);
    return React.useMemo(() => ({
        data: data ?? ({} as any),
        refresh: refresh,
        isLoading: loading,
        errors: error,
        fetchMore: fetchMore,
        hasMore: hasMore,
    }), [data, refresh, loading, error, fetchMore, hasMore]);
}