import { IdentityRoles } from "../../configure/security/IdentityRoles";
import { RouteEntry } from "../../shared/routes";
import { DeleteChildsRelationship } from "./DeleteChildsRelationship";
import { CreateChildsRelationship, EditChildsRelationship } from "./EditChildsRelationship";
import { ChildsRelationshipsList } from "./ChildsRelationshipsList";

export const childsRelationshipRoutes: Array<RouteEntry> = [
    { path: '/administration/children-management/childs-relationships/add', element: <CreateChildsRelationship />, authorize: true, requireRole: IdentityRoles.Administration, },
    { path: '/administration/children-management/childs-relationships/edit/:id', element: <EditChildsRelationship />, authorize: true, requireRole: IdentityRoles.Administration, },
    { path: '/administration/children-management/childs-relationships/delete/:id', element: <DeleteChildsRelationship />, authorize: true, requireRole: IdentityRoles.Administration, },
    { path: '/administration/children-management/childs-relationships/', element: <ChildsRelationshipsList />, authorize: true, requireRole: IdentityRoles.Administration, },
];