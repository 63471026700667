import { RouteEntry } from "../shared/routes";
import { apiAuthorizationRoutes } from "../components/api-authorization/routes";
import { accountRoutes } from "../components/account/routes";
import { profileRoutes } from "../components/profiles/routes";
import { homeRoutes } from "../components/home/routes";
import { administrationRoutes, administrationSidebarRoutes } from "../components/administration/routes";
import { userRoutes } from "../components/users/routes";
import { PageNotFound } from "../components/pageNotFound/PageNotFound";
import { staffAreaRoutes } from "../components/staffArea/routes";
import { memberAreaRoutes } from "../components/memberArea/routes";
import { gendersRoutes } from "../components/genders/routes";
import { termsRoutes } from "../components/terms/routes";
import { childsRelationshipRoutes } from "../components/childsRelationships/routes";
import { cancellationReasonRoutes } from '../components/cancellationReasons/routes';
import { classTypesRoutes } from '../components/classTypes/routes';
import { staffAbsenceTypesRoutes } from '../components/staffAbsenceTypes/routes';
import { classLocationsRoutes } from '../components/classLocations/routes';
import { generalDiscountsRoutes } from '../components/generalDiscounts/routes';
import { scheduledClassesClassScheduleRoutes } from "../components/scheduledClass/schedule/routes";
import { staffRoutes } from "../components/staffs/routes";
import { scheduledClassesAdministrationRoutes } from "../components/scheduledClass/administration/routes";
import { classStagesRoutes } from "../components/classStages/routes";
import { memberRoutes } from "../components/members/routes";
import { childrenRoutes } from "../components/children/routes";
import { lessonPlansRoutes } from "../components/lessonPlans/routes";
import { staffTimeOffRoutes } from "../components/staffTimeOff/routes";
import { staffAbsencesRoutes } from "../components/holidaysAndSickness/routes";
import { staffQualificationsRoutes } from "../components/staffQualifications/routes";
import { staffClassesRoutes } from "../components/staffClasses/routes";
import { classRegistersRoutes } from "../components/classRegisters/routes";
import { cancelClassRoutes } from "../components/cancelClass/routes";
import { cancelledClassesRoutes } from "../components/cancelledClasses/routes";
import { classWaitingListsRoutes } from "../components/classWaitingLists/routes";
import { membersChildrenRoutes } from "../components/membersChildren/routes";
import { staffQueryMessagesRoutes } from "../components/staffQueryMessages/routes";
import { membersQueryMessagesRoutes } from "../components/membersQueryMessages/routes";
import { adminQueryMessagesRoutes } from "../components/adminQueryMessages/routes";
import { arrangeCoverRoutes } from "../components/arrangeCover/routes";
import { membersClassFinderRoutes } from "../components/membersClassFinder/routes";
import { holidaysAndSicknessRoutes } from "../components/membersChildren/holidaysAndSickness/routes";
import { childAbsenceTypesRoutes } from "../components/childAbsenceTypes/routes";
import { classFinderRoutes } from "../components/classFinder/routes";
import { staffChildDetailsRoutes } from "../components/staffChildDetails/routes";
import { giftVouchersRoutes } from "../components/giftVouchers/routes";
import { dashboardRoutes } from "../components/dashboard/routes";
import { reenrolmentRoutes } from "../components/reenrolments/routes";
import { backgroundTasksRoutes } from "../components/backgroundTasks/routes";
import { paymentsRoutes } from "../components/payments/routes";
import { exportToExcelRoutes } from "../components/exportToExcel/routes";
import { noticeBoardItemsRoutes } from "../components/noticeBoardItems/routes";
import { classNavigatorAdministrationRoutes } from "../components/classNavigator/routes";
import { queryMessageTypesRoutes } from "../components/queryMessageTypes/routes";
import { scheduledClassesClassTimetableRoutes } from "../components/scheduledClass/timetable/routes";
import { membersWelcomeScreenRoutes } from "../components/membersWelcome/routes";
import { amenityTypesRoutes } from "../components/amenityTypes/routes";
import { childAbsenceRoutes } from "../components/childAbsences/routes";

/**
 * All routes used by the application.
 */
export const appRoutes: Array<RouteEntry> = [
    // Security.
    ...apiAuthorizationRoutes,
    ...accountRoutes,

    // Base navigation.
    ...homeRoutes,
    ...profileRoutes,

    ...memberAreaRoutes,
    ...staffAreaRoutes,
    ...administrationRoutes,

    // Specific components that provide routable functionality.
    // Just list these alphabetically.
    ...adminQueryMessagesRoutes,
    ...amenityTypesRoutes,
    ...arrangeCoverRoutes,
    ...backgroundTasksRoutes,
    ...cancelClassRoutes,
    ...cancellationReasonRoutes,
    ...cancelledClassesRoutes,
    ...childAbsenceRoutes,
    ...childAbsenceTypesRoutes,
    ...childrenRoutes,
    ...childsRelationshipRoutes,
    ...classFinderRoutes,
    ...classLocationsRoutes,
    ...classNavigatorAdministrationRoutes,
    ...classRegistersRoutes,
    ...classStagesRoutes,
    ...classTypesRoutes,
    ...classWaitingListsRoutes,
    ...dashboardRoutes,
    ...exportToExcelRoutes,
    ...gendersRoutes,
    ...generalDiscountsRoutes,
    ...giftVouchersRoutes,
    ...holidaysAndSicknessRoutes,
    ...lessonPlansRoutes,
    ...memberRoutes,
    ...membersChildrenRoutes,
    ...membersClassFinderRoutes,
    ...membersWelcomeScreenRoutes,
    ...membersQueryMessagesRoutes,
    ...noticeBoardItemsRoutes,
    ...paymentsRoutes,
    ...queryMessageTypesRoutes,
    ...reenrolmentRoutes,
    ...scheduledClassesAdministrationRoutes,
    ...scheduledClassesClassScheduleRoutes,
    ...scheduledClassesClassTimetableRoutes,
    ...staffAbsencesRoutes,
    ...staffAbsenceTypesRoutes,
    ...staffChildDetailsRoutes,
    ...staffClassesRoutes,
    ...staffQualificationsRoutes,
    ...staffQueryMessagesRoutes,
    ...staffRoutes,
    ...staffTimeOffRoutes,
    ...termsRoutes,
    ...userRoutes,
    
    // Default if we hit a bad route.
    { path: '*', element: <PageNotFound /> }
];

/**
 * Routes used to populate the sidebar.
 */
export const sidebarRoutes: Array<RouteEntry> = [
    ...administrationSidebarRoutes,
    { path: '*', element: <></>}
];