import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import { useValidatorCallback } from "pojo-validator-react";
import { ValidatedInput } from "pojo-validator-reactstrap";
import { useTranslation } from "react-i18next";
import { useAsyncCallback } from "react-use-async-callback";
import { Alert, Button, Col, Form, FormGroup, InputGroup, Label, ModalBody, ModalFooter, ModalHeader, Row, Spinner } from "reactstrap";
import { ButtonAsync } from "reactstrap-buttonasync";
import { Child } from "../../../../api/main/models/Child";
import { PaymentState } from "../../../../api/main/models/constants/PaymentState";
import { TaxRates } from "../../../../api/main/models/constants/TaxRates";
import { Payment, paymentDefaultValues } from "../../../../api/main/models/Payment";
import { useSavePaymentMutation } from "../../../../api/main/payments/useSavePaymentMutation";
import { useSendPaymentAvailableEmailMutation } from "../../../../api/main/payments/useSendPaymentAvailableEmailMutation";
import { AlertOnErrors } from "../../../../shared/alertOnErrors";
import { useChanges } from "../../../../shared/useChanges";
import { HappyIcon } from "../../../shared/Utilities/HappyIcon";

export interface CreateAdHocPaymentModalProps {
    child: Child | undefined | null,

    toggle: () => void,
    refreshPaymentList: () => Promise<void>,
}

export const CreateAdHocPaymentModal = (props: CreateAdHocPaymentModalProps) => {
    const {
        child,
        toggle,
        refreshPaymentList
    } = props;
    const { t } = useTranslation();

    // Model (Payment)
    const { model, change, changes } = useChanges({} as Payment, { ...paymentDefaultValues(), childId: child?.id, memberId: child?.memberId, paymentState: PaymentState.Unpaid, createdDate: moment().toISOString(), totalAmountTaxPercentage: TaxRates.IrelandVatRate, isCombinedPayment: false, totalPreDiscountAmountGross: 0, numberOfLessons: 0, costPerLesson: 0 });
    const [savePayment, { errors: savePaymentMutationErrors }] = useSavePaymentMutation();

    // Email sending
    const [sendPaymentAvailableEmail, { errors: sendPaymentAvailableEmailMutationErrors }] = useSendPaymentAvailableEmailMutation();

    // Main model validation
    const [validate, validationErrors] = useValidatorCallback((validation, fieldsToCheck) => {
        const rules = {
            name: () => !model?.name ? t('editChild.paymentsTab.adHocPayment.errors.name', 'Name is required') : '',
            totalAmountGross: () => !model?.totalAmountGross ? t('editChild.paymentsTab.adHocPayment.errors.totalAmountGross', 'An amount is required') : '',
        };

        validation.checkRules(rules, fieldsToCheck);
    }, [model]);

    // Save everything
    const [saveForm, { isExecuting: isSaving, errors: saveFormErrors }] = useAsyncCallback(async () => {
        if (!model) {
            return;
        }

        if (!validate()) {
            return;
        }

        // Save the main model.
        await savePayment(model.id, { ...changes }, true);

        // If there are no payment errors send the payment email
        if (!savePaymentMutationErrors) {
            await sendPaymentAvailableEmail(model?.id);
        }

        // Refresh the list of payments in the parent component
        refreshPaymentList();

        // Close the modal.
        toggle();
    }, [model, changes]);

    // Render the UI
    //
    return (
        <>
            <AlertOnErrors
                errors={[
                    saveFormErrors, savePaymentMutationErrors,
                    sendPaymentAvailableEmailMutationErrors
                ]}
            />

            <ModalHeader toggle={toggle}>
                <h1>{t('editChild.paymentsTab.adHocPayment.title', 'One-off payment for')}</h1>
                <h3>{t('common.fullName', '{{firstName}} {{lastName}}', { firstName: child?.firstName, lastName: child?.lastName })}</h3>
            </ModalHeader>

            <ModalBody>
                <Form onSubmit={e => { e.preventDefault(); }}>
                    <Alert color="info">
                        {t('editChild.paymentsTab.adHocPayment.info.message', 'This is to generate a one-off payment for a cub.') }
                    </Alert>

                    <Row>
                        <Col>
                            <FormGroup>
                                <Label htmlFor="name">{t('editChild.paymentsTab.adHocPayment.description.label', 'Description')}</Label>
                                <ValidatedInput name="name" type="text" value={model?.name ?? ''} onChange={e => change({ name: e.currentTarget.value })} onBlur={e => validate('name')} validationErrors={validationErrors['name']} />
                            </FormGroup>
                        </Col>
                    
                        <Col>
                            <FormGroup>
                                <Label htmlFor="totalAmountGross">{t('editChild.paymentsTab.adHocPayment.amount.label', 'Amount')}</Label>
                                <InputGroup>
                                    <Button color="secondary" onClick={(e) => e.preventDefault()}><HappyIcon icon="euro" /></Button>
                                    <ValidatedInput name="discountAmount" type="number" value={model?.totalAmountGross ?? 0} onChange={e => change({ totalAmountGross: e.currentTarget.valueAsNumber, totalPreDiscountAmountGross: e.currentTarget.valueAsNumber })} onBlur={e => validate('totalAmountGross')} validationErrors={validationErrors['totalAmountGross']} />
                                </InputGroup>
                            </FormGroup>
                        </Col>
                    </Row>
                </Form>
            </ModalBody>

            <ModalFooter>
                <ButtonAsync color="primary" isExecuting={isSaving} onClick={() => saveForm()}
                    executingChildren={<><Spinner size="sm" /> {t('common.saving', 'Saving...')}</>}>
                    <FontAwesomeIcon icon="save" />
                    <> </>
                    {t('common.save', 'Save')}
                </ButtonAsync>
                <Button type="button" color="primary" outline onClick={e => toggle()}>
                    {t('common.cancel', 'Cancel')}
                </Button>
            </ModalFooter>
        </>
    );
};