import { ModalBody, ModalHeader } from "reactstrap";
import { ChildAttendanceWithLocationAndStaff, ScheduledClassChildWithLocationAndStaff } from "./classesTab";
import { StaffBioComponent } from "./StaffBioComponent";

export interface StaffModalProps {
    model: ScheduledClassChildWithLocationAndStaff | ChildAttendanceWithLocationAndStaff | undefined | null,
    toggle: () => void;
    classType: string | undefined | null;
}

/**
 * ComponentModal used to display ScheduledClassStaff for a single ScheduledClass under membersChild classesTab classesComponent
 */
export const StaffModal = (props: StaffModalProps) => {
    const { model, toggle, classType } = props;

    // Render the UI
    //
    return (
        <>
            <ModalHeader toggle={toggle}>
                
            </ModalHeader>

            <ModalBody>
                <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
                    <StaffBioComponent
                        staffProfile={model?.primaryTeacherProfile}
                        role={'PrimaryTeacher'}
                        classType={classType}
                    />

                    {model?.additionalTeacherProfiles?.map(additionalTeacher => (
                        <StaffBioComponent
                            staffProfile={additionalTeacher}
                            role={'AdditionalTeacher'}
                            key={additionalTeacher.id}
                            classType={classType}
                        />
                    ))}

                    {model?.cubCoordinatorProfiles?.map(cubCoordinator => (
                        <StaffBioComponent
                            staffProfile={cubCoordinator}
                            role={'Coordinator'}
                            key={cubCoordinator.id}
                            classType={classType}
                        />
                    ))}
                </div>
            </ModalBody>
        </>
    );
};