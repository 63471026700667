import moment from "moment";
import { useTranslation } from "react-i18next";
import { Button, ButtonGroup } from "reactstrap";
import { StaffSubmissionAcceptanceState } from "../../../api/main/models/constants/StaffSubmissonAcceptanceState";
import { StaffQualificationCheck } from "../../../api/main/models/StaffQualificationCheck";
import { ModelArrayChanges } from "../../../shared/useChanges";
import { ApproveQualificationFileViewComponent } from "./ApproveQualificationFileViewComponent";
import { StaffQualificationCheckWithProfile } from "./ApproveQualificationsList";

export interface ApproveQualificationItemProps {
    model: StaffQualificationCheckWithProfile | null | undefined,
    staffQualificationManager: ModelArrayChanges<StaffQualificationCheck, string>,
    updateStaffQualificationCheck: (id: string, changes: Partial<StaffQualificationCheck>, isCreate: boolean) => Promise<void>,
    currentUserId: string | null | undefined;
}

/**
 * Individual StaffQualificationCheck item, for Approving / Denying
 */
export const ApproveQualificationItem = (props: ApproveQualificationItemProps) => {
    const { model, staffQualificationManager, updateStaffQualificationCheck, currentUserId } = props;
    const { t } = useTranslation();

    // Handle Approve/Deny a StaffQualificationCheck
    const handleUpdatingAStaffQualificationCheck = (acceptanceState: string) => {
        if (!model || !acceptanceState || !currentUserId) {
            return
        }

        let changes = {
            acceptanceState: model?.acceptanceState,
            confirmedDate: moment(Date.now()).toISOString(),
        }

        if (acceptanceState === 'approve') {
            changes.acceptanceState = StaffSubmissionAcceptanceState.Approved
        }

        if (acceptanceState === 'reject') {
            changes.acceptanceState = StaffSubmissionAcceptanceState.Rejected;
        }

        staffQualificationManager.changeFor(model?.id, changes);

        updateStaffQualificationCheck(model?.id, changes, false);
    }

    // Render the UI
    //
    return (
        <tr key={model?.id}>
            <td>
                {t('approveQualificationsList.approveQualificationItem.qualification.value', '{{qualificationName}}', { qualificationName: model?.name })}
            </td>
            <td>
                {t('common.date', '{{date, DD/MM/YYYY}}', { date: moment(model?.submittedDate) })}
            </td>
            <td>
                {t('common.fullName', '{{firstName}} {{lastName}}', { firstName: model?.profile?.firstName, lastName: model?.profile?.lastName })}
            </td>
            <td>
                <ApproveQualificationFileViewComponent evidenceBlobReferenceId={model?.evidenceBlobReferenceId} />
            </td>
            <td>
                <ButtonGroup>
                    <Button color="success" onClick={() => handleUpdatingAStaffQualificationCheck('approve')}>
                        {t('common.accept', 'Accept')}
                    </Button>
                    <Button color="danger" onClick={() => handleUpdatingAStaffQualificationCheck('reject')}>
                        {t('common.reject', 'Reject')}
                    </Button>
                </ButtonGroup>
            </td>
        </tr>
    );
}