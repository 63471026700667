import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ValidationErrors } from "pojo-validator";
import { useCallback, useMemo } from "react";
import { ConditionalFragment } from "react-conditionalfragment";
import { useTranslation } from "react-i18next";
import { Alert, Button, Col, FormGroup, Label, ListGroup, Row } from "reactstrap";
import { RoleInClass } from "../../../../../api/main/models/constants/RoleInClass";
import { Profile } from "../../../../../api/main/models/Profile";
import { ScheduledClass } from "../../../../../api/main/models/ScheduledClass";
import { ScheduledClassStaff, scheduledClassStaffDefaultValues } from "../../../../../api/main/models/ScheduledClassStaff";
import { ModelArrayChanges } from "../../../../../shared/useChanges";
import { ScheduledClassStaffComponent } from "./ScheduledClassStaffComponent";

export interface ScheduledClassStaffTabProps {
    model: ScheduledClass | null | undefined,

    scheduledClassStaffsManager: ModelArrayChanges<ScheduledClassStaff, string>,
    validateScheduledClassStaff: (model: ScheduledClassStaff) => boolean,
    scheduledClassStaffValidationErrors: (id: string) => ValidationErrors,
    profiles: Array<Profile> | null | undefined,
}

/**
 * ScheduledClassStaffTab for ScheduledClasses
 */
export const ScheduledClassStaffTab = (props: ScheduledClassStaffTabProps) => {
    const {
        model,
        scheduledClassStaffsManager,
        profiles,
        validateScheduledClassStaff,
        scheduledClassStaffValidationErrors,
    } = props;
    const { t } = useTranslation();

    // Primary teacher.  We can have at most one of these.
    const primaryTeachers = useMemo(() => scheduledClassStaffsManager.model.filter(item => item.roleInClass === RoleInClass.PrimaryTeacher), [scheduledClassStaffsManager]);
    const hasPrimaryTeacher = !!primaryTeachers.filter(it => !it.isSubsitute).length;
    const addPrimaryTeacher = useCallback(() => {
        scheduledClassStaffsManager.addFor({ ...scheduledClassStaffDefaultValues(), scheduledClassId: model?.id, roleInClass: RoleInClass.PrimaryTeacher, });
    }, [scheduledClassStaffsManager, model]);

    // Additional teachers
    const additionalTeachers = useMemo(() => scheduledClassStaffsManager.model.filter(item => item.roleInClass === RoleInClass.AdditionalTeacher), [scheduledClassStaffsManager]);
    const addAdditionalTeacher = useCallback(() => {
        scheduledClassStaffsManager.addFor({ ...scheduledClassStaffDefaultValues(), scheduledClassId: model?.id, roleInClass: RoleInClass.AdditionalTeacher, });
    }, [scheduledClassStaffsManager, model]);

    // Coordinators.
    const coordinators = useMemo(() => scheduledClassStaffsManager.model.filter(item => item.roleInClass === RoleInClass.Coordinator), [scheduledClassStaffsManager]);
    const addCoordinator = useCallback(() => {
        scheduledClassStaffsManager.addFor({ ...scheduledClassStaffDefaultValues(), scheduledClassId: model?.id, roleInClass: RoleInClass.Coordinator, });
    }, [scheduledClassStaffsManager, model]);

    // Open the arrange cover section in a new tab
    const openArrangeCover = useCallback((id: string) => {
        window.open(`/administration/staff-management/arrange-cover/edit/${id}`);
    }, []);

    // Render the UI
    //
    return (
        <>
            <Alert color="secondary">
                <Row>
                    <Col>
                        {t('editScheduledClass.scheduledClassStaff.infoMessage', 'This screen is for managing the regular team for a class, you can use the button to the right to arrange cover for this class.')}
                    </Col>
                    <Col xs="auto">
                        <ConditionalFragment showIf={!!model}>
                            <Button color="secondary" onClick={() => openArrangeCover(model?.id ?? '')}>
                                <FontAwesomeIcon icon="people-arrows" />
                                <> </>
                                {t('common.arrangeCover', 'Arrange cover')}
                            </Button>
                        </ConditionalFragment>
                    </Col>
                </Row>
            </Alert>

            {/* Primary teacher.  We can have at most one of these. */}
            <FormGroup>
                <Label htmlFor="additionalTeachers">
                    {t('editScheduledClass.scheduledClassStaff.primaryTeacher.label', 'Teacher')}
                </Label>

                <ListGroup id="primaryTeachers">
                    {
                        primaryTeachers.map(item => (
                            <ScheduledClassStaffComponent key={item.id}
                                model={item}
                                change={changes => scheduledClassStaffsManager.changeFor(item.id, changes)}
                                remove={() => scheduledClassStaffsManager.removeFor(item.id)}
                                staffProfiles={profiles}
                                validate={() => validateScheduledClassStaff(item)}
                                validationErrors={scheduledClassStaffValidationErrors(item.id)}

                            />
                        ))
                    }
                </ListGroup>

                <ConditionalFragment showIf={!hasPrimaryTeacher}>
                    <div className="mt-2">
                        <Button color='primary' outline onClick={() => addPrimaryTeacher()}>
                            {t('editScheduledClass.scheduledClassStaff.primaryTeacher.add', 'Add primary teacher')}
                        </Button>
                    </div>
                </ConditionalFragment>
            </FormGroup>

            {/* Additional teachers.  We can have lots of these. */}
            <FormGroup>
                <Label htmlFor="additionalTeachers">
                    {t('editScheduledClass.scheduledClassStaff.additionalTeacher.label', 'Additional teachers')}
                </Label>

                <ListGroup id="additionalTeachers">
                    {
                        additionalTeachers.map(item => (
                            <ScheduledClassStaffComponent key={item.id}
                                model={item}
                                change={changes => scheduledClassStaffsManager.changeFor(item.id, changes)}
                                remove={() => scheduledClassStaffsManager.removeFor(item.id)}
                                staffProfiles={profiles}
                                validate={() => validateScheduledClassStaff(item)}
                                validationErrors={scheduledClassStaffValidationErrors(item.id)}

                            />
                        ))
                    }
                </ListGroup>

                <div className='mt-2'>
                    <Button color='primary' outline onClick={() => addAdditionalTeacher()}>
                        {t('editScheduledClass.scheduledClassStaff.additionalTeacher.add', 'Add additional teacher')}
                    </Button>
                </div>
            </FormGroup>

            {/* Coordinators.  We can also have lots of these. */}
            <FormGroup>
                <Label htmlFor="coordinators">
                    {t('editScheduledClass.scheduledClassStaff.coordinators.label', 'Cub coordinators')}
                </Label>

                <ListGroup id="coordinators">
                    {
                        coordinators.map(item => (
                            <ScheduledClassStaffComponent key={item.id}
                                model={item}
                                change={changes => scheduledClassStaffsManager.changeFor(item.id, changes)}
                                remove={() => scheduledClassStaffsManager.removeFor(item.id)}
                                staffProfiles={profiles}
                                validate={() => validateScheduledClassStaff(item)}
                                validationErrors={scheduledClassStaffValidationErrors(item.id)}

                            />
                        ))
                    }
                </ListGroup>

                <div className="mt-2">
                    <Button color="primary" outline onClick={() => addCoordinator()}>
                        {t('editScheduledClass.scheduledClassStaff.coordinators.add', 'Add Cub coordinator')}
                    </Button>
                </div>
            </FormGroup>
        </>
    );
};