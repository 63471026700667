import * as React from 'react';
import { useSaveInStore, SaveInStoreOptions } from '../../../shared/useSaveInStore';
import { useUpdateClassStageMutation } from './useUpdateClassStageMutation';
import { ClassStage } from '../models/ClassStage';
import { useCreateClassStageMutation } from './useCreateClassStageMutation';
import { ClassStageCreateInput, ClassStageUpdateInput } from '../generated/graphql';

/**
 * Get a callback to save a ClassStage in the api using either a create or update.
 */
export function useSaveClassStageMutation(options: SaveInStoreOptions<ClassStage, string> = {}) {
    const [_create, createStatus] = useCreateClassStageMutation();
    const create = React.useCallback((model: Partial<ClassStage>) => _create(model as ClassStageCreateInput), [_create]);

    const [_update, updateStatus] = useUpdateClassStageMutation();
    const update = React.useCallback((id: string, changes: Partial<ClassStage>) => _update(id, changes as ClassStageUpdateInput), [_update]);

    return useSaveInStore(
        [create, createStatus],
        [update, updateStatus],
        options
    );
}
