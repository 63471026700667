import { useMemo } from "react";
import { ConditionalFragment } from "react-conditionalfragment";
import { Child } from "../../../../api/main/models/Child";
import { RoleInClass } from "../../../../api/main/models/constants/RoleInClass";
import { useConsumeChildWaitingListsTabViewModel } from "../../../../api/main/scheduledClassChildren/viewModels/useConsumeChildWaitingListsTabViewModel";
import { AlertOnErrors } from "../../../../shared/alertOnErrors";
import { LoadingIndicator } from "../../../shared/loadingIndicator/LoadingIndicator";
import { NoResultsFound } from "../../../shared/noResultsFound/NoResultsFound";
import { ClassesComponent } from "../classesTab/classesComponent";
import { ScheduledClassChildWithLocationAndStaff } from "../classesTab/classesTab";
import "./waitingListsTab.scss";

export interface WaitingListsTabProps {
    model: Child | null | undefined,
}

/**
 * Component under MembersChildrenList that lets the user view their WaitingList Classes
 */
export const WaitingListsTab = (props: WaitingListsTabProps) => {
    const { model } = props;

    // Load the data
    const {
        data: {
            items: _allItems,
            classLocations,
            classTypes,
            profiles,
            classStages,
            classSubStages,
            terms,
        }, errors: loadErrors, isLoading, refresh,
    } = useConsumeChildWaitingListsTabViewModel(model?.id);

    // Combine the data
    const allItems = useMemo(() => _allItems?.map(item => {
        const location = classLocations?.find(it => it.id === item.scheduledClass.classLocationId);

        // Primary teacher
        const primaryTeacher = item.scheduledClass.scheduledClassStaffs.find(it => it.roleInClass === RoleInClass.PrimaryTeacher && it.scheduledClassId === item.scheduledClassId);
        const primaryTeacherProfile = profiles?.find(it => it.id === primaryTeacher?.staffId);

        // Additional teachers
        const additionalTeachers = item.scheduledClass.scheduledClassStaffs.filter(it => it.roleInClass === RoleInClass.AdditionalTeacher && it.scheduledClassId === item.scheduledClassId);
        const additionalTeacherProfiles = additionalTeachers?.map(additionalTeacher => {
            const profile = profiles?.find(it => it.id === additionalTeacher.staffId);

            return {
                ...(profile ?? {}),

                profile,
            };
        });

        // Cub coordinators
        const cubCoordinators = item.scheduledClass.scheduledClassStaffs.filter(it => it.roleInClass === RoleInClass.Coordinator && it.scheduledClassId === item.scheduledClassId);
        const cubCoordinatorProfiles = cubCoordinators?.map(cubCoordinator => {
            const profile = profiles?.find(it => it.id === cubCoordinator.staffId);

            return {
                ...(profile ?? {}),

                profile,
            };
        });

        // NumberOfLessons for CurrentClassSubStage
        const numberOfLessons = classSubStages?.find(it => it.id === item.scheduledClass.currentClassSubStageId)?.numberOfLessons ?? 0;

        // Return the combined data
        return {
            ...item,
            location,
            primaryTeacherProfile,
            additionalTeacherProfiles,
            cubCoordinatorProfiles,
            numberOfLessons
        } as ScheduledClassChildWithLocationAndStaff;
    }), [classLocations, _allItems, profiles, classSubStages]);

    // Render the UI
    //
    return (
        <div className="classes-tab">
            <AlertOnErrors errors={loadErrors} />


            {/* WaitingList classes */}
            <div className="classes-tab-class-type">
                <h2 style={{ margin: '0 auto', fontSize: '2rem', fontFamily: 'museosansrounded', fontWeight: '600' }}>My Waitlists</h2>

                <ConditionalFragment showIf={!!allItems?.length}>
                    {
                        classTypes?.map(classType => (
                            <div key={classType.id}>
                                <ConditionalFragment showIf={!!allItems?.filter(it => it.scheduledClass?.classTypeId === classType.id)?.length}>
                                    <h3 className="classes-tab-class-type-header">
                                        {classType.name} classes
                                    </h3>
                                </ConditionalFragment>

                                {/* If the Child is registered in any classes, we will render them here */}
                                <ConditionalFragment showIf={!!allItems?.filter(it => it.scheduledClass?.classTypeId === classType.id).length}>
                                    <div className="classes-tab-class-type-class-classes">
                                        {
                                            allItems?.filter(it => it.scheduledClass?.classTypeId === classType.id).map(item => (
                                                <ClassesComponent
                                                    model={item as ScheduledClassChildWithLocationAndStaff}
                                                    key={item.id}
                                                    refresh={refresh}
                                                    term={terms?.find(it => it.id === item.scheduledClass?.termId)}
                                                    classType={classType.name}
                                                    classStages={classStages}
                                                    classSubStages={classSubStages}
                                                    isWaitingList={true}
                                                />
                                            ))
                                        }
                                    </div>
                                </ConditionalFragment>
                            </div>
                        ))
                    }
                </ConditionalFragment>
            </div>

            <ConditionalFragment showIf={!isLoading && !allItems?.length}>
                <NoResultsFound />
            </ConditionalFragment>

            <ConditionalFragment showIf={isLoading}>
                <LoadingIndicator fullWidth />
            </ConditionalFragment>
        </div>
    );
};