import { useTranslation } from 'react-i18next';
import { MainContainer } from '../../shared/mainContainer/MainContainer';
import { Banner } from '../../shared/banner/Banner';
import { useNavigate, useParams } from 'react-router-dom';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { AlertOnErrors } from '../../../shared/alertOnErrors';
import { useGenerateStripePaymentIntentMutation } from '../../../api/main/payments/useGeneratePaymentIntentMutation';
import { ConditionalFragment } from 'react-conditionalfragment';
import { LoadingIndicator } from '../../shared/loadingIndicator/LoadingIndicator';
import { useAsyncCallback } from 'react-use-async-callback';
import { useStripe, useElements, PaymentElement, Elements } from "@stripe/react-stripe-js";
import { Alert, Button, Col, Input, Label, Row, Spinner } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { StickyToolbar } from '../../shared/stickyToolbar/StickyToolbar';
import { ButtonAsync } from 'reactstrap-buttonasync';
import { loadStripe } from '@stripe/stripe-js';
import { stripeConfig } from '../../../configure/stripeConfig';
import { usePaymentCheckoutViewModel } from '../../../api/main/payments/viewModel/usePaymentCheckoutViewModel';
import { PaymentCheckoutDetails } from './PaymentCheckoutDetails';
import { useIsBookingValid } from '../../../api/main/scheduledClassChildren/viewModels/useIsBookingValid';
import { useToggleState } from 'use-toggle-state';
import { Timer } from '../../../shared/timer/Timer';
import moment, { Moment } from 'moment';
import { useCancelExpiredBookingMutation } from '../../../api/main/payments/viewModel/useCancelExpiredBookingMutation';

// Make sure to call `loadStripe` outside of a component’s render to avoid recreating the `Stripe` object on every render.
const stripePromise = loadStripe(stripeConfig.getPublishableKeyForEnvironment());

/**
 * Checkout for a payment.
 * 
 * NOTE this component is a wrapper around PaymentCheckoutUi which will be shown once Stripe is ready for us.
 */
export const PaymentCheckout = (props: any) => {
    const { id } = useParams<{ id: string | undefined; }>();

    // Geneate a client secret for a PaymentIntent for the current payment.
    const [_generatePaymentIntent, { errors: generatePaymentIntentErrors }] = useGenerateStripePaymentIntentMutation();
    const [clientSecret, setClientSecret] = useState<string>('');

    // Calculate a new payment intent.
    const generatePaymentIntent = useCallback(async () => {
        if (!id) { return; }

        // Securly generate the PaymentIntent on the server.
        const ret = await _generatePaymentIntent(id);
        setClientSecret(ret ?? '');
    }, [id, setClientSecret, _generatePaymentIntent]);

    // When we load generate a new payment intent.
    useEffect(() => {
        generatePaymentIntent();
    }, [id, generatePaymentIntent]);

    const stripeOptions = useMemo(() => ({ clientSecret }), [clientSecret]);

    // Just show the loading indicator until we have something to pass to stripe.
    if (!clientSecret) {
        return (
            <>
                <AlertOnErrors errors={[generatePaymentIntentErrors]} />
                <LoadingIndicator fullWidth />
            </>
        );
    }

    // Now render the real UI wrapped in a Stripe Elements component.
    // NOTE we use a key based on clientSecret here to force a refresh when we recalculate, e.g. by a gift voucher being
    // added.
    return (
        <Elements key={clientSecret ?? ''} stripe={stripePromise} options={stripeOptions}>
            <PaymentCheckoutUi {...props} regeneratePaymentIntent={generatePaymentIntent} />
        </Elements>
    );
};

export interface PaymentCheckoutUiProps {
    regeneratePaymentIntent: () => Promise<void>,
}

/**
 * Actual UI for PaymentCheckout component, which is only rendered once Stripe is ready for us.
 * @returns
 */
export const PaymentCheckoutUi = (props: PaymentCheckoutUiProps) => {
    const {
        regeneratePaymentIntent,
    } = props;

    const { id } = useParams<{ id: string | undefined; }>();
    const navigate = useNavigate();

    const { t } = useTranslation();

    // State for T&C acceptance.
    const [termsAccepted, setTermsAccepted] = useState(false);

    // Load the payment.
    const { data: modelData, isLoading, errors: loadErrors, refresh, } = usePaymentCheckoutViewModel(id);
    const model = modelData?.model;

    // Cancelling the booking
    const [cancelBooking, { errors: cancelBookingErrors }] = useCancelExpiredBookingMutation();

    const [isClassAvailable, toggleIsClassAvailable] = useToggleState(true);

    const {
        data: {
            isBookingValid,
            scheduledClassChildren
        }, refresh: refreshIsBookingValid
    } = useIsBookingValid(model?.childId, model?.scheduledClassId);

    // While we're on this screen, we want to refresh the IsBookingValid to ensure there is still an open place in the class.
    useEffect(() => {
        const timer = setInterval(() => {
            refreshIsBookingValid();
        }, 10 * 1000);

        if (isBookingValid?.success === false) {
            clearInterval(timer);
            toggleIsClassAvailable();
        }

        // Cleanup function.
        return () => clearInterval(timer);
    }, [refreshIsBookingValid, isBookingValid, toggleIsClassAvailable]);

    // Reload (when a voucher has been applied).
    const recalculatePayment = useCallback(async () => {
        await regeneratePaymentIntent();
        await refresh();
    }, [refresh, regeneratePaymentIntent]);

    // Prepare stripe.
    const stripe = useStripe();
    const elements = useElements();

    const [paymentStatus, setPaymentStatus] = useState<string | undefined>(undefined);
    const [paymentError, setPaymentError] = useState<string | undefined>();

    // Handle the payment using the Strip PaymentElement data.
    const [pay, { isExecuting: isPaying, errors: payErrors, }] = useAsyncCallback(async () => {
        // If we try to sumbit too early, do nothing.
        if (!stripe || !elements || !model) {
            return;
        }

        // If the class is not longer available do nothing.
        if (!isClassAvailable) {
            return;
        }

        // Ask stripe to confirm the payment.
        const result = await stripe.confirmPayment({
            //`Elements` instance that was used to create the Payment Element
            elements: elements as any,
            redirect: "always", // Will only redirect regardless of if the payment method requires it.
            confirmParams: {
                return_url: new URL(`/my/checkout-complete/${id}`, new URL(window.location.href)).toString(),
            }
        });

        // If we get an error, we need to show it.
        if (result.error) {
            // Show error to your customer (for example, payment details incomplete)
            setPaymentError(result.error.message);
            setPaymentStatus('error');
        }

        // On success we'll be redirected to /checkout-complete/ so we don't need to handle that here.
    }, [stripe, elements, model]);


    // Expire once the payment is 10 minutes old.
    const expiryDate = useMemo(() => {
        if (!model) {
            return null;
        }

        return moment(model.createdDate).add(10, 'minutes');
    }, [model]);

    const [bookingCancelled, toggleBookingCancelled] = useToggleState(false);

    const myScheduledClassChild = useMemo(() => {
        if (!scheduledClassChildren) {
            return null;
        }

        return scheduledClassChildren[0];
    }, [scheduledClassChildren]);

    // When the timer expires, we want to ...
    const timerExpired = useCallback(() => {
        if (!model?.childId || !model?.scheduledClassId || !id) {
            return;
        }

        // If this isn't a self service class, we don't need to do anything.
        if (!myScheduledClassChild?.isSelfService) {
            return;
        }

        // Toggle booking cancelled. 
        toggleBookingCancelled();

        // Cancel the booking.
        cancelBooking(model.childId, model.scheduledClassId, id);

    }, [model, id, cancelBooking, toggleBookingCancelled, myScheduledClassChild]);


    // Render the UI
    return (
        <>
            <Banner>
                <StickyToolbar>
                    <Row>
                        <Col>
                            <h1>
                                {t('paymentCheckout.title', 'Checkout')}
                            </h1>
                            {/*<h3>{model?.name}</h3>*/}
                            <h3>{t('paymentCheckout.info.message', 'Please check your class info is correct and click pay now to complete your booking.')}</h3>
                        </Col>

                        <ConditionalFragment showIf={(!!isBookingValid || !!expiryDate) && !!myScheduledClassChild?.isSelfService}>
                            <Col xs="auto">
                                <Timer expiryDate={expiryDate as Moment} onExpired={timerExpired} />
                            </Col>
                        </ConditionalFragment>

                    </Row>

                    <ConditionalFragment showIf={isLoading}>
                        <Row>
                            <Col xs="auto">
                                <LoadingIndicator />
                            </Col>
                        </Row>
                    </ConditionalFragment>
                </StickyToolbar>
            </Banner>

            <MainContainer>
                <AlertOnErrors errors={[loadErrors, payErrors, cancelBookingErrors]} />

                <Alert color="warning">
                    {t('paymentCheckout.warning.message.one', 'Class bookings will be reserved for 10 minutes, due to high demand')}
                </Alert>

                <ConditionalFragment showIf={!isBookingValid || !!bookingCancelled}>
                    <Alert color="danger">
                        <Row>
                            <Col>
                                {t('paymentCheckout.warning.message.two', 'We\'re sorry but the place in this class is no longer available')}
                            </Col>

                            <Col xs="auto">
                                <Button color="danger" onClick={() => navigate('/my/children')}>{t('paymentCheckout.searchForAnotherClass.button', 'Search for another class')}</Button>
                            </Col>
                        </Row>
                    </Alert>
                </ConditionalFragment>

                <PaymentCheckoutDetails data={modelData} allowVoucherAdd={true} recalculatePayment={recalculatePayment} />

                <ConditionalFragment showIf={paymentStatus === 'error'}>
                    <Alert color="danger">
                        <Row>
                            <Col xs="auto">
                                <FontAwesomeIcon icon="exclamation-triangle" />
                            </Col>
                            <Col>
                                {t('paymentCheckout.error.text', 'Sorry, your payment failed: {{paymentError}}', { paymentError, })}
                            </Col>
                        </Row>
                    </Alert>
                </ConditionalFragment>

                <form onSubmit={e => {
                    e.preventDefault();
                    pay();
                }}>
                    <div className="mb-2">
                        <PaymentElement />
                    </div>

                    <ConditionalFragment showIf={!termsAccepted}>
                        <Alert color="info">
                            {t('paymentCheckout.info.text', 'Terms and Conditions must be accepted before checkout can be completed.')}
                        </Alert>
                    </ConditionalFragment>

                    <Row>
                        <Col>
                            <a href="Happy Cubs Terms and Conditions.pdf" target="_blank" rel="noopener noreferrer">
                                <Button color="link" className="p-0" onClick={() => null}>
                                    <FontAwesomeIcon icon="eye" />
                                    <> </>
                                    {t('common.terms.view', 'View Terms & Conditions')}
                                </Button>
                            </a>
                        </Col>

                        <Col xs="auto">

                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <Label htmlFor="terms">{t('common.terms.accept', 'Accept Terms & Conditions / Waiver?')}</Label>
                            {/*<TwoValueSwitch leftLabel="No" rightLabel="Yes" checked={termsAccepted} onChange={setTermsAccepted} />*/}
                            <> </>
                            <Input type="checkbox" id="terms" checked={termsAccepted} onChange={e => setTermsAccepted(e.target.checked)} />
                        </Col>
                        <Col xs="auto">
                            <ButtonAsync type="submit" color="primary" disabled={!stripe || !termsAccepted}
                                isExecuting={isPaying} executingChildren={<><Spinner size="sm" /><> </>{t('paymentCheckout.payButton.executingText', 'Processing payment...')}</>}
                            >
                                <FontAwesomeIcon icon="credit-card" />
                                <> </>
                                {t('paymentCheckout.payButton.text', 'Pay €{{amount, 0.00}} now', { amount: model?.totalAmountGross })}
                            </ButtonAsync>
                        </Col>
                    </Row>
                </form>
            </MainContainer>
        </>
    );
};

