import gql from 'graphql-tag';
import { Guid } from 'guid-string';
import moment from 'moment';
import { StaffFieldsFragment } from '../generated/graphql';

export const staffFieldsFragment = gql`
    fragment staffFields on Staff {
        id
        userId
        archived
        startDate
        endDate
        photoBlobReferenceId
        bioHtml
        primaryJobTitle
        secondaryJobTitle
        cPDRecommendationsHtml
        isAdminStaff
        isTeacher
        isCoordinator
        canReceiveQueryMessageEmails
    }
`;

export type Staff = Omit<StaffFieldsFragment, '__typename'>;

/**
 * Default values.
 */
export const staffDefaultValues = (): Partial<Staff> => ({
    id: Guid.newGuid(),
    userId: '',
    archived: false,
    startDate: moment().toISOString(),
    endDate: null,
    photoBlobReferenceId: null,
    bioHtml: '',
    primaryJobTitle: '',
    secondaryJobTitle: '',
    cPDRecommendationsHtml: '',
    isAdminStaff: false,
    isTeacher: false,
    isCoordinator: false,
    canReceiveQueryMessageEmails: false,
});