import { IdentityRoles } from '../../configure/security/IdentityRoles';
import { RouteEntry } from '../../shared/routes';
import { ClassLocationsList } from './ClassLocationsList';
import { DeleteClassLocation } from './DeleteClassLocation';
import { CreateClassLocation, EditClassLocation } from './edit/EditClassLocation';
import { ExpiringRequirementChecksList } from './ExpiringRequirementChecksList';


/**
 * Routes that should be added to configuration/routeConfig.ts to provide access to these components via routing.
 */
export const classLocationsRoutes: Array<RouteEntry> = [
    { path: '/administration/class-management/pools/add', element: <CreateClassLocation />, authorize: true, requireRole: IdentityRoles.Administration, },
    { path: '/administration/class-management/pools/edit/:id', element: <EditClassLocation />, authorize: true, requireRole: IdentityRoles.Administration, },
    { path: '/administration/class-management/pools/delete/:id', element: <DeleteClassLocation />, authorize: true, requireRole: IdentityRoles.Administration, },
    { path: '/administration/class-management/pools', element: <ClassLocationsList />, authorize: true, requireRole: IdentityRoles.Administration, },
    { path: '/administration/class-management/expiring-requirement-checks', element: <ExpiringRequirementChecksList />, authorize: true, requireRole: IdentityRoles.Administration },
];
