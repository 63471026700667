import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useMemo } from "react";
import { ConditionalFragment } from "react-conditionalfragment";
import { useTranslation } from "react-i18next";
import { Button, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import { useToggleState } from "use-toggle-state";
import { useClassFinderCoreLocationViewModel } from "../../api/main/classFinderCore/useClassFinderCoreLocationViewModel";
import { ClassLocation } from "../../api/main/models/ClassLocation";
import { dayOfWeekDisplayName } from "../../api/main/models/constants/DayOfWeek";
import { AlertOnErrors } from "../../shared/alertOnErrors";
import { ScheduledClassWithRelationships } from "../classFinder/ClassFinder";
import { ClassFinderDay } from "../classFinder/ClassFinderDay";
import "./classFinderCoreLocation.scss";

interface ClassFinderCoreLocationProps {
    locationId: string | undefined | null;
    location: ClassLocation | undefined | null;
    suitableClasses: Array<ScheduledClassWithRelationships> | undefined | null;
    distance: string | undefined | null;
    childDateOfBirth: string | undefined | null;

    childId?: string | undefined | null;
    isForCourtesyClass?: boolean;
    courtesyClassCreditId?: string | undefined | null;
}

export const ClassFinderCoreLocation = (props: ClassFinderCoreLocationProps) => {
    const {
        locationId,
        location,
        suitableClasses,
        distance,
        childDateOfBirth,
        childId,
        isForCourtesyClass,
        courtesyClassCreditId
    } = props;
    const { t } = useTranslation();

    const {
        data: {
            items: _classLocationAmenityTypes,
            amenityTypes,
        }, errors: loadErrors
    } = useClassFinderCoreLocationViewModel(locationId);

    const classLocationAmenityTypes = useMemo(() => _classLocationAmenityTypes?.map(item => {
        const amenity = amenityTypes?.find(amenity => amenity.id === item.amenityTypeId);

        return {
            ...amenity,
        };

    }), [_classLocationAmenityTypes, amenityTypes]);

    // Group classes by dayOfWeek
    const classesByDay = useMemo(() => {
        if (!suitableClasses) return [];

        const classesByDay = suitableClasses.reduce((acc, curr) => {
            const dayOfWeek = curr.dayOfWeek;
            const day = acc.find(d => d.day === dayOfWeekDisplayName(dayOfWeek, t));

            if (day) {
                day.classes.push(curr);
            } else {
                acc.push({ day: dayOfWeekDisplayName(dayOfWeek, t), classes: [curr] });
            }

            return acc;
        }, [] as { day: string, classes: ScheduledClassWithRelationships[]; }[]);

        // Ensure Sunday is always last
        const sunday = classesByDay.find(it => it.day === "Sunday");
        if (sunday) {
            classesByDay.splice(classesByDay.indexOf(sunday), 1);
            classesByDay.push(sunday);
        }

        return classesByDay;
    }, [suitableClasses, t]);

    const [isOpen, toggleIsOpen] = useToggleState();

    // Render the UI
    //
    return (
        <>
            <AlertOnErrors errors={[
                loadErrors,
            ]} />

            <Row className="m-0 justify-content-between align-items-center class-finder-core-location">
                <Col>
                    <span className="class-finder-core-location-name">{location?.name}</span>
                </Col>

                <Col>
                    <span className="class-finder-core-location-info" onClick={() => toggleIsOpen()}>{t('common.poolInfo', '{{info}}', { info: classesByDay[0].classes[0].classType?.name === 'Swim Cubs' ? 'Pool Info' : 'Venue Info' })}</span>
                </Col>

                <Col xs="auto">
                    <span>
                        <ConditionalFragment showIf={!!distance && parseFloat(distance) > 0}>
                            <FontAwesomeIcon icon="location-pin" />
                            <> </>
                            {t('common.distance', '{{distance}}km', { distance: distance })}
                        </ConditionalFragment>
                    </span>
                </Col>
            </Row>

            {
                classesByDay?.map(it => (
                    <ClassFinderDay
                        dayName={it.day}
                        classes={it.classes}
                        childDateOfBirth={childDateOfBirth}
                        childId={childId}
                        isForCourtesyClass={isForCourtesyClass}
                        courtesyClassCreditId={courtesyClassCreditId} />
                ))
            }

            <Modal
                isOpen={isOpen}
                toggle={() => toggleIsOpen()}>
                <ModalHeader>
                    <Row>
                        <span style={{ color: '#fada00', fontFamily: 'museosansrounded', fontSize: '2rem' }}>
                            {location?.name}
                        </span>
                    </Row>

                    <Row style={{ fontSize: '1rem', fontFamily: 'museosansrounded' }}>
                        <span>
                            {location?.address}
                        </span>

                        <a href={location?.mapUrl} target="_blank" rel="noreferrer">
                            <> </>
                            {t('common.viewOnGooleMaps', 'Directions Google Maps')}
                        </a>
                    </Row>
                </ModalHeader>

                <ModalBody>
                    {
                        classLocationAmenityTypes?.map(item => (
                            <Row>
                                <Col xs={1}>
                                    <FontAwesomeIcon icon="circle-check" style={{ color: '#fada00', width: '16px', height: '16px' }} />
                                </Col>

                                <Col xs={11}>
                                    {item.name}
                                </Col>
                            </Row>
                        ))
                    }
                </ModalBody>

                <ModalFooter>
                    <Button color="secondary" onClick={() => toggleIsOpen()}>{t('common.close', 'Close')}</Button>
                </ModalFooter>
            </Modal>
        </>
    );
};