import { useMutation } from "@apollo/react-hooks";
import { useAsyncCallback } from 'react-use-async-callback';
import gql from "graphql-tag";
import { AsyncActionStatus } from '../../../shared/abstractStore';
import { UpdateNoticeBoardItemMutation, UpdateNoticeBoardItemMutationVariables, NoticeBoardItemUpdateInput } from '../generated/graphql';
import { noticeBoardItemFieldsFragment } from '../models/NoticeBoardItem';

/**
 * Get a callback to update a NoticeBoardItem in the api.
 */
export function useUpdateNoticeBoardItemMutation(): [(id: string, model: NoticeBoardItemUpdateInput) => Promise<void>, AsyncActionStatus] {
    const [mutationAction, { error }] = useMutation<UpdateNoticeBoardItemMutation, UpdateNoticeBoardItemMutationVariables>(
        gql`
            mutation updateNoticeBoardItemMutation ($model: NoticeBoardItemUpdateInput!) {
                updateNoticeBoardItem(model: $model) {
                    ...noticeBoardItemFields
                }
            }

            ${noticeBoardItemFieldsFragment}
        `
    );

    // Wrap the mutation in a standard callback.
    const [action, { isExecuting, errors: actionErrors }] = useAsyncCallback(async (id: string, model: NoticeBoardItemUpdateInput) => {
        const result = await mutationAction({ variables: { model: { ...model, id: id } } });
        if (result.errors && result.errors.length) {
            throw result.errors;
        }
    }, [mutationAction]);

    return [action, { isExecuting: isExecuting, errors: error ? error.message : actionErrors }];
}
