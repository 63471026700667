import { Button, Row, Col, Spinner, NavItem, NavLink, Badge, Alert } from 'reactstrap';
import { AlertOnErrors } from '../../../../shared/alertOnErrors';
import { LoadingIndicator } from '../../../shared/loadingIndicator/LoadingIndicator';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MainContainer } from '../../../shared/mainContainer/MainContainer';
import { useParams, useNavigate } from 'react-router';
import { useChanges, useChangesArray } from '../../../../shared/useChanges';
import { useValidatorCallback } from 'pojo-validator-react';
import { FormButtons } from '../../../shared/formButtons/FormButtons';
import { ButtonAsync } from 'reactstrap-buttonasync';
import { useAsyncCallback } from 'react-use-async-callback';
import { ConditionalFragment } from 'react-conditionalfragment';
import { Banner } from '../../../shared/banner/Banner';
import { StickyToolbar } from '../../../shared/stickyToolbar/StickyToolbar';
import { ScheduledClass, scheduledClassDefaultValues } from '../../../../api/main/models/ScheduledClass';
import { useSaveScheduledClassMutation } from '../../../../api/main/scheduledClasses/useSaveScheduledClassMutation';
import { PillsNavBar } from '../../../shared/pillsNavBar/PillsNavBar';
import { useCallback, useMemo, useState } from 'react';
import { MainTab } from "./MainTab";
import { useEditScheduledClassSupportingData } from '../../../../api/main/scheduledClasses/viewModels/useEditScheduledClassSupportingData';
import { isNullOrUndefined } from 'util';
import { DayOfWeek } from '../../../../api/main/models/constants/DayOfWeek';
import { getScheduledClassSummary } from '../../getScheduledClassSummary';
import { useEditScheduledClassViewModel } from '../../../../api/main/scheduledClasses/viewModels/useEditScheduledClassViewModel';
import { ScheduledClassStaffTab } from './Staff/ScheduledClassStaffTab';
import { ScheduledClassStaff } from '../../../../api/main/models/ScheduledClassStaff';
import { useSaveScheduledClassStaffMutation } from '../../../../api/main/scheduledClassStaffs/useSaveScheduledClassStaffMutation';
import { useDeleteScheduledClassStaffMutation } from '../../../../api/main/scheduledClassStaffs/useDeleteScheduledClassStaff';
import { useValidatorArrayCallback } from '../../../../shared/validator-react-contrib/useValidatorArrayCallback';
import { StaffNote } from '../../../../api/main/models/StaffNote';
import { useSaveStaffNoteMutation } from '../../../../api/main/staffNotes/useSaveStaffNoteMutation';
import { useDeleteStaffNoteMutation } from '../../../../api/main/staffNotes/useDeleteStaffNote';
import { hasAnyValidationErrors } from '../../../../utilities/hasAnyValidationErrors';
import { findLastUpdatedStaffNote } from '../../../../utilities/findLastUpdatedStaffNote';
import moment from 'moment';
import { useReplaceSearchParamsEffect, useSearchParams } from '../../../../shared/useURLSearchParams';
import { ScheduledClassChild } from '../../../../api/main/models/ScheduledClassChild';
import { useSaveScheduledClassChildMutation } from '../../../../api/main/scheduledClassChildren/useSaveScheduledClassChildMutation';
import { useDeleteScheduledClassChildMutation } from '../../../../api/main/scheduledClassChildren/useDeleteScheduledClassChild';
import { Term } from '../../../../api/main/models/Term';
import { StaffNotesTab } from '../../../staffNotes/StaffNotesTab';
import { EventsTab } from './eventsTab/EventsTab';

export interface EditScheduledClassProps {
    isCreate?: boolean,
    onCreateDefaultValues?: () => Partial<ScheduledClass>;
}

/**
 * Component to create an ScheduledClass.
 */
export const CreateScheduledClass = (props: EditScheduledClassProps) => (<EditScheduledClass isCreate={true} {...props} />);

/**
 * Component to edit an ScheduledClass.
 */
export const EditScheduledClass = (props: EditScheduledClassProps) => {
    const {
        isCreate,
        onCreateDefaultValues,
    } = props;

    const { t } = useTranslation();
    const { id } = useParams<{ id: string | undefined; }>();
    const navigate = useNavigate();

    // Load all data.
    const {
        data: {
            model: storeModel,
            scheduledClassStaffs,
            scheduledClassChildren,
            scheduledClassChildEvents
        },
        errors: loadErrors, isLoading: _isLoading,
    } = useEditScheduledClassViewModel(id);

    // Load the supporting data
    const {
        data: {
            classTypes,
            classStages,
            classSubStages,
            classLocations,
            classLocationClassTypes,
            profiles,
            terms,
            staffNotes,
        },
        errors: loadSupportingDataErrors,
        isLoading: isLoadingSupportingData
    } = useEditScheduledClassSupportingData(storeModel?.id);
    const isLoading = _isLoading || isLoadingSupportingData;

    // Model (ScheduledClass)
    const { model, change, changes } = useChanges(storeModel, isCreate ? { ...scheduledClassDefaultValues(), ...(onCreateDefaultValues ? onCreateDefaultValues() : {}) } : undefined);
    const [saveScheduledClass, { errors: saveErrors }] = useSaveScheduledClassMutation();

    // Find thisTerm and nextTerm
    const { thisTerm, nextTerm } = useMemo(() => {
        let thisTerm: Term | null | undefined = null;
        let nextTerm: Term | null | undefined = null;

        const thisTermIndex = terms?.findIndex(item => item.id === model.termId);

        thisTerm = terms?.find(item => item.id === model.termId);
        if (!isNaN(thisTermIndex)) {
            nextTerm = terms[thisTermIndex + 1];
        }

        return { thisTerm, nextTerm };
    }, [terms, model]);

    // Model (StaffNotes)
    const scheduledClassStaffNotesManager = useChangesArray<StaffNote, string>(staffNotes, item => item.id);
    const [saveStaffNote] = useSaveStaffNoteMutation();
    const [removeStaffNote] = useDeleteStaffNoteMutation();

    // Model (Register)(ScheduledClassChild)
    const scheduledClassChildrenManager = useChangesArray<ScheduledClassChild, string>(scheduledClassChildren, item => item.id);
    const [saveScheduledClassChild] = useSaveScheduledClassChildMutation();
    const [removeScheduledClassChild] = useDeleteScheduledClassChildMutation();

    // Model (ScheduledClassStaffs)
    const scheduledClassStaffsManager = useChangesArray<ScheduledClassStaff, string>(scheduledClassStaffs, item => item.id);
    const [saveScheduledClassStaff] = useSaveScheduledClassStaffMutation();
    const [removeScheduledClassStaff] = useDeleteScheduledClassStaffMutation();

    // ScheduledClassStaffs validation
    const [validateScheduledClassStaff, scheduledClassStaffValidationErrors] = useValidatorArrayCallback<ScheduledClassStaff>((myModel, validation, fieldsToCheck) => {
        const rules = {
            staffId: () => !myModel?.staffId ? t('editScheduledClass.scheduledClassStaff.errors.staffId', 'Primary teacher cannot be empty') : '',
        };

        validation.checkRules(rules, fieldsToCheck);
    }, []);

    // StaffNotes validation
    const [validateScheduledClassStaffNote, scheduledClassStaffNoteValidationErrors] = useValidatorArrayCallback<StaffNote>((myModel, validation, fieldsToCheck) => {
        const rules = {
            noteType: () => !myModel?.noteType ? t('editScheduledClass.staffNote.errors.noteType', 'Note type is required') : '',
        };

        validation.checkRules(rules, fieldsToCheck);
    }, []);

    // Main model validation (including all related objects).
    const [validate, validationErrors] = useValidatorCallback((validation, fieldsToCheck) => {
        const rules = {
            classTypeId: () => !model?.classTypeId ? t('editScheduledClass.errors.classTypeId', 'An activity must be selected') : '',
            termId: () => !model?.termId ? t('editScheduledClass.errors.termId', 'A term must be selected') : '',
            classLocationId: () => !model?.classLocationId ? t('editScheduledClass.errors.classLocationId', 'A location must be selected') : '',
            currentClassSubStageId: () => !!model?.currentClassStageId && !model?.currentClassSubStageId ? t('editScheduledClass.errors.currentClassSubStageId', 'You must select a stage to run the class this term.') : '',
            nextClassSubStageId: () => !!model?.nextClassStageId && !model?.nextClassSubStageId ? t('editScheduledClass.errors.nextClassSubStageId', 'You must select a stage to run the class next term.') : '',
            dayOfWeek: () => isNullOrUndefined(model?.dayOfWeek) || model?.dayOfWeek < DayOfWeek.Sunday || model?.dayOfWeek > DayOfWeek.Saturday ? t('editScheduledClass.errors.dayOfWeek', 'A day must be selected') : '',

            // Any erros in the related objects
            scheduledClassStaffs: () => scheduledClassStaffsManager.model.filter(it => !validateScheduledClassStaff(it)).length ? t('editScheduledClass.errors.scheduledClassStaffs', 'One or more of the scheduled class staff members are invalid') : '',
            staffNotes: () => scheduledClassStaffNotesManager.model.filter(it => !validateScheduledClassStaffNote(it)).length ? t('editScheduledClass.errors.staffNotes', 'One or more of the staff notes are invalid') : ''
        };
        validation.checkRules(rules, fieldsToCheck);
    }, [model, scheduledClassStaffsManager, validateScheduledClassStaff]);

    // Save everything.
    const [saveForm, { isExecuting: isSaving, errors: saveFormErrors }] = useAsyncCallback(async (options?: { dontNavigate?: boolean, }) => {
        if (!model) {
            return;
        }

        if (!validate()) {
            return;
        }

        // Save the main model. If this is a create, we need to save the originKey as well.
        if (isCreate) {
            await saveScheduledClass(model.id, { ...changes, originKey: model.id }, isCreate ?? false);
        } else {
            await saveScheduledClass(model.id, { ...changes }, isCreate ?? false);
        }

        // Saves changes to the ScheduledClassChildren
        for (const item of scheduledClassChildrenManager.added) { await saveScheduledClassChild(item.id, scheduledClassChildrenManager.changesFor(item.id), true); }
        for (const item of scheduledClassChildrenManager.updated) { await saveScheduledClassChild(item.id, scheduledClassChildrenManager.changesFor(item.id), false); }
        for (const item of scheduledClassChildrenManager.removed) { await removeScheduledClassChild(item.id); }
        scheduledClassChildrenManager.markAsSaved();

        // Saves changes to the ScheduledClassStaffs
        for (const item of scheduledClassStaffsManager.added) { await saveScheduledClassStaff(item.id, scheduledClassStaffsManager.changesFor(item.id), true); }
        for (const item of scheduledClassStaffsManager.updated) { await saveScheduledClassStaff(item.id, scheduledClassStaffsManager.changesFor(item.id), false); }
        for (const item of scheduledClassStaffsManager.removed) { await removeScheduledClassStaff(item.id); }
        scheduledClassStaffsManager.markAsSaved();

        // Saves changes to the StaffNote
        for (const item of scheduledClassStaffNotesManager.added) { await saveStaffNote(item.id, scheduledClassStaffNotesManager.changesFor(item.id), true); }
        for (const item of scheduledClassStaffNotesManager.updated) { await saveStaffNote(item.id, scheduledClassStaffNotesManager.changesFor(item.id), false); }
        for (const item of scheduledClassStaffNotesManager.removed) { await removeStaffNote(item.id); }
        scheduledClassStaffNotesManager.markAsSaved();

        // Go back to previous screen.
        if (!options?.dontNavigate) {
            navigate(-1);
        }
    }, [validate, saveScheduledClass, model, changes, isCreate, id, navigate, scheduledClassStaffsManager, saveScheduledClassStaff, removeScheduledClassStaff,]);

    // Manage the tab being displayed.  If we have tab= on the query string, default to it, otherwise default to the main tab.
    const { tab: tabParam } = useSearchParams();
    // Manage the tab being displayed.
    const [activeTab, setActiveTab] = useState<'main' | 'staff' | 'staff-notes' | 'classRegister' | 'events'>(tabParam as any || 'main');

    // Keep the URL up to date with the currentTab
    useReplaceSearchParamsEffect({ tab: activeTab === 'main' ? '' : activeTab });

    // Generate the summary descrition for the class.
    const summaryText = useMemo(() => getScheduledClassSummary(
        model,
        {
            classLocation: classLocations?.find(item => item.id === model?.classLocationId),
            classStage: classStages?.find(item => item.id === model?.currentClassStageId),
            classSubStage: classSubStages?.find(item => item.id === model?.currentClassSubStageId),
        }
    ), [model, classLocations, classStages, classSubStages]);

    // Call function to return the last updated note
    const lastNoteUpdate = useMemo(() => {
        return findLastUpdatedStaffNote(staffNotes);
    }, [staffNotes]);

    // The name of the term this class is from
    const termName = useMemo(() => {
        if (!terms) return;

        return terms.find(item => item.id === model?.termId)?.name;
    }, [terms, model]);

    // Open the class register in a new tab
    const openClassRegister = useCallback((id: string) => {
        window.open(`/administration/children-management/register/${id}`);
    }, []);

    // Render the UI
    //
    return (
        <>
            <Banner>
                <StickyToolbar>
                    <Row>
                        <Col xs={12} md="auto">
                            <h1>
                                {
                                    isCreate ? t('editScheduledClass.createHeading.default', 'Add class')
                                        : t('editScheduledClass.editHeading.default', 'Edit class')
                                }
                            </h1>
                            <h3>{summaryText}</h3>
                            <h4>{termName}</h4>
                        </Col>
                        <Col>
                            <PillsNavBar>
                                <NavItem>
                                    <NavLink active={activeTab === 'main'} onClick={() => setActiveTab('main')}>
                                        {t('editScheduledClass.nav.main', 'Class details')}
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink active={activeTab === 'staff'} onClick={() => setActiveTab('staff')}>
                                        {t('editScheduledClass.nav.team', 'Team')}
                                        <> </>
                                        <Badge pill>
                                            {scheduledClassStaffsManager.model.length}
                                        </Badge>
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink active={activeTab === 'staff-notes'} onClick={() => setActiveTab('staff-notes')}>
                                        {t('editScheduledClass.nav.classNotes.name', 'Class notes ')}
                                        <></>
                                        {
                                            lastNoteUpdate !== null ? (
                                                <>
                                                    <Badge pill color="secondary">
                                                        {t('editScheduledClass.nav.staffNotes.badgeUpdated', 'Updated: ')} {t('common.date', '{{date, DD/MM/YYYY}}', { date: moment(lastNoteUpdate.updatedDate), })}
                                                    </Badge>
                                                </>
                                            ) : null
                                        }

                                        {/* Show a warning triangle if any fields for this tab have validation errors */}
                                        <ConditionalFragment showIf={hasAnyValidationErrors([validationErrors['staffNotes'],])}>
                                            <> </><FontAwesomeIcon icon="exclamation-triangle" color="danger" />
                                        </ConditionalFragment>
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink active={activeTab === 'events'} onClick={() => setActiveTab('events')}>
                                        {t('editScheduledClass.nav.events', 'Events')}
                                        {' '}
                                        <ConditionalFragment showIf={!!(scheduledClassChildEvents?.length > 0)}>
                                            <Badge color="secondary" pill style={{ paddingLeft: "5px" }}>
                                                {scheduledClassChildEvents?.length}
                                            </Badge>
                                        </ConditionalFragment>
                                    </NavLink>
                                </NavItem>
                            </PillsNavBar>
                        </Col>
                        <ConditionalFragment showIf={isLoading}>
                            <Col xs="auto">
                                <LoadingIndicator size="sm" />
                            </Col>
                        </ConditionalFragment>
                    </Row>
                </StickyToolbar>
            </Banner>
            <MainContainer>
                <AlertOnErrors errors={[
                    loadErrors, loadSupportingDataErrors,
                    saveFormErrors, saveErrors,
                ]} />



                <ConditionalFragment showIf={!!nextTerm && !!nextTerm?.copiedClassesOnDate}>
                    <Alert color="warning">
                        {t('editScheduledClass.classWarning', '{{termName}} has already had its classes generated based on {{thisTerm}} (this term). Any changes made here will need to be manually changed for this class in {{termName}}.', { termName: nextTerm?.name, thisTerm: thisTerm?.name })}
                    </Alert>
                </ConditionalFragment>

                {/* Active tab */}
                {
                    activeTab === 'main' ? (
                        <MainTab
                            model={model}
                            change={change}
                            validate={validate}
                            validationErrors={validationErrors}
                            saveForm={saveForm}
                            terms={terms}
                            classTypes={classTypes}
                            classStages={classStages}
                            classSubStages={classSubStages}
                            classLocations={classLocations}
                            classLocationClassTypes={classLocationClassTypes}
                        />
                    ) : activeTab === 'staff' ? (
                        <ScheduledClassStaffTab
                            model={model}
                            scheduledClassStaffsManager={scheduledClassStaffsManager}
                            validateScheduledClassStaff={validateScheduledClassStaff}
                            scheduledClassStaffValidationErrors={scheduledClassStaffValidationErrors}
                            profiles={profiles}
                        />
                    ) : activeTab === 'staff-notes' ? (
                        <StaffNotesTab
                            model={{ targetId: model.id, targetType: 'ScheduledClass', headingName: 'Class', modalHeading: summaryText }}
                            staffNotesManager={scheduledClassStaffNotesManager}
                            validateStaffNote={validateScheduledClassStaffNote}
                            staffNoteValidationErrors={scheduledClassStaffNoteValidationErrors}
                            staffProfiles={profiles}
                            saveForm={saveForm}
                        />
                    ) : activeTab === 'events' ? (
                        <EventsTab
                            events={scheduledClassChildEvents}
                        />
                    ) : null
                }

                {/* Save buttons at the bottom of all tabs. */}
                <FormButtons>
                    <ConditionalFragment showIf={!isLoading}>
                        <ButtonAsync color="primary" isExecuting={isSaving} onClick={() => saveForm()}
                            executingChildren={<><Spinner size="sm" /> {t('common.saving', 'Saving...')}</>}>
                            <FontAwesomeIcon icon="save" />
                            <> </>
                            {t('common.save', 'Save')}
                        </ButtonAsync>
                    </ConditionalFragment>

                    <Button type="button" color="primary" outline onClick={e => navigate(-1)}>
                        {t('common.cancel', 'Cancel')}
                    </Button>

                    <ConditionalFragment showIf={!isLoading}>
                        <Button color="primary" outline onClick={() => openClassRegister(model.id)}>
                            <FontAwesomeIcon icon="eye" />
                            <> </>
                            {t('common.viewRegister', 'View register')}
                        </Button>
                    </ConditionalFragment>
                </FormButtons>
            </MainContainer>
        </>
    );
};
