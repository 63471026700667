import './sidebar.scss';

import { AppRoutes } from '../../shared/appRoutes/AppRoutes';
import { sidebarRoutes } from '../../../configure/routeConfig';
import { SidebarMinimizer } from './sidebarMinimizer/SidebarMinimizer';
import { SidebarNav } from './sidebarNav/SidebarNav';
import { useSidebarState } from '../../../globalState/sidebarState/useSidebarState';
import { Helmet } from 'react-helmet-async';
import { HeaderSubNavigation } from '../header/HeaderSubNavigation';

/**
 * Sidebar in the layout.
 */
export const Sidebar = () => {
    const [sidebarState] = useSidebarState();

    return (
        <div className="sidebar">
            {/* Update the style of body based on the global sidebar state */}
            <Helmet>
                <body data-sidebar-visible={sidebarState.visible} data-sidebar-minimized={sidebarState.minimized} data-sidebar-suggested={sidebarState.suggested} />
            </Helmet>

            <div className="sidebar-navigation-container">
                {/*<SidebarNav className="sidebar-header-navigation">*/}
                {/*    <HeaderNavigation />*/}
                {/*</SidebarNav>*/}
                <SidebarNav className="sidebar-header-navigation">
                    <HeaderSubNavigation />
                </SidebarNav>

                <SidebarNav className="sidebar-navigation">
                    <AppRoutes routes={sidebarRoutes} />
                </SidebarNav>
            </div>

            {/* Blank space to make sure the minimizer is always at the bottom */}
            <div className="sidebar-blank-space">
            </div>

            <SidebarMinimizer />
        </div>
    );
};
