import { useTranslation } from "react-i18next";
import { ClassType } from "../../../../api/main/models/ClassType";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./addClassComponent.scss";
import { useNavigate } from "react-router-dom";
import { Child } from "../../../../api/main/models/Child";

export interface ClassComponentProps {
    child: Child | undefined | null,
    classType: ClassType,
    isFirstClass: boolean,
}

/**
 * Component under ClassesTab that lets the user add a class.
 */
export const AddClassComponent = (props: ClassComponentProps) => {
    const {
        child,
        classType,
        isFirstClass,
    } = props;

    const { t } = useTranslation();
    const navigate = useNavigate();

    // Render the UI
    //
    return (
        <>
            <div className="add-class-component" onClick={() => navigate(`/my/class-finder/${child?.id}?classType=${encodeURIComponent(classType.id)}`)}>
                <div className="add-class-component-icon">
                    <FontAwesomeIcon icon="plus-circle" />
                </div>
                <div className="add-class-component-text">
                    {
                        isFirstClass ? t('addClassComponent.text', 'Find a {{classTypeName}} class', { classTypeName: classType.name })
                            : t('addAdditionalClassComponent.text', 'Add an additional {{classTypeName}} class', { classTypeName: classType.name })
                    }        
                </div>
            </div>
        </>
    );
};