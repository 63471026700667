import moment from "moment";
import React, { useCallback, useMemo, useState } from "react";
import { ConditionalFragment } from "react-conditionalfragment";
import { useTranslation } from "react-i18next";
import { useAsyncCallback } from "react-use-async-callback";
import { Badge, Input, ModalBody, ModalHeader, } from "reactstrap";
import { useToggleState } from "use-toggle-state";
import { useChildAbsencesForScheduledClassViewModel } from "../../api/main/childAbsences/useChildAbsencesForScheduledClassViewModel";
import { Child } from "../../api/main/models/Child";
import { ChildAttendance, childAttendanceDefaultValues } from "../../api/main/models/ChildAttendance";
import { CourtesyClassCredit } from "../../api/main/models/CourtesyClassCredit";
import { ScheduledClass } from "../../api/main/models/ScheduledClass";
import { StaffNote, staffNoteDefaultValues } from "../../api/main/models/StaffNote";
import { useSaveStaffNoteMutation } from "../../api/main/staffNotes/useSaveStaffNoteMutation";
import { AlertOnErrors } from "../../shared/alertOnErrors";
import { ModelArrayChanges } from "../../shared/useChanges";
import { StyledModal } from "../shared/styledModal/StyledModal";
import { HappyIcon } from "../shared/Utilities/HappyIcon";
import { TooltipWrapper } from "../shared/Utilities/TooltipWrapper";
import { AddProgressNoteModal } from "./edit/AddProgressNoteModal";
import { NotesModalComponent } from "./NotesModalComponent";

export interface RegisterComponentProps {
    child: Child | null | undefined,
    childAttendancesManager: ModelArrayChanges<ChildAttendance, string>,
    scheduledClassId: string,
    currentUserStaffId: string,
    currentLessonNumber: string | number,
    dateInView: string | undefined,
    staffNotesManager: ModelArrayChanges<StaffNote, string>,
    childStaffNotes: Array<StaffNote> | null | undefined,
    isFirstTimeInThisClass: boolean,
    // Trigger a save after a short delay and after state has been synchronised
    saveDebounce: () => void,
    courtesyClassCredits: Array<CourtesyClassCredit> | null | undefined,
    scheduledClass: ScheduledClass | null | undefined,
    memberStaffNotes: Array<StaffNote> | null | undefined,
}

/**
 * StaffArea - ScheduledClasses - Class register component
 */
export const RegisterComponent = (props: RegisterComponentProps) => {
    const {
        child,
        childAttendancesManager,
        scheduledClassId,
        currentUserStaffId,
        currentLessonNumber,
        dateInView,
        staffNotesManager,
        childStaffNotes,
        isFirstTimeInThisClass,
        saveDebounce,
        courtesyClassCredits,
        scheduledClass,
        memberStaffNotes
    } = props;
    const { t } = useTranslation();

    // Load the data
    const {
        data: {
            items: childAbsences,
            childAbsenceTypes
        }, errors: loadErrors
    } = useChildAbsencesForScheduledClassViewModel(scheduledClassId);

    // Find any Children with an Absence for this ScheduledClass
    const absenceTypeName = useMemo(() => {
        // Find the ChildAbsence, if any
        const childAbsence = childAbsences?.find(childAbsence => childAbsence.childId === child?.id);
        if (!childAbsence) return;

        // Return the ChildAbsenceType name
        return childAbsenceTypes?.find(it => it.id === childAbsence.childAbsenceTypeId)?.name;
    }, [childAbsences, childAbsenceTypes, child]);

    // Handle creating ChildAttendance records and marking a child as attended.
    const handleMarkChildAsAttended = async (attended: boolean) => {
        // No need to filter by ScheduledClassId as we will only have ChildAttendances that are for this 
        // ScheduledClassId in this component
        const existing = childAttendancesManager.model.find(it => it.childId === child?.id);
        if (!existing) {
            childAttendancesManager.addFor({
                ...childAttendanceDefaultValues(),
                childId: child?.id,
                attended: attended,
                lessonDate: moment(dateInView).set('hour', scheduledClass?.startTimeHours ?? 0).set('minutes', scheduledClass?.startTimeMinutes ?? 0),
                scheduledClassId: scheduledClassId,
                attendanceMarkedByUserId: currentUserStaffId,
                termLessonNumber: Number(currentLessonNumber)
            });
        } else {
            childAttendancesManager.changeFor(existing.id, {
                attended: attended
            });
        }

        saveDebounce();
    };

    // Find the current ChildAttendance record.
    const attended = useMemo(() => {
        // No need to filter by ScheduledClassId as we will only have ChildAttendances that are for this 
        // ScheduledClassId in this component
        return childAttendancesManager.model.find(it => it.childId === child?.id);
    }, [childAttendancesManager, child]);
    
    // Save StaffNote (ProgressNote)
    const [saveProgressNote] = useSaveStaffNoteMutation();
    const [handleSaveProgressNote, { isExecuting: isSaving, errors: saveformErrors }] = useAsyncCallback(async () => {
        for (const item of staffNotesManager.added) {
            await saveProgressNote(item.id, staffNotesManager.changesFor(item.id), true);
        }

        handleTogglingModal();
        _toggleAddProgressNoteModal();
    }, [staffNotesManager]);

    // Add a new ProgressNote (StaffNote)
    const addProgressNote = useCallback(() => {
        staffNotesManager.addFor({
            ...staffNoteDefaultValues(),
            targetId: child?.id,
            targetType: 'Child',
            updatedDate: moment().toISOString(),
            updatedByUserId: currentUserStaffId,
            noteType: 'Progress'
        });
    }, [staffNotesManager, child, currentUserStaffId]);

    // Separate the various note types
    const staffNotes = useMemo(() =>
        childStaffNotes?.filter(it => it.noteType === 'General' && it.targetId === child?.id),
        [childStaffNotes, child?.id]);
    const progressNotes = useMemo(() =>
        childStaffNotes?.filter(it => it.noteType === 'Progress' && it.targetId === child?.id),
        [childStaffNotes, child?.id]);
    // We want to find only the StaffNote (ProgressNote) that was created when clicking add.
    const latestProgressNote = useMemo(() =>
        staffNotesManager?.model.filter(it => it.targetId === child?.id).pop(),
        [staffNotesManager, child?.id]);
 
    // Calculate number of days until a child's birthday
    const calculateDaysUntilBirthday = (birthday: string) => {
        const today = moment().format('YYYY-MM-DD');

        // Calculate the child's age in years
        const age = moment(today).diff(birthday, 'years');

        // Using the age to increase birthday by that number of years.
        let nextBirthday = moment(birthday).add(age, 'years');

        if (nextBirthday.isSame(today)) {
            // If birthday is today send cake icon
            return <HappyIcon icon='birthday' clickHandler={() => onModalOpen('Birthday info')} />;
        } else {
            // If birthday is not today then add 1 year to find next birthday, as it will have been this year
            nextBirthday = moment(birthday).add(age + 1, 'years');
            const daysUntilNextBirthday = nextBirthday.diff(today, 'days');
            if (daysUntilNextBirthday < 7) {
                return <HappyIcon icon='birthday' clickHandler={() => onModalOpen('Birthday info')} />;
            } else {
                return null;
            }
        }
    };

    // Modal State - We set the name (title) here, so we can use the same ModalComponent for each NoteType
    const [modalOpenName, setModalOpenName] = useState<string>();

    // Handle Modal toggling - for HealthNotes, StaffNotes, and ProgressNotes. 
    // Adding ProgressNotes is handled with its own state and Modal.
    const [modalIsOpen, _toggleModal] = useToggleState();
    const handleTogglingModal = () => {
        _toggleModal();
    };
    // When the Modal is opened we set the name (title) and then toggle the Modal.
    const onModalOpen = (modalName: string) => {
        setModalOpenName(modalName);

        handleTogglingModal();
    };

    // Adding ProgressNote Modal - When clicking the add button inside the ProgressNotes Modal (NotesModalComponent) 
    // we want to close the Modal we were in (NotesModalComponent), add a new ProgressNote to the staffNotesManager, 
    // and then show the AddProgressNotesModal 
    const [addProgressNoteModalIsOpen, _toggleAddProgressNoteModal] = useToggleState();
    const onAddProgressNoteModalOpen = () => {
        _toggleModal(); // Close the open modal
        addProgressNote(); // Create a new Progress note
        _toggleAddProgressNoteModal(); // Show the Modal
    };
    // Handle closing the AddProgressNotesModal
    const onAddProgressNoteModalClose = () => {
        _toggleAddProgressNoteModal();
    };

    // Check whether the child has any CourtesyClassCredits, we want to check here that the credit is consumed for this ScheduledClass and for the ScheduledClass runnning today
    const childHasCourtesyClassCredit = useMemo(() =>
        courtesyClassCredits?.find(it =>
            it.childId === child?.id
            && it.isConsumed === true
            && it.consumedForDate >= moment(dateInView).startOf('day').toISOString()
            && it.consumedForDate < moment(dateInView).endOf('day').toISOString()) ? true : false,
        [courtesyClassCredits, child?.id, dateInView]);

    // Render the UI
    //
    return (
        <React.Fragment key={child?.id}>
            <AlertOnErrors
                errors={[
                    saveformErrors,
                    loadErrors
                ]}
            />

            <tr>
                <td>

                    <Input
                        name="didAttend"
                        type="checkbox"
                        checked={attended?.attended ?? false}
                        onChange={(e) => handleMarkChildAsAttended(e.currentTarget.checked)}
                    />
                </td>

                <td>
                    {t('common.fullname', '{{firstName}} {{lastName}} ', { firstName: child?.firstName, lastName: child?.lastName })}

                    {/* Conditional rendering of a Badge with the AbsenceTypeName for any Children who are going to absent from this lesson */}
                    <ConditionalFragment showIf={!!absenceTypeName}>
                        <Badge color="warning">
                            {absenceTypeName}
                        </Badge>
                        <> </>
                    </ConditionalFragment>

                    {/* Conditional rendering of a Badge with the "First time in this class" for any Children who are first time attendees to this class */}
                    <ConditionalFragment showIf={isFirstTimeInThisClass}>
                        <Badge color="primary">
                            {t('staffClassesTab.staffClassItem.registerComponent.firstTime.badge', 'First time in this class')}
                        </Badge>
                        <> </>
                    </ConditionalFragment>

                    {/* Conditional rendering of a Badge with the "Courtesy class" for any Children who are here using a CourtesyClassCredit */}
                    <ConditionalFragment showIf={childHasCourtesyClassCredit}>
                        <Badge color="" style={{ backgroundColor: "#fa0050" }} >
                            {t('staffClassesTab.staffClassItem.registerComponent.courtesyClass.badge', 'Courtesy class')}
                        </Badge>
                    </ConditionalFragment>
                </td>

                <td>
                    <ConditionalFragment showIf={!!child?.healthNoteHtml}>
                        <TooltipWrapper targetId={`secret-input-${child?.id}-healthNotes`} icon="file-medical" color="#fa7600" content={''} clickHandler={() => onModalOpen('Health notes')}>
                            {t('staffClassesTab.staffClassItem.viewNotes', '{{count}} note{{plural}}', { count: 1, plural: '' })}
                        </TooltipWrapper>
                    </ConditionalFragment>
                </td>

                <td>
                    <ConditionalFragment showIf={!!staffNotes?.filter(it => it.targetId === child?.id).length}>
                        <TooltipWrapper targetId={`secret-input-${child?.id}-staffNotes`} icon="sticky-note" color="#0076fa" content={''} clickHandler={() => onModalOpen('Staff notes')}>
                            {t('staffClassesTab.staffClassItem.viewNotes', '{{count}} note{{plural}}', { count: (staffNotes?.filter(it => it.targetId === child?.id)?.length ?? 0) + (memberStaffNotes?.length ?? 0), plural: (staffNotes?.filter(it => it.targetId === child?.id)?.length ?? 0) + (memberStaffNotes?.length ?? 0) === 1 ? '' : 's' })}
                        </TooltipWrapper>
                    </ConditionalFragment>
                </td>

                <td>
                    <TooltipWrapper targetId={`secret-input-${child?.id}-progressNotes`} icon="clipboard" color="#a700fa" content={''} clickHandler={() => onModalOpen('Progress notes')}>
                        {t('staffClassesTab.staffClassItem.viewNotes', '{{count}} note{{plural}}', { count: progressNotes?.length ?? 0, plural: (progressNotes?.length ?? 0) === 1 ? '' : 's'})}
                    </TooltipWrapper>
                </td>

                <td>
                    <p>
                        {calculateDaysUntilBirthday(child?.dateOfBirth)}
                    </p>

                </td>
            </tr>

            {/* Birthday modal */}
            <ConditionalFragment showIf={modalOpenName === 'Birthday info'}>
                <StyledModal
                    isOpen={modalIsOpen}
                    toggle={handleTogglingModal}
                    size="md">
                    <>
                        <ModalHeader toggle={handleTogglingModal}>
                            <h2>{t('common.birthday', 'Birthday')}</h2>
                        </ModalHeader>

                        <ModalBody>
                            <HappyIcon icon="birthday" />
                            <> </>
                            {t('common.date', '{{date, DD/MM/YYYY}}', { date: moment(child?.dateOfBirth) })}
                        </ModalBody>
                    </>
                </StyledModal>
            </ConditionalFragment>

            {/* HealthNotes modal */}
            <ConditionalFragment showIf={modalOpenName === 'Health notes'}>
                <StyledModal
                    isOpen={modalIsOpen}
                    toggle={handleTogglingModal}
                    size="md">
                    <NotesModalComponent
                        toggle={handleTogglingModal}
                        title="Health notes"
                        child={child}
                        healthNote={child?.healthNoteHtml ?? ''}
                        onModalOpen={() => onAddProgressNoteModalOpen()}
                    />
                </StyledModal>
            </ConditionalFragment>

            {/* StaffNotes modal */}
            <ConditionalFragment showIf={modalOpenName === 'Staff notes'}>
                <StyledModal
                    isOpen={modalIsOpen}
                    toggle={handleTogglingModal}
                    size="md">
                    <NotesModalComponent
                        toggle={handleTogglingModal}
                        title="Staff notes"
                        child={child}
                        staffNotes={staffNotes}
                        onModalOpen={() => onAddProgressNoteModalOpen()}
                        memberNotes={memberStaffNotes}
                    />
                </StyledModal>
            </ConditionalFragment>

            {/* Progress Notes modal */}
            <ConditionalFragment showIf={modalOpenName === 'Progress notes'}>
                <StyledModal
                    isOpen={modalIsOpen}
                    toggle={handleTogglingModal}
                    size="md">
                    <NotesModalComponent
                        toggle={handleTogglingModal}
                        title="Progress notes"
                        child={child}
                        progressNotes={progressNotes}
                        onModalOpen={() => onAddProgressNoteModalOpen()}
                    />
                </StyledModal>
            </ConditionalFragment>

            {/* Modal used for handling adding a new ProgressNotes */}
            <ConditionalFragment showIf={addProgressNoteModalIsOpen}>
                <StyledModal
                    isOpen={addProgressNoteModalIsOpen}
                    toggle={handleTogglingModal}
                    size="md">
                    <AddProgressNoteModal
                        model={latestProgressNote}
                        toggle={onAddProgressNoteModalClose}
                        change={changes => staffNotesManager.changeFor(latestProgressNote?.id ?? '', changes)}
                        save={handleSaveProgressNote}
                        isSaving={isSaving}
                        child={child}
                        cancel={onAddProgressNoteModalClose}
                    />
                </StyledModal>
            </ConditionalFragment>
        </React.Fragment>
    );
};