import { ColouredTagSelector, ColouredTagSelectorProps } from "../../shared/colouredTagSelector/ColouredTagSelector";

export interface ClassTypeSelectorWithColorsProps {
    colors: Array<string>,
}

/**
 * Component for selecting and deselecting ClassTypes.
 */
export const ClassTypeSelectorWithColors = (props: ColouredTagSelectorProps) => {
    const { colors } = props;

    return (
        <ColouredTagSelector {...props} colors={colors} />
    );
};