import gql from 'graphql-tag';
import { Guid } from 'guid-string';
import moment from 'moment';
import { QueryMessageFieldsFragment } from '../generated/graphql';

export const queryMessageFieldsFragment = gql`
    fragment queryMessageFields on QueryMessage {
        id
        memberId
        childId
        submittedDate
        contentsHtml
        assignedToStaffUserId
        isReplyFromStaff
        hasBeenRepliedTo
        subject
        archived
        queryMessageTypeId
        threadKey
        resolved
    }
`;

export type QueryMessage = Omit<QueryMessageFieldsFragment, '__typename'>;

/**
 * Default values.
 */
export const queryMessageDefaultValues = (): Partial<QueryMessage> => ({
    id: Guid.newGuid(),
    memberId: undefined,
    childId: null,
    submittedDate: moment().toISOString(),
    contentsHtml: '',
    assignedToStaffUserId: '',
    isReplyFromStaff: false,
    hasBeenRepliedTo: false,
    subject: '',
    queryMessageTypeId: undefined,
    archived: false,
    threadKey: null,
    resolved: false,
});