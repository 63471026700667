import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import { useCallback, useState } from "react";
import { ConditionalFragment } from "react-conditionalfragment";
import { useTranslation } from "react-i18next";
import { Button, Col, ListGroup, ListGroupItem, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import { useToggleState } from "use-toggle-state";
import { Child } from "../../api/main/models/Child";
import { HtmlDisplay } from "../../shared/htmlEditor";
import { StyledModal } from "../shared/styledModal/StyledModal";
import { EditProgressNoteModal } from "./edit/EditProgressNoteModal";
import { StaffNotesWithProfile } from "./StaffClassItem";

export interface NotesModalComponentProps {
    toggle: () => void,

    title: string,
    child: Child | null | undefined,
    staffNotes?: Array<StaffNotesWithProfile> | null | undefined,
    healthNote?: string | null | undefined;
    progressNotes?: Array<StaffNotesWithProfile> | null | undefined,
    onModalOpen: () => void,
    memberNotes?: Array<StaffNotesWithProfile> | null | undefined,
};

/**
 * NotesModalComponent - used for the RegisterComponent for StaffClasses. Can display Health, Staff, and Progress notes.
 */
export const NotesModalComponent = (props: NotesModalComponentProps) => {
    const {
        toggle,
        child,
        title,
        staffNotes,
        healthNote,
        progressNotes,
        onModalOpen,
        memberNotes
    } = props;
    const { t } = useTranslation();

    const [progressNoteToEdit, setProgressNoteToEdit] = useState<string>('');
    const [editProgressModalIsOpen, toggleEditProgressNoteIsOpen] = useToggleState();
    const onEditProgressNoteOpen = useCallback((id: string) => {
        if (!id) return;

        setProgressNoteToEdit(id);
        toggleEditProgressNoteIsOpen();

    }, [setProgressNoteToEdit, toggleEditProgressNoteIsOpen]);

    // Redner the UI
    //
    return (
        <>
            <ModalHeader toggle={toggle}>
                <h2>
                    {title}
                </h2>
                <p>
                    {t('common.fullName', '{{firstName}} {{lastName}}', { firstName: child?.firstName, lastName: child?.lastName })}
                </p>
            </ModalHeader>

            <ModalBody>
                <ConditionalFragment showIf={!!healthNote}>
                    <HtmlDisplay html={child?.healthNoteHtml ?? ''} />
                </ConditionalFragment>

                <ConditionalFragment showIf={!!staffNotes}>
                    <h3>Child Notes</h3>
                    <ListGroup>
                        {staffNotes?.map((staffNote, index) => (
                            <ListGroupItem key={staffNote?.id + index}>
                                <Row>
                                    <Col className="text-muted" style={{ fontWeight: 'bold' }}>
                                        {t('staffClassesTab.staffClassItem.registerComponent.notesModalComponent.updatedOn', 'Updated on ')}
                                        {t('common.date', '{{date, DD/MM/YYYY}}', { date: moment(staffNote.updatedDate) })}
                                        {t('staffClassesTab.staffClassItem.registerComponent.notesModalComponent.by', ' by ')}
                                        {t('common.fullName', '{{firstName}} {{lastName}}', { firstName: staffNote?.profile?.firstName, lastName: staffNote?.profile?.lastName })}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <HtmlDisplay html={staffNote.contentsHtml} key={staffNote.id + child?.id + index} />
                                    </Col>
                                </Row>
                            </ListGroupItem>
                        ))}
                    </ListGroup>
                </ConditionalFragment>

                <ConditionalFragment showIf={!!progressNotes?.length}>
                    <ListGroup>
                        {progressNotes?.map(progressNote => (
                            <ListGroupItem key={progressNote?.id}>
                                <Row>
                                    <Col className="text-muted" style={{ fontWeight: 'bold' }}>
                                        {t('staffClassesTab.staffClassItem.registerComponent.notesModalComponent.updatedOn', 'Updated on ')}
                                        {t('common.date', '{{date, DD/MM/YYYY}}', { date: moment(progressNote.updatedDate) })}
                                        {t('staffClassesTab.staffClassItem.registerComponent.notesModalComponent.by', ' by ')}
                                        {t('common.fullName', '{{firstName}} {{lastName}}', { firstName: progressNote?.profile?.firstName, lastName: progressNote?.profile?.lastName })}
                                    </Col>

                                    <Col xs="auto">
                                        <Button color="primary" outline onClick={() => onEditProgressNoteOpen(progressNote?.id)}>
                                            <FontAwesomeIcon icon="edit" />
                                            <> </>
                                            {t('common.edit', 'Edit')}
                                        </Button>
                                    </Col>
                                </Row>

                                <HtmlDisplay html={progressNote.contentsHtml} key={progressNote.id} />

                            </ListGroupItem>
                        ))}
                    </ListGroup>
                </ConditionalFragment>

                <ConditionalFragment showIf={!!memberNotes}>
                    <h3>Member Notes</h3>
                    <ListGroup>
                        {memberNotes?.map((memberNote, index) => (
                            <ListGroupItem key={memberNote?.id + index}>
                                <Row>
                                    <Col className="text-muted" style={{ fontWeight: 'bold' }}>
                                        {t('staffClassesTab.staffClassItem.registerComponent.notesModalComponent.updatedOn', 'Updated on ')}
                                        {t('common.date', '{{date, DD/MM/YYYY}}', { date: moment(memberNote.updatedDate) })}
                                        {t('staffClassesTab.staffClassItem.registerComponent.notesModalComponent.by', ' by ')}
                                        {t('common.fullName', '{{firstName}} {{lastName}}', { firstName: memberNote?.profile?.firstName, lastName: memberNote?.profile?.lastName })}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <HtmlDisplay html={memberNote.contentsHtml} key={memberNote.id + child?.id + index} />
                                    </Col>
                                </Row>
                            </ListGroupItem>
                        ))}
                    </ListGroup>
                </ConditionalFragment>
            </ModalBody>

            <ModalFooter>
                <ConditionalFragment showIf={!!progressNotes}>
                    <Col>
                        <Button color="primary" outline onClick={() => onModalOpen()}>
                            {t('staffClassesTab.staffClassItem.registerComponent.notesModalComponent.addProgressNote', 'Add progress note')}
                        </Button>
                    </Col>
                </ConditionalFragment>

                <Col xs="auto">
                    <Button color="primary" onClick={() => toggle()}>{t('common.close', 'Close')}</Button>
                </Col>
            </ModalFooter>

            <ConditionalFragment showIf={editProgressModalIsOpen}>
                <StyledModal
                    isOpen={editProgressModalIsOpen}
                    toggle={() => toggleEditProgressNoteIsOpen()}
                    size="md">
                    <EditProgressNoteModal id={progressNoteToEdit} toggleEditProgressNoteIsOpen={toggleEditProgressNoteIsOpen} />
                </StyledModal>
            </ConditionalFragment>
        </>
    );
};