import { IdentityRoles } from "../../configure/security/IdentityRoles";
import { RouteEntry } from "../../shared/routes";
import { ChildAbsencesList } from "./ChildAbsencesList";


/**
 * Routes that should be added to configuration/routeConfig.ts to provide access to these components via routing.
 */
export const childAbsenceRoutes: Array<RouteEntry> = [
    { path: '/administration/children-management/child-absences', element: <ChildAbsencesList />, authorize: true, requireRole: IdentityRoles.Administration, },
];
