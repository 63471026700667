import * as React from 'react';
import { useLazyQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { AsyncLoadResult, AsyncLoadOptions } from '../../../../shared/abstractStore';
import { mainApiConfig } from '../../../../configure/mainApiConfig';
import { EditScheduledClassSupportingDataQuery } from '../../generated/graphql';
import { classTypeFieldsFragment } from '../../models/ClassType';
import { classStageFieldsFragment } from '../../models/ClassStage';
import { classSubStageFieldsFragment } from '../../models/ClassSubStage';
import { classLocationFieldsFragment } from '../../models/ClassLocation';
import { classLocationClassTypeFieldsFragment } from '../../models/ClassLocationClassType';
import { termFieldsFragment } from '../../models/Term';
import { Guid } from 'guid-string';
import { staffNoteFieldsFragment } from '../../models/StaffNote';
import { staffFieldsFragment } from '../../models/Staff';

/**
 * Get a the supporting data needed for dropdowns etc. for the EditScheduledClass component.
 * @param id
 * @param options
 */
export function useEditScheduledClassSupportingData(id: string | undefined | null, options: AsyncLoadOptions = {}): AsyncLoadResult<EditScheduledClassSupportingDataQuery> {
    // Query the data we need form the api.
    const [load, { data, loading, error, refetch }] = useLazyQuery<EditScheduledClassSupportingDataQuery>(
        gql`
        query editScheduledClassSupportingDataQuery ($id: ID!) {
            classTypes {
                ...classTypeFields
            }

            classStages {
                ...classStageFields
            }

            classSubStages {
                ...classSubStageFields
            }

            classLocations {
                ...classLocationFields
            }

            classLocationClassTypes {
                ...classLocationClassTypeFields
            }

            profiles (isStaff: true) {
                id
                userId
                firstName
                lastName
                archived
                primaryEmail
                secondaryEmail
                primaryPhone
                secondaryPhone
                addressLine1
                addressLine2
                city
                county
                postcode
                country
                genderId
                isStaff
                isMember
                
                user {
                    id
                    email
                }
            }

            terms {
                ...termFields
            }

            staffNotes (targetId: $id) {
                ...staffNoteFields    
            }

            staffs {
                ...staffFields
            }
        }

        ${classTypeFieldsFragment}
        ${classStageFieldsFragment}
        ${classSubStageFieldsFragment}
        ${classLocationFieldsFragment}
        ${classLocationClassTypeFieldsFragment}
        ${termFieldsFragment}
        ${staffNoteFieldsFragment}
        ${staffFieldsFragment}
        `,
        {
            variables: {
                id: id ?? Guid.empty
            },
            fetchPolicy: options.fetchPolicy ?? mainApiConfig.defaultFetchPolicy,
            pollInterval: options.pollInterval,
            onCompleted: (data) => {
                if (options.onCompleted) {
                    options.onCompleted(data);
                }
            }
        }
    );

    // Run the query (unless we are being lazy).
    React.useEffect(() => {
        if (!options.lazy) {
            load();
        }
    }, [options.lazy, load])

    // Return the results.
    const refresh = React.useCallback(async () => { !refetch ? await load() : await refetch(); }, [refetch, load]);
    return React.useMemo(() => ({
        data: data ?? ({} as any),
        refresh: refresh,
        isLoading: loading,
        errors: error
    }), [data, refresh, loading, error]);
}
