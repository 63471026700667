import { NavItem } from 'reactstrap';
import { AuthorizeContainer } from '../../../shared/authorizeRoute';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IdentityRoles } from '../../../configure/security/IdentityRoles';
import { NavLinkTrackActive } from '../../shared/navLinkTrackActive/NavLinkTrackActive';
import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { StaffQualificationsOverdueBadgeComponent } from '../../staffQualifications/StaffQualifcationsOverdueBadgeComponent';

/**
 * Sub navigation menu for the app. Works in conjunction with HeaderNavigation.
 */
export const HeaderSubNavigation = () => {
    const { t } = useTranslation();
    const { pathname: locationPathname } = useLocation();

    // Because we have so many routes, our header navigation groups by role.
    // Each role appears under a grouping of the route.  This allows us to cope with
    // users who have all the roles.  By clicking into a top level route, we go on to
    // make the options under that group visible, while "collapsing" the other groups
    // to a single link.
    const routeGroup = useMemo(() => {
        const lowerPathname = locationPathname.toLocaleLowerCase();
        if (lowerPathname.startsWith('/administration')) {
            return 'administration';
        } else if (lowerPathname.startsWith('/staff')) {
            return 'staff';
        } else if (lowerPathname.startsWith('/my')) {
            return 'member';
        }

        // If we get here we're not within any group, so return none which will likely show all
        // the top level navigation options.
        return 'none';
    }, [locationPathname]);

    return (
        <>
            {/* Member */}
            {
                routeGroup === 'member' ? (
                    <AuthorizeContainer>
                        <NavItem>
                            <NavLinkTrackActive to="/my/children">
                                {/*<FontAwesomeIcon icon="child-reaching" className="nav-icon" />*/}
                                <span data-route-group={routeGroup} >{t('headerSubNavigation.myBearCubs.name', 'My Bear Cubs')}</span>
                            </NavLinkTrackActive>
                        </NavItem>
                        <NavItem>
                            <NavLinkTrackActive to="/my/payments">
                                {/*<FontAwesomeIcon icon="money-bill-1-wave" className="nav-icon" />*/}
                                <span data-route-group={routeGroup}>{t('headerSubNavigation.payments.name', 'Payments')}</span>
                            </NavLinkTrackActive>
                        </NavItem>
                        <NavItem>
                            <NavLinkTrackActive to="/my/messages">
                                {/*<FontAwesomeIcon icon="comment" className="nav-icon" />*/}
                                <span data-route-group={routeGroup}>{t('headerSubNavigation.messages.name', 'Messages')}</span>
                            </NavLinkTrackActive>
                        </NavItem>
                    </AuthorizeContainer>
                ) : null
            }

            {/* Staff */}
            {
                routeGroup === 'staff' ? (
                    <AuthorizeContainer requireRole={IdentityRoles.Staff}>
                        <NavItem>
                            <NavLinkTrackActive to="/staff/classes">
                                <FontAwesomeIcon icon="person-chalkboard" className="nav-icon" />
                                <span>{t('headerSubNavigation.classes.name', 'Classes')}</span>
                            </NavLinkTrackActive>
                        </NavItem>
                        <NavItem>
                            <NavLinkTrackActive to="/staff/lesson-plans">
                                <FontAwesomeIcon icon="book-open" className="nav-icon" />
                                <span>{t('headerSubNavigation.lessonPlans.name', 'Lesson plans')}</span>
                            </NavLinkTrackActive>
                        </NavItem>
                        <NavItem>
                            <NavLinkTrackActive to="/staff/qualifications">
                                <FontAwesomeIcon icon="certificate" className="nav-icon" />
                                <span>
                                    {t('headerSubNavigation.qualifications.name', 'Qualifications')}

                                </span>
                                {/* Renders a badge with the number of Overdue Qualifications for the current user */}
                                <StaffQualificationsOverdueBadgeComponent />
                            </NavLinkTrackActive>
                        </NavItem>
                        <NavItem>
                            <NavLinkTrackActive to="/staff/messages">
                                <FontAwesomeIcon icon="comment" className="nav-icon" />
                                <span>{t('headerSubNavigation.messages.name', 'Messages')}</span>
                            </NavLinkTrackActive>
                        </NavItem>
                        <NavItem>
                            <NavLinkTrackActive to="/staff/time-off">
                                <FontAwesomeIcon icon="calendar" className="nav-icon" />
                                <span>{t('headerSubNavigation.timeOff.name', 'Time off')}</span>
                            </NavLinkTrackActive>
                        </NavItem>
                </AuthorizeContainer>
                ) : null
            }
           

            {/* Administration */}
            {
                routeGroup === 'administration' ? (
                <AuthorizeContainer requireRole={IdentityRoles.Administration}>
                        <NavItem>
                            <NavLinkTrackActive to="/administration/class-management">
                                <FontAwesomeIcon icon="person-chalkboard" className="nav-icon" />
                                <span>{t('headerSubNavigation.classes.name', 'Classes')}</span>
                            </NavLinkTrackActive>
                        </NavItem>
                        <NavItem>
                            <NavLinkTrackActive to="/administration/children-management">
                                <FontAwesomeIcon icon="child-reaching" className="nav-icon" />
                                <span>{t('headerSubNavigation.members.name', 'Members')}</span>
                            </NavLinkTrackActive>
                        </NavItem>
                        <NavItem>
                            <NavLinkTrackActive to="/administration/staff-management">
                                <FontAwesomeIcon icon="people-group" className="nav-icon" />
                                <span>{t('headerSubNavigation.staffManagement.name', 'Staff management')}</span>
                            </NavLinkTrackActive>
                        </NavItem>
                        <NavItem>
                            <NavLinkTrackActive to="/administration/messages">
                                <FontAwesomeIcon icon="comment" className="nav-icon" />
                                <span>{t('headerSubNavigation.messages.name', 'Messages')}</span>
                            </NavLinkTrackActive>
                        </NavItem>
                        <NavItem>
                            <NavLinkTrackActive to="/administration/dashboard">
                                <FontAwesomeIcon icon="gauge" className="nav-icon" />
                                <span>{t('headerSubNavigation.dashboard.name', 'Dashboard')}</span>
                            </NavLinkTrackActive>
                        </NavItem>
                </AuthorizeContainer>
                ) : null
            }
        </>
    );
};
