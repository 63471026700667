import { ValidationErrors } from 'pojo-validator';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, FormGroup, Label, ListGroup } from 'reactstrap';
import { Profile } from '../../../../api/main/models/Profile';
import { StaffQualificationCheck, staffQualificationCheckDefaultValues } from '../../../../api/main/models/StaffQualificationCheck';
import { ModelArrayChanges } from '../../../../shared/useChanges';
import { QualificationCheckComponent } from './QualificationCheckComponent';

export interface QualificationChecksTabProps {
    model: Profile | null | undefined,

    staffQualificationChecksManager: ModelArrayChanges<StaffQualificationCheck, string>
    validateStaffQualificationCheck: (model: StaffQualificationCheck) => boolean,
    staffQualificationCheckValidationErrors: (id: string) => ValidationErrors
}

/**
 * StaffQualificationChecksTab for EditStaff
 */
export const QualificationChecksTab = (props: QualificationChecksTabProps) => {
    const { model, staffQualificationChecksManager, validateStaffQualificationCheck, staffQualificationCheckValidationErrors } = props;
    const { t } = useTranslation();

    //Add a new StaffQualification and link it to the parent model
    const addStaffQualificationCheck = useCallback(() => {
        staffQualificationChecksManager.addFor({ ...staffQualificationCheckDefaultValues(), staffId: model?.id });
    }, [staffQualificationChecksManager, model]);

    // Render the UI
    //
    return (
        <>
            <FormGroup>
                <Label htmlFor='staffQualificationChecks'>
                    {t('editStaff.qualificationCheckTab.headingLabel', 'Relevant qualifications:')}
                </Label>

                <ListGroup id='staffQualificationChecks'>
                    {
                        staffQualificationChecksManager.model.map(item => (
                            <QualificationCheckComponent key={item.id}
                                model={item}
                                change={changes => staffQualificationChecksManager.changeFor(item.id, changes)}
                                remove={() => staffQualificationChecksManager.removeFor(item.id)}
                                validate={() => validateStaffQualificationCheck(item)}
                                validationErrors={staffQualificationCheckValidationErrors(item.id)}
                            />
                        ))
                    }
                </ListGroup>

                <div className='mt-2'>
                    <Button color='primary' outline onClick={() => addStaffQualificationCheck()}>
                        {t('editStaff.qualificationCheckTab.addQualificationCheck', 'Add qualification')}
                    </Button>
                </div>
            </FormGroup>
        </>
    );
}