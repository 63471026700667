import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useValidatorCallback } from "pojo-validator-react";
import { useMemo, useState } from "react";
import { ConditionalFragment } from "react-conditionalfragment";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { useAsyncCallback } from "react-use-async-callback";
import { Alert, Badge, Button, Col, NavItem, NavLink, Row, Spinner } from "reactstrap";
import { ButtonAsync } from "reactstrap-buttonasync";
import { useChangeAccountEmailCallback, useInviteCallback, useResendInviteEmailCallback } from "../../../api/account";
import { useSaveMemberMutation } from "../../../api/main/members/useSaveMemberMutation";
import { Member, memberDefaultValues } from "../../../api/main/models/Member";
import { Profile, profileDefaultValues } from "../../../api/main/models/Profile";
import { userDefaultValues } from "../../../api/main/models/User";
import { useSaveProfileMutation } from "../../../api/main/profiles/useSaveProfileMutation";
import { useEditMemberViewModel } from "../../../api/main/profiles/viewModels/useEditMemberViewModel";
import { useProfileSupportingData } from "../../../api/main/profiles/viewModels/useProfileSupportingData";
import { AlertOnErrors } from "../../../shared/alertOnErrors";
import { useChanges, useChangesArray } from "../../../shared/useChanges";
import { hasAnyValidationErrors } from "../../../utilities/hasAnyValidationErrors";
import { MainTab } from "./MainTab";
import { Banner } from "../../shared/banner/Banner";
import { FormButtons } from "../../shared/formButtons/FormButtons";
import { LoadingIndicator } from "../../shared/loadingIndicator/LoadingIndicator";
import { MainContainer } from "../../shared/mainContainer/MainContainer";
import { PillsNavBar } from "../../shared/pillsNavBar/PillsNavBar";
import { StaffNote } from "../../../api/main/models/StaffNote";
import { useSaveStaffNoteMutation } from "../../../api/main/staffNotes/useSaveStaffNoteMutation";
import { useDeleteStaffNoteMutation } from "../../../api/main/staffNotes/useDeleteStaffNote";
import { useValidatorArrayCallback } from "../../../shared/validator-react-contrib/useValidatorArrayCallback";
import moment from "moment";
import { findLastUpdatedStaffNote } from "../../../utilities/findLastUpdatedStaffNote";
import { useBlobReference } from "../../../api/main/blobReferences/useBlobReference";
import { BlobUploadService } from "../../../api/main/blobReferences/BlobUploadService";
import { PhotoComponent } from "./photosTab/PhotoComponent";
import { ChildrenTab } from "./childrenTab/ChildrenTab";
import { useReplaceSearchParamsEffect, useSearchParams } from "../../../shared/useURLSearchParams";
import { PaymentsTab } from "./paymentsTab/PaymentsTab";
import { StaffNotesTab } from "../../staffNotes/StaffNotesTab";
import { EventsTab } from "./eventsTab/EventsTab";

export interface EditMemberProps {
    isCreate?: boolean,
    onCreateDefaultValues?: () => Partial<Profile>,
    defaultRoleGroup?: string,
    filterRoleGroups?: (groups: Array<{ id: string, name: string; }>) => Array<{ id: string, name: string; }>,
}

/**
 * Create a new Member
 */
export const CreateMember = (props: EditMemberProps) => (<EditMember isCreate={true} {...props} />);

/**
 * Edit a Member (actually a profile)
 */
export const EditMember = (props: EditMemberProps) => {
    const {
        isCreate,
        onCreateDefaultValues,
    } = props;
    const { t } = useTranslation();
    const { id } = useParams<{ id: string | undefined; }>();

    // Load all data
    const { data: {
        model: storeModel,
        member: storeMemberModel,
        staffNotes,
        children,
        scheduledClassChildEvents
    }, isLoading: _isLoading, errors: loadErrors } = useEditMemberViewModel(id);

    // Supporting data (dependant on the current user's subscription)
    const { data: {
        genders,
        staffProfiles
    }, isLoading: isLoadingSupportingData, errors: loadSupportingDataErrors } = useProfileSupportingData();
    const isLoading = _isLoading || isLoadingSupportingData;

    // Model (Profile)
    const { model, change, changes } = useChanges(storeModel, isCreate ? { ...profileDefaultValues(), isMember: true, ...(onCreateDefaultValues ? onCreateDefaultValues() : {}) } : undefined);
    const [save, { errors: saveErrors }] = useSaveProfileMutation();

    // Model (User)
    const { model: userModel, change: changeUserModel } = useChanges(storeModel?.user, isCreate ? { ...userDefaultValues() } : {});

    // Model (Member)
    const { model: memberModel, change: changeMemberModel, changes: memberModelChanges } = useChanges<Member>(storeMemberModel, isCreate ? { ...memberDefaultValues() } : undefined);
    const [saveMember, { errors: saveMemberErrors }] = useSaveMemberMutation();

    // Member image upload (photo)
    const { data: { model: image }, errors: imageLoadErrors } = useBlobReference(memberModel?.photoBlobReferenceId);
    const [onUploadImage, { errors: imageUploadErrors, isExecuting: isUploadingImage }] = useAsyncCallback(async (files: FileList | null) => {
        if (!files) {
            return;
        }
        let uploadService: BlobUploadService = new BlobUploadService("/api/blobs");
        let result = await uploadService.upload(files);

        if (!!result) {
            changeMemberModel({ photoBlobReferenceId: result.id });
        }
    }, [change]);

    // Clear image functionality
    const [clearImage, { isExecuting: isClearingImage }] = useAsyncCallback(async () => {
        changeMemberModel({ photoBlobReferenceId: null });
    }, [change]);

    // Model (StaffNotes)
    const memberStaffNotesManager = useChangesArray<StaffNote, string>(staffNotes, item => item.id);
    const [saveStaffNote] = useSaveStaffNoteMutation();
    const [removeStaffNote] = useDeleteStaffNoteMutation();

    // Invite
    const [invite, { errors: inviteErrors }] = useInviteCallback();
    const [resendInviteEmail, { isExecuting: isResendingInviteEmail, errors: resendInviteEmailErrors }] = useResendInviteEmailCallback();
    const [hasResentInviteEmail, setHasResentInviteEmail] = useState<boolean>(false);

    // Chnage email
    const [changeAccountEmail, { errors: changeEmailErrors }] = useChangeAccountEmailCallback();

    const navigate = useNavigate();

    // StaffNotes validation
    const [validateMemberStaffNote, memberStaffNoteValidationErrors] = useValidatorArrayCallback<StaffNote>((myModel, validation, fieldsToCheck) => {
        const rules = {
            noteType: () => !myModel?.noteType ? t('editMember.staffNote.errors.noteType', 'Note type is required') : '',
        };

        validation.checkRules(rules, fieldsToCheck);
    }, []);

    // Main model validation
    const [validate, validationErrors] = useValidatorCallback((validation, fieldsToCheck) => {
        const rules = {
            firstName: () => !model?.firstName ? t('editMember.firstNameRequired', 'First name is required') : '',
            lastName: () => !model?.lastName ? t('editMember.lastNameRequired', 'Last name is required') : '',
            primaryEmail: () => !model?.primaryEmail ? t('editMember.emailRequired', 'A primary email address is required') : '',
            genderId: () => !model?.genderId ? t('editMember.genderRequired', 'A gender is required') : '',

            // Any errors in the related object
            staffNotes: () => memberStaffNotesManager.model.filter(it => !validateMemberStaffNote(it)).length ? t('editMember.errors.staffNotes', 'One or more of the staff notes are invalid') : ''
        };

        validation.checkRules(rules, fieldsToCheck);
    }, [model, userModel]);

    // Save everything
    const [saveForm, { isExecuting: isSaving, errors: saveFormErrors }] = useAsyncCallback(async (options?: { dontNavigate?: boolean, navigateTo?: string, }) => {
        if (!validate()) {
            return;
        }

        // If we are creating this member, make sure we save the member and get its id before continuing with saving the profile.
        let userId = model.userId;
        if (isCreate) {
            // Create an invited member but don't send the invite yet, we'll send it after the profile has been saved; so we can include their name
            // and make it more personal for higher engagement.
            const result = await invite({ ...userModel, sendEmail: false });
            userId = result.userId;
            changeUserModel({ id: userId });
            change({ userId: userId });
            changeMemberModel({ userId: userId });
        }

        // Make sure the id of the Member record always matches the id of the Profile record.
        if (memberModel.id !== model.id) {
            changeMemberModel({ id: model.id });
        }

        // Save the profile.
        await save(model.id, { ...changes, userId: userId }, !!isCreate);

        // Save the member record.
        // NOTE we always want the id to match the profile's id so we handle this here.
        // We also will create a member record if one doesn't exist, even if we are not isCreate == true.  To identify this we look for when the memberModel.id is not the same as the model.id.
        await saveMember(memberModel.id, { ...memberModelChanges, id: model.id, userId: userId }, (!!isCreate || memberModel.id !== model.id));

        // Saves changes to the StaffNote
        for (const item of memberStaffNotesManager.added) { await saveStaffNote(item.id, memberStaffNotesManager.changesFor(item.id), true); }
        for (const item of memberStaffNotesManager.updated) { await saveStaffNote(item.id, memberStaffNotesManager.changesFor(item.id), false); }
        for (const item of memberStaffNotesManager.removed) { await removeStaffNote(item.id); }
        memberStaffNotesManager.markAsSaved();

        // If we created a member invite, send the email now the profile has been saved so it can include more personal information (e.g. their name).
        if (isCreate) {
            await resendInviteEmail(userModel.email);
        }

        // Handle changes to the email address of existing users.
        if (!isCreate && storeModel && storeModel.user.email !== userModel.email) {
            await changeAccountEmail(storeModel.user.email, userModel.email);
        }

        // Navigate to the next screen.
        if (!options?.dontNavigate) {
            if (options?.navigateTo) {
                navigate(options.navigateTo);
            } else {
                navigate(-1);
            }
        }
    }, [validate, save, model, changes, isCreate, navigate, userModel, invite, changeUserModel, change,
        changeAccountEmail, resendInviteEmail, memberModelChanges, memberModel, saveMember, memberStaffNotesManager, validateMemberStaffNote, changeMemberModel]);

    // Manage the tab being displayed.  If we have tab= on the query string, default to it, otherwise default to the main tab.
    const { tab: tabParam } = useSearchParams();
    const [activeTab, setActiveTab] = useState<'main' | 'photo' | 'children' | 'payments' | 'discounts' | 'staff-notes' | 'events'>(tabParam as any || 'main');

    // Keep the URL up to date with the currentTab
    useReplaceSearchParamsEffect({ tab: activeTab === 'main' ? '' : activeTab });

    // Call function to return the last updated note
    const lastNoteUpdate = useMemo(() => {
        return findLastUpdatedStaffNote(staffNotes);
    }, [staffNotes]);

    // Render the UI
    //
    return (
        <>
            <Banner>
                <Row>
                    <Col xs={12} md="auto">
                        <h1>
                            {
                                isCreate ? t('editMember.createHeading.default', 'Add member')
                                    : t('editMember.editHeading.default', 'Edit member')
                            }
                        </h1>
                        <h3>{t('editMember.subheading.name', '{{ firstName }} {{ lastName }}', { firstName: model?.firstName ?? '', lastName: model?.lastName ?? '' })}</h3>
                    </Col>
                    <Col>
                        <PillsNavBar>
                            <NavItem>
                                <NavLink active={activeTab === 'main'} onClick={() => setActiveTab('main')}>
                                    {t('editMember.nav.main', 'Member details')}

                                    {/* Show a warning triangle if any fields for this tab have validation errors */}
                                    <ConditionalFragment showIf={hasAnyValidationErrors([validationErrors['firstName'], validationErrors['lastName'], validationErrors['primaryEmail'],])}>
                                        <> </><FontAwesomeIcon icon="exclamation-triangle" color="danger" />
                                    </ConditionalFragment>
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink active={activeTab === 'photo'} onClick={() => setActiveTab('photo')}>
                                    {t('editMember.nav.photo', 'Photo')}
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink active={activeTab === 'children'} onClick={() => setActiveTab('children')}>
                                    {t('editMember.nav.children', 'Booking history')}
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink active={activeTab === 'payments'} onClick={() => setActiveTab('payments')}>
                                    {t('editMember.nav.payment', 'Payments')}
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink active={activeTab === 'staff-notes'} onClick={() => setActiveTab('staff-notes')}>
                                    {t('editMember.nav.staffNotes.name', 'Notes on member')}
                                    <> </>
                                    {
                                        lastNoteUpdate !== null ? (
                                            <>
                                                <Badge pill color="secondary">
                                                    {t('editMember.nav.staffNotes.badgeUpdated', 'Updated: ')} {t('common.date', '{{date, DD/MM/YYYY}}', { date: moment(lastNoteUpdate.updatedDate), })}
                                                </Badge>
                                            </>
                                        ) : null
                                    }
                                    {/* Show a warning triangle if any fields for this tab have validation errors */}
                                    <ConditionalFragment showIf={hasAnyValidationErrors([validationErrors['staffNotes'],])}>
                                        <> </><FontAwesomeIcon icon="exclamation-triangle" color="danger" />
                                    </ConditionalFragment>
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink active={activeTab === 'events'} onClick={() => setActiveTab('events')}>
                                    {t('editMember.nav.events', 'Events')}
                                    {' '}
                                    <ConditionalFragment showIf={scheduledClassChildEvents?.length > 0}>
                                        <Badge pill color="secondary">
                                            {scheduledClassChildEvents?.length}
                                        </Badge>
                                    </ConditionalFragment>
                                </NavLink>
                            </NavItem>
                        </PillsNavBar>
                    </Col>
                    <ConditionalFragment showIf={isLoading}>
                        <Col xs="auto">
                            <LoadingIndicator size="sm" />
                        </Col>
                    </ConditionalFragment>
                </Row>
            </Banner>

            <MainContainer>
                <AlertOnErrors errors={[
                    loadErrors,
                    loadSupportingDataErrors,
                    saveFormErrors,
                    saveErrors,
                    inviteErrors,
                    resendInviteEmailErrors,
                    changeEmailErrors,
                    saveMemberErrors,
                    imageLoadErrors,
                    imageUploadErrors
                ]} />

                {
                    hasResentInviteEmail ? (
                        <Alert color="success" >
                            <>{t('editMember.confirmationEmailHasBeenResent', 'Invite email for this user has been resent.  Please ask the user to check their email to confirm.')} </>
                            <ButtonAsync type="button" color="success" onClick={async e => { e.preventDefault(); await resendInviteEmail(storeModel?.user?.email ?? userModel.email); }}
                                isExecuting={isResendingInviteEmail}
                                executingChildren={<><Spinner size="sm" />{t('common.sending', 'Sending...')}</>}>
                                {t('common.resendEmail', 'Resend email')}
                            </ButtonAsync>
                        </Alert>
                    ) : null
                }

                {
                    activeTab === 'main' ? (
                        <MainTab
                            model={model}
                            change={change}
                            validate={validate}
                            validationErrors={validationErrors}
                            saveForm={saveForm}
                            memberModel={memberModel}
                            changeMemberModel={changeMemberModel}
                            resendInviteEmail={resendInviteEmail}
                            setHasResentInviteEmail={setHasResentInviteEmail}
                            isResendingInviteEmail={isResendingInviteEmail}
                            genders={genders}
                            userModel={userModel}
                            changeUserModel={changeUserModel}
                            isCreate={isCreate}
                            storeModel={storeModel}
                        />
                    ) : activeTab === 'photo' ? (
                        <PhotoComponent
                            saveForm={saveForm}
                            isExecutingImageUpload={isUploadingImage}
                            onImageUpload={onUploadImage}
                            isExecutingClearImage={isClearingImage}
                            imageUploadClickHandler={clearImage}
                            imageUrl={image?.url}
                        />
                    ) : activeTab === 'children' ? (
                        <ChildrenTab
                            children={children}
                            memberId={memberModel?.id}
                        />
                    ) : activeTab === 'payments' ? (
                        <PaymentsTab
                            children={children}
                        />
                    ) : activeTab === 'staff-notes' ? (
                        <StaffNotesTab
                            model={{ targetId: model.id, targetType: 'Member', headingName: 'Member', modalHeading: t('common.fullName', '{{firstName}} {{lastName}}', { firstName: model.firstName, lastName: model.lastName }) }}
                            staffNotesManager={memberStaffNotesManager}
                            validateStaffNote={validateMemberStaffNote}
                            staffNoteValidationErrors={memberStaffNoteValidationErrors}
                            staffProfiles={staffProfiles}
                            saveForm={saveForm}
                        />
                    ) : activeTab === 'events' ? (
                        <EventsTab
                            events={scheduledClassChildEvents}
                        />
                    ) : null
                }
                <FormButtons>
                    <ConditionalFragment showIf={!isLoading}>
                        <ButtonAsync color="primary" isExecuting={isSaving} onClick={() => saveForm()}
                            executingChildren={<><Spinner size="sm" /> {t('common.saving', 'Saving...')}</>}>
                            <FontAwesomeIcon icon="save" />
                            <> {t('common.save', 'Save')}</>
                        </ButtonAsync>
                    </ConditionalFragment>
                    <Button type="button" color="primary" outline onClick={e => navigate(-1)}>
                        {t('common.cancel', 'Cancel')}
                    </Button>
                </FormButtons>
            </MainContainer>
        </>
    );
};