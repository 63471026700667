import { gql, useLazyQuery } from "@apollo/client";
import { Guid } from "guid-string";
import React from "react";
import { mainApiConfig } from "../../../../configure/mainApiConfig";
import { AsyncLoadOptions, AsyncLoadResult } from "../../../../shared/abstractStore";
import { ConsumeScheduledClassForEnrollInClassViewModelQuery, ConsumeScheduledClassForEnrollInClassViewModelQueryVariables } from '../../generated/graphql';
import { childsRelationshipFieldsFragment } from "../../models/ChildsRelationship";
import { classLocationFieldsFragment } from "../../models/ClassLocation";
import { classStageFieldsFragment } from "../../models/ClassStage";
import { classSubStageFieldsFragment } from "../../models/ClassSubStage";
import { classTypeFieldsFragment } from "../../models/ClassType";
import { genderFieldsFragment } from "../../models/Gender";
import { scheduledClassFieldsFragment } from "../../models/ScheduledClass";
import { termFieldsFragment } from "../../models/Term";

export function useConsumeScheduledClassForEnrollInClassViewModel(scheduledClassId: string | undefined | null, options: AsyncLoadOptions = {}): AsyncLoadResult<ConsumeScheduledClassForEnrollInClassViewModelQuery> {
    // Query the data we need from the api
    const [load, { data, loading, error, refetch }] = useLazyQuery<ConsumeScheduledClassForEnrollInClassViewModelQuery, ConsumeScheduledClassForEnrollInClassViewModelQueryVariables>(
        gql`
         query consumeScheduledClassForEnrollInClassViewModelQuery ($scheduledClassId: ID!) {
             model: scheduledClassForAnonymousUser (id: $scheduledClassId) {
                 ...scheduledClassFields
                 
                 classLocation: classLocationForAnonymousUser {
                     ...classLocationFields
                 }
             }
            
             classTypes: classTypesForAnonymousUser {
                 ...classTypeFields
             }

             genders: gendersForAnonymousUser {
                 ...genderFields
             }

             childsRelationships: childsRelationshipsForAnonymousUser {
                 ...childsRelationshipFields
             }

             terms: termsForAnonymousUser {
                 ...termFields
             }

             classStages: classStagesForAnonymousUser {
                 ...classStageFields
             }

             classSubStages: classSubStagesForAnonymousUser {
                 ...classSubStageFields
             }
         }

         ${scheduledClassFieldsFragment}
         ${classLocationFieldsFragment}
         ${classTypeFieldsFragment}
         ${genderFieldsFragment}
         ${childsRelationshipFieldsFragment}
         ${termFieldsFragment}
         ${classStageFieldsFragment}
         ${classSubStageFieldsFragment}
        `,
        {
            variables: {
                scheduledClassId: scheduledClassId ?? Guid.empty,
            },
            fetchPolicy: options.fetchPolicy ?? mainApiConfig.defaultFetchPolicy,
            pollInterval: options.pollInterval,
            onCompleted: (data) => {
                if (options.onCompleted) {
                    options.onCompleted(data);
                }
            }
        }
    );

    // Run the query (unless we are being lazy).
    React.useEffect(() => {
        if (!options.lazy) {
            load();
        }
    }, [options.lazy, load]);

    // Return the results.
    const refresh = React.useCallback(async () => { !refetch ? await load() : await refetch(); }, [refetch, load]);
    return React.useMemo(() => ({
        data: data ?? ({} as any),
        refresh: refresh,
        isLoading: loading,
        errors: error,
    }), [data, refresh, loading, error]);
};