import { TFunction } from "i18next";


/**
 * Role of a staff member in the delivery of a ScheduledClass used by the RoleInClass field of the ScheduledClassStaff model.
 */
export enum RoleInClass {
    PrimaryTeacher = 'PrimaryTeacher',
    AdditionalTeacher = 'AdditionalTeacher',
    Coordinator = 'Coordinator',
}

/**
 * Returns all RoleInClass options, suitable for use in a list.
 */
export function getRoleInClasss(): Array<RoleInClass> {
    const ret = Object.keys(RoleInClass).map(key => key as RoleInClass);
    return ret;
}

/**
 * Convert a RoleInClass to its display name. 
 */
export function roleInClassDisplayName(name: RoleInClass | string, t: TFunction): string {
    switch (name) {
        case RoleInClass.PrimaryTeacher: return t('roleInClass.primaryTeacher.name', 'Primary teacher');
        case RoleInClass.AdditionalTeacher: return t('roleInClass.additionalTeacher.name', 'Additional teacher');
        case RoleInClass.Coordinator: return t('roleInClass.coordinator.name', 'Cub coordinator');
        default: return t('roleInClass.default.name', 'Unknown');
    }
}
