import { IdentityRoles } from "../../configure/security/IdentityRoles";
import { RouteEntry } from "../../shared/routes";
import { DeleteGiftVoucher } from "./DeleteGiftVoucher";
import { CreateGiftVoucher, EditGiftVoucher } from "./edit/EditGiftVoucher";
import { GiftVouchersList } from "./GiftVouchersList";

/**
 * Routes that should be added to configuration/routeConfig.ts to provide access to these components via routing.
 */
export const giftVouchersRoutes: Array<RouteEntry> = [
    { path: '/administration/children-management/gift-vouchers/add', element: <CreateGiftVoucher />, authorize: true, requireRole: IdentityRoles.Administration, },
    { path: '/administration/children-management/gift-vouchers/edit/:id', element: <EditGiftVoucher />, authorize: true, requireRole: IdentityRoles.Administration, },
    { path: '/administration/children-management/gift-vouchers/delete/:id', element: <DeleteGiftVoucher />, authorize: true, requireRole: IdentityRoles.Administration, },
    { path: '/administration/children-management/gift-vouchers', element: <GiftVouchersList />, authorize: true, requireRole: IdentityRoles.Administration, },
];
