import * as React from 'react';
import { useSaveInStore, SaveInStoreOptions } from '../../../shared/useSaveInStore';
import { useUpdateChildDiscountMutation } from './useUpdateChildDiscountMutation';
import { ChildDiscount } from '../models/ChildDiscount';
import { useCreateChildDiscountMutation } from './useCreateChildDiscountMutation';
import { ChildDiscountCreateInput, ChildDiscountUpdateInput } from '../generated/graphql';

/**
 * Get a callback to save a ChildDiscount in the api using either a create or update.
 */
export function useSaveChildDiscountMutation(options: SaveInStoreOptions<ChildDiscount, string> = {}) {
    const [_create, createStatus] = useCreateChildDiscountMutation();
    const create = React.useCallback((model: Partial<ChildDiscount>) => _create(model as ChildDiscountCreateInput), [_create]);

    const [_update, updateStatus] = useUpdateChildDiscountMutation();
    const update = React.useCallback((id: string, changes: Partial<ChildDiscount>) => _update(id, changes as ChildDiscountUpdateInput), [_update]);

    return useSaveInStore(
        [create, createStatus],
        [update, updateStatus],
        options
    );
}
