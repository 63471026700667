import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ReactNode, useState } from "react";
import { ConditionalFragment } from "react-conditionalfragment";
import { Tooltip } from "reactstrap";

export interface TooltipWrapperProps {
    targetId: string,
    content?: string,
    icon: string,
    color: string,
    clickHandler: () => void,
    children: ReactNode,
}

export const TooltipWrapper = (props: TooltipWrapperProps) => {
    const { targetId, children, icon, color, clickHandler, content } = props;

    const [isOpen, setIsOpen] = useState(false);

    const toggleToolTip = () => setIsOpen(!isOpen);

    return (
        <div
            id={targetId}
            onMouseEnter={() => toggleToolTip()}
            onMouseLeave={() => toggleToolTip()}>

            <ConditionalFragment showIf={!!content}>
                {content}
                <> </>
            </ConditionalFragment>

            <FontAwesomeIcon
                color={color}
                cursor="pointer"
                icon={icon as IconProp}
                onClick={clickHandler}
            />

            <Tooltip placement="left" isOpen={isOpen} target={targetId}>
                {children}
            </Tooltip>
        </div>
    );
};