import * as React from 'react';
import { useSaveInStore, SaveInStoreOptions } from '../../../shared/useSaveInStore';
import { useUpdateClassLocationRequirementCheckMutation } from './useUpdateClassLocationRequirementCheckMutation';
import { ClassLocationRequirementCheck } from '../models/ClassLocationRequirementCheck';
import { useCreateClassLocationRequirementCheckMutation } from './useCreateClassLocationRequirementCheckMutation';
import { ClassLocationRequirementCheckCreateInput, ClassLocationRequirementCheckUpdateInput } from '../generated/graphql';

/**
 * Get a callback to save a ClassLocationRequirementCheck in the api using either a create or update.
 */
export function useSaveClassLocationRequirementCheckMutation(options: SaveInStoreOptions<ClassLocationRequirementCheck, string> = {}) {
    const [_create, createStatus] = useCreateClassLocationRequirementCheckMutation();
    const create = React.useCallback((model: Partial<ClassLocationRequirementCheck>) => _create(model as ClassLocationRequirementCheckCreateInput), [_create]);

    const [_update, updateStatus] = useUpdateClassLocationRequirementCheckMutation();
    const update = React.useCallback((id: string, changes: Partial<ClassLocationRequirementCheck>) => _update(id, changes as ClassLocationRequirementCheckUpdateInput), [_update]);

    return useSaveInStore(
        [create, createStatus],
        [update, updateStatus],
        options
    );
}
