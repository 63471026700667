import moment from "moment";
import { ConditionalFragment } from "react-conditionalfragment";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { Col, Row } from "reactstrap";
import { useChild } from "../../api/main/children/useChild";
import { AlertOnErrors } from "../../shared/alertOnErrors";
import { Banner } from "../shared/banner/Banner";
import { LoadingIndicator } from "../shared/loadingIndicator/LoadingIndicator";
import { MainContainer } from "../shared/mainContainer/MainContainer";
import { StickyToolbar } from "../shared/stickyToolbar/StickyToolbar";

/**
 * Component that allows a Staff member to view child details - used for QueryMessages when the child may be the subject of a message
 */
export const StaffChildDetails = () => {
    const {
        childId,
    } = useParams<{ childId: string | undefined, }>();
    const { t } = useTranslation();

    // Load the data
    const {
        data: {
            model
        }, isLoading, errors: loadErrors
    } = useChild(childId);

    // Render the UI
    //
    return (
        <>
            <Banner fluid>
                <StickyToolbar>
                    <Row>
                        <Col>
                            <h1>
                                {t('staffChildDetails.title', 'Cub details')}
                            </h1>
                            <Row className="align-items-center mb-2">
                                <Col>
                                    <h3>
                                        {t('common.fullName', '{{firstName}} {{lastName}}', { firstName: model?.firstName ?? '', lastName: model?.lastName ?? '' })}
                                    </h3>
                                </Col>
                            </Row>
                        </Col>
                        <ConditionalFragment showIf={isLoading}>
                            <Col xs="auto">
                                <LoadingIndicator size="sm" />
                            </Col>
                        </ConditionalFragment>

                    </Row>
                </StickyToolbar>
            </Banner>

            <MainContainer fluid>
                <AlertOnErrors
                    errors={[loadErrors]}
                />

                <Row>
                    <Col>
                        {t('common.fullName', '{{firstName}} {{lastName}}', { firstName: model?.firstName, lastName: model?.lastName })}
                    </Col>
                    <Col>
                        {t('common.date', '{{date, DD/MM/YYYY}}', { date: moment(model?.dateOfBirth) })}
                    </Col>
                </Row>

                <Row>
                    <Col>
                        TODO - Determine what details we need to show here - This component is not being used. The links from QueryMessages have been removed
                    </Col>
                </Row>
            </MainContainer>
        </>
    );
};