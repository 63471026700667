import { RouteEntry } from "../../shared/routes";
import { DeleteMember } from "./DeleteMember";
import { CreateMember, EditMember } from "../members/edit/EditMember";
import { MembersList } from "./MembersList";
import { IdentityRoles } from "../../configure/security/IdentityRoles";
import { CreateChild } from "../children/edit/EditChild";

export const memberRoutes: Array<RouteEntry> = [
    { path: '/administration/children-management/members/addChildFor/:memberId', element: <CreateChild />, authorize: true, requireRole: IdentityRoles.Administration },
    { path: '/administration/children-management/members/edit/:id', element: <EditMember />, authorize: true, requireRole: IdentityRoles.Administration },
    { path: '/administration/children-management/members/delete/:id', element: <DeleteMember />, authorize: true, requireRole: IdentityRoles.Administration },
    { path: '/administration/children-management/members/add', element: <CreateMember />, authorize: true, requireRole: IdentityRoles.Administration },
    { path: '/administration/children-management/members', element: <MembersList />, authorize: true, requireRole: IdentityRoles.Administration }
];
