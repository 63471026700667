import moment from "moment";
import { useTranslation } from "react-i18next";
import { Col, ListGroupItem, ListGroupItemText, Row } from "reactstrap";
import { BlobReference } from "../../api/main/models/BlobReference";
import { QueryMessage } from "../../api/main/models/QueryMessage";
import { ProfileImageComponent } from "../profileImageComponent/ProfileImageComponent";

export interface QueryMessageItemProps {
    message: QueryMessage,
    profileImage: BlobReference | undefined | null,
    isMemberView?: boolean,
}

export const QueryMessageItem = (props: QueryMessageItemProps) => {
    const { t } = useTranslation();
    const {
        message,
        profileImage,
        isMemberView,
    } = props;

    // Render the UI
    //
    return (
        <ListGroupItem>
            <Row>
                <Col xs={isMemberView ? 3 : 1}>
                    <ProfileImageComponent imageUrl={profileImage?.url ?? null} small={true} isReplyFromStaff={message.isReplyFromStaff} />
                </Col>
                <Col xs={isMemberView ? 9 : 11} className="d-flex flex-column justify-content-between">
                    <ListGroupItemText>
                        {message.contentsHtml}
                    </ListGroupItemText>
                    <small style={{ color: '#737373' }}>
                        {isMemberView ? (
                            <span>{message.isReplyFromStaff ? t('common.receivedOn', 'Received on') : t('common.sentOn', 'Sent on')}</span>
                        ) : (
                            <span>{message.isReplyFromStaff ? t('common.sentOn', 'Sent on') : t('common.receivedOn', 'Received on')}</span>
                        )}
                        <> </>
                        {t('common.dateTimeLong', '{{date, dddd, MMMM Do YYYY, HH:mm:ss}}', { date: moment(message.submittedDate) })}
                    </small>
                </Col>
            </Row>
        </ListGroupItem>
    );
};