import { Button, Form, Label, FormGroup, Spinner, Input, Row, Col } from 'reactstrap';
import { AlertOnErrors } from '../../../shared/alertOnErrors';
import { LoadingIndicator } from '../../shared/loadingIndicator/LoadingIndicator';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MainContainer } from '../../shared/mainContainer/MainContainer';
import { useParams, useNavigate } from 'react-router';
import { FormButtons } from '../../shared/formButtons/FormButtons';
import { ButtonAsync } from 'reactstrap-buttonasync';
import { useAsyncCallback } from 'react-use-async-callback';
import { ConditionalFragment } from 'react-conditionalfragment';
import { Banner } from '../../shared/banner/Banner';
import { useDeleteScheduledClassMutation } from '../../../api/main/scheduledClasses/useDeleteScheduledClass';
import { useScheduledClass } from '../../../api/main/scheduledClasses/useScheduledClass';
import { useEditScheduledClassSupportingData } from '../../../api/main/scheduledClasses/viewModels/useEditScheduledClassSupportingData';
import { useMemo } from 'react';
import { getScheduledClassSummary } from '../getScheduledClassSummary';
import { dayOfWeekDisplayName } from '../../../api/main/models/constants/DayOfWeek';

/**
 * Delete a ScheduledClass
 */
export const DeleteScheduledClass = () => {
    const { t } = useTranslation();
    const { id } = useParams<{ id: string }>();
    const navigate = useNavigate();

    const {
        data: {
            model,
        },
        errors: loadErrors, isLoading: _isLoading,
    } = useScheduledClass(id);
    const {
        data: {
            classTypes,
            classStages,
            classSubStages,
            classLocations,
        },
        errors: loadSupportingDataErrors,
        isLoading: isLoadingSupportingData
    } = useEditScheduledClassSupportingData(model?.classLocationId ?? '');
    const isLoading = _isLoading || isLoadingSupportingData;

    // Delete the scheduledClass.
    const [remove, { errors: removeErrors }] = useDeleteScheduledClassMutation();

    // When we submit, delete the scheduledClass.
    const [onRemoveFormSubmit, { isExecuting: isRemoving, errors: removeFormErrors }] = useAsyncCallback(async () => {
        await remove(id ?? '');
        navigate(-1);
    }, [remove, navigate]);

    // Generate the summary descrition for the class.
    const summaryText = useMemo(() => getScheduledClassSummary(
        model,
        {
            classLocation: classLocations?.find(item => item.id === model?.classLocationId),
            classStage: classStages?.find(item => item.id === model?.currentClassStageId),
            classSubStage: classSubStages?.find(item => item.id === model?.currentClassSubStageId),
        }
    ), [model, classLocations, classStages, classSubStages]);

    // Render the UI.
    //
    return (
        <>
            <Banner>
                <Row>
                    <Col>
                        <h1>{t('deleteScheduledClass.heading', 'Are you sure you want to delete this scheduled class?')}</h1>
                        <h3>{summaryText}</h3>
                    </Col>
                    <ConditionalFragment showIf={isLoading}>
                        <Col xs="auto">
                            <LoadingIndicator size="sm" />
                        </Col>
                    </ConditionalFragment>
                </Row>
            </Banner>

             <MainContainer>
                <AlertOnErrors errors={[loadErrors, loadSupportingDataErrors, removeFormErrors, removeErrors, ]} />

                <Form onSubmit={e => { e.preventDefault(); onRemoveFormSubmit(); }}>
                    <FormGroup>
                        <Label htmlFor="classTypeId">{t('editScheduledClass.mainTab.classTypeId.label', 'Activity')}</Label>
                        <Input readOnly name="classTypeId" type="text" value={classTypes?.find(item => item.id === model?.classTypeId)?.name ?? ''} />
                    </FormGroup>

                    
                    <FormGroup>
                        <Label htmlFor="classLocationId">{t('editScheduledClass.mainTab.classLocationId.label', 'Location')}</Label>
                        <Input readOnly name="classLocationId" type="text" value={classLocations?.find(item => item.id === model?.classLocationId)?.name ?? ''} />
                    </FormGroup>

                    {/* Day and time */}
                    <h4>{t('editScheduledClass.mainTab.schedulingHeading', 'Scheduling')}</h4>
                    <Row>
                        <Col>
                            <FormGroup>
                                <Label htmlFor="dayOfWeek">{t('editScheduledClass.mainTab.dayOfWeek.label', 'Day')}</Label>
                                <Input readOnly name="dayOfWeek" type="text" value={dayOfWeekDisplayName(model?.dayOfWeek ?? 99, t)} />
                            </FormGroup>
                        </Col>
                        <Col md="auto">
                            <FormGroup readOnly>
                                <Label htmlFor="startTimeHours">{t('editScheduledClass.mainTab.startTimeHours.label', 'Start time')}</Label>
                                <div>
                                    <Input readOnly style={{ display: 'inline-block', maxWidth: '60px', textAlign: 'right', }} name="startTimeHours" type="number" min={0} max={23} value={model?.startTimeHours ?? 0} />
                                    :
                                    <Input readOnly style={{ display: 'inline-block', maxWidth: '60px', }} name="startTimeMinutes" type="number" min={0} max={59} value={model?.startTimeMinutes ?? 0} />
                                </div>
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup>
                                <Label htmlFor="endTimeHours">{t('editScheduledClass.mainTab.endTimeHours.label', 'End time')}</Label>
                                <div>
                                    <Input readOnly style={{ display: 'inline-block', maxWidth: '60px', textAlign: 'right', }} name="endTimeHours" type="number" min={0} max={23} value={model?.endTimeHours ?? 0} />
                                    :
                                    <Input readOnly style={{ display: 'inline-block', maxWidth: '60px', }} name="endTimeMinutes" type="number" min={0} max={59} value={model?.endTimeMinutes ?? 0} />
                                </div>
                            </FormGroup>
                        </Col>
                    </Row>

                    <FormButtons>
                            <ButtonAsync color="danger" isExecuting={isRemoving}
                                executingChildren={<> <Spinner size="sm" /> {t('common.deleting', 'Deleting...')} </>}>
                                <FontAwesomeIcon icon="trash" />
                                <> </>
                                {t('common.delete', 'Delete')}
                            </ButtonAsync>
                        <Button type="button" color="primary" outline onClick={e => navigate(- 1)}>
                            {t('common.cancel', 'Cancel')}
                        </Button>
                    </FormButtons>
                </Form>
            </MainContainer>
        </>
    );

}
