import gql from 'graphql-tag';
import { Guid } from 'guid-string';
import { CancellationReasonFieldsFragment } from '../generated/graphql';

export const cancellationReasonFieldsFragment = gql`
    fragment cancellationReasonFields on CancellationReason {
        id
        name
        displayOrder
        archived
    }
`;

export type CancellationReason = Omit<CancellationReasonFieldsFragment, '__typename'>;

/**
 * Default values.
 */
export const cancellationReasonDefaultValues = (): Partial<CancellationReason> => ({
    id: Guid.newGuid(),
    name: '',
    displayOrder: 99,
    archived: false,
});