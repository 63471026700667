
import { RouteEntry } from "../../shared/routes";
import { ClassFinder } from "./ClassFinder";
import { ClassSearch } from "./classSearchCard/ClassSearch";
import { EnrollInClass, EnrollInClassWaitingList } from "./EnrollInClass";
import { StagesInformationStatic } from "./StagesInformationStatic";

export const classFinderRoutes: Array<RouteEntry> = [
    { path: '/class-search', element: <ClassSearch />, },
    { path: '/class-finder', element: <ClassFinder />, },
    { path: '/class-finder/enrol-in-class/:scheduledClassId/:dateOfBirth', element: <EnrollInClass /> },
    { path: '/class-finder/join-class-waiting-list/:scheduledClassId/:dateOfBirth', element: <EnrollInClassWaitingList /> },

    // Static page for Stages information, copied from happycubs.ie/swim-cubs/stages
     { path: '/stages-information', element: <StagesInformationStatic />, },
];
