import * as React from 'react';
import { Button, Form, Label, FormGroup, Spinner, Input, Row, Col } from 'reactstrap';
import { AlertOnErrors } from '../../shared/alertOnErrors';
import { LoadingIndicator } from '../shared/loadingIndicator/LoadingIndicator';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MainContainer } from '../shared/mainContainer/MainContainer';
import { useParams, useNavigate } from 'react-router';
import { FormButtons } from '../shared/formButtons/FormButtons';
import { ButtonAsync } from 'reactstrap-buttonasync';
import { useAsyncCallback } from 'react-use-async-callback';
import { useProfile } from '../../api/main/profiles/useProfile';
import { useLockoutCallback } from '../../api/account';
import { ConditionalFragment } from 'react-conditionalfragment';
import { Banner } from '../shared/banner/Banner';
import { useDeleteProfileMutation } from '../../api/main/profiles/useDeleteProfileMutation';
import { useDeleteStaffMutation } from '../../api/main/staffs/useDeleteStaffMutation';

/**
 * Delete a user (profile) by locking them out permanently.
 */
export const DeleteStaff = () => {
    const { t } = useTranslation();
    const { id } = useParams<{ id: string }>();
    const { data: { model }, isLoading, errors: loadErrors } = useProfile(id);
    const [remove, { errors: removeErrors }] = useDeleteProfileMutation();
    const [removeStaff, { errors: removeStaffErrors }] = useDeleteStaffMutation();
    const [lockout, { errors: lockoutErrors }] = useLockoutCallback();
    const navigate = useNavigate();

    const [onRemoveFormSubmit, { isExecuting: isRemoving, errors: removeFormErrors }] = useAsyncCallback(async () => {
        if (!model || !model.user) {
            return;
        }
        await removeStaff(model.id);
        await remove(model.id);
        await lockout({ email: model.user.email, archive: true, });

        navigate(-1);
    }, [lockout, model, navigate, removeStaff ]);

    return (
        <>
            <Banner>
                <Row>
                    <Col>
                        <h1>{t('deleteStaff.heading.default', 'Are you sure you want to delete this staff member?')}</h1>
                    </Col>
                    <ConditionalFragment showIf={isLoading}>
                        <Col xs="auto">
                            <LoadingIndicator size="sm" />
                        </Col>
                    </ConditionalFragment>
                </Row>
            </Banner>

            <MainContainer>
                <AlertOnErrors errors={[loadErrors, removeFormErrors, removeErrors, removeStaffErrors,lockoutErrors]} />

                <Form onSubmit={e => { e.preventDefault(); onRemoveFormSubmit(); }}>
                    <Row>
                        <Col>
                            <FormGroup>
                                <Label htmlFor="firstName">{t('deleteStaff.firstName', 'First name')}</Label>
                                <Input name="firstName" type="text" readOnly plaintext value={model?.firstName ?? ''} />
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup>
                                <Label htmlFor="lastName">{t('deleteStaff.lastName', 'Last name')}</Label>
                                <Input name="lastName" type="text" readOnly plaintext value={model?.lastName ?? ''} />
                            </FormGroup>
                        </Col>
                    </Row>
                    <FormGroup>
                        <Label htmlFor="email">{t('deleteStaff.email', 'Email')}</Label>
                        <Input name="lastName" type="text" readOnly plaintext value={model?.user?.email ?? ''} />
                    </FormGroup>

                    <FormButtons>
                        <ConditionalFragment showIf={!isLoading}>
                            <ButtonAsync color="danger" isExecuting={isRemoving}
                                executingChildren={<><Spinner size="sm" /> {t('common.deleting', 'Deleting...')}</>}>
                                <FontAwesomeIcon icon="trash" />
                                <> {t('common.delete', 'Delete')}</>
                            </ButtonAsync>
                        </ConditionalFragment>
                        <Button type="button" color="primary" outline onClick={e => navigate(-1)}>
                            {t('common.cancel', 'Cancel')}
                        </Button>
                    </FormButtons>
                </Form>
            </MainContainer>
        </>
    );
};
