import { gql, useMutation } from "@apollo/client";
import { AsyncActionStatus } from "../../../../shared/abstractStore";
import { CreateChildAttendanceAsCourtesyClassResult, CreateChildAttendanceAsCourtesyClassMutation, CreateChildAttendanceAsCourtesyClassMutationVariables } from '../../generated/graphql';
import { useAsyncCallback } from "react-use-async-callback";

/**
 * Get a callback to create a ChildAttendance in the api. Used for consuming courtesyClassCredits
 */
export function useCreateChildAttendanceAsCourtesyClassMutation(): [(childId: string, scheduledClassId: string, courtesyClassCreditId: string, lessonDate: string) => Promise<CreateChildAttendanceAsCourtesyClassResult>, AsyncActionStatus] {
    // Query data we need from the api.
    const [mutationAction, { error }] = useMutation<CreateChildAttendanceAsCourtesyClassMutation, CreateChildAttendanceAsCourtesyClassMutationVariables>(
        gql`
        mutation createChildAttendanceAsCourtesyClassMutation ($childId: ID!, $scheduledClassId: ID!, $courtesyClassCreditId: ID!, $lessonDate: DateTimeOffset!) {
            createChildAttendanceAsCourtesyClass (childId: $childId, scheduledClassId: $scheduledClassId, courtesyClassCreditId: $courtesyClassCreditId, lessonDate: $lessonDate) {
                success
                warningMessage
                errorMessage
            }
        }
        `
    );

    // Wrap the mutation in a standard callback.
    const [action, { isExecuting, errors: actionErrors }] = useAsyncCallback(async (childId: string, scheduledClassId: string, courtesyClassCreditId: string, lessonDate: string) => {
        const result = await mutationAction({ variables: { childId, scheduledClassId, courtesyClassCreditId, lessonDate } });
        if (result.errors && result.errors.length) {
            throw result.errors;
        }

        return result.data?.createChildAttendanceAsCourtesyClass ?? { success: false, errorMessage: 'Failed to join courtesy class', warningMessage: '' };
    }, [mutationAction]);

    return [action, { isExecuting: isExecuting, errors: error ? error.message : actionErrors }];
};