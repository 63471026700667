import gql from 'graphql-tag';
import { Guid } from 'guid-string';
import { ClassLocationRequirementCheckFieldsFragment } from '../generated/graphql';

export const classLocationRequirementCheckFieldsFragment = gql`
    fragment classLocationRequirementCheckFields on ClassLocationRequirementCheck {
        id
        name
        classLocationId
        reviewDate
        archived
        evidenceBlobReferenceId
    }
`;

export type ClassLocationRequirementCheck = Omit<ClassLocationRequirementCheckFieldsFragment, '__typename'>;

/**
 * Default values.
 */
export const classLocationRequirementCheckDefaultValues = (): Partial<ClassLocationRequirementCheck> => ({
    id: Guid.newGuid(),
    name: '',
    classLocationId: undefined,
    reviewDate: null,
    archived: false,
    evidenceBlobReferenceId: null,
});