import { Button, Row, Col, Form, Label, FormGroup, Spinner } from 'reactstrap';
import { AlertOnErrors } from '../../shared/alertOnErrors';
import { LoadingIndicator } from '../shared/loadingIndicator/LoadingIndicator';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MainContainer } from '../shared/mainContainer/MainContainer';
import { useParams, useNavigate } from 'react-router';
import { useChanges } from '../../shared/useChanges';
import { useValidatorCallback } from 'pojo-validator-react';
import { ValidatedInput } from 'pojo-validator-reactstrap';
import { FormButtons } from '../shared/formButtons/FormButtons';
import { ButtonAsync } from 'reactstrap-buttonasync';
import { useAsyncCallback } from 'react-use-async-callback';
import { ConditionalFragment } from 'react-conditionalfragment';
import { Banner } from '../shared/banner/Banner';
import { StickyToolbar } from '../shared/stickyToolbar/StickyToolbar';
import { useChildsRelationship } from '../../api/main/childsRelationships/useChildsRelationship';
import { ChildsRelationship, childsRelationshipDefaultValues } from '../../api/main/models/ChildsRelationship';
import { useSaveChildsRelationshipMutation } from '../../api/main/childsRelationships/useSaveChildsRelationshipMutation';

export interface EditChildsRelationshipProps {
    isCreate?: boolean,
    onCreateDefaultValues?: () => Partial<ChildsRelationship>
}

/**
 * Component to create an Gender.
 */
export const CreateChildsRelationship = (props: EditChildsRelationshipProps) => (<EditChildsRelationship isCreate={true} {...props} />);

/**
 * Component to edit a ChildsRelationship.
 */
export const EditChildsRelationship = (props: EditChildsRelationshipProps) => {
    const {
        isCreate,
        onCreateDefaultValues,
    } = props

    const { t } = useTranslation();
    const { id } = useParams<{ id: string | undefined }>();
    const navigate = useNavigate();


    // Load all data.
    const {
        data: {
            model: storeModel,
        },
        errors: loadErrors, isLoading,
    } = useChildsRelationship(id);


    // Model (ChildsRelationship)
    const { model, change, changes } = useChanges(storeModel, isCreate ? { ...childsRelationshipDefaultValues(), ...(onCreateDefaultValues ? onCreateDefaultValues() : {}) } : undefined);
    const [saveChildsRelationship, { errors: saveErrors }] = useSaveChildsRelationshipMutation();

    // Main model validation.
    const [validate, validationErrors] = useValidatorCallback((validation, fieldsToCheck) => {
        const rules = {
            name: () => !model?.name ? t('editChildsRelationship.errors.name', 'Name cannot be empty') : '',
        };
        validation.checkRules(rules, fieldsToCheck);
    }, [model]);

    // Save everything.
    const [saveForm, { isExecuting: isSaving, errors: saveFormErrors }] = useAsyncCallback(async () => {
        if (!model) {
            return;
        }

        if (!validate()) {
            return;
        }

        // Save the main model.
        await saveChildsRelationship(model.id, { ...changes }, isCreate ?? false);

        // Go back to previous screen.
        navigate(-1);
    }, [validate, saveChildsRelationship, model, changes, isCreate, id, navigate]);

    return (
        <>
            <Banner>
                <StickyToolbar>
                    <Row>
                        <Col>
                            <h1>
                                {
                                    isCreate ? t('editChildsRelationship.createHeading.default', 'Add Relationship')
                                        : t('editChildsRelationship.editHeading.default', 'Edit Relationship')
                                }
                            </h1>
                            <h3>{model?.name}</h3>
                        </Col>
                        <ConditionalFragment showIf={isLoading}>
                            <Col xs="auto">
                                <LoadingIndicator size="sm" />
                            </Col>
                        </ConditionalFragment>
                    </Row>
                </StickyToolbar>
            </Banner>
            <MainContainer>
                <AlertOnErrors errors={[
                    loadErrors,
                    saveFormErrors, saveErrors,
                ]} />

                <Form onSubmit={e => { e.preventDefault(); saveForm(); }}>
                    <FormGroup>
                        <Label htmlFor="name">{t('editChildsRelationship.name.label', 'Name')}</Label>
                        <ValidatedInput name="name" type="text" value={model?.name ?? ''} onChange={e => change({ name: e.currentTarget.value })} onBlur={e => validate('name')} validationErrors={validationErrors['name']} />
                    </FormGroup>
                    <FormGroup>
                        <Label htmlFor="name">{t('editChildsRelationship.displayOrder.label', 'Order')}</Label>
                        <ValidatedInput name="displayOrder" type="number" value={model?.displayOrder ?? ''} onChange={e => change({ displayOrder: e.currentTarget.valueAsNumber })} onBlur={e => validate('displayOrder')} validationErrors={validationErrors['displayOrder']} />
                    </FormGroup>

                    <FormButtons>
                        <ConditionalFragment showIf={!isLoading}>
                            <ButtonAsync color="primary" isExecuting={isSaving} onClick={() => saveForm()}
                                executingChildren={<><Spinner size="sm" /> {t('common.saving', 'Saving...')}</>}>
                                <FontAwesomeIcon icon="save" />
                                <> </>
                                {t('common.save', 'Save')}
                            </ButtonAsync>
                        </ConditionalFragment>

                        <Button type="button" color="primary" outline onClick={e => navigate(-1)}>
                            {t('common.cancel', 'Cancel')}
                        </Button>
                    </FormButtons>
                </Form>
            </MainContainer>
        </>
    );
}