import { useMutation } from "@apollo/react-hooks";
import { useAsyncCallback } from 'react-use-async-callback';
import gql from "graphql-tag";
import { AsyncActionStatus } from '../../../shared/abstractStore';
import { CreateTermHolidaysForLocationMutation, CreateTermHolidaysForLocationMutationVariables } from '../generated/graphql';
import { termHolidayFieldsFragment } from '../models/TermHoliday';

/**
 * Get a callback to create TermHolidays for a ClassLocation in the api.
 */
export function useCreateTermHolidaysForLocationMutation(): [(termId: string, locationId: string, holidayDates: Array<Date>) => Promise<void>, AsyncActionStatus] {
    const [mutationAction, { error }] = useMutation<CreateTermHolidaysForLocationMutation, CreateTermHolidaysForLocationMutationVariables>(
        gql`
            mutation createTermHolidaysForLocationMutation ($termId: ID!, $locationId: ID!, $holidayDates: [DateTimeOffset!]!) {
                createTermHolidaysForLocation(termId: $termId, locationId: $locationId, holidayDates: $holidayDates) {
                    ...termHolidayFields
                }
            }

            ${termHolidayFieldsFragment}
        `
    );

    // Wrap the mutation in a standard callback.
    const [action, { isExecuting, errors: actionErrors }] = useAsyncCallback(async (termId: string, locationId: string, holidayDates: Array<Date>) => {
        const result = await mutationAction({ variables: { termId: termId, locationId: locationId, holidayDates: holidayDates  } });
        if (result.errors && result.errors.length) {
            throw result.errors;
        }
    }, [mutationAction]);

    return [action, { isExecuting: isExecuting, errors: error ? error.message : actionErrors }];
}