import gql from 'graphql-tag';
import { BackgroundTaskFieldsFragment } from '../generated/graphql';

export const backgroundTaskFieldsFragment = gql`
    fragment backgroundTaskFields on BackgroundTask {
        id
        name
        argumentsJson
        taskStatus
        scheduledForAfter
        priority
        userId
        queuedDate
        startedDate
        completedDate
        resultsJosn
        executionErrors
    }
`;

export type BackgroundTask = Omit<BackgroundTaskFieldsFragment, '__typename'>;

// NOTE BackgroundTasks cannot be created front end, so there is no backgroundTaskDefaultValues() function.
