import { RouteEntry } from "../../shared/routes";
import { DeleteStaff } from "./DeleteStaff";
import { CreateStaff, EditStaff } from "./edit/EditStaff";
import { StaffsList } from "./StaffsList";
import { TeachersList } from "./TeachersList";
import { CoordinatorsList } from "./CoordinatorsList";
import { AdminStaffsList } from "./AdminStaffsList";
import { ExpiringQualificationsList } from "./ExpiringQualificationsList";
import { ApproveQualificationsList } from "./approveQualifications/ApproveQualificationsList";
import { IdentityRoles } from "../../configure/security/IdentityRoles";

export const staffRoutes: Array<RouteEntry> = [
    { path: '/administration/staff-management/staff/add', element: <CreateStaff />, authorize: true, requireRole: IdentityRoles.Administration },
    { path: '/administration/staff-management/staff/edit/:id', element: <EditStaff />, authorize: true, requireRole: IdentityRoles.Administration },
    { path: '/administration/staff-management/staff/delete/:id', element: <DeleteStaff />, authorize: true, requireRole: IdentityRoles.Administration },
    { path: '/administration/staff-management/staff', element: <StaffsList />, authorize: true, requireRole: IdentityRoles.Administration },

    { path: '/administration/staff-management/teachers/add', element: <CreateStaff />, authorize: true, requireRole: IdentityRoles.Administration },
    { path: '/administration/staff-management/teachers/edit/:id', element: <EditStaff />, authorize: true , requireRole: IdentityRoles.Administration},
    { path: '/administration/staff-management/teachers/delete/:id', element: <DeleteStaff />, authorize: true, requireRole: IdentityRoles.Administration },
    { path: '/administration/staff-management/teachers', element: <TeachersList />, authorize: true, requireRole: IdentityRoles.Administration },

    { path: '/administration/staff-management/coordinators/add', element: <CreateStaff />, authorize: true, requireRole: IdentityRoles.Administration },
    { path: '/administration/staff-management/coordinators/edit/:id', element: <EditStaff />, authorize: true , requireRole: IdentityRoles.Administration},
    { path: '/administration/staff-management/coordinators/delete/:id', element: <DeleteStaff />, authorize: true , requireRole: IdentityRoles.Administration},
    { path: '/administration/staff-management/coordinators', element: <CoordinatorsList />, authorize: true, requireRole: IdentityRoles.Administration },

    { path: '/administration/staff-management/office-staff/add', element: <CreateStaff />, authorize: true, requireRole: IdentityRoles.Administration },
    { path: '/administration/staff-management/office-staff/edit/:id', element: <EditStaff />, authorize: true, requireRole: IdentityRoles.Administration },
    { path: '/administration/staff-management/office-staff/delete/:id', element: <DeleteStaff />, authorize: true , requireRole: IdentityRoles.Administration},
    { path: '/administration/staff-management/office-staff', element: <AdminStaffsList />, authorize: true, requireRole: IdentityRoles.Administration },

    { path: '/administration/staff-management/expiring-qualifications', element: <ExpiringQualificationsList />, authorize: true, requireRole: IdentityRoles.Administration },
    { path: '/administration/staff-management/approve-qualifications', element: <ApproveQualificationsList />, authorize: true, requireRole: IdentityRoles.Administration }
];
