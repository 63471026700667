import { TFunction } from "i18next";


/**
 * Status of a background task.  Keep this matched up with BackgroundTaskStatus.cs.
 */
export enum BackgroundTaskStatus {
    Queued = "Queued",
    Executing = "Executing",
    Completed = "Completed",
    Cancelled = "Cancelled",
}

/**
 * Returns all BackgroundTaskStatus options, suitable for use in a list.
 */
export function getBackgroundTaskStatus(): Array<BackgroundTaskStatus> {
    const ret = Object.keys(BackgroundTaskStatus).map(key => key as BackgroundTaskStatus);
    return ret;
}

/**
 * Convert a BackgroundTaskStatus to its display name. 
 */
export function backgroundTaskStatusDisplayName(name: BackgroundTaskStatus | string, t: TFunction): string {
    switch (name) {
        case BackgroundTaskStatus.Queued: return t('backgroundTaskStatus.queued.name', 'Queued');
        case BackgroundTaskStatus.Executing: return t('backgroundTaskStatus.executing.name', 'Executing');
        case BackgroundTaskStatus.Completed: return t('backgroundTaskStatus.completed.name', 'Completed');
        case BackgroundTaskStatus.Cancelled: return t('backgroundTaskStatus.cancelled.name', 'Cancelled');
        default: return t('backgroundTaskStatus.default.name', '');
    }
}
