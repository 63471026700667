import { ApexOptions } from "apexcharts";
import { useMemo } from "react";
import Chart from "react-apexcharts";
import { ConditionalFragment } from "react-conditionalfragment";
import { useTranslation } from "react-i18next";

export type QueryMessageViewModelData = {
    noReply: number,
    repliedLongerThanSevenDays: number,
    repliedWithinOneDay: number,
    repliedWithinSevenDays: number,
    repliedWithinThreeDays: number,
    repliedWithinTwoDays: number,
};

export interface QueryMessagesDonutChartProps {
    chartData: QueryMessageViewModelData | null | undefined,
}

/**
 * QueryMessages DonutChart for the QueryMessages Report
 * @param props
 * @returns
 */
export const QueryMessagesDonutChart = (props: QueryMessagesDonutChartProps) => {
    const { chartData } = props;

    const { t } = useTranslation();

    // Data formatted for the chart
    const formattedData = useMemo(() => {
        if (!chartData) {
            return [];
        }

        return [
            { label: t('queryMessagesDonutChart.dataLabels.oneDay', 'One day'), value: chartData.repliedWithinOneDay },
            { label: t('queryMessagesDonutChart.dataLabels.twoDays', 'Two days'), value: chartData.repliedWithinTwoDays },
            { label: t('queryMessagesDonutChart.dataLabels.threeDays', 'Three days'), value: chartData.repliedWithinThreeDays },
            { label: t('queryMessagesDonutChart.dataLabels.withinAWeek', 'Within a week'), value: chartData.repliedWithinSevenDays },
            { label: t('queryMessagesDonutChart.dataLabels.longerThanAWeek', 'Longer than a week'), value: chartData.repliedLongerThanSevenDays },
            { label: t('queryMessagesDonutChart.dataLabels.noReply', 'Not yet replied to'), value: chartData.noReply },
        ];
    }, [chartData, t]);

    // Chart options
    const options = useMemo(() => ({
        chart: {
            toolbar: {
                show: false,
            },
        },

        title: {
            text: t('queryMessagesDonutChart.title', 'Query messages'),
        },
        labels: formattedData.map(item => item.label),
        colors: ['#106b3c', '#198754', '#49c878', '#fd7e14', '#a82232', '#dc3545'],
        plotOptions: {
            pie: {
                donut: {
                    labels: {
                        show: true,
                        total: {
                            show: true,
                        }
                    }
                }
            }
        },
    }), [formattedData, t]) as ApexOptions;

    // Chart data
    const series = useMemo(() => {
        return formattedData.map(item => item.value);
    }, [formattedData]);

    // Tracking the total, if the total queryMessages is zero, then we don't really want to show an empty chart
    const total = useMemo(() => series?.reduce((accumulator, currentValue) => accumulator + currentValue, 0), [series]);

    // Render the UI
    //
    return (
        <>
            <ConditionalFragment showIf={total > 0}>
                <Chart options={options} series={series} type="donut" height={500} />
            </ConditionalFragment>
        </>
    );
};