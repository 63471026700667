import { TFunction } from "i18next";


/**
 * Type of note, used by the NoteType field of the StaffNote model.
 */
export enum NoteType {
    General = "General",
    Progress = "Progress",
}

/**
 * Returns all NoteType options, suitable for use in a list.
 */
export function getNoteTypes(): Array<NoteType> {
    const ret = Object.keys(NoteType).map(key => key as NoteType);
    return ret;
}

/**
 * Convert a NoteType to its display name. 
 */
export function noteTypeDisplayName(name: NoteType | string, t: TFunction): string {
    switch (name) {
        case NoteType.General: return t('noteType.general.name', 'General note');
        case NoteType.Progress: return t('noteType.progress.name', 'Progress note');

        default: return t('noteType.general.name', 'General note');
    }
}
