import { useMutation } from "@apollo/react-hooks";
import { useAsyncCallback } from 'react-use-async-callback';
import gql from "graphql-tag";
import { AsyncActionStatus } from '../../../shared/abstractStore';
import { GenerateStripePaymentIntentMutation, GenerateStripePaymentIntentMutationVariables } from '../generated/graphql';

/**
 * Get a callback to delete a Payment in the api.
 */
export function useGenerateStripePaymentIntentMutation(): [(id: string) => Promise<string | undefined | null>, AsyncActionStatus] {
    const [mutationAction, { error }] = useMutation<GenerateStripePaymentIntentMutation, GenerateStripePaymentIntentMutationVariables>(
        gql`
            mutation generateStripePaymentIntentMutation ($id: ID!) {
                generateStripePaymentIntent(id: $id)
            }
        `
    );

    // Wrap the mutation in a standard callback.
    const [action, { isExecuting, errors: actionErrors }] = useAsyncCallback(async (id: string) => {
        const result = await mutationAction({ variables: { id: id } });
        if (result.errors && result.errors.length) {
            throw result.errors;
        }

        return result.data?.generateStripePaymentIntent;
    }, [mutationAction]);

    return [action, { isExecuting: isExecuting, errors: error ? error.message : actionErrors }];
}
