import { gql, useLazyQuery } from "@apollo/client";
import { Guid } from "guid-string";
import React from "react";
import { mainApiConfig } from "../../../../configure/mainApiConfig";
import { AsyncLoadOptions, AsyncLoadResult } from "../../../../shared/abstractStore";
import { ChildPaymentsTabViewModelQuery, ChildPaymentsTabViewModelQueryVariables } from '../../generated/graphql';
import { paymentFieldsFragment } from "../../models/Payment";


export function useChildPaymentsTabViewModel(childId?: string | undefined |null, options: AsyncLoadOptions = {}): AsyncLoadResult<ChildPaymentsTabViewModelQuery> {
    // Query data we need from the api.
    const [load, { data, loading, error, refetch, }] = useLazyQuery<ChildPaymentsTabViewModelQuery, ChildPaymentsTabViewModelQueryVariables>(
        gql`
        query childPaymentsTabViewModelQuery ($childId: ID!) {
            items: payments (childId: $childId) {
                ...paymentFields
            }
        }

        ${paymentFieldsFragment}
        `,
        {

            variables: {
                childId: childId ?? Guid.empty,
            },
            fetchPolicy: options.fetchPolicy ?? mainApiConfig.defaultFetchPolicy,
            pollInterval: options.pollInterval,
            onCompleted: (data) => {
                if (options.onCompleted) {
                    options.onCompleted(data);
                }
            }
        }
    );

    // Run the query (unless we are being lazy).
    React.useEffect(() => {
        if (!options.lazy) {
            load();
        }
    }, [options.lazy, load]);

    // Return the results.
    const refresh = React.useCallback(async () => { !refetch ? await load() : await refetch(); }, [refetch, load]);
    return React.useMemo(() => ({
        data: data ?? ({} as any),
        refresh: refresh,
        isLoading: loading,
        errors: error,
    }), [data, refresh, loading, error,]);
};