import { IdentityRoles } from "../../configure/security/IdentityRoles";
import { RouteEntry } from "../../shared/routes";
import { AmenityTypesList } from "./AmenityTypesList";
import { DeleteAmenityType } from "./DeleteAmenityType";
import { CreateAmenityType, EditAmenityType } from "./EditAmenityType";

/**
 * Routes that should be added to configuration/routeConfig.ts to provide access to these components via routing.
 */
export const amenityTypesRoutes: Array<RouteEntry> = [
    { path: '/administration/class-management/amenities/add', element: <CreateAmenityType />, authorize: true, requireRole: IdentityRoles.Administration },
    { path: '/administration/class-management/amenities/edit/:id', element: <EditAmenityType />, authorize: true, requireRole: IdentityRoles.Administration },
    { path: '/administration/class-management/amenities/delete/:id', element: <DeleteAmenityType />, authorize: true, requireRole: IdentityRoles.Administration },
    { path: '/administration/class-management/amenities', element: <AmenityTypesList />, authorize: true, requireRole: IdentityRoles.Administration }
];
