import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { ConditionalFragment } from "react-conditionalfragment";
import { useTranslation } from "react-i18next";
import { LinkContainer } from "react-router-bootstrap";
import { useNavigate } from "react-router-dom";
import { Waypoint } from "react-waypoint";
import { Button, ButtonDropdown, ButtonGroup, CardTitle, Col, DropdownItem, DropdownMenu, DropdownToggle, Row } from "reactstrap";
import { useToggleStateArray } from "use-toggle-state";
import { useQueryMessageTypes } from "../../api/main/queryMessageTypes/useQueryMessageTypes";
import { usePreferredListViewMode } from "../../globalState/preferredListViewMode/usePreferredListViewMode";
import { AlertOnErrors } from "../../shared/alertOnErrors";
import { useReplaceSearchParamsEffect, useSearchParams } from "../../shared/useURLSearchParams";
import { Banner } from "../shared/banner/Banner";
import { CardsOrTable } from "../shared/cardsOrTable/CardsOrTable";
import { CardsOrTableToggle } from "../shared/cardsOrTable/CardsOrTableToggle";
import { LoadingIndicator } from "../shared/loadingIndicator/LoadingIndicator";
import { MainContainer } from "../shared/mainContainer/MainContainer";
import { NoResultsFound } from "../shared/noResultsFound/NoResultsFound";
import { SearchInput } from "../shared/searchInput/SearchInput";
import { StickyToolbar } from "../shared/stickyToolbar/StickyToolbar";

/**
 * List of QueryMessageTypes
 */
export const QueryMessageTypesList = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    // Load data
    const { data: { items: allItems }, isLoading, errors: loadingErrors, fetchMore, hasMore } = useQueryMessageTypes({ pageSize: undefined });
    const [isMenuOpen, toggleMenuOpen] = useToggleStateArray();

    // Use the preferred view mode for lists
    const [viewMode] = usePreferredListViewMode();

    // Search
    const { search: searchParam } = useSearchParams();
    const [search, setSearch] = React.useState<string>(searchParam ?? '');

    // Keep the URL up to date with the search text
    useReplaceSearchParamsEffect({ search: search });

    // Filter by the search
    const items = React.useMemo(() => {
        let ret = (allItems ?? []);

        let lowerSearch = search.toLocaleLowerCase();

        if (lowerSearch) {
            ret = ret.filter(item =>
                item.name.toLocaleLowerCase().indexOf(lowerSearch) >= 0 || item.displayOrder.toString().indexOf(lowerSearch) >= 0
            );
        }

        return ret;
    }, [allItems, search]);

    // Render the UI
    //
    return (
        <>
            <Banner fluid>
                <StickyToolbar>
                    <Row>
                        <Col>
                            <h1>
                                {t('queryMessageTypesList.title', 'Query message types')}
                            </h1>
                        </Col>
                        <ConditionalFragment showIf={isLoading}>
                            <Col xs="auto">
                                <LoadingIndicator size="sm" />
                            </Col>
                        </ConditionalFragment>
                        <Col xs="auto">
                            <CardsOrTableToggle />
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <SearchInput value={search} onChange={e => setSearch(e.currentTarget.value)} />
                        </Col>

                        <Col xs="auto">
                            <Row>
                                <ButtonGroup>
                                    <LinkContainer to={'add'}>
                                        <Button color="primary">
                                            <FontAwesomeIcon icon="plus" /> {t('queryMessageTypesList.add', 'Add')}
                                        </Button>
                                    </LinkContainer>
                                </ButtonGroup>
                            </Row>
                        </Col>
                    </Row>
                </StickyToolbar>
            </Banner>

            <MainContainer fluid>
                <AlertOnErrors errors={loadingErrors} />
                <CardsOrTable
                    viewMode={viewMode}
                    items={items}
                    tableHeadings={[
                        null, /* Handles the card specific function for whcih we don't want a table column */
                        t('queryMessageTypesList.name.heading', 'Name'),
                        t('queryMessageTypesList.displayOrder', 'Order')
                    ]}
                    columns={[
                        (item, view) => {
                            // This column doesn't show in the table
                            if (view !== 'cards') {
                                return null;
                            }
                            return (
                                <>
                                    <CardTitle tag="h5">{item.name}</CardTitle>
                                </>
                            );
                        },

                        // The remaining columns are for the table mode only

                        // Name
                        (item, view) => {
                            if (view !== 'table') {
                                return null;
                            }

                            return item.name;
                        },

                        // DisplayOrder
                        (item, view) => {
                            if (view !== 'table') {
                                return null;
                            }

                            return item.displayOrder;
                        }
                    ]}

                    buttons={(item) => (
                        <ButtonGroup>
                            <LinkContainer to={`edit/${item.id}`}>
                                <Button color="primary">
                                    <FontAwesomeIcon icon="edit" />
                                    <> {t('common.edit', 'Edit')}</>
                                </Button>
                            </LinkContainer>

                            <ButtonDropdown isOpen={isMenuOpen(item.id)} toggle={() => toggleMenuOpen(item.id)}>
                                <DropdownToggle color="primary" caret>
                                    <span className="visually-hidden">{t('common.delete', 'Delete')}</span>
                                </DropdownToggle>

                                <DropdownMenu end>
                                    <LinkContainer to={`delete/${item.id}`}>
                                        <DropdownItem className="text-danger">
                                            <FontAwesomeIcon icon="trash" />
                                            <> {t('common.delete', 'Delete')}</>
                                        </DropdownItem>
                                    </LinkContainer>
                                </DropdownMenu>
                            </ButtonDropdown>
                        </ButtonGroup>
                    )}
                    onItemClick={item => navigate(`edit/${item.id}`)}
                />

                <ConditionalFragment showIf={isLoading && !items?.length}>
                    <LoadingIndicator fullWidth />
                </ConditionalFragment>
                <ConditionalFragment showIf={!isLoading && !items?.length}>
                    <NoResultsFound search={search} />
                </ConditionalFragment>
                <ConditionalFragment showIf={!isLoading && hasMore()}>
                    <Waypoint key={items?.length ?? 0} onEnter={fetchMore} />
                    <LoadingIndicator fullWidth />
                </ConditionalFragment>
            </MainContainer>
        </>
    );
};