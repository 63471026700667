import { useTranslation } from 'react-i18next';
import { ValidateCallback } from 'pojo-validator-react';
import { ValidatedInput } from 'pojo-validator-reactstrap';
import { Staff } from '../../../../api/main/models/Staff';
import { ValidatedISODateTimeInput } from '../../../shared/isoDateTimeInput/ValidatedISODateTimeInput';
import { ValidationErrors } from 'pojo-validator';
import * as React from 'react';
import {  Row, Col, Form, Label, FormGroup,} from 'reactstrap';
import { ConditionalFragment } from 'react-conditionalfragment';
import { User } from '../../../../api/main/models/User';
import { TwoValueSwitch } from '../../../shared/twoValueSwitch/TwoValueSwitch';
import { RoleGroup } from '../../../../api/main/models/RoleGroup';
import { NavigateFunction } from 'react-router-dom';
import { ClassTypeSelector } from '../../../classTypes/ClassTypeSelector';
import { ClassType } from '../../../../api/main/models/ClassType';
import { TeacherClassType } from '../../../../api/main/models/TeacherClassType';
import { ModelArrayChanges } from '../../../../shared/useChanges';
import { Guid } from 'guid-string';
import { Profile } from '../../../../api/main/models/Profile';
import { RoleGroup as RoleGroupStrings } from '../../../../api/main/models/constants/RoleGroup';


export interface EmploymentTabProps {
    validate: ValidateCallback,
    validationErrors: ValidationErrors,
    staffModel: Staff | null | undefined,
    changeStaffModel: (staffChanges: Partial<Staff>) => void,
    roleGroupModel: { id: string } | null | undefined,
    changeRoleGroupModel: (changes: Partial<{ id: string }>) => void,
    roleGroups: Array<RoleGroup> | null | undefined,
    isLoading: boolean,
    isSaving: boolean,
    navigate: NavigateFunction,
    model: Profile | null | undefined,
    userModel: User | null | undefined,
    changeUserModel: (changes: Partial<User>) => void,
    classTypes: Array<ClassType>,
    teacherClassTypeManager: ModelArrayChanges<TeacherClassType, string>,

    saveForm: () => Promise<void>,
    isCreate: boolean | null | undefined
}

/**
 * EmploymentTab for EditStaff
 */
export const EmploymentTab = (props: EmploymentTabProps) => {
    const {
        staffModel,
        changeStaffModel,
        validate,
        validationErrors,
        roleGroupModel,
        changeRoleGroupModel,
        roleGroups,
        teacherClassTypeManager,
        classTypes,
        model,
        saveForm,
    } = props;
    const { t } = useTranslation();

    // Admin role string, used for the CanReceiveEmails toggle
    const adminRoleGroupString = React.useMemo(() => RoleGroupStrings.Administrator, []);

    //Render the UI
    return (

        <Form onSubmit={e => { e.preventDefault(); saveForm(); }}>
            <h4>{t('editStaff.employmentDetails.heading', 'Employment details')}</h4>
            <Row>
                <Col xs={12} md="">
                    <FormGroup>
                        <Label htmlFor="primaryJobTitle">{t('editStaff.primaryJobTitle.name', 'Primary job title')}</Label>
                        <ValidatedInput name="primaryJobTitle" type="text" value={staffModel?.primaryJobTitle ?? ''} onChange={e => changeStaffModel({ primaryJobTitle: e.currentTarget.value })} onBlur={e => validate('primaryJobTitle')} validationErrors={validationErrors['primaryJobTitle']} />
                    </FormGroup>
                </Col>
                <Col>
                    <FormGroup>
                        <Label htmlFor="secondaryJobTitle">{t('editStaff.secondaryJobTitle.name', 'Secondary job title')}</Label>
                        <ValidatedInput name="secondaryJobTitle" type="text" value={staffModel?.secondaryJobTitle ?? ''} onChange={e => changeStaffModel({ secondaryJobTitle: e.currentTarget.value })} onBlur={e => validate('secondaryJobTitle')} validationErrors={validationErrors['secondaryJobTitle']} />
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col>
                    <FormGroup>
                        <Label htmlFor="startDate">{t('editStaff.startDate.label', 'Start date')}</Label>
                        <ValidatedISODateTimeInput type="date" value={staffModel?.startDate ?? ''} onChange={e => changeStaffModel({ startDate: e.currentTarget.value })} onBlur={e => validate('startDate')} validationErrors={validationErrors['startDate']}></ValidatedISODateTimeInput>
                    </FormGroup>
                </Col>
                <Col>
                    <FormGroup>
                        <Label htmlFor="endDate">{t('editStaff.endDate.label', 'End date')}</Label>
                        <ValidatedISODateTimeInput type="date" value={staffModel?.endDate ?? null} onChange={e => changeStaffModel({ endDate: e.currentTarget.value })} onBlur={e => validate('endDate')} validationErrors={validationErrors['endDate']}></ValidatedISODateTimeInput>
                    </FormGroup>
                </Col>
            </Row>

            <h4>{t('editStaff.applicationAccess.heading', 'Application access')}</h4>
            <Row>
                <Col>
                    <FormGroup switch>
                        <Label htmlFor="isTeacher">{t('editStaff.isTeacher.label', 'Teacher')}</Label>
                        <TwoValueSwitch checked={staffModel?.isTeacher ?? false} leftLabel="" rightLabel="" onChange={e => changeStaffModel({ isTeacher: e })}></TwoValueSwitch>
                    </FormGroup>
                </Col>
                <Col>
                    <FormGroup switch>
                        <Label htmlFor="isCoordinator">{t('editStaff.isCoordinator.label', 'Cub Coordinator')}</Label>
                        <TwoValueSwitch checked={staffModel?.isCoordinator ?? false} leftLabel="" rightLabel="" onChange={e => changeStaffModel({ isCoordinator: e })}></TwoValueSwitch>
                    </FormGroup>
                </Col>
                <Col>
                    <FormGroup switch>
                        <Label htmlFor="isAdmin">{t('editStaff.isAdmin.label', 'Office Staff')}</Label>
                        <TwoValueSwitch checked={staffModel?.isAdminStaff ?? false} leftLabel="" rightLabel="" onChange={e => changeStaffModel({ isAdminStaff: e })}></TwoValueSwitch>
                    </FormGroup>
                </Col>
                <Col>
                    <ConditionalFragment showIf={roleGroupModel?.id === roleGroups?.find(it => it.name === adminRoleGroupString)?.id}>
                        <FormGroup switch>
                            <Label htmlFor="canReceiveQueryMessageEmails">{t('editStaff.canReceiveQueryMessageEmails.label', 'Receives query notifications')}</Label>
                            <TwoValueSwitch checked={staffModel?.canReceiveQueryMessageEmails ?? false} leftLabel="" rightLabel="" onChange={e => changeStaffModel({ canReceiveQueryMessageEmails: e })}></TwoValueSwitch>
                        </FormGroup>
                    </ConditionalFragment>
                </Col>
            </Row>
            <Row>
                <ConditionalFragment showIf={staffModel?.isTeacher === true}>
                    <FormGroup>
                        <Label htmlFor="teacherClassType">{t('editStaff.teacherClassType.label', 'Can teach lessons for: ') }</Label>
                <ClassTypeSelector
                    input
                    tags={classTypes}
                    isSelected={tag => !!teacherClassTypeManager.model.find(it => it.classTypeId === tag.id)}
                    toggle={tag => {
                        let existing = teacherClassTypeManager.model.find(item => item.classTypeId === tag.id);
                        if (existing) {
                            teacherClassTypeManager.removeFor(existing.id);
                        } else {
                            teacherClassTypeManager.addFor({ id: Guid.newGuid(), classTypeId: tag.id, staffId: model?.id ?? '', archived: false, });
                        }
                    }}
                   />
                    </FormGroup>
                </ConditionalFragment>

            </Row>

            <ConditionalFragment showIf={(roleGroups?.length ?? 0) > 1}>
                <FormGroup>
                    <Label htmlFor="roleGroup">{t('editStaff.roleGroup', 'Application security group')}</Label>
                    <ValidatedInput name="roleGroup" type="select" value={roleGroupModel?.id ?? ''} onChange={e => changeRoleGroupModel({ id: e.currentTarget.value })} onBlur={e => validate('roleGroup')} validationErrors={validationErrors['roleGroup']}>
                        {
                            roleGroups?.map(item => (
                                <option key={item.id} value={item.id}>{item.name}</option>
                            ))
                        }
                    </ValidatedInput>
                </FormGroup>
            </ConditionalFragment>
        </Form>
    );

};

