import { Form, Label, FormGroup, Input, InputGroup, Button, Row, Col } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { ValidateCallback } from 'pojo-validator-react';
import { ValidatedInput } from 'pojo-validator-reactstrap';
import { ClassLocation } from '../../../api/main/models/ClassLocation';
import { ValidationErrors } from 'pojo-validator';
import { HtmlEditor } from '../../../shared/htmlEditor';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getFullUrl } from '../../../utilities/getFullUrl';
import { ClassTypeSelector } from '../../classTypes/ClassTypeSelector';
import { Guid } from 'guid-string';
import { ClassLocationClassType } from '../../../api/main/models/ClassLocationClassType';
import { ClassType } from '../../../api/main/models/ClassType';
import { ModelArrayChanges } from '../../../shared/useChanges';
import { ClassLocationAmenityType } from '../../../api/main/models/ClassLocationAmenityType';
import { AmenityTypeSelector } from '../../amenityTypes/AmenityTypeSelector';
import { AmenityType } from '../../../api/main/models/AmenityType';

export interface MainTabProps {
    model: ClassLocation | null | undefined,
    change: (changes: Partial<ClassLocation>) => void,
    validate: ValidateCallback,
    validationErrors: ValidationErrors,
    saveForm: () => Promise<void>,

    classLocationClassTypesManager: ModelArrayChanges<ClassLocationClassType, string>,
    classTypes: Array<ClassType>,
    classLocationAmenityTypesManager: ModelArrayChanges<ClassLocationAmenityType, string>,
    amenityTypes: Array<AmenityType>,
}

/**
 * MainTab for EditClassLocation.
 */
export const MainTab = (props: MainTabProps) => {
    const {
        model,
        change,
        validate,
        validationErrors,
        saveForm,
        classLocationClassTypesManager,
        classTypes,
        classLocationAmenityTypesManager,
        amenityTypes
    } = props;
    const { t } = useTranslation();

    // Handle validating the MapUrl
    const openMapUrl = (url: string | undefined) => {
        const fullUrl = getFullUrl(url);

        // If we don't have a URL to work with do nothing.
        if (!fullUrl) { return; }

        // Open in a new window.
        window.open(fullUrl);
    };

    // Render the UI
    //
    return (
        <>
            <Form onSubmit={e => { e.preventDefault(); saveForm(); }}>
                <FormGroup>
                    <Label htmlFor="name">{t('editClassLocation.mainTab.name.label', 'Name')}</Label>
                    <ValidatedInput name="name" type="text" value={model?.name ?? ''} onChange={e => change({ name: e.currentTarget.value })} onBlur={e => validate('name')} validationErrors={validationErrors['name']} />
                </FormGroup>

                <FormGroup>
                    <Label htmlFor="name">{t('editClassLocation.mainTab.classLocationClassTypes.label', 'Activities')}</Label>
                    <ClassTypeSelector
                        input
                        tags={classTypes}
                        isSelected={tag => !!classLocationClassTypesManager.model.find(it => it.classTypeId === tag.id)}
                        toggle={tag => {
                            let existing = classLocationClassTypesManager.model.find(item => item.classTypeId === tag.id);
                            if (existing) {
                                classLocationClassTypesManager.removeFor(existing.id);
                            } else {
                                classLocationClassTypesManager.addFor({ id: Guid.newGuid(), classTypeId: tag.id, classLocationId: model?.id ?? '', archived: false, });
                            }
                        }}
                    />
                </FormGroup>

                <FormGroup>
                    <Label htmlFor="name">{t('editClassLocation.mainTab.classLocationAmenityTypes.label', 'Amenities')}</Label>
                    <AmenityTypeSelector
                        input
                        tags={amenityTypes}
                        isSelected={tag => !!classLocationAmenityTypesManager.model.find(it => it.amenityTypeId === tag.id)}
                        toggle={tag => {
                            let existing = classLocationAmenityTypesManager.model.find(item => item.amenityTypeId === tag.id);
                            if (existing) {
                                classLocationAmenityTypesManager.removeFor(existing.id);
                            } else {
                                classLocationAmenityTypesManager.addFor({ id: Guid.newGuid(), amenityTypeId: tag.id, classLocationId: model?.id ?? '', archived: false, });
                            }
                        }}
                    />
                </FormGroup>

                <FormGroup>
                    <Label htmlFor="description">{t('editClassLocation.mainTab.address.label', 'Address')}</Label>
                    <Input type="text" value={model?.address ?? ''} onChange={e => change({ address: e.currentTarget.value })} />
                </FormGroup>

                <FormGroup>
                    <Label htmlFor="directions">{t('editClassLocation.mainTab.directions.label', 'Directions')}</Label>
                    <HtmlEditor size="sm" value={model?.directionsHtml ?? ''} onChange={text => change({ directionsHtml: text })} />
                </FormGroup>

                <FormGroup>
                    <Label htmlFor="mapUrl">{t('editClassLocation.mainTab.mapUrl.label', 'Map URL')}</Label>
                    <InputGroup>
                        <Input name="mapUrl" type="text" value={model?.mapUrl} onChange={e => change({ mapUrl: e.currentTarget.value })} />
                        <Button onClick={() => openMapUrl(model?.mapUrl)}>
                            <FontAwesomeIcon icon={'link'} />
                        </Button>
                    </InputGroup>
                </FormGroup>

                <FormGroup>
                    <Row>
                        <Col>
                            <Label htmlFor="latitude">{t('editClassLocation.mainTab.latitude.label', 'Latitude')}</Label>
                            <Input type="text" value={model?.latitude ?? ''} onChange={e => change({ latitude: e.currentTarget.value })} />
                        </Col>

                        <Col>
                            <Label htmlFor="longitude">{t('editClassLocation.mainTab.longitude.label', 'Longitude')}</Label>
                            <Input type="text" value={model?.longitude ?? ''} onChange={e => change({ longitude: e.currentTarget.value })} />
                        </Col>
                    </Row>
                </FormGroup>
            </Form>
        </>
    );
};
