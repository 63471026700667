import { gql, useLazyQuery } from "@apollo/client";
import React from "react";
import { mainApiConfig } from "../../../configure/mainApiConfig";
import { AsyncLoadOptions, AsyncLoadResult } from "../../../shared/abstractStore";
import { EnrollInClassSupportingDataQuery, EnrollInClassSupportingDataQueryVariables } from '../generated/graphql';
import { userFieldsFragment } from "../models/User";

export function useEnrollInClassSupportingData(email: string | undefined | null, options: AsyncLoadOptions = {}): AsyncLoadResult<EnrollInClassSupportingDataQuery> {
    // Query the data we need from the api
    const [load, { data, loading, error, refetch }] = useLazyQuery<EnrollInClassSupportingDataQuery, EnrollInClassSupportingDataQueryVariables>(
        gql`
         query enrollInClassSupportingDataQuery ($email: String!) {
            users: usersForAnonymousUser (email: $email) {
                ...userFields
            }
         }

         ${userFieldsFragment}
        `,
        {
            variables: {
                email: email ?? '',
            },
            fetchPolicy: options.fetchPolicy ?? mainApiConfig.defaultFetchPolicy,
            pollInterval: options.pollInterval,
            onCompleted: (data) => {
                if (options.onCompleted) {
                    options.onCompleted(data);
                }
            }
        }
    );

    // Run the query (unless we are being lazy).
    React.useEffect(() => {
        if (!options.lazy) {
            load();
        }
    }, [options.lazy, load]);

    // Return the results.
    const refresh = React.useCallback(async () => { !refetch ? await load() : await refetch(); }, [refetch, load]);
    return React.useMemo(() => ({
        data: data ?? ({} as any),
        refresh: refresh,
        isLoading: loading,
        errors: error,
    }), [data, refresh, loading, error]);
};