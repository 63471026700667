import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useValidatorCallback } from "pojo-validator-react";
import { ValidatedInput } from "pojo-validator-reactstrap";
import { ConditionalFragment } from "react-conditionalfragment";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { useAsyncCallback } from "react-use-async-callback";
import { Button, Col, Form, FormGroup, Label, Row, Spinner } from "reactstrap";
import { ButtonAsync } from "reactstrap-buttonasync";
import { useAmenityType } from "../../api/main/amenityTypes/useAmenityType";
import { useSaveAmenityTypeMutation } from "../../api/main/amenityTypes/useSaveAmenityTypeMutation";
import { AmenityType, amenityTypeDefaultValues } from "../../api/main/models/AmenityType";
import { AlertOnErrors } from "../../shared/alertOnErrors";
import { useChanges } from "../../shared/useChanges";
import { Banner } from "../shared/banner/Banner";
import { FormButtons } from "../shared/formButtons/FormButtons";
import { LoadingIndicator } from "../shared/loadingIndicator/LoadingIndicator";
import { MainContainer } from "../shared/mainContainer/MainContainer";
import { StickyToolbar } from "../shared/stickyToolbar/StickyToolbar";

export interface EditAmenityTypeProps {
    isCreate?: boolean,
    onCreateDefaultValues?: () => Partial<AmenityType>;
}

/**
 * Component to create a AmenityType
 */
export const CreateAmenityType = (props: EditAmenityTypeProps) => (<EditAmenityType isCreate={true} {...props} />);

/**
 * Component to edit a AmenityType
 */
export const EditAmenityType = (props: EditAmenityTypeProps) => {
    const {
        isCreate,
        onCreateDefaultValues
    } = props;

    const { t } = useTranslation();
    const { id } = useParams<{ id: string | undefined; }>();
    const navigate = useNavigate();

    // Load all data
    const {
        data: {
            model: storeModel,
        },
        errors: loadErrors, isLoading
    } = useAmenityType(id);

    // Model (AmenityType)
    const { model, change, changes } = useChanges(storeModel, isCreate ? { ...amenityTypeDefaultValues(), ...(onCreateDefaultValues ? onCreateDefaultValues() : {}) } : undefined);
    const [saveAmenityType, { errors: saveErrors }] = useSaveAmenityTypeMutation();

    // Main model validation
    const [validate, validationErrors] = useValidatorCallback((validation, fieldsToCheck) => {
        const rules = {
            name: () => !model?.name ? t('editAmenityType.errors.name', 'Name cannot be empty') : '',
        };
        validation.checkRules(rules, fieldsToCheck);
    }, [model]);

    // Save everything
    const [saveForm, { isExecuting: isSaving, errors: saveFormErrors }] = useAsyncCallback(async () => {
        if (!model) {
            return;
        }

        if (!validate()) {
            return;
        }

        // Save the main model.
        await saveAmenityType(model.id, { ...changes }, isCreate ?? false);

        // Go back to previous screen.
        navigate(-1);
    }, [validate, saveAmenityType, model, changes, isCreate, id, navigate]);

    // Render the UI
    //
    return (
        <>
            <Banner>
                <StickyToolbar>
                    <Row>
                        <Col>
                            <h1>
                                {
                                    isCreate ? t('editAmenityType.createHeading.default', 'Add activity')
                                        : t('editAmenityType.editHeading.default', 'Edit activity')
                                }
                            </h1>

                            <h3>{model?.name}</h3>
                        </Col>

                        <ConditionalFragment showIf={isLoading}>
                            <Col xs="auto">
                                <LoadingIndicator size="sm" />
                            </Col>
                        </ConditionalFragment>
                    </Row>
                </StickyToolbar>
            </Banner>

            <MainContainer>
                <AlertOnErrors errors={[
                    loadErrors,
                    saveFormErrors,
                    saveErrors
                ]} />

                <Form onSubmit={e => { e.preventDefault(); saveForm(); }}>
                    <FormGroup>
                        <Label htmlFor="name">{t('editAmenityType.name.label', 'Name')}</Label>
                        <ValidatedInput name="name" type="text" value={model?.name ?? ''} onChange={e => change({ name: e.currentTarget.value })} onBlur={e => validate('name')} validationErrors={validationErrors['name']} />
                    </FormGroup>

                    <FormGroup>
                        <Label htmlFor="name">{t('editAmenityType.displayOrder.label', 'Order')}</Label>
                        <ValidatedInput name="displayOrder" type="number" value={model?.displayOrder ?? ''} onChange={e => change({ displayOrder: e.currentTarget.valueAsNumber })} onBlur={e => validate('displayOrder')} validationErrors={validationErrors['displayOrder']} />
                    </FormGroup>

                    <FormButtons>
                        <ConditionalFragment showIf={!isLoading}>
                            <ButtonAsync color="primary" isExecuting={isSaving} onClick={() => saveForm()}
                                executingChildren={<><Spinner size="sm" /> {t('common.saving', 'Saving...')}</>}>
                                <FontAwesomeIcon icon="save" />
                                <> </>
                                {t('common.save', 'Save')}
                            </ButtonAsync>
                        </ConditionalFragment>

                        <Button type="button" color="primary" outline onClick={e => navigate(-1)}>
                            {t('common.cancel', 'Cancel')}
                        </Button>
                    </FormButtons>
                </Form>
            </MainContainer>
        </>
    )
};