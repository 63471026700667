/**
 * Constants for the available roles.
 */
export const IdentityRoles = {
    Staff: 'Staff',
    Administration: 'Administration',
    AdminAndFinance: 'AdminAndFinance',
};

///**
// * Constants (enum) for the available Roles.
// */
//export enum IdentityRoles {
//    Administration = 'Administration'
//}
