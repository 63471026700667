import gql from 'graphql-tag';
import { Guid } from 'guid-string';
import { ScheduledClassCancellationFieldsFragment } from '../generated/graphql';
import moment from "moment";

export const scheduledClassCancellationFieldsFragment = gql`
    fragment scheduledClassCancellationFields on ScheduledClassCancellation {
        id
        scheduledClassId
        termLessonNumber
        lessonDate
        cancellationReasonId
        message
        cancelledAt
        emailsSent
        smsSent
        cancelledByStaffUserId
        archived
    }
`;

export type ScheduledClassCancellation = Omit<ScheduledClassCancellationFieldsFragment, '__typename'>;

/**
 * Default values.
 */
export const scheduledClassCancelationDefaultValues = (): Partial<ScheduledClassCancellation> => ({
    id: Guid.newGuid(),
    scheduledClassId: undefined,
    termLessonNumber: 0,
    lessonDate: moment().toISOString(),
    cancellationReasonId: undefined,
    message: '',
    cancelledAt: moment().toISOString(),
    emailsSent: false,
    smsSent: false,
    cancelledByStaffUserId: '',
    archived: false,
});