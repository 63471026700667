import gql from 'graphql-tag';
import { Guid } from 'guid-string';
import { ChildDiscountFieldsFragment } from '../generated/graphql';

export const childDiscountFieldsFragment = gql`
    fragment childDiscountFields on ChildDiscount {
        id
        childId
        name
        discountAmount
        discountPercentage
        archived
        expiryDate
        isSingleUse
        isSingleUseConsumed
    }
`;

export type ChildDiscount = Omit<ChildDiscountFieldsFragment, '__typename'>;

/**
 * Default values.
 */
export const childDiscountDefaultValues = (): Partial<ChildDiscount> => ({
    id: Guid.newGuid(),
    childId: undefined,
    name: '',
    discountAmount: 0,
    discountPercentage: 0,
    archived: false,
    expiryDate: '',
    isSingleUse: false,
    isSingleUseConsumed: false,
});