import { Helmet } from 'react-helmet-async';
import './bodyBackground.scss';

export type BodyBackgrounds = 'default'
    | 'account'
    ;

export interface BodyBackgroundProps {
    background?: BodyBackgrounds,
}

/**
 * Background settings that change the body tags background.
 * @param props
 */
export const BodyBackground = (props: BodyBackgroundProps) => {
    const {
        background = 'default',
    } = props;

    return (
        <div>
            <Helmet>
                <body data-background={background} />
            </Helmet>
        </div>
        );
};