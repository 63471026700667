import * as React from 'react';
import { useSaveInStore, SaveInStoreOptions } from '../../../shared/useSaveInStore';
import { useUpdateTermMutation } from './useUpdateTermMutation';
import { Term } from '../models/Term';
import { useCreateTermMutation } from './useCreateTermMutation';
import { TermCreateInput, TermUpdateInput } from '../generated/graphql';

/**
 * Get a callback to save a Term in the api using either a create or update.
 */
export function useSaveTermMutation(options: SaveInStoreOptions<Term, string> = {}) {
    const [_create, createStatus] = useCreateTermMutation();
    const create = React.useCallback((model: Partial<Term>) => _create(model as TermCreateInput), [_create]);

    const [_update, updateStatus] = useUpdateTermMutation();
    const update = React.useCallback((id: string, changes: Partial<Term>) => _update(id, changes as TermUpdateInput), [_update]);

    return useSaveInStore(
        [create, createStatus],
        [update, updateStatus],
        options
    );
}
