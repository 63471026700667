import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ValidationErrors } from 'pojo-validator';
import { ValidateCallback } from 'pojo-validator-react';
import { ValidatedInput } from 'pojo-validator-reactstrap';
import { useMemo } from 'react';
import { ConditionalFragment } from 'react-conditionalfragment';
import { useTranslation } from 'react-i18next';
import { useAsyncCallback } from 'react-use-async-callback';
import { Badge, Button, ButtonGroup, Col, FormGroup, Label, ListGroupItem, Row, Spinner } from 'reactstrap';
import { ButtonAsync } from 'reactstrap-buttonasync';
import { BlobUploadService } from '../../../../api/main/blobReferences/BlobUploadService';
import { useBlobReference } from '../../../../api/main/blobReferences/useBlobReference';
import { StaffQualificationCheck } from '../../../../api/main/models/StaffQualificationCheck';
import { AlertOnErrors } from '../../../../shared/alertOnErrors';
import { hasDatePassed } from '../../../../utilities/hasDatePassed';
import { FileUploadButton } from '../../../shared/fileUploadButton/FileUploadButton';
import { ValidatedISODateTimeInput } from '../../../shared/isoDateTimeInput/ValidatedISODateTimeInput';
import { UploadedFilePreview } from '../../../shared/uploadedFilePreview/UploadedFilePreview';

export interface QualificationsCheckComponentProps {
    model: StaffQualificationCheck | undefined,
    change: (changes: Partial<StaffQualificationCheck>) => void;
    remove: () => void;

    validate: ValidateCallback;
    validationErrors: ValidationErrors;
}

/**
 * Component under StaffQualifications that lets the user edit a StaffQualificationCheck
 */
export const QualificationCheckComponent = (props: QualificationsCheckComponentProps) => {
    const { model, change, remove, validate, validationErrors } = props;
    const { t } = useTranslation();

    // Check if the review date is in the past.
    const isOverdue = useMemo(() => hasDatePassed(model?.reviewDate), [model]);

    // Contents file uploading
    const { data: { model: contentsFile }, errors: contentsFileLoadErrors } = useBlobReference(model?.evidenceBlobReferenceId);
    const [onUploadContentsFile, { errors: contentsFileUploadErrors, isExecuting: isUploadingContentsFile, }] = useAsyncCallback(async (files: FileList | null) => {
        if (!files) {
            return;
        }

        let uploadService: BlobUploadService = new BlobUploadService("/api/blobs");
        let result = await uploadService.upload(files);

        if (!!result) {
            change({ evidenceBlobReferenceId: result.id });
        }
    }, [change]);
    const [clearContentsFile, { isExecuting: isClearingContentsFile, }] = useAsyncCallback(async () => {
        change({ evidenceBlobReferenceId: null });
    }, [change]);

    //Render the UI
    //
    return (
        <>
            <AlertOnErrors errors={[
                    contentsFileLoadErrors,
                    contentsFileUploadErrors
                ]} />

            <ListGroupItem>
                <Row>
                    <Col>
                        <FormGroup>
                            <Label htmlFor='name'>{t('editStaff.qualificationCheckComponent.name.label', 'Name')}</Label>
                            <ValidatedInput name='name' type='text' value={model?.name ?? ''} onChange={e => change({ name: e.currentTarget.value })} onBlur={e => validate('name')} validationErrors={validationErrors['name']} />
                        </FormGroup>
                    </Col>
                    <Col>
                        <FormGroup>
                            <Label htmlFor='reviewDate'>
                                {t('editStaff.qualificationCheckComponent.reviewDate.label', 'Next review date')}
                                <ConditionalFragment showIf={isOverdue}>
                                    <> </>
                                    <Badge color="danger">
                                        {t('editClassLocation.classLocationComponent.reviewDate.overdue', 'Overdue')}
                                    </Badge>
                                </ConditionalFragment>
                            </Label>
                            <ValidatedISODateTimeInput name='reviewDate' type='date' value={model?.reviewDate ?? ''} onChange={e => change({ reviewDate: e.currentTarget.value })} onBlur={e => validate('reviewDate')} validationErrors={validationErrors['reviewDate']} />
                        </FormGroup>
                    </Col>
                    <Col>
                        <FormGroup>
                            <Label htmlFor="fileUpload">{t('editClassLocation.lessonDocumentComponent.fileUpload.label', 'Upload file')}</Label>
                            <Row>
                                <Col>
                                    <UploadedFilePreview blobReference={contentsFile} />
                                    <Row>
                                        <Col>
                                            <ButtonGroup>
                                                <FileUploadButton
                                                    color={`primary`}
                                                    isExecuting={isUploadingContentsFile}
                                                    executingChildren={<><Spinner size="sm" /> {t('common.uploading', 'Uploading...')}</>}
                                                    onUpload={onUploadContentsFile}
                                                    outline={false}>
                                                    {t('editClassLocation.lessonDocumentComponent.uploadButtonText', 'Upload a file')}
                                                </FileUploadButton>
                                                <ButtonAsync color="primary"
                                                    outline
                                                    isExecuting={isClearingContentsFile}
                                                    type="button"
                                                    onClick={clearContentsFile}
                                                    executingChildren={<><Spinner size="sm" /> {t('editClassLocation.clearingFile', 'Clearing file...')}</>}>
                                                    {t('editClassLocation.lessonDocumentComponent.clearUploadButton', 'Clear file')}
                                                </ButtonAsync>
                                            </ButtonGroup>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </FormGroup>
                    </Col>
                    <Col xs='auto'>
                        <FormGroup>
                            <Label htmlFor='delete'>&nbsp;</Label>
                            <div>
                                <Button color='danger' outline onClick={() => remove()}>
                                    <FontAwesomeIcon icon='trash-alt' />
                                    <> </>
                                    {t('editStaff.qualificationCheckComponent.delete', 'Delete qualification')}
                                </Button>
                            </div>
                        </FormGroup>
                    </Col>
                </Row>
            </ListGroupItem>
        </>
    );
};