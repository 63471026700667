import * as React from "react";
import { Alert, Button, CardBody, CardHeader, Col, Row } from "reactstrap";
import { useConfirmEmailChangeCallback } from "../../api/account";
import { LoadingIndicator } from "../shared/loadingIndicator/LoadingIndicator";
import { useTranslation } from "react-i18next";
import { LinkContainer } from "react-router-bootstrap";
import { ConditionalFragment } from "react-conditionalfragment";
import { FormButtons } from "../shared/formButtons/FormButtons";
import { MainContainer } from "../shared/mainContainer/MainContainer";
import { BodyBackground } from "../layout/bodyBackground/BodyBackground";
import { AccountCard } from "./accountCard/AccountCard";

/**
 * Confirm an email address change for an account using a unique link included in the email. 
 */
export const ConfirmEmailChange = () => {
    const { t } = useTranslation();
    const [emailConfirmed, setEmailConfirmed] = React.useState<boolean>(false);
    const [confirmEmail, { errors: confirmEmailErrors }] = useConfirmEmailChangeCallback();

    // Trigger the email confirmation on page load.
    React.useEffect(() => {
        (async () => {
            if (!emailConfirmed) {
                const ok = await confirmEmail();
                setEmailConfirmed(ok);
            }
        })();
    }, [confirmEmail, setEmailConfirmed, emailConfirmed]);

    // Render the UI.
    // 
    return (
        <MainContainer color="transparent" centerChildren="vertically">
            <BodyBackground background="account" />
            <AccountCard>
                <CardHeader>
                    <h1>
                        {
                            confirmEmailErrors ? t('confirmEmailChange.confirmEmailChangeFailureHeading', 'Sorry we cannot confirm your change of email using this code')
                                : emailConfirmed ? t('confirmEmailChange.confirmEmailChangeSuccessHeading', 'Thank you for confirming your change of email.')
                                    : (<LoadingIndicator />)
                        }
                    </h1>
                </CardHeader>
                <CardBody>
                    <ConditionalFragment showIf={!!confirmEmailErrors}>
                        <Alert color="danger">
                            {confirmEmailErrors}
                        </Alert>
                    </ConditionalFragment>
                    <ConditionalFragment showIf={!!emailConfirmed}>
                        {/*<Alert color="success">*/}
                        {/*    <>{t('confirmEmailChange.thankYouForConfirming', 'Thank you for confirming your change of email.  We\'ve swapped that over for you.')} </>*/}
                        {/*</Alert>*/}
                        <p>
                            {t('confirmEmailChange.thankYouForConfirming', 'Thank you for confirming your change of email.  We\'ve swapped that over for you.')}
                        </p>
                        <FormButtons>
                            <Row>
                                <Col>
                                </Col>
                                <Col xs="auto">
                                    <LinkContainer to="/authentication/login">
                                        <Button color="primary">
                                            {t('confirmEmailChange.signInToContinue', 'Continue')}
                                        </Button>
                                    </LinkContainer>
                                </Col>
                            </Row>
                        </FormButtons>
                    </ConditionalFragment>
                </CardBody>
            </AccountCard>
        </MainContainer>
    );
};
