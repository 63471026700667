import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useValidatorCallback } from "pojo-validator-react";
import { ValidatedInput } from "pojo-validator-reactstrap";
import { ConditionalFragment } from "react-conditionalfragment";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { useAsyncCallback } from "react-use-async-callback";
import { Button, Col, Form, FormGroup, Label, Row, Spinner } from "reactstrap";
import { ButtonAsync } from "reactstrap-buttonasync";
import { NoticeBoardItem, noticeBoardItemDefaultValues } from "../../api/main/models/NoticeBoardItem";
import { useNoticeBoardItem } from "../../api/main/noticeBoardItems/useNoticeBoardItem";
import { useSaveNoticeBoardItemMutation } from "../../api/main/noticeBoardItems/useSaveNoticeBoardItemMutation";
import { AlertOnErrors } from "../../shared/alertOnErrors";
import { HtmlEditor } from "../../shared/htmlEditor";
import { useChanges } from "../../shared/useChanges";
import { Banner } from "../shared/banner/Banner";
import { FormButtons } from "../shared/formButtons/FormButtons";
import { ValidatedISODateTimeInput } from "../shared/isoDateTimeInput/ValidatedISODateTimeInput";
import { LoadingIndicator } from "../shared/loadingIndicator/LoadingIndicator";
import { MainContainer } from "../shared/mainContainer/MainContainer";
import { StickyToolbar } from "../shared/stickyToolbar/StickyToolbar";

export interface EditNoticeBoardItemProps {
    isCreate?: boolean,
    onCreateDefaultValues?: () => Partial<NoticeBoardItem>;
}

/**
 * Component to create a NoticeBoardItem.
 */
export const CreateNoticeBoardItem = (props: EditNoticeBoardItemProps) => (<EditNoticeBoardItem isCreate={true} {...props} />);

/**
 * Component to edit a NoticeBoardItem
 * @returns
 */
export const EditNoticeBoardItem = (props: EditNoticeBoardItemProps) => {
    const {
        isCreate,
        onCreateDefaultValues
    } = props;

    const { t } = useTranslation();
    const { id } = useParams<{ id: string | undefined; }>();
    const navigate = useNavigate();

    // Load all data
    const {
        data: {
            model: storeModel,
        }, isLoading, errors: loadErrors
    } = useNoticeBoardItem(id);

    // Model (NoticeBoardItem)
    const { model, change, changes } = useChanges(storeModel, isCreate ? { ...noticeBoardItemDefaultValues(), ...(onCreateDefaultValues ? onCreateDefaultValues() : {}) } : undefined);
    const [saveNoticeBoardItem, { errors: saveErrors }] = useSaveNoticeBoardItemMutation();

    // Main model validation
    const [validate, validationErrors] = useValidatorCallback((validation, fieldsToCheck) => {
        const rules = {
            title: () => !model?.title ? t('editNoticeBoardItem.errors.title', 'Title cannot be empty') : '',
            contentsHtml: () => !model?.contentHtml ? t('editNoticeBoardItem.errors.contentsHtml', 'Content cannot be empty') : '',
            startDate: () => !model?.startDate ? t('editNoticeBoardItem.errors.startDate', 'Start date cannot be empty') : '',
        };
        validation.checkRules(rules, fieldsToCheck);
    }, [model]);

    // Save everything.
    const [saveForm, { isExecuting: isSaving, errors: saveFormErrors }] = useAsyncCallback(async () => {
        if (!model) {
            return;
        }

        if (!validate()) {
            return;
        }

        // Save the main model.
        await saveNoticeBoardItem(model.id, { ...changes }, isCreate ?? false);

        // Go back to previous screen.
        navigate(-1);
    }, [validate, saveNoticeBoardItem, model, changes, isCreate, id, navigate]);

    // Render the UI.
    //
    return (
        <>
            <Banner>
                <StickyToolbar>
                    <Row>
                        <Col>
                            <h1>
                                {
                                    isCreate ? t('editNoticeBoardItem.createHeading.default', 'Add notice board item')
                                        : t('editNoticeBoardItem.editHeading.default', 'Edit notice board item')
                                }
                            </h1>
                            <h3>{model?.title}</h3>
                        </Col>
                        <ConditionalFragment showIf={isLoading}>
                            <Col xs="auto">
                                <LoadingIndicator size="sm" />
                            </Col>
                        </ConditionalFragment>
                    </Row>
                </StickyToolbar>
            </Banner>

            <MainContainer>
                <AlertOnErrors errors={[
                    loadErrors,
                    saveFormErrors, saveErrors,
                ]} />

                <Form onSubmit={e => { e.preventDefault(); saveForm(); }}>
                    <FormGroup>
                        <Label htmlFor="title">{t('editNoticeBoardItem.title.label', 'Title')}</Label>
                        <ValidatedInput name="title" type="text" value={model?.title ?? ''} onChange={e => change({ title: e.currentTarget.value })} onBlur={e => validate('title')} validationErrors={validationErrors['title']} />
                    </FormGroup>

                    <FormGroup>
                        <Label htmlFor="contentsHtml">{t('editNoticeBoardItem.message.label', 'Message')}</Label>
                        <HtmlEditor size="sm" value={model?.contentHtml ?? ''} onChange={text => change({ contentHtml: text })} />
                    </FormGroup>

                    <Row>
                        <Col>
                            <FormGroup>
                                <Label htmlFor="startDate">{t('editNoticeBoardItem.startDate.label', 'Start date')}</Label>
                                <ValidatedISODateTimeInput type="date" value={model?.startDate ?? ''} onChange={e => change({ startDate: e.currentTarget.value })} onBlur={e => validate('startDate')} validationErrors={validationErrors['startDate']}></ValidatedISODateTimeInput>
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup>
                                <Label htmlFor="endDate">{t('editNoticeBoardItem.endDate.label', 'End date')}</Label>
                                <ValidatedISODateTimeInput type="date" value={model?.endDate ?? ''} onChange={e => change({ endDate: e.currentTarget.value })} onBlur={e => validate('endDate')} validationErrors={validationErrors['endDate']}></ValidatedISODateTimeInput>
                            </FormGroup>
                        </Col>
                    </Row>

                    <FormButtons>
                        <ConditionalFragment showIf={!isLoading}>
                            <ButtonAsync color="primary" isExecuting={isSaving} onClick={() => saveForm()}
                                executingChildren={<><Spinner size="sm" /> {t('common.saving', 'Saving...')}</>}>
                                <FontAwesomeIcon icon="save" />
                                <> </>
                                {t('common.save', 'Save')}
                            </ButtonAsync>
                        </ConditionalFragment>

                        <Button type="button" color="primary" outline onClick={e => navigate(-1)}>
                            {t('common.cancel', 'Cancel')}
                        </Button>
                    </FormButtons>
                </Form>
            </MainContainer>
        </>
    );
};