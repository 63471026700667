import { useLazyQuery } from "@apollo/client";
import gql from "graphql-tag";
import React, { useMemo } from "react";
import { mainApiConfig } from "../../../../configure/mainApiConfig";
import { AsyncLoadOptions, AsyncLoadResult } from "../../../../shared/abstractStore";
import { childFieldsFragment } from "../../models/Child";
import { ChildrenListViewModelQuery, ChildrenListViewModelQueryVariables } from "../../generated/graphql";
import { termFieldsFragment } from "../../models/Term";
import { termHolidayFieldsFragment } from "../../models/TermHoliday";
export interface ChildrenListViewModelAsyncLoadOptions extends AsyncLoadOptions {
    ids?: Array<string>,
}

/**
 * Get all data required for the ScheduledClassesList component from the api.
 * @param options
 */
export function useChildrenListViewModel(options: ChildrenListViewModelAsyncLoadOptions = {}): AsyncLoadResult<ChildrenListViewModelQuery> {
    // Query data we need from the api.
    const [load, { data, loading, error, refetch }] = useLazyQuery<ChildrenListViewModelQuery, ChildrenListViewModelQueryVariables>(
        gql`
        query childrenListViewModelQuery ($offset: Int, $first: Int, $ids: [ID!]) {
            items: children (offset: $offset, first: $first, ids: $ids) {
                ...childFields
            }

            profiles {
                 id
                userId
                firstName
                lastName
                archived
                primaryEmail
                secondaryEmail
                primaryPhone
                secondaryPhone
                addressLine1
                addressLine2
                city
                county
                postcode
                country
                genderId
                isStaff
                isMember
                
                user {
                  id
                  email
                }
            }

            lastUpdatedNote: latestStaffNotes (targetIds: $ids) {
                updatedDate
                targetId
            }

            terms {
                ...termFields
            }

            termHolidays {
                ...termHolidayFields
            }
        }

        ${childFieldsFragment}
        ${termFieldsFragment}
        ${termHolidayFieldsFragment}
        `,
        {
            variables: {
                ids: options.ids
            },
            fetchPolicy: options.fetchPolicy ?? mainApiConfig.defaultFetchPolicy,
            pollInterval: options.pollInterval,
            onCompleted: (data) => {
                if (options.onCompleted) {
                    options.onCompleted(data);
                }
            }
        }
    );

    // Run the query (unless we are being lazy).
    React.useEffect(() => {
        if (!options.lazy) {
            load();
        }
    }, [options.lazy, load]);

    // Return the results.
    const refresh = React.useCallback(async () => { !refetch ? await load() : await refetch(); }, [refetch, load]);
    return useMemo(() => ({
        data: data ?? ({} as any),
        refresh: refresh,
        isLoading: loading,
        errors: error,
    }), [data, refresh, loading, error,]);
};