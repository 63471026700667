import moment from "moment";
import { ValidationErrors } from "pojo-validator";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, FormGroup, Table } from "reactstrap";
import { useCurrentUserId } from "../../api/account";
import { Profile } from "../../api/main/models/Profile";
import { StaffNote, staffNoteDefaultValues } from "../../api/main/models/StaffNote";
import { ModelArrayChanges } from "../../shared/useChanges";
import { StaffNoteComponent } from "./StaffNotesComponent";

export interface StaffNoteType {
    targetId: string | undefined,
    targetType: 'ClassLocation' | 'Member' | 'Child' | 'ScheduledClass',
    headingName: string | undefined,
    modalHeading: string | undefined,
};

export interface StaffNotesTabProps {
    model: StaffNoteType | null | undefined,
    staffProfiles: Array<Profile> | null | undefined,

    staffNotesManager: ModelArrayChanges<StaffNote, string>,
    validateStaffNote: (model: StaffNote) => boolean,
    staffNoteValidationErrors: (id: string) => ValidationErrors,
    saveForm: (options?: { dontNavigate?: boolean; }) => Promise<void>,
};

/**
 * StaffNotes - Component is used under ClassLocations, Members, and Children notes tabs.
 */
export const StaffNotesTab = (props: StaffNotesTabProps) => {
    const {
        model,
        staffNotesManager,
        validateStaffNote,
        staffNoteValidationErrors,
        staffProfiles,
        saveForm,
    } = props;
    const { t } = useTranslation();

    const currentUserId = useCurrentUserId();

    // Add a new StaffNote and link it to the parent model
    const [newStaffNoteId, setNewStaffNoteId] = useState<string | undefined>(undefined);
    const addStaffNote = useCallback(() => {
        const newModel = { ...staffNoteDefaultValues(), targetId: model?.targetId, targetType: model?.targetType, updatedDate: moment().toISOString(), updatedByUserId: currentUserId, };
        staffNotesManager.addFor(newModel);
        setNewStaffNoteId(newModel.id);
    }, [staffNotesManager, model, currentUserId, setNewStaffNoteId]);

    // Render the UI
    //
    return (
        <>
            <FormGroup>
                <Table responsive striped>
                    <thead>
                        <tr>
                            <th>{t('stafffNotesTab.tableHeading.notesAboutThisLocation', 'Notes about this {{headingName}}', { headingName: model?.headingName?.toLocaleLowerCase() })}</th>
                            <th>{t('stafffNotesTab.tableHeading.showOnRegister', 'Show on register')}</th>
                            <th>{t('stafffNotesTab.tableHeading.noteBy', 'Note by')}</th>
                            <th>{t('stafffNotesTab.tableHeading.updated', 'Updated')}</th>
                            <th>{t('stafffNotesTab.tableHeading.delete', 'Delete')}</th>
                        </tr>
                    </thead>

                    <tbody>
                        {
                            staffNotesManager.model.map(item => (
                                <StaffNoteComponent key={item.id}
                                    model={item}
                                    staffProfiles={staffProfiles}
                                    change={changes => staffNotesManager.changeFor(item.id, changes)}
                                    remove={() => staffNotesManager.removeFor(item.id)}
                                    validate={() => validateStaffNote(item)}
                                    validationErrors={staffNoteValidationErrors(item.id)}
                                    saveForm={saveForm}
                                    initialModalIsOpen={item.id === newStaffNoteId ? true : false}
                                    modalHeading={model?.modalHeading ?? ''}
                                />
                            ))
                        }
                    </tbody>
                </Table>

                <div className="mt-2">
                    <Button color="primary" outline onClick={() => addStaffNote()}>
                        {t('stafffNotesTab.note.add', 'Add staff note')}
                    </Button>
                </div>
            </FormGroup>
        </>
    );
};