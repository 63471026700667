import { Navigate } from "react-router-dom";
import { useCurrentUserProfile } from "../../api/main/profiles/useCurrentUserProfile";
import { useStaff } from "../../api/main/staffs/useStaff";
/**
 * Default page for / url.
 * 
 * TODO testing with different account configs and making it so you don't 
 * get sent to /my by default just because your profile hasn't loaded yet.
 * It should wait for your profile to load or determine that there isn't one to load and then decide
 */
export const HomeIndex = () => {
    const { data: { model: profile } } = useCurrentUserProfile();
    const { data: { model: staff } } = useStaff(profile?.userId);

    if ((profile?.isMember && !profile?.isStaff) || !profile) {
        return (<Navigate to="/my" replace />)
    }
    else if (profile?.isStaff) {
            if (staff?.isAdminStaff) {
                return (<Navigate to="/administration" replace />);

            }
            else {
                return (<Navigate to="/staff" replace />);
            }
    }
    else {
        return null;
    }


    
};