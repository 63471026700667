import { Document, Image, Page, Text, View, StyleSheet } from '@react-pdf/renderer';
import moment from 'moment';
import { PaymentCheckoutViewModelQuery } from '../../../api/main/generated/graphql';
import { GiftVoucher } from '../../../api/main/models/GiftVoucher';
import { Profile } from '../../../api/main/models/Profile';

export interface PaymentCheckoutReceiptPDFProps {
    model: PaymentCheckoutViewModelQuery['model'];
    member: Profile | undefined | null;
    termStartDate: string;
    termEndDate: string;
    giftVouchers: Array<GiftVoucher> | undefined | null;
};

export const PaymentCheckoutReceiptPDF = (props: PaymentCheckoutReceiptPDFProps) => {
    const {
        model,
        member,
        termStartDate,
        termEndDate,
        giftVouchers
    } = props;

    // Create styles
    const styles = StyleSheet.create({
        page: {
            flexDirection: 'column',
            padding: 10,
        },
        body: {
            flexGrow: 1,
            fontSize: 12,
            marginTop: 30,
            marginBottom: 40,
            marginLeft: 40,
            marginRight: 40,
        },
        header: {
            marginTop: 30,
            marginBottom: 10,
        },
        footer: {
            textAlign: 'center',
            position: 'absolute',
            bottom: 0,
            left: 50,
            right: 50,
        },
        main: {
        },
        section: {
            margin: 10,
            padding: 10,
            flexGrow: 1,
        },
        title: {
            fontSize: 24,
            fontWeight: 'bold',
            color: '#fada00'
        },
        subtitle: {
            fontSize: 14,
            fontWeight: 'bold',
            marginBottom: 10,
        },
        subtitleYellow: {
            fontSize: 16,
            marginTop: 20,
            marginBottom: 20,
            color: '#fada00'
        },
        tableHeading: {
            fontSize: 13,
            marginBottom: 10,
            color: '#fada00'
        },
        text: {
            fontSize: 12,
        },
        boldText: {
            fontSize: 12,
            fontWeight: 'extrabold',
        },
        image: {
            maxWidth: '400px',
            height: 'auto',
            margin: '0 auto'
        },
        payment: {
            display: 'flex',
            flexDirection: 'row',
            border: '2 solid #fada00',
            marginTop: 20,
            padding: 5
        },
        paymentDetails: {
            flexGrow: 2,
            borderRight: '2 solid #fada00'
        },
        paymentAmount: {
            flexGrow: 1,
            paddingLeft: 5
        },
        spacing: {
            marginTop: 10,
            marginBottom: 20
        },
        smallSpacing: {
            marginTop: 5,
            marginBottom: 10
        }
    });

    // BaseUrl - used for images.
    const baseUrl = `https://${window.location.hostname}${window.location.port && window.location.port !== '443' ? ':' + window.location.port : ''}`;

    // If the model is not available, return a message.
    if (!model) {
        return (
            <Document>
                <Page size="A4" orientation="portrait">
                    <Text>PDF could not be generated.</Text>
                </Page>
            </Document>
        );
    }

    // DaysOfWeek - Unable to use dayOfWeekDisplayName as it requires t, but we can use hooks here.
    const daysOfWeek = [
        'Sunday',
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday',
        'Saturday'
    ];

    // Render the UI
    //  
    return (
        <>
            <Document>
                <Page size="A4" orientation="portrait" style={styles.page}>
                    <View fixed>
                        <Image style={styles.image} src={`${baseUrl}/img/emails/logo.png`} />
                    </View>

                    <View style={styles.section}>
                        <View style={styles.header}>
                            <Text style={styles.title}>Receipt</Text>
                        </View>

                        <View style={styles.subtitle}>
                            <Text>Payment date: {moment(model?.createdDate).format('DD/MM/YYYY')}</Text>
                        </View>

                        <View>
                            <Text style={styles.boldText}>Payment by:</Text>
                            <Text style={styles.text}>{member?.firstName} {member?.lastName}</Text>
                            <Text style={styles.text}>{member?.addressLine1}</Text>
                            <Text style={styles.text}>{member?.addressLine2}</Text>
                            <Text style={styles.text}>{member?.city}</Text>
                            <Text style={styles.text}>{member?.postcode}</Text>
                        </View>

                        <View style={styles.subtitleYellow}>
                            <Text>Receipt in relation to the purchase of classes for:</Text>
                        </View>

                        <View>
                            <Text style={styles.text}>Child name: {model?.child?.firstName} {model?.child?.lastName}</Text>
                            <Text style={styles.text}>Birth date: {moment(model?.child?.dateOfBirth).format('DD/MM/YYYY')}</Text>
                            <Text style={styles.text}>Activity: {model?.scheduledClass?.classType?.name} classes</Text>
                            <Text style={styles.text}>Location: {model?.scheduledClass?.classLocation?.name}</Text>
                            <Text style={styles.text}>Class day: {daysOfWeek[model?.scheduledClass?.dayOfWeek ?? 0]}</Text>
                            <Text style={styles.text}>Class time: {model?.scheduledClass?.startTimeHours}:{model?.scheduledClass?.startTimeMinutes}</Text>
                            <Text style={styles.text}>Term:  {model?.term?.name}</Text>
                            <Text style={styles.text}>Term dates:  {termStartDate} - {termEndDate}</Text>
                        </View>

                        <View style={styles.payment}>
                            <View style={styles.paymentDetails}>
                                <Text style={styles.tableHeading}>Order: #{model?.id}</Text>

                                <View >
                                    <Text style={[styles.text, styles.spacing]}>{model?.scheduledClass?.classType?.name} classes - {model?.term?.name} - {model?.child?.firstName} {model?.child?.lastName}</Text>
                                    {model?.paymentDiscounts?.map(discount => {
                                        return <Text style={styles.text}>Discount: {!!discount.generalDiscount ? discount.generalDiscount?.name
                                            : discount.childDiscount ? discount.childDiscount?.name
                                                : 'Discount'}</Text>;
                                    })}
                                </View>

                                <View style={styles.spacing}>
                                    {giftVouchers?.map(voucher => {
                                        return <Text style={styles.text}>Gift voucher: {voucher.serialNumber}</Text>;
                                    })}
                                </View>

                                <Text style={styles.boldText}>Total paid</Text>
                            </View>

                            <View style={styles.paymentAmount}>
                                <Text style={styles.tableHeading}>Amount:</Text>

                                <View >
                                    <Text style={[styles.text, styles.spacing]}>€{model?.totalPreDiscountAmountGross.toFixed(2)}</Text>
                                    {model?.paymentDiscounts?.map(discount => {
                                        return <Text style={styles.text}>-€{(discount.discountAmount).toFixed(2)}</Text>;
                                    })}
                                </View>

                                <View >
                                    {giftVouchers?.map(voucher => {
                                        return <Text style={[styles.text, styles.spacing]}>-€{(voucher.value).toFixed(2)}</Text>;
                                    })}
                                </View>

                                <View>
                                    <Text style={styles.boldText}>€{model?.paidAmountGross.toFixed(2)}</Text>
                                </View>
                            </View>
                        </View>

                        <View>
                            <Text style={styles.subtitleYellow}>Thank you for choosing Happy Cubs &#128155;</Text>
                        </View>

                        <View style={styles.footer}>
                            <Text style={styles.text}>Swim Cubs Limited, T/A Happy Cubs</Text>
                            <Text style={styles.text}>hello@happycubs.ie | happycubs.ie | 087485 5905</Text>
                            <Text style={styles.text}>Company registration number: 608828</Text>
                        </View>
                    </View>
                </Page>
            </Document>
        </>
    );
};