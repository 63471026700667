import { ConditionalFragment } from "react-conditionalfragment";
import { useTranslation } from "react-i18next";
import { Col, ListGroup, Row } from "reactstrap";
import { AlertOnErrors } from "../../shared/alertOnErrors";
import { Banner } from "../shared/banner/Banner";
import { LoadingIndicator } from "../shared/loadingIndicator/LoadingIndicator";
import { MainContainer } from "../shared/mainContainer/MainContainer";
import { StickyToolbar } from "../shared/stickyToolbar/StickyToolbar";
import { BackgroundTaskStatus } from "../../api/main/models/constants/BackgroundTaskStatus"
import { useEffect, useMemo } from "react";
import { useBackgroundTasks } from "../../api/main/backgroundTasks/useBackgroundTasks";
import { BackgroundTaskComponent } from "./BackgroundTaskComponent";

/**
 * Component that lets an administrator see what background tasks are have completed in the past.
 */
export const BackgroundTasksHistory = () => {
    const { t } = useTranslation();

    const {
        data: { items: _items, },
        isLoading,
        errors: loadErrors,
        refresh,
    } = useBackgroundTasks({ historyCutoffDays: 366 /* one year */ });

    // We want to only include cancelled and completed.
    const items = useMemo(() => {
        if (!_items) {
            return _items;
        }

        // Remove anything not in the history statuses.
        let ret = _items.filter(item => item.taskStatus === BackgroundTaskStatus.Completed || item.taskStatus === BackgroundTaskStatus.Cancelled);

        // Reverse the order so the most recent is at the top.
        ret.reverse();

        return ret;
    }, [_items]);


    // While we're on this screen, we want to refresh the task status on a short interval as tasks are continually being processed.
    useEffect(() => {
        // Start an interval timer.
        const timer = setInterval(() => {
            refresh();
        }, 10 * 1000);

        // Cleanup function.
        return () => clearInterval(timer);
    }, [refresh]);

    // Render the UI
    //
    return (
        <>
            <Banner>
                <StickyToolbar>
                    <Row>
                        <Col>
                            <h1>
                                {t('backgroundTasksHistory.title', 'Automation history')}
                            </h1>
                        </Col>
                        <ConditionalFragment showIf={isLoading}>
                            <Col xs="auto">
                                <LoadingIndicator size="sm" />
                            </Col>
                        </ConditionalFragment>
                    </Row>
                </StickyToolbar>
            </Banner>

            <MainContainer>
                <AlertOnErrors
                    errors={[
                        loadErrors,
                    ]}
                />

                <ListGroup>
                    {
                        items?.map(item => (
                            <BackgroundTaskComponent key={item.id} model={item} refresh={refresh} />
                        ))
                    }
                </ListGroup>
            </MainContainer>
        </>
    );
};