import { IdentityRoles } from "../../../configure/security/IdentityRoles";
import { RouteEntry } from "../../../shared/routes";
import { DeleteScheduledClass } from "./DeleteScheduledClass";
import { CreateScheduledClass, EditScheduledClass } from "./edit/EditScheduledClass";
import { ScheduledClassesList } from "./ScheduledClassesList";


/**
 * Routes that should be added to configuration/routeConfig.ts to provide access to these components via routing.
 */
export const scheduledClassesAdministrationRoutes: Array<RouteEntry> = [
    { path: '/administration/class-management/classes/add', element: <CreateScheduledClass />, authorize: true, requireRole: IdentityRoles.Administration, },
    { path: '/administration/class-management/classes/edit/:id', element: <EditScheduledClass />, authorize: true, requireRole: IdentityRoles.Administration, },
    { path: '/administration/class-management/classes/delete/:id', element: <DeleteScheduledClass />, authorize: true, requireRole: IdentityRoles.Administration, },
    { path: '/administration/class-management/classes', element: <ScheduledClassesList />, authorize: true, requireRole: IdentityRoles.Administration, },
];
