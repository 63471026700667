import { IdentityRoles } from "../../configure/security/IdentityRoles";
import { RouteEntry } from "../../shared/routes";
import { CreateCancellationReason, EditCancellationReason } from './EditCancellationReason'
import { CancellationReasonsList } from './CancellationReasonsList'
import { DeleteCancellationReason } from './DeleteCancellationReason';

/**
 * Routes that should be added to configuration/routeConfig.ts to provide access to these components via routing.
 */
export const cancellationReasonRoutes: Array<RouteEntry> = [
    { path: '/administration/class-management/cancellation-reasons/add', element: <CreateCancellationReason />, authorize: true, requireRole: IdentityRoles.Administration },
    { path: '/administration/class-management/cancellation-reasons/edit/:id', element: <EditCancellationReason />, authorize: true, requireRole: IdentityRoles.Administration },
    { path: '/administration/class-management/cancellation-reasons/delete/:id', element: <DeleteCancellationReason />, authorize: true, requireRole: IdentityRoles.Administration },
    { path: '/administration/class-management/cancellation-reasons', element: <CancellationReasonsList />, authorize: true, requireRole: IdentityRoles.Administration }
];
