import * as React from 'react';
import { useSaveInStore, SaveInStoreOptions } from '../../../shared/useSaveInStore';
import { useUpdateQueryMessageMutation } from './useUpdateQueryMessageMutation';
import { QueryMessage } from '../models/QueryMessage';
import { QueryMessageAndEmailMemberCreateInput, QueryMessageUpdateInput } from '../generated/graphql';
import { useCreateQueryMessageAndEmailMemberMutation } from './useCreateQueryMessageAndEmailMemberMutation';

/**
 * Get a callback to save a QueryMessage in the api using either a create or update.
 */
export function useSaveQueryMessageAndEmailMemberMutation(options: SaveInStoreOptions<QueryMessage, string> = {}) {
    const [_create, createStatus] = useCreateQueryMessageAndEmailMemberMutation();
    const create = React.useCallback((model: Partial<QueryMessage>) => _create(model as QueryMessageAndEmailMemberCreateInput), [_create]);

    const [_update, updateStatus] = useUpdateQueryMessageMutation();
    const update = React.useCallback((id: string, changes: Partial<QueryMessage>) => _update(id, changes as QueryMessageUpdateInput), [_update]);

    return useSaveInStore(
        [create, createStatus],
        [update, updateStatus],
        options
    );
}
