import { useLazyQuery } from "@apollo/client";
import { AsyncLoadOptions, AsyncLoadResult } from "../../../../shared/abstractStore";
import gql from "graphql-tag";
import { mainApiConfig } from "../../../../configure/mainApiConfig";
import { useCallback, useEffect, useMemo } from "react";
import { EditMemberChildSupportingDataQuery } from '../../generated/graphql';
import { genderFieldsFragment } from "../../models/Gender";
import { childsRelationshipFieldsFragment } from "../../models/ChildsRelationship";

/**
 * Get the supporting data needed for dropdowns etc for the EditMemberChild component
 * @param id 
 * @param options
 */
export function useEditMemberChildSupportingData(options: AsyncLoadOptions = {}): AsyncLoadResult<EditMemberChildSupportingDataQuery> {
    // Query the data we need from the api
    const [load, { data, loading, error, refetch }] = useLazyQuery<EditMemberChildSupportingDataQuery>(
        gql`
            query editMemberChildSupportingDataQuery ($offset: Int, $first: Int) {
                childsRelationships (offset: $offset, first: $first) {
                    ...childsRelationshipFields
                }

                genders {
                    ...genderFields
                }
            }

        ${childsRelationshipFieldsFragment}
        ${genderFieldsFragment}
        `,
        {
            variables: {
            },
            fetchPolicy: options.fetchPolicy ?? mainApiConfig.defaultFetchPolicy,
            pollInterval: options.pollInterval,
            onCompleted: (data) => {
                if (options.onCompleted) {
                    options.onCompleted(data);
                }
            }
        }
    );

    // Run the query (unless we are being lazy).
    useEffect(() => {
        if (!options.lazy) {
            load();
        }
    }, [options.lazy, load]);

    // Return the results.
    const refresh = useCallback(async () => { !refetch ? await load() : await refetch(); }, [refetch, load]);
    return useMemo(() => ({
        data: data ?? ({} as any),
        refresh: refresh,
        isLoading: loading,
        errors: error
    }), [data, refresh, loading, error]);
}