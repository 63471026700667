import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ConditionalFragment } from "react-conditionalfragment";
import { useTranslation } from "react-i18next"
import { useNavigate, useParams } from "react-router";
import { useAsyncCallback } from "react-use-async-callback";
import { Button, Col, FormGroup, Input, Label, Row, Spinner, Form } from "reactstrap";
import { ButtonAsync } from "reactstrap-buttonasync";
import { useLockoutCallback } from "../../api/account";
import { useDeleteMemberMutation } from "../../api/main/members/useDeleteMember";
import { useDeleteProfileMutation } from "../../api/main/profiles/useDeleteProfileMutation";
import { useProfile } from "../../api/main/profiles/useProfile";
import { AlertOnErrors } from "../../shared/alertOnErrors";
import { Banner } from "../shared/banner/Banner";
import { FormButtons } from "../shared/formButtons/FormButtons";
import { LoadingIndicator } from "../shared/loadingIndicator/LoadingIndicator";
import { MainContainer } from "../shared/mainContainer/MainContainer";

/**
 * Delete a Member (Profile) 
 */
export const DeleteMember = () => {
    const { t } = useTranslation();
    const { id } = useParams<{ id: string; }>();
    const navigate = useNavigate();

    // Load the data
    const { data: { model, }, errors: loadErrors, isLoading } = useProfile(id);

    // Delete the Profile
    const [removeProfile, { errors: removeProfileErrors }] = useDeleteProfileMutation();

    // Delete the Member
    const [removeMember, { errors: removeMemberErrors }] = useDeleteMemberMutation();

    // Lockout the Member
    const [lockout, { errors: lockoutErrors }] = useLockoutCallback();

    // When we submit, delete the Member
    const [onRemoveFormSubmit, { isExecuting: isRemoving, errors: removeFormErrors }] = useAsyncCallback(async () => {
        if (!model || !model.user) {
            return;
        }

        await removeMember(model.id);
        await removeProfile(model.id);
        await lockout({ email: model?.user.email, archive: true });
        navigate(-1);
    }, [lockout, model, navigate, removeProfile, removeMember]);

    // Render the UI
    //
    return (
        <>
            <Banner>
                <Row>
                    <Col>
                        <h1>{t('deleteMember.heading', 'Are you sure you want to delete this bear?')}</h1>
                    </Col>
                    <ConditionalFragment showIf={isLoading}>
                        <Col xs="auto">
                            <LoadingIndicator size="sm" />
                        </Col>
                    </ConditionalFragment>
                </Row>
            </Banner>

            <MainContainer>
                <AlertOnErrors errors={[loadErrors, removeFormErrors, removeMemberErrors, removeProfileErrors, lockoutErrors]} />

                <Form onSubmit={e => { e.preventDefault(); onRemoveFormSubmit(); }}>
                    <FormGroup>
                        <Label htmlFor="name">{t('deleteMember.name.label', "name")}</Label>
                        <Input name="name" type="text" readOnly value={model?.firstName ?? ''} />
                    </FormGroup>

                    <FormButtons>
                        <ButtonAsync color="danger" isExecuting={isRemoving}
                            executingChildren={<> <Spinner size="sm" /> {t('common.deleting', 'Deleting...')} </>}>
                            <FontAwesomeIcon icon="trash" />
                            <> </>
                            {t('common.delete', 'Delete')}
                        </ButtonAsync>
                        <Button type="button" color="primary" outline onClick={e => navigate(- 1)}>
                            {t('common.cancel', 'Cancel')}
                        </Button>
                    </FormButtons>
                </Form>
            </MainContainer>
        </>
    );
}