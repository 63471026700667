import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useValidatorCallback } from "pojo-validator-react";
import { ConditionalFragment } from "react-conditionalfragment";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { useAsyncCallback } from "react-use-async-callback";
import { Button, ButtonGroup, Col, Form, FormGroup, FormText, Label, ModalBody, ModalFooter, ModalHeader, NavItem, NavLink, Row, Spinner } from "reactstrap";
import { BlobUploadService } from "../../../api/main/blobReferences/BlobUploadService";
import { useBlobReference } from "../../../api/main/blobReferences/useBlobReference";
import { useSaveChildMutation } from "../../../api/main/children/useSaveChildMutation";
import { useEditMemberChildSupportingData } from "../../../api/main/members/viewModels/useEditMemberChildSupportingData";
import { AlertOnErrors } from "../../../shared/alertOnErrors";
import { useChanges, useChangesArray } from "../../../shared/useChanges";
import { Banner } from "../../shared/banner/Banner";
import { LoadingIndicator } from "../../shared/loadingIndicator/LoadingIndicator";
import { MainContainer } from "../../shared/mainContainer/MainContainer";
import { StickyToolbar } from "../../shared/stickyToolbar/StickyToolbar";
import moment from "moment";
import { HtmlDisplay, HtmlEditor } from "../../../shared/htmlEditor";
import "./editMemberChild.scss";
import { StyledModal } from "../../shared/styledModal/StyledModal";
import { useToggleState } from "use-toggle-state";
import { ValidatedInput } from "pojo-validator-reactstrap";
import { ValidatedISODateTimeInput } from "../../shared/isoDateTimeInput/ValidatedISODateTimeInput";
import { UploadedImagePreview } from "../../shared/uploadedImagePreview/UploadedImagePreview";
import { FileUploadButton } from "../../shared/fileUploadButton/FileUploadButton";
import { ButtonAsync } from "reactstrap-buttonasync";
import { FormButtons } from "../../shared/formButtons/FormButtons";
import { useCallback, useState } from "react";
import { useReplaceSearchParamsEffect, useSearchParams } from "../../../shared/useURLSearchParams";
import { PillsNavBar } from "../../shared/pillsNavBar/PillsNavBar";
import { useEditMemberChildViewModel } from "../../../api/main/members/viewModels/useEditMemberChildViewModel";
import { EmergencyContact, emergencyContactDefaultValues } from "../../../api/main/models/EmergencyContact";
import { useSaveEmergencyContactMutation } from "../../../api/main/emergencyContacts/useSaveEmergencyContactMutation";
import { useDeleteEmergencyContactMutation } from "../../../api/main/emergencyContacts/useDeleteEmergencyContact";
import { useValidatorArrayCallback } from "../../../shared/validator-react-contrib/useValidatorArrayCallback";
import { HorizontalRule } from "../../shared/horizontalRule/HorizontalRule";
import { EmergencyContactComponent } from "./emergencyContactsTab/EmergencyContactComponent";

export const EditMemberChild = () => {
    const { t } = useTranslation();
    const { childId } = useParams<{ childId: string | undefined; }>();
    const navigate = useNavigate();

    // Handle showing of the Edit Modal modal
    const [editModalIsOpen, toggleEditModalOpen] = useToggleState();

    // Load the data
    const {
        data: {
            model: storeChild,
            emergencyContacts
        }, isLoading: _isLoading, errors: loadErrors, refresh
    } = useEditMemberChildViewModel(childId);

    // Load the supporting data
    const {
        data: {
            genders,
            childsRelationships
        }, isLoading: isLoadingSupportingData, errors: loadSupportingDataErrors
    } = useEditMemberChildSupportingData();
    const isLoading = _isLoading || isLoadingSupportingData;

    // Model (Child)
    const { model, change, changes } = useChanges(storeChild, {});
    const [saveChild, { errors: saveErrors }] = useSaveChildMutation();

    // Child image upload (photo)
    const { data: { model: image }, isLoading: isLoadingImage, errors: imageLoadErrors } = useBlobReference(model?.photoBlobReferenceId);
    const [onUploadImage, { errors: imageUploadErrors, isExecuting: isUploadingImage }] = useAsyncCallback(async (files: FileList | null) => {
        if (!files) {
            return;
        }

        let uploadService: BlobUploadService = new BlobUploadService("/api/blobs");
        let result = await uploadService.upload(files);

        if (!!result) {
            change({ photoBlobReferenceId: result.id });
        }
    }, [change]);

    // Clear image functionality
    const [clearImage, { isExecuting: isClearingImage }] = useAsyncCallback(async () => {
        change({ photoBlobReferenceId: null });
    }, [change]);

    // Model (EmergencyContacts)
    const childEmergencyContactsManager = useChangesArray<EmergencyContact, string>(emergencyContacts, item => item.id);
    const [saveEmergencyContact] = useSaveEmergencyContactMutation();
    const [removeEmergencyContact] = useDeleteEmergencyContactMutation();

    // EmergencyContacts validation
    const [validateEmergencyContact, emergencyContactValidationErrors] = useValidatorArrayCallback<EmergencyContact>((myModel, validation, fieldsToCheck) => {
        const rules = {
            firstName: () => !myModel?.firstName ? t('editMemberChild.emergencyContact.errors.firstName', 'First name is required') : '',
            lastName: () => !myModel?.lastName ? t('editMemberChild.emergencyContact.errors.lastName', 'Last name is required') : '',
            primaryPhone: () => !myModel?.primaryPhone ? t('editMemberChild.emergencyContact.errors.primaryPhone', 'A primary phone number is required') : '',
        };

        validation.checkRules(rules, fieldsToCheck);
    }, []);

    // Main model validation
    const [validate, validationErrors] = useValidatorCallback((validation, fieldsToCheck) => {
        const rules = {
            firstName: () => !model?.firstName ? t('editMemberChild.errors.firstNameRequired', 'First name is required') : '',
            lastName: () => !model?.lastName ? t('editMemberChild.errors.lastNameRequired', 'Last name is required') : '',
            memberId: () => !model?.memberId ? t('editMemberChild.errors.memberIdRequired', 'Member is required') : '',
            genderId: () => !model?.genderId ? t('editMemberChild.errors.genderId', 'Gender is required') : '',
            memberChildsRelationshipId: () => !model?.memberChildsRelationshipId ? t('editMemberChild.memberChildsRelationshipId', 'Relationship to child is required') : '',
            dateOfBirth: () => !model?.dateOfBirth ? t('editMemberChild.dateOfBirth.errors.startDate', 'Date of birth is required') : '',

            // Any errors in the related object
            emergencyContacts: () => childEmergencyContactsManager.model.filter(it => !validateEmergencyContact(it)).length ? t('editMemberChild.errors.emergegencyContacts', 'One or more of the emergency contacts are invalid') : '',
        };
        validation.checkRules(rules, fieldsToCheck);
    }, [model, childEmergencyContactsManager, validateEmergencyContact]);

    // Save everything
    const [saveForm, { isExecuting: isSaving, errors: saveFormErrors }] = useAsyncCallback(async () => {
        if (!model) {
            return;
        }

        if (!validate()) {
            return;
        }

        // Save the main model.
        await saveChild(model.id, { ...changes }, false);

        // Saves changes to the EmergencyContact
        for (const item of childEmergencyContactsManager.added) { await saveEmergencyContact(item.id, childEmergencyContactsManager.changesFor(item.id), true); }
        for (const item of childEmergencyContactsManager.updated) { await saveEmergencyContact(item.id, childEmergencyContactsManager.changesFor(item.id), false); }
        for (const item of childEmergencyContactsManager.removed) { await removeEmergencyContact(item.id); }
        childEmergencyContactsManager.markAsSaved();

        // Refresh and close the modal
        refresh();
        toggleEditModalOpen();
    }, [validate, model, changes]);

    // Manage the tab being displayed.  If we have tab= on the query string, default to it, otherwise default to the main tab.
    const { tab: tabParam } = useSearchParams();
    const [activeTab, setActiveTab] = useState<'main' | 'emergency-contacts'>(tabParam as any || 'main');

    // Keep the URL up to date with the currentTab
    useReplaceSearchParamsEffect({ tab: activeTab === 'main' ? '' : activeTab });

    // Add a new EmergencyContact and link it to the parent model
    const addChildEmergencyContact = useCallback(() => {
        childEmergencyContactsManager.addFor({ ...emergencyContactDefaultValues(), childsRelationshipId: model?.memberChildsRelationshipId, targetId: model?.id, targetType: 'Child' });
    }, [childEmergencyContactsManager, model]);

    // Render the UI
    //
    return (
        <>
            <Banner fluid>
                <StickyToolbar>
                    <Row style={{ textAlign: 'center' }}>
                        <Col>
                            <h1 style={{ fontSize: '3rem' }}>
                                {t('editMemberChild.title', ' Bear Cub\'s details')}
                            </h1>
                        </Col>
                        <ConditionalFragment showIf={isLoading || isLoadingImage}>
                            <Col xs="auto">
                                <LoadingIndicator size="sm" />
                            </Col>
                        </ConditionalFragment>
                    </Row>
                </StickyToolbar>
            </Banner>

            <MainContainer className="edit-member-child">
                <AlertOnErrors errors={[
                    loadErrors, loadSupportingDataErrors,
                    saveErrors, saveFormErrors,
                    imageLoadErrors, imageUploadErrors
                ]} />

                {/* Viewing Section */}
                {/* Profile Image */}
                <Row className="m-0 p-0 justify-content-center">
                    <ConditionalFragment showIf={!image}>
                        <FontAwesomeIcon icon="user" fontSize="10rem" />
                    </ConditionalFragment>

                    <ConditionalFragment showIf={!!image && !isLoadingImage}>
                        <img className="edit-member-child-profile-image" src={image?.url ?? '/img/originals/profileImagePlaceholder.jpg'} alt="Profile" />
                    </ConditionalFragment>
                </Row>

                {/* Name */}
                <Row className="edit-member-child-name">
                    <p>{t('common.fullName', '{{firstName}} {{lastName}}', { firstName: model?.firstName ?? '', lastName: model?.lastName ?? '' })}</p>
                </Row>

                {/* Details */}
                <Row className="edit-member-child-details">
                    <Col className="edit-member-child-details-dob">
                        <p className="edit-member-child-details-dob-label">{t('com  mon.dateOfBirth.label', 'Date of birth')}</p>
                        <p className="edit-member-child-details-dob-value">{t('common.date', '{{date, DD/MM/YYYY}}', { date: moment(model?.dateOfBirth) })}</p>
                    </Col>

                    <Col className="edit-member-child-details-gender">
                        <p className="edit-member-child-details-gender-label">{t('common.gender', 'Gender')}</p>
                        <p className="edit-member-child-details-gender-value">{genders?.find(it => it.id === model?.genderId)?.name}</p>
                    </Col>
                </Row>

                {/* Health Notes */}
                <Row className="edit-member-child-health-notes">
                    <p className="edit-member-child-health-notes-label">{t('common.healthNotes', 'Health notes')}</p>
                    <HtmlDisplay html={model?.healthNoteHtml} />
                </Row>

                {/* Emergency Contacts */}
                <ConditionalFragment showIf={!!childEmergencyContactsManager.model.length}>
                    <HorizontalRule />

                    <h3 className="edit-member-child-emergency-contacts-title">{t('common.emergencyContacts', 'Emergency contacts')}</h3>

                    {childEmergencyContactsManager.model.map(emergencyContact => (
                        <Row key={emergencyContact.id} className="edit-member-child-emergency-contact">
                            <Row>
                                <Col>
                                    <p className="edit-member-child-emergency-contact-label">{t('common.name', 'Name')}</p>
                                    <p className="edit-member-child-emergency-contact-value">{t('common.fullName', '{{firstName}} {{lastName}}', { firstName: emergencyContact?.firstName ?? '', lastName: emergencyContact?.lastName ?? '' })}</p>
                                </Col>

                                <Col>
                                    <p className="edit-member-child-emergency-contact-label">{t('common.relationship', 'Relationship')}</p>
                                    <p className="edit-member-child-emergency-contact-value">{childsRelationships?.find(it => it.id === emergencyContact?.childsRelationshipId)?.name}</p>
                                </Col>
                            </Row>

                            <Row>
                                <Col>
                                    <p className="edit-member-child-emergency-contact-label">{t('common.primaryPhone', 'Primary phone')}</p>
                                    <p className="edit-member-child-emergency-contact-value">{emergencyContact?.primaryPhone}</p>
                                </Col>

                                <Col>
                                    <p className="edit-member-child-emergency-contact-label">{t('common.primaryEmail', 'Primary email')}</p>
                                    <p className="edit-member-child-emergency-contact-value">{emergencyContact?.primaryEmail}</p>
                                </Col>
                            </Row>

                            <ConditionalFragment showIf={!!emergencyContact.secondaryPhone || !!emergencyContact.secondaryEmail}>
                                <Row>
                                    <Col>
                                        <p className="edit-member-child-emergency-contact-label">{t('common.secondaryPhone', 'Secondary phone')}</p>
                                        <p className="edit-member-child-emergency-contact-value">{emergencyContact?.secondaryPhone}</p>
                                    </Col>

                                    <Col>
                                        <p className="edit-member-child-emergency-contact-label">{t('common.secondaryEmail', 'Secondary email')}</p>
                                        <p>{emergencyContact?.secondaryEmail}</p>
                                    </Col>
                                </Row>
                            </ConditionalFragment>
                        </Row>
                    ))}
                </ConditionalFragment>

                {/* Actions */}
                <Row className="gap-2">
                    <Button color="primary" onClick={() => toggleEditModalOpen()}>{t('common.updateDetails', 'Update details')}</Button>
                    <Button color="secondary" outline onClick={() => navigate(-1)}>{t('common.back', 'Back')}</Button>
                </Row>

                {/* Edit Modal */}
                <ConditionalFragment showIf={!!editModalIsOpen}>
                    <StyledModal
                        isOpen={editModalIsOpen}
                        toggle={() => toggleEditModalOpen()}
                        size="lg"
                    >
                        <ModalHeader toggle={() => toggleEditModalOpen()}>
                            <Row>
                                <Col xs={12} md="auto">
                                    {t('common.updateDetails', 'Update details')}
                                </Col>
                            </Row>
                        </ModalHeader>

                        <ModalBody>
                            <Row style={{ margin: '0 auto', marginBottom: '2rem' }}>
                                <PillsNavBar>
                                    <NavItem>
                                        <NavLink active={activeTab === 'main'} onClick={() => setActiveTab('main')}>
                                            {t('editMemberChild.nav.main', 'Details')}
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink active={activeTab === 'emergency-contacts'} onClick={() => setActiveTab('emergency-contacts')}>
                                            {t('editMember.nav.emergencyContacts', 'Emergency contacts')}
                                        </NavLink>
                                    </NavItem>
                                </PillsNavBar>
                            </Row>

                            <Form onSubmit={e => { e.preventDefault(); }}>
                                {
                                    activeTab === 'main' ? (
                                        <>
                                            <Row>
                                                <Col>
                                                    <FormGroup>
                                                        <Label htmlFor="firstName">{t('editMemberChild.mainTab.firstName.label', 'First name')}</Label>
                                                        <ValidatedInput name="firstName" type="text" value={model?.firstName ?? ''} onChange={e => change({ firstName: e.currentTarget.value })} onBlur={e => validate('firstName')} validationErrors={validationErrors['firstName']} />
                                                    </FormGroup>
                                                </Col>
                                                <Col>
                                                    <FormGroup>
                                                        <Label htmlFor="lastName">{t('editMemberChild.mainTab.lastName.label', 'Last name')}</Label>
                                                        <ValidatedInput name="lastName" type="text" value={model?.lastName ?? ''} onChange={e => change({ lastName: e.currentTarget.value })} onBlur={e => validate('lastName')} validationErrors={validationErrors['lastName']} />
                                                    </FormGroup>
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col>
                                                    <FormGroup>
                                                        <Label htmlFor="dateOfBirth">{t('editMemberChild.mainTab.dateOfBirth.label', 'Date of birth')}</Label>
                                                        <ValidatedISODateTimeInput type="date" value={model?.dateOfBirth ?? ''} onChange={e => change({ dateOfBirth: e.currentTarget.value })} onBlur={e => validate('dateOfBirth')} validationErrors={validationErrors['dateOfBirth']}></ValidatedISODateTimeInput>
                                                    </FormGroup>
                                                </Col>
                                                <Col>
                                                    <FormGroup>
                                                        <Label htmlFor="genderId">{t('editMemberChild.mainTab.genderId.label', 'Gender')}</Label>
                                                        <ValidatedInput name="genders" type="select" value={model?.genderId ?? ''} onChange={e => change({ genderId: e.currentTarget.value })} onBlur={e => validate('genderId')} validationErrors={validationErrors['genderId']}>
                                                            <option value="">{t('editMemberChild.mainTab.genderId.pleaseSubmit', '(Please select a gender)')}</option>
                                                            {
                                                                genders?.map(item => (
                                                                    <option key={item.id} value={item.id}>{t('editMemberChild.mainTab.genderId.value', '{{ gender }}', { gender: item.name })}</option>
                                                                ))
                                                            }
                                                        </ValidatedInput>
                                                    </FormGroup>
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col>
                                                    <FormGroup>
                                                        <Label htmlFor="name">{t('editMemberChild.image.label', 'Photo')}</Label>
                                                        <UploadedImagePreview size="sm" src={image?.url ?? ''} />
                                                        <FormText>{t('editMemberChild.photo.warning', 'A photo will be used so staff are able to better recognise cubs.')}</FormText>
                                                        <Row>
                                                            <Col>
                                                                <ButtonGroup>
                                                                    <FileUploadButton
                                                                        color={`primary`}
                                                                        isExecuting={isUploadingImage}
                                                                        executingChildren={<><Spinner size="sm" /> {t('common.uploading', 'Uploading...')}</>}
                                                                        onUpload={onUploadImage}
                                                                        outline={false}>
                                                                        {t('editMemberChild.uploadButtonText', 'Upload a photo')}
                                                                    </FileUploadButton>
                                                                    <ButtonAsync color="primary"
                                                                        outline
                                                                        isExecuting={isClearingImage}
                                                                        type="button"
                                                                        onClick={clearImage}
                                                                        executingChildren={<><Spinner size="sm" /> {t('editMemberChild.clearingImage', 'Clearing photo...')}</>}>
                                                                        {t('editMemberChild.clearImageButton', 'Clear photo')}
                                                                    </ButtonAsync>
                                                                </ButtonGroup>
                                                            </Col>
                                                        </Row>
                                                    </FormGroup>
                                                </Col>
                                            </Row>

                                            <FormGroup>
                                                <Label htmlFor="HealthNoteHtml">{t('editMemberChild.mainTab.healthNotes.label', 'Health notes')}</Label>
                                                <HtmlEditor size="sm" value={model?.healthNoteHtml ?? ''} onChange={text => change({ healthNoteHtml: text })} />
                                            </FormGroup>
                                        </>
                                    ) : activeTab === 'emergency-contacts' ? (
                                        <>
                                            <div className="mt-2 mb-2">
                                                <Button color="primary" outline onClick={() => addChildEmergencyContact()}>
                                                    {t('editMemberChild.emergencyContactsTab.add', 'Add emergency contact')}
                                                </Button>
                                            </div>

                                            {
                                                childEmergencyContactsManager.model.map(emergencyContact => (
                                                    <EmergencyContactComponent key={emergencyContact.id}
                                                        model={emergencyContact}
                                                        change={changes => childEmergencyContactsManager.changeFor(emergencyContact.id, changes)}
                                                        remove={() => childEmergencyContactsManager.removeFor(emergencyContact.id)}
                                                        validate={() => validateEmergencyContact(emergencyContact)}
                                                        validationErrors={emergencyContactValidationErrors(emergencyContact.id)}
                                                        childsRelationships={childsRelationships}
                                                    />
                                                ))
                                            }
                                        </>
                                    ) : null
                                }
                            </Form>
                        </ModalBody>

                        <ModalFooter>
                            {/*Save buttons at the bottom of all tabs. */}
                            <FormButtons>
                                <ConditionalFragment showIf={!isLoading}>
                                    <ButtonAsync color="primary" isExecuting={isSaving} onClick={() => saveForm()}
                                        executingChildren={<><Spinner size="sm" /> {t('common.saving', 'Saving...')}</>}>
                                        <FontAwesomeIcon icon="save" />
                                        <> </>
                                        {t('common.save', 'Save')}
                                    </ButtonAsync>
                                </ConditionalFragment>

                                <Button type="button" color="primary" outline onClick={() => toggleEditModalOpen()}>
                                    {t('common.cancel', 'Cancel')}
                                </Button>
                            </FormButtons>
                        </ModalFooter>
                    </StyledModal>
                </ConditionalFragment>

                <ConditionalFragment showIf={isLoading && !model}>
                    <LoadingIndicator fullWidth />
                </ConditionalFragment>
            </MainContainer>
        </>
    );
};