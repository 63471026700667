import * as React from 'react';
import { useSaveInStore, SaveInStoreOptions } from '../../../shared/useSaveInStore';
import { useUpdateChildAbsenceMutation } from './useUpdateChildAbsenceMutation';
import { ChildAbsence } from '../models/ChildAbsence';
import { useCreateChildAbsenceMutation } from './useCreateChildAbsenceMutation';
import { ChildAbsenceCreateInput, ChildAbsenceUpdateInput } from '../generated/graphql';

/**
 * Get a callback to save a ChildAbsence in the api using either a create or update.
 */
export function useSaveChildAbsenceMutation(options: SaveInStoreOptions<ChildAbsence, string> = {}) {
    const [_create, createStatus] = useCreateChildAbsenceMutation();
    const create = React.useCallback((model: Partial<ChildAbsence>) => _create(model as ChildAbsenceCreateInput), [_create]);

    const [_update, updateStatus] = useUpdateChildAbsenceMutation();
    const update = React.useCallback((id: string, changes: Partial<ChildAbsence>) => _update(id, changes as ChildAbsenceUpdateInput), [_update]);

    return useSaveInStore(
        [create, createStatus],
        [update, updateStatus],
        options
    );
}
