import { useMutation } from "@apollo/react-hooks";
import { useAsyncCallback } from 'react-use-async-callback';
import gql from "graphql-tag";
import { AsyncActionStatus } from '../../../shared/abstractStore';
import { CreateChildAttendanceMutation, CreateChildAttendanceMutationVariables, ChildAttendanceCreateInput } from '../generated/graphql';
import { childAttendanceFieldsFragment } from '../models/ChildAttendance';

/**
 * Get a callback to create a ChildAttendance in the api.
 */
export function useCreateChildAttendanceMutation(): [(model: ChildAttendanceCreateInput) => Promise<void>, AsyncActionStatus] {
    const [mutationAction, { error }] = useMutation<CreateChildAttendanceMutation, CreateChildAttendanceMutationVariables>(
        gql`
            mutation createChildAttendanceMutation ($model: ChildAttendanceCreateInput!) {
                createChildAttendance(model: $model) {
                    ...childAttendanceFields
                }
            }

            ${childAttendanceFieldsFragment}
        `
    );

    // Wrap the mutation in a standard callback.
    const [action, { isExecuting, errors: actionErrors }] = useAsyncCallback(async (model: ChildAttendanceCreateInput) => {
        const result = await mutationAction({ variables: { model: model } });
        if (result.errors && result.errors.length) {
            throw result.errors;
        }
    }, [mutationAction]);

    return [action, { isExecuting: isExecuting, errors: error ? error.message : actionErrors }];
}
