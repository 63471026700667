import * as React from 'react';
import { useSaveInStore, SaveInStoreOptions } from '../../../shared/useSaveInStore';
import { useUpdateGenderMutation } from './useUpdateGenderMutation';
import { Gender } from '../models/Gender';
import { useCreateGenderMutation } from './useCreateGenderMutation';
import { GenderCreateInput, GenderUpdateInput } from '../generated/graphql';

/**
 * Get a callback to save a Gender in the api using either a create or update.
 */
export function useSaveGenderMutation(options: SaveInStoreOptions<Gender, string> = {}) {
    const [_create, createStatus] = useCreateGenderMutation();
    const create = React.useCallback((model: Partial<Gender>) => _create(model as GenderCreateInput), [_create]);

    const [_update, updateStatus] = useUpdateGenderMutation();
    const update = React.useCallback((id: string, changes: Partial<Gender>) => _update(id, changes as GenderUpdateInput), [_update]);

    return useSaveInStore(
        [create, createStatus],
        [update, updateStatus],
        options
    );
}
