import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import { useValidatorCallback } from "pojo-validator-react";
import { ValidatedInput } from "pojo-validator-reactstrap";
import { useEffect } from "react";
import { ConditionalFragment } from "react-conditionalfragment";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { useAsyncCallback } from "react-use-async-callback";
import { Button, FormGroup, Label, Spinner, Form, Row, Col, Alert } from "reactstrap";
import { ButtonAsync } from "reactstrap-buttonasync";
import { useToggleState } from "use-toggle-state";
import { useCurrentUserId } from "../../api/account";
import { scheduledClassCancelationDefaultValues, ScheduledClassCancellation } from "../../api/main/models/ScheduledClassCancellation";
import { useProcessScheduledClassCancellationMutation } from "../../api/main/scheduledClassCancellations/useProcessScheduledClassCancellationMutation";
import { useSaveScheduledClassCancellationMutation } from "../../api/main/scheduledClassCancellations/useSaveScheduledClassCancellationMutation";
import { useScheduledClassForScheduledClassCanellationSupportingData } from "../../api/main/scheduledClasses/viewModels/useScheduledClassForScheduledClassCancellationSupportingData";
import { AlertOnErrors } from "../../shared/alertOnErrors";
import { useChanges } from "../../shared/useChanges";
import { getScheduledClassSummary } from "../scheduledClass/getScheduledClassSummary";
import { Banner } from "../shared/banner/Banner";
import { FormButtons } from "../shared/formButtons/FormButtons";
import { ValidatedISODateTimeInput } from "../shared/isoDateTimeInput/ValidatedISODateTimeInput";
import { LoadingIndicator } from "../shared/loadingIndicator/LoadingIndicator";
import { MainContainer } from "../shared/mainContainer/MainContainer";
import { StickyToolbar } from "../shared/stickyToolbar/StickyToolbar";
import { TwoValueSwitch } from "../shared/twoValueSwitch/TwoValueSwitch";

/**
 * Component to Cancel a ScheduledClass (ScheduledClassCancellation)
 */
export const CancelAClass = () => {
    const { id } = useParams<{ id: string | undefined; }>();
    const { t } = useTranslation();
    const navigate = useNavigate();

    // Get the current UserId
    const currentUserStaffId = useCurrentUserId();

    // Load the supporting data
    const {
        data: {
            model: storeScheduledClass,
            cancellationReasons,
            scheduledClassCancellations
        }, isLoading, errors: loadErrors
    } = useScheduledClassForScheduledClassCanellationSupportingData(id);

    // Model (ScheduledClassCancellations)
    const { model, change, changes } = useChanges({} as ScheduledClassCancellation, { ...scheduledClassCancelationDefaultValues(), scheduledClassId: id, cancelledByStaffUserId: currentUserStaffId, lessonDate: null }); // LessonDate set to null to allow setting the default date in the useEffect below
    const [saveScheduledClassCancellation] = useSaveScheduledClassCancellationMutation();

    // If we don't have a lesson date set, default it to the next time the class will run (i.e. the next day that is the right day of the week, we don't want to get too
    // complicated and worry about holidays here.)
    useEffect(() => {
        // If we don't have a model, keep waiting.
        if (!model || !storeScheduledClass) { return; }

        // If we already have a lesson date set, do nothing.
        if (model.lessonDate !== null) { return; }

        // If we get here we don't have a lesson date set, so lets set it to a default.
        //

        // DayOfWeek the Class runs
        const scheduledClassDayOfWeek = storeScheduledClass.dayOfWeek;

        // Today
        let today = moment();

        // If today's day isn't the day the class runs then increment by one until we find the matching day
        // Moment uses the same dayOfTheWeek Enumeration as .net 0-6 for Sunday-Saturday
        while (today.day() !== scheduledClassDayOfWeek) {
            today.add(1, 'day');
        }

        // Return the date - this will be the pre-selected date for the ScheduledClassCancellation form
        const defaultLessonDate = today.toISOString();

        change({ lessonDate: defaultLessonDate });
    }, [model, change, storeScheduledClass]);

    // Main model validation
    const [validate, validationErrors] = useValidatorCallback((validation, fieldsToCheck) => {
        const rules = {
            cancellationReason: () => !model?.cancellationReasonId ? t('cancelClassList.cancelAClass.cancellationReason.error', 'A cancellation reason is required') : '',
            message: () => !model?.message ? t('cancelClassList.cancelAClass.message.error', 'A cancellation  message is required') : '',
            lessonDate: () => moment(model?.lessonDate).day() !== storeScheduledClass?.dayOfWeek ? t('cancelClassList.cancelAClass.lessonDate.error', 'Lesson date is required, which must be for the same day of the week as this class') : ''
        };

        validation.checkRules(rules, fieldsToCheck);
    }, [model]);

    // Model (CourtesyClassCredits)
    const [processScheduledClassCancellation] = useProcessScheduledClassCancellationMutation();

    // RescheduleClass Toggle
    const [isReschedule, setIsReschedule] = useToggleState(false);

    // Cancellation error message
    const [cancellationError, toggleCancellationError] = useToggleState();

    // Save the form
    const [saveForm, { isExecuting: isSaving, errors: saveFormErrors }] = useAsyncCallback(async () => {
        if (!model) {
            return;
        }

        // Set the StaffId and the CancelledAt
        changes.cancelledByStaffUserId = currentUserStaffId;
        changes.cancelledAt = moment().toISOString();

        if (!validate()) {
            return;
        }

        // Check if there is an existing cancellation for this class on the same date. This logic is implemented inside ProcessScheduledClassCancellation on the server, but adding logic here to stop duplicate cancellations being added.
        const cancelledClassesForSameDateAndClass = scheduledClassCancellations?.filter(it => it.scheduledClassId === model.scheduledClassId && moment(it.lessonDate).date() === moment(model.lessonDate).date());
        if (cancelledClassesForSameDateAndClass.length) {
            toggleCancellationError();
            return;
        }

        //Save the ScheduledClassCanellation
        await saveScheduledClassCancellation(model.id, { ...changes }, true);

        // Process the cancellation.
        await processScheduledClassCancellation(model.id, isReschedule);

        // Go back to the previous screen
        navigate(-1);
    }, [model, saveScheduledClassCancellation, processScheduledClassCancellation]);

    // Render the UI
    //
    return (
        <>
            <Banner fluid>
                <StickyToolbar>
                    <Row>
                        <Col>
                            <h1>{t('cancelClassList.cancelAClass.title', 'Cancel class')}</h1>
                            <h3>{getScheduledClassSummary(storeScheduledClass, { classLocation: storeScheduledClass?.classLocation })}</h3>
                        </Col>
                        <ConditionalFragment showIf={isLoading}>
                            <Col xs="auto">
                                <LoadingIndicator size="sm" />
                            </Col>
                        </ConditionalFragment>
                    </Row>
                </StickyToolbar>
            </Banner>

            <MainContainer fluid>
                <AlertOnErrors
                    errors={[
                        loadErrors,
                        saveFormErrors,
                    ]}
                />

                <ConditionalFragment showIf={!!cancellationError}>
                    <Alert color="warning">
                        {t('cancelClassList.cancellationError.message', 'The class you attempted to cancel for {{date, DD/MM/YYYY}}, has already been cancelled. No changes have been made.', { date: moment(model.lessonDate) })}
                    </Alert>
                </ConditionalFragment>

                <Form onSubmit={e => { e.preventDefault(); }}>
                    <Row>
                        <Col>
                            <FormGroup>
                                <Label htmlFor="lessonDate">{t('common.lessonDate', 'Lesson date')}</Label>
                                <ValidatedISODateTimeInput name="lessonDate" type="date" value={model?.lessonDate ?? ''} onChange={e => change({ lessonDate: e.currentTarget.value })} onBlur={e => validate('lessonDate')} validationErrors={validationErrors['lessonDate']} />
                            </FormGroup>
                        </Col>

                        <Col>
                            <FormGroup>
                                <Label htmlFor="cancellationReason">{t('common.cancellationReason', 'Cancellation Reason')}</Label>

                                <ValidatedInput name="cancellationReason" type="select" value={model?.cancellationReasonId ?? ''} onChange={e => change({ cancellationReasonId: e.currentTarget.value })} onBlur={e => validate('cancellationReason')} validationErrors={validationErrors['cancellationReason']} >
                                    <option value="">{t('cancelClassList.cancelAClass.pleaseSelect', '(Please select cancellation reason)')}</option>
                                    {cancellationReasons?.map(item => (
                                        <option value={item.id} key={item.id}>{item.name}</option>
                                    ))}
                                </ValidatedInput>
                            </FormGroup>
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <FormGroup>
                                <Label htmlFor="message">{t('common.messages', 'Message')}</Label>
                                <ValidatedInput name="message" type="textarea" value={model?.message ?? ''} onChange={e => change({ message: e.currentTarget.value })} onBlur={e => validate('message')} validationErrors={validationErrors['message']} />
                            </FormGroup>
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <FormGroup>
                                <Label htmlFor="scheduledClassId">{t('cancelClassList.cancelAClass.rescheduleClass', 'Class is going to be re-scheduled')}</Label>
                                <TwoValueSwitch leftLabel="No" rightLabel="Yes" checked={isReschedule} onChange={() => setIsReschedule()} />
                            </FormGroup>
                        </Col>
                    </Row>

                    <ConditionalFragment showIf={!!isReschedule}>
                        <Alert color="info">
                            {t('cancelClassList.cancelAClass.reschedule.message', 'As you have selected that this class will be rescheduled, no courtesy class credits will be issued and you are required to re-schedule this class manually.')}
                        </Alert>
                    </ConditionalFragment>

                    <FormButtons>
                        <ConditionalFragment showIf={!isLoading}>
                            <ButtonAsync color="primary" isExecuting={isSaving} onClick={() => saveForm()}
                                executingChildren={<><Spinner size="sm" /> {t('common.saving', 'Saving...')}</>}>
                                <FontAwesomeIcon icon="save" />
                                <> </>
                                {t('common.save', 'Save')}
                            </ButtonAsync>
                        </ConditionalFragment>

                        <Button type="button" color="primary" outline onClick={e => navigate(-1)}>
                            {t('common.cancel', 'Cancel')}
                        </Button>
                    </FormButtons>
                </Form>
            </MainContainer>
        </>
    );
};