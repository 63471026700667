import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { StaffSubmissionAcceptanceState } from "../../../api/main/models/constants/StaffSubmissonAcceptanceState";
import { StaffAbsence } from "../../../api/main/models/StaffAbsence";

/**
 * Hook that returns the appropriate color and statusMessage depending on the StaffSubmissionAcceptanceState.
 * @param model
 * @returns 
 */
export function useStaffAbsenceStatusColorAndMessage(model: StaffAbsence | null | undefined) {
    const { t } = useTranslation();
    return useMemo(() => {
        switch (model?.acceptanceState as StaffSubmissionAcceptanceState | undefined) {
            case StaffSubmissionAcceptanceState.Approved: return { color: 'success', statusMessage: t('useStaffAbsenceStatusColorAndMessage.approved.message', 'Approved') };
            case StaffSubmissionAcceptanceState.SelfAccepted: return { color: undefined, statusMessage: t('useStaffAbsenceStatusColorAndMessage.selfApproved.message', 'Self approved') };
            case StaffSubmissionAcceptanceState.Rejected: return { color: 'danger', statusMessage: t('useStaffAbsenceStatusColorAndMessage.rejected.message', 'Rejected') };
            case StaffSubmissionAcceptanceState.Submitted: return { color: undefined, statusMessage: t('useStaffAbsenceStatusColorAndMessage.pending.message', 'Pending') };
        }

        // If we hit here we don't know, so return some defaults.
        return { color: undefined, statusMessage: undefined };
    }, [model, t]);
}