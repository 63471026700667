import { useLazyQuery } from "@apollo/client";
import gql from "graphql-tag";
import React, { useMemo } from "react";
import { mainApiConfig } from "../../../../configure/mainApiConfig";
import { AsyncLoadPagedOptions, AsyncLoadPagedResult } from "../../../../shared/abstractStore";
import { useFetchMoreCallback } from "../../../../shared/useFetchMoreCallback";
import { ExpiringStaffQualificationChecksQuery, ExpiringStaffQualificationChecksQueryVariables } from "../../generated/graphql";
import { profileFieldsFragment } from "../../models/Profile";
import { staffQualificationCheckFieldsFragment } from "../../models/StaffQualificationCheck";


/**
 * Get all data required for the ExpiringQualificationsListViewModel component from the api.
 * @param options
 */

export function useExpiringQualificationsListViewModel(options: AsyncLoadPagedOptions = {}): AsyncLoadPagedResult<ExpiringStaffQualificationChecksQuery> {
    // Query data we need from the api
    const [load, { data, loading, error, refetch, fetchMore: fetchMoreFromStore }] = useLazyQuery<ExpiringStaffQualificationChecksQuery, ExpiringStaffQualificationChecksQueryVariables>(
        gql`
        query expiringStaffQualificationChecksQuery ($offset: Int, $first: Int) {
            items: staffQualificationChecks (offset: $offset, first: $first) {
                ...staffQualificationCheckFields
            }

            profiles (isStaff: true) {
                ...profileFields
            }
        }

        ${staffQualificationCheckFieldsFragment}
        ${profileFieldsFragment}
        `,
        {
            variables: {
                offset: options.startOffset,
                first: options.pageSize,
            },
            fetchPolicy: options.fetchPolicy ?? mainApiConfig.defaultFetchPolicy,
            pollInterval: options.pollInterval,
            onCompleted: (data) => {
                if (options.onCompleted) {
                    options.onCompleted(data);
                }
            }
        }
    );

    //Run the query (unless we're being lazy)
    React.useEffect(() => {
        if (!options.lazy) {
            load();
        }
    }, [options.lazy, load]);

    // Get the functions used to manage paging
    const { fetchMore, hasMore: _hasMore } = useFetchMoreCallback<ExpiringStaffQualificationChecksQuery>(
        fetchMoreFromStore,
        (options?.startOffset ?? 0) + (data?.items?.length ?? 0),
        (prevResults, newResults) => ({
            ...prevResults,
            ...newResults,

            items: [...(prevResults?.items ?? []), ...(newResults?.items ?? [])],
            profiles: newResults?.profiles ?? [],
        }),
        (newResults) => newResults?.items?.length
    );
    const hasMore = React.useCallback(() => !options.pageSize ? false : _hasMore(), [options.pageSize, _hasMore]);


    // Return the results
    const refresh = React.useCallback(async () => { !refetch ? await load() : await refetch(); }, [refetch, load]);
    return useMemo(() => ({
        data: data ?? ({} as any),
        refresh: refresh,
        isLoading: loading,
        errors: error,
        fetchMore: fetchMore,
        hasMore: hasMore,
    }), [data, refresh, loading, error, fetchMore, hasMore]);
};