import { useTranslation } from "react-i18next";
import { PaymentState } from "../../../api/main/models/constants/PaymentState";
import { PaymentsList } from "./PaymentsList";

/**
 * Outstanding payments.
 */
export const OutstandingPaymentsList = () => {
    const { t } = useTranslation();

    return (
        <PaymentsList
            title={t('outstandingPaymentsList.title', 'Outstanding payments')}
            filter={item => item.paymentState !== PaymentState.Paid}
        />
    );
};