import { gql, useLazyQuery } from "@apollo/client";
import React, { useMemo } from "react";
import { mainApiConfig } from "../../../../configure/mainApiConfig";
import { AsyncLoadOptions, AsyncLoadResult } from "../../../../shared/abstractStore";
import { profileFieldsFragment } from "../../models/Profile";
import { staffQualificationCheckFieldsFragment } from "../../models/StaffQualificationCheck";
import { ApproveStaffQualificationsListViewModelQuery, ApproveStaffQualificationsListViewModelQueryVariables } from "../../generated/graphql";

/**
 * Get all model data required for the ApproveQualificationsList component
 * @param acceptanceState
 * @param options
 */
export function useApproveStaffQualificationsListViewModel(acceptanceState: string | undefined | null, options: AsyncLoadOptions = {}): AsyncLoadResult<ApproveStaffQualificationsListViewModelQuery> {
    // Query the data we need from the api
    const [load, { data, loading, error, refetch, }] = useLazyQuery<ApproveStaffQualificationsListViewModelQuery, ApproveStaffQualificationsListViewModelQueryVariables>(
        gql`
        query approveStaffQualificationsListViewModelQuery ($acceptanceState: String!) {
            items: staffQualificationChecks (acceptanceState: $acceptanceState) {
                ...staffQualificationCheckFields
            }

            profiles (isStaff: true) {
                ...profileFields
            }
        }

        ${staffQualificationCheckFieldsFragment}
        ${profileFieldsFragment}
        `,
        {
            variables: {
                acceptanceState: acceptanceState ?? ""
            },
            fetchPolicy: options.fetchPolicy ?? mainApiConfig.defaultFetchPolicy,
            pollInterval: options.pollInterval,
            onCompleted: (data) => {
                if (options.onCompleted) {
                    options.onCompleted(data);
                }
            }
        }
    );

    //Run the query (unless we're being lazy)
    React.useEffect(() => {
        if (!options.lazy) {
            load();
        }
    }, [options.lazy, load]);

    // Return the results
    const refresh = React.useCallback(async () => { !refetch ? await load() : await refetch(); }, [refetch, load]);
    return useMemo(() => ({
        data: data ?? ({} as any),
        refresh: refresh,
        isLoading: loading,
        errors: error,
    }), [data, refresh, loading, error,]);
};