import { TFunction } from "i18next";


/**
 * Role groups
 */
export enum RoleGroup {
    User = "User",
    Staff = "Staff",
    Administrator = "Administrator",
}


/**
 * Returns all RoleGroup options, suitable for use in a list.
 */
export function getPaymentStates(): Array<RoleGroup> {
    const ret = Object.keys(RoleGroup).map(key => key as RoleGroup);
    return ret;
}

/**
 * Return all RoleGroup names.
 */
export function roleGroupDisplayNameFromString(name: RoleGroup | string, t: TFunction): string {
    switch (name) {
        case RoleGroup.User: return t('roleGroup.user.name', 'Member');
        case RoleGroup.Staff: return t('roleGroup.staff.name', 'Staff');
        case RoleGroup.Administrator: return t('roleGroup.administrator.name', 'Administrator');
        default: return t('roleGroup.user.name', 'Member');
    }
}
