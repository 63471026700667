import { useMutation } from "@apollo/react-hooks";
import { useAsyncCallback } from 'react-use-async-callback';
import gql from "graphql-tag";
import { AsyncActionStatus } from '../../../shared/abstractStore';
import { CreateScheduledClassChildMutation, CreateScheduledClassChildMutationVariables, ScheduledClassChildCreateInput } from '../generated/graphql';
import { scheduledClassChildFieldsFragment } from '../models/ScheduledClassChild';

/**
 * Get a callback to create a ScheduledClassChild in the api.
 */
export function useCreateScheduledClassChildMutation(): [(model: ScheduledClassChildCreateInput) => Promise<void>, AsyncActionStatus] {
    const [mutationAction, { error }] = useMutation<CreateScheduledClassChildMutation, CreateScheduledClassChildMutationVariables>(
        gql`
            mutation createScheduledClassChildMutation ($model: ScheduledClassChildCreateInput!) {
                createScheduledClassChild(model: $model) {
                    ...scheduledClassChildFields
                }
            }

            ${scheduledClassChildFieldsFragment}
        `
    );

    // Wrap the mutation in a standard callback.
    const [action, { isExecuting, errors: actionErrors }] = useAsyncCallback(async (model: ScheduledClassChildCreateInput) => {
        const result = await mutationAction({ variables: { model: model } });
        if (result.errors && result.errors.length) {
            throw result.errors;
        }
    }, [mutationAction]);

    return [action, { isExecuting: isExecuting, errors: error ? error.message : actionErrors }];
}
