import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Button, FormGroup, Label, ModalBody, ModalFooter, ModalHeader, Spinner } from "reactstrap";
import { ButtonAsync } from "reactstrap-buttonasync";
import { Child } from "../../../api/main/models/Child";
import { StaffNote } from "../../../api/main/models/StaffNote";
import { HtmlEditor } from "../../../shared/htmlEditor";
import { TwoValueSwitch } from "../../shared/twoValueSwitch/TwoValueSwitch";

export interface AddProgressNoteModalProps {
    model: StaffNote | null | undefined,
    toggle: () => void,

    change: (changes: Partial<StaffNote>) => void;
    save: () => Promise<void>,
    isSaving: boolean,
    child: Child | null | undefined,
    cancel: () => void;
}

/**
 * Modal used for adding a ProgressNote (StaffNote) from StaffClassesTab -> StaffClassItem -> Register
 */
export const AddProgressNoteModal = (props: AddProgressNoteModalProps) => {
    const {
        model,
        toggle,
        change,
        save,
        isSaving,
        child,
        cancel
    } = props;
    const { t } = useTranslation();

    // We want the default for showOnRegister to be true, so onload set to true.
    useEffect(() => {
        change({ showOnRegister: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []); // <-- Ignore the linting, this empty array is needed. If we remove it or add "change" it will cause an infinite loop.

    // Render the UI
    //
    return (
        <>
            <ModalHeader toggle={toggle}>
                <h2>{t('staffClassesTab.staffClassItem.registerComponent.addProgressNoteModal.addProgressNote', 'Add progress note')}</h2>
                <p>{t('common.fullName', '{{firstName}} {{lastName}}', { firstName: child?.firstName, lastName: child?.lastName })}</p>
            </ModalHeader>

            <ModalBody>
                <FormGroup>
                    <>
                        <Label htmlFor="contentsHtml">{t('editMember.staffNote.contentsHtml', 'Note')}</Label>
                        <HtmlEditor size="sm" value={model?.contentsHtml ?? ''} onChange={text => change({ contentsHtml: text })} />
                    </>
                </FormGroup>

                <FormGroup>
                    <Label htmlFor="showOnRegister">{t('common.showOnRegister', 'Show on register')}</Label>
                    <TwoValueSwitch leftLabel="" rightLabel="" checked={model?.showOnRegister ?? false} onChange={(value) => change({ showOnRegister: value })} />
                </FormGroup>
            </ModalBody>

            <ModalFooter>
                <ButtonAsync color="primary" isExecuting={isSaving} onClick={() => save()}
                    executingChildren={<><Spinner size="sm" /> {t('common.saving', 'Saving...')}</>}>
                    <FontAwesomeIcon icon="save" />
                    <> </>
                    {t('common.save', 'Save')}
                </ButtonAsync>

                <Button type="button" color="primary" outline onClick={e => cancel()}>
                    {t('common.cancel', 'Cancel')}
                </Button>
            </ModalFooter>
        </>
    );
};