const testPublishableKey = 'pk_test_rnfl7WRJHAgakpRWhSNxljIT'; // Test (Ambidect)
const productionPublishableKey = 'pk_live_cw3bBNxZlnRYvbn2aKpsBWmM'; // Happy Cubs production key.

export const stripeConfig = {
    /**
     * Returns the stripe Publishable key (starting "pk_") for use with the Stripe integration.
     * 
     * We'll automatically return the one most relevent to the environment based on the URL.
     */
    getPublishableKeyForEnvironment: () => {
        // If we are on the test or developer sites, return the test key.
        if (window.location.hostname === 'localhost' || window.location.hostname === 'happy-cubs-development.azurewebsites.net') {
            return testPublishableKey;
        }

        // Otherwise return the live key.
        return productionPublishableKey;
    }

};