import * as React from 'react';
import { useSaveInStore, SaveInStoreOptions } from '../../../shared/useSaveInStore';
import { useUpdateTeacherClassTypeMutation } from './useUpdateTeacherClassTypeMutation';
import { TeacherClassType } from '../models/TeacherClassType';
import { useCreateTeacherClassTypeMutation } from './useCreateTeacherClassTypeMutation';
import { TeacherClassTypeCreateInput, TeacherClassTypeUpdateInput } from '../generated/graphql';

/**
 * Get a callback to save a TeacherClassType in the api using either a create or update.
 */
export function useSaveTeacherClassTypeMutation(options: SaveInStoreOptions<TeacherClassType, string> = {}) {
    const [_create, createStatus] = useCreateTeacherClassTypeMutation();
    const create = React.useCallback((model: Partial<TeacherClassType>) => _create(model as TeacherClassTypeCreateInput), [_create]);

    const [_update, updateStatus] = useUpdateTeacherClassTypeMutation();
    const update = React.useCallback((id: string, changes: Partial<TeacherClassType>) => _update(id, changes as TeacherClassTypeUpdateInput), [_update]);

    return useSaveInStore(
        [create, createStatus],
        [update, updateStatus],
        options
    );
}
