/**
 * Filter array to contain only unique items.
 */
export function uniqueItemsArray<T>(array: Array<T>): Array<T> {
    let ret: Array<T> = [];

    for (const item of array) {
        const existingIndex = ret.findIndex(it => it === item); // NOTE we use findIndex rather than find() to cope with items that are undefined.
        if (existingIndex !== -1) {
            // Continue as the item is already in the array.
            continue;
        } else {
            // Add to the array as its the first time we've seen this item.
            ret.push(item);
        }
    }

    return ret;
}