import { gql, useLazyQuery } from "@apollo/client";
import React from "react";
import { mainApiConfig } from "../../../configure/mainApiConfig";
import { AsyncLoadOptions, AsyncLoadResult } from "../../../shared/abstractStore";
import { ConsumeLessonPlansListQuery, ConsumeLessonPlansListQueryVariables } from "../generated/graphql";
import { classStageFieldsFragment } from "../models/ClassStage";
import { classSubStageFieldsFragment } from "../models/ClassSubStage";
import { classTypeFieldsFragment } from "../models/ClassType";
import { lessonDocumentFieldsFragment } from "../models/LessonDocument";

/**
 * Get all data required for the LessonPlansList component from the api.
 * @param options
 */
export function useConsumeLessonPlansList(options: AsyncLoadOptions = {}): AsyncLoadResult<ConsumeLessonPlansListQuery> {
    // Query the data we need from the api
    const [load, { data, loading, error, refetch }] = useLazyQuery<ConsumeLessonPlansListQuery, ConsumeLessonPlansListQueryVariables>(
        gql`
        query consumeLessonPlansListQuery {
            lessonDocuments {
                ...lessonDocumentFields

                blobReference {
                    id
                    filename
                }
            }

            classTypes {
                ...classTypeFields
            }

            classStages {
                ...classStageFields
            }

            classSubStages {
                ...classSubStageFields
            }
        }

        ${lessonDocumentFieldsFragment}
        ${classTypeFieldsFragment}
        ${classStageFieldsFragment}
        ${classSubStageFieldsFragment}
        `,
        {

            fetchPolicy: options.fetchPolicy ?? mainApiConfig.defaultFetchPolicy,
            pollInterval: options.pollInterval,
            onCompleted: (data) => {
                if (options.onCompleted) {
                    options.onCompleted(data);
                }
            }
        }
    );

    // Run the query (unless we are being lazy).
    React.useEffect(() => {
        if (!options.lazy) {
            load();
        }
    }, [options.lazy, load]);

    // Return the results.
    const refresh = React.useCallback(async () => { !refetch ? await load() : await refetch(); }, [refetch, load]);
    return React.useMemo(() => ({
        data: data ?? ({} as any),
        refresh: refresh,
        isLoading: loading,
        errors: error,
    }), [data, refresh, loading, error,]);
}

