import { gql, useLazyQuery } from "@apollo/client";
import React from "react";
import { mainApiConfig } from "../../../../configure/mainApiConfig";
import { AsyncLoadOptions, AsyncLoadResult } from "../../../../shared/abstractStore";
import { ScheduledClassForClassRegistersViewModelQuery, ScheduledClassForClassRegistersViewModelQueryVariables } from '../../generated/graphql';
import { childFieldsFragment } from "../../models/Child";
import { classLocationFieldsFragment } from "../../models/ClassLocation";
import { classStageFieldsFragment } from "../../models/ClassStage";
import { classSubStageFieldsFragment } from "../../models/ClassSubStage";
import { classTypeFieldsFragment } from "../../models/ClassType";
import { scheduledClassFieldsFragment } from "../../models/ScheduledClass";
import { scheduledClassChildFieldsFragment } from "../../models/ScheduledClassChild";
import { termFieldsFragment } from "../../models/Term";
import { termHolidayFieldsFragment } from "../../models/TermHoliday";

/**
 * Get all the data required for the ClassRegistersList componet from the api
 */
export function useScheduledClassForClassRegistersViewModel(options: AsyncLoadOptions = {}): AsyncLoadResult<ScheduledClassForClassRegistersViewModelQuery> {
    const [load, { data, loading, error, refetch }] = useLazyQuery<ScheduledClassForClassRegistersViewModelQuery, ScheduledClassForClassRegistersViewModelQueryVariables>(
        gql`
        query scheduledClassForClassRegistersViewModelQuery {
            items: scheduledClasses {
                ...scheduledClassFields

                classLocation {
                    ...classLocationFields
                }

                scheduledClassChildren {
                ...scheduledClassChildFields

                    child {
                        ...childFields
                    }
            }
            }

            terms (restrictToTermsWithClasses: true) {
                ...termFields
            }

            termHolidays {
                ...termHolidayFields
            }

            classStages {
                ...classStageFields
            }

            classSubStages {
                ...classSubStageFields
            }

            classTypes {
                ...classTypeFields
            }
        }

        ${scheduledClassFieldsFragment}
        ${scheduledClassChildFieldsFragment}
        ${classLocationFieldsFragment}
        ${childFieldsFragment}
        ${termFieldsFragment}
        ${termHolidayFieldsFragment}
        ${classStageFieldsFragment}
        ${classSubStageFieldsFragment}
        ${classTypeFieldsFragment}
        `,
        {
            fetchPolicy: options.fetchPolicy ?? mainApiConfig.defaultFetchPolicy,
            pollInterval: options.pollInterval,
            onCompleted: (data) => {
                if (options.onCompleted) {
                    options.onCompleted(data);
                }
            }
        }
    );

    // Run the query (unless we are being lazy).
    React.useEffect(() => {
        if (!options.lazy) {
            load();
        }
    }, [options.lazy, load]);

    // Return the results.
    const refresh = React.useCallback(async () => { !refetch ? await load() : await refetch(); }, [refetch, load]);
    return React.useMemo(() => ({
        data: data ?? ({} as any),
        refresh: refresh,
        isLoading: loading,
        errors: error
    }), [data, refresh, loading, error]);
}