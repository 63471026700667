import { TFunction } from "i18next";


/**
 * State of a submission by a staff member that needs approval by admins, used by the AcceptanceState field of the StaffAbsence and StaffQualificationCheck models.
 */
export enum StaffSubmissionAcceptanceState {
    Submitted = "Submitted",
    Approved = "Approved",
    Rejected = "Rejected",
    SelfAccepted = "SelfAccepted",
}

/**
 * Returns all StaffSubmissionAcceptanceState options, suitable for use in a list.
 */
export function getStaffSubmissionAcceptanceStates(): Array<StaffSubmissionAcceptanceState> {
    const ret = Object.keys(StaffSubmissionAcceptanceState).map(key => key as StaffSubmissionAcceptanceState);
    return ret;
}

/**
 * Convert a payment state to its display name. 
 */
export function staffSubmissionAcceptanceStateDisplayName(name: StaffSubmissionAcceptanceState | string, t: TFunction): string {
    switch (name) {
        case StaffSubmissionAcceptanceState.Submitted: return t('staffSubmissionAcceptanceState.submitted.name', 'Submitted for review');
        case StaffSubmissionAcceptanceState.Approved: return t('staffSubmissionAcceptanceState.approved.name', 'Approved');
        case StaffSubmissionAcceptanceState.Rejected: return t('staffSubmissionAcceptanceState.rejected.name', 'Rejected');
        case StaffSubmissionAcceptanceState.SelfAccepted: return t('staffSubmissionAcceptanceState.selfAccepted.name', 'No review required');
        default: return t('staffSubmissionAcceptanceState.submitted.name', 'Submitted for review');
    }
}
