import gql from 'graphql-tag';
import { Guid } from 'guid-string';
import moment from 'moment';
import { MemberFieldsFragment } from '../generated/graphql';

export const memberFieldsFragment = gql`
    fragment memberFields on Member {
        id
        userId
        archived
        registeredDate
        photoBlobReferenceId
        optInToMarketing
        optInToMarketingUpdatedDate
        applyStaffDiscount
    }
`;

export type Member = Omit<MemberFieldsFragment, '__typename'>;

/**
 * Default values.
 */
export const memberDefaultValues = (): Partial<Member> => ({
    id: Guid.newGuid(),
    userId: '',
    archived: false,
    registeredDate: moment().toISOString(),
    photoBlobReferenceId: null,
    optInToMarketing: false,
    optInToMarketingUpdatedDate: moment().toISOString(),
    applyStaffDiscount: false,
});