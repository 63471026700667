import { useTranslation } from 'react-i18next';
import { Staff } from '../../../../api/main/models/Staff';
import { Form, Label, FormGroup} from 'reactstrap'; 
import { HtmlEditor } from '../../../../shared/htmlEditor';

export interface BiosTabProps {
    staffModel: Staff | null | undefined,
    changeStaffModel: (staffChanges: Partial<Staff>) => void,
    saveForm: () => Promise<void>,
    isCreate: boolean | null | undefined
}

/**
 * BioTab for EditStaff
 */
export const BiosTab = (props: BiosTabProps) => {
    const {
        staffModel,
        changeStaffModel,
        saveForm,
    } = props;
    const { t } = useTranslation();

    //Render the UI
    return (
        <Form onSubmit={e => { e.preventDefault(); saveForm(); }}>
            <FormGroup>
                <Label htmlFor="bioHtml">{t('editStaff.bioHtml', 'Bio')}</Label>
                <HtmlEditor size="xl" value={staffModel?.bioHtml ?? ''} onChange={text => changeStaffModel({ bioHtml: text })} />
            </FormGroup>
        </Form>
    );

};