import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useValidatorCallback } from "pojo-validator-react";
import { useCallback, useMemo } from "react";
import { ConditionalFragment } from "react-conditionalfragment";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { useAsyncCallback } from "react-use-async-callback";
import { Button, Col, FormGroup, Label, ListGroup, Row, Spinner } from "reactstrap";
import { ButtonAsync } from "reactstrap-buttonasync";
import { ScheduledClassStaff, scheduledClassStaffDefaultValues } from "../../../api/main/models/ScheduledClassStaff";
import { useDeleteScheduledClassStaffMutation } from "../../../api/main/scheduledClassStaffs/useDeleteScheduledClassStaff";
import { useSaveScheduledClassStaffMutation } from "../../../api/main/scheduledClassStaffs/useSaveScheduledClassStaffMutation";
import { useEditScheduledClassStaffToArrangeCover } from "../../../api/main/scheduledClassStaffs/viewModels/useEditScheduledClassStaffToArrangeCover";
import { AlertOnErrors } from "../../../shared/alertOnErrors";
import { useChangesArray } from "../../../shared/useChanges";
import { useValidatorArrayCallback } from "../../../shared/validator-react-contrib/useValidatorArrayCallback";
import { getScheduledClassSummary } from "../../scheduledClass/getScheduledClassSummary";
import { Banner } from "../../shared/banner/Banner";
import { FormButtons } from "../../shared/formButtons/FormButtons";
import { LoadingIndicator } from "../../shared/loadingIndicator/LoadingIndicator";
import { MainContainer } from "../../shared/mainContainer/MainContainer";
import { StickyToolbar } from "../../shared/stickyToolbar/StickyToolbar";
import { SubstituteTeacherComponent } from "./SubstituteTeacherComponent";

/**
 * Component to edit a ScheduledClassStaff for ArrangeCoverList
 */
export const EditScheduledClassStaffToArrangeCover = () => {
    const { t } = useTranslation();
    const { id } = useParams<{ id: string | undefined; }>();
    const navigate = useNavigate();

    // Load the data
    const {
        data: {
            items: storeScheduledClassStaffs,
            profiles,
            scheduledClass,
            classStages,
            classSubStages
        }, isLoading, errors: loadErrors
    } = useEditScheduledClassStaffToArrangeCover(id);

    // ClassStage
    const classStage = useMemo(() => {
        return classStages?.find(it => it.id === scheduledClass?.currentClassStageId);
    }, [classStages, scheduledClass]);

    // ClassSubStage
    const classSubStage = useMemo(() => {
        return classSubStages?.find(it => it.id === scheduledClass?.currentClassSubStageId);
    }, [classSubStages, scheduledClass]);

    // Filtered storeScheduledClassStaffs - Filtered to only show those with a substitute start and end date
    const filteredStoreScheduledClassStaffs = useMemo(() => {
        return storeScheduledClassStaffs?.filter(it => it.isSubsitute);
    }, [storeScheduledClassStaffs]);

    // Model (ScheduledClassStaff)
    const scheduledClassStaffsManager = useChangesArray<ScheduledClassStaff, string>(filteredStoreScheduledClassStaffs, item => item.id);
    const [saveScheduledClassStaff] = useSaveScheduledClassStaffMutation();
    const [removeScheduledClassStaff] = useDeleteScheduledClassStaffMutation();

    // Add a new ScheduledClassStaff
    const addScheduledClassStaff = useCallback(() => {
        scheduledClassStaffsManager.addFor({ ...scheduledClassStaffDefaultValues(), scheduledClassId: id, isSubsitute: true });
    }, [scheduledClassStaffsManager, id]);

    // Validation
    const [validateScheduledClassStaff] = useValidatorArrayCallback<ScheduledClassStaff>((myModel, validation, fieldsToCheck) => {
        const rules = {
            staffId: () => !myModel.staffId ? t('editScheduledClassStaffToArrangeCover.errors.staffId', 'Substitute teacher is required') : '',
            roleInClass: () => !myModel?.roleInClass ? t('editScheduledClassStaffToArrangeCover.errors.roleInClass', 'Role in class is required') : '',
            subsituteStartDate: () => !myModel?.subsituteStartDate ? t('editScheduledClassStaffToArrangeCover.errors.subsituteStartDate', 'Substitute start date is required') : '',
            subsituteEndDate: () => !myModel?.subsituteEndDate ? t('editScheduledClassStaffToArrangeCover.errors.subsituteEndDate', 'Substitute end date is required') : '',
        };

        validation.checkRules(rules, fieldsToCheck);
    }, []);
    const [validate, validationErrors] = useValidatorCallback((validation, fieldsToCheck) => {
        const rules = {
            validateScheduledClassStaff: () => scheduledClassStaffsManager.model.filter(it => !validateScheduledClassStaff(it)).length ? t('editScheduledClassStaffToArrangeCover.errors.scheduledClassStaff', 'One or more of the substitute teachers are invalid') : '',
        };
        validation.checkRules(rules, fieldsToCheck);
    }, [
        scheduledClassStaffsManager,
        validateScheduledClassStaff,
    ]);

    // Save the ScheduledClassStaffs
    const [saveForm, { isExecuting: isSaving, errors: saveFormErrors }] = useAsyncCallback(async () => {
        if (!validate()) {
            return;
        }

        // Save any Updated ScheduledClassStaff
        for (const item of scheduledClassStaffsManager.added) { await saveScheduledClassStaff(item.id, scheduledClassStaffsManager.changesFor(item.id), true); }
        for (const item of scheduledClassStaffsManager.updated) { await saveScheduledClassStaff(item.id, scheduledClassStaffsManager.changesFor(item.id), false); }
        for (const item of scheduledClassStaffsManager.removed) { await removeScheduledClassStaff(item.id); }

        // Go back to the previous screen
        navigate(-1);
    }, [validate, saveScheduledClassStaff, removeScheduledClassStaff, navigate, scheduledClassStaffsManager,]);

    // Render the UI
    //
    return (
        <>
            <Banner>
                <StickyToolbar>
                    <Row>
                        <Col>
                            <h1>

                                {t('editScheduledClassStaffToArrangeCover.title', 'Arrange cover')}
                            </h1>
                            <h5>{getScheduledClassSummary(scheduledClass, { classLocation: scheduledClass?.classLocation, classStage: classStage, classSubStage: classSubStage })}</h5>
                        </Col>
                        <ConditionalFragment showIf={isLoading}>
                            <Col xs="auto">
                                <LoadingIndicator size="sm" />
                            </Col>
                        </ConditionalFragment>
                    </Row>
                </StickyToolbar>
            </Banner>

            <MainContainer>

                <AlertOnErrors
                    errors={[
                        loadErrors,
                        saveFormErrors,
                    ]}
                />

                <FormGroup>
                    <Label htmlFor="editScheduledClassStaffToArrangeCover">
                        {t('editScheduledClassStaffToArrangeCover.headingLabel', 'Substitute teachers for this class:')}
                    </Label>

                    <ListGroup id="editScheduledClassStaffToArrangeCover">
                        {
                            scheduledClassStaffsManager.model.map(item => (
                                <SubstituteTeacherComponent key={item.id}
                                    model={item}
                                    change={changes => scheduledClassStaffsManager.changeFor(item.id, changes)}
                                    remove={() => scheduledClassStaffsManager.removeFor(item.id)}
                                    profiles={profiles}
                                    validate={() => validateScheduledClassStaff(item)}
                                    validationErrors={validationErrors}
                                />
                            ))
                        }
                    </ListGroup>

                    <div className="mt-2">
                        <Button color="primary" outline onClick={() => addScheduledClassStaff()}>
                            {t('editScheduledClassStaffToArrangeCover.add', 'Add substitute to class')}
                        </Button>
                    </div>
                </FormGroup>

                <FormButtons>
                    <ConditionalFragment showIf={!isLoading}>
                        <ButtonAsync color="primary" isExecuting={isSaving} onClick={() => saveForm()}
                            executingChildren={<><Spinner size="sm" /> {t('common.saving', 'Saving...')}</>}>
                            <FontAwesomeIcon icon="save" />
                            <> </>
                            {t('common.save', 'Save')}
                        </ButtonAsync>
                    </ConditionalFragment>

                    <Button type="button" color="primary" outline onClick={e => navigate(-1)}>
                        {t('common.cancel', 'Cancel')}
                    </Button>
                </FormButtons>
            </MainContainer>
        </>
    );
};