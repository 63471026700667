import { gql, useLazyQuery } from "@apollo/client";
import { ScheduledClassesForChildrenCourtesyClassCreditsTabViewModelQuery, ScheduledClassesForChildrenCourtesyClassCreditsTabViewModelQueryVariables } from '../../generated/graphql';
import { AsyncLoadOptions, AsyncLoadResult } from "../../../../shared/abstractStore";
import { scheduledClassFieldsFragment } from "../../models/ScheduledClass";
import { classLocationFieldsFragment } from "../../models/ClassLocation";
import { mainApiConfig } from "../../../../configure/mainApiConfig";
import React from "react";
import { classStageFieldsFragment } from "../../models/ClassStage";
import { classSubStageFieldsFragment } from "../../models/ClassSubStage";

export function useScheduledClassesForChildrenCourtesyClassCreditsTabViewModel(options: AsyncLoadOptions = {}): AsyncLoadResult<ScheduledClassesForChildrenCourtesyClassCreditsTabViewModelQuery> {
    // Query the data we need from the api
    const [load, { data, loading, error, refetch }] = useLazyQuery<ScheduledClassesForChildrenCourtesyClassCreditsTabViewModelQuery, ScheduledClassesForChildrenCourtesyClassCreditsTabViewModelQueryVariables>(
        gql`
         query scheduledClassesForChildrenCourtesyClassCreditsTabViewModelQuery {
             items: scheduledClasses {
                 ...scheduledClassFields
                 
                 classLocation {
                     ...classLocationFields
                 }
             }
              
             classStages {
                ...classStageFields
             }

             classSubStages {
                ...classSubStageFields
             }
         }

         ${scheduledClassFieldsFragment}
         ${classLocationFieldsFragment}
         ${classStageFieldsFragment}
         ${classSubStageFieldsFragment}
         `,
        {
            variables: {
            },
            fetchPolicy: options.fetchPolicy ?? mainApiConfig.defaultFetchPolicy,
            pollInterval: options.pollInterval,
            onCompleted: (data) => {
                if (options.onCompleted) {
                    options.onCompleted(data);
                }
            }
        }
    );

    // Run the query (unless we are being lazy).
    React.useEffect(() => {
        if (!options.lazy) {
            load();
        }
    }, [options.lazy, load]);

    // Return the results.
    const refresh = React.useCallback(async () => { !refetch ? await load() : await refetch(); }, [refetch, load]);
    return React.useMemo(() => ({
        data: data ?? ({} as any),
        refresh: refresh,
        isLoading: loading,
        errors: error,
    }), [data, refresh, loading, error]);
};