import * as React from 'react';
import { useSaveInStore, SaveInStoreOptions } from '../../../shared/useSaveInStore';
import { useUpdateClassSubStageMutation } from './useUpdateClassSubStageMutation';
import { ClassSubStage } from '../models/ClassSubStage';
import { useCreateClassSubStageMutation } from './useCreateClassSubStageMutation';
import { ClassSubStageCreateInput, ClassSubStageUpdateInput } from '../generated/graphql';

/**
 * Get a callback to save a ClassSubStage in the api using either a create or update.
 */
export function useSaveClassSubStageMutation(options: SaveInStoreOptions<ClassSubStage, string> = {}) {
    const [_create, createStatus] = useCreateClassSubStageMutation();
    const create = React.useCallback((model: Partial<ClassSubStage>) => _create(model as ClassSubStageCreateInput), [_create]);

    const [_update, updateStatus] = useUpdateClassSubStageMutation();
    const update = React.useCallback((id: string, changes: Partial<ClassSubStage>) => _update(id, changes as ClassSubStageUpdateInput), [_update]);

    return useSaveInStore(
        [create, createStatus],
        [update, updateStatus],
        options
    );
}
