import { Form, Label, FormGroup, Col, Row } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { ValidateCallback } from 'pojo-validator-react';
import { ValidatedInput } from 'pojo-validator-reactstrap';
import { Term } from '../../../api/main/models/Term';
import { ValidatedISODateTimeInput } from '../../shared/isoDateTimeInput/ValidatedISODateTimeInput';
import { ValidationErrors } from 'pojo-validator';
import { TwoValueSwitch } from '../../shared/twoValueSwitch/TwoValueSwitch';

export interface MainTabProps {
    model: Term | null | undefined,
    change: (changes: Partial<Term>) => void,
    validate: ValidateCallback,
    validationErrors: ValidationErrors,
    saveForm: () => Promise<void>,
}

/**
 * MainTab for EditTerm.
 */
export const MainTab = (props: MainTabProps) => {
    const {
        model,
        change,
        validate,
        validationErrors,
        saveForm,
    } = props;
    const { t } = useTranslation();

    // Render the UI
    //
    return (
        <>
            <Form onSubmit={e => { e.preventDefault(); saveForm(); }}>
                <Row>
                    <Col>
                        <FormGroup>
                            <Label htmlFor="name">{t('editTerm.mainTab.name.label', 'Name')}</Label>
                            <ValidatedInput name="name" type="text" value={model?.name ?? ''} onChange={e => change({ name: e.currentTarget.value })} onBlur={e => validate('name')} validationErrors={validationErrors['name']} />
                        </FormGroup>
                    </Col>
                    <Col>
                        <Label htmlFor="canSelfRegister">{t('editTerm.mainTab.canSelfRegister.label', 'Registration open')}</Label>
                        <TwoValueSwitch leftLabel="No" rightLabel="Yes" checked={model?.canSelfRegister ?? true} onChange={(value) => change({ canSelfRegister: value })} />
                    </Col>
                </Row>
                <FormGroup>
                    <Label htmlFor="startDate">{t('editTerm.mainTab.startDate.label', 'Start date')}</Label>
                    <ValidatedISODateTimeInput name="startDate" type="date" value={model?.startDate ?? ''} onChange={e => change({ startDate: e.currentTarget.value })} onBlur={e => validate('startDate')} validationErrors={validationErrors['startDate']} />
                </FormGroup>
                <FormGroup>
                    <Label htmlFor="endDate">{t('editTerm.mainTab.endDate.label', 'End date')}</Label>
                    <ValidatedISODateTimeInput name="endDate" type="date" value={model?.endDate ?? ''} onChange={e => change({ endDate: e.currentTarget.value })} onBlur={e => validate('endDate')} validationErrors={validationErrors['endDate']} />
                </FormGroup>
            </Form>
        </>
    );
}
