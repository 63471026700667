import { ValidationErrors } from "pojo-validator";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Button, FormGroup, Label, ListGroup } from "reactstrap";
import { Staff } from "../../../../api/main/models/Staff";
import { EmergencyContact, emergencyContactDefaultValues } from "../../../../api/main/models/EmergencyContact";
import { ModelArrayChanges } from "../../../../shared/useChanges";
import { EmergencyContactComponent } from "./EmergencyContactsComponent";
export interface EmergencyContactsTabProps {
    model: Staff | null | undefined,

    staffEmergencyContactsManager: ModelArrayChanges<EmergencyContact, string>,
    validateStaffEmergencyContact: (model: EmergencyContact) => boolean,
    staffEmergencyContactValidationErrors: (id: string) => ValidationErrors,
}

/**
 * EmergencyContacts for Staffs
 */
export const EmergencyContactsTab = (props: EmergencyContactsTabProps) => {
    const { model, staffEmergencyContactsManager, validateStaffEmergencyContact, staffEmergencyContactValidationErrors } = props;
    const { t } = useTranslation();

    // Add a new EmergencyContact and link it to the parent model
    const addStaffEmergencyContact = useCallback(() => {
        staffEmergencyContactsManager.addFor({ ...emergencyContactDefaultValues(), targetId: model?.id, targetType: 'Staff' });
    }, [staffEmergencyContactsManager, model]);

    // Render the UI
    //
    return (
        <>
            <FormGroup>
                <Label htmlFor="staffEmergencyContacts">
                    {t('editStaff.staffEmergencyContactsTab.headingLabel', 'Emergency contacts:')}
                </Label>

                <ListGroup id="staffEmergencyContacts">
                    {
                        staffEmergencyContactsManager.model.map(item => (
                            <EmergencyContactComponent key={item.id}
                                model={item}
                                change={changes => staffEmergencyContactsManager.changeFor(item.id, changes)}
                                remove={() => staffEmergencyContactsManager.removeFor(item.id)}
                                validate={() => validateStaffEmergencyContact(item)}
                                validationErrors={staffEmergencyContactValidationErrors(item.id)}
                            />
                        ))
                    }
                </ListGroup>

                <div className="mt-2">
                    <Button color="primary" outline onClick={() => addStaffEmergencyContact()}>
                        {t('editStaff.staffEmergencyContactsTab.add', 'Add emergency contact')}
                    </Button>
                </div>
            </FormGroup>
        </>
    );
};