import { IdentityRoles } from "../../configure/security/IdentityRoles";
import { RouteEntry } from "../../shared/routes";
import { ClassNavigator } from "./ClassNavigator";

/**
 * Routes that should be added to configuration/routeConfig.ts to provide access to these components via routing.
 */
export const classNavigatorAdministrationRoutes: Array<RouteEntry> = [
    { path: '/administration/class-management/class-navigator', element: <ClassNavigator />, authorize: true, requireRole: IdentityRoles.Administration, },
];
