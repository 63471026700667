import { useMemo, useState } from "react";
import { ConditionalFragment } from "react-conditionalfragment";
import { useTranslation } from "react-i18next";
import { useAsyncCallback } from "react-use-async-callback";
import { Button, Col, ListGroup, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import { dayOfWeekDisplayName } from "../../../api/main/models/constants/DayOfWeek";
import { useMoveScheduledClassChildMutation } from "../../../api/main/scheduledClassChildren/useMoveScheduledClassChildMutation";
import { useScheduledClassChild } from "../../../api/main/scheduledClassChildren/useScheduledClassChild";
import { useScheduledClassForClassRegistersViewModel } from "../../../api/main/scheduledClasses/viewModels/useScheduledClassesForClassRegistersViewModel";
import { AlertOnErrors } from "../../../shared/alertOnErrors";
import { getScheduledClassSummary } from "../../scheduledClass/getScheduledClassSummary";
import { LoadingIndicator } from "../loadingIndicator/LoadingIndicator";
import { SearchInput } from "../searchInput/SearchInput";
import { MoveChildModalItem } from "./MoveChildModalItem";

export interface MoveChildModalResultMessages {
    errorMessage: string | undefined | null, warningMessage: string | undefined | null, successMessage: string | undefined | null;
}

export interface MoveChildModalProps {
    toggle: () => void,
    scheduledClassChildId: string | undefined,
    refreshChildrenList: () => Promise<void>,
    setResultMessages: (messages: MoveChildModalResultMessages) => void,
}

/*
* ComponentModal for Moving a Child around registers
*/
export const MoveChildModal = (props: MoveChildModalProps) => {
    const {
        toggle,
        scheduledClassChildId,
        refreshChildrenList,
        setResultMessages,
    } = props;
    const { t } = useTranslation();

    // Load the data
    const {
        data: {
            model: modelFrom,
        }, isLoading: _isLoading, errors: loadErrors
    } = useScheduledClassChild(scheduledClassChildId);

    // Load the supporting data
    const {
        data: {
            items: _items,
            classStages,
            classSubStages,
            classTypes,
            terms
        }, isLoading: isLoadingSupportingData, errors: supportingDataLoadErrors
    } = useScheduledClassForClassRegistersViewModel();
    const isLoading = _isLoading || isLoadingSupportingData;

    // Move handler
    const [moveScheduledClassChild, { errors: moveScheduledClassChildErrors }] = useMoveScheduledClassChildMutation();

    // ScheduledClass we are moving from
    const scheduledClassMovingFrom = useMemo(() => {
        return _items?.find(it => it.id === modelFrom?.scheduledClassId);
    }, [_items, modelFrom]);

    // Handle moving the child between ScheduledClasses
    const [moveChild, { errors: moveChildErrors, }] = useAsyncCallback(async (scheduledClassId: string, options: { endOfTerm: boolean, }) => {
        if (!scheduledClassChildId || !modelFrom) {
            return;
        }

        // Save the ScheduledClassChild and set the response alert
        const response = await moveScheduledClassChild(scheduledClassChildId, scheduledClassId, options.endOfTerm);

        const scheduledClass = _items?.find(it => it.id === scheduledClassId);

        setResultMessages({
            warningMessage: response.warningMessage,
            errorMessage: response.errorMessage,
            successMessage: response.success ?
                t('moveChildModal.success.message', 'Child has been moved to {{dayOfWeek}} {{startTimeHours, 00}}:{{startTimeMinutes, 00}} {{locationName}}', { dayOfWeek: dayOfWeekDisplayName(scheduledClass?.dayOfWeek ?? 0, t), startTimeHours: scheduledClass?.startTimeHours, startTimeMinutes: scheduledClass?.startTimeMinutes, locationName: scheduledClass?.classLocation?.name, })
                : undefined,
        });

        // Refresh the list
        refreshChildrenList();

        // Close the modal
        toggle();
    }, [scheduledClassChildId, modelFrom, moveScheduledClassChild, setResultMessages, refreshChildrenList, toggle, _items]);

    // Search Filter
    const [search, setSearch] = useState<string>('');

    // Filter the list
    const items = useMemo(() => {
        if (!_items) {
            return _items;
        }
        let ret = (_items ?? []);

        const lowerSearch = search.toLocaleLowerCase();
        if (lowerSearch) {
            ret = ret.filter(item =>
                getScheduledClassSummary(item, { classLocation: item.classLocation }).toLowerCase().indexOf(lowerSearch) >= 0
            );
        }

        ret = ret.filter(it => it.termId === scheduledClassMovingFrom?.termId);

        return ret;
    }, [_items, search, scheduledClassMovingFrom]);

    // Render the UI
    //
    return (
        <>
            <ModalHeader toggle={toggle}>
                <Row>
                    <Col>
                        <h3>
                            {t('moveChildModal.title', 'Moving cub from')}
                            <> </>
                            <small>
                                {getScheduledClassSummary(scheduledClassMovingFrom, { classLocation: scheduledClassMovingFrom?.classLocation, classStage: classStages?.find(it => it.id === scheduledClassMovingFrom?.currentClassStageId), classSubStage: classSubStages?.find(it => it.id === scheduledClassMovingFrom?.currentClassSubStageId) })} [{terms?.find(it => it.id === scheduledClassMovingFrom?.termId)?.name ?? ''}]
                            </small>
                        </h3>
                    </Col>
                    <ConditionalFragment showIf={isLoading}>
                        <Col xs="auto">
                            <LoadingIndicator />
                        </Col>
                    </ConditionalFragment>
                </Row>
            </ModalHeader>

            <AlertOnErrors
                errors={[
                    loadErrors,
                    supportingDataLoadErrors,
                    moveChildErrors, moveScheduledClassChildErrors,
                ]}
            />

            <ModalBody>
                <p className="text-muted" style={{ fontSize: '0.9rem' }}>
                    {t('moveChildModal.whenHintText.p1', 'Moving a cub to another class in the same term can be completed immediately. Moves within a term will transfer any existing payment for the child to their new class.')}
                    <br />
                    {t('moveChildModal.whenHintText.p2', 'Moving a cub to another class in a different term can be completed immediately or at the end of the current term. Moves for unpaid children or for next term will send the Mama / Papa bear a payment link to pay for the new class.')}
                </p>

                <div className="mb-1">
                    <Row>
                        <Col>
                            <SearchInput
                                placeholder={t('moveChildModal.searchPlaceholder', 'Search for a new class for the cub')}
                                value={search} onChange={e => setSearch(e.currentTarget.value)}
                            />
                        </Col>
                    </Row>
                </div>

                <ListGroup>
                    {/* Here we are filtering out any classes that don't have any free spaces, as requested by Paula on 02/09/2024 */}
                    {items?.filter(it => it.maximumClassSize - it.scheduledClassChildren.filter(x => !x.isOnWaitingList).length > 0).map(item => {
                        const isCurrentClass = (item.id === scheduledClassMovingFrom?.id);
                        const classStage = classStages?.find(it => it.id === item.currentClassStageId);
                        const classSubStage = classSubStages?.find(it => it.id === item.currentClassSubStageId);

                        // Don't show an entry for the current class we are moving from.
                        if (isCurrentClass) {
                            return null;
                        }

                        return (
                            <MoveChildModalItem key={item.id}
                                model={item}
                                moveChild={moveChild}
                                classStage={classStage}
                                classSubStage={classSubStage}
                                classTypes={classTypes}
                            />
                        );
                    })}
                </ListGroup>
            </ModalBody>

            <ModalFooter>
                <Button color="primary" outline onClick={toggle}>
                    {t('common.close', 'Close')}
                </Button>
            </ModalFooter>
        </>
    );
};