import { IdentityRoles } from "../../../configure/security/IdentityRoles";
import { RouteEntry } from "../../../shared/routes";
import { ClassSchedule } from "./ClassSchedule";


/**
 * Routes that should be added to configuration/routeConfig.ts to provide access to these components via routing.
 */
export const scheduledClassesClassScheduleRoutes: Array<RouteEntry> = [
    { path: '/administration/class-management/schedule', element: <ClassSchedule />, authorize: true, requireRole: IdentityRoles.Administration, },
];
