import { ValidationErrors } from "pojo-validator";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Button, FormGroup, Label, ListGroup } from "reactstrap";
import { Child } from "../../../../api/main/models/Child";
import { ChildDiscount, childDiscountDefaultValues } from "../../../../api/main/models/ChildDiscount";
import { ModelArrayChanges } from "../../../../shared/useChanges";
import { DiscountComponent } from "./DiscountComponent";

export interface DiscountsTabProps {
    model: Child | null | undefined,

    childChildDiscountsManager: ModelArrayChanges<ChildDiscount, string>,
    validateChildChildDiscount: (model: ChildDiscount) => boolean,
    childChildDiscountValidationErrors: (id: string) => ValidationErrors,
}

/**
 * ChildDiscounts for Children
 */
export const DiscountsTab = (props: DiscountsTabProps) => {
    const { model, childChildDiscountsManager, validateChildChildDiscount, childChildDiscountValidationErrors } = props;
    const { t } = useTranslation();

    // Add a new ChildDiscount and link it to the parent model
    const addChildDiscount = useCallback(() => {
        childChildDiscountsManager.addFor({ ...childDiscountDefaultValues(), childId: model?.id })
    }, [childChildDiscountsManager, model]);

    // Render the UI
    //
    return (
        <>
            <FormGroup>
                <Label htmlFor="childChildDiscounts">
                    {t('editChild.childChildDiscountsTab.headingLabel', 'Discounts for this cub:')}
                </Label>

                <ListGroup id="childChildDiscounts">
                    {
                        childChildDiscountsManager.model.map(item => (
                            <DiscountComponent key={item.id}
                                model={item}
                                change={changes => childChildDiscountsManager.changeFor(item.id, changes)}
                                remove={() => childChildDiscountsManager.removeFor(item.id)}
                                validate={() => validateChildChildDiscount(item)}
                                validationErrors={childChildDiscountValidationErrors(item.id)}
                            />
                        ))
                    }
                </ListGroup>

                <div className="mt-2">
                    <Button color="primary" outline onClick={() => addChildDiscount()}>
                        {t('editChild.childChildDiscountsTab.add', 'Add special discount')}
                    </Button>
                </div>
            </FormGroup>
        </>
    );
}