import { useMutation } from "@apollo/react-hooks";
import { useAsyncCallback } from 'react-use-async-callback';
import gql from "graphql-tag";
import { AsyncActionStatus } from '../../../shared/abstractStore';
import { ApplyGiftVoucherMutation, ApplyGiftVoucherMutationVariables } from '../generated/graphql';

/**
 * Get a callback to apply a GiftVoucher to a payment in the api.
 */
export function useApplyGiftVoucher(): [(paymentId: string, serialNumber: string) => Promise<{ success: boolean, message: string, }>, AsyncActionStatus] {
    const [mutationAction, { error }] = useMutation<ApplyGiftVoucherMutation, ApplyGiftVoucherMutationVariables>(
        gql`
            mutation applyGiftVoucherMutation ($paymentId: ID!, $serialNumber: String!) {
                applyGiftVoucher(paymentId: $paymentId, serialNumber: $serialNumber) {
                    success
                    message
                }
            }
        `
    );

    // Wrap the mutation in a standard callback.
    const [action, { isExecuting, errors: actionErrors }] = useAsyncCallback(async (paymentId: string, serialNumber: string) => {
        const result = await mutationAction({ variables: { paymentId: paymentId, serialNumber: serialNumber } });
        if (result.errors && result.errors.length) {
            throw result.errors;
        }

        return result.data?.applyGiftVoucher ?? { success: false, message: 'Voucher could not be applied.', };
    }, [mutationAction]);

    return [action, { isExecuting: isExecuting, errors: error ? error.message : actionErrors }];
}
