import { TFunction } from "i18next";


/**
 * Day of the week.  Theses values have been assigned to match the .NET DateOfWeek enum.
 */
export enum DayOfWeek {
    Sunday = 0,
    Monday = 1,
    Tuesday = 2,
    Wednesday = 3,
    Thursday = 4,
    Friday = 5,
    Saturday = 6
}

/**
 * Returns all DayOfWeek options, suitable for use in a list.
 */
export function getDayOfWeeks(): Array<DayOfWeek> {
    const ret = [0, 1, 2, 3, 4, 5, 6].map(value => value as DayOfWeek);
    return ret;
}

/**
 * Convert a DayOfWeek to its display name. 
 */
export function dayOfWeekDisplayName(name: DayOfWeek | number, t: TFunction): string {
    switch (name) {
        case DayOfWeek.Sunday: return t('dayOfWeek.sunday.name', 'Sunday');
        case DayOfWeek.Monday: return t('dayOfWeek.monday.name', 'Monday');
        case DayOfWeek.Tuesday: return t('dayOfWeek.tuesday.name', 'Tuesday');
        case DayOfWeek.Wednesday: return t('dayOfWeek.wednesday.name', 'Wednesday');
        case DayOfWeek.Thursday: return t('dayOfWeek.thursday.name', 'Thursday');
        case DayOfWeek.Friday: return t('dayOfWeek.friday.name', 'Friday');
        case DayOfWeek.Saturday: return t('dayOfWeek.saturday.name', 'Saturday');

        default: return t('dayOfWeek.invalid.name', 'Invalid day');
    }
}
