import { Fragment, useMemo, useState } from "react";
import { ConditionalFragment } from "react-conditionalfragment";
import { useTranslation } from "react-i18next";
import { Button, ButtonGroup, Col, Row } from "reactstrap";
import { useStagesInformationStaticViewModel } from "../../api/main/stagesInformationStatic/viewModels/useStagesInformationStaticViewModel";
import { AlertOnErrors } from "../../shared/alertOnErrors";
import { useReplaceSearchParamsEffect, useSearchParams } from "../../shared/useURLSearchParams";
import { Banner } from "../shared/banner/Banner";
import { HorizontalRule } from "../shared/horizontalRule/HorizontalRule";
import { LoadingIndicator } from "../shared/loadingIndicator/LoadingIndicator";
import { MainContainer } from "../shared/mainContainer/MainContainer";
import { StickyToolbar } from "../shared/stickyToolbar/StickyToolbar";
import "./stagesInformationStatic.scss";

export const StagesInformationStatic = () => {
    const { t } = useTranslation();

    const {
        data: {
            classTypes,
        }, isLoading, errors: loadErrors 
    } = useStagesInformationStaticViewModel();

    // Manage the tab, filter, and subStage being displayed
    const {
        classType: classTypeParam,
    } = useSearchParams();

    // Filters: ClassStages
    const [_filterClassTypes, setFilterClassTypes] = useState<string>(classTypeParam ?? '');
    const filterClassTypes = useMemo(() => {
        if (!classTypes) return

        return classTypes?.find(it => it.id.toLocaleLowerCase() === _filterClassTypes.toLocaleLowerCase())?.id
    }, [classTypes, _filterClassTypes]);

    // Keep the URL up to date with the currentTab
    useReplaceSearchParamsEffect({ classType: _filterClassTypes ?? '' });

    // Render the UI
    //
    return (
        <>
            <Banner>
                <StickyToolbar>
                    <Row>
                        <Col>
                            <h1>
                                {t('stagesInformationStatic.title', 'Stages')}
                            </h1>
                        </Col>
                        <Col xs="auto">
                            <ButtonGroup>
                                {
                                    classTypes?.map(classType => (
                                        <Button key={classType.id} color="primary" outline={filterClassTypes !== classType.id} onClick={() => setFilterClassTypes(classType.id)}>
                                            {classType.name}
                                        </Button>
                                    ))
                                }
                            </ButtonGroup>
                        </Col>
                        <ConditionalFragment showIf={isLoading}>
                            <Col xs="auto">
                                <LoadingIndicator />
                            </Col>
                        </ConditionalFragment>
                    </Row>
                </StickyToolbar>
            </Banner>

            <MainContainer>
                <AlertOnErrors error={loadErrors} />

                <div className="stages-information-static">
                    {
                        filterClassTypes === classTypes?.find(it => it.name === 'Swim Cubs')?.id ? (
                            <>
                                <Row className="stages-information-static-header">
                                    <h2 className="stages-information-static-header-text">{t('stagesInformationStatic.swimCubsStages', 'Swim Cubs Stages')}</h2>

                                    <strong className="stages-information-static-header-subtext">{t('stagesInformationStatic.swimCubsInfo', 'Find out below which stage is best for your bear cub. If in doubt, ')} <a className="stages-information-static-header-subtext-link" href="mailto:hello@happycubs.ie">get in touch with us.</a></strong>
                                    {t('stagesInformationStatic.swimCubsBio', 'At Swim Cubs we have classes to suit babies, toddlers and pre-schoolers from 2 months to 4.5 years. Our stages are expertly designed by age and ability and take into account varying developmental stages within the age groups. Classes are loads of fun incorporating songs and toys to teach swimming techniques and potentially lifesaving water safety skills. Most importantly, our classes have been developed with a child -led, gentle approach, working at your bear cub\'s individual pace.')}
                                </Row>

                                <Row className="stages-information-static-section">
                                    <Col xs={12} md={6} className="stages-information-static-section-image stages-information-static-section-swim-baby-cubs">
                                    </Col>

                                    <Col xs={12} md={6}>
                                        <Row className="stages-information-static-section-header">
                                            <h5 className="stages-information-static-section-header-text">{t('common.babyCubsColon.name', 'Baby Cubs:')}</h5>
                                            <h6 className="stages-information-static-section-header-subtext">{t('common.babyCubs.age', '2 months to 17 months')}</h6>
                                        </Row>

                                        <Row className="stages-information-static-section-subheader">
                                            <h6 className="stages-information-static-section-subheader-text">{t('common.stages.1to5', 'Stage 1 to Stage 5')}</h6>
                                        </Row>

                                        <Row className="stages-information-static-section-text">
                                            <p>{t('common.swim.babyCubs.paragraph1', 'Baby Cubs classes are suitable for babies from 2 months (in our hydrotherapy pools in Bray & Sandymount) and 3 months in all of our other pools. We recommend popping a baby wet suit on smaller babies to keep them nice and cosy. You can buy one here and collect it at your first class.')}</p>
                                            <p>{t('common.swim.babyCubs.paragraph2', 'Baby Cubs Stage 1 classes are suitable for beginners who have never attended a swimming class before. Classes are calm and relaxed to aid bonding with your bear cub in the water. In this stage we introduce your baby to the wonderful world of water. Your baby will learn their first swimming positions, how to float and basic water safety skills. You will learn how to hold and care for your baby in the water with the help of our expert teachers.')}</p>
                                            <p>{t('common.swim.babyCubs.paragraph3', 'If your bear cub is over 4 months old and a beginner, you can also choose to join Baby Cubs Stage 2. When your baby cub is that little bit more developed, toys are added to the classes to progress their swimming skills.')}</p>
                                            <p>{t('common.swim.babyCubs.paragraph4', 'As your bear cub progresses through the five stages of Baby Cubs, we will gently develop your baby\'s paddling, kicking, breathing and floating techniques.More advanced underwater swims will be added at your bear cub’s pace. Your bear cub will progress and learn new lifesaving skills. The emphasis is still on learning through play to build your bear cub\'s water confidence while having lots of fun.')}</p>
                                            <p>{t('common.swim.babyCubs.paragraph5', 'If you and your bear cub have attended classes with other swim schools, you can join from Baby Cubs 2 to 5, depending on their ability.')}</p>
                                        </Row>
                                    </Col>
                                </Row>


                                <div style={{ margin: '3rem 0' }}>
                                    <HorizontalRule />
                                </div>

                                <Row className="stages-information-static-section">
                                    <Col xs={12} md={6}>
                                        <Row className="stages-information-static-section-header">
                                            <h5 className="stages-information-static-section-header-text">{t('common.swim.toddlerCubs.name', 'Toddler Cubs')}</h5>
                                            <h6 className="stages-information-static-section-header-subtext">{t('common.swim.toddlerCubs.age', '18 months to 3.5 years')}</h6>
                                        </Row>

                                        <Row className="stages-information-static-section-subheader">
                                            <h6 className="stages-information-static-section-subheader-text">{t('common.stages.1to5', 'Stage 1 to Stage 5')}</h6>
                                        </Row>

                                        <Row className="stages-information-static-section-text">
                                            <p>{t('common.swim.toddlerCubs.paragraph1', 'Toddler Cubs classes are suitable for bear cubs aged from 1.5 years to 3.5 years. Our stages are tailored to suit absolute beginners who have never attended swim classes before and also water confident toddlers. Toddler Cubs classes are energetic and loads of fun. Your bear cub will progress through the stages developing their swimming skills through repetition and song.')}</p>
                                            <p>{t('common.swim.toddlerCubs.paragraph2', 'Toddler Cubs Stage 1 is a beginner’s class for toddlers aged between 1.5 and 3.5 years of age who have never done a swimming lesson before. Your toddler will learn basic swimming skills and water safety to build their confidence in the water through repetition. We do this in an energetic fun way using lots of swim toys and songs to keep your toddler engaged and enjoying every second.')}</p>
                                            <p>{t('common.swim.toddlerCubs.paragraph3', 'If your toddler is happy in the water, you can also choose to join our Toddler Cubs Stage 2 classes. If you and your bear cub have attended classes with other swim schools, you can join from Toddler Cubs 2 to 5, depending on their ability.')}</p>
                                            <p>{t('common.swim.toddlerCubs.paragraph4', 'As your bear cub progresses through the five stages of Baby Cubs, we will gently develop your baby\'s paddling, kicking, breathing and floating techniques.More advanced underwater swims will be added at your bear cub’s pace. Your bear cub will progress and learn new lifesaving skills. The emphasis is still on learning through play to build your bear cub\'s water confidence while having lots of fun.')}</p>
                                            <p>{t('common.swim.toddlerCubs.paragraph5', 'If you and your bear cub have attended classes with other swim schools, you can join from Baby Cubs 2 to 5, depending on their ability.')}</p>
                                        </Row>
                                    </Col>

                                    <Col xs={12} md={6} className="stages-information-static-section-image stages-information-static-section-swim-toddler-cubs">
                                    </Col>
                                </Row>

                                <div style={{ margin: '3rem 0' }}>
                                    <HorizontalRule />
                                </div>

                                <Row className="stages-information-static-section">
                                    <Col xs={12} md={6} className="stages-information-static-section-image stages-information-static-section-swim-big-cubs">
                                    </Col>

                                    <Col xs={12} md={6}>
                                        <Row className="stages-information-static-section-header">
                                            <h5 className="stages-information-static-section-header-text">{t('common.swim.bigCubs.name', 'Big Cubs')}</h5>
                                            <h6 className="stages-information-static-section-header-subtext">{t('common.swim.bigCubs.age', '3.5 years to 4.5 years')}</h6>
                                        </Row>

                                        <Row className="stages-information-static-section-text">
                                            <p>{t('common.swim.bigCubs.paragraph1', 'This stage is suitable for bear cubs aged between 3.5 years and 4.5 years who have never attended a structured swimming lesson before.  Big Cubs classes are especially great for bear cubs who are a little apprehensive about swimming and need the support of their mama or papa bear in the water. Classes are kept small, to a maximum of 10 parent/child pairs.  The emphasis is on building your bear cubs water confidence in a supportive, caring, gentle and fun way.  The aim is to progress your bear cub to swim short distances independently with aids to build on their water and lifesaving skills. All this will prepare them for swimming lessons without their mama or papa bear in the water with them.')}</p>

                                            <p style={{ paddingTop: '4rem', paddingBottom: '4rem', fontWeight: 'bold' }}>{t('common.swim.bigCubs.paragraph2', 'If you don\'t see a stage that seems to fit your little bear cub, give us a shout.  We\'ll try to make sure everyone can get involved.')}</p>
                                        </Row>
                                    </Col>
                                </Row>
                            </>
                        ) : (
                            <>
                                <Row className="stages-information-static-header">
                                    <h2 className="stages-information-static-header-text">{t('stagesInformationStatic.musicCubsStages', 'Music Cubs Stages')}</h2>
                                    <strong className="stages-information-static-header-subtext">{t('stagesInformationStatic.swimCubsInfo', 'Find out below which stage is best for your bear cub. If in doubt, ')} <a className="stages-information-static-header-subtext-link" href="mailto:hello@happycubs.ie">get in touch with us.</a></strong>
                                    {t('stagesInformationStatic.musicCubsBio', 'At Music Cubs we have classes to suit babies, toddlers and big kids from birth to 5 years. Our stages are expertly designed by age and ability and take into account varying developmental stages within the age groups. Our classes have been developed with a child-led, gentle approach, working at your bear cub\'s individual pace.')}
                                </Row>

                                <Row className="stages-information-static-section">
                                    <Col xs={12} md={6} className="stages-information-static-section-image stages-information-static-section-music-baby-cubs">
                                    </Col>

                                    <Col xs={12} md={6}>
                                        <Row className="stages-information-static-section-header">
                                            <h5 className="stages-information-static-section-header-text">{t('common.music.babyCubs.name', 'Baby Cubs')}</h5>
                                            <h6 className="stages-information-static-section-header-subtext">{t('common.music.babyCubs.age', 'Birth to 17 months')}</h6>
                                        </Row>

                                        <Row className="stages-information-static-section-text">
                                            <p>{t('common.music.babyCubs.paragraph1', 'Introduce your baby to the wonderful world of music.  Classes are calm, gentle and fun. Special family bonding time together every week.')}</p>
                                            <ul style={{ marginLeft: '2rem' }}>
                                                <li>{t('common.music.babyCubs.paragraph2', 'Learn songs to sing & hum to your baby to help soothe and relax them, as well as up-tempo jams to make your baby giggle')}</li>
                                                <li>{t('common.music.babyCubs.paragraph3', 'Watch your baby react to new sounds as your teacher plays music on the violin, ukulele, guitar and xylophone')}</li>
                                                <li>{t('common.music.babyCubs.paragraph4', 'Baby Cubs explore different instruments to improve their cognitive development and fine motor skills')}</li>
                                                <li>{t('common.music.babyCubs.paragraph5', 'Introduce new sensory sensations with rainmakers, egg shakers and floor drums')}</li>
                                            </ul>
                                            <p>{t('common.music.babyCubs.paragraph6', 'There are 5 stages of Baby Cubs that your bear cub can progress through.  Each stage will include new songs, instruments, sensory play & fun to help your bear cub grow a lifelong love of music.')}</p>
                                        </Row>
                                    </Col>
                                </Row>

                                <div style={{ margin: '3rem 0' }}>
                                    <HorizontalRule />
                                </div>

                                <Row className="stages-information-static-section">
                                    <Col xs={12} md={6}>
                                        <Row className="stages-information-static-section-header">
                                            <h5 className="stages-information-static-section-header-text">{t('common.music.toddlerCubs.name', 'Toddler Cubs')}</h5>
                                            <h6 className="stages-information-static-section-header-subtext">{t('common.music.toddlerCubs.age', '1.5 years to 3.5 years')}</h6>
                                        </Row>

                                        <Row className="stages-information-static-section-text">
                                            <p>{t('common.music.toddlerCubs.paragraph1', 'Toddler Cubs classes are high energy. Full of fun using acoustic music, instrument play and movement to bring out the inner music-maker in your bear cub. Every week you will drum, sing, move and play to rhythms & beats.')}</p>
                                            <ul style={{ paddingLeft: '2rem' }}>
                                                <li>{t('common.music.toddlerCubs.paragraph2', 'Play rhythms and beats using a variety of music instruments - tambourine, floor drums, egg shakers and music sticks')}</li>
                                                <li>{t('common.music.toddlerCubs.paragraph3', 'Learn to recognise and follow basic patterns in music and move to a steady beat')}</li>
                                                <li>{t('common.music.toddlerCubs.paragraph4', 'Bear Cubs will experience live music - contemporary, classic & traditional tunes on string and woodwind instruments to introduce a variety of sounds')}</li>
                                                <li>{t('common.music.toddlerCubs.paragraph5', 'Learn how sounds are produced from musical instruments')}</li>
                                                <li>{t('common.music.toddlerCubs.paragraph6', 'Fun classes of movement & play using scarves, mirrors and parachute-play to keep your bear cub engaged')}</li>
                                                <li>{t('common.music.toddlerCubs.paragraph7', 'Education and exploration on a variety of instruments')}</li>
                                            </ul>
                                            <p>{t('common.music.toddlerCubs.paragraph8', 'There are 5 stages of Toddler Cubs that your bear cub can progress through.  Each stage will include new songs, instruments, sensory play & fun to help your bear cub grow a lifelong love of music.')}</p>
                                        </Row>
                                    </Col>

                                    <Col xs={12} md={6} className="stages-information-static-section-image stages-information-static-section-music-toddler-cubs">
                                    </Col>
                                </Row>

                                <div style={{ margin: '3rem 0' }}>
                                    <HorizontalRule />
                                </div>

                                <Row className="stages-information-static-section">
                                    <Col xs={12} md={6} className="stages-information-static-section-image stages-information-static-section-music-big-cubs">
                                    </Col>

                                    <Col xs={12} md={6}>
                                        <Row className="stages-information-static-section-header">
                                            <h5 className="stages-information-static-section-header-text">{t('common.music.bigCubs.name', 'Big Cubs')}</h5>
                                            <h6 className="stages-information-static-section-header-subtext">{t('common.music.bigCubs.age', '3.5 years to 5 years')}</h6>
                                        </Row>

                                        <Row className="stages-information-static-section-text">
                                            <p>{t('common.music.bigCubs.paragraph1', 'Big Cubs classes are designed to grow your bear cub\'s natural love of music in a fun relaxed environment.Grow that love of music to help them choose their favourite individual instrument for future learning.')}</p>
                                            <ul style={{ marginLeft: '2rem' }}>
                                                <li>{t('common.music.bigCubs.paragraph2', 'Introduction to a variety of musical instruments - boomwhackers, violin, guitar, ukulele, drums, flute and more')}</li>
                                                <li>{t('common.music.bigCubs.paragraph3', 'Learn about to musical notes and genres in a fun way')}</li>
                                                <li>{t('common.music.bigCubs.paragraph4', 'Play rhythms and beats using a variety of music instruments - tambourine, floor drums, egg shakers and music sticks')}</li>
                                                <li>{t('common.music.bigCubs.paragraph5', 'Creative movement activities, with a partner and as a group')}</li>
                                                <li>{t('common.music.bigCubs.paragraph6', 'Bear Cubs will experience live music - contemporary, classic & traditional tunes on string and woodwind instruments to introduce a variety of sounds')}</li>
                                                <li>{t('common.music.bigCubs.paragraph7', 'Engage your bear cub with a variety of sounds, musical genres, cultures, composers and instruments')}</li>
                                            </ul>
                                            <p>{t('common.music.bigCubs.paragraph8', 'There are five Big Cubs stages for your bear cub to progress through.  Each new term will bring new songs, instruments, sensory play and fun to help your bear cub grow a lifelong love of music.')}</p>
                                        </Row>
                                    </Col>
                                </Row>

                                <div style={{ margin: '3rem 0' }}>
                                    <HorizontalRule />
                                </div>

                                <Row className="stages-information-static-section">
                                    <Col xs={12} md={6}>
                                        <Row className="stages-information-static-section-header">
                                            <h5 className="stages-information-static-section-header-text">{t('common.music.familyCubs.name', 'Family Cubs')}</h5>
                                            <h6 className="stages-information-static-section-header-subtext">{t('common.music.familyCubs.age', 'Birth to 5 years')}</h6>
                                        </Row>

                                        <Row className="stages-information-static-section-text">
                                            <p>{t('common.music.familyCubs.paragraph1', 'Family Cubs classes are a mixed-age class, suitable for Baby Cubs, Toddler Cubs & Big Cubs aged between birth to 5 years. A class for families with two or more bear cubs who want to have fun together as a family. Classes are designed to keep everyone entertained from Baby Cubs to Big Cubs. Lots of fun with movement activities, group dances, and instrument play.')}</p>
                                            <p>{t('common.music.familyCubs.paragraph2', 'At least one grown up must attend the class per two bear cubs, and two grown ups must accompany three or more bear cubs.')}</p>
                                            <p>{t('common.music.familyCubs.paragraph3', 'Family Cubs is all about special weekly family bonding time together. Encouraging more musical fun at home and inspiring a lifelong love of music.')}</p>
                                        </Row>
                                    </Col>

                                    <Col xs={12} md={6} className="stages-information-static-section-image stages-information-static-section-music-family-cubs">
                                    </Col>
                                </Row>
                            </>
                        )
                    }
                </div>
            </MainContainer>
        </>
    );
};