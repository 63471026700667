import * as React from 'react';
import { useSaveInStore, SaveInStoreOptions } from '../../../shared/useSaveInStore';
import { useUpdateGiftVoucherMutation } from './useUpdateGiftVoucherMutation';
import { GiftVoucher } from '../models/GiftVoucher';
import { useCreateGiftVoucherMutation } from './useCreateGiftVoucherMutation';
import { GiftVoucherCreateInput, GiftVoucherUpdateInput } from '../generated/graphql';

/**
 * Get a callback to save a GiftVoucher in the api using either a create or update.
 */
export function useSaveGiftVoucherMutation(options: SaveInStoreOptions<GiftVoucher, string> = {}) {
    const [_create, createStatus] = useCreateGiftVoucherMutation();
    const create = React.useCallback((model: Partial<GiftVoucher>) => _create(model as GiftVoucherCreateInput), [_create]);

    const [_update, updateStatus] = useUpdateGiftVoucherMutation();
    const update = React.useCallback((id: string, changes: Partial<GiftVoucher>) => _update(id, changes as GiftVoucherUpdateInput), [_update]);

    return useSaveInStore(
        [create, createStatus],
        [update, updateStatus],
        options
    );
}
