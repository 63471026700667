import { IdentityRoles } from '../../configure/security/IdentityRoles';
import { RouteEntry } from '../../shared/routes';
import { ClassStagesList } from './ClassStagesList';
import { DeleteClassStage } from './DeleteClassStage';
import { CreateClassStage, EditClassStage } from './edit/EditClassStage';

/**
 * Routes that should be added to configuration/routeConfig.ts to provide access to these components via routing.
 */
export const classStagesRoutes: Array<RouteEntry> = [
    { path: '/administration/class-management/class-stages/add', element: <CreateClassStage />, authorize: true, requireRole: IdentityRoles.Administration, },
    { path: '/administration/class-management/class-stages/edit/:id', element: <EditClassStage />, authorize: true, requireRole: IdentityRoles.Administration, },
    { path: '/administration/class-management/class-stages/delete/:id', element: <DeleteClassStage />, authorize: true, requireRole: IdentityRoles.Administration, },
    { path: '/administration/class-management/class-stages', element: <ClassStagesList />, authorize: true, requireRole: IdentityRoles.Administration, },
];