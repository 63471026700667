import { useMutation } from "@apollo/react-hooks";
import { useAsyncCallback } from 'react-use-async-callback';
import gql from "graphql-tag";
import { AsyncActionStatus } from '../../../shared/abstractStore';
import { UpdateScheduledClassMutation, UpdateScheduledClassMutationVariables, ScheduledClassUpdateInput } from '../generated/graphql';
import { scheduledClassFieldsFragment } from '../models/ScheduledClass';

/**
 * Get a callback to update a ScheduledClass in the api.
 */
export function useUpdateScheduledClassMutation(): [(id: string, model: ScheduledClassUpdateInput) => Promise<void>, AsyncActionStatus] {
    const [mutationAction, { error }] = useMutation<UpdateScheduledClassMutation, UpdateScheduledClassMutationVariables>(
        gql`
            mutation updateScheduledClassMutation ($model: ScheduledClassUpdateInput!) {
                updateScheduledClass(model: $model) {
                    ...scheduledClassFields
                }
            }

            ${scheduledClassFieldsFragment}
        `
    );

    // Wrap the mutation in a standard callback.
    const [action, { isExecuting, errors: actionErrors }] = useAsyncCallback(async (id: string, model: ScheduledClassUpdateInput) => {
        const result = await mutationAction({ variables: { model: { ...model, id: id } } });
        if (result.errors && result.errors.length) {
            throw result.errors;
        }
    }, [mutationAction]);

    return [action, { isExecuting: isExecuting, errors: error ? error.message : actionErrors }];
}
