import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import { ConditionalFragment } from 'react-conditionalfragment';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { LinkContainer } from 'react-router-bootstrap';
import { Button, ButtonDropdown, ButtonGroup, CardTitle, Col, DropdownItem, DropdownMenu, DropdownToggle, Row } from 'reactstrap';
import { useToggleStateArray } from 'use-toggle-state';
import { useClassLocationsListViewModel } from '../../api/main/classLocations/viewModels/useClassLocationsListViewModel';
import { AmenityType } from '../../api/main/models/AmenityType';
import { ClassType } from '../../api/main/models/ClassType';
import { usePreferredListViewMode } from '../../globalState/preferredListViewMode/usePreferredListViewMode';
import { AlertOnErrors } from '../../shared/alertOnErrors';
import { HtmlDisplay } from '../../shared/htmlEditor';
import { useReplaceSearchParamsEffect, useSearchParams } from '../../shared/useURLSearchParams';
import { AmenityTypeSelector } from '../amenityTypes/AmenityTypeSelector';
import { ClassTypeSelectorWithColors } from '../classTypes/ClassTypeSelectorWithColors';
import { Banner } from '../shared/banner/Banner';
import { CardsOrTable } from '../shared/cardsOrTable/CardsOrTable';
import { CardsOrTableToggle } from '../shared/cardsOrTable/CardsOrTableToggle';
import { LoadingIndicator } from '../shared/loadingIndicator/LoadingIndicator';
import { MainContainer } from '../shared/mainContainer/MainContainer';
import { SearchInput } from '../shared/searchInput/SearchInput';
import { StickyToolbar } from '../shared/stickyToolbar/StickyToolbar';

/**
 * List of ClassLocations
 */
export const ClassLocationsList = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    // Load data.
    const {
        data: {
            items: _allItems,
            classLocationClassTypes,
            classTypes,
            classLocationAmenityTypes,
            amenityTypes,
        },
        errors: loadingErrors, isLoading,
    } = useClassLocationsListViewModel();

    // Combine the data from all the tables into one model.
    const allItems = React.useMemo(() => _allItems?.map(item => {
        // Get our class type links.
        const myClassTypeLinks = classLocationClassTypes?.filter(it => it.classLocationId === item.id);
        const myAmenityTypeLinks = classLocationAmenityTypes?.filter(it => it.classLocationId === item.id);

        return {
            ...item,

            classTypes: myClassTypeLinks
                ?.map(link => classTypes.find(type => type.id === link.classTypeId))
                ?.filter(type => !!type) // remove any nulls.
                ?.map(link => link as ClassType) // Now we have removed the nulls, we can tell it we know there will not be any.
            ,

            amenityTypes: myAmenityTypeLinks
                ?.map(link => amenityTypes.find(type => type.id === link.amenityTypeId))
                ?.filter(type => !!type) // remove any nulls.
                ?.map(link => link as AmenityType) // Now we have removed the nulls, we can tell it we know there will not be any.
        };
    }), [_allItems, classLocationClassTypes, classTypes, classLocationAmenityTypes, amenityTypes]);

    const [isMenuOpen, toggleMenuOpen] = useToggleStateArray();

    // Use the preferred view mode for lists.
    const [viewMode] = usePreferredListViewMode();

    // Search.
    const { search: searchParam } = useSearchParams();
    const [search, setSearch] = React.useState<string>(searchParam ?? '');

    // Keep the URL up to date with the search text.
    useReplaceSearchParamsEffect({ search: search });

    // Filter by the search.
    const items = React.useMemo(() => {
        let ret = (allItems ?? []);

        let lowerSearch = search.toLocaleLowerCase();
        if (lowerSearch) {
            // Just filtering by all string values that arent ids to start with. Most likely wont need them all for search.
            ret = ret.filter(item =>
                item.name.toLocaleLowerCase().indexOf(lowerSearch) >= 0
                || !!item.classTypes.find(it => (it?.name?.toLocaleLowerCase() ?? '').indexOf(lowerSearch) >= 0)
            );
        }

        return ret;

    }, [allItems, search,]);

    // Render the UI.
    //
    return (
        <>
            <Banner fluid>
                <StickyToolbar>
                    <Row>
                        <Col>
                            <h1>
                                {t('classLocationsList.title', 'Locations')}
                            </h1>
                        </Col>
                        <ConditionalFragment showIf={isLoading}>
                            <Col xs="auto">
                                <LoadingIndicator size="sm" />
                            </Col>
                        </ConditionalFragment>
                        <Col xs="auto">
                            <CardsOrTableToggle />
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <SearchInput value={search} onChange={e => setSearch(e.currentTarget.value)} />
                        </Col>
                        <Col xs="auto">
                            <Row>
                                <ButtonGroup>
                                    <LinkContainer to={"add"}>
                                        <Button color="primary">
                                            <FontAwesomeIcon icon="plus" /><> {t('classLocationsList.add', 'Add')}</>
                                        </Button>
                                    </LinkContainer>
                                </ButtonGroup>
                            </Row>
                        </Col>
                    </Row>
                </StickyToolbar>
            </Banner>

            <MainContainer fluid>
                <AlertOnErrors errors={loadingErrors} />

                <CardsOrTable
                    viewMode={viewMode}
                    items={items}
                    tableHeadings={[
                        null, /* Handles the card specific function for which we don't want a table column. */
                        t('classLocationsList.name.heading', 'Name'),
                        t('classLocationsList.address.heading', 'Address'),
                        t('classLocationsList.directions.heading', 'Directions'),
                        t('classLocationsList.classTypes.heading', 'Activities'),
                        t('classLocationsList.amenities.heading', 'Amenities')
                    ]}
                    columns={[
                        // We handle the card layout as a specific column ignored from the table.
                        (item, view) => {
                            // This column doesn't show in the table.
                            if (view !== 'cards') {
                                return null;
                            }

                            return (
                                <>
                                    <CardTitle tag="h5">{item.name}</CardTitle>

                                    <ClassTypeSelectorWithColors
                                        readOnly
                                        tags={item?.classTypes}
                                        colors={["secondary", "primary"]}
                                    />
                                    <div style={{ maxHeight: "4.8rem", overflow: "hidden", }}>
                                        {item.address}
                                    </div>
                                </>
                            );
                        },

                        // Name
                        (item, view) => {
                            if (view !== 'table') {
                                return null;
                            }

                            return item.name;
                        },

                        // Description
                        (item, view) => {
                            if (view !== 'table') {
                                return null;
                            }

                            return item.address;
                        },

                        // Directions
                        (item, view) => {
                            if (view !== 'table') {
                                return null;
                            }

                            return (<HtmlDisplay html={item.directionsHtml} />);
                        },

                        // Activities
                        (item, view) => {
                            if (view !== 'table') {
                                return null;
                            }

                            return <ClassTypeSelectorWithColors
                                readOnly
                                tags={item?.classTypes}
                                colors={["secondary", "primary"]}
                            />;
                        },

                        // Amenities
                        (item, view) => {
                            if (view !== 'table') {
                                return null;
                            }

                            return <AmenityTypeSelector
                                readOnly
                                tags={item?.amenityTypes}
                            />;
                        },
                    ]}
                    buttons={(item) => (
                        <ButtonGroup>
                            <LinkContainer to={`edit/${item.id}`}>
                                <Button color="primary">
                                    <FontAwesomeIcon icon="edit" />
                                    <> {t('common.edit', 'Edit')}</>
                                </Button>
                            </LinkContainer>
                            <ButtonDropdown isOpen={isMenuOpen(item.id)} toggle={() => toggleMenuOpen(item.id)}>
                                <DropdownToggle color="primary" caret>
                                    <span className="visually-hidden">{t('common.menuDropdown', 'More')}</span>
                                </DropdownToggle>
                                <DropdownMenu end>
                                    <LinkContainer to={`delete/${item.id}`}>
                                        <DropdownItem className="text-danger">
                                            <FontAwesomeIcon icon="trash" />
                                            <> {t('common.delete', 'Delete')}</>
                                        </DropdownItem>
                                    </LinkContainer>
                                </DropdownMenu>
                            </ButtonDropdown>
                        </ButtonGroup>
                    )}
                    onItemClick={item => navigate(`edit/${item.id}`)}
                />
            </MainContainer>
        </>
    );
};