import gql from 'graphql-tag';
import { Guid } from 'guid-string';
import moment from 'moment';
import { TermHolidayFieldsFragment } from '../generated/graphql';

export const termHolidayFieldsFragment = gql`
    fragment termHolidayFields on TermHoliday {
        id
        name
        archived
        termId
        classLocationId
        startDate
        endDate
    }
`;

export type TermHoliday = Omit<TermHolidayFieldsFragment, '__typename'>;

/**
 * Default values.
 */
export const termHolidayDefaultValues = (): Partial<TermHoliday> => ({
    id: Guid.newGuid(),
    name: '',
    archived: false,
    termId: undefined,
    classLocationId: null,
    startDate: moment().startOf('day').toISOString(),
    endDate: moment().startOf('day').add('day', 7).toISOString(),
});