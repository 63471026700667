import { NavItem } from 'reactstrap';
import { AuthorizeContainer } from '../../shared/authorizeRoute';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IdentityRoles } from '../../configure/security/IdentityRoles';
import { NavTitle } from '../shared/navTitle/NavTitle';
import { SidebarRequired } from '../layout/sidebar/sidebarRequired/SidebarRequired';
import { NavLinkTrackActive } from '../shared/navLinkTrackActive/NavLinkTrackActive';

/**
 * Class Management Administration navigation menu for the app. 
 */
export const ClassManagementNavigation = () => {
    const { t } = useTranslation();

    return (
        <>
            <SidebarRequired />
            <AuthorizeContainer requireRole={IdentityRoles.Administration}>
                <NavTitle>
                    {t('classManagementNavigation.classSchedule.heading', 'Class schedule')}
                </NavTitle>
                <NavItem>
                    <NavLinkTrackActive to="/administration/class-management/class-navigator">
                        <FontAwesomeIcon icon="location-crosshairs" className="nav-icon" />
                        {t('classManagementNavigation.classNavigator.name', 'Class navigator')}
                    </NavLinkTrackActive>
                </NavItem>
                <NavItem>
                    <NavLinkTrackActive to="/administration/class-management/timetable">
                        <FontAwesomeIcon icon="calendar-days" className="nav-icon" />
                        {t('classManagementNavigation.classTimetable.name', 'Class timetable')}
                    </NavLinkTrackActive>
                </NavItem>
                <NavItem>
                    <NavLinkTrackActive to="/administration/class-management/schedule">
                        <FontAwesomeIcon icon="magnifying-glass" className="nav-icon" />
                        {t('classManagementNavigation.scheduleExaminer.name', 'Schedule examiner')}
                    </NavLinkTrackActive>
                </NavItem>
                <NavItem>
                    <NavLinkTrackActive to="/administration/class-management/classes">
                        <FontAwesomeIcon icon="person-chalkboard" className="nav-icon" />
                        {t('classManagementNavigation.classes.name', 'Class list')}
                    </NavLinkTrackActive>
                </NavItem>
                <NavItem>
                    <NavLinkTrackActive to="/administration/class-management/terms">
                        <FontAwesomeIcon icon="calendar-check" className="nav-icon" />
                        {t('classManagementNavigation.terms.name', 'Terms')}
                    </NavLinkTrackActive>
                </NavItem>
                {/*<NavItem>*/}
                {/*    <NavLinkTrackActive to="/administration/class-management/dashboard">*/}
                {/*        <FontAwesomeIcon icon="chart-line" className="nav-icon" />*/}
                {/*        {t('classManagementNavigation.dashboard.name', 'Dashboard')}*/}
                {/*    </NavLinkTrackActive>*/}
                {/*</NavItem>*/}


                <NavTitle>
                    {t('classManagementNavigation.reenrolment.heading', 'Re-enrolment')}
                </NavTitle>
                <NavItem>
                    <NavLinkTrackActive to="/administration/class-management/reenrolment">
                        <FontAwesomeIcon icon="clock-rotate-left" className="nav-icon" />
                        {t('childrenManagementNavigation.reenrolment.name', 'Re-enrolement processes')}
                    </NavLinkTrackActive>
                </NavItem>
                <NavItem>
                    <NavLinkTrackActive to="/administration/class-management/close-reenrolment">
                        <FontAwesomeIcon icon="stop" className="nav-icon" />
                        {t('childrenManagementNavigation.closeReenrolment.name', 'Close Re-enrolement')}
                    </NavLinkTrackActive>
                </NavItem>


                <NavTitle>
                    {t('classManagementNavigation.classCancellations.heading', 'Class cancellations')}
                </NavTitle>
                <NavItem>
                    <NavLinkTrackActive to="/administration/class-management/cancel-class">
                        <FontAwesomeIcon icon="calendar-xmark" className="nav-icon" />
                        {t('classManagementNavigation.cancelClass.name', 'Cancel a class')}
                    </NavLinkTrackActive>
                </NavItem>
                <NavItem>
                    <NavLinkTrackActive to="/administration/class-management/cancelled-classes">
                        <FontAwesomeIcon icon="layer-group" className="nav-icon" />
                        {t('classManagementNavigation.cancelledClasses.name', 'Cancelled classes')}
                    </NavLinkTrackActive>
                </NavItem>

                <NavTitle>
                    {t('classManagementNavigation.locations.heading', 'Pools and Locations')}
                </NavTitle>
                <NavItem>
                    <NavLinkTrackActive to="/administration/class-management/pools">
                        <FontAwesomeIcon icon="water-ladder" className="nav-icon" />
                        {t('classManagementNavigation.pools.name', 'Locations')}
                    </NavLinkTrackActive>
                </NavItem>
                <NavItem>
                    <NavLinkTrackActive to="/administration/class-management/expiring-requirement-checks">
                        <FontAwesomeIcon icon="certificate" className="nav-icon" />
                        {t('classManagementNavigation.expiringRequirementChecks.name', 'Expiring requirement checks')}
                    </NavLinkTrackActive>
                </NavItem>
                <NavTitle>
                    {t('classManagementNavigation.classStages.heading', 'Stage management')}
                </NavTitle>
                <NavItem>
                    <NavLinkTrackActive to="/administration/class-management/class-stages">
                        <FontAwesomeIcon icon="bars-progress" className="nav-icon" />
                        {/*{t('classManagementNavigation.classStages.name', 'Class stages')}*/}
                        {t('classManagementNavigation.classStages.name', 'Class types')}
                    </NavLinkTrackActive>
                </NavItem>
                <NavItem>
                    <NavLinkTrackActive to="/administration/class-management/class-types">
                        <FontAwesomeIcon icon="drum" className="nav-icon" />
                        {/*{t('classManagementNavigation.classTypes.name', 'Class types')}*/}
                        {t('classManagementNavigation.classTypes.name', 'Activities')}
                    </NavLinkTrackActive>
                </NavItem>

                <NavTitle>
                    {t('classManagementNavigation.lookupManagement.heading', 'Lookup management')}
                </NavTitle>
                <NavItem>
                    <NavLinkTrackActive to="/administration/class-management/amenities">
                        <FontAwesomeIcon icon="tags" className="nav-icon" />
                        {t('classManagementNavigation.amenities.name', 'Location Amenities')}
                    </NavLinkTrackActive>
                </NavItem>
                <NavItem>
                    <NavLinkTrackActive to="/administration/class-management/cancellation-reasons">
                        <FontAwesomeIcon icon="tags" className="nav-icon" />
                        {t('classManagementNavigation.cancellationReasons.name', 'Cancellation reasons')}
                    </NavLinkTrackActive>
                </NavItem>
                <NavItem>
                    <NavLinkTrackActive to="/administration/class-management/query-message-subjects">
                        <FontAwesomeIcon icon="tags" className="nav-icon" />
                        {t('classManagementNavigation.queryMessageTypes.name', 'Query message types')}
                    </NavLinkTrackActive>
                </NavItem>

                <NavTitle>
                    {t('classManagementNavigation.automation.heading', 'Automation')}
                </NavTitle>
                <NavItem>
                    <NavLinkTrackActive to="/administration/class-management/scheduled-automation">
                        <FontAwesomeIcon icon="clock" className="nav-icon" />
                        {t('classManagementNavigation.scheduledAutomation.name', 'Scheduled automation')}
                    </NavLinkTrackActive>
                </NavItem>
                <NavItem>
                    <NavLinkTrackActive to="/administration/class-management/automation-history">
                        <FontAwesomeIcon icon="database" className="nav-icon" />
                        {t('classManagementNavigation.automationHistory.name', 'Automation history')}
                    </NavLinkTrackActive>
                </NavItem>
                <NavItem>
                    <NavLinkTrackActive to="/administration/class-management/notice-board">
                        <FontAwesomeIcon icon="bullhorn" className="nav-icon" />
                        {t('classManagementNavigation.noticeBoard.name', 'Notice board')}
                    </NavLinkTrackActive>
                </NavItem>
            </AuthorizeContainer>
        </>
    );
};
