import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ConditionalFragment } from "react-conditionalfragment";
import { useTranslation } from "react-i18next";
import { Button, Col, Row, Table } from "reactstrap";
import { useReportDataSourceServiceForReenrolmentsViewModel } from "../../../api/main/dashboard/viewModels/useReportDataSourceServiceForReenrolmentsViewModel";
import { AlertOnErrors } from "../../../shared/alertOnErrors";
import { useLocalStorage } from "../../../shared/useLocalStorage";
import { ClassScheduleFilterState } from "../../scheduledClass/schedule/ClassSchedule";
import { LoadingIndicator } from "../../shared/loadingIndicator/LoadingIndicator";
import { NoResultsFound } from "../../shared/noResultsFound/NoResultsFound";
import { TermSelector } from "../../shared/termSelector/TermSelector";
import { ReenrolmentsDonutChart } from "../charts/ReenrolmentsDonutChart";

/**
 * ReenrolmentsTab for Dashboard
 */
export const ReenrolmentsTab = () => {
    const { t } = useTranslation();

    // Allow filtering (persisted to local storage between sessions).
    const [filterState, setFilterState] = useLocalStorage<ClassScheduleFilterState>('classSchedule.filterState', {});

    // Load the data
    const {
        data: {
            reenrolmentsData
        }, isLoading, errors: loadErrors
    } = useReportDataSourceServiceForReenrolmentsViewModel(filterState.termId);

    // Render the UI
    //
    return (
        <>
            {/* Alert on errors */}
            <AlertOnErrors
                errors={[loadErrors]}
            />

            {/* No results found */}
            <ConditionalFragment showIf={!isLoading && !reenrolmentsData}>
                <NoResultsFound />
            </ConditionalFragment>

            {/* Term toggle */}
            <Row>
                <Col>

                </Col>

                <Col xs="auto">
                    <TermSelector selectedTermId={filterState.termId} setFilterState={({ termId: value }) => setFilterState({ termId: value })} restrictToTermsWithClasses={true} showPreviousTerms={true} />
                </Col>
            </Row>

            {/* Loading indicator */}
            <ConditionalFragment showIf={!!isLoading}>
                <LoadingIndicator />
            </ConditionalFragment>

            {/* Render the Chart and our Table of data */}
            <ConditionalFragment showIf={!isLoading && !!reenrolmentsData}>
                <ReenrolmentsDonutChart chartData={reenrolmentsData} />

                {/* Export button */}
                <Row>
                    <Col>
                    </Col>

                    <Col xs="auto">
                        <Button color="link">
                            <a className="btn btn-outline-primary" href={`/api/exportreports/exportreenrolments?format=${encodeURIComponent('xlsx')}&termId=${filterState.termId}`} download>
                                <FontAwesomeIcon icon="download" />
                                <> </>
                                {t('dashboard.reenrolmentsTab.download', 'Export')}
                            </a>
                        </Button>
                    </Col>
                </Row>

                {/* Table data */}
                <Table responsive striped>
                    <thead>
                        <tr>
                            <th>{t('dashboard.reenrolmentsTab.termName.heading', 'Term')}</th>
                            <th>{t('dashboard.reenrolmentsTab.newStarters.heading', 'New starters')}</th>
                            <th>{t('dashboard.reenrolmentsTab.renewed.heading', 'Renewals')}</th>
                            <th>{t('dashboard.reenrolmentsTab.changedClass.heading', 'Class moves')}</th>
                            <th>{t('dashboard.reenrolmentsTab.leaving.heading', 'Leavers')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                {reenrolmentsData?.termName}
                            </td>

                            <td>
                                {reenrolmentsData?.newStarters}
                            </td>

                            <td>
                                {reenrolmentsData?.renewed}
                            </td>

                            <td>
                                {reenrolmentsData?.changedClass}
                            </td>

                            <td>
                                {reenrolmentsData?.leaving}
                            </td>
                        </tr>
                    </tbody>
                </Table>
            </ConditionalFragment>
        </>
    );
};