import * as React from 'react';
import { useLazyQuery } from "@apollo/client";
import { gql } from 'graphql-tag';
import { Guid } from "guid-string";
import { mainApiConfig } from "../../../../configure/mainApiConfig";
import { EditScheduledClassStaffsViewModeQuery, EditScheduledClassStaffsViewModeQueryVariables } from '../../generated/graphql';
import { scheduledClassFieldsFragment } from "../../models/ScheduledClass";
import { AsyncLoadOptions, AsyncLoadResult } from '../../../../shared/abstractStore';
import { scheduledClassStaffFieldsFragment } from '../../models/ScheduledClassStaff';
import { staffNoteFieldsFragment } from '../../models/StaffNote';
import { scheduledClassChildFieldsFragment } from '../../models/ScheduledClassChild';
import { childAttendanceFieldsFragment } from '../../models/ChildAttendance';
import { childFieldsFragment } from '../../models/Child';
import { scheduledClassChildEventFieldsFragment } from '../../models/ScheduledClassChildEvent';

/**
 * Get all the data required for the EditScheduledClass component from the api
 */
export function useEditScheduledClassViewModel(id: string | undefined | null, options: AsyncLoadOptions = {}): AsyncLoadResult<EditScheduledClassStaffsViewModeQuery> {
    const [load, { data, loading, error, refetch }] = useLazyQuery<EditScheduledClassStaffsViewModeQuery, EditScheduledClassStaffsViewModeQueryVariables>(
        gql`
        query editScheduledClassStaffsViewModeQuery ($id: ID!) {
            model: scheduledClass(id: $id) {
                ...scheduledClassFields

                childAttendances {
                    ...childAttendanceFields

                    child {
                        ...childFields
                    }
                }
            }

            scheduledClassStaffs (scheduledClassId: $id) {
                ...scheduledClassStaffFields
            }

            staffNotes (targetId: $id) {
                ...staffNoteFields
            }

            scheduledClassChildren (scheduledClassId: $id) {
                ...scheduledClassChildFields
            }

            scheduledClassChildEvents (scheduledClassId: $id) {
                ...scheduledClassChildEventFields
            }
        }

        ${scheduledClassFieldsFragment}
        ${scheduledClassStaffFieldsFragment}
        ${staffNoteFieldsFragment}
        ${scheduledClassChildFieldsFragment}
        ${childAttendanceFieldsFragment}
        ${childFieldsFragment}
        ${scheduledClassChildEventFieldsFragment}
        `,
        {
            variables: {
                id: id ?? Guid.empty
            },
            fetchPolicy: options.fetchPolicy ?? mainApiConfig.defaultFetchPolicy,
            pollInterval: options.pollInterval,
            onCompleted: (data) => {
                if (options.onCompleted) {
                    options.onCompleted(data);
                }
            }
        }
    );

    // Run the query (unless we are being lazy).
    React.useEffect(() => {
        if (!options.lazy) {
            load();
        }
    }, [options.lazy, load]);

    // Return the results.
    const refresh = React.useCallback(async () => { !refetch ? await load() : await refetch(); }, [refetch, load]);
    return React.useMemo(() => ({
        data: data ?? ({} as any),
        refresh: refresh,
        isLoading: loading,
        errors: error
    }), [data, refresh, loading, error]);
}