import * as React from 'react';
import { useSaveInStore, SaveInStoreOptions } from '../../../shared/useSaveInStore';
import { useUpdateStaffAbsenceMutation } from './useUpdateStaffAbsenceMutation';
import { StaffAbsence } from '../models/StaffAbsence';
import { useCreateStaffAbsenceMutation } from './useCreateStaffAbsenceMutation';
import { StaffAbsenceCreateInput, StaffAbsenceUpdateInput } from '../generated/graphql';

/**
 * Get a callback to save a StaffAbsence in the api using either a create or update.
 */
export function useSaveStaffAbsenceMutation(options: SaveInStoreOptions<StaffAbsence, string> = {}) {
    const [_create, createStatus] = useCreateStaffAbsenceMutation();
    const create = React.useCallback((model: Partial<StaffAbsence>) => _create(model as StaffAbsenceCreateInput), [_create]);

    const [_update, updateStatus] = useUpdateStaffAbsenceMutation();
    const update = React.useCallback((id: string, changes: Partial<StaffAbsence>) => _update(id, changes as StaffAbsenceUpdateInput), [_update]);

    return useSaveInStore(
        [create, createStatus],
        [update, updateStatus],
        options
    );
}
