import { ConditionalFragment } from "react-conditionalfragment";
import { useTranslation } from "react-i18next";
import { Col } from "reactstrap";
import { useBlobReference } from "../../../api/main/blobReferences/useBlobReference";
import { AlertOnErrors } from "../../../shared/alertOnErrors";
import { LoadingIndicator } from "../../shared/loadingIndicator/LoadingIndicator";
import { HappyIcon } from "../../shared/Utilities/HappyIcon";

export interface ApproveQualificationFileViewComponentProps {
    evidenceBlobReferenceId: string | undefined | null,
}

export const ApproveQualificationFileViewComponent = (props: ApproveQualificationFileViewComponentProps) => {
    const { evidenceBlobReferenceId } = props;
    const { t } = useTranslation();

    // Get the UploadedDocument details
    const {
        data: {
            model: contentsFile
        }, isLoading, errors: contentsFileLoadErrors
    } = useBlobReference(evidenceBlobReferenceId);

    // Render the UI
    //
    return (
        <>
            <AlertOnErrors
                errors={[contentsFileLoadErrors]}
            />

            <ConditionalFragment showIf={isLoading}>
                <Col xs="auto">
                    <LoadingIndicator size="sm" />
                </Col>
            </ConditionalFragment>

            <ConditionalFragment showIf={!!contentsFile}>
                <HappyIcon icon="file" />
                <> </>
                <a href={contentsFile?.url} target="_blank" rel="noreferrer">
                    {t('approveQualificationsList.approveQualificationFileViewComponent.viewDocument.value', 'View docuement')}
                </a>
            </ConditionalFragment>

            <ConditionalFragment showIf={!contentsFile}>
                <HappyIcon icon="no-file" />
                <> </>
                {t('approveQualificationsList.approveQualificationFileViewComponent.noDocument.value', 'No documents')}
            </ConditionalFragment>
        </>
    );
}