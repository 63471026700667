import { ValidationErrors } from "pojo-validator";
import { ValidateCallback } from "pojo-validator-react";
import { ValidatedInput } from "pojo-validator-reactstrap";
import { useTranslation } from "react-i18next";
import { FormGroup, Label, Form, Row, Col } from "reactstrap";

import { Child } from "../../../api/main/models/Child";
import { ChildsRelationship } from "../../../api/main/models/ChildsRelationship";
import { Profile } from '../../../api/main/models/Profile';
import { HtmlEditor } from "../../../shared/htmlEditor";
import { ValidatedISODateTimeInput } from "../../shared/isoDateTimeInput/ValidatedISODateTimeInput";

export interface MainTabProps {
    model: Child | null | undefined,
    change: (changes: Partial<Child>) => void,
    memberProfiles: Array<Profile> | null | undefined,
    validate: ValidateCallback,
    validationErrors: ValidationErrors,
    genders: Array<{ id: string, name: string, }> | undefined | null,
    childsRelationships: Array<ChildsRelationship> | null | undefined;
}

/**
 * MainTab for EditChild
 */
export const MainTab = (props: MainTabProps) => {
    const {
        model,
        change,
        memberProfiles,
        validate,
        validationErrors,
        genders,
        childsRelationships
    } = props;
    const { t } = useTranslation();

    // Render the UI
    //
    return (
        <>
            <Form onSubmit={e => { e.preventDefault(); }}>
                <h4>{t('editChild.mainTab.childDetails.heading', 'Bear cub details')}</h4>

                <Row>
                    <Col>
                        <FormGroup>
                            <Label htmlFor="firstName">{t('editChild.mainTab.firstName.label', 'First name')}</Label>
                            <ValidatedInput name="firstName" type="text" value={model?.firstName ?? ''} onChange={e => change({ firstName: e.currentTarget.value })} onBlur={e => validate('firstName')} validationErrors={validationErrors['firstName']} />
                        </FormGroup>
                    </Col>
                    <Col>
                        <FormGroup>
                            <Label htmlFor="lastName">{t('editChild.mainTab.lastName.label', 'Last name')}</Label>
                            <ValidatedInput name="lastName" type="text" value={model?.lastName ?? ''} onChange={e => change({ lastName: e.currentTarget.value })} onBlur={e => validate('lastName')} validationErrors={validationErrors['lastName']} />
                        </FormGroup>
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <FormGroup>
                            <Label htmlFor="dateOfBirth">{t('editChild.mainTab.dateOfBirth.label', 'Date of birth')}</Label>
                            <ValidatedISODateTimeInput type="date" value={model?.dateOfBirth ?? ''} onChange={e => change({ dateOfBirth: e.currentTarget.value })} onBlur={e => validate('dateOfBirth')} validationErrors={validationErrors['dateOfBirth']}></ValidatedISODateTimeInput>
                        </FormGroup>
                    </Col>
                    <Col>
                        <FormGroup>
                            <Label htmlFor="genderId">{t('editChild.mainTab.genderId.label', 'Gender')}</Label>
                            <ValidatedInput name="genders" type="select" value={model?.genderId ?? ''} onChange={e => change({ genderId: e.currentTarget.value })} onBlur={e => validate('genderId')} validationErrors={validationErrors['genderId']}>
                                <option value="">{t('editChild.mainTab.genderId.pleaseSubmit', '(Please select a gender)')}</option>
                                {
                                    genders?.map(item => (
                                        <option key={item.id} value={item.id}>{t('editChild.mainTab.genderId.value', '{{ gender }}', {gender: item.name})}</option>
                                    ))
                                }
                            </ValidatedInput>
                        </FormGroup>
                    </Col>
                </Row>

                <h4>{t('editChild.mainTab.memberDetails.heading', 'Mama and papa bears')}</h4>
                <Row>
                    <Col>
                        <FormGroup>
                            <Label htmlFor="memberId">{t('editChild.mainTab.memberId.label', 'Member')}</Label>
                            <ValidatedInput name="memberId" type="select" value={model?.memberId ?? ''} onChange={e => change({ memberId: e.currentTarget.value })} onBlur={e => validate('memberId')} validationErrors={validationErrors}>
                                <option value="">{t('editChild.mainTab.memberId.pleaseSelect', '(Please select a member)')}</option>
                                {
                                    memberProfiles?.map(profile => (
                                        <option key={profile.id} value={profile.id}>{t('editChild.mainTab.memberId.value', '{{ firstName }} {{ lastName }} ({{ primaryEmail }})', { firstName: profile.firstName ?? '', lastName: profile.lastName ?? '', primaryEmail: profile.primaryEmail ?? '' })}</option>
                                    ))
                                }
                            </ValidatedInput>
                        </FormGroup>
                        
                    </Col>
                    <Col>
                        <FormGroup>
                            <Label htmlFor="memberChildsRelationshipId">{t('editChild.mainTab.memberChildsRelationshipId.label', 'Relationship to child')}</Label>
                            <ValidatedInput name="memberChildsRelationshipId" type="select" value={model?.memberChildsRelationshipId ?? ''} onChange={e => change({ memberChildsRelationshipId: e.currentTarget.value })} onBlur={e => validate('memberChildsRelationshipId')} validationErrors={validationErrors}>
                                <option value="">{t('editChild.mainTab.memberChildsRelationshipId.pleaseSelect', '(Please select relationship to child)')}</option>
                                {
                                    childsRelationships?.map(childRelationship => (
                                        <option key={childRelationship.id} value={childRelationship.id}>{t('editChild.mainTab.memberChildsRelationshipId.value', '{{ childRelationship }}', { childRelationship: childRelationship.name ?? '' })}</option>
                                    ))
                                }
                            </ValidatedInput>
                        </FormGroup>
                    </Col>
                </Row>


                <h4>{t('editChild.mainTab.healthNotes.heading', 'Medical info for bear cub')}</h4>
                <Row>
                    <Row>
                        <FormGroup>
                            <Label htmlFor="HealthNoteHtml">{t('editChild.mainTab.medicalInfo.label', 'Medical info')}</Label>
                            <HtmlEditor size="sm" value={model?.healthNoteHtml ?? ''} onChange={text => change({ healthNoteHtml: text })} />
                        </FormGroup>
                    </Row>
                </Row>
            </Form>
        </>
    );
};