import gql from 'graphql-tag';
import { Guid } from 'guid-string';
import moment from 'moment';
import { StaffNoteFieldsFragment } from '../generated/graphql';
import { NoteType } from './constants/NoteType';

export const staffNoteFieldsFragment = gql`
    fragment staffNoteFields on StaffNote {
        id
        targetId
        targetType
        contentsHtml
        noteType
        updatedDate
        updatedByUserId
        blobReferenceId
        archived
        showOnRegister
    }
`;

export type StaffNote = Omit<StaffNoteFieldsFragment, '__typename'>;

/**
 * Default values.
 */
export const staffNoteDefaultValues = (): Partial<StaffNote> => ({
    id: Guid.newGuid(),
    targetId: undefined,
    targetType: '',
    contentsHtml: '',
    noteType: NoteType.General,
    updatedDate: moment().toISOString(),
    updatedByUserId: '',
    blobReferenceId: null,
    archived: false,
    showOnRegister: false
});