import { BodyBackground } from "../../layout/bodyBackground/BodyBackground";
import { MainContainer } from "../../shared/mainContainer/MainContainer";
import { ClassSearchCard } from "./ClassSearchCard";

export const ClassSearch = () => {
    // Render the UI
    //
    return (
        <>
            <MainContainer color="transparent" centerChildren="vertically">
                <BodyBackground background="account" />
                    <ClassSearchCard />
            </MainContainer>
        </>
    );
};