import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import { useMemo, useState } from "react";
import { ConditionalFragment } from "react-conditionalfragment";
import { useTranslation } from "react-i18next";
import { LinkContainer } from "react-router-bootstrap";
import { useNavigate } from "react-router-dom";
import { Button, CardSubtitle, CardTitle, Col, Input, Row } from "reactstrap";
import { useExpiringRequirementChecksListViewModel } from "../../api/main/classLocations/viewModels/useExpiringRequirementChecksViewModel";
import { usePreferredListViewMode } from "../../globalState/preferredListViewMode/usePreferredListViewMode";
import { AlertOnErrors } from "../../shared/alertOnErrors";
import { useReplaceSearchParamsEffect, useSearchParams } from "../../shared/useURLSearchParams";
import { Banner } from "../shared/banner/Banner";
import { CardsOrTable } from "../shared/cardsOrTable/CardsOrTable";
import { CardsOrTableToggle } from "../shared/cardsOrTable/CardsOrTableToggle";
import { LoadingIndicator } from "../shared/loadingIndicator/LoadingIndicator";
import { MainContainer } from "../shared/mainContainer/MainContainer";
import { NoResultsFound } from "../shared/noResultsFound/NoResultsFound";
import { SearchInput } from "../shared/searchInput/SearchInput";
import { StickyToolbar } from "../shared/stickyToolbar/StickyToolbar";

/**
 * List of ExpiringRequirementChecks for all ClassLocations
 */
export const ExpiringRequirementChecksList = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    // Load the data
    const {
        data: {
            items: _items,
        }, isLoading, errors: loadErrors
    } = useExpiringRequirementChecksListViewModel();

    // Use the preferred view mode for lists.
    const [viewMode] = usePreferredListViewMode();

    // Let the user choose a timeFrame filter.
    const [timeFrame, setTimeFrame] = useState<number>(1);

    // Search.
    const { search: searchParam } = useSearchParams();
    const [search, setSearch] = useState<string>(searchParam ?? '');

    // Keep the URL up to date with the search text.
    useReplaceSearchParamsEffect({ search: search });

    // Filter by the search.
    const items = useMemo(() => {
        let ret = (_items ?? []);

        // Filter to show only things that have expired or will expire within a month.
        const dateFilter = moment().add(timeFrame, 'month');
        ret = ret.filter(item =>
            !!item.reviewDate // Has a review date
            && moment(item.reviewDate) <= dateFilter // Review date is expired or will expire before dateFilter (e.g. 1 month).
        );

        // Filter by the user's search.
        let lowerSearch = search.toLocaleLowerCase();
        if (lowerSearch) {
            // Just filtering by all string values that arent ids to start with. Most likely wont need them all for search.
            ret = ret.filter(item =>
                item.name.toLocaleLowerCase().indexOf(lowerSearch) >= 0
                || item.classLocation.name.toLocaleLowerCase().indexOf(lowerSearch) >= 0
            );
        }

        // Sorting by the ReviewDate
        ret = ret.sort((a, b) => new Date(a.reviewDate).getTime() - new Date(b.reviewDate).getTime());

        return ret;

    }, [_items, search, timeFrame]);

    // Render the UI
    //
    return (
        <>
            <Banner fluid>
                <StickyToolbar>
                    <Row>
                        <Col>
                            <h1>
                                {t('expiringRequirementChecks.title', 'Expiring requirement checks')}
                            </h1>
                        </Col>
                        <ConditionalFragment showIf={isLoading}>
                            <Col xs="auto">
                                <LoadingIndicator size="sm" />
                            </Col>
                        </ConditionalFragment>
                        <Col xs="auto">
                            <Input type="select" value={timeFrame} onChange={e => setTimeFrame(parseInt(e.currentTarget.value))}>
                                <option value={0}>{t('expiringRequirementChecks.timeFrame.overdue', 'Overdue')}</option>
                                <option value={1}>{t('expiringRequirementChecks.timeFrame.1Month', 'Expires in 1 month or less')}</option>
                                <option value={2}>{t('expiringRequirementChecks.timeFrame.2Month', 'Expires in 2 months or less')}</option>
                                <option value={3}>{t('expiringRequirementChecks.timeFrame.3Month', 'Expires in 3 months or less')}</option>
                            </Input>
                        </Col>
                        <Col xs="auto">
                            <CardsOrTableToggle />
                        </Col>
                    </Row>

                    <SearchInput value={search} onChange={e => setSearch(e.currentTarget.value)} />
                </StickyToolbar>
            </Banner>

            <MainContainer fluid>
                <AlertOnErrors
                    errors={[
                        loadErrors
                    ]}
                />

                <CardsOrTable
                    viewMode={viewMode}
                    items={items}
                    tableHeadings={[
                        null, /* Handles the card specific function for which we don't want a table column. */
                        t('expiringRequirementChecks.locationName.heading', 'Location'),
                        t('expiringRequirementChecks.requirementCheck.heading', 'Name'),
                        t('expiringRequirementChecks.reveiwDate.heading', 'Review date'),
                    ]}
                    columns={[
                        // We handle the card layout as a specific column ignored from the table.
                        (item, view) => {
                            // This column doesn't show in the table.
                            if (view !== 'cards') {
                                return null;
                            }
                            return (
                                <>
                                    <CardTitle tag="h5">{item?.classLocation.name}</CardTitle>
                                    <CardSubtitle>
                                        <h6>{item.name}</h6>
                                        <h6>{t('expiringRequirementChecks.reveiwDate.value', 'Review date: {{date, DD/MM/YYYY}}', { date: moment(item.reviewDate) })}</h6>
                                    </CardSubtitle>
                                </>
                            );
                        },

                        // The remaining columns are for table mode only.

                        // Review date
                        (item, view) => {
                            if (view !== 'table') {
                                return null;
                            }

                            return item.classLocation.name;
                        },

                        // RequirementCheck name
                        (item, view) => {
                            if (view !== 'table') {
                                return null;
                            }

                            return item.name;
                        },

                        // Review date
                        (item, view) => {
                            if (view !== 'table') {
                                return null;
                            }

                            return t('common.date', '{{date, DD/MM/YYYY}}', { date: moment(item.reviewDate) });
                        },
                    ]}

                    buttons={(item) => (
                        <LinkContainer to={`/administration/class-management/pools/edit/${item.classLocationId}?tab=requirementChecks`}>
                            <Button color="primary">
                                <FontAwesomeIcon icon="edit" />
                                <> {t('common.edit', 'Edit')}</>
                            </Button>
                        </LinkContainer>
                    )}
                    onItemClick={item => navigate(`/administration/class-management/pools/edit/${item.classLocationId}?tab=requirementChecks`)}
                />

                <ConditionalFragment showIf={isLoading && !items?.length}>
                    <LoadingIndicator fullWidth />
                </ConditionalFragment>
                <ConditionalFragment showIf={!isLoading && !items?.length}>
                    <NoResultsFound search={search} />
                </ConditionalFragment>
            </MainContainer>
        </>
    );
}