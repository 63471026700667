import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useAsyncCallback } from "react-use-async-callback";
import { Col, ListGroupItem, Row, Spinner } from "reactstrap";
import { ButtonAsync } from "reactstrap-buttonasync";
import { useCancelBackgroundTaskMutation } from "../../api/main/backgroundTasks/useCancelBackgroundTaskMutation";
import { useRequeueBackgroundTaskMutation } from "../../api/main/backgroundTasks/useRequeueBackgroundTaskMutation";
import { BackgroundTask } from "../../api/main/models/BackgroundTask";
import { backgroundTaskNameDisplayName } from "../../api/main/models/constants/BackgroundTaskName";
import { BackgroundTaskStatus } from "../../api/main/models/constants/BackgroundTaskStatus";
import { AlertOnErrors } from "../../shared/alertOnErrors";

export interface BackgroundTaskComponentProps {
    model: BackgroundTask,
    refresh: () => void,
}

/**
 * Component to show and manage a single task in a list of BackgroundTasks.
 */
export const BackgroundTaskComponent = (props: BackgroundTaskComponentProps) => {
    const {
        model,
        refresh
    } = props;

    const { t } = useTranslation();

    // Allow the cancelling of a current task.
    const [cancelBackgroundTaskMutation, { errors: cancelBackgroundTaskMutationErrors }] = useCancelBackgroundTaskMutation();
    const [cancelBackgroundTask, { isExecuting: isCancellingBackgroundTask, errors: cancelBackgroundTaskErrors }] = useAsyncCallback(async (id: string) => {
        // Try cancel on the server.  This will only work if we haven't started executing the task.
        await cancelBackgroundTaskMutation(id);

        refresh();
    }, [cancelBackgroundTaskMutation, refresh]);

    // Allow the requeueing of a current task.
    const [requeueBackgroundTaskMutation, { errors: requeueBackgroundTaskMutationErrors }] = useRequeueBackgroundTaskMutation();
    const [requeueBackgroundTask, { isExecuting: isRequeueingBackgroundTask, errors: requeueBackgroundTaskErrors }] = useAsyncCallback(async (id: string) => {
        // Try requeue on the server.  This will only work if we haven't started executing the task.
        await requeueBackgroundTaskMutation(id);

        refresh();
    }, [requeueBackgroundTaskMutation, refresh]);

    // Work out details for our display based on the state of the model.
    const {
        hasError, isScheduledForFuture, canCancel, canRequeue, color, icon,
    } = useMemo(() => {
        const hasError = !!model.executionErrors;
        const isScheduledForFuture = !!model.scheduledForAfter;

        return {
            hasError,
            isScheduledForFuture,

            canCancel: model.taskStatus === BackgroundTaskStatus.Queued,
            canRequeue: model.taskStatus === BackgroundTaskStatus.Cancelled || (model.taskStatus === BackgroundTaskStatus.Completed && hasError),


            color: model.taskStatus === BackgroundTaskStatus.Executing ? 'primary'
                : model.taskStatus === BackgroundTaskStatus.Completed && hasError ? 'danger'
                    : model.taskStatus === BackgroundTaskStatus.Completed && !hasError ? 'success'
                        : model.taskStatus === BackgroundTaskStatus.Cancelled ? 'dark'
                            : undefined,

            icon: (model.taskStatus === BackgroundTaskStatus.Executing ? 'hourglass'
                : model.taskStatus === BackgroundTaskStatus.Completed && hasError ? 'exclamation-triangle'
                    : model.taskStatus === BackgroundTaskStatus.Completed && !hasError ? 'check'
                        : model.taskStatus === BackgroundTaskStatus.Cancelled ? 'times'
                            : isScheduledForFuture ? 'calendar-days' : 'clock'
            ) as IconProp,

        };
    }, [model]);
    

    // UI.
    return (
        <ListGroupItem color={color}>
            <AlertOnErrors errors={[
                cancelBackgroundTaskErrors, cancelBackgroundTaskMutationErrors,
                requeueBackgroundTaskErrors, requeueBackgroundTaskMutationErrors,
            ]} />
            <Row>
                <Col xs="auto">
                    <FontAwesomeIcon icon={icon} />
                </Col>
                <Col>
                    <div style={{ fontWeight: 'bold' }}>
                        {backgroundTaskNameDisplayName(model.name, t)}
                    </div>
                    <div className="text-muted">
                        {
                            model.taskStatus === BackgroundTaskStatus.Executing || model.taskStatus === BackgroundTaskStatus.Queued ? (
                                <>
                                    {
                                        isScheduledForFuture ? (
                                            <>
                                                {t('backgroundTaskComponent.scheduledText', 'Scheduled to run after {{date, DD/MM/YYYY HH:mm}}', { date: moment(model.scheduledForAfter) })}
                                            </>
                                        ) : (
                                            <>
                                                {t('backgroundTaskComponent.queuedText', 'Task was queued on {{date, DD/MM/YYYY HH:mm}} and will run as soon as possible.', { date: moment(model.queuedDate) })}
                                            </>
                                        )
                                    }
                                </>
                            ) : model.taskStatus === BackgroundTaskStatus.Completed && hasError ? (
                                <>
                                    {t('backgroundTaskComponent.error.dates', 'Task experienced errors after attempting to run between between {{startDate, DD/MM/YYYY HH:mm}} and {{completedDate, DD/MM/YYYY HH:mm}}.', { startDate: moment(model.startedDate), completedDate: moment(model.completedDate), })}
                                    <p>
                                            {t('backgroundTaskComponent.error.p2', 'Error reason:')}
                                        <> </>
                                        {model.executionErrors}
                                    </p>
                                    </>
                                ) : model.taskStatus === BackgroundTaskStatus.Completed && !hasError ? (
                                    <>
                                            {t('backgroundTaskComponent.success.dates', 'Task completed successfully and ran between between {{startDate, DD/MM/YYYY HH:mm}} and {{completedDate, DD/MM/YYYY HH:mm}}.', { startDate: moment(model.startedDate), completedDate: moment(model.completedDate), })}
                                    </>
                            ) : model.taskStatus === BackgroundTaskStatus.Cancelled ? (
                                <>
                                    {t('backgroundTasksHistory.cancelled.dates', 'Cancelled before executing on {{date, DD/MM/YYYY HH:mm}}.', { date: moment(model.completedDate) })}
                                </>
                            ) : null
                        }
                    </div>
                </Col>
                {
                    canCancel ? (
                        <Col xs="auto">
                            <ButtonAsync color="secondary" outline
                                isExecuting={isCancellingBackgroundTask}
                                executingChildren={<><Spinner size="sm" /> {t('backgroundTaskComponent.cancelling', 'Cancelling...')}</>}
                                onClick={() => cancelBackgroundTask(model.id)}
                            >
                                <FontAwesomeIcon icon="times" />
                                <> </>
                                {t('backgroundTaskComponent.cancel', 'Cancel')}
                            </ButtonAsync>
                        </Col>
                    ) : null
                }
                {
                    canRequeue ? (
                        <Col xs="auto">
                            <ButtonAsync color="secondary" outline
                                isExecuting={isRequeueingBackgroundTask}
                                executingChildren={<><Spinner size="sm" /> {t('backgroundTaskComponent.rescheduling', 'Rescheduling...')}</>}
                                onClick={() => requeueBackgroundTask(model.id)}
                            >
                                <FontAwesomeIcon icon="clock-rotate-left" />
                                <> </>
                                {
                                    hasError ? t('backgroundTaskComponent.retry', 'Retry')
                                        :  t('backgroundTaskComponent.rescheudle', 'Reschedule')
                                }
                            </ButtonAsync>
                        </Col>
                    ) : null
                }
            </Row>
        </ListGroupItem>
    );
};