import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import { useValidatorCallback } from "pojo-validator-react";
import { ValidatedInput } from "pojo-validator-reactstrap";
import { ConditionalFragment } from "react-conditionalfragment";
import { useTranslation } from "react-i18next";
import { useAsyncCallback } from "react-use-async-callback";
import { Button, ButtonGroup, Col, Form, FormGroup, Label, ModalBody, ModalFooter, ModalHeader, Row, Spinner } from "reactstrap";
import { ButtonAsync } from "reactstrap-buttonasync";
import { BlobUploadService } from "../../../api/main/blobReferences/BlobUploadService";
import { useBlobReference } from "../../../api/main/blobReferences/useBlobReference";
import { StaffQualificationCheck, staffQualificationCheckDefaultValues } from "../../../api/main/models/StaffQualificationCheck";
import { useSaveStaffQualificationCheckMutation } from "../../../api/main/staffQualificationChecks/useSaveStaffQualificationCheckMutation";
import { useEditStaffQualificationViewModel } from "../../../api/main/staffQualificationChecks/viewModels/useEditStaffQualificationViewModel";
import { AlertOnErrors } from "../../../shared/alertOnErrors";
import { useChanges } from "../../../shared/useChanges";
import { FileUploadButton } from "../../shared/fileUploadButton/FileUploadButton";
import { ValidatedISODateTimeInput } from "../../shared/isoDateTimeInput/ValidatedISODateTimeInput";
import { UploadedFilePreview } from "../../shared/uploadedFilePreview/UploadedFilePreview";

export interface EditStaffQualifcationCheckModalProps {
    isCreate?: boolean,
    onCreateDefaultValues?: () => Partial<StaffQualificationCheck>,
    id?: string | null | undefined,
    staffId: string,
    toggle: () => void
}

/**
 * ComponentModal to Create a StaffQualification
 */
export const CreateStaffQualifcationCheckModal = (props: EditStaffQualifcationCheckModalProps) => (<EditStaffQualifcationCheckModal isCreate={true} {...props} />);

/**
 * ComponentModal to Edit a StaffQualification
 */
export const EditStaffQualifcationCheckModal = (props: EditStaffQualifcationCheckModalProps) => {
    const { isCreate, onCreateDefaultValues, id, staffId, toggle } = props;
    const { t } = useTranslation();

    // Load the data
    const {
        data: {
            model: storeModel,
        }, isLoading, errors: loadErrors
    } = useEditStaffQualificationViewModel(id);

    // Model (StaffQualification)
    const { model, change, changes } = useChanges(storeModel, isCreate ? {
        ...staffQualificationCheckDefaultValues(), staffId: staffId, submittedDate: moment(Date.now()).toISOString(), acceptanceState: 'Submitted', ...(onCreateDefaultValues ? onCreateDefaultValues() : {})
    } : undefined);
    const [saveStaffQualification] = useSaveStaffQualificationCheckMutation();

    // Main model validation
    const [validate, validationErrors] = useValidatorCallback((validation, fieldsToCheck) => {
        const rules = {
            name: () => !model?.name ? t('editStaffQualificationCheckModal.errors.startDate', 'Name is required') : '',
            reviewDate: () => !model?.reviewDate ? t('editStaffQualificationCheckModal.errors.endDate', 'Review date is required') : '',
        };

        validation.checkRules(rules, fieldsToCheck);
    }, [model]);

    // Save everything
    const [saveForm, { isExecuting: isSaving, errors: saveFormErrors }] = useAsyncCallback(async () => {
        if (!model) {
            return;
        }

        if (!validate()) {
            return;
        }

        if (!isCreate) {
            changes.acceptanceState = 'Submitted';
        }

        // Save the main model.
        await saveStaffQualification(model.id, { ...changes }, isCreate ?? false);

        // Close the modal.
        toggle();
    }, [model, changes, isCreate, id]);

    // Contents file
    const { data: { model: contentsFile }, errors: contentsFileLoadErrors } = useBlobReference(model?.evidenceBlobReferenceId);
    const [onUploadContentsFile, { errors: contentsFileUploadErrors, isExecuting: isUploadingContentsFile, }] = useAsyncCallback(async (files: FileList | null) => {
        if (!files) {
            return;
        }

        let uploadService: BlobUploadService = new BlobUploadService("/api/blobs");
        let result = await uploadService.upload(files);

        if (!!result) {
            change({ evidenceBlobReferenceId: result.id });
        }
    }, [change]);
    const [clearContentsFile, { isExecuting: isClearingContentsFile, }] = useAsyncCallback(async () => {
        change({ evidenceBlobReferenceId: null });
    }, [change]);

    return (
        <>
            <AlertOnErrors
                errors={[
                    loadErrors,
                    saveFormErrors,
                    contentsFileLoadErrors,
                    contentsFileUploadErrors
                ]}
            />

            <ModalHeader>
                <h1>
                    {
                        isCreate ? t('staffQualifcationsTab.editStaffQualificationCheckModal.add', 'Add qualification') : t('staffQualifcationsTab.editStaffQualificationCheckModal.edit', 'Edit qualification')
                    }
                </h1>
            </ModalHeader>

            <ModalBody>
                <Form onSubmit={e => { e.preventDefault(); }}>
                    <Row>
                        <Col>
                            <FormGroup>
                                <Label htmlFor="nameOfQualification">{t('staffQualifcationsTab.editStaffQualificationCheckModal.nameOfQualification.label', 'Name of qualification')}</Label>
                                <ValidatedInput name="name" type="text" value={model?.name ?? ''} onChange={e => change({ name: e.currentTarget.value })} onBlur={e => validate('name')} validationErrors={validationErrors['name']} />
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup>
                                <Label htmlFor="reviewDate">{t('staffQualifcationsTab.editStaffQualificationCheckModal.reviewDate.label', 'Review date')}</Label>
                                <ValidatedISODateTimeInput name="reviewDate" type="date" value={model?.reviewDate ?? ''} onChange={e => change({ reviewDate: e.currentTarget.value })} onBlur={e => validate('reviewDate')} validationErrors={validationErrors['reviewDate']} />
                            </FormGroup>
                        </Col>
                    </Row>

                    <Row style={{ marginTop: '1rem', textAlign: 'center' }}>
                        <Col>
                            <UploadedFilePreview blobReference={contentsFile} />
                            <ButtonGroup style={{ margin: '1rem 0' } }>
                                <FileUploadButton
                                    color={`primary`}
                                    isExecuting={isUploadingContentsFile}
                                    executingChildren={<><Spinner size="sm" /> {t('common.uploading', 'Uploading...')}</>}
                                    onUpload={onUploadContentsFile}
                                    outline>
                                    {t('staffQualifcationsTab.editStaffQualificationCheckModal.uploadButtonText', 'Submit evidence or a photo')}
                                </FileUploadButton>
                                <ButtonAsync color="primary"
                                    outline
                                    isExecuting={isClearingContentsFile}
                                    type="button"
                                    onClick={clearContentsFile}
                                    executingChildren={<><Spinner size="sm" /> {t('editClassLocation.clearingFile', 'Clearing file...')}</>}>
                                    {t('staffQualifcationsTab.editStaffQualificationCheckModal.clearUploadButton', 'Remove evidence or photo')}
                                </ButtonAsync>
                            </ButtonGroup>
                        </Col>
                    </Row>
                </Form>
            </ModalBody>

            <ModalFooter>
                <ConditionalFragment showIf={!isLoading}>
                    <ButtonAsync color="primary" isExecuting={isSaving} onClick={() => saveForm()}
                        executingChildren={<><Spinner size="sm" /> {t('common.saving', 'Saving...')}</>}>
                        <FontAwesomeIcon icon="save" />
                        <> </>
                        {t('editStaffQualificationCheckModal.submitRequest.button', 'Save')}
                    </ButtonAsync>
                    <Button type="button" color="primary" outline onClick={e => toggle()}>
                        {t('common.cancel', 'Cancel')}
                    </Button>
                </ConditionalFragment>
            </ModalFooter>
        </>
    );
}