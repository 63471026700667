import gql from 'graphql-tag';
import { Guid } from 'guid-string';
import { NoticeBoardItemFieldsFragment } from '../generated/graphql';

export const noticeBoardItemFieldsFragment = gql`
    fragment noticeBoardItemFields on NoticeBoardItem {
        id
        title
        contentHtml
        createdDate
        startDate
        endDate
        archived
    }
`;

export type NoticeBoardItem = Omit<NoticeBoardItemFieldsFragment, '__typename'>;

/**
 * Default values.
 */
export const noticeBoardItemDefaultValues = (): Partial<NoticeBoardItem> => ({
    id: Guid.newGuid(),
    title: '',
    contentHtml: '',
    createdDate: new Date().toISOString(),
    startDate: null,
    endDate: null,
    archived: false,
});