import { useMutation } from "@apollo/react-hooks";
import { useAsyncCallback } from 'react-use-async-callback';
import gql from "graphql-tag";
import { AsyncActionStatus } from '../../../shared/abstractStore';
import { ProcessScheduledClassCancellationMutation, ProcessScheduledClassCancellationMutationVariables } from '../generated/graphql';
import { backgroundTaskFieldsFragment } from "../models/BackgroundTask";

/**
 * Get a callback to process a ScheduledClassCancellation in the api, to do things such as issuing credits, sending emails etc.
 */
export function useProcessScheduledClassCancellationMutation(): [(id: string, isReschedule: boolean) => Promise<void>, AsyncActionStatus] {
    const [mutationAction, { error }] = useMutation<ProcessScheduledClassCancellationMutation, ProcessScheduledClassCancellationMutationVariables>(
        gql`
            mutation processScheduledClassCancellationMutation ($id: ID!, $isReschedule: Boolean!) {
                processScheduledClassCancellation(id: $id, isReschedule: $isReschedule) {
                    ...backgroundTaskFields
                }
            }

            ${backgroundTaskFieldsFragment}
        `
    );

    // Wrap the mutation in a standard callback.
    const [action, { isExecuting, errors: actionErrors }] = useAsyncCallback(async (id: string, isReschedule: boolean) => {
        const result = await mutationAction({ variables: { id: id, isReschedule: isReschedule } });
        if (result.errors && result.errors.length) {
            throw result.errors;
        }
    }, [mutationAction]);

    return [action, { isExecuting: isExecuting, errors: error ? error.message : actionErrors }];
}
