import { useMutation } from "@apollo/react-hooks";
import { useAsyncCallback } from 'react-use-async-callback';
import gql from "graphql-tag";
import { AsyncActionStatus } from '../../../shared/abstractStore';
import { UpdateAmenityTypeMutation, UpdateAmenityTypeMutationVariables, AmenityTypeUpdateInput } from '../generated/graphql';
import { amenityTypeFieldsFragment } from '../models/AmenityType';

/**
 * Get a callback to update a AmenityType in the api.
 */
export function useUpdateAmenityTypeMutation(): [(id: string, model: AmenityTypeUpdateInput) => Promise<void>, AsyncActionStatus] {
    const [mutationAction, { error }] = useMutation<UpdateAmenityTypeMutation, UpdateAmenityTypeMutationVariables>(
        gql`
            mutation updateAmenityTypeMutation ($model: AmenityTypeUpdateInput!) {
                updateAmenityType(model: $model) {
                    ...amenityTypeFields
                }
            }

            ${amenityTypeFieldsFragment}
        `
    );

    // Wrap the mutation in a standard callback.
    const [action, { isExecuting, errors: actionErrors }] = useAsyncCallback(async (id: string, model: AmenityTypeUpdateInput) => {
        const result = await mutationAction({ variables: { model: { ...model, id: id } } });
        if (result.errors && result.errors.length) {
            throw result.errors;
        }
    }, [mutationAction]);

    return [action, { isExecuting: isExecuting, errors: error ? error.message : actionErrors }];
}
