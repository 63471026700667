import { useMutation } from "@apollo/react-hooks";
import { useAsyncCallback } from 'react-use-async-callback';
import gql from "graphql-tag";
import { AsyncActionStatus } from '../../../shared/abstractStore';
import { AddToScheduledClassMutation, AddToScheduledClassMutationVariables } from '../generated/graphql';
import { scheduledClassChildFieldsFragment } from "../models/ScheduledClassChild";

/**
 * Get a callback to delete a ScheduledClassChild in the api.
 */
export function useAddToScheduledClassMutation(): [(childId: string, scheduledClassId: string, reflectChangesForFutureTerms: boolean) => Promise<void>, AsyncActionStatus] {
    const [mutationAction, { error }] = useMutation<AddToScheduledClassMutation, AddToScheduledClassMutationVariables>(
        gql`
            mutation addToScheduledClassMutation ($childId: ID!, $scheduledClassId: ID!, $reflectChangesForFutureTerms: Boolean!) {
                addToScheduledClass(childId: $childId, scheduledClassId: $scheduledClassId, reflectChangesForFutureTerms: $reflectChangesForFutureTerms) {
                    ...scheduledClassChildFields
                }
            }

            ${scheduledClassChildFieldsFragment}
        `
    );

    // Wrap the mutation in a standard callback.
    const [action, { isExecuting, errors: actionErrors }] = useAsyncCallback(async (childId: string, scheduledClassId: string, reflectChangesForFutureTerms: boolean) => {
        const result = await mutationAction({ variables: { childId, scheduledClassId, reflectChangesForFutureTerms } });
        if (result.errors && result.errors.length) {
            throw result.errors;
        }
    }, [mutationAction]);

    return [action, { isExecuting: isExecuting, errors: error ? error.message : actionErrors }];
}
