import { IdentityRoles } from "../../configure/security/IdentityRoles";
import { RouteEntry } from "../../shared/routes";
import { DeleteTerm } from "./DeleteTerm";
import { CreateTerm, EditTerm } from "./edit/EditTerm";
import { TermsList } from "./TermsList";


/**
 * Routes that should be added to configuration/routeConfig.ts to provide access to these components via routing.
 */
export const termsRoutes: Array<RouteEntry> = [
    { path: '/administration/class-management/terms/add', element: <CreateTerm />, authorize: true, requireRole: IdentityRoles.Administration, },
    { path: '/administration/class-management/terms/edit/:id', element: <EditTerm />, authorize: true, requireRole: IdentityRoles.Administration, },
    { path: '/administration/class-management/terms/delete/:id', element: <DeleteTerm />, authorize: true, requireRole: IdentityRoles.Administration, },
    { path: '/administration/class-management/terms', element: <TermsList />, authorize: true, requireRole: IdentityRoles.Administration, },
];
