import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import { useState } from "react";
import { ConditionalFragment } from "react-conditionalfragment";
import { useTranslation } from "react-i18next";
import { LinkContainer } from "react-router-bootstrap";
import { useAsyncCallback } from "react-use-async-callback";
import { Alert, Button, Card, Spinner } from "reactstrap";
import { ButtonAsync } from "reactstrap-buttonasync";
import { PaymentState } from "../../../../api/main/models/constants/PaymentState";
import { Payment } from "../../../../api/main/models/Payment";
import { useSendPaymentAvailableEmailMutation } from "../../../../api/main/payments/useSendPaymentAvailableEmailMutation";
import { AlertOnErrors } from "../../../../shared/alertOnErrors";
import { getPaymentStateBadge } from "../../../shared/Utilities/getPaymentStateBadge";
import "./paymentsComponent.scss";

export interface PaymentComponentProps {
    payment: Payment | undefined | null,
}

/**
 * Component for displaying a single Payment for a Child
 */
export const PaymentComponent = (props: PaymentComponentProps) => {
    const { t } = useTranslation();
    const { payment } = props;

    // Callback to send an email about the payment.
    const [emailStatusMessage, setEmailStatusMessage] = useState<string | undefined>();
    const [sendPaymentAvailableEmailMutation, { errors: sendPaymentAvailableEmailErrorsMutation }] = useSendPaymentAvailableEmailMutation();
    const [sendPaymentAvailableEmail, { isExecuting: isSendingPaymentAvailableEmail, errors: sendPaymentAvailableEmailErrors }] = useAsyncCallback(async () => {
        if (!payment) {
            return;
        }

        await sendPaymentAvailableEmailMutation(payment.id);
        setEmailStatusMessage(t('paymentsList.emailSentMessage', 'Payment link email has been sent.'));
    }, [sendPaymentAvailableEmailMutation, setEmailStatusMessage, payment]);

    // Render the UI
    //
    return (
        <>
            <Card body className={`payment-component payment-component-${payment?.paymentState}`}>
                <AlertOnErrors errors={[sendPaymentAvailableEmailErrorsMutation, sendPaymentAvailableEmailErrors]} />

                <div className="payment-component-description">
                    {payment?.name}
                </div>
                <div className="payment-component-amount">
                    {t('common.euroCurrency', '€{{value}}', { value: payment?.totalAmountGross.toFixed(2) })}
                    <> </>
                    {getPaymentStateBadge(payment?.paymentState as PaymentState, t)}
                </div>

                <ConditionalFragment showIf={!!payment?.paidDate}>
                    <div className="payment-component-paid-date">
                        {t('paymentComponent.paidDate', 'Paid on {{dateTime, DD/MM/YYYY HH:mm}}', { dateTime: moment(payment?.paidDate) })}
                    </div>
                </ConditionalFragment>

                <div className="payment-component-actions">
                    {
                        payment?.paymentState === PaymentState.Paid ? (
                            <LinkContainer to={`/administration/children-management/payment-receipt/${payment?.id}`}>
                                <Button color="success" outline>
                                    <FontAwesomeIcon icon="eye" />
                                    <> </>
                                    {t('paymentComponent.viewPayment', 'View receipt')}
                                </Button>
                            </LinkContainer>
                        ) : (
                            <>
                                <LinkContainer to={`/administration/children-management/payment-breakdown/${payment?.id}`}>
                                    <Button color="primary" outline>
                                        <FontAwesomeIcon icon="eye" />
                                        <> </>
                                        {t('paymentComponent.viewBrakdown', 'View breakdown')}
                                    </Button>
                                </LinkContainer>
                                <> </>
                                <ButtonAsync color="primary" outline
                                    isExecuting={isSendingPaymentAvailableEmail} executingChildren={<><Spinner size="sm" /><> </>{t('paymentComponent.sending', 'Sending...')}</>}
                                    onClick={sendPaymentAvailableEmail}
                                >
                                    <FontAwesomeIcon icon="envelope" />
                                    <> </>
                                    {t('paymentComponent.resendPaymentLink', 'Resend payment link')}
                                </ButtonAsync>

                                <ConditionalFragment showIf={payment?.paymentState === PaymentState.Unpaid}>
                                        <LinkContainer to={`/administration/children-management/children/payment/delete/${payment?.id}`} style={{ marginLeft: '4px' }}>
                                            <Button color="danger" outline>
                                                <FontAwesomeIcon icon="trash" />
                                                <> {t('common.delete', 'Delete')}</>
                                            </Button>
                                        </LinkContainer>
                                </ConditionalFragment>
                            </>
                        )
                    }

                    {
                        !!payment?.stripePaymentIntentId ? (
                            <>
                                <> </>
                                <a href={`https://dashboard.stripe.com/payments/${payment?.stripePaymentIntentId}`} target="_blank" rel="noreferrer">
                                    <Button color="secondary" outline>
                                        <FontAwesomeIcon icon="credit-card" />
                                        <> </>
                                        {t('paymentComponent.ViewOnStripe', 'View on Stripe')}
                                    </Button>
                                </a>
                            </>
                        ) : null
                    }
                </div>


                {/* Show a success message when an email notifciation is sent out about a payment */}
                <ConditionalFragment showIf={!!emailStatusMessage}>
                    <Alert color="success" className="mt-2">
                        {emailStatusMessage}
                    </Alert>
                </ConditionalFragment>
            </Card>
        </>
    );
};