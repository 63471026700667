import * as React from 'react';
import { useSaveInStore, SaveInStoreOptions } from '../../../shared/useSaveInStore';
import { useUpdateClassLocationAmenityTypeMutation } from './useUpdateClassLocationAmenityTypeMutation';
import { ClassLocationAmenityType } from '../models/ClassLocationAmenityType';
import { useCreateClassLocationAmenityTypeMutation } from './useCreateClassLocationAmenityTypeMutation';
import { ClassLocationAmenityTypeCreateInput, ClassLocationAmenityTypeUpdateInput } from '../generated/graphql';

/**
 * Get a callback to save a ClassLocationAmenityType in the api using either a create or update.
 */
export function useSaveClassLocationAmenityTypeMutation(options: SaveInStoreOptions<ClassLocationAmenityType, string> = {}) {
    const [_create, createStatus] = useCreateClassLocationAmenityTypeMutation();
    const create = React.useCallback((model: Partial<ClassLocationAmenityType>) => _create(model as ClassLocationAmenityTypeCreateInput), [_create]);

    const [_update, updateStatus] = useUpdateClassLocationAmenityTypeMutation();
    const update = React.useCallback((id: string, changes: Partial<ClassLocationAmenityType>) => _update(id, changes as ClassLocationAmenityTypeUpdateInput), [_update]);

    return useSaveInStore(
        [create, createStatus],
        [update, updateStatus],
        options
    );
}
