import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useCallback, useMemo, useState } from "react";
import { ConditionalFragment } from "react-conditionalfragment";
import { useTranslation } from "react-i18next";
import { Button, ButtonGroup, Col, Row } from "reactstrap";
import { useCurrentUserProfile } from "../../api/main/profiles/useCurrentUserProfile";
import { useStaffQualifcationsTabViewModel } from "../../api/main/staffQualificationChecks/viewModels/useStaffQualificationsTabViewModel";
import { AlertOnErrors } from "../../shared/alertOnErrors";
import { Banner } from "../shared/banner/Banner";
import { LoadingIndicator } from "../shared/loadingIndicator/LoadingIndicator";
import { MainContainer } from "../shared/mainContainer/MainContainer";
import { SearchInput } from "../shared/searchInput/SearchInput";
import { StickyToolbar } from "../shared/stickyToolbar/StickyToolbar";
import { StaffQualifcationCard } from "./cards/StaffQualificationCard";
import { useSearchParams } from '../../shared/useURLSearchParams';
import { useToggleState } from "use-toggle-state";
import { CreateStaffQualifcationCheckModal, EditStaffQualifcationCheckModal } from "./edit/EditStaffQualifcationCheckModal";
import { StaffQualificationCheck } from "../../api/main/models/StaffQualificationCheck";
import { BlobReference } from "../../api/main/models/BlobReference";
import { StyledModal } from "../shared/styledModal/StyledModal";

export interface StaffQualificationWithEvidenceBlobReference extends StaffQualificationCheck {
    evidenceBlobReference?: BlobReference;
}

/**
 * StaffArea for managing StaffQualificationChecks
 */
export const StaffQualificationsTab = () => {
    const { t } = useTranslation();
    const { search: searchParam } = useSearchParams();
    const [search, setSearch] = React.useState<string>(searchParam ?? '');

    // Load the data - Current UserProfile
    const {
        data: {
            model
        }, isLoading: _isLoading, errors: loadErrors,
    } = useCurrentUserProfile();

    // Get the current User's StaffId
    const currentUserStaffId = model?.id;

    // Get all supporting data using the StaffId
    const {
        data: {
            staffQualificationChecks
        }, isLoading: isLoadingSupportingData, errors: supportingDataLoadErrors,
        refresh: refreshStaffQualifications,
    } = useStaffQualifcationsTabViewModel(currentUserStaffId);
    const isLoading = _isLoading || isLoadingSupportingData;

    // Combine any loaded data into single objects
    const allItems = useMemo(() => staffQualificationChecks?.map(item => {

        return {
            ...item
        };
    }), [staffQualificationChecks]);

    // Filters
    const items = useMemo(() => {
        let ret = (allItems ?? []);

        // Sort by ReviewDate
        ret = ret?.sort((a, b) => {
            return new Date(a.reviewDate).getTime() - new Date(b.reviewDate).getTime();
        });

        let lowerSearch = search.toLocaleLowerCase();
        if (lowerSearch) {
            ret = ret.filter(item =>
                item.name.toLocaleLowerCase().indexOf(lowerSearch) >= 0
            );
        }

        return ret;
    }, [allItems, search]);

    // Showing the Create Modal
    const [staffQualificationModalIsOpen, _toggleStaffQualificationModal] = useToggleState();
    const toggleStaffQualificationModal = useCallback(() => {
        _toggleStaffQualificationModal();
        refreshStaffQualifications(); // Trigger a refresh of the StaffQualifications
    }, [_toggleStaffQualificationModal, refreshStaffQualifications]);

    // Showing the Edit modal
    const [idToEdit, setIdToEdit] = useState('');
    const [staffQualificationEditModalIsOpen, _toggleStaffQualificationEditModal] = useToggleState();
    const toggleStaffQualificationEditModal = useCallback(() => {
        _toggleStaffQualificationEditModal();
    }, [_toggleStaffQualificationEditModal]);

    // Handles setting the ID for the StaffAbsence we are looking to Edit. Taken this approach due to not having params due to using Modals.
    const handleEditStaffQualification = (id: string) => {
        setIdToEdit(id);
        _toggleStaffQualificationEditModal();
    };

    // Render the UI
    //
    return (
        <>
            <Banner>
                <StickyToolbar>
                    <Row>
                        <Col>
                            <h1>{t('staffQualifcationsTab.title', 'Qualifications')}</h1>
                        </Col>
                        <ConditionalFragment showIf={isLoading}>
                            <Col xs="auto">
                                <LoadingIndicator size="sm" />
                            </Col>
                        </ConditionalFragment>
                    </Row>
                    <Row>
                        <Col>
                            <SearchInput value={search} onChange={e => setSearch(e.currentTarget.value)} />
                        </Col>
                        <Col xs="auto">
                            <Row>
                                <ButtonGroup>
                                    <Button color="primary" onClick={() => toggleStaffQualificationModal()}>
                                        <FontAwesomeIcon icon="plus" />
                                        <> </>
                                        {t('staffQualifcationsTab.add', 'Add qualification')}
                                    </Button>
                                </ButtonGroup>
                            </Row>
                        </Col>
                    </Row>
                </StickyToolbar>
            </Banner>

            <MainContainer>
                <>
                    <AlertOnErrors
                        errors={[loadErrors, supportingDataLoadErrors]}
                    />

                    {/* Section for all currentStaffMembers StaffQualifcations: Sorted by reviewDate */}
                    {items?.map(item => (
                        <StaffQualifcationCard
                            model={item as StaffQualificationWithEvidenceBlobReference}
                            handleEditStaffQualification={handleEditStaffQualification}
                            key={item.id}
                        />
                    ))}

                    {/* Modals for Create / Edit */}
                    <ConditionalFragment showIf={staffQualificationModalIsOpen}>
                        <StyledModal
                            isOpen={staffQualificationModalIsOpen}
                            toggle={toggleStaffQualificationModal}
                            size="md"                        >
                            <CreateStaffQualifcationCheckModal
                                staffId={currentUserStaffId ?? ''}
                                toggle={toggleStaffQualificationModal}
                            />
                        </StyledModal>
                    </ConditionalFragment>

                    <ConditionalFragment showIf={staffQualificationEditModalIsOpen}>
                        <StyledModal
                            isOpen={staffQualificationEditModalIsOpen}
                            toggle={toggleStaffQualificationEditModal}
                            size="md"                        >
                            <EditStaffQualifcationCheckModal
                                staffId={currentUserStaffId ?? ''}
                                id={idToEdit}
                                toggle={toggleStaffQualificationEditModal}
                            />
                        </StyledModal>
                    </ConditionalFragment>
                </>
            </MainContainer>
        </>
    );
};