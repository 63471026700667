import { useMutation } from "@apollo/react-hooks";
import { useAsyncCallback } from 'react-use-async-callback';
import gql from "graphql-tag";
import { AsyncActionStatus } from '../../../shared/abstractStore';
import { AddToScheduledClassWaitingListMutation, AddToScheduledClassWaitingListMutationVariables } from '../generated/graphql';
import { scheduledClassChildFieldsFragment } from "../models/ScheduledClassChild";

/**
 * Get a callback to delete a ScheduledClassChild in the api.
 */
export function useAddToScheduledClassWaitingListMutation(): [(childId: string, scheduledClassWaitingListId: string) => Promise<void>, AsyncActionStatus] {
    const [mutationAction, { error }] = useMutation<AddToScheduledClassWaitingListMutation, AddToScheduledClassWaitingListMutationVariables>(
        gql`
            mutation addToScheduledClassWaitingListMutation ($childId: ID!, $scheduledClassId: ID!) {
                addToScheduledClassWaitingList(childId: $childId, scheduledClassId: $scheduledClassId) {
                    ...scheduledClassChildFields
                }
            }

            ${scheduledClassChildFieldsFragment}
        `
    );

    // Wrap the mutation in a standard callback.
    const [action, { isExecuting, errors: actionErrors }] = useAsyncCallback(async (childId: string, scheduledClassId: string) => {
        const result = await mutationAction({ variables: { childId, scheduledClassId, } });
        if (result.errors && result.errors.length) {
            throw result.errors;
        }
    }, [mutationAction]);

    return [action, { isExecuting: isExecuting, errors: error ? error.message : actionErrors }];
}
