import * as React from 'react';
import { useSaveInStore, SaveInStoreOptions } from '../../../shared/useSaveInStore';
import { useUpdateChildsRelationshipMutation } from './useUpdateChildsRelationshipMutation';
import { ChildsRelationship } from '../models/ChildsRelationship';
import { useCreateChildsRelationshipMutation } from './useCreateChildsRelationshipMutation';
import { ChildsRelationshipCreateInput, ChildsRelationshipUpdateInput } from '../generated/graphql';

/**
 * Get a callback to save a ChildsRelationship in the api using either a create or update.
 */
export function useSaveChildsRelationshipMutation(options: SaveInStoreOptions<ChildsRelationship, string> = {}) {
    const [_create, createStatus] = useCreateChildsRelationshipMutation();
    const create = React.useCallback((model: Partial<ChildsRelationship>) => _create(model as ChildsRelationshipCreateInput), [_create]);

    const [_update, updateStatus] = useUpdateChildsRelationshipMutation();
    const update = React.useCallback((id: string, changes: Partial<ChildsRelationship>) => _update(id, changes as ChildsRelationshipUpdateInput), [_update]);

    return useSaveInStore(
        [create, createStatus],
        [update, updateStatus],
        options
    );
}
