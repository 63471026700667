import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useCallback, useMemo } from "react";
import { ConditionalFragment } from "react-conditionalfragment";
import { useTranslation } from "react-i18next";
import { Button, Card, CardBody, CardHeader, Col, Input, Row, Table } from "reactstrap";
import { useDashboardSupportingData } from "../../../api/main/dashboard/viewModels/useDashboardSupportingData";
import { useReportDataSourceServiceForClassSizesViewModel } from "../../../api/main/dashboard/viewModels/useReportDataSourceServiceForClassSizesViewModel";
import { useCurrentTerm } from "../../../api/main/terms/useCurrentTerm";
import { AlertOnErrors } from "../../../shared/alertOnErrors";
import { useLocalStorage } from "../../../shared/useLocalStorage";
import { ClassScheduleFilterState } from "../../scheduledClass/schedule/ClassSchedule";
import { LoadingIndicator } from "../../shared/loadingIndicator/LoadingIndicator";
import { NoResultsFound } from "../../shared/noResultsFound/NoResultsFound";
import { TermSelector } from "../../shared/termSelector/TermSelector";
import { ClassSizesBarChart } from "../charts/ClassSizesBarChart";



/**
 * ClassSizesTab for Dashboard
 */
export const ClassSizesTab = () => {
    const { t } = useTranslation();

    // Load the current term
    const {
        data: {
            model: currentTerm
        }, isLoading: isLoadingCurrentTerm, errors: currentTermLoadErrors
    } = useCurrentTerm();

    // Load the supporting data - filters
    const {
        data: {
            classTypes,
            classStages,
            classLocations
        }, isLoading: isLoadingSupportingData, errors: supportingDataLoadErrors
    } = useDashboardSupportingData();

    // Allow filtering (persisted to local storage between sessions).
    const [filterState, setFilterState] = useLocalStorage<ClassScheduleFilterState>('classSchedule.filterState', {});
    const changeFilterState = useCallback((changes: Partial<ClassScheduleFilterState>) => setFilterState(prevState => ({
        ...prevState,
        ...changes,
    })), [setFilterState]);

    // Filter the items that can be filtered by other filter choices.
    const classStagesForFilter = useMemo(() => classStages?.filter(item => item.classTypeId === filterState.classTypeId), [classStages, filterState]);

    // Showing and hiding UI elements (persisted to local storage between sessions).
    const [isFiltersVisible, _setIsFiltersVisible] = useLocalStorage('classSchedule.isFiltersVisible', false);
    const toggleFiltersVisible = useCallback(() => _setIsFiltersVisible(prevState => !prevState), [_setIsFiltersVisible]);
    const resetFilters = useCallback(() => setFilterState({ termId: currentTerm?.id }), [setFilterState, currentTerm?.id]);

    // Load the data
    const {
        data: {
            classSizesData,
        }, isLoading: _isLoading, errors: loadErrors
    } = useReportDataSourceServiceForClassSizesViewModel(filterState.termId, filterState.classTypeId, filterState.classStageId, filterState.classLocationId);
    const isLoading = isLoadingSupportingData || _isLoading || isLoadingCurrentTerm;

    // Sort the data to show the highest utilized classes first
    const sortedClassSizesData = useMemo(() => {
        if (!classSizesData) return;

        let ret = ([...classSizesData] ?? []);
        return ret.sort((a, b) => {
            if (a.classUtilizationPercentage === b.classUtilizationPercentage) {
                return 0;
            } else if (a.classUtilizationPercentage > b.classUtilizationPercentage) {
                return -1;
            } else {
                return 1;
            }
        });
    }, [classSizesData]);

    // Render the UI
    //
    return (
        <>
            {/* Alert on errors */}
            <AlertOnErrors
                errors={[
                    loadErrors,
                    supportingDataLoadErrors,
                    currentTermLoadErrors
                ]}
            />

            {/* Filters */}
            <ConditionalFragment showIf={isFiltersVisible}>
                <Card className="mb-2">
                    <CardHeader>
                        <h5>{t('dashboard.classSizesTab.filters.heading', 'Filter')}</h5>
                    </CardHeader>
                    <CardBody>
                        <Row>
                            <Col>
                                <Input type="select" value={filterState.classTypeId ?? ''} onChange={e => changeFilterState({ classTypeId: e.currentTarget.value || null, })}>
                                    <option value="">{t('dashboard.classSizesTab.filter.classTypes.all', '(All activities)')}</option>
                                    {
                                        classTypes?.map(item => (
                                            <option key={item.id} value={item.id}>{item.name}</option>
                                        ))
                                    }
                                </Input>
                            </Col>
                            <ConditionalFragment showIf={filterState.classTypeId !== null}>
                                <Col>
                                    <Input type="select" value={filterState.classStageId ?? ''} onChange={e => changeFilterState({ classStageId: e.currentTarget.value || null, })}>
                                        <option value="">{t('dashboard.classSizesTab.filter.classStages.all', '(All class types)')}</option>
                                        {
                                            classStagesForFilter?.map(item => (
                                                <option key={item.id} value={item.id}>{item.name}</option>
                                            ))
                                        }
                                    </Input>
                                </Col>
                            </ConditionalFragment>
                            <Col>
                                <Input type="select" value={filterState.classLocationId ?? ''} onChange={e => changeFilterState({ classLocationId: e.currentTarget.value || null })}>
                                    <option value="">{t('dashboard.classSizesTab.filter.classLocations.all', '(All locations)')}</option>
                                    {
                                        classLocations?.map(item => (
                                            <option key={item.id} value={item.id}>{item.name}</option>
                                        ))
                                    }
                                </Input>
                            </Col>
                        </Row>
                        <Button color="link" onClick={resetFilters}>
                            {t('dashboard.classSizesTab.filters.reset', 'Reset filters')}
                        </Button>
                    </CardBody>
                </Card>
            </ConditionalFragment>

            {/* Term toggle */}
            <Row>
                <Col>
                    <Button color="primary" outline={!isFiltersVisible} onClick={() => toggleFiltersVisible()}>
                        <FontAwesomeIcon icon="filter" />
                        <> </>
                        {t('dashboard.classSizesTab.toggleFilters', 'Filter')}
                    </Button>
                </Col>

                <Col xs="auto">
                    <TermSelector selectedTermId={filterState.termId} setFilterState={({ termId: value }) => setFilterState({ termId: value })} restrictToTermsWithClasses={true} showPreviousTerms={true} />
                </Col>
            </Row>

            {/* Loading indicator */}
            <ConditionalFragment showIf={!!isLoading}>
                <LoadingIndicator />
            </ConditionalFragment>

            {/* No results found */}
            <ConditionalFragment showIf={!isLoading && !sortedClassSizesData?.length}>
                <NoResultsFound />
            </ConditionalFragment>

            {/* Render the Chart and our Table of data */}
            <ConditionalFragment showIf={!isLoading && !!sortedClassSizesData && !!sortedClassSizesData.length}>
                <ClassSizesBarChart chartData={sortedClassSizesData} />

                {/* Export button */}
                <Row>
                    <Col>
                    </Col>

                    <Col xs="auto">
                        <Button color="link">
                            <a className="btn btn-outline-primary" href={`/api/exportreports/exportclasssizes?format=${encodeURIComponent('xlsx')}&termId=${filterState.termId}`} download>
                                <FontAwesomeIcon icon="download" />
                                <> </>
                                {t('dashboard.classSizesTab.download', 'Export')}
                            </a>
                        </Button>
                    </Col>
                </Row>

                {/* Table data */}
                <Table responsive striped>
                    <thead>
                        <tr>
                            <th>{t('dashboard.classSizesTab.classDetails.heading', 'Class details')}</th>
                            <th>{t('dashboard.classSizesTab.repliesWithinOneDay.heading', 'Attending')}</th>
                            <th>{t('dashboard.classSizesTab.repliesWithinTwoDays.heading', 'Spaces')}</th>
                            <th>{t('dashboard.classSizesTab.repliesWithinThreeDays.heading', 'Waiting list')}</th>
                            <th>{t('dashboard.classSizesTab.repliesWithinSevenDays.heading', 'Occupancy %')}</th>
                            <th>{t('dashboard.classSizesTab.repliesOverAWeek.heading', 'Occupany level')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            sortedClassSizesData?.map(sortedClassSizeData => (
                                <tr key={sortedClassSizeData?.classDetails}>
                                    <td>
                                        {sortedClassSizeData?.classDetails}
                                    </td>

                                    <td>
                                        {sortedClassSizeData?.attending}
                                    </td>

                                    <td>
                                        {sortedClassSizeData?.spaces}
                                    </td>

                                    <td>
                                        {sortedClassSizeData?.waitingList}
                                    </td>

                                    <td>
                                        {sortedClassSizeData?.classUtilizationPercentage}
                                    </td>

                                    <td>
                                        {sortedClassSizeData?.classUtilization}
                                    </td>
                                </tr>
                            ))
                        }
                    </tbody>
                </Table>
            </ConditionalFragment>
        </>
    );
};