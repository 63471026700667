import moment from "moment";
import { useTranslation } from "react-i18next";
import { Table } from "reactstrap";
import { TableDataItem } from "../EditTerm";
import "./datesTable.scss";

export interface DatesTableProps {
    tableData: Array<TableDataItem>,
    setTableData: (tableData: Array<TableDataItem>) => void
};

/**
 * Component that renders a table of dates withing a term, and allows the user to move dates between lesson dates and holiday dates
 */
export const DatesTable = (props: DatesTableProps) => {
    const { t } = useTranslation();
    const {
        tableData,
        setTableData
    } = props;

    // Move a date from lessonDates to holidayDates
    const moveDateToHolidays = (dayOfWeek: string, date: Date) => {
        const updatedData = tableData.map(entry => {
            if (entry.dayOfWeek === dayOfWeek) {
                entry.lessonDates = entry.lessonDates.filter(lessonDate => lessonDate !== date);

                // Insert the date into the holidayDates array in the correct position
                const insertionIndex = findInsertionIndex(entry.holidayDates, date);
                entry.holidayDates.splice(insertionIndex, 0, date);

                // Set changed to be true - used for tracking unsaved changes
                entry.changed = true;
            }
            return entry;
        });

        // Update the state or do any necessary side effects
        setTableData(updatedData);
    };

    // Move a date from holidayDates to lessonDates
    const moveDateToLessons = (dayOfWeek: string, date: Date) => {
        const updatedData = tableData.map(entry => {
            if (entry.dayOfWeek === dayOfWeek) {
                entry.holidayDates = entry.holidayDates.filter(holidayDate => holidayDate !== date);

                // Insert the date into the lessonDates array in the correct position
                const insertionIndex = findInsertionIndex(entry.lessonDates, date);
                entry.lessonDates.splice(insertionIndex, 0, date);

                // Set changed to be true - used for tracking unsaved changes
                entry.changed = true;
            }

            return entry;
        });

        // Update the state or do any necessary side effects
        setTableData(updatedData);
    };

    // Find the index to insert a date at
    const findInsertionIndex = (dates: Date[], newDate: Date) => {
        const parsedNewDate = new Date(newDate);

        // Loop through the dates and return the index of the first date that is later than the new date
        for (let i = 0; i < dates.length; i++) {
            const parsedDate = new Date(dates[i]);
            if (parsedDate > parsedNewDate) {
                return i;
            }
        }

        return dates.length; // Insert at the end if no earlier date is found
    };

    // Render the UI
    //
    return (
        <>
            <Table responsive striped className="dates-table">
                <thead>
                    <tr>
                        <th>{t('common.day', 'Day')}</th>
                        <th>{t('common.lessonDates', 'Lesson dates')}</th>
                        <th>{t('common.lessonWeeks', 'Lesson weeks')}</th>
                        <th>{t('common.holidayDates', 'Holiday dates')}</th>
                        <th>{t('common.holidayWeeks', 'Holiday weeks')}</th>
                    </tr>
                </thead>

                <tbody>
                    {tableData?.map((row, index) => (
                        <tr key={index}>
                            <td>{row.dayOfWeek}</td>
                            <td>
                                {row.lessonDates.map(date => (
                                    <span className="dates-table-lessonDate" key={date.toString()} onClick={() => moveDateToHolidays(row.dayOfWeek, date)}>
                                        {moment(date).format('DD MMMM')},{' '}
                                    </span>
                                ))}
                            </td>
                            <td>{row.lessonDates.length}</td>
                            <td>
                                {row.holidayDates.map(date => (
                                    <span className="dates-table-holidayDate" key={date.toString()} onClick={() => moveDateToLessons(row.dayOfWeek, date)}>
                                        {moment(date).format('DD MMMM')},{' '}
                                    </span>
                                ))}
                            </td>
                            <td>{row.holidayDates.length}</td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </>
    );
};