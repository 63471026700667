import { IdentityRoles } from "../../configure/security/IdentityRoles";
import { RouteEntry } from "../../shared/routes";
import { ClassTypesList } from "./ClassTypesList";
import { CreateClassType, EditClassType } from './EditClassType'
import { DeleteClassType } from './DeleteClassType'

/**
 * Routes that should be added to configuration/routeConfig.ts to provide access to these components via routing.
 */
export const classTypesRoutes: Array<RouteEntry> = [
    { path: '/administration/class-management/class-types/add', element: <CreateClassType />, authorize: true, requireRole: IdentityRoles.Administration },
    { path: '/administration/class-management/class-types/edit/:id', element: <EditClassType />, authorize: true, requireRole: IdentityRoles.Administration },
    { path: '/administration/class-management/class-types/delete/:id', element: <DeleteClassType />, authorize: true, requireRole: IdentityRoles.Administration },
    { path: '/administration/class-management/class-types', element: <ClassTypesList />, authorize: true, requireRole: IdentityRoles.Administration }
];
