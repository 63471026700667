import { useLazyQuery } from "@apollo/client";
import gql from "graphql-tag";
import { Guid } from "guid-string";
import React from "react";
import { mainApiConfig } from "../../../configure/mainApiConfig";
import { AsyncLoadOptions, AsyncLoadResult } from "../../../shared/abstractStore";
import { ChildAbsencesForScheduledClassViewModelQuery, ChildAbsencesForScheduledClassViewModelQueryVariables } from '../generated/graphql';
import { childAbsenceFieldsFragment } from "../models/ChildAbsence";
import { childAbsenceTypeFieldsFragment } from "../models/ChildAbsenceType";


export function useChildAbsencesForScheduledClassViewModel(scheduledClassId: string | undefined | null, options: AsyncLoadOptions = {}): AsyncLoadResult<ChildAbsencesForScheduledClassViewModelQuery> {
    // Query the data we need from the api
    const [load, { data, loading, error, refetch, }] = useLazyQuery<ChildAbsencesForScheduledClassViewModelQuery, ChildAbsencesForScheduledClassViewModelQueryVariables>(
        gql`
        query childAbsencesForScheduledClassViewModelQuery ($scheduledClassId: ID!) {
            items: childAbsences (scheduledClassId: $scheduledClassId) {
                ...childAbsenceFields
            }

            childAbsenceTypes {
                ...childAbsenceTypeFields
            }
        }

        ${childAbsenceFieldsFragment}
        ${childAbsenceTypeFieldsFragment}
        `,
        {

            variables: {
                scheduledClassId: scheduledClassId ?? Guid.empty,
            },
            fetchPolicy: options.fetchPolicy ?? mainApiConfig.defaultFetchPolicy,
            pollInterval: options.pollInterval,
            onCompleted: (data) => {
                if (options.onCompleted) {
                    options.onCompleted(data);
                }
            }
        }
    );

    // Run the query (unless we are being lazy).
    React.useEffect(() => {
        if (!options.lazy) {
            load();
        }
    }, [options.lazy, load]);

    // Return the results.
    const refresh = React.useCallback(async () => { !refetch ? await load() : await refetch(); }, [refetch, load]);
    return React.useMemo(() => ({
        data: data ?? ({} as any),
        refresh: refresh,
        isLoading: loading,
        errors: error,
    }), [data, refresh, loading, error,]);
};