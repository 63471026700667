import gql from 'graphql-tag';
import { Guid } from 'guid-string';
import moment from 'moment';
import { ChildAbsenceFieldsFragment } from '../generated/graphql';

export const childAbsenceFieldsFragment = gql`
    fragment childAbsenceFields on ChildAbsence {
        id
        childId
        childAbsenceTypeId
        scheduledClassId
        startDate
        endDate
        submittedDate
        receivedCourtesyClassCredit
        archived
    }
`;

export type ChildAbsence = Omit<ChildAbsenceFieldsFragment, '__typename'>;

/**
 * Default values.
 */
export const childAbsenceDefaultValues = (): Partial<ChildAbsence> => ({
    id: Guid.newGuid(),
    childId: undefined,
    childAbsenceTypeId: undefined,
    scheduledClassId: undefined,
    startDate: moment().startOf('day').toISOString(),
    endDate: moment().startOf('day').add('day', 7).toISOString(),
    submittedDate: moment().toISOString(),
    receivedCourtesyClassCredit: false,
    archived: false,
});