import { gql, useLazyQuery } from "@apollo/client";
import { Guid } from "guid-string";
import React from "react";
import { mainApiConfig } from "../../../../configure/mainApiConfig";
import { AsyncLoadOptions, AsyncLoadResult } from "../../../../shared/abstractStore";
import { childAttendanceFieldsFragment } from "../../models/ChildAttendance";
import { EditChildAttendancesViewModelQuery, EditChildAttendancesViewModelQueryVariables } from '../../generated/graphql';

/**
 * Get a list of ChildAttendances for a ScheduledClassId and given date
 */
export function useEditChildAttendancesViewModel(scheduledClassId: string | undefined | null, lessonDate?: string | undefined | null, options: AsyncLoadOptions = {}):
    AsyncLoadResult<EditChildAttendancesViewModelQuery> {
    // Query data we need from the api.
    const [load, { data, loading, error, refetch, }] = useLazyQuery<EditChildAttendancesViewModelQuery, EditChildAttendancesViewModelQueryVariables>(
        gql`
        query editChildAttendancesViewModelQuery ($scheduledClassId: ID, $lessonDate: DateTimeOffset) {
            items: childAttendances (scheduledClassId: $scheduledClassId, lessonDate: $lessonDate) {
                ...childAttendanceFields
            }
        }

        ${childAttendanceFieldsFragment}
        `,
        {

            variables: {
                scheduledClassId: scheduledClassId ?? Guid.empty,
                lessonDate: lessonDate ?? ''
            },
            fetchPolicy: options.fetchPolicy ?? mainApiConfig.defaultFetchPolicy,
            pollInterval: options.pollInterval,
            onCompleted: (data) => {
                if (options.onCompleted) {
                    options.onCompleted(data);
                }
            }
        }
    );

    // Run the query (unless we are being lazy).
    React.useEffect(() => {
        if (!options.lazy) {
            load();
        }
    }, [options.lazy, load]);

    // Return the results.
    const refresh = React.useCallback(async () => { !refetch ? await load() : await refetch(); }, [refetch, load]);
    return React.useMemo(() => ({
        data: data ?? ({} as any),
        refresh: refresh,
        isLoading: loading,
        errors: error,
    }), [data, refresh, loading, error,]);
    };