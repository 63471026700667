import { IdentityRoles } from '../../configure/security/IdentityRoles';
import { RouteEntry } from '../../shared/routes';
import { ChildAbsenceTypesList } from './ChildAbsenceTypesList';
import { DeleteChildAbsenceType } from './DeleteChildAbsenceType';
import { CreateChildAbsenceType, EditChildAbsenceType } from './EditChildAbsenceType';

/**
 * Routes that should be added to configuration/routeConfig.ts to provide access to these components via routing.
 */
export const childAbsenceTypesRoutes: Array<RouteEntry> = [
    { path: '/administration/children-management/absence-types/add', element: <CreateChildAbsenceType />, authorize: true, requireRole: IdentityRoles.Administration },
    { path: '/administration/children-management/absence-types/edit/:id', element: <EditChildAbsenceType />, authorize: true, requireRole: IdentityRoles.Administration },
    { path: '/administration/children-management/absence-types/delete/:id', element: <DeleteChildAbsenceType />, authorize: true, requireRole: IdentityRoles.Administration },
    { path: '/administration/children-management/absence-types', element: <ChildAbsenceTypesList />, authorize: true, requireRole: IdentityRoles.Administration }
];
