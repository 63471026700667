import { Form, Label, FormGroup, Row, Col, Button, InputGroup, Input, FormText } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { ValidateCallback } from 'pojo-validator-react';
import { ValidatedInput } from 'pojo-validator-reactstrap';
import { ScheduledClass } from '../../../../api/main/models/ScheduledClass';
import { ValidationErrors } from 'pojo-validator';
import { ClassType } from '../../../../api/main/models/ClassType';
import { ClassStage } from '../../../../api/main/models/ClassStage';
import { ClassSubStage } from '../../../../api/main/models/ClassSubStage';
import { ClassLocation } from '../../../../api/main/models/ClassLocation';
import { ClassLocationClassType } from '../../../../api/main/models/ClassLocationClassType';
import { useMemo } from 'react';
import { dayOfWeekDisplayName, getDayOfWeeks } from '../../../../api/main/models/constants/DayOfWeek';
import { HappyIcon } from '../../../shared/Utilities/HappyIcon';
import { Term } from '../../../../api/main/models/Term';
import moment from 'moment';

export interface MainTabProps {
    model: ScheduledClass | null | undefined,
    change: (changes: Partial<ScheduledClass>) => void,
    validate: ValidateCallback,
    validationErrors: ValidationErrors,
    saveForm: () => Promise<void>,

    terms: Array<Term> | undefined | null,
    classTypes: Array<ClassType> | undefined | null,
    classStages: Array<ClassStage> | undefined | null,
    classSubStages: Array<ClassSubStage> | undefined | null,
    classLocations: Array<ClassLocation> | undefined | null,
    classLocationClassTypes: Array<ClassLocationClassType> | undefined | null,
}

/**
 * MainTab for EditScheduledClass.
 */
export const MainTab = (props: MainTabProps) => {
    const {
        model,
        change,
        validate,
        validationErrors,
        saveForm,

        terms,
        classTypes,
        classStages: allClassStages,
        classSubStages: allClassSubStages,
        classLocations: allClassLocations,
        classLocationClassTypes,
    } = props;
    const { t } = useTranslation();

    // Filter the class stages by the ClassType.
    const classStages = useMemo(() => allClassStages?.filter(item => item.classTypeId === model?.classTypeId), [allClassStages, model]);
    const currentClassSubStages = useMemo(() => allClassSubStages?.filter(item => item.classStageId === model?.currentClassStageId), [allClassSubStages, model]);

    const classLocations = useMemo(() => allClassLocations?.filter(location => !!classLocationClassTypes?.find(link => link.classLocationId === location.id && link.classTypeId === model?.classTypeId)), [allClassLocations, classLocationClassTypes, model]);

    // Caculate the total termly cost.
    // NOTE because this is floating point maths, the numbers will be inaccurate until we round them.
    const totalTermlyCost = ((model?.costPerLesson ?? 0.0) * (model?.lessonsPerTerm ?? 0));

    const selectedTerm = useMemo(() => {
        if (!model?.termId) return null;

        return {
            startDate: terms?.find(it => it.id === model?.termId)?.startDate,
            endDate: terms?.find(it => it.id === model?.termId)?.endDate,
        };
    }, [model?.termId, terms]);

    // Render the UI
    //
    return (
        <>
            <Form onSubmit={e => { e.preventDefault(); saveForm(); }}>
                <Row>
                    <Col>
                        <FormGroup>
                            <Label htmlFor="classTypeId">{t('editScheduledClass.mainTab.classTypeId.label', 'Activity')}</Label>
                            <ValidatedInput name="classTypeId" type="select" value={model?.classTypeId ?? ''} onChange={e => change({ classTypeId: e.currentTarget.value })} onBlur={e => validate('classTypeId')} validationErrors={validationErrors['classTypeId']}>
                                <option value="">{t('editScheduledClass.mainTab.classTypeId.pleaseSelect', '(Please select an activity)')}</option>
                                {
                                    classTypes?.map(item => (
                                        <option key={item.id} value={item.id}>{item.name}</option>
                                    ))
                                }
                            </ValidatedInput>
                        </FormGroup>
                    </Col>

                    <Col>
                        <FormGroup>
                            <Label htmlFor="termId">{t('editScheduledClass.mainTab.termId.label', 'Term')}</Label>
                            <ValidatedInput name="termId" type="select" value={model?.termId ?? ''} onChange={e => change({ termId: e.currentTarget.value })} onBlur={e => validate('termId')} validationErrors={validationErrors['termId']}>
                                <option value="">{t('editScheduledClass.mainTab.termId.pleaseSelect', '(Please select a term)')}</option>
                                {
                                    terms?.map(item => (
                                        <option key={item.id} value={item.id}>{item.name}</option>
                                    ))
                                }
                            </ValidatedInput>
                            <FormText color="secondary">
                                { t('common.dates', 'Dates:')} {t('common.date', '{{date, DD/MM/YYYY}}', { date: moment(selectedTerm?.startDate) })} - {t('common.date', '{{date, DD/MM/YYYY}}', { date: moment(selectedTerm?.endDate) })}
                            </FormText>
                        </FormGroup>
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <FormGroup>
                            <Label htmlFor="classLocationId">{t('editScheduledClass.mainTab.classLocationId.label', 'Location')}</Label>
                            <ValidatedInput name="classLocationId" type="select" value={model?.classLocationId ?? ''} onChange={e => change({ classLocationId: e.currentTarget.value })} onBlur={e => validate('classLocationId')} validationErrors={validationErrors['classLocationId']}>
                                <option value="">{t('editScheduledClass.mainTab.classLocationId.pleaseSelect', '(Please select a location)')}</option>
                                {
                                    classLocations?.map(item => (
                                        <option key={item.id} value={item.id}>{item.name}</option>
                                    ))
                                }
                            </ValidatedInput>
                        </FormGroup>
                    </Col>
                    <Col>
                        <FormGroup>
                            <Label htmlFor="maximumClassSize">{t('editScheduledClass.mainTab.maximumClassSize.label', 'Maximum class size')}</Label>
                            <ValidatedInput name="maximumClassSize" type="number" value={model?.maximumClassSize ?? 0} onChange={e => change({ maximumClassSize: e.currentTarget.valueAsNumber })} onBlur={e => validate('maximumClassSize')} validationErrors={validationErrors['maximumClassSize']} />
                        </FormGroup>
                    </Col>
                </Row>

                {/* Day and time */}
                <h4>{t('editScheduledClass.mainTab.schedulingHeading', 'Scheduling')}</h4>
                <Row>
                    <Col>
                        <FormGroup>
                            <Label htmlFor="dayOfWeek">{t('editScheduledClass.mainTab.dayOfWeek.label', 'Day')}</Label>
                            <ValidatedInput name="dayOfWeek" type="select" value={model?.dayOfWeek ?? ''} onChange={e => change({ dayOfWeek: parseInt(e.currentTarget.value) })} onBlur={e => validate('dayOfWeek')} validationErrors={validationErrors['dayOfWeek']}>
                                <option value="99">{t('editScheduledClass.mainTab.dayOfWeek.pleaseSelect', '(Please select a day)')}</option>
                                {
                                    getDayOfWeeks().map(item => (
                                        <option key={item} value={item}>{dayOfWeekDisplayName(item, t)}</option>
                                    ))
                                }
                            </ValidatedInput>
                        </FormGroup>
                    </Col>
                    <Col md="auto">
                        <FormGroup>
                            <Label htmlFor="startTimeHours">{t('editScheduledClass.mainTab.startTimeHours.label', 'Start time')}</Label>
                            <div>
                                <ValidatedInput style={{ display: 'inline-block', maxWidth: '60px', textAlign: 'right', }} name="startTimeHours" type="number" min={0} max={23} value={model?.startTimeHours ?? 0} onChange={e => change({ startTimeHours: e.currentTarget.valueAsNumber })} onBlur={e => validate('startTimeHours')} validationErrors={validationErrors['startTimeHours']} />
                                :
                                <ValidatedInput style={{ display: 'inline-block', maxWidth: '60px', }} name="startTimeMinutes" type="number" min={0} max={59} value={model?.startTimeMinutes ?? 0} onChange={e => change({ startTimeMinutes: e.currentTarget.valueAsNumber })} onBlur={e => validate('startTimeMinutes')} validationErrors={validationErrors['startTimeMinutes']} />
                            </div>
                        </FormGroup>
                    </Col>
                    <Col>
                        <FormGroup>
                            <Label htmlFor="endTimeHours">{t('editScheduledClass.mainTab.endTimeHours.label', 'End time')}</Label>
                            <div>
                                <ValidatedInput style={{ display: 'inline-block', maxWidth: '60px', textAlign: 'right', }} name="endTimeHours" type="number" min={0} max={23} value={model?.endTimeHours ?? 0} onChange={e => change({ endTimeHours: e.currentTarget.valueAsNumber })} onBlur={e => validate('endTimeHours')} validationErrors={validationErrors['endTimeHours']} />
                                :
                                <ValidatedInput style={{ display: 'inline-block', maxWidth: '60px', }} name="endTimeMinutes" type="number" min={0} max={59} value={model?.endTimeMinutes ?? 0} onChange={e => change({ endTimeMinutes: e.currentTarget.valueAsNumber })} onBlur={e => validate('endTimeMinutes')} validationErrors={validationErrors['endTimeMinutes']} />
                            </div>
                        </FormGroup>
                    </Col>
                </Row>

                {/* Costs */}
                <h4>{t('editScheduledClass.mainTab.costsHeading', 'Costs')}</h4>
                <Row>
                    <Col>
                        <FormGroup>
                            <Label htmlFor="costPerLesson">{t('editScheduledClass.mainTab.costPerLesson.label', 'Cost per class')}</Label>
                            <InputGroup>
                                <Button color="secondary" onClick={(e) => e.preventDefault()}><HappyIcon icon="euro" /></Button>
                                <ValidatedInput name="costPerLesson" type="number" value={model?.costPerLesson ?? 0} onChange={e => change({ costPerLesson: e.currentTarget.valueAsNumber })} onBlur={e => validate('costPerLesson')} validationErrors={validationErrors['costPerLesson']} />
                            </InputGroup>
                        </FormGroup>
                    </Col>
                    <Col>
                        <FormGroup>
                            <Label htmlFor="lessonsPerTerm">{t('editScheduledClass.mainTab.lessonsPerTerm.label', 'Classes per term')}</Label>
                            <ValidatedInput name="lessonsPerTerm" type="number" value={model?.lessonsPerTerm ?? 0} onChange={e => change({ lessonsPerTerm: e.currentTarget.valueAsNumber })} onBlur={e => validate('lessonsPerTerm')} validationErrors={validationErrors['lessonsPerTerm']} />
                        </FormGroup>
                    </Col>
                    <Col>
                        <FormGroup>
                            <Label htmlFor="totalTermlyCost">{t('editScheduledClass.mainTab.totalTermlyCost', 'Term cost')}</Label>
                            <InputGroup>
                                <Button color="secondary" onClick={(e) => e.preventDefault()}><HappyIcon icon="euro" /></Button>
                                <Input readOnly disabled name="totalTermlyCost" type="number" value={totalTermlyCost.toFixed(2)} />
                            </InputGroup>
                        </FormGroup>
                    </Col>
                </Row>

                <Row>
                    {/* Current details of the class */}
                    <Col>
                        <h4>{t('editScheduledClass.mainTab.classDetailsHeading', 'Class details')}</h4>
                        <FormGroup>
                            <Label htmlFor="classTypeId">{t('editScheduledClass.mainTab.currentClassStageId.label', 'Class type')}</Label>
                            <ValidatedInput name="classStageId" type="select" value={model?.currentClassStageId ?? ''}
                                onChange={e => change({ currentClassStageId: e.currentTarget.value || null /* optional foreign key */, currentClassSubStageId: model?.currentClassStageId === '' ? '' : null })}
                                onBlur={e => validate('currentClassStageId')} validationErrors={validationErrors['currentClassStageId']}>
                                <option value="">{t('editScheduledClass.mainTab.currentClassStageId.pleaseSelect', '(Class not running in the current term)')}</option>
                                {
                                    classStages?.map(item => (
                                        <option key={item.id} value={item.id}>{item.name}</option>
                                    ))
                                }
                            </ValidatedInput>
                        </FormGroup>
                        <FormGroup>
                            <Label htmlFor="classTypeId">{t('editScheduledClass.mainTab.currentClassSubStageId.label', 'Stage')}</Label>
                            <ValidatedInput name="currentClassSubStageId" type="select" value={model?.currentClassSubStageId ?? ''}
                                onChange={e => change({ currentClassSubStageId: e.currentTarget.value || null /* optional foriegn key */ })}
                                onBlur={e => validate('currentClassSubStageId')} validationErrors={validationErrors['currentClassSubStageId']}>
                                <option value="">{t('editScheduledClass.mainTab.currentClassSubStageId.pleaseSelect', '(Class not running in the current term)')}</option>
                                {
                                    currentClassSubStages?.map(item => (
                                        <option key={item.id} value={item.id}>{item.name}</option>
                                    ))
                                }
                            </ValidatedInput>
                        </FormGroup>
                        <FormGroup>
                            <Label htmlFor="currentLessonNumber">{t('editScheduledClass.mainTab.currentLessonNumber.label', 'Current lesson no.')}</Label>
                            <ValidatedInput name="currentLessonNumber" type="number" value={model?.currentLessonNumber ?? 0} onChange={e => change({ currentLessonNumber: e.currentTarget.valueAsNumber })} onBlur={e => validate('currentLessonNumber')} validationErrors={validationErrors['currentLessonNumber']} />
                        </FormGroup>
                    </Col>
                </Row>
            </Form>
        </>
    );
};
