import { useMutation } from "@apollo/react-hooks";
import { useAsyncCallback } from 'react-use-async-callback';
import gql from "graphql-tag";
import { AsyncActionStatus } from '../../../shared/abstractStore';
import { UpdateGeneralDiscountMutation, UpdateGeneralDiscountMutationVariables, GeneralDiscountUpdateInput } from '../generated/graphql';
import { generalDiscountFieldsFragment } from '../models/GeneralDiscount';

/**
 * Get a callback to update a GeneralDiscount in the api.
 */
export function useUpdateGeneralDiscountMutation(): [(id: string, model: GeneralDiscountUpdateInput) => Promise<void>, AsyncActionStatus] {
    const [mutationAction, { error }] = useMutation<UpdateGeneralDiscountMutation, UpdateGeneralDiscountMutationVariables>(
        gql`
            mutation updateGeneralDiscountMutation ($model: GeneralDiscountUpdateInput!) {
                updateGeneralDiscount(model: $model) {
                    ...generalDiscountFields
                }
            }

            ${generalDiscountFieldsFragment}
        `
    );

    // Wrap the mutation in a standard callback.
    const [action, { isExecuting, errors: actionErrors }] = useAsyncCallback(async (id: string, model: GeneralDiscountUpdateInput) => {
        const result = await mutationAction({ variables: { model: { ...model, id: id } } });
        if (result.errors && result.errors.length) {
            throw result.errors;
        }
    }, [mutationAction]);

    return [action, { isExecuting: isExecuting, errors: error ? error.message : actionErrors }];
}
