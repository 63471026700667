import { useLazyQuery } from "@apollo/client";
import gql from "graphql-tag";
import React from "react";
import { mainApiConfig } from "../../../../configure/mainApiConfig";
import { AsyncLoadPagedOptions, AsyncLoadPagedResult } from "../../../../shared/abstractStore";
import { useFetchMoreCallback } from "../../../../shared/useFetchMoreCallback";
import { classLocationFieldsFragment } from "../../models/ClassLocation";
import { classStageFieldsFragment } from "../../models/ClassStage";
import { classSubStageFieldsFragment } from "../../models/ClassSubStage";
import { classTypeFieldsFragment } from "../../models/ClassType";
import { ClassStagesListViewModelQuery, ClassStagesListViewModelQueryVariables } from "../../generated/graphql";

/**
 * Get all data required for the ScheduledClassesList component from the api.
 * @param options
 */
export function useClassStagesListViewModel(options: AsyncLoadPagedOptions = {}): AsyncLoadPagedResult<ClassStagesListViewModelQuery> {
    // Query data we need from the api.
    const [load, { data, loading, error, refetch, fetchMore: fetchMoreFromStore }] = useLazyQuery<ClassStagesListViewModelQuery, ClassStagesListViewModelQueryVariables>(
        gql`
        query classStagesListViewModelQuery ($offset: Int, $first: Int) {
            items: classStages (offset: $offset, first: $first) {
                ...classStageFields
            }

            classTypes {
                ...classTypeFields
            }

            classStages {
                ...classStageFields
            }

            classSubStages {
                ...classSubStageFields
            }

            classLocations {
                ...classLocationFields
            }
        }

        ${classStageFieldsFragment}
        ${classTypeFieldsFragment}
        ${classStageFieldsFragment}
        ${classSubStageFieldsFragment}
        ${classLocationFieldsFragment}
        `,
        {

            variables: {
                offset: options.startOffset,
                first: options.pageSize,
            },
            fetchPolicy: options.fetchPolicy ?? mainApiConfig.defaultFetchPolicy,
            pollInterval: options.pollInterval,
            onCompleted: (data) => {
                if (options.onCompleted) {
                    options.onCompleted(data);
                }
            }
        }
    );

    // Run the query (unless we are being lazy).
    React.useEffect(() => {
        if (!options.lazy) {
            load();
        }
    }, [options.lazy, load]);

    // Get the functions used to manage paging.
    const { fetchMore, hasMore: _hasMore } = useFetchMoreCallback<ClassStagesListViewModelQuery>(
        fetchMoreFromStore,
        (options?.startOffset ?? 0) + (data?.items?.length ?? 0),
        (prevResults, newResults) => ({
            ...prevResults,
            ...newResults,

            items: [...(prevResults?.items ?? []), ...(newResults?.items ?? [])],
            classTypes: newResults?.classTypes ?? [],
            classStages: newResults?.classStages ?? [],
            classSubStages: newResults?.classSubStages ?? [],
            classLocations: newResults?.classLocations ?? [],
        }),
        (newResults) => newResults?.items?.length
    );
    const hasMore = React.useCallback(() => !options.pageSize ? false : _hasMore(), [options.pageSize, _hasMore]);

    // Return the results.
    const refresh = React.useCallback(async () => { !refetch ? await load() : await refetch(); }, [refetch, load]);
    return React.useMemo(() => ({
        data: data ?? ({} as any),
        refresh: refresh,
        isLoading: loading,
        errors: error,
        fetchMore: fetchMore,
        hasMore: hasMore,
    }), [data, refresh, loading, error, fetchMore, hasMore]);
};