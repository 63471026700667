import * as React from 'react';
import { useSaveInStore, SaveInStoreOptions } from '../../../shared/useSaveInStore';
import { useUpdateStaffNoteMutation } from './useUpdateStaffNoteMutation';
import { StaffNote } from '../models/StaffNote';
import { useCreateStaffNoteMutation } from './useCreateStaffNoteMutation';
import { StaffNoteCreateInput, StaffNoteUpdateInput } from '../generated/graphql';

/**
 * Get a callback to save a StaffNote in the api using either a create or update.
 */
export function useSaveStaffNoteMutation(options: SaveInStoreOptions<StaffNote, string> = {}) {
    const [_create, createStatus] = useCreateStaffNoteMutation();
    const create = React.useCallback((model: Partial<StaffNote>) => _create(model as StaffNoteCreateInput), [_create]);

    const [_update, updateStatus] = useUpdateStaffNoteMutation();
    const update = React.useCallback((id: string, changes: Partial<StaffNote>) => _update(id, changes as StaffNoteUpdateInput), [_update]);

    return useSaveInStore(
        [create, createStatus],
        [update, updateStatus],
        options
    );
}
