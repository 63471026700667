import gql from 'graphql-tag';
import { Guid } from 'guid-string';
import { ClassLocationAmenityTypeFieldsFragment } from '../generated/graphql';

export const classLocationAmenityTypeFieldsFragment = gql`
    fragment classLocationAmenityTypeFields on ClassLocationAmenityType {
        id
        classLocationId
        amenityTypeId
        archived
    }
`;

export type ClassLocationAmenityType = Omit<ClassLocationAmenityTypeFieldsFragment, '__typename'>;

/**
 * Default values.
 */
export const classLocationAmenityTypeDefaultValues = (): Partial<ClassLocationAmenityType> => ({
    id: Guid.newGuid(),
    classLocationId: undefined,
    amenityTypeId: undefined,
    archived: false,
});