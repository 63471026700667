import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ConditionalFragment } from "react-conditionalfragment";
import { useTranslation } from "react-i18next"
import { useNavigate, useParams } from "react-router-dom";
import { useAsyncCallback } from "react-use-async-callback";
import { Col, Row, Form, FormGroup, Label, Input, Spinner, Button } from "reactstrap";
import { ButtonAsync } from "reactstrap-buttonasync";
import { useCancellationReason } from "../../api/main/cancellationReasons/useCancellationReason";
import { useDeleteCancellationReasonMutation } from "../../api/main/cancellationReasons/useDeleteCancellationReason";
import { AlertOnErrors } from "../../shared/alertOnErrors";
import { Banner } from "../shared/banner/Banner";
import { FormButtons } from "../shared/formButtons/FormButtons";
import { LoadingIndicator } from "../shared/loadingIndicator/LoadingIndicator";
import { MainContainer } from "../shared/mainContainer/MainContainer";

/**
 * Delete a CancellationReason
 */
export const DeleteCancellationReason = () => {
    const { t } = useTranslation();
    const { id } = useParams<{ id: string }>();
    const navigate = useNavigate();

    // Load the data
    const { data: { model, }, errors: loadErrors, isLoading } = useCancellationReason(id);

    // Delete the CancellationReason
    const [remove, { errors: removeErrors }] = useDeleteCancellationReasonMutation();

    // When we submit, delete the CancellationReason
    const [onRemoveFormSubmit, { isExecuting: isRemoving, errors: removeFormErrors, }] = useAsyncCallback(async () => {
        await remove(id ?? '');
        navigate(-1);
    }, [remove, navigate]);

    // Render the UI
    //
    return (
        <>
            <Banner>
                <Row>
                    <Col>
                        <h1>{t('deleteCancellationReason.heading', 'Are you sure you want to delete this cancellation reason?')}</h1>
                        <h3>{model?.name}</h3>
                    </Col>
                    <ConditionalFragment showIf={isLoading}>
                        <Col xs="auto">
                            <LoadingIndicator size="sm" />
                        </Col>
                    </ConditionalFragment>
                </Row>
            </Banner>

            <MainContainer>
                <AlertOnErrors errors={[loadErrors, removeFormErrors, removeErrors,]} />

                <Form onSubmit={e => { e.preventDefault(); onRemoveFormSubmit(); }}>
                    <FormGroup>
                        <Label htmlFor="name">{t('deleteCancellationReason.name.label', 'Name')}</Label>
                        <Input name="name" type="text" readOnly value={model?.name ?? ''} />
                    </FormGroup>

                    <FormButtons>
                        <ButtonAsync color="danger" isExecuting={isRemoving}
                            executingChildren={<> <Spinner size="sm" /> {t('common.deleting', 'Deleting...')} </>}>
                            <FontAwesomeIcon icon="trash" />
                            <> </>
                            {t('common.delete', 'Delete')}
                        </ButtonAsync>
                        <Button type="button" color="primary" outline onClick={e => navigate(- 1)}>
                            {t('common.cancel', 'Cancel')}
                        </Button>
                    </FormButtons>
                </Form>
            </MainContainer>
        </>
    )
}