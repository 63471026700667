import gql from 'graphql-tag';
import { Guid } from 'guid-string';
import { ClassLocationClassTypeFieldsFragment } from '../generated/graphql';

export const classLocationClassTypeFieldsFragment = gql`
    fragment classLocationClassTypeFields on ClassLocationClassType {
        id
        classLocationId
        classTypeId
        archived
    }
`;

export type ClassLocationClassType = Omit<ClassLocationClassTypeFieldsFragment, '__typename'>;

/**
 * Default values.
 */
export const classLocationClassTypeDefaultValues = (): Partial<ClassLocationClassType> => ({
    id: Guid.newGuid(),
    classLocationId: undefined,
    classTypeId: undefined,
    archived: false,
});