import { IdentityRoles } from '../../configure/security/IdentityRoles';
import { RouteEntry } from '../../shared/routes';
import { DeleteQueryMessageType } from './DeleteQueryMessageType';
import { CreateQueryMessageType, EditQueryMessageType } from './EditQueryMessageType';
import { QueryMessageTypesList } from './QueryMessageTypesList';
/**
 * Routes that should be added to configuration/routeConfig.ts to provide access to these components via routing.
 */
export const queryMessageTypesRoutes: Array<RouteEntry> = [
    { path: '/administration/class-management/query-message-subjects/add', element: <CreateQueryMessageType />, authorize: true, requireRole: IdentityRoles.Administration },
    { path: '/administration/class-management/query-message-subjects/edit/:id', element: <EditQueryMessageType />, authorize: true, requireRole: IdentityRoles.Administration },
    { path: '/administration/class-management/query-message-subjects/delete/:id', element: <DeleteQueryMessageType />, authorize: true, requireRole: IdentityRoles.Administration },
    { path: '/administration/class-management/query-message-subjects', element: <QueryMessageTypesList />, authorize: true, requireRole: IdentityRoles.Administration }
];
