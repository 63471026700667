import * as React from 'react';
import { useSaveInStore, SaveInStoreOptions } from '../../../shared/useSaveInStore';
import { useUpdateNoticeBoardItemMutation } from './useUpdateNoticeBoardItemMutation';
import { NoticeBoardItem } from '../models/NoticeBoardItem';
import { useCreateNoticeBoardItemMutation } from './useCreateNoticeBoardItemMutation';
import { NoticeBoardItemCreateInput, NoticeBoardItemUpdateInput } from '../generated/graphql';

/**
 * Get a callback to save a NoticeBoardItem in the api using either a create or update.
 */
export function useSaveNoticeBoardItemMutation(options: SaveInStoreOptions<NoticeBoardItem, string> = {}) {
    const [_create, createStatus] = useCreateNoticeBoardItemMutation();
    const create = React.useCallback((model: Partial<NoticeBoardItem>) => _create(model as NoticeBoardItemCreateInput), [_create]);

    const [_update, updateStatus] = useUpdateNoticeBoardItemMutation();
    const update = React.useCallback((id: string, changes: Partial<NoticeBoardItem>) => _update(id, changes as NoticeBoardItemUpdateInput), [_update]);

    return useSaveInStore(
        [create, createStatus],
        [update, updateStatus],
        options
    );
}
