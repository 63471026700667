import * as React from 'react';
import { useSaveInStore, SaveInStoreOptions } from '../../../shared/useSaveInStore';
import { useUpdateStaffMutation } from './useUpdateStaffMutation';
import { Staff } from '../models/Staff';
import { useCreateStaffMutation } from './useCreateStaffMutation';
import { StaffCreateInput, StaffUpdateInput } from '../generated/graphql';

/**
 * Get a callback to save a Staff in the api using either a create or update.
 */
export function useSaveStaffMutation(options: SaveInStoreOptions<Staff, string> = {}) {
    const [_create, createStatus] = useCreateStaffMutation();
    const create = React.useCallback((model: Partial<Staff>) => _create(model as StaffCreateInput), [_create]);

    const [_update, updateStatus] = useUpdateStaffMutation();
    const update = React.useCallback((id: string, changes: Partial<Staff>) => _update(id, changes as StaffUpdateInput), [_update]);

    return useSaveInStore(
        [create, createStatus],
        [update, updateStatus],
        options
    );
}
