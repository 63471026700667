import { ApexOptions } from "apexcharts";
import { useMemo } from "react";
import Chart from "react-apexcharts";
import { ConditionalFragment } from "react-conditionalfragment";

export type PaymentViewModelData = {
    termName: string,
    totalAmountPaid: number,
    totalAmountUnpaid: number,
};
export interface PaymentsBarChartProps {
    chartData: Array<PaymentViewModelData> | undefined | null,
}

/**
 * Payments BarChart for the Payments Report
 * @param props
 * @returns
 */
export const PaymentsBarChart = (props: PaymentsBarChartProps) => {
    const { chartData } = props;

    // Chart options
    const options = useMemo(() => {
        const xAxisCategories = chartData?.map(it => {
            return it.termName;
        });

        return {
            chart: {
                height: 500,
                type: 'bar',
            },
            plotOptions: {
                bar: {
                    horizontal: true,
                    //dataLabels: {
                    //    position: 'top',
                    //},
                }
            },
            dataLabels: {
                enabled: true,
                offsetX: -6,
                style: {
                    fontSize: '12px',
                    colors: ['#fff']
                }
            },
            colors: ['#198754', '#dc3545'],
            stroke: {
                show: true,
                width: 1,
                colors: ['#fff']
            },
            tooltip: {
                shared: true,
                intersect: false,
            },
            xaxis: {
                categories: xAxisCategories,
            },
            legend: {
                show: true,
                showForSingleSeries: true,
                customLegendItems: ['Paid', 'Unpaid'],
                markers: {
                    fillColors: ['#198754', '#dc3545']
                }
            }
        } as ApexOptions;
    }, [chartData]);

    // Chart data
    const series = useMemo(() => {
        const ret: any = {
            data: [],
        };

        let paidValues: Array<number> = [];
        let unPaidValues: Array<number> = [];
        let categories: Array<string> = [];

        // Iterate over each term, and add the values to the arrays
        chartData?.forEach(item => {
            paidValues.push(item.totalAmountPaid);
            unPaidValues.push(item.totalAmountUnpaid);
            categories.push(item.termName);
        });

        // Push the values into the return for the chart series data
        ret.data.push({ data: paidValues, name: 'Paid' });
        ret.data.push({ data: unPaidValues, name: 'Unpaid' });

        // Return the chart data
        return ret;
    }, [chartData]);

    // Get all the totols for each term
    const totalsForEachTerm = useMemo(() => chartData?.map(it => {
        let ret = 0;

        ret += it.totalAmountPaid;
        ret += it.totalAmountUnpaid;

        return ret;
    }), [chartData]);

    // Tracking the total, if the total payments are zero for all terms, then we don't really want to show an empty chart
    const total = useMemo(() => totalsForEachTerm?.reduce((accumulator, currentValue) => accumulator + currentValue, 0), [totalsForEachTerm]);

    // Render the UI
    //
    return (
        <>
            <ConditionalFragment showIf={!!total && total > 0}>
                <Chart options={options} series={series?.data} type="bar" height={500} />
            </ConditionalFragment>
        </>
    );
};