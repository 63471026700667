import React, { useCallback, useMemo } from "react";
import { ScheduledClassWithRelationships } from "./ScheduledTimetableViewDimensions";
import { TimetableEvent } from "./TimetableEvent";
import { ConditionalFragment } from "react-conditionalfragment";
import { ScheduledClass } from "../../../api/main/models/ScheduledClass";
import { ScheduledTimetableViewDimension } from "./ScheduledTimetableViewDimensions";
import "./scheduleTimetableView.scss";
import { useTranslation } from "react-i18next";
import { DayOfWeek, dayOfWeekDisplayName } from "../../../api/main/models/constants/DayOfWeek";
import moment from "moment";
import { Term } from "../../../api/main/models/Term";

/**
 * Props for ScheduleCalendarView.
 */
export interface ScheduleTimetableViewProps {
    items: Array<ScheduledClassWithRelationships>,
    groupDimension?: ScheduledTimetableViewDimension,
    rowDimension: ScheduledTimetableViewDimension,
    columnDimension: ScheduledTimetableViewDimension,
    colorDimension?: ScheduledTimetableViewDimension,

    onEventClick?: (event: ScheduledClass) => void,

    startOfWeek: Date,
    term: Term | undefined;
}

/**
 * Component that renders a flexible calendar like view of classes.
 * @param props
 */
export const ScheduleTimetableView = (props: ScheduleTimetableViewProps) => {
    const {
        items,
        groupDimension,
        rowDimension,
        columnDimension,
        colorDimension,
        onEventClick,
        startOfWeek,
        term
    } = props;
    const { t } = useTranslation();

    // Get the groups for the groups, rows, and columns.
    const groupGroups = useMemo(() => {
        // If no secondaryRowDimension we need to return a single item array that we'll know not to render the UI for.
        if (!groupDimension) {
            return [{ id: '', name: '', }];
        }

        // Otherwise return the actual row groups.
        return groupDimension.groupsFunction(items);
    }, [groupDimension, items]);
    const rowGroups = useMemo(() => rowDimension.groupsFunction(items), [rowDimension, items]);
    const columnGroups = useMemo(() => columnDimension.groupsFunction(items), [columnDimension, items]);

    // For colors, we work through a fix length color wheel (which have associated CSS classes) to assign colors based on this grouping, so lets
    // construct the lookup table now.
    const colorIndexMap = useMemo((): { [key: string]: number; } => {
        if (!colorDimension) {
            return {};
        }

        // Go through each group and assign a colour from the map to it.
        // NOTE we work as if an unlimited number of colours are available.  It is up to CalendarEvent to wrap the colours if needed.
        let ret: { [key: string]: number; } = {};
        const groups = colorDimension.groupsFunction(items);
        for (let i = 0; i < groups.length; ++i) {
            ret[groups[i].id] = i;
        }

        return ret;
    }, [colorDimension, items]);

    // Get the dates for eac day of the week
    const getDateForDayOfWeek = useCallback((dayOfWeek: string) => {
        const today = startOfWeek;

        // Create an array of the days of the week, starting with Sunday.
        const daysOfWeek = Object.keys(DayOfWeek).filter(x => !(parseInt(x) >= 0));
        // Find the index of the day of the week we're looking for.
        const dayIndex = daysOfWeek.indexOf(dayOfWeek);

        // Calculate the date of the next day of the week.
        const daysUntilTargetDay = (dayIndex - today.getDay()) % 7;

        // If the day is today, and it's not Sunday, then add 7 days to get the next day.
        const daysToAdd = dayIndex === 0 && today.getDay() !== 0 ? 7 : 0;

        // Calculate the date of the next day of the week.
        const targetDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() + daysUntilTargetDay + daysToAdd);

        // Return the date.
        return targetDate;
    }, [startOfWeek]);

    // Render the UI.
    return (
        <div className="schedule-calendar-view">
            <div className="schedule-calendar-view-headings">
                <div className="schedule-calendar-view-headings-row-and-column-label">
                    {/* This is to create a space in the top left above the row names. */}
                </div>
                {
                    columnGroups.map(columnGroup => (
                        <div key={columnGroup.id} className="schedule-calendar-view-column-label">
                            {moment(getDateForDayOfWeek(columnGroup.name)).isBetween(term?.startDate, term?.endDate, undefined, '[]') ? (
                                <>
                                    {columnGroup.name}
                                    <> </>
                                    {t('common.date', '{{date, DD/MM/YYYY}}', { date: moment(getDateForDayOfWeek(columnGroup.name)) })}
                                </>
                            ) : t('common.outsideOfTerm', 'Outside of term ({{termName}})', { termName: term?.name })}
                        </div>
                    ))
                }
            </div>
            <div className="schedule-calendar-view-body">
                {
                    groupGroups.map(groupGroup => (
                        <React.Fragment key={groupGroup.id}>
                            <ConditionalFragment showIf={!!groupGroup.id} key={groupGroup.id}>
                                <div className="schedule-calendar-view-row-group-heading" key={groupGroup.id}>
                                    {groupGroup.name}
                                </div>
                            </ConditionalFragment>

                            {
                                rowGroups.map(rowGroup => {
                                    // If this row is going to have no items in it, then skip the row.  This prevents us having
                                    // rows within a group where they are empty or logically don't belong from a user's perspective.
                                    if (!!groupDimension) {
                                        const rowEvents = items.filter(item =>
                                            rowDimension.filterFunction(rowGroup.id, item)
                                            && groupDimension.filterFunction(groupGroup.id, item)
                                        );
                                        if (!rowEvents.length) {
                                            return null;
                                        }
                                    }

                                    // Render the row.
                                    return (
                                        <div key={rowGroup.id} className="schedule-calendar-view-row">
                                            <div className="schedule-calendar-view-row-label">
                                                {rowGroup.name}
                                            </div>

                                            {
                                                columnGroups.map(columnGroup => {
                                                    // Get the events that match the row, secondary row, and column.
                                                    let events: Array<ScheduledClassWithRelationships>;
                                                    if (!groupDimension) {
                                                        events = items.filter(item =>
                                                            rowDimension.filterFunction(rowGroup.id, item)
                                                            && columnDimension.filterFunction(columnGroup.id, item)
                                                        );
                                                    } else {
                                                        events = items.filter(item =>
                                                            rowDimension.filterFunction(rowGroup.id, item)
                                                            && columnDimension.filterFunction(columnGroup.id, item)
                                                            && groupDimension.filterFunction(groupGroup.id, item)
                                                        );
                                                    }

                                                    // UI.
                                                    return (
                                                        <div key={columnGroup.id} className="schedule-calendar-view-cell">
                                                            {
                                                                events.map(event => {
                                                                    // Look up our colorIndex from the color map.
                                                                    let colorIndex = 0;
                                                                    if (colorDimension) {
                                                                        const colorValueId = colorDimension?.getIdFunction(event);
                                                                        const myColorIndex = colorIndexMap[colorValueId];
                                                                        if (myColorIndex !== undefined) {
                                                                            colorIndex = myColorIndex;
                                                                        }
                                                                    }

                                                                    // Get the date of this class.
                                                                    const dateOfThisClass = getDateForDayOfWeek(dayOfWeekDisplayName(event.dayOfWeek, t));

                                                                    // Check if this class is cancelled.
                                                                    const isClassCancelled = !!event?.classCancellations?.find(it => moment(it.lessonDate).isSame(moment(dateOfThisClass), 'day'));

                                                                    // Get any term holidays
                                                                    const termHolidays = event.holidays;

                                                                    // Check if the date of this class is within a holiday
                                                                    const isDateWithinHoliday = (dateOfAClass: Date) => {
                                                                        if (!termHolidays) return false;
                                                                        for (const holiday of termHolidays) {
                                                                            // Empty array '[]' means inclusive.
                                                                            if (moment(dateOfAClass).isBetween(holiday.startDate, holiday.endDate, undefined, '[]')) {
                                                                                if (!holiday.classLocationId) {
                                                                                    return true;
                                                                                } else if (holiday.classLocationId === event.classLocationId) {
                                                                                    return true;
                                                                                } else {
                                                                                    return false;
                                                                                }
                                                                            }
                                                                        }
                                                                        return false;
                                                                    };
                                                                    const isHoliday = isDateWithinHoliday(dateOfThisClass);

                                                                    // Check if this class has a primary teacher substitute -> empty array '[]' means inclusive.
                                                                    const isPrimaryTeacherSubstitute = !!(moment(dateOfThisClass).isBetween(event?.substitutePrimaryTeacher?.scheduledClassStaff?.subsituteStartDate, event?.substitutePrimaryTeacher?.scheduledClassStaff?.subsituteEndDate, undefined, '[]') && event?.id === event?.substitutePrimaryTeacher?.scheduledClassStaff?.scheduledClassId);

                                                                    // Is the PrimaryTeacher going to be absent on this day?
                                                                    const isPrimaryTeacherAbsent = !!(event?.primaryTeacher?.absences?.find(it => moment(dateOfThisClass).isBetween(it.startDate, it.endDate, undefined, '[]')));

                                                                    if (term && !moment(dateOfThisClass).isBetween(moment(term.startDate), moment(term.endDate), undefined, '[]')) {
                                                                        // Outside of term, do nothing
                                                                        return null;
                                                                    } else {

                                                                        // Render the event.
                                                                        return (
                                                                            <TimetableEvent key={event.id + columnGroup.id}
                                                                                event={event}
                                                                                onClick={() => onEventClick?.(event)}
                                                                                colorIndex={colorIndex}
                                                                                isClassCancelled={isClassCancelled}
                                                                                isHoliday={isHoliday}
                                                                                isPrimaryTeacherSubstitute={isPrimaryTeacherSubstitute}
                                                                                isPrimaryTeacherAbsent={isPrimaryTeacherAbsent}
                                                                                dateOfThisClass={dateOfThisClass}
                                                                            />
                                                                        );
                                                                    }
                                                                })
                                                            }
                                                        </div>
                                                    );
                                                })
                                            }
                                        </div>
                                    );
                                })
                            }
                        </React.Fragment>
                    ))
                }
            </div>
        </div>
    );
};