import * as React from 'react';
import { useLazyQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { Guid } from "guid-string";
import { ClassFinderCoreLocationViewModelQuery, ClassFinderCoreLocationViewModelQueryVariables } from '../generated/graphql';
import { amenityTypeFieldsFragment } from '../models/AmenityType';
import { classLocationAmenityTypeFieldsFragment } from '../models/ClassLocationAmenityType';
import { mainApiConfig } from '../../../configure/mainApiConfig';
import { AsyncLoadOptions, AsyncLoadResult } from '../../../shared/abstractStore';

/**
 * Get a BlobReference for an anonymouse user from the api.
 * @param classLocationId
 * @param options
 */
export function useClassFinderCoreLocationViewModel(classLocationId: string | undefined | null, options: AsyncLoadOptions = {}): AsyncLoadResult<ClassFinderCoreLocationViewModelQuery> {
    // Query the data we need form the api.
    const [load, { data, loading, error, refetch }] = useLazyQuery<ClassFinderCoreLocationViewModelQuery, ClassFinderCoreLocationViewModelQueryVariables>(
        gql`
        query classFinderCoreLocationViewModelQuery ($classLocationId: ID!) {
            items: classLocationAmenityTypes (classLocationId: $classLocationId) {
                ...classLocationAmenityTypeFields
            }

            amenityTypes: amenityTypes {
                ...amenityTypeFields
            }
        }

        ${classLocationAmenityTypeFieldsFragment}
        ${amenityTypeFieldsFragment}
        `,
        {
            variables: {
                classLocationId: classLocationId ?? Guid.empty
            },
            fetchPolicy: options.fetchPolicy ?? mainApiConfig.defaultFetchPolicy,
            pollInterval: options.pollInterval,
            onCompleted: (data) => {
                if (options.onCompleted) {
                    options.onCompleted(data);
                }
            }
        }
    );

    // Run the query (unless we are being lazy).
    React.useEffect(() => {
        if (!options.lazy) {
            load();
        }
    }, [options.lazy, load]);

    // Return the results.
    const refresh = React.useCallback(async () => { !refetch ? await load() : await refetch(); }, [refetch, load]);
    return React.useMemo(() => ({
        data: data ?? ({} as any),
        refresh: refresh,
        isLoading: loading,
        errors: error
    }), [data, refresh, loading, error]);
}
