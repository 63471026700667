import { useMutation } from "@apollo/react-hooks";
import { useAsyncCallback } from 'react-use-async-callback';
import gql from "graphql-tag";
import { AsyncActionStatus } from '../../../shared/abstractStore';
import { CreateQueryMessageMutation, CreateQueryMessageMutationVariables, QueryMessageCreateInput } from '../generated/graphql';
import { queryMessageFieldsFragment } from '../models/QueryMessage';

/**
 * Get a callback to create a QueryMessage in the api.
 */
export function useCreateQueryMessageMutation(): [(model: QueryMessageCreateInput) => Promise<void>, AsyncActionStatus] {
    const [mutationAction, { error }] = useMutation<CreateQueryMessageMutation, CreateQueryMessageMutationVariables>(
        gql`
            mutation createQueryMessageMutation ($model: QueryMessageCreateInput!) {
                createQueryMessage(model: $model) {
                    ...queryMessageFields
                }
            }

            ${queryMessageFieldsFragment}
        `
    );

    // Wrap the mutation in a standard callback.
    const [action, { isExecuting, errors: actionErrors }] = useAsyncCallback(async (model: QueryMessageCreateInput) => {
        const result = await mutationAction({ variables: { model: model } });
        if (result.errors && result.errors.length) {
            throw result.errors;
        }
    }, [mutationAction]);

    return [action, { isExecuting: isExecuting, errors: error ? error.message : actionErrors }];
}
