import { useLazyQuery } from "@apollo/client";
import gql from "graphql-tag";
import { Guid } from "guid-string";
import React from "react";
import { mainApiConfig } from "../../../../configure/mainApiConfig";
import { AsyncLoadOptions, AsyncLoadResult } from "../../../../shared/abstractStore";
import { ConsumeScheduledClassToAddHolidayOrSicknessQuery, ConsumeScheduledClassToAddHolidayOrSicknessQueryVariables } from '../../generated/graphql';
import { childFieldsFragment } from "../../models/Child";
import { childAbsenceTypeFieldsFragment } from "../../models/ChildAbsenceType";
import { classLocationFieldsFragment } from "../../models/ClassLocation";
import { classStageFieldsFragment } from "../../models/ClassStage";
import { classSubStageFieldsFragment } from "../../models/ClassSubStage";
import { scheduledClassFieldsFragment } from "../../models/ScheduledClass";

export function useConsumeScheduledClassToAddHolidayOrSickness(scheduledClassId: string | undefined | null, childId: string | undefined | null, options: AsyncLoadOptions = {}): AsyncLoadResult<ConsumeScheduledClassToAddHolidayOrSicknessQuery> {
    // Query the data we need from the api
    const [load, { data, loading, error, refetch }] = useLazyQuery<ConsumeScheduledClassToAddHolidayOrSicknessQuery, ConsumeScheduledClassToAddHolidayOrSicknessQueryVariables>(
    gql`
    query consumeScheduledClassToAddHolidayOrSickness ($scheduledClassId: ID!, $childId: ID!) {
        model: scheduledClass (id: $scheduledClassId) {
            ...scheduledClassFields

            lessonDatesForCurrentTerm

            classLocation {
                ...classLocationFields
            }
        }

        child (id: $childId) {
            ...childFields
        }

        childAbsenceTypes {
            ...childAbsenceTypeFields
        }

        classStages {
            ...classStageFields
        }

        classSubStages {
            ...classSubStageFields
        }
    }

    ${scheduledClassFieldsFragment}
    ${classLocationFieldsFragment}
    ${childFieldsFragment}
    ${childAbsenceTypeFieldsFragment}
    ${classStageFieldsFragment}
    ${classSubStageFieldsFragment}
    `,
        {
            variables: {
                scheduledClassId: scheduledClassId ?? Guid.empty,
                childId: childId ?? Guid.empty
            },
            fetchPolicy: options.fetchPolicy ?? mainApiConfig.defaultFetchPolicy,
            pollInterval: options.pollInterval,
            onCompleted: (data) => {
                if (options.onCompleted) {
                    options.onCompleted(data);
                }
            }
        }
    );

    // Run the query (unless we are being lazy).
    React.useEffect(() => {
        if (!options.lazy) {
            load();
        }
    }, [options.lazy, load]);

    // Return the results.
    const refresh = React.useCallback(async () => { !refetch ? await load() : await refetch(); }, [refetch, load]);
    return React.useMemo(() => ({
        data: data ?? ({} as any),
        refresh: refresh,
        isLoading: loading,
        errors: error,
    }), [data, refresh, loading, error]);
};