import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import { ConditionalFragment } from "react-conditionalfragment";
import { useTranslation } from "react-i18next";
import { Badge, Button } from "reactstrap";
import { StaffSubmissionAcceptanceState } from "../../api/main/models/constants/StaffSubmissonAcceptanceState";
import { StaffAbsence } from "../../api/main/models/StaffAbsence";
import { useProfile } from "../../api/main/profiles/useProfile";
import { AlertOnErrors } from "../../shared/alertOnErrors";
import { ModelArrayChanges } from "../../shared/useChanges";
import { useStaffAbsenceStatusColorAndMessage } from "../staffTimeOff/cards/useStaffAbsenceStatusColorAndMessage";
import { useStaffAbsenceTypeForIcons } from "../staffTimeOff/cards/useStaffAbsenceTypeForIcons";
import { StaffAbsenceWithProfileAndAbsenceType } from "./StaffAbsenceList";

export interface StaffAbsenceItemHistoricalProps {
    model: StaffAbsenceWithProfileAndAbsenceType | null | undefined,
    staffAbsenceManager: ModelArrayChanges<StaffAbsence, string>,
    updateStaffAbsence: (id: string, changes: Partial<StaffAbsence>, isCreate: boolean) => Promise<void>,
    currentUserId: string | null | undefined,
}

/**
 * Individual StaffAbsence item, for viewing historical requests
 */
export const StaffAbsenceItemHistorical = (props: StaffAbsenceItemHistoricalProps) => {
    const { model, staffAbsenceManager, updateStaffAbsence, currentUserId } = props;
    const { t } = useTranslation();

    // Load user data - to show who approved the StaffAbsence
    const {
        data: {
            model: approvedByUser
        }, errors: loadErrors
    } = useProfile(model?.approvedByStaffUserId, { isUserId: true });

    // Handle undoing the Approval / Rejection of a StaffAbsence
    // Handle approving a StaffAbsence request
    const handleUndoStaffAbsenceAction = () => {
        if (!model || !currentUserId) {
            return;
        }

        const changes = {
            acceptanceState: StaffSubmissionAcceptanceState.Submitted,
            approvedByStaffUserId: currentUserId,
            confirmedDate: moment(Date.now()).toISOString()
        };

        staffAbsenceManager.changeFor(model?.id, changes);

        updateStaffAbsence(model?.id, changes, false);
    };

    // Get the color coding and message for better UI
    const { color } = useStaffAbsenceStatusColorAndMessage(model);

    // Get the icon to display along with the absenceType
    const icon = useStaffAbsenceTypeForIcons(model?.absenceType?.name ?? '');

    // Render the UI
    //
    return (
        <>
            <AlertOnErrors
                errors={[loadErrors]}
            />

            <tr key={model?.id}>
                <td>
                    {t('common.fullName', '{{firstName}} {{lastName}}', { firstName: model?.profile?.firstName ?? '', lastName: model?.profile?.lastName ?? '' })}
                </td>
                <td>
                    <ConditionalFragment showIf={!!icon}>
                        {icon}
                    </ConditionalFragment>
                    {t('staffAbsenceItemHistorical.absenceType.value', '{{absenceType}}', { absenceType: model?.absenceType?.name })}
                </td>
                <td>
                    {t('common.date', '{{date, DD/MM/YYYY}}', { date: moment(model?.startDate) })}
                </td>
                <td>
                    {t('common.date', '{{date, DD/MM/YYYY}}', { date: moment(model?.endDate) })}
                </td>
                <td>
                    {t('common.date', '{{date, DD/MM/YYYY}}', { date: moment(model?.submittedDate) })}
                </td>
                <td >
                    <Badge color={color} pill>
                        {model?.acceptanceState}
                    </Badge>
                </td>
                <td>
                    {t('common.date', '{{date, DD/MM/YYYY}}', { date: moment(model?.confirmedDate) })}
                </td>
                <td>
                    {t('common.fullName', '{{firstName}} {{lastName}}', { firstName: approvedByUser?.firstName ?? '', lastName: approvedByUser?.lastName ?? '' })}
                </td>
                <td>
                    <Button color="secondary" onClick={() => handleUndoStaffAbsenceAction()}>
                        <FontAwesomeIcon icon="rotate-left" />
                        <> </>
                        {t('common.undo', 'Undo')}
                    </Button>
                </td>
            </tr>
        </>
    );
};