import * as React from 'react';
import { useSaveInStore, SaveInStoreOptions } from '../../../shared/useSaveInStore';
import { useUpdateLessonDocumentMutation } from './useUpdateLessonDocumentMutation';
import { LessonDocument } from '../models/LessonDocument';
import { useCreateLessonDocumentMutation } from './useCreateLessonDocumentMutation';
import { LessonDocumentCreateInput, LessonDocumentUpdateInput } from '../generated/graphql';

/**
 * Get a callback to save a LessonDocument in the api using either a create or update.
 */
export function useSaveLessonDocumentMutation(options: SaveInStoreOptions<LessonDocument, string> = {}) {
    const [_create, createStatus] = useCreateLessonDocumentMutation();
    const create = React.useCallback((model: Partial<LessonDocument>) => _create(model as LessonDocumentCreateInput), [_create]);

    const [_update, updateStatus] = useUpdateLessonDocumentMutation();
    const update = React.useCallback((id: string, changes: Partial<LessonDocument>) => _update(id, changes as LessonDocumentUpdateInput), [_update]);

    return useSaveInStore(
        [create, createStatus],
        [update, updateStatus],
        options
    );
}
