import moment from "moment";

/**
 * Returns the integer year/month/days age of someone based on their date of birth.
 * @param dateOfBirth
 */
export function getAgeFromDateOfBirth(dateOfBirth: string): { years: number, months: number, days: number, }
{
    const dateOfBirthDate = moment(dateOfBirth).toDate();
    const now = moment().toDate();

    let dy = now.getFullYear() - dateOfBirthDate.getFullYear();
    let dm = now.getMonth() - dateOfBirthDate.getMonth();
    let dd = now.getDate() - dateOfBirthDate.getDate();

    if (dd < 0) {
        dm -= 1;
        dd += 30;
    }

    if (dm < 0) {
        dy -= 1;
        dm += 12;
    }

    return {
        years: dy,
        months: dm,
        days: dd,
    };
}