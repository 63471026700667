import { ValidationErrors } from "pojo-validator";
import { useMemo } from "react";
import { ConditionalFragment } from "react-conditionalfragment";
import { useTranslation } from "react-i18next";
import { Card, CardBody, Col, Collapse, Row, } from "reactstrap";
import { useToggleState } from "use-toggle-state";
import { ClassStage } from "../../../../api/main/models/ClassStage";
import { ClassSubStage } from "../../../../api/main/models/ClassSubStage";
import { LessonDocument } from "../../../../api/main/models/LessonDocument";
import { ModelArrayChanges } from "../../../../shared/useChanges";
import { CardHeaderCollapse } from "../../../shared/cardHeaderCollapse/CardHeaderCollapse";
import { ClassStageLessonDocumentComponent } from "./ClassStageLessonDocumentComponent";

export interface ClassStageSubStageLessonDocumentTabProps {
    model: ClassSubStage | undefined,
    lessonDocumentsManager: ModelArrayChanges<LessonDocument, string>,
    classStage: ClassStage | null | undefined,

    validateLessonDocument: (model: LessonDocument) => boolean,
    lessonDocumentValidationErrors: (id: string) => ValidationErrors,
}

/**
 * Component under ClassStages used for managing LessonDocuments
 */
export const ClassStageSubStageLessonDocumentTab = (props: ClassStageSubStageLessonDocumentTabProps) => {
    const { model, lessonDocumentsManager, classStage, validateLessonDocument, lessonDocumentValidationErrors } = props;
    const { t } = useTranslation();

    // Handle toggling the CardCollapse
    const [isOpen, toggleOpen] = useToggleState(false);

    // Determine how many lessons have missing lessonDocuments
    let missingLessonPlanCounts = useMemo(() => {
        return lessonDocumentsManager.model.filter(it => it.classSubStageId === model?.id && it.isLessonPlan === true && it.blobReferenceId !== null).length;
    }, [lessonDocumentsManager, model]);

    // Render the UI
    //
    return (
        <>
            {
                <Card style={{ margin: "10px 0" }}>
                    <CardHeaderCollapse isOpen={isOpen} toggle={toggleOpen}>
                        <Row>
                            <Col>
                                {model?.name}
                                <div>
                                    {t('editClassStage.classStageSubStageLessonDocumentTab.addLessonDocuments.subheading', 'Click to view lessons')}
                                </div>
                            </Col>
                            <ConditionalFragment showIf={!!missingLessonPlanCounts}>
                                <Col xs="auto">
                                    {t('editClassStage.classStageSubStageLessonDocumentTab.warning.message', 'Stage has {{missingLessonPlanCount}} lesson plans but has {{totalLessons}} lessons', { missingLessonPlanCount: model?.numberOfLessons - missingLessonPlanCounts , totalLessons: model?.numberOfLessons })}
                                </Col>
                            </ConditionalFragment>
                        </Row>
                    </CardHeaderCollapse>

                    <Collapse isOpen={isOpen}>
                        <CardBody>
                            {
                                // Generate an Array of length ClassSubStage.numberOfLessons, take the index and add 1 (zero indexed, but no lesson zero) in the callback. Then we can map over for each lessonNumber
                                Array.from({ length: model?.numberOfLessons }, (_, index) => index + 1).map(lessonNumber => (
                                    <ClassStageLessonDocumentComponent
                                        key={lessonNumber}
                                        model={model}
                                        lessonDocumentsManager={lessonDocumentsManager}
                                        classStage={classStage}
                                        validateLessonDocument={validateLessonDocument}
                                        lessonDocumentValidationErrors={lessonDocumentValidationErrors}
                                        lessonNumber={lessonNumber}
                                    />
                                ))
                            }
                        </CardBody>
                    </Collapse>
                </Card>
            }
        </>
    );
};