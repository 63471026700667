import { TFunction } from "react-i18next";
import { Badge } from "reactstrap";
import { PaymentState, paymentStateDisplayName } from "../../../api/main/models/constants/PaymentState";
import { HappyIcon } from "./HappyIcon";

/**
 * Returns the appropriate Badge when given a valid PaymentState
 * @param paymentState
 */
export const getPaymentStateBadge = (paymentState: PaymentState | undefined, t: TFunction) => {
    switch (paymentState) {
        case PaymentState.Paid:
            return (
                <Badge color= "success" pill >
                    <HappyIcon icon="payment" />
                    <> </>
                    {paymentStateDisplayName(paymentState ?? '', t)}
                </Badge>
            );
        case PaymentState.Unpaid:
            return (
                <Badge color= "warning" pill >
                    <HappyIcon icon="payment" />
                    <> </>
                    {paymentStateDisplayName(paymentState ?? '', t)}
            </Badge>
            );
        case PaymentState.PartialPaid:
            return (
                <Badge color= "warning" pill >
                    <HappyIcon icon="payment" />
                    <> </>
                    {paymentStateDisplayName(paymentState ?? '', t)}
            </Badge>
            );
        case PaymentState.Failed:
            return (
                <Badge color= "danger" pill >
                    <HappyIcon icon="payment" />
                    <> </>
                    {paymentStateDisplayName(paymentState ?? '', t)}
            </Badge>
            );
        default:
            return <></>
    }
};