import * as React from 'react';
import { useLazyQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { AsyncLoadOptions, AsyncLoadResult } from '../../../../shared/abstractStore';
import { mainApiConfig } from '../../../../configure/mainApiConfig';
import { IsBookingValidQuery, IsBookingValidQueryVariables } from '../../generated/graphql';
import { Guid } from 'guid-string';
import { scheduledClassChildFieldsFragment } from '../../models/ScheduledClassChild';

/**
 * Get all data required for the ScheduledClassesChildViewMode component from the api.
 * @param options
 */
export function useIsBookingValid(childId: string | undefined | null, scheduledClassId: string | undefined | null, options: AsyncLoadOptions = {}): AsyncLoadResult<IsBookingValidQuery> {
    // Query data we need from the api.
    const [load, { data, loading, error, refetch, }] = useLazyQuery<IsBookingValidQuery, IsBookingValidQueryVariables>(
        gql`
        query isBookingValidQuery ($childId: ID!, $scheduledClassId: ID!) {
            isBookingValid (childId: $childId, scheduledClassId: $scheduledClassId) {
                success
            }

            scheduledClassChildren (childId: $childId, scheduledClassId: $scheduledClassId) {
                ...scheduledClassChildFields
            }
        }

        ${scheduledClassChildFieldsFragment}
        `,


        {

            variables: {
                childId: childId ?? Guid.empty,
                scheduledClassId: scheduledClassId ?? Guid.empty,
            },
            fetchPolicy: options.fetchPolicy ?? mainApiConfig.defaultFetchPolicy,
            pollInterval: options.pollInterval,
            onCompleted: (data) => {
                if (options.onCompleted) {
                    options.onCompleted(data);
                }
            }
        }
    );

    // Run the query (unless we are being lazy).
    React.useEffect(() => {
        if (!options.lazy) {
            load();
        }
    }, [options.lazy, load]);

    // Return the results.
    const refresh = React.useCallback(async () => { !refetch ? await load() : await refetch(); }, [refetch, load]);
    return React.useMemo(() => ({
        data: data ?? ({} as any),
        refresh: refresh,
        isLoading: loading,
        errors: error,
    }), [data, refresh, loading, error,]);
}