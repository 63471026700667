import gql from 'graphql-tag';
import { Guid } from 'guid-string';
import { ClassLocationFieldsFragment } from '../generated/graphql';

export const classLocationFieldsFragment = gql`
    fragment classLocationFields on ClassLocation {
        id
        name
        archived
        address
        directionsHtml
        mapUrl
        photoBlobReferenceId
        longitude
        latitude
    }
`;

export type ClassLocation = Omit<ClassLocationFieldsFragment, '__typename'>;

/**
 * Default values.
 */
export const classLocationDefaultValues = (): Partial<ClassLocation> => ({
    id: Guid.newGuid(),
    name: '',
    archived: false,
    address: '',
    directionsHtml: '',
    mapUrl: '',
    photoBlobReferenceId: null,
    longitude: '',
    latitude: ''
});