import * as React from 'react';
import { useLazyQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { AsyncLoadPagedResult, AsyncLoadPagedOptions } from '../../../../shared/abstractStore';
import { useFetchMoreCallback } from '../../../../shared/useFetchMoreCallback';
import { StaffsListViewModelQuery, StaffsListViewModelQueryVariables } from '../../generated/graphql';
import { mainApiConfig } from '../../../../configure/mainApiConfig';
import { profileFieldsFragment } from '../../models/Profile';
import { staffFieldsFragment } from '../../models/Staff';
import { userFieldsFragment } from '../../models/User';
import { teacherClassTypeFieldsFragment } from '../../models/TeacherClassType';
import { classTypeFieldsFragment } from '../../models/ClassType';

/**
 * Get a list of profiles from the api for the UsersListView component.
 * @param id
 * @param options
 */
export function useStaffsListViewModel(options: AsyncLoadPagedOptions = {}): AsyncLoadPagedResult<StaffsListViewModelQuery> {
    // Query data we need from the api.
    const [load, { data, loading, error, refetch, fetchMore: fetchMoreFromStore }] = useLazyQuery<StaffsListViewModelQuery, StaffsListViewModelQueryVariables>(
        gql`
        query staffsListViewModelQuery ($offset: Int, $first: Int) {
            items: profiles (offset: $offset, first: $first, isStaff: true) {
                ...profileFields
            }

            staffs: staffs{
                ...staffFields
            }

            users: users(isStaff: true) {
                ...userFields
            }
            teacherClassTypes: teacherClassTypes{
                ...teacherClassTypeFields
            }

            classTypes: classTypes{
                ...classTypeFields
            }
        }

        ${profileFieldsFragment}
        ${staffFieldsFragment}
        ${userFieldsFragment}                
        ${teacherClassTypeFieldsFragment}
        ${classTypeFieldsFragment}


        `,
        {

            variables: {
                offset: options.startOffset,
                first: options.pageSize,
            },
            fetchPolicy: options.fetchPolicy ?? mainApiConfig.defaultFetchPolicy,
            pollInterval: options.pollInterval,
            onCompleted: (data) => {
                if (options.onCompleted) {
                    options.onCompleted(data);
                }
            }
        }
    );

    // Run the query (unless we are being lazy).
    React.useEffect(() => {
        if (!options.lazy) {
            load();
        }
    }, [options.lazy, load])

    // Get the functions used to manage paging.
    const { fetchMore, hasMore: _hasMore } = useFetchMoreCallback<StaffsListViewModelQuery>(
        fetchMoreFromStore,
        (options?.startOffset ?? 0) + (data?.items?.length ?? 0),
        (prevResults, newResults) => ({
            ...prevResults,
            ...newResults,

            items: [...(prevResults?.items ?? []), ...(newResults?.items ?? [])],
            staffs: newResults?.staffs ?? [],
            users: newResults?.users ?? [],
            teacherClassTypes: newResults?.teacherClassTypes ?? [],
            classTypes: newResults?.classTypes ?? []
        }),
        (newResults) => newResults?.items?.length
    );
    const hasMore = React.useCallback(() => !options.pageSize ? false : _hasMore(), [options.pageSize, _hasMore]);
    
    // Return the results.
    const refresh = React.useCallback(async () => { !refetch ? await load() : await refetch(); }, [refetch, load]);
    return React.useMemo(() => ({
        data: data ?? ({} as any),
        refresh: refresh,
        isLoading: loading,
        errors: error,
        fetchMore: fetchMore,
        hasMore: hasMore,
    }), [data, refresh, loading, error, fetchMore, hasMore]);
}
