import { IdentityRoles } from "../../configure/security/IdentityRoles";
import { RouteEntry } from "../../shared/routes";
import { DeletePayment } from "../membersChildren/membersChild/paymentsTab/DeletePayment";
import { ChildrenList } from "./ChildrenList";
import { DeleteChild } from "./DeleteChild";
import { CreateChild, EditChild } from "./edit/EditChild";

export const childrenRoutes: Array<RouteEntry> = [
    { path: '/administration/children-management/children/edit/:id', element: <EditChild />, authorize: true, requireRole: IdentityRoles.Administration },
    { path: '/administration/children-management/children/delete/:id', element: <DeleteChild />, authorize: true, requireRole: IdentityRoles.Administration },
    { path: '/administration/children-management/children/add', element: <CreateChild />, authorize: true, requireRole: IdentityRoles.Administration },
    { path: '/administration/children-management/children', element: <ChildrenList />, authorize: true, requireRole: IdentityRoles.Administration },
    { path: '/administration/children-management/children/payment/delete/:id', element: <DeletePayment />, authorize: true, requireRole: IdentityRoles.Administration }
];
