import { useMutation } from "@apollo/react-hooks";
import { useAsyncCallback } from 'react-use-async-callback';
import gql from "graphql-tag";
import { AsyncActionStatus } from '../../../shared/abstractStore';
import { StartReenrolmentMutation, StartReenrolmentMutationVariables } from '../generated/graphql';
import { BackgroundTask, backgroundTaskFieldsFragment } from '../models/BackgroundTask';

/**
 * Get a callback to start a background task on the server that will run a reenrolment process.
 */
export function useStartReenrolmentMutation(): [(ids: Array<string>, id: string) => Promise<BackgroundTask | undefined | null>, AsyncActionStatus] {
    const [mutationAction, { error }] = useMutation<StartReenrolmentMutation, StartReenrolmentMutationVariables>(
        gql`
            mutation startReenrolmentMutation ($ids: [ID!], $termId: ID!) {
                startReenrolment(ids: $ids, termId: $termId) {
                    ...backgroundTaskFields
                }
            }

            ${backgroundTaskFieldsFragment}
        `
    );

    // Wrap the mutation in a standard callback.
    const [action, { isExecuting, errors: actionErrors }] = useAsyncCallback(async (ids: Array<string>, termId: string): Promise<BackgroundTask | undefined | null> => {
        const result = await mutationAction({ variables: { ids: ids, termId: termId } });
        if (result.errors && result.errors.length) {
            throw result.errors;
        }
        return result?.data?.startReenrolment;
    }, [mutationAction]);

    return [action, { isExecuting: isExecuting, errors: error ? error.message : actionErrors }];
}
