import * as React from 'react';
import { useLazyQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { AsyncLoadOptions, AsyncLoadResult } from '../../../../shared/abstractStore';
import { mainApiConfig } from '../../../../configure/mainApiConfig';
import { EditStaffClassesTabViewModelQuery, EditStaffClassesTabViewModelQueryVariables } from '../../generated/graphql';
import { scheduledClassStaffFieldsFragment } from '../../models/ScheduledClassStaff';
import { Guid } from 'guid-string';
import { scheduledClassFieldsFragment } from '../../models/ScheduledClass';
import { classLocationFieldsFragment } from '../../models/ClassLocation';

/**
 * Get all data required for the EditStaffClassesTab component from the api.
 * @param options
 */
export function useEditStaffClassesTabViewModel(staffId: string | undefined | null, options: AsyncLoadOptions = {}): AsyncLoadResult<EditStaffClassesTabViewModelQuery> {
    // Query data we need from the api.
    const [load, { data, loading, error, refetch, }] = useLazyQuery<EditStaffClassesTabViewModelQuery, EditStaffClassesTabViewModelQueryVariables>(
        gql`
        query editStaffClassesTabViewModelQuery ($staffId: ID) {
            items: scheduledClassStaffs (staffId: $staffId) {
                ...scheduledClassStaffFields

                scheduledClass {
                    ...scheduledClassFields
                }
            }

            classLocations {
                ...classLocationFields
            } 
        }

        ${scheduledClassStaffFieldsFragment}
        ${scheduledClassFieldsFragment}
        ${classLocationFieldsFragment}
        `,
        {

            variables: {
                staffId: staffId ?? Guid.empty,
            },
            fetchPolicy: options.fetchPolicy ?? mainApiConfig.defaultFetchPolicy,
            pollInterval: options.pollInterval,
            onCompleted: (data) => {
                if (options.onCompleted) {
                    options.onCompleted(data);
                }
            }
        }
    );

    // Run the query (unless we are being lazy).
    React.useEffect(() => {
        if (!options.lazy) {
            load();
        }
    }, [options.lazy, load]);

    // Return the results.
    const refresh = React.useCallback(async () => { !refetch ? await load() : await refetch(); }, [refetch, load]);
    return React.useMemo(() => ({
        data: data ?? ({} as any),
        refresh: refresh,
        isLoading: loading,
        errors: error,
    }), [data, refresh, loading, error,]);
}