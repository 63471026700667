import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ValidationErrors } from "pojo-validator";
import { ValidateCallback } from "pojo-validator-react";
import { ValidatedInput } from "pojo-validator-reactstrap";
import { useTranslation } from "react-i18next";
import { useAsyncCallback } from "react-use-async-callback";
import { Button, ButtonGroup, Col, FormGroup, Label, ListGroupItem, Row, Spinner } from "reactstrap";
import { ButtonAsync } from "reactstrap-buttonasync";
import { BlobUploadService } from "../../../../api/main/blobReferences/BlobUploadService";
import { useBlobReference } from "../../../../api/main/blobReferences/useBlobReference";
import { WelcomePackDocument } from "../../../../api/main/models/WelcomePackDocument";
import { AlertOnErrors } from "../../../../shared/alertOnErrors";
import { FileUploadButton } from "../../../shared/fileUploadButton/FileUploadButton";
import { TwoValueSwitch } from "../../../shared/twoValueSwitch/TwoValueSwitch";
import { UploadedFilePreview } from "../../../shared/uploadedFilePreview/UploadedFilePreview";

export interface ClassStageWelcomePackDocumentComponentProps {
    model: WelcomePackDocument | undefined,
    change: (changes: Partial<WelcomePackDocument>) => void;
    remove: () => void,

    validate: ValidateCallback,
    validationErrors: ValidationErrors;
}

/**
 * Component under ClassStage that lets the user edit a WelcomePackDocument
 */
export const ClassStageWelcomePackDocumentComponent = (props: ClassStageWelcomePackDocumentComponentProps) => {
    const { model, change, remove, validate, validationErrors } = props;
    const { t } = useTranslation();

    // Contents file uploading
    const { data: { model: contentsFile }, errors: contentsFileLoadErrors } = useBlobReference(model?.blobReferenceId);
    const [onUploadContentsFile, { errors: contentsFileUploadErrors, isExecuting: isUploadingContentsFile, }] = useAsyncCallback(async (files: FileList | null) => {
        if (!files) {
            return;
        }

        let uploadService: BlobUploadService = new BlobUploadService("/api/blobs");
        let result = await uploadService.upload(files);

        if (!!result) {
            change({ blobReferenceId: result.id });
        }
    }, [change]);
    const [clearContentsFile, { isExecuting: isClearingContentsFile, }] = useAsyncCallback(async () => {
        change({ blobReferenceId: null });
    }, [change]);

    return (
        <>

            <AlertOnErrors errors={[
                contentsFileLoadErrors,
                contentsFileUploadErrors
            ]} />

            <ListGroupItem>
                <Row>
                    <Col>
                        <FormGroup>
                            <Label htmlFor="name">{t('editClassStage.welcomePackDocumentComponent.name.label', 'Name')}</Label>
                            <ValidatedInput name="name" type="text" value={model?.name ?? ''} onChange={e => change({ name: e.currentTarget.value })} onBlur={e => validate('name')} validationErrors={validationErrors['name']} />
                        </FormGroup>
                    </Col>
                    <Col>
                        <FormGroup>
                            <Label htmlFor="isWelcomePack">{t('editClassStage.welcomePackDocumentComponent.isWelcomePack.label', 'Welcome pack')}</Label>
                            <TwoValueSwitch leftLabel="" rightLabel="" checked={model?.isWelcomePack ?? false} onChange={(value) => change({ isWelcomePack: value })} />
                        </FormGroup>
                    </Col>
                    <Col>
                        <FormGroup>
                            <Label htmlFor="fileUpload">{t('editClassStage.welcomePackDocumentComponent.fileUpload.label', 'Upload file')}</Label>
                            <Row>
                                <Col>
                                    <UploadedFilePreview blobReference={contentsFile} />
                                    <Row>
                                        <Col>
                                            <ButtonGroup>
                                                <FileUploadButton
                                                    color={`primary`}
                                                    isExecuting={isUploadingContentsFile}
                                                    executingChildren={<><Spinner size="sm" /> {t('common.uploading', 'Uploading...')}</>}
                                                    onUpload={onUploadContentsFile}
                                                    outline={false}>
                                                    {t('editClassStage.welcomePackDocumentComponent.uploadButtonText', 'Upload a file')}
                                                </FileUploadButton>
                                                <ButtonAsync color="primary"
                                                    outline
                                                    isExecuting={isClearingContentsFile}
                                                    type="button"
                                                    onClick={clearContentsFile}
                                                    executingChildren={<><Spinner size="sm" /> {t('editClassStage.clearingFile', 'Clearing file...')}</>}>
                                                    {t('editClassStage.welcomePackDocumentComponent.clearUploadButton', 'Clear file')}
                                                </ButtonAsync>
                                            </ButtonGroup>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </FormGroup>
                    </Col>
                    <Col xs='auto'>
                        <FormGroup>
                            <Label htmlFor="name">&nbsp;</Label>
                            <div>
                                <Button color="danger" outline onClick={() => remove()}>
                                    <FontAwesomeIcon icon="trash-alt" />
                                    <> </>
                                    {t('ediClassStage.welcomePackDocumentComponent.delete', 'Delete lesson document')}
                                </Button>
                            </div>
                        </FormGroup>
                    </Col>
                </Row>
            </ListGroupItem> 
        </>
    );
};