import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { usePreferredListViewMode } from "../../globalState/preferredListViewMode/usePreferredListViewMode";
import { useReplaceSearchParamsEffect, useSearchParams } from '../../shared/useURLSearchParams';
import { Banner } from '../shared/banner/Banner';
import { StickyToolbar } from '../shared/stickyToolbar/StickyToolbar';
import { Button, CardTitle, Col, Input, Row } from 'reactstrap';
import { ConditionalFragment } from 'react-conditionalfragment';
import { LoadingIndicator } from '../shared/loadingIndicator/LoadingIndicator';
import { CardsOrTableToggle } from '../shared/cardsOrTable/CardsOrTableToggle';
import { SearchInput } from '../shared/searchInput/SearchInput';
import { LinkContainer } from 'react-router-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MainContainer } from '../shared/mainContainer/MainContainer';
import { AlertOnErrors } from '../../shared/alertOnErrors';
import { CardsOrTable } from '../shared/cardsOrTable/CardsOrTable';
import { NoResultsFound } from '../shared/noResultsFound/NoResultsFound';
import { Waypoint } from 'react-waypoint';
import { useExpiringQualificationsListViewModel } from '../../api/main/staffQualificationChecks/viewModels/useExpiringQualificationsListViewModel';
import moment from "moment";

/**
 * StaffList filtered to just show teachers.
 */
export const ExpiringQualificationsList = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    // Load data.
    const {
        data: {
            items: _allItems,
            profiles
        }, isLoading, errors: loadingErrors, fetchMore, hasMore } = useExpiringQualificationsListViewModel({ pageSize: undefined, });

    // Combine all the loaded data so that each item includes its related details
    const allItems = useMemo(() => _allItems?.map(item => {
        const profile = profiles?.find(it => it.id === item.staffId);

        return {
            ...item,
            profile
        };
    }), [_allItems, profiles]);
    

    // Use the preferred view mode for lists.
    const [viewMode] = usePreferredListViewMode();

    // Let the user choose a timeFrame filter.
    const [timeFrame, setTimeFrame] = useState<number>(1);

    // Search.
    const { search: searchParam } = useSearchParams();
    const [search, setSearch] = useState<string>(searchParam ?? '');

    // Keep the URL up to date with the search text.
    useReplaceSearchParamsEffect({ search: search });

    // Filter by the search.
    const items = useMemo(() => {
        let ret = (allItems ?? []);

        // Filter to show only things that have expired or will expire within a month.
        const dateFilter = moment().add(timeFrame, 'month');
        ret = ret.filter(item =>
            !!item.reviewDate // Has a review date
            && moment(item.reviewDate) <= dateFilter // Review date is expired or will expire before dateFilter (e.g. 1 month).
        );

        // Filter by the user's search.
        let lowerSearch = search.toLocaleLowerCase();
        if (lowerSearch) {
            // Just filtering by all string values that arent ids to start with. Most likely wont need them all for search.
            ret = ret.filter(item =>
                item.name.toLocaleLowerCase().indexOf(lowerSearch) >= 0
                || item.reviewDate.toString().toLocaleLowerCase().indexOf(lowerSearch) >= 0
                || (item.profile?.firstName ?? '').toLocaleLowerCase().indexOf(lowerSearch) >= 0
                || (item.profile?.lastName ?? '').toLocaleLowerCase().indexOf(lowerSearch) >= 0
            );
        }

        return ret;

    }, [allItems, search, timeFrame]);

    // Render the UI.
    //
    return (
        <>
            <Banner fluid>
                <StickyToolbar>
                    <Row>
                        <Col>
                            <h1>
                                {t('expiringQualificationsList.title', 'Expiring qualifications')}
                            </h1>
                        </Col>
                        <ConditionalFragment showIf={isLoading}>
                            <Col xs="auto">
                                <LoadingIndicator size="sm" />
                            </Col>
                        </ConditionalFragment>
                        <Col xs="auto">
                            <Input type="select" value={timeFrame} onChange={e => setTimeFrame(parseInt(e.currentTarget.value))}>
                                <option value={0}>{t('expiringQualificationsList.timeFrame.overdue', 'Overdue')}</option>
                                <option value={1}>{t('expiringQualificationsList.timeFrame.1Month', 'Expires in 1 month or less')}</option>
                                <option value={2}>{t('expiringQualificationsList.timeFrame.2Month', 'Expires in 2 months or less')}</option>
                                <option value={3}>{t('expiringQualificationsList.timeFrame.3Month', 'Expires in 3 months or less')}</option>
                            </Input>
                        </Col>
                        <Col xs="auto">
                            <CardsOrTableToggle />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <SearchInput value={search} onChange={e => setSearch(e.currentTarget.value)} />
                        </Col>
                    </Row>
                </StickyToolbar>
            </Banner>
            <MainContainer fluid>
                <AlertOnErrors errors={loadingErrors} />
                <CardsOrTable
                    viewMode={viewMode}
                    items={items}
                    tableHeadings={[
                        null, /* Handles the card specific function for which we don't want a table column. */
                        t('expiringQualificationsList.qualification.heading', 'Qualification'),
                        t('expiringQualificationsList.reveiwDate.heading', 'Review date'),
                        t('expiringQualificationsList.staffName.heading', 'Staff name'),
                    ]}
                    columns={[
                        // We handle the card layout as a specific column ignored from the table.
                        (item, view) => {
                            // This column doesn't show in the table.
                            if (view !== 'cards') {
                                return null;
                            }
                            return (
                                <>
                                    <CardTitle tag="h5">{item.name}</CardTitle>
                                    <h6>{t('expiringQualificationsList.reveiwDate.value', 'Review date: {{date, DD/MM/YYYY}}', { date: moment(item.reviewDate)})}</h6>
                                    <h6>{t('common.fullName', '{{firstName}} {{lastName}}', { firstName: item.profile?.firstName, lastName: item.profile?.lastName })}</h6>
                                </>
                            );
                        },

                        // The remaining columns are for table mode only.

                        // Site - a combination of the name and the summary.
                        (item, view) => {
                            if (view !== 'table') {
                                return null;
                            }

                            return item.name;
                        },

                        // reviewDate
                        (item, view) => {
                            if (view !== 'table') {
                                return null;
                            }

                            return t('common.date', '{{date, DD/MM/YYYY}}', { date: moment(item.reviewDate) });
                        },

                        // staffName
                        (item, view) => {
                            if (view !== 'table') {
                                return null;
                            }

                            return t('common.fullName', '{{firstName}} {{lastName}}', { firstName: item.profile?.firstName, lastName: item.profile?.lastName });
                        },

                    ]}

                    buttons={(item) => (
                        <LinkContainer to={`/administration/staff-management/staff/edit/${item.staffId}?tab=qualifications`}>
                            <Button color="primary">
                                <FontAwesomeIcon icon="edit" />
                                <> {t('common.edit', 'Edit')}</>
                            </Button>
                        </LinkContainer>
                    )}
                    onItemClick={item => navigate(`/administration/staff-management/staff/edit/${item.staffId}?tab=qualifications`)}
                />

                <ConditionalFragment showIf={isLoading && !items?.length}>
                    <LoadingIndicator fullWidth />
                </ConditionalFragment>
                <ConditionalFragment showIf={!isLoading && !items?.length}>
                    <NoResultsFound search={search} />
                </ConditionalFragment>
                <ConditionalFragment showIf={!isLoading && hasMore()}>
                    <Waypoint key={items?.length ?? 0} onEnter={fetchMore} />
                    <LoadingIndicator fullWidth />
                </ConditionalFragment>
            </MainContainer>
        </>
    );

};

