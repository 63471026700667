import gql from 'graphql-tag';
import { Guid } from 'guid-string';
import { ScheduledClassStaffFieldsFragment } from '../generated/graphql';

export const scheduledClassStaffFieldsFragment = gql`
    fragment scheduledClassStaffFields on ScheduledClassStaff {
        id
        scheduledClassId
        staffId
        roleInClass
        isSubsitute
        archived
        subsituteStartDate
        subsituteEndDate
    }
`;

export type ScheduledClassStaff = Omit<ScheduledClassStaffFieldsFragment, '__typename'>;

/**
 * Default values.
 */
export const scheduledClassStaffDefaultValues = (): Partial<ScheduledClassStaff> => ({
    id: Guid.newGuid(),
    scheduledClassId: undefined,
    staffId: undefined,
    roleInClass: '',
    isSubsitute: false,
    archived: false,
    subsituteStartDate: null,
    subsituteEndDate: null,
});