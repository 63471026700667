import moment from "moment";
import { useEffect, useMemo, useState } from "react";
import { ConditionalFragment } from "react-conditionalfragment";
import { useTranslation } from "react-i18next";
import { CardSubtitle, CardTitle, Col, Row } from "reactstrap";
import { useScheduledClassCancellationsListViewModel } from "../../api/main/scheduledClasses/viewModels/useScheduledClassCancellationsListViewModel";
import { useCurrentTerm } from "../../api/main/terms/useCurrentTerm";
import { usePreferredListViewMode } from "../../globalState/preferredListViewMode/usePreferredListViewMode";
import { AlertOnErrors } from "../../shared/alertOnErrors";
import { useLocalStorage } from "../../shared/useLocalStorage";
import { useReplaceSearchParamsEffect, useSearchParams } from "../../shared/useURLSearchParams";
import { getScheduledClassSummary } from "../scheduledClass/getScheduledClassSummary";
import { ClassScheduleFilterState } from "../scheduledClass/schedule/ClassSchedule";
import { Banner } from "../shared/banner/Banner";
import { CardsOrTable } from "../shared/cardsOrTable/CardsOrTable";
import { CardsOrTableToggle } from "../shared/cardsOrTable/CardsOrTableToggle";
import { LoadingIndicator } from "../shared/loadingIndicator/LoadingIndicator";
import { MainContainer } from "../shared/mainContainer/MainContainer";
import { SearchInput } from "../shared/searchInput/SearchInput";
import { StickyToolbar } from "../shared/stickyToolbar/StickyToolbar";
import { TermSelector } from "../shared/termSelector/TermSelector";

/**
 * Component to display a List of CancelledClasses
 */
export const CancelledClassesList = () => {
    const { t } = useTranslation();

    // Load the data
    const {
        data: {
            items: _items,
            cancellationReasons,
            staffProfiles,
            classStages,
            classSubStages,
            classTypes,
            terms
        }, isLoading: isLoadingData, errors: loadErrors
    } = useScheduledClassCancellationsListViewModel();

    // Load the current term
    const {
        data: {
            model: currentTerm
        }, isLoading: isLoadingCurrentTerm, errors: currentTermLoadErrors
    } = useCurrentTerm();
    const isLoading = isLoadingData || isLoadingCurrentTerm;

    // Combines all the loaded data so that each item includes its related details
    const allItems = useMemo(() => _items?.map(item => {
        const cancellationReason = cancellationReasons?.find(it => it.id === item.cancellationReasonId);
        const staffProfile = staffProfiles?.find(it => it.userId === item.cancelledByStaffUserId);
        const classStage = classStages?.find(it => it.id === item.scheduledClass.currentClassStageId);
        const classSubStage = classSubStages?.find(it => it.id === item.scheduledClass.currentClassSubStageId);
        const term = terms?.find(it => it.id === item.scheduledClass.termId);

        return {
            ...item,
            cancellationReason,
            staffProfile,
            classStage,
            classSubStage,
            term
        };
    }), [_items, cancellationReasons, staffProfiles, classStages, classSubStages, terms]);

    // Use the preferred view mode for lists.
    const [viewMode] = usePreferredListViewMode();

    // Search.
    const { search: searchParam } = useSearchParams();
    const [search, setSearch] = useState<string>(searchParam ?? '');

    // Keep the URL up to date with the search text.
    useReplaceSearchParamsEffect({ search: search });

    // Allow filtering (persisted to local storage between sessions).
    const [filterState, setFilterState] = useLocalStorage<ClassScheduleFilterState>('classSchedule.filterState', {});

    // Filter by the search.
    const items = useMemo(() => {
        let ret = (allItems ?? []);

        let lowerSearch = search.toLocaleLowerCase();
        if (lowerSearch) {
            ret = ret.filter(item =>
                (item.cancellationReason?.name ?? '').toLocaleLowerCase().indexOf(lowerSearch) >= 0
                || item.scheduledClass.classLocation.name.toLocaleLowerCase().indexOf(lowerSearch) >= 0
                || (item.staffProfile?.firstName ?? '').toLocaleLowerCase().indexOf(lowerSearch) >= 0
                || (item.staffProfile?.lastName ?? '').toLocaleLowerCase().indexOf(lowerSearch) >= 0
                || item.message.toLocaleLowerCase().indexOf(lowerSearch) >= 0
            );
        }

        if (filterState.termId) {
            ret = ret?.filter(item => item?.term?.id === filterState.termId);
        }

        return ret;

    }, [allItems, search, filterState]);

    // Set the default filter state to the current term.
    useEffect(() => {
        if (!filterState.termId) {
            setFilterState({ termId: currentTerm?.id });
        }
    }, [filterState, currentTerm?.id, setFilterState]);

    // Render the UI
    //
    return (
        <>
            <Banner fluid>
                <StickyToolbar>
                    <Row>
                        <Col>
                            <h1>
                                {t('cancelledClassesList.title', 'Cancelled classes')}
                            </h1>
                        </Col>
                        <ConditionalFragment showIf={isLoading}>
                            <Col xs="auto">
                                <LoadingIndicator size="sm" />
                            </Col>
                        </ConditionalFragment>
                        <Col xs="auto">
                            <CardsOrTableToggle />
                        </Col>
                    </Row>
                    <Row>
                        <Col xs="auto">
                            <TermSelector selectedTermId={filterState.termId} setFilterState={({ termId: value }) => setFilterState({ termId: value })} restrictToTermsWithClasses={true} showPreviousTerms={true} />
                        </Col>
                        <Col>
                            <SearchInput value={search} onChange={e => setSearch(e.currentTarget.value)} />
                        </Col>
                    </Row>
                </StickyToolbar>
            </Banner>

            <MainContainer fluid>
                <AlertOnErrors
                    errors={[
                        loadErrors,
                        currentTermLoadErrors
                    ]}
                />

                <CardsOrTable
                    viewMode={viewMode}
                    items={items}
                    tableHeadings={[
                        null /* Handles the card specific function for which we don't want a table column. */,
                        t('cancelledClassesList.term.heading', 'Term'),
                        t('cancelledClassesList.name.heading', 'Class summary'),
                        t('cancelledClassesList.classType.heading', 'Activity'),
                        t('cancelledClassesList.cancellationReason.heading', 'Cancellation reason'),
                        t('cancelledClassesList.cancelledOn.heading', 'Cancelled date'),
                        t('cancelledClassesList.cancelledBy.heading', 'Cancelled by'),
                        t('cancelledClassesList.lessonDate.heading', 'Lesson date'),
                        t('cancelledClassesList.lessonNumber.heading', 'Lesson number'),
                        t('cancelledClassesList.message.heading', 'Message'),
                        t('cancelledClassesList.emailSent.heading', 'Email sent'),
                        t('cancelledClassesList.smsSent.heading', 'SMS Sent'),
                    ]}
                    columns={[
                        (item, view) => {
                            // This column doesn't show in the table.
                            if (view !== 'cards') {
                                return null;
                            }

                            return (
                                <>
                                    <CardTitle tag="h5">
                                        {getScheduledClassSummary(item.scheduledClass, { classLocation: item.scheduledClass.classLocation, classStage: item.classStage, classSubStage: item.classSubStage })}
                                    </CardTitle>
                                    <CardSubtitle>
                                        <Row>
                                            <Col>
                                                {t('cancelledClassesList.cancelledOn.header', 'Cancelled on')}
                                                <> </>
                                                {t('common.date', '{{date, DD/MM/YYYY}}', { date: moment(item.cancelledAt) })}
                                                <> </>
                                                {t('common.by', 'by')}
                                                <> </>
                                                {t('common.fullName', '{{firstName}} {{lastName}}', { firstName: item.staffProfile?.firstName ?? '', lastName: item.staffProfile?.lastName ?? '' })}
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col>
                                                {t('cancelledClassesList.dateOfClass', 'Date of class')}
                                                <> </>
                                                {t('common.date', '{{date, DD/MM/YYYY}}', { date: moment(item.lessonDate) })}
                                            </Col>
                                            <Col>
                                                {t('cancelledClassesList.lessonNumber.header', 'Lesson')}
                                                <> </>
                                                {item.termLessonNumber}
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col>
                                                {item.cancellationReason?.name}
                                            </Col>
                                            <Col>
                                                {item.message}
                                            </Col>
                                        </Row>
                                    </CardSubtitle>
                                </>
                            );
                        },

                        // The remaining columns are for table mode only.

                        // Term
                        (item, view) => {
                            if (view !== 'table') {
                                return null;
                            }

                            return item.term?.name;
                        },

                        // Name
                        (item, view) => {
                            if (view !== 'table') {
                                return null;
                            }

                            return getScheduledClassSummary(item.scheduledClass, { classLocation: item.scheduledClass.classLocation, classStage: item.classStage, classSubStage: item.classSubStage });
                        },

                        // Class type
                        (item, view) => {
                            if (view !== 'table') {
                                return null;
                            }

                            return classTypes?.find(it => it.id === item.scheduledClass.classTypeId)?.name;
                        },

                        // Cancellation  reason
                        (item, view) => {
                            if (view !== 'table') {
                                return null;
                            }

                            return item.cancellationReason?.name;
                        },

                        // Cancelled on
                        (item, view) => {
                            if (view !== 'table') {
                                return null;
                            }

                            return t('common.date', '{{date, DD/MM/YYYY}}', { date: moment(item.cancelledAt) });
                        },

                        // Cancelled by
                        (item, view) => {
                            if (view !== 'table') {
                                return null;
                            }

                            return t('common.fullName', '{{firstName}} {{lastName}}', { firstName: item.staffProfile?.firstName, lastName: item.staffProfile?.lastName });
                        },

                        // Lesson date
                        (item, view) => {
                            if (view !== 'table') {
                                return null;
                            }

                            return t('common.date', '{{date, DD/MM/YYYY}}', { date: moment(item.lessonDate) });
                        },

                        // Lesson number
                        (item, view) => {
                            if (view !== 'table') {
                                return null;
                            }

                            return item.termLessonNumber;
                        },

                        // Message
                        (item, view) => {
                            if (view !== 'table') {
                                return null;
                            }

                            return item.message;
                        },

                        // Email sent
                        (item, view) => {
                            if (view !== 'table') {
                                return null;
                            }

                            return item.emailsSent ? t('common.yes', 'Yes') : t('common.no', 'No');
                        },

                        // SMS sent
                        (item, view) => {
                            if (view !== 'table') {
                                return null;
                            }

                            return item.smsSent ? t('common.yes', 'Yes') : t('common.no', 'No');
                        }
                    ]}
                />
            </MainContainer>
        </>
    );
};