import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./addChildComponent.scss";
import { useNavigate } from "react-router-dom";

export interface ChildComponentProps {
    isFirstChild: boolean,
    memberProfileId: string | undefined | null,
 
}

/**
 * Component under MembersChildren that lets the user add a child.
 */
export const AddChildComponent = (props: ChildComponentProps) => {
    const {
        isFirstChild,
        memberProfileId
    } = props;

    const { t } = useTranslation();
    const navigate = useNavigate();

    // Render the UI
    //
    return (
        <>
            <div className="add-child-component" onClick={() => navigate(`add/${memberProfileId}`)}>
                <p className="add-child-component-icon"><FontAwesomeIcon icon="plus-circle" /></p>
                <p className="add-child-component-text">
                    {
                        isFirstChild ? t('addChildComponent.text', 'Add your bear cub',)
                            : t('addAdditionalChildComponent.text', 'Add another sibling',)
                    }
                </p>
            </div>
        </>
    );
};