import { Row, Col, Input, Card, CardBody, CardHeader, Button } from 'reactstrap';
import { AlertOnErrors } from '../../../shared/alertOnErrors';
import { LoadingIndicator } from '../../shared/loadingIndicator/LoadingIndicator';
import { useTranslation } from 'react-i18next';
import { MainContainer } from '../../shared/mainContainer/MainContainer';
import { StickyToolbar } from '../../shared/stickyToolbar/StickyToolbar';
import { ConditionalFragment } from 'react-conditionalfragment';
import { Banner } from '../../shared/banner/Banner';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { ScheduleTimetableViewDimensions } from './ScheduledTimetableViewDimensions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from 'react-router-dom';
import { ScheduledClass } from '../../../api/main/models/ScheduledClass';
import { useLocalStorage } from '../../../shared/useLocalStorage';
import { RoleInClass } from '../../../api/main/models/constants/RoleInClass';
import { Guid } from 'guid-string';
import { useCurrentTerm } from '../../../api/main/terms/useCurrentTerm';
import { useTermsWithClasses } from '../../../api/main/terms/viewModels/useTermsWithClasses';
import { TermSelector } from '../../shared/termSelector/TermSelector';
import { ScheduleTimetableView } from './ScheduleTimetableView';
import moment from 'moment';
import { useClassTimetableViewModel } from '../../../api/main/scheduledClasses/viewModels/useClassTimetableViewModel';
import "./classTimetable.scss";

/**
 * Structure of the filter state used for filtering.
 */
export interface ClassTimetableFilterState {
    classTypeId?: string | undefined | null,
    classStageId?: string | undefined | null,
    classSubStageId?: string | undefined | null,
    classLocationId?: string | undefined | null,
    staffId?: string | undefined | null,
    termId?: string | undefined | null,
    dayOfWeek?: string | undefined | null;
}
/**
 * Interactive schedule of all classes.
 */
export const ClassTimetable = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    // Load all the terms
    const {
        data: {
            items: allTerms,
        }, isLoading: isLoadingTerms, errors: termsLoadingErrors,
    } = useTermsWithClasses();

    // Load the current term
    const {
        data: {
            model: currentTerm
        }, isLoading: isLoadingCurrentTerm, errors: currentTermLoadingErrors,
    } = useCurrentTerm();

    // Allow filtering (persisted to local storage between sessions).
    const [filterState, setFilterState] = useLocalStorage<ClassTimetableFilterState>('classSchedule.filterState', {});
    const changeFilterState = useCallback((changes: Partial<ClassTimetableFilterState>) => setFilterState(prevState => ({
        ...prevState,
        ...changes,
    })), [setFilterState]);

    // Load data.
    const {
        data: {
            items: _allItems,
            classTypes,
            classLocations,
            classStages,
            classSubStages,
            profiles,
            scheduledClassStaffs,
            classLocationClassTypes,
            scheduledClassCancellations,
            termHolidays,
            staffAbsences
        },
        isLoading: isLoadingData, errors: loadingErrors,
    } = useClassTimetableViewModel(filterState?.termId ?? currentTerm?.id);
    const isLoading = isLoadingData || isLoadingTerms || isLoadingCurrentTerm;

    // Combine all the loaded data so each item includes its related details.
    const allItems = useMemo(() => _allItems?.map(item => {
        // Follow the various lookups for related data.
        const classType = classTypes?.find(it => it.id === item.classTypeId);
        const classLocation = classLocations?.find(it => it.id === item.classLocationId);
        const currentClassStage = classStages?.find(it => it.id === item.currentClassStageId);
        const currentClassSubStage = classSubStages?.find(it => it.id === item.currentClassSubStageId);
        const nextClassStage = classStages?.find(it => it.id === item.nextClassStageId);
        const nextClassSubStage = classSubStages?.find(it => it.id === item.nextClassSubStageId);
        const classCancellations = scheduledClassCancellations?.filter(it => it.scheduledClassId === item.id);
        const holidays = termHolidays?.filter(it => it.termId === item.termId);

        // Work out the staff from profiles and scheduledClassStaff
        const myStaffLinks = scheduledClassStaffs.filter(it => it.scheduledClassId === item.id);
        const primaryTeacherLink = myStaffLinks.find(it => it.roleInClass === RoleInClass.PrimaryTeacher && !it.isSubsitute);

        // Work out if we have a substitue primary teacher
        const substitutePrimaryTeacherLink = myStaffLinks.find(it => it.roleInClass === RoleInClass.PrimaryTeacher && it.isSubsitute);

        const allStaff = myStaffLinks.map(link => {
            const profile = profiles.find(it => it.id === link.staffId);
            return {
                ...(profile ?? {}),

                scheduledClassStaff: link,
                absences: staffAbsences.filter(it => it.staffId === link.staffId),
            };
        });
        const primaryTeacher = allStaff.find(it => it.scheduledClassStaff.id === primaryTeacherLink?.id);
        const substitutePrimaryTeacher = allStaff.find(it => it.scheduledClassStaff.id === substitutePrimaryTeacherLink?.id);

        // Return everything as a single object.
        return {
            ...item,

            classType,
            classLocation,
            classStage: currentClassStage,
            classSubStage: currentClassSubStage,
            nextClassStage,
            nextClassSubStage,

            primaryTeacher,
            allStaff,
            classCancellations,
            holidays,
            substitutePrimaryTeacher,
        };
    }).filter(item => !!item.classStage?.id) // Remove anything that doesn't have a stage in the term being displayed.
        , [_allItems, classTypes, classLocations, classStages, classSubStages,
            profiles, scheduledClassStaffs, scheduledClassCancellations, termHolidays, staffAbsences
        ]);
    // Filter the items that can be filtered by other filter choices.
    const classStagesForFilter = useMemo(() => classStages?.filter(item => item.classTypeId === filterState.classTypeId), [classStages, filterState]);
    const classSubStagesForFilter = useMemo(() => classSubStages?.filter(item => item.classStageId === filterState.classStageId), [classSubStages, filterState]);

    // Filter the items to show based on the filter.
    const items = useMemo(() => {
        let ret = allItems;

        if (filterState.classTypeId) {
            ret = ret?.filter(item => item.classTypeId === filterState.classTypeId);
        }

        if (filterState.classStageId) {
            ret = ret?.filter(item => item.currentClassStageId === filterState.classStageId);
        }

        if (filterState.classSubStageId) {
            ret = ret?.filter(item => item.currentClassSubStageId === filterState.classSubStageId);
        }

        if (filterState.classLocationId) {
            ret = ret?.filter(item => item.classLocationId === filterState.classLocationId);
        }

        if (filterState.staffId) {
            // Special handling for "No primary teacher assigned" represented by Guid.empty.
            if (filterState.staffId === Guid.empty) {
                ret = ret?.filter(item => !item.primaryTeacher?.id);
            } else {
                // Otherwise we can find the staff member in any role, not just the primary teacher role.
                ret = ret?.filter(item => !!item.allStaff.find(link => link.id === filterState.staffId));
            }
        }

        if (filterState.termId) {
            ret = ret?.filter(item => item.termId === filterState.termId);
        }

        if (filterState.dayOfWeek) {
            ret = ret?.filter(item => item.dayOfWeek.toString() === filterState.dayOfWeek);
        }

        return ret;
    }, [allItems, filterState]);
    const resetFilters = useCallback(() => setFilterState({ termId: currentTerm?.id }), [setFilterState, currentTerm?.id]);
  
    // Filtering the ClassLocations -> If viewing a specific class type, only show the locations that have that class type.
    const filteredClassLocations = useMemo(() => {
        if (!classLocations || !classLocationClassTypes) {
            return;
        }

        // If we aren't filtering by class type, return all class locations.
        if (!filterState?.classTypeId) {
            return classLocations;
        }

        // Otherwise, return only the class locations that have the class type.
        return classLocations.filter(classLocation => {
            const matchingClassTypes = classLocationClassTypes.filter(classLocationClassType => {
                return classLocationClassType.classLocationId === classLocation.id && classLocationClassType.classTypeId === filterState.classTypeId;
            });

            return matchingClassTypes.length > 0;
        });
    }, [filterState, classLocations, classLocationClassTypes]);

    // View settings.
    const {
        groupDimension,
        rowDimension,
        columnDimension,
        colorDimension,
    } = useMemo(() => {
        // Defaults 
        const groupDimension = ScheduleTimetableViewDimensions['classType'];
        const rowDimension = ScheduleTimetableViewDimensions['startTimeHour'];
        const columnDimension =  ScheduleTimetableViewDimensions['dayOfWeek'];
        const colorDimension =  ScheduleTimetableViewDimensions['classLocation'];


        return {
            groupDimension,
            rowDimension,
            columnDimension,
            colorDimension,
        };
    }, []);

    // Showing and hiding UI elements (persisted to local storage between sessions).
    const [isFiltersVisible, _setIsFiltersVisible] = useLocalStorage('classTimetable.isFiltersVisible', false);
    const toggleFiltersVisible = useCallback(() => _setIsFiltersVisible(prevState => !prevState), [_setIsFiltersVisible]);

    // An event has been clicked, so open it.
    const onEventClick = useCallback((event: ScheduledClass) => {
        navigate(`/administration/class-management/classes/edit/${event.id}`);
    }, [navigate]);

    // TODO - If we don't have the currentTerm selected then this should be the start of the selected term.
    // Get the date of the start of the week. Monday
    const getStartOfWeek = useMemo(() => {
        const today = new Date();
        const dayOfWeek = today.getDay();
        const diff = today.getDate() - dayOfWeek + (dayOfWeek === 0 ? -6 : 1); // adjust when day is sunday

        const date = new Date(today.setDate(diff));
        return date;
    }, []);

    // State for the date last Monday
    const [startOfWeek, setStartOfWeek] = useState(getStartOfWeek);

    // Change the start of the week
    const changeWeek = useCallback((change: number): void => {
        const newDate = new Date(startOfWeek);
        const date = newDate.setDate(newDate.getDate() + (7 * change));
        setStartOfWeek(new Date(date));
    }, [startOfWeek]);

    const endOfWeek = useMemo(() => {
        const val = new Date(startOfWeek);

        return new Date(val.setDate(val.getDate() + 6));
    }, [startOfWeek]);
    const selectedTerm = useMemo(() => allTerms?.find(it => it.id === filterState.termId), [allTerms, filterState.termId]);

    // If there is no term selected, select the current term.
    useEffect(() => {
        if (!filterState.termId) {
            setFilterState({ termId: currentTerm?.id });
        }
    }, [filterState, currentTerm?.id, setFilterState]);
   
    // Render the UI.
    //
    return (
        <>
            <Banner fluid>
                <StickyToolbar>
                    <Row>
                        <Col xs={12} sm="auto">
                            <h1>
                                {t('classTimetable.title', 'Class timetable')}
                            </h1>
                            <h4>{allTerms?.find(it => it.id === filterState.termId)?.name}</h4>
                        </Col>
                        <Col>
                            <Button color="primary" outline={!isFiltersVisible} onClick={() => toggleFiltersVisible()}>
                                <FontAwesomeIcon icon="filter" />
                                <> </>
                                {t('classTimetable.toggleFilters', 'Filter')}
                            </Button>
                        </Col>
                        <ConditionalFragment showIf={isLoading}>
                            <Col xs="auto">
                                <LoadingIndicator size="sm" />
                            </Col>
                        </ConditionalFragment>
                    </Row>

                    {/* Filters */}
                    <ConditionalFragment showIf={isFiltersVisible}>
                        <Card className="mb-2">
                            <CardHeader>
                                <h5>{t('classTimetable.filters.heading', 'Filter')}</h5>
                            </CardHeader>
                            <CardBody>
                                <Row>
                                    <Col xs="auto">
                                        <TermSelector selectedTermId={filterState.termId} setFilterState={({ termId: value }) => setFilterState({ termId: value })} restrictToTermsWithClasses={true} showPreviousTerms={true} />
                                    </Col>
                                    <Col xs="auto">
                                        <Input type="select" value={filterState.dayOfWeek ?? ''} onChange={e => changeFilterState({ dayOfWeek: e.currentTarget.value, })}>
                                            <option value="">{t('classTimetable.filter.dayOfWeek.all', '(All days)')}</option>
                                            <option value="0">{t('classTimetable.filter.dayOfWeek.sunday', 'Sunday')}</option>
                                            <option value="1">{t('classTimetable.filter.dayOfWeek.monday', 'Monday')}</option>
                                            <option value="2">{t('classTimetable.filter.dayOfWeek.tuesday', 'Tuesday')}</option>
                                            <option value="3">{t('classTimetable.filter.dayOfWeek.wednesday', 'Wednesday')}</option>
                                            <option value="4">{t('classTimetable.filter.dayOfWeek.thursday', 'Thursday')}</option>
                                            <option value="5">{t('classTimetable.filter.dayOfWeek.friday', 'Friday')}</option>
                                            <option value="6">{t('classTimetable.filter.dayOfWeek.saturday', 'Saturday')}</option>
                                        </Input>
                                    </Col>
                                    <Col>
                                        <Input type="select" value={filterState.classTypeId ?? ''} onChange={e => changeFilterState({ classTypeId: e.currentTarget.value, classStageId: '', classSubStageId: '', })}>
                                            <option value="">{t('classTimetable.filter.classTypes.all', '(All activities)')}</option>
                                            {
                                                classTypes?.map(item => (
                                                    <option key={item.id} value={item.id}>{item.name}</option>
                                                ))
                                            }
                                        </Input>
                                    </Col>
                                    <ConditionalFragment showIf={!!filterState.classTypeId}>
                                        <Col>
                                            <Input type="select" value={filterState.classStageId ?? ''} onChange={e => changeFilterState({ classStageId: e.currentTarget.value, classSubStageId: '', })}>
                                                <option value="">{t('classTimetable.filter.classStages.all', '(All class types)')}</option>
                                                {
                                                    classStagesForFilter?.map(item => (
                                                        <option key={item.id} value={item.id}>{item.name}</option>
                                                    ))
                                                }
                                            </Input>
                                        </Col>
                                    </ConditionalFragment>
                                    <ConditionalFragment showIf={!!filterState.classStageId}>
                                        <Col>
                                            <Input type="select" value={filterState.classSubStageId ?? ''} onChange={e => changeFilterState({ classSubStageId: e.currentTarget.value })}>
                                                <option value="">{t('classTimetable.filter.classSubStages.all', '(All stages)')}</option>
                                                {
                                                    classSubStagesForFilter?.map(item => (
                                                        <option key={item.id} value={item.id}>{item.name}</option>
                                                    ))
                                                }
                                            </Input>
                                        </Col>
                                    </ConditionalFragment>
                                    <Col>
                                        <Input type="select" value={filterState.classLocationId ?? ''} onChange={e => changeFilterState({ classLocationId: e.currentTarget.value })}>
                                            <option value="">{t('classTimetable.filter.classLocations.all', '(All locations)')}</option>
                                            {
                                                filteredClassLocations?.map(item => (
                                                    <option key={item.id} value={item.id}>{item.name}</option>
                                                ))
                                            }
                                        </Input>
                                    </Col>
                                    <Col>
                                        <Input type="select" value={filterState.staffId ?? ''} onChange={e => changeFilterState({ staffId: e.currentTarget.value })}>
                                            <option value="">{t('classTimetable.filter.staff.all', '(All staff)')}</option>
                                            <option value={Guid.empty} >{t('classTimetable.filter.staff.noPrimaryTeacher', '(No primary teacher assigned)')}</option>
                                            {
                                                profiles?.map(item => (
                                                    <option key={item.id} value={item.id}>{t('common.fullName', '{{firstName}} {{lastName}}', { firstName: item.firstName ?? '', lastName: item.lastName ?? '' })}</option>
                                                ))
                                            }
                                        </Input>
                                    </Col>
                                </Row>
                                <Button color="link" onClick={resetFilters}>
                                    {t('classTimetable.filters.reset', 'Reset filters')}
                                </Button>
                            </CardBody>
                        </Card>
                    </ConditionalFragment>
                </StickyToolbar>
            </Banner>

            <MainContainer fluid>
                <AlertOnErrors errors={[
                    loadingErrors,
                    termsLoadingErrors,
                    currentTermLoadingErrors,
                ]} />

                <Row className="class-timetable">
                    <Col xs="auto">
                        <Button color="primary" outline onClick={() => changeWeek(-1)} disabled={moment(selectedTerm?.startDate).isBetween(startOfWeek.toDateString(), endOfWeek.toDateString(), undefined, '[]')}>
                            <FontAwesomeIcon icon="chevron-left" />
                            <> </>
                            {t('common.previousWeek', 'Previous week')}
                        </Button>
                    </Col>
                    <Col>
                        <h2 className="class-timetable-week-beginning">
                            {t('common.weekBeginning', 'Week beginning')} {t('common.dateFull', '{{date, DD MMMM YYYY}}', { date: startOfWeek })}
                        </h2>
                    </Col>
                    <Col xs="auto">
                        <Button color="primary" outline onClick={() => changeWeek(1)} disabled={moment(selectedTerm?.endDate).isBetween(startOfWeek.toDateString(), endOfWeek.toDateString(), undefined, '[]')}>
                            {t('common.nextWeek', 'Next week')}
                            <> </>
                            <FontAwesomeIcon icon="chevron-right" />
                        </Button>
                    </Col>
                </Row>

                <ScheduleTimetableView
                    items={items ?? []}
                    groupDimension={groupDimension}
                    rowDimension={rowDimension}
                    columnDimension={columnDimension}
                    colorDimension={colorDimension}
                    onEventClick={onEventClick}
                    startOfWeek={startOfWeek}
                    term={selectedTerm}
                />
            </MainContainer>
        </>
    );
};