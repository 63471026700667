import { useMutation } from "@apollo/react-hooks";
import { useAsyncCallback } from 'react-use-async-callback';
import gql from "graphql-tag";
import { AsyncActionStatus } from '../../../shared/abstractStore';
import { CreateAmenityTypeMutation, CreateAmenityTypeMutationVariables, AmenityTypeCreateInput } from '../generated/graphql';
import { amenityTypeFieldsFragment } from '../models/AmenityType';

/**
 * Get a callback to create a AmenityType in the api.
 */
export function useCreateAmenityTypeMutation(): [(model: AmenityTypeCreateInput) => Promise<void>, AsyncActionStatus] {
    const [mutationAction, { error }] = useMutation<CreateAmenityTypeMutation, CreateAmenityTypeMutationVariables>(
        gql`
            mutation createAmenityTypeMutation ($model: AmenityTypeCreateInput!) {
                createAmenityType(model: $model) {
                    ...amenityTypeFields
                }
            }

            ${amenityTypeFieldsFragment}
        `
    );

    // Wrap the mutation in a standard callback.
    const [action, { isExecuting, errors: actionErrors }] = useAsyncCallback(async (model: AmenityTypeCreateInput) => {
        const result = await mutationAction({ variables: { model: model } });
        if (result.errors && result.errors.length) {
            throw result.errors;
        }
    }, [mutationAction]);

    return [action, { isExecuting: isExecuting, errors: error ? error.message : actionErrors }];
}
