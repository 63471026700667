import { gql, useLazyQuery } from "@apollo/client";
import React from "react";
import { mainApiConfig } from "../../../../configure/mainApiConfig";
import { AsyncLoadPagedOptions, AsyncLoadPagedResult } from "../../../../shared/abstractStore";
import { useFetchMoreCallback } from "../../../../shared/useFetchMoreCallback";
import { ClassLocationsListViewModelQuery, ClassLocationsListViewModelQueryVariables } from "../../generated/graphql";
import { amenityTypeFieldsFragment } from "../../models/AmenityType";
import { classLocationFieldsFragment } from "../../models/ClassLocation";
import { classLocationAmenityTypeFieldsFragment } from "../../models/ClassLocationAmenityType";
import { classLocationClassTypeFieldsFragment } from "../../models/ClassLocationClassType";
import { classTypeFieldsFragment } from "../../models/ClassType";

/**
 * Get all the model data required for the ClassLocationsList component from the api
 * @param options
 */
export function useClassLocationsListViewModel(options: AsyncLoadPagedOptions = {}): AsyncLoadPagedResult<ClassLocationsListViewModelQuery> {
    // Query data we need from the api.
    const [load, { data, loading, error, refetch, fetchMore: fetchMoreFromStore, }] = useLazyQuery<ClassLocationsListViewModelQuery, ClassLocationsListViewModelQueryVariables>(
        gql`
        query classLocationsListViewModelQuery ($offset: Int, $first: Int) {
            items: classLocations (offset: $offset, first: $first) {
                ...classLocationFields
            }

            classLocationClassTypes {
                ...classLocationClassTypeFields
            }

            classTypes {
                ...classTypeFields
            }

            classLocationAmenityTypes {
                ...classLocationAmenityTypeFields
            }

            amenityTypes {
                ...amenityTypeFields
            }
        }

        ${classLocationFieldsFragment}
        ${classLocationClassTypeFieldsFragment}
        ${classTypeFieldsFragment}
        ${classLocationAmenityTypeFieldsFragment}
        ${amenityTypeFieldsFragment}
        `,
        {
            variables: {
                offset: options.startOffset,
                first: options.pageSize,
            },
            fetchPolicy: options.fetchPolicy ?? mainApiConfig.defaultFetchPolicy,
            pollInterval: options.pollInterval,
            onCompleted: (data) => {
                if (options.onCompleted) {
                    options.onCompleted(data);
                }
            }
        }
    );

    // Run the query (unless we are being lazy).
    React.useEffect(() => {
        if (!options.lazy) {
            load();
        }
    }, [options.lazy, load]);

    // Get the functions used to manage paging.
    const { fetchMore, hasMore: _hasMore } = useFetchMoreCallback<ClassLocationsListViewModelQuery>(
        fetchMoreFromStore,
        (options?.startOffset ?? 0) + (data?.items?.length ?? 0),
        (prevResults, newResults) => ({
            ...prevResults,
            ...newResults,

            items: [...(prevResults?.items ?? []), ...(newResults?.items ?? [])],
            classLocationClassTypes: newResults?.classLocationClassTypes ?? [],
            classTypes: newResults?.classTypes ?? [],
            classLocationAmenityTypes: newResults?.classLocationAmenityTypes ?? [],
            amenityTypes: newResults?.amenityTypes ?? [],
        }),
        (newResults) => newResults?.items?.length
    );
    const hasMore = React.useCallback(() => !options.pageSize ? false : _hasMore(), [options.pageSize, _hasMore]);

    // Return the results.
    const refresh = React.useCallback(async () => { !refetch ? await load() : await refetch(); }, [refetch, load]);
    return React.useMemo(() => ({
        data: data ?? ({} as any),
        refresh: refresh,
        isLoading: loading,
        errors: error,
        fetchMore: fetchMore,
        hasMore: hasMore,
    }), [data, refresh, loading, error, fetchMore, hasMore]);
}
