import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import { ConditionalFragment } from "react-conditionalfragment";
import { useTranslation } from "react-i18next";
import { LinkContainer } from "react-router-bootstrap";
import { Button, ButtonDropdown, ButtonGroup, Card, CardBody, Col, DropdownItem, DropdownMenu, DropdownToggle, Row } from "reactstrap";
import { useToggleStateArray } from "use-toggle-state";
import { StaffAbsence } from "../../../api/main/models/StaffAbsence";
import { StaffAbsenceType } from "../../../api/main/models/StaffAbsenceType";
import { HappyIcon } from "../../shared/Utilities/HappyIcon";
import { useStaffAbsenceStatusColorAndMessage } from "./useStaffAbsenceStatusColorAndMessage";
import { useStaffAbsenceTypeForIcons } from "./useStaffAbsenceTypeForIcons";

export interface UpcomingStaffAbsenceCardProps {
    model: StaffAbsence | null | undefined,
    staffAbsenceTypes: Array<StaffAbsenceType> | null | undefined,
    handleEditStaffAbsence: (id: string) => void,
}

/**
 * CardComponent for Upcoming StaffAbsences, ones which are currently ongoing, or in the future.
 */
export const UpcomingStaffAbsenceCard = (props: UpcomingStaffAbsenceCardProps) => {
    const { model, staffAbsenceTypes, handleEditStaffAbsence } = props;
    const { t } = useTranslation();

    // Handle the dropdown menu toggle
    const [isMenuOpen, toggleMenuOpen] = useToggleStateArray();

    // Get the color coding and message for better UI
    const { color, statusMessage } = useStaffAbsenceStatusColorAndMessage(model);

    // Get the icon to display along with the absenceType
    const absenceTypeName = staffAbsenceTypes?.find(it => it.id === model?.staffAbsenceTypeId)?.name;
    const icon = useStaffAbsenceTypeForIcons(absenceTypeName ?? '');

    // Render the UI
    //
    return (
        <Card color={color} outline style={{ marginBottom: '10px' }} key={model?.id}>
            <CardBody>
                <Row>
                    <Col>
                        <h4>
                            {t('common.date', '{{date, DD/MM/YYYY}}', { date: moment(model?.startDate) })}

                            <span style={{ fontSize: '14px' }}> {t('staffTimeOffTab.untilDate', 'until {{date, DD/MM/YYYY}}', { date: moment(model?.endDate) })}</span>
                        </h4>
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <ConditionalFragment showIf={!!icon}>
                            {icon}
                        </ConditionalFragment>
                        <> </>

                        {t('staffTimeOffTab.upcomingStaffAbsenceCard.absenceType.value', '{{absenceTypeName}}', { absenceTypeName: absenceTypeName })}
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <HappyIcon icon="info" />
                        <> </>
                        {t('staffTimeOffTab.upcomingStaffAbsenceCard.status.title', 'Status: ') }
                        <span className={`text-${color}`}>
                            {t('staffTimeOffTab.upcomingStaffAbsenceCard.status.message', '{{status}}', { status: statusMessage })}
                        </span> 
                    </Col>

                </Row>

                <Row style={{marginTop: '8px', marginBottom: '4px'} }>
                    <Col>
                        <></>
                    </Col>
                    <Col xs="auto">
                        <ButtonGroup>
                            <Button color="primary" outline onClick={() => handleEditStaffAbsence(model?.id ?? '')}>
                                <HappyIcon icon="calendar" />
                                <> </>
                                {t('staffTimeOffTab.upcomingStaffAbsenceCard.changeDates.button', 'Change dates')}
                            </Button>

                            <ButtonDropdown isOpen={isMenuOpen(model?.id ?? '')} toggle={() => toggleMenuOpen(model?.id ?? '')}>
                                <DropdownToggle color="primary" caret>
                                    <span className="visually-hidden">{t('common.menuDropdown', 'More')}</span>
                                </DropdownToggle>
                                <DropdownMenu end>
                                    <LinkContainer to={`delete/${model?.id}`}>
                                        <DropdownItem className="text-danger">
                                            <FontAwesomeIcon icon="trash" />
                                            <> {t('staffTimeOffTab.upcomingStaffAbsenceCard.cancelRequest.button', 'Cancel request')}</>
                                        </DropdownItem>
                                    </LinkContainer>
                                </DropdownMenu>
                            </ButtonDropdown>
                        </ButtonGroup>
                    </Col>
                </Row>
            </CardBody>
        </Card>
    );
}