import gql from "graphql-tag";
import { Guid } from "guid-string";
import moment from "moment";
import { GiftVoucherFieldsFragment } from "../generated/graphql";

export const giftVoucherFieldsFragment = gql`
    fragment giftVoucherFields on GiftVoucher {
        id
        serialNumber
        archived
        dateAdded
        expiryDate
        isConsumed
        consumedOnDate
        consumedOnPaymentId
        value
        isMultiUse
    }
`;

export type GiftVoucher = Omit<GiftVoucherFieldsFragment, '__typename'>;

/**
 * Default values
 */
export const giftVoucherDefaultValues = (): Partial<GiftVoucher> => ({
    id: Guid.newGuid(),
    serialNumber: '',
    archived: false,
    dateAdded: moment().toISOString(),
    expiryDate: moment().startOf('day').add(1, 'year').toISOString(),
    isConsumed: false,
    consumedOnDate: null,
    consumedOnPaymentId: null,
    value: 0.00,
    isMultiUse: false,
});