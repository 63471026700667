import * as React from 'react';
import { useSaveInStore, SaveInStoreOptions } from '../../../shared/useSaveInStore';
import { useUpdateScheduledClassChildMutation } from './useUpdateScheduledClassChildMutation';
import { ScheduledClassChild } from '../models/ScheduledClassChild';
import { useCreateScheduledClassChildMutation } from './useCreateScheduledClassChildMutation';
import { ScheduledClassChildCreateInput, ScheduledClassChildUpdateInput } from '../generated/graphql';

/**
 * Get a callback to save a ScheduledClassChild in the api using either a create or update.
 */
export function useSaveScheduledClassChildMutation(options: SaveInStoreOptions<ScheduledClassChild, string> = {}) {
    const [_create, createStatus] = useCreateScheduledClassChildMutation();
    const create = React.useCallback((model: Partial<ScheduledClassChild>) => _create(model as ScheduledClassChildCreateInput), [_create]);

    const [_update, updateStatus] = useUpdateScheduledClassChildMutation();
    const update = React.useCallback((id: string, changes: Partial<ScheduledClassChild>) => _update(id, changes as ScheduledClassChildUpdateInput), [_update]);

    return useSaveInStore(
        [create, createStatus],
        [update, updateStatus],
        options
    );
}
