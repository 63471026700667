import { useTranslation } from 'react-i18next';
import { ValidateCallback } from 'pojo-validator-react';
import { ValidatedInput } from 'pojo-validator-reactstrap';
import { ValidationErrors } from 'pojo-validator';
import { Row, Col, Form, Label, FormGroup, Spinner, FormText } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ButtonAsync } from 'reactstrap-buttonasync';
import { Profile } from '../../../api/main/models/Profile';
import { User } from '../../../api/main/models/User';
import { Member } from '../../../api/main/models/Member';
import { TwoValueSwitch } from '../../shared/twoValueSwitch/TwoValueSwitch';


export interface MainTabProps {
    model: Profile | null | undefined,
    change: (changes: Partial<Profile>) => void,
    storeModel: Profile & { user: User | undefined | null; } | null | undefined,
    validate: ValidateCallback,
    validationErrors: ValidationErrors,
    memberModel: Member | null | undefined,
    changeMemberModel: (memberChanges: Partial<Member>) => void,
    resendInviteEmail: (email: string) => Promise<boolean>,
    setHasResentInviteEmail: (value: boolean) => void,
    isResendingInviteEmail: boolean,
    genders: Array<{ id: string, name: string, }> | undefined | null,
    userModel: User | null | undefined,
    changeUserModel: (changes: Partial<User>) => void,
    saveForm: () => Promise<void>,
    isCreate: boolean | null | undefined;
}

/**
 * MainTab for EditMember
 */
export const MainTab = (props: MainTabProps) => {
    const {
        model,
        change,
        storeModel,
        memberModel,
        changeMemberModel,
        validate,
        validationErrors,
        resendInviteEmail,
        setHasResentInviteEmail,
        isResendingInviteEmail,
        genders,
        userModel,
        changeUserModel,

        saveForm,
    } = props;
    const { t } = useTranslation();

    // Render the UI
    //
    return (
        <Form onSubmit={e => { e.preventDefault(); saveForm(); }}>
            <h4>{t('editMember.mainTab.personalDetails.heading', 'Personal details')}</h4>
            <Row>
                <Col>
                    <FormGroup>
                        <Label htmlFor="firstName">{t('editMember.firstName', 'First name')}</Label>
                        <ValidatedInput name="firstName" type="text" value={model?.firstName ?? ''} onChange={e => change({ firstName: e.currentTarget.value })} onBlur={e => validate('firstName')} validationErrors={validationErrors['firstName']} />
                    </FormGroup>
                </Col>
                <Col>
                    <FormGroup>
                        <Label htmlFor="lastName">{t('editMember.lastName', 'Last name')}</Label>
                        <ValidatedInput name="lastName" type="text" value={model?.lastName ?? ''} onChange={e => change({ lastName: e.currentTarget.value })} onBlur={e => validate('lastName')} validationErrors={validationErrors['lastName']} />
                    </FormGroup>
                </Col>
            </Row>
            <FormGroup>
                <Label htmlFor="genderId">{t('editMember.genderId', 'Gender')}</Label>
                <ValidatedInput name="genders" type="select" value={model?.genderId ?? ''} onChange={e => change({ genderId: e.currentTarget.value })} onBlur={e => validate('genderId')} validationErrors={validationErrors['genderId']}>
                    <option value="">{t('editMember.genders.pleaseSubmit', '(Please select a gender)')}</option>
                    {
                        genders?.map(item => (
                            <option key={item.id} value={item.id}>{item.name}</option>
                        ))
                    }
                </ValidatedInput>
            </FormGroup>


            <h4>{t('editMember.mainTab.contactDetails.heading', 'Contact details')}</h4>
            <Row>
                {/* Phones and emails on the left */}
                <Col>
                    <FormGroup>
                        <Label htmlFor="email">{t('editMember.mainTab.email', 'Primary email')}</Label>
                        <Row>
                            <Col>
                                <ValidatedInput name="email" type="email" value={model?.primaryEmail ?? ''}
                                    onChange={e => {
                                        changeUserModel({ email: e.currentTarget.value });
                                        change({ primaryEmail: e.currentTarget.value });
                                    }}
                                    onBlur={e => validate('email')} validationErrors={validationErrors['email']} />
                            </Col>
                            {
                                storeModel && !storeModel.user?.emailConfirmed && userModel?.email === storeModel.user?.email ? (
                                    <Col xs="auto">
                                        <ButtonAsync type="button" color="primary" outline onClick={async e => { e.preventDefault(); await resendInviteEmail(userModel?.email ?? ''); setHasResentInviteEmail(true); }}
                                            isExecuting={isResendingInviteEmail}
                                            executingChildren={<><Spinner size="sm" />{t('editMember.resendingInvite', 'Resending invite...')}</>}>
                                            <FontAwesomeIcon icon="envelope" />
                                            <> {t('editMember.resendInvite', 'Resend invite')}</>
                                        </ButtonAsync>
                                    </Col>
                                ) : null
                            }
                        </Row>
                        {
                            storeModel && !storeModel.user?.emailConfirmed && userModel?.email === storeModel.user?.email ? (
                                <FormText>
                                    {t('editMember.userHasNotAcceptedInvite', 'This user has not accepted the invite.  You can change their email if required or resend the invite.')}
                                </FormText>
                            ) : storeModel && !storeModel.user?.emailConfirmed && userModel?.email !== storeModel.user?.email ? (
                                <FormText>
                                    {t('editMember.userHasNotAcceptedInviteAndEmailChanged', 'This user has not yet accepted the invite, a new invite will be sent to the new email you have supplied.')}
                                </FormText>
                            ) : storeModel && storeModel.user?.emailConfirmed && userModel?.email !== storeModel.user.email ? (
                                <FormText color="warning">
                                    {t('editMember.userHasAcceptedInviteAndEmailChanged', 'This user will be asked to confirm the email change before it takes affect.  The old email address will be shown in the system until the change has been confirmed.')}
                                </FormText>
                            ) : storeModel && storeModel.user?.emailConfirmed && userModel?.email === storeModel.user.email ? (
                                <FormText>
                                    {t('editMember.userHasAcceptedInviteAndEmailNotChanged', 'If you change this user\'s email they will be sent an email to confirm the change before it takes affect.')}
                                </FormText>
                            ) : null
                        }
                    </FormGroup>
                    <FormGroup>
                        <Label htmlFor="secondaryEmail">{t('editMember.seconderyEmail', 'Secondary email')}</Label>
                        <ValidatedInput name="secondaryEmail" type="email" value={model?.secondaryEmail ?? ''} onChange={e => change({ secondaryEmail: e.currentTarget.value })} onBlur={e => validate('secondaryEmail')} validationErrors={validationErrors['secondaryEmail']} />
                    </FormGroup>
                    <FormGroup>
                        <Label htmlFor="primaryPhone">{t('editMember.primaryPhone', 'Primary phone number')}</Label>
                        <ValidatedInput name="primaryPhone" type="tel" value={model?.primaryPhone ?? ''} onChange={e => change({ primaryPhone: e.currentTarget.value })} onBlur={e => validate('primaryPhone')} validationErrors={validationErrors['primaryPhone']} />
                    </FormGroup>
                    <FormGroup>
                        <Label htmlFor="secondaryPhone">{t('editMember.secondaryPhone', 'Secondary phone number')}</Label>
                        <ValidatedInput name="secondaryPhone" type="tel" value={model?.secondaryPhone ?? ''} onChange={e => change({ secondaryPhone: e.currentTarget.value })} onBlur={e => validate('secondaryPhone')} validationErrors={validationErrors['secondaryPhone']} />
                    </FormGroup>

                    <FormGroup switch style={{ paddingLeft: '0px', }}>
                        <Label htmlFor="applyStaffDiscount">{t('editStaff.applyStaffDiscount.label', 'Apply staff discount?')}</Label>
                        <TwoValueSwitch checked={memberModel?.applyStaffDiscount ?? false} leftLabel={t('common.no', 'No')} rightLabel={t('common.yes', 'Yes')} onChange={e => changeMemberModel({ applyStaffDiscount: e })}></TwoValueSwitch>
                    </FormGroup>

                    <FormGroup switch style={{ paddingLeft: '0px', }}>
                        <Label htmlFor="marketingOptIn">{t('editStaff.marketingOptIn.label', 'Has agreed to receive marketing communications?')}</Label>
                        <TwoValueSwitch checked={memberModel?.optInToMarketing ?? false} leftLabel={t('common.no', 'No')} rightLabel={t('common.yes', 'Yes')} onChange={e => changeMemberModel({ optInToMarketing: e })}></TwoValueSwitch>
                    </FormGroup>
                </Col>

                {/* Address on the right */}
                <Col xs={12} md="">
                    <FormGroup>
                        <Label htmlFor="addressLine1">{t('editMember.addressLine1', 'Address line 1')}</Label>
                        <ValidatedInput name="addressLine1" type="text" value={model?.addressLine1 ?? ''} onChange={e => change({ addressLine1: e.currentTarget.value })} onBlur={e => validate('addressLine1')} validationErrors={validationErrors['addressLine1']} />
                    </FormGroup>
                    <FormGroup>
                        <Label htmlFor="addressLine2">{t('editMember.addressLine2', 'Address line 2')}</Label>
                        <ValidatedInput name="addressLine2" type="text" value={model?.addressLine2 ?? ''} onChange={e => change({ addressLine2: e.currentTarget.value })} onBlur={e => validate('addressLine2')} validationErrors={validationErrors['addressLine2']} />
                    </FormGroup>
                    <FormGroup>
                        <Label htmlFor="city">{t('editMember.city', 'City')}</Label>
                        <ValidatedInput name="city" type="text" value={model?.city ?? ''} onChange={e => change({ city: e.currentTarget.value })} onBlur={e => validate('city')} validationErrors={validationErrors['city']} />
                    </FormGroup>
                    <FormGroup>
                        <Label htmlFor="county">{t('editMember.county', 'County')}</Label>
                        <ValidatedInput name="county" type="text" value={model?.county ?? ''} onChange={e => change({ county: e.currentTarget.value })} onBlur={e => validate('county')} validationErrors={validationErrors['county']} />
                    </FormGroup>
                    <FormGroup>
                        <Label htmlFor="postcode">{t('editMember.postcode', 'Postcode')}</Label>
                        <ValidatedInput name="postcode" type="text" value={model?.postcode ?? ''} onChange={e => change({ postcode: e.currentTarget.value })} onBlur={e => validate('postcode')} validationErrors={validationErrors['postcode']} />
                    </FormGroup>
                    <FormGroup>
                        <Label htmlFor="country">{t('editMember.country', 'Country')}</Label>
                        <ValidatedInput name="country" type="text" value={model?.country ?? ''} onChange={e => change({ country: e.currentTarget.value })} onBlur={e => validate('country')} validationErrors={validationErrors['country']} />
                    </FormGroup>
                </Col>
            </Row>
        </Form>
    );

};

