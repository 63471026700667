export const iconColors = (iconType: string) => {
    switch (iconType) {
        case 'health-note':
            return '#fa7600';
        case 'staff-note':
            return '#0076fa';
        case 'progress-note':
            return '#a700fa';
        case 'birthday':
            return '#008bfa';
        default:
            return '#494949';
    };
};