import gql from 'graphql-tag';
import { Guid } from 'guid-string';
import moment from 'moment';
import { ChildsRelationshipFieldsFragment } from '../generated/graphql';

export const childsRelationshipFieldsFragment = gql`
    fragment childsRelationshipFields on ChildsRelationship {
        id
        name
        displayOrder
        archived
    }
`;

export type ChildsRelationship = Omit<ChildsRelationshipFieldsFragment, '__typename'>;

/**
 * Default values.
 */
export const childsRelationshipDefaultValues = (): Partial<ChildsRelationship> => ({
    id: Guid.newGuid(),
    name: '',
    displayOrder: moment().unix(),
    archived: false,
});