import { useTranslation } from "react-i18next";
import { PaymentState } from "../../../api/main/models/constants/PaymentState";
import { PaymentsList } from "./PaymentsList";

/**
 * Payment history.
 */
export const PaymentHistoryList = () => {
    const { t } = useTranslation();

    return (
        <PaymentsList
            title={t('paymentHistoryList.title', 'Payment history')}
            filter={item => item.paymentState === PaymentState.Paid}
            sort={items => {
                // Sort items by payment date.
                items.sort((a, b) => {
                    if (a.paidDate === b.paidDate) {
                        return 0;
                    } else if (a.paidDate < b.paidDate) {
                        return -1;
                    } else {
                        return 1;
                    }
                });

                // We want the newest at the top.
                items.reverse();

                // Return the array, which has been modified in place anyway.
                return items;
            }
}
            showPaymentDate={true}
        />
    );
};