import { gql, useLazyQuery } from "@apollo/client";
import React from "react";
import { mainApiConfig } from "../../../../configure/mainApiConfig";
import { AsyncLoadOptions, AsyncLoadResult } from "../../../../shared/abstractStore";
import { scheduledClassCancellationFieldsFragment } from "../../models/ScheduledClassCancellation";
import { ScheduledClassCancellationsListViewModelQuery, ScheduledClassCancellationsListViewModelQueryVariables } from '../../generated/graphql';
import { cancellationReasonFieldsFragment } from "../../models/CancellationReason";
import { scheduledClassFieldsFragment } from "../../models/ScheduledClass";
import { classLocationFieldsFragment } from "../../models/ClassLocation";
import { profileFieldsFragment } from "../../models/Profile";
import { classStageFieldsFragment } from "../../models/ClassStage";
import { classSubStageFieldsFragment } from "../../models/ClassSubStage";
import { classTypeFieldsFragment } from "../../models/ClassType";
import { termFieldsFragment } from "../../models/Term";

export function useScheduledClassCancellationsListViewModel(options: AsyncLoadOptions = {}): AsyncLoadResult<ScheduledClassCancellationsListViewModelQuery> {
    // Query data we need from the api.
    const [load, { data, loading, error, refetch, }] = useLazyQuery<ScheduledClassCancellationsListViewModelQuery, ScheduledClassCancellationsListViewModelQueryVariables>(
        gql`
        query scheduledClassCancellationsListViewModelQuery {
            items: scheduledClassCancellations {
                ...scheduledClassCancellationFields

                scheduledClass {
                    ...scheduledClassFields

                    classLocation {
                        ...classLocationFields
                    }
                }
            }

            cancellationReasons {
                ...cancellationReasonFields
            }

            staffProfiles: profiles (isStaff: true) {
                ...profileFields
            } 

            classStages {
                ...classStageFields
            }

            classSubStages {
                ...classSubStageFields
            }

            classTypes {
                ...classTypeFields
            }

            terms (restrictToTermsWithClasses: true) {
                ...termFields
            }
        }

        ${scheduledClassCancellationFieldsFragment}
        ${scheduledClassFieldsFragment}
        ${classLocationFieldsFragment}
        ${cancellationReasonFieldsFragment}
        ${profileFieldsFragment}
        ${classStageFieldsFragment}
        ${classSubStageFieldsFragment}
        ${classTypeFieldsFragment}
        ${termFieldsFragment}
        `,
        {
            fetchPolicy: options.fetchPolicy ?? mainApiConfig.defaultFetchPolicy,
            pollInterval: options.pollInterval,
            onCompleted: (data) => {
                if (options.onCompleted) {
                    options.onCompleted(data);
                }
            }
        }
    );

    // Run the query (unless we are being lazy).
    React.useEffect(() => {
        if (!options.lazy) {
            load();
        }
    }, [options.lazy, load]);

    // Return the results.
    const refresh = React.useCallback(async () => { !refetch ? await load() : await refetch(); }, [refetch, load]);
    return React.useMemo(() => ({
        data: data ?? ({} as any),
        refresh: refresh,
        isLoading: loading,
        errors: error,
    }), [data, refresh, loading, error]);
};