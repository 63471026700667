import gql from 'graphql-tag';
import { Guid } from 'guid-string';
import moment from 'moment';
import { StaffAbsenceFieldsFragment } from '../generated/graphql';
import { StaffSubmissionAcceptanceState } from './constants/StaffSubmissonAcceptanceState';

export const staffAbsenceFieldsFragment = gql`
    fragment staffAbsenceFields on StaffAbsence {
        id
        staffId
        approvedByStaffUserId
        staffAbsenceTypeId
        startDate
        endDate
        acceptanceState
        submittedDate
        confirmedDate
        archived

    }
`;

export type StaffAbsence = Omit<StaffAbsenceFieldsFragment, '__typename'>;

/**
 * Default values.
 */
export const staffAbsenceDefaultValues = (): Partial<StaffAbsence> => ({
    id: Guid.newGuid(),
    staffId: undefined,
    approvedByStaffUserId: '',
    staffAbsenceTypeId: undefined,
    startDate: moment().toISOString(),
    endDate: moment().toISOString(),
    acceptanceState: StaffSubmissionAcceptanceState.Submitted,
    submittedDate: moment().toISOString(),
    confirmedDate: null,
    archived: false,
});