import gql from 'graphql-tag';
import { Guid } from 'guid-string';
import { ClassStageFieldsFragment } from '../generated/graphql';

export const classStageFieldsFragment = gql`
    fragment classStageFields on ClassStage {
        id
        name
        displayOrder
        classTypeId
        archived
        startAgeYears
        startAgeMonths
        endAgeYears
        endAgeMonths
        nextClassStageId
        wrapBackToStartOnCompletion
        photoBlobReferenceId
    }
`;

export type ClassStage = Omit<ClassStageFieldsFragment, '__typename'>;

/**
 * Default values.
 */
export const classStageDefaultValues = (): Partial<ClassStage> => ({
    id: Guid.newGuid(),
    name: '',
    displayOrder: 99,
    classTypeId: undefined,
    archived: false,
    startAgeYears: 0,
    startAgeMonths: 0,
    endAgeYears: 0,
    endAgeMonths: 0,
    nextClassStageId: null,
    wrapBackToStartOnCompletion: false,
    photoBlobReferenceId: null,
});