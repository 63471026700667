import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ValidationErrors } from "pojo-validator";
import { ValidateCallback } from "pojo-validator-react";
import { ValidatedInput } from "pojo-validator-reactstrap";
import { ConditionalFragment } from "react-conditionalfragment";
import { useTranslation } from "react-i18next";
import { Badge, Button, Col, FormGroup, Label, ListGroupItem, Row } from "reactstrap";
import { Profile } from "../../../../../api/main/models/Profile";
import { ScheduledClassStaff } from "../../../../../api/main/models/ScheduledClassStaff";
import { Staff } from "../../../../../api/main/models/Staff";
import { ValidatedISODateTimeInput } from "../../../../shared/isoDateTimeInput/ValidatedISODateTimeInput";

export interface StaffProfile extends Profile {
    staff?: Staff | undefined | null;
}

export interface ScheduledClassStaffComponentProps {
    model: ScheduledClassStaff,
    change: (changes: Partial<ScheduledClassStaff>) => void,
    remove: () => void,
    staffProfiles: Array<StaffProfile> | undefined | null,

    validate: ValidateCallback,
    validationErrors: ValidationErrors,
}
/**
 * Component under ScheduledClasses that lets the user add a new ScheduledClassStaff
 */
export const ScheduledClassStaffComponent = (props: ScheduledClassStaffComponentProps) => {
    const {
        model,
        change,
        remove,
        staffProfiles,
        validate,
        validationErrors,
    } = props;
    const { t } = useTranslation();

    // Render the UI
    //
    return (
        <ListGroupItem>
            <ConditionalFragment showIf={model.isSubsitute}>
                <Row>
                    <Col xs="auto">
                        <Badge color='warning'>{t('editSchedluedClass.scheduledClassStaffComponent.substituteTeacher.badge', 'Substitute teacher')}</Badge>
                    </Col>
                </Row>
            </ConditionalFragment>

            <Row style={{ paddingTop: '8px' }}>
                <Col>
                    <FormGroup>
                        <span className="sr-only">{t('editScheduledClass.scheduledClassStaffComponent.staffId.label', 'Staff member')}</span>
                        <ValidatedInput name="staffId" type="select" value={model.staffId} onChange={e => change({ staffId: e.currentTarget.value })} validationErrors={validationErrors['staffId']} onBlur={e => validate('staffId')}>
                            <option value="">{t('editScheduledClass.scheduledClassStaffComponent.staffId.pleaseSelect', '(Please select a staff member)')}</option>
                            {
                                staffProfiles?.map(item => {
                                    return <option key={item.id} value={item.id}>{t('common.fullName', '{{firstName}} {{lastName}}', { firstName: item.firstName ?? '', lastName: item.lastName ?? '', })}</option>;
                                })
                            }
                        </ValidatedInput>
                    </FormGroup>
                </Col>

                <ConditionalFragment showIf={model.subsituteStartDate && model.subsituteEndDate}>
                    <Col>
                        <FormGroup>
                            <Label htmlFor="substituteStartDate">{t('editScheduledClass.scheduledClassStaffComponent.substituteStartDate.label', 'Substitute start date')}</Label>
                            <ValidatedISODateTimeInput name="substituteStartDate" type="date" value={model.subsituteStartDate} validationErrors={[]} />
                        </FormGroup>
                    </Col>

                    <Col>
                        <FormGroup>
                            <Label htmlFor="substituteEndDate">{t('editScheduledClass.scheduledClassStaffComponent.substituteEndDate.label', 'Substitute end date')}</Label>
                            <ValidatedISODateTimeInput name="substituteEndDate" type="date" value={model.subsituteEndDate} validationErrors={[]} />
                        </FormGroup>
                    </Col>
                </ConditionalFragment>

                <Col xs='auto'>
                    <FormGroup>
                        <Button color='danger' outline onClick={() => remove()} style={{ display: 'block' }}> {/* Display block is important here to align the button with the inputs */}
                            <FontAwesomeIcon icon='trash-alt' />
                            <> </>
                            {t('editScheduledClass.scheduledClassStaffComponent.delete', 'Remove from class')}
                        </Button>
                    </FormGroup>
                </Col>
            </Row>
        </ListGroupItem>
    );
};