import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Button, FormGroup, ListGroup } from "reactstrap";
import { Child } from "../../../../api/main/models/Child";
import { ChildComponent } from "./ChildComponent";

export interface ChildrenTabProps {
    children: Array<Child> | null | undefined,
    memberId: string | null | undefined,
}

/**
 * ChildrenTab for Members
 */
export const ChildrenTab = (props: ChildrenTabProps) => {
    const { children, memberId } = props;
    const { t } = useTranslation();
    const navigate = useNavigate();

    // Add child
    const addChildToMember = () => {
        navigate(`/administration/children-management/members/addChildFor/${memberId ?? ''}`);
    };

    // Render the UI
    //
    return (
        <FormGroup>
            <ListGroup>
                <h4>{t('editMember.children.childrenDetails.heading', 'Bear cubs')}</h4>

                {children?.map((child, index) => (
                    <ChildComponent key={child?.id} child={child} childrenCount={children.length} childIndex={index + 1} />
                ))}
            </ListGroup>

            <div className="mt-2">
                <Button color="primary" outline onClick={() => addChildToMember()}>
                    {t('editMember.children.addChild', 'Add Cub')}
                </Button>
            </div>
        </FormGroup>
    );
};