import gql from 'graphql-tag';
import { Guid } from 'guid-string';
import { ClassSubStageFieldsFragment } from '../generated/graphql';

export const classSubStageFieldsFragment = gql`
    fragment classSubStageFields on ClassSubStage {
        id
        name
        displayOrder
        classStageId
        numberOfLessons
        archived
    }
`;

export type ClassSubStage = Omit<ClassSubStageFieldsFragment, '__typename'>;

/**
 * Default values.
 */
export const classSubStageDefaultValues = (): Partial<ClassSubStage> => ({
    id: Guid.newGuid(),
    name: '',
    displayOrder: 99,
    numberOfLessons: 10,
    classStageId: undefined,
    archived: false,
});