import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import { useCallback, useMemo, useState } from "react";
import { ConditionalFragment } from "react-conditionalfragment";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useAsyncCallback } from "react-use-async-callback";
import { Alert, Badge, Button, ButtonGroup, CardTitle, Col, FormGroup, Input, Label, ListGroup, ModalBody, ModalFooter, ModalHeader, Row, Spinner } from "reactstrap";
import { ButtonAsync } from "reactstrap-buttonasync";
import { useToggleState } from "use-toggle-state";
import { useBlobReference } from "../../api/main/blobReferences/useBlobReference";
import { QueryMessage, queryMessageDefaultValues } from "../../api/main/models/QueryMessage";
import { useSaveQueryMessageAndEmailMemberMutation } from "../../api/main/queryMessages/useSaveQueryMessageAndEmailMemberMutation";
import { useSaveQueryMessageMutation } from "../../api/main/queryMessages/useSaveQueryMessageMutation";
import { useUpdateQueryMessagesMutation } from "../../api/main/queryMessages/useUpdateQueryMessagesMutation";
import { useUpdateQueryMessagesToggleResolvedMutation } from "../../api/main/queryMessages/useUpdateQueryMessagesToggleResolved";
import { useQueryMessageThreadViewModel } from "../../api/main/queryMessages/viewModels/useQueryMessageThreadViewModel";
import { AlertOnErrors } from "../../shared/alertOnErrors";
import { useChanges } from "../../shared/useChanges";
import { Banner } from "../shared/banner/Banner";
import { LoadingIndicator } from "../shared/loadingIndicator/LoadingIndicator";
import { NoResultsFound } from "../shared/noResultsFound/NoResultsFound";
import { StyledModal } from "../shared/styledModal/StyledModal";
import { QueryMessageItem } from "./QueryMessageItem";

export interface QueryMessageThreadProps {
    memberId: string | undefined | null,
    queryMessageId: string | undefined | null,
    isMemberView?: boolean,
    isAdminView?: boolean,
    staffId?: string | undefined | null,
};

export const QueryMessageThreadAdmin = () => {
    const {
        memberId,
        queryMessageId,
    } = useParams<{ memberId: string | undefined, queryMessageId: string | undefined, }>();

    return <QueryMessageThread memberId={memberId} queryMessageId={queryMessageId} isAdminView={true} />;
};

export const QueryMessageThreadMember = () => {
    const {
        memberId,
        queryMessageId,
    } = useParams<{ memberId: string | undefined, queryMessageId: string | undefined, }>();

    return <QueryMessageThread memberId={memberId} queryMessageId={queryMessageId} isMemberView={true} />;
};

export const QueryMessageThreadStaff = () => {
    const {
        memberId,
        queryMessageId,
        staffId
    } = useParams<{ memberId: string | undefined, queryMessageId: string | undefined, staffId: string | undefined; }>();

    return <QueryMessageThread memberId={memberId} queryMessageId={queryMessageId} staffId={staffId} />;
};

export const QueryMessageThread = (props: QueryMessageThreadProps) => {
    const {
        memberId,
        queryMessageId,
        isMemberView = false,
        isAdminView = false,
        staffId,
    } = props;
    const { t } = useTranslation();

    // Load the data
    const {
        data: {
            items: _allItems,
            member,
            profiles,
            queryMessageTypes
        }, isLoading: _isLoading, errors: queryMessageLoadErrors, refresh: refreshQueryMessages
    } = useQueryMessageThreadViewModel(memberId, staffId ?? null);

    // Load the ProfileImage
    const {
        data: {
            model: profileImage
        }, isLoading: isLoadingProfileImage, errors: profileImageLoadErrors
    } = useBlobReference(member?.photoBlobReferenceId);
    const isLoading = _isLoading || isLoadingProfileImage;

    // Group QueryMessages into threads
    const items = useMemo(() => _allItems?.filter(it => it.id === queryMessageId).map(item => {
        return {
            ...item,
            thread: _allItems?.filter(it => it.threadKey === item.id)
        };
    }), [_allItems, queryMessageId]);

    // Model (QueryMessage)
    const { model, change, changes } = useChanges<QueryMessage>({} as any, { ...queryMessageDefaultValues() });
    const [saveQueryMessage] = useSaveQueryMessageMutation();
    const [saveQueryMessages] = useUpdateQueryMessagesMutation();
    const [toggleQueryMessagesResolved] = useUpdateQueryMessagesToggleResolvedMutation();
    const [saveQueryMessageAndEmailMember] = useSaveQueryMessageAndEmailMemberMutation();

    // Reset the model so we have a new blank message
    const resetToNewQueryMessage = useCallback(() => {
        change({ ...queryMessageDefaultValues() });
    }, [change]);

    // Alert state
    const [alertIsOpen, setAlert] = useToggleState();

    // Alert message 
    const [alertMessage, setAlertMessage] = useState<string>('');

    // Save everything
    const [saveForm, { isExecuting: isSavingMainModel, errors: saveFormErrors }] = useAsyncCallback(async () => {
        if (!model || !queryMessageToReplyTo?.memberId) {
            return;
        }

        // Update the message we are replying to so that it has the flag hasBeenRepliedTo set to true
        await saveQueryMessage(queryMessageToReplyTo.id, { hasBeenRepliedTo: true }, false);

        // Add missing details to QueryMessage
        changes.submittedDate = moment();
        changes.memberId = queryMessageToReplyTo.memberId;
        changes.isReplyFromStaff = isMemberView ? false : true;
        changes.queryMessageTypeId = queryMessageToReplyTo.queryMessageTypeId;
        changes.threadKey = queryMessageToReplyTo.threadKey ? queryMessageToReplyTo.threadKey : queryMessageToReplyTo.id; // If we are replying to a thread message, then copy the threadKey else we are replying to the first message and we should use the ID
        changes.assignedToStaffUserId = queryMessageToReplyTo.assignedToStaffUserId;
        if (isMemberView) {
            changes.resolved = false;
        }

        // Save the main model
        await saveQueryMessageAndEmailMember(model.id, { ...changes }, true);

        // Set the alert and alert message
        setAlertMessage(t('queryMessageThread.alertMessage.messageSent', 'Message sent'));
        setAlert();
        // Toggle the Alert off after a short delay
        setTimeout(() => {
            setAlert();
        }, 3000);

        refreshQueryMessages();

        // Clear the message that is now part of the chat, and create a blank one in advance of the next message to be sent.
        resetToNewQueryMessage();
    }, [model, changes, resetToNewQueryMessage]);

    // Mark message as resolved
    const [toggleResolved, { isExecuting: isTogglingResolved, errors: toggleResolvedErrors }] = useAsyncCallback(async () => {
        if (!model || !queryMessageToReplyTo?.memberId) {
            return;
        }

        // Using QueryMessageToReplyTo, find all other messages from this thread
        // The start of the thread will be at items[0], the thread messages will be under items[0].thread
        const queryMessagesToToggleResolved = [items[0], items[0].thread?.filter(it => it.threadKey === queryMessageToReplyTo.threadKey || it.id === queryMessageToReplyTo.id)].flat().map(it => it.id);

        // Update the message we are replying to so that it has the flag hasBeenRepliedTo set to true
        //await saveQueryMessage(queryMessageToReplyTo.id, { resolved: !queryMessageToReplyTo.resolved }, false);
        await toggleQueryMessagesResolved(queryMessagesToToggleResolved);

        // Set the resolved status for the current message input to match the new resolved status.
        change({ resolved: !queryMessageToReplyTo.resolved });

        refreshQueryMessages();
    }, [model, changes, resetToNewQueryMessage]);

    // Get the last QueryMessage - some of the details are required for the reply
    const queryMessageToReplyTo = useMemo(() => {
        if (!items?.length) return;

        if (items[0]?.thread.length) {
            return items[0]?.thread?.slice(-1)[0];
        } else {
            return items?.slice(-1)[0];
        }
    }, [items]);

    // Assigned To State
    const [assignedToId, setAssignedToId] = useState<string>();

    // Save AssignedToStaff
    const [saveAssignedToStaff, { isExecuting: isSavingAssignToStaff, errors: saveAssignToStaffErrors }] = useAsyncCallback(async (staffId: string) => {
        if (!queryMessageToReplyTo?.memberId || !staffId) {
            return;
        }

        // Using QueryMessageToReplyTo, find all other messages from this thread
        // The start of the thread will be at items[0], the thread messages will be under items[0].thread
        const queryMessagesToAssignToStaff = [items[0], items[0].thread?.filter(it => it.threadKey === queryMessageToReplyTo.threadKey || it.id === queryMessageToReplyTo.id)].flat().map(it => it.id);

        // Update the messages we are assigning to staff
        await saveQueryMessages(queryMessagesToAssignToStaff, staffId);

        // Set the alert and alert message
        setAlertMessage(t('queryMessageThread.alertMessage.assignedToStaff', 'Message assigned to staff successfully '));
        setAlert();
        // Toggle the Alert off after a short delay
        setTimeout(() => {
            setAlert();
        }, 3000);

        _setIsOpen();
    }, [queryMessageToReplyTo]);
    const isSaving = isSavingMainModel || isSavingAssignToStaff;

    // Assign to Staff Modal
    const [isOpen, _setIsOpen] = useToggleState();
    const setIsOpen = useCallback(() => {
        _setIsOpen(!isOpen);
    }, [_setIsOpen, isOpen]);

    // Get resolved status for the thread
    const getThreadStatus = useMemo(() => {
        if (!items) return;

        const isResolved = items[0]?.resolved;

        return <Badge color={isResolved ? 'success' : 'danger'} >{t('common.resolvedStatus', '{{status}}', { status: isResolved ? 'Closed' : 'Open' })}</Badge>;
    }, [items, t]);

    // Render the UI
    //
    return (
        <>
            <Banner fluid>
                <Row>
                    <Col>
                        <h1>
                            {t('queryMessageThread.title', 'Messages')}
                        </h1>
                    </Col>
                    <ConditionalFragment showIf={!isLoading && !isMemberView}>
                        <Col xs="auto">
                            {getThreadStatus}
                        </Col>
                    </ConditionalFragment>
                    <ConditionalFragment showIf={!isLoading && !!isAdminView && !!queryMessageToReplyTo?.assignedToStaffUserId}>
                        <Col xs="auto">
                            <Badge color="secondary">
                                {t('common.assignedTo', 'Assigned to')} {t('common.fullName', '{{firstName}} {{lastName}}', { firstName: profiles?.find(it => it.id === queryMessageToReplyTo?.assignedToStaffUserId)?.firstName, lastName: profiles?.find(it => it.id === queryMessageToReplyTo?.assignedToStaffUserId)?.lastName })}
                            </Badge>
                        </Col>
                    </ConditionalFragment>
                    <ConditionalFragment showIf={isLoading}>
                        <Col xs="auto">
                            <LoadingIndicator size="sm" />
                        </Col>
                    </ConditionalFragment>
                </Row>

                <Row>
                    <Col>
                        <h3>
                            <ConditionalFragment showIf={!isMemberView}>
                                <a href={`administration/children-management/members/edit/${member?.user?.profile?.id}?tab=children`} target="_blank" rel="noreferrer">
                                    {t('common.fullName', '{{firstName}} {{lastName}}', { firstName: member?.user?.profile?.firstName ?? '', lastName: member?.user?.profile?.lastName ?? '' })}
                                </a>
                            </ConditionalFragment>
                        </h3>
                        <h5>{t('common.subject', 'Subject')}: {queryMessageTypes?.find(it => it.id === items[0]?.queryMessageTypeId)?.name}</h5>
                        <ConditionalFragment showIf={!!isAdminView && !!queryMessageToReplyTo?.assignedToStaffUserId}>
                            <h5>{t('common.assignedTo', 'Assigned to')} {t('common.fullName', '{{firstName}} {{lastName}}', { firstName: profiles?.find(it => it.id === queryMessageToReplyTo?.assignedToStaffUserId)?.firstName, lastName: profiles?.find(it => it.id === queryMessageToReplyTo?.assignedToStaffUserId)?.lastName })}</h5>
                        </ConditionalFragment>
                    </Col>

                    <Col xs="auto">
                        <ButtonGroup>
                            <ConditionalFragment showIf={!!isAdminView}>
                                <> </>
                                <Button color="primary" outline onClick={() => setIsOpen()}>
                                    <FontAwesomeIcon icon="forward" />
                                    <> </>
                                    {t('queryMessageThread.assignToStaff.button', 'Assign to staff')}
                                </Button>
                            </ConditionalFragment>
                            <> </>
                            <ConditionalFragment showIf={!isMemberView}>
                                <ButtonAsync color="primary" outline isExecuting={isTogglingResolved} onClick={() => toggleResolved()}
                                    executingChildren={<><Spinner size="sm" />
                                        {
                                            queryMessageToReplyTo?.resolved ? (
                                                t('common.markingAsOpen.button', 'Marking as open...')
                                            ) : (
                                                t('common.markingAsClosed.button', 'Marking as closed...')
                                            )
                                        }
                                    </>}>
                                    <FontAwesomeIcon icon={queryMessageToReplyTo?.resolved ? 'xmark' : 'check'} />
                                    <> </>
                                    {queryMessageToReplyTo?.resolved ? (
                                        t('queryMessageThread.markAsOpen.button', 'Mark as open')
                                    ) : (
                                        t('queryMessageThread.markAsClosed.button', 'Mark as closed')
                                    )}
                                </ButtonAsync>
                            </ConditionalFragment>
                        </ButtonGroup>
                    </Col>
                </Row>
            </Banner>

            <AlertOnErrors error={[
                queryMessageLoadErrors, profileImageLoadErrors,
                saveFormErrors, toggleResolvedErrors, saveAssignToStaffErrors
            ]} />

            <ConditionalFragment showIf={!!alertIsOpen}>
                <Alert color="success">
                    {alertMessage}
                </Alert>
            </ConditionalFragment>

            <ConditionalFragment showIf={!isLoading}>
                {items?.length ? (
                    <ListGroup>
                        <QueryMessageItem message={items[0]} profileImage={profileImage} isMemberView={isMemberView} />
                        {items[0]?.thread?.map(message => (
                            <QueryMessageItem message={message} profileImage={profileImage} isMemberView={isMemberView} />
                        ))}
                    </ListGroup>
                ) : (
                    <NoResultsFound />
                )}
            </ConditionalFragment>

            <Row>
                <FormGroup>
                    <Label htmlFor="contentsHtml" style={{
                        fontSize: '2rem', color: '#fada00', margin: '1rem 0'
                    }}>{t('common.reply', 'Reply')}</Label>
                    <Input type="textarea" rows={8} value={model?.contentsHtml ?? ''} onChange={(e) => change({ contentsHtml: e.currentTarget.value })} />
                </FormGroup>

                <ConditionalFragment showIf={!isLoading}>
                    <div className="text-center">
                        <ButtonAsync color="primary" isExecuting={isSaving} onClick={() => saveForm()}
                            executingChildren={<><Spinner size="sm" /> {t('common.sendingMessage', 'Sending message...')}</>}>
                            <FontAwesomeIcon icon="save" />
                            <> </>
                            {t('queryMessageThread.sendMessage.button', 'Send message')}
                        </ButtonAsync>
                        <> </>
                        <Button color="primary" outline onClick={e => change({ contentsHtml: '' })}>
                            {t('common.clear', 'Clear')}
                        </Button>
                    </div>
                </ConditionalFragment>
            </Row>

            <StyledModal isOpen={isOpen} toggle={setIsOpen} size="lg">
                <ModalHeader toggle={setIsOpen}>
                    <CardTitle tag="h1">
                        {t('queryMessageThread.modal.assignToStaff', 'Assign to Staff')}
                    </CardTitle>
                </ModalHeader>

                <ModalBody>
                    <Label htmlFor="assignedToStaffUserId">{t('queryMessageThread.staffMember.label', 'Staff member')}</Label>
                    <Input name="assignedToStaffUserId" type="select" value={assignedToId} onChange={(e) => setAssignedToId(e.currentTarget.value)}>
                        <option value="">{t('queryMessageThread.pleaseSelect', '(Please select staff member)')}</option>
                        {profiles?.map(profile => (
                            <option value={profile.id} key={profile.id}> {t('common.fullName', '{{firstName}} {{lastName}}', { firstName: profile?.firstName, lastName: profile?.lastName })}</option>
                        ))}
                    </Input>
                </ModalBody>

                <ModalFooter>
                    <ButtonAsync color="primary" isExecuting={isSaving} onClick={() => saveAssignedToStaff(assignedToId ?? '')}
                        executingChildren={<><Spinner size="sm" /> {t('common.saving', 'Saving...')}</>}>
                        <FontAwesomeIcon icon="save" />
                        <> </>
                        {t('common.save', 'Save')}
                    </ButtonAsync>
                    <Button type="button" color="primary" outline onClick={e => setIsOpen()}>
                        {t('common.cancel', 'Cancel')}
                    </Button>
                </ModalFooter>
            </StyledModal>
        </>
    );
};