import { useMutation } from "@apollo/client";
import gql from "graphql-tag";
import { AsyncActionStatus } from "../../../shared/abstractStore";
import { UpdateQueryMessagesToggleResolvedMutation, UpdateQueryMessagesToggleResolvedMutationVariables } from "../generated/graphql";
import { queryMessageFieldsFragment } from "../models/QueryMessage";
import { useAsyncCallback } from "react-use-async-callback";

/**
 * Get a callback to update QueryMessages in the api.
 * @param options
 */
export function useUpdateQueryMessagesToggleResolvedMutation():[(ids: Array<string> | undefined | null) => Promise<void>, AsyncActionStatus] {
    const [mutationAction, { error }] = useMutation<UpdateQueryMessagesToggleResolvedMutation, UpdateQueryMessagesToggleResolvedMutationVariables>(
        gql`
        mutation updateQueryMessagesToggleResolvedMutation ($ids: [ID!]) {
                updateQueryMessagesToggleResolved(ids: $ids) {
                    ...queryMessageFields
                }
            }

            ${queryMessageFieldsFragment}
        `
    );

    // Wrap the mutation in a standard callback.
    const [action, { isExecuting, errors: actionErrors }] = useAsyncCallback(async (ids: Array<string> | undefined | null) => {
        const result = await mutationAction({ variables: { ids: ids } });
        if (result.errors && result.errors.length) {
            throw result.errors;
        }
    }, [mutationAction]);

    return [action, { isExecuting: isExecuting, errors: error ? error.message : actionErrors }];
};