import * as React from "react";
import { Form, FormGroup, Label, FormText, Spinner, Row, Col, CardHeader, CardBody } from "reactstrap";
import { useConfirmInviteCallback } from "../../api/account";
import { useTranslation } from "react-i18next";
import { AlertOnErrors } from "../../shared/alertOnErrors";
import { useValidatorCallback } from "pojo-validator-react";
import { useChanges } from "../../shared/useChanges";
import { ConfirmInvite as ConfirmInviteModel } from "../../api/account/models/ConfirmInvite";
import { usePasswordValidation } from "../../shared/passwordValidation";
import { ValidatedInput } from "pojo-validator-reactstrap";
import { FormButtons } from "../shared/formButtons/FormButtons";
import { ButtonAsync } from "reactstrap-buttonasync";
import { ConfirmInviteProfileDetails } from "../profiles/ConfirmInviteProfileDetails";
import { MainContainer } from "../shared/mainContainer/MainContainer";
import { useNavigate } from "react-router-dom";
import { BodyBackground } from "../layout/bodyBackground/BodyBackground";
import { AccountCard } from "./accountCard/AccountCard";

/**
 * Confirm an email address and provide registration details as part of an invite.
 */
export const ConfirmInvite = () => {
    const { t } = useTranslation();
    const [confirmInvite, { isExecuting: isConfirmingInvite, errors: confirmInviteErrors }] = useConfirmInviteCallback();
    const { model, change } = useChanges<ConfirmInviteModel>({ password: '', confirmPassword: '' });
    const { checkPassword, passwordRequirements } = usePasswordValidation();
    const navigate = useNavigate();

    /**
     * Validate the model before trying to use it.
     */
    const [validate, validationErrors] = useValidatorCallback((validation, fieldsToCheck) => {
        const rules = {
            password: () => !model.password ? t('confirmInvite.passwordRequired', 'Password is required')
                : checkPassword(model.password).errorDescription,
            confirmPassword: () => model.confirmPassword !== model.password ? t('confirmInvite.passwordsDoNotMatch', 'The password and confirmation password do not match') : '',
        };

        validation.checkRules(rules, fieldsToCheck);
    }, [model]);

    /**
     * Perform a login by and handle the result.
     */
    const [isDoingFullPageRedirect, setIsDoingFullPageRedirect] = React.useState<boolean>(false);
    const performRegister = React.useCallback(async (): Promise<void> => {
        if (!validate()) {
            return;
        }

        const result = await confirmInvite(model);

        if (result) {
            if (result.requiresTwoFactor) {
                navigate(`/account/loginWithTwoFactor?returnUrl=${encodeURIComponent(result.returnUrl)}`);
            }

            // Redirect the whole page (not just the react app) as its likely the returnUrl is handled on the server.
            if (result.succeeded) {
                // Redirect the whole page (not just react) to the returnUrl to let the server handle as well as the client.
                if (!result.requiresEmailConfirmation && !result.requiresTwoFactor) {
                    setIsDoingFullPageRedirect(true);
                    window.location.href = result.returnUrl;
                }
            }
        }
    }, [confirmInvite, model, navigate, validate, setIsDoingFullPageRedirect]);


    // Render the UI.
    return (
        <MainContainer color="transparent" centerChildren="vertically">
            <BodyBackground background="account" />
            <AccountCard>
                <CardHeader>
                    <h1>
                        {t('confirmInvite.finishRegistrationHeading', 'Register your account')}
                    </h1>
                </CardHeader>
                <CardBody>
                    <p>
                        {t('confirmInvite.thanksForAccepting', 'Thanks for accepting the invite.  You can now set a password to finish your account creation.')}
                    </p>

                    <Form onSubmit={async e => { e.preventDefault(); await performRegister(); }}>
                        <AlertOnErrors simple errors={[confirmInviteErrors]} />

                        <ConfirmInviteProfileDetails />

                        <FormGroup>
                            <Label htmlFor="password">{t('register.password', 'Password')}</Label>
                            <ValidatedInput type="password" name="password" autoComplete="new-password" value={model.password} onChange={e => change({ password: e.currentTarget.value })} onBlur={e => validate('password')} validationErrors={validationErrors['password']} />
                            <FormText color="white">
                                {passwordRequirements}
                            </FormText>
                        </FormGroup>
                        <FormGroup>
                            <Label htmlFor="confirmPassword">{t('register.confirmPassword', 'Confirm password')}</Label>
                            <ValidatedInput type="password" name="confirmPassword" autoComplete="new-password" value={model.confirmPassword} onChange={e => change({ confirmPassword: e.currentTarget.value })} onBlur={e => validate('confirmPassword')} validationErrors={validationErrors['confirmPassword']} />
                        </FormGroup>


                        <FormButtons>
                            <Row>
                                <Col>
                                </Col>
                                <Col xs="auto">
                                    <ButtonAsync type="submit" color="primary" isExecuting={isConfirmingInvite || isDoingFullPageRedirect}
                                        executingChildren={<><Spinner size="sm" /> {t('confirmInvite.registering', 'Registering...')}</>}>
                                        {t('confirmInvite.register', 'Register')}
                                    </ButtonAsync>
                                </Col>
                            </Row>
                        </FormButtons>
                    </Form>
                </CardBody>
            </AccountCard>
        </MainContainer>
    );
};
