import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import { useValidatorCallback } from "pojo-validator-react";
import { ValidatedInput } from "pojo-validator-reactstrap";
import { useMemo, useState, } from "react";
import { ConditionalFragment } from "react-conditionalfragment";
import { useTranslation } from "react-i18next";
import { useAsyncCallback } from "react-use-async-callback";
import { Button, CardTitle, Col, FormGroup, Input, Label, ListGroup, ModalBody, ModalFooter, ModalHeader, Row, Spinner } from "reactstrap";
import { ButtonAsync } from "reactstrap-buttonasync";
import { useToggleState } from "use-toggle-state";
import { QueryMessage, queryMessageDefaultValues } from "../../api/main/models/QueryMessage";
import { useSaveQueryMessageAndEmailMemberMutation } from "../../api/main/queryMessages/useSaveQueryMessageAndEmailMemberMutation";
import { useAdminQueryMessagesForAllMembersViewModel } from "../../api/main/queryMessages/viewModels/useAdminQueryMessageForAllMembersViewModel";
import { AlertOnErrors } from "../../shared/alertOnErrors";
import { useChanges } from "../../shared/useChanges";
import { useReplaceSearchParamsEffect, useSearchParams } from "../../shared/useURLSearchParams";
import { QueryMessageThreadList } from "../queryMessages/QueryMessageThreadList";
import { Banner } from "../shared/banner/Banner";
import { LoadingIndicator } from "../shared/loadingIndicator/LoadingIndicator";
import { MainContainer } from "../shared/mainContainer/MainContainer";
import { NoResultsFound } from "../shared/noResultsFound/NoResultsFound";
import { SearchInput } from "../shared/searchInput/SearchInput";
import { StyledModal } from "../shared/styledModal/StyledModal";

export type MembersWithMessagesType = {
    memberId: string,
    memberName: string,
    photoBlobReferenceId: string | null | undefined,
    messages: [Array<QueryMessage>];
};

/**
 * Component for displaying QueryMessages from a Member
 */
export const AdminQueryMessagesOverview = () => {
    const { t } = useTranslation();

    // Filtering for All, Open, or Closed
    const [messageFilter, setMessageFilter] = useState<'all' | 'closed' | 'open'>('open');

    // Filtering for timespan of messages to get
    const [_timespanFilter, setTimespanFilter] = useState<number>(1);
    const timespanFilter = useMemo(() => messageFilter === 'open' ? 0 : _timespanFilter, [messageFilter, _timespanFilter]);

    // Load the data
    const {
        data: {
            items,
            queryMessageTypes,
            profiles,
        }, isLoading, errors: loadErrors, refresh: refreshMessages
    } = useAdminQueryMessagesForAllMembersViewModel({ numberOfMonths: timespanFilter, });

    // Model (QueryMessage)
    const { model, change, changes } = useChanges({} as any, { ...queryMessageDefaultValues(), submittedDate: moment() });
    const [saveQueryMessageAndEmailMember] = useSaveQueryMessageAndEmailMemberMutation();

    // QueryMessage Validation
    const [validate, validationErrors] = useValidatorCallback((validation, fieldsToCheck) => {
        const rules = {
            subject: () => !model?.queryMessageTypeId ? t('messageModal.errors.subjectRequired', 'Subject is required') : '',
            memberId: () => !model?.memberId ? t('messageModal.errors.memberRequired', 'Member is required') : '',
        };
        validation.checkRules(rules, fieldsToCheck);
    }, [model]);

    // Save the QueryMessage
    const [saveForm, { isExecuting: isSaving, errors: saveFormErrors }] = useAsyncCallback(async () => {
        if (!model) {
            return;
        }

        if (!validate()) {
            return;
        }

        // Set the changes
        changes.isReplyFromStaff = true;

        // Save the main model
        await saveQueryMessageAndEmailMember(model.id, { ...changes }, true);

        // Close the modal
        setIsOpen();

        // Refresh the messages list
        if (refreshMessages) {
            refreshMessages();
        }
    }, [model, changes,]);

    // Filtering for subjects
    const [subjectFilter, setSubjectFilter] = useState<string | undefined>();

    // Search.
    const { memberSearch: memberSearchParam, messageSearch: messageSearchParam } = useSearchParams();
    const [memberSearch, setMemberSearch] = useState<string>(memberSearchParam ?? '');
    const [messageSearch, setMessageSearch] = useState<string>(messageSearchParam ?? '');

    // Keep the URL up to date with the search text.
    useReplaceSearchParamsEffect({ memberSearch, messageSearch });

    // Generate an Array of threads
    const allThreadsWithMember = useMemo(() => {
        if (!items) return;

        let result: Array<MembersWithMessagesType> = [];

        // Here i was going to filter the messages with !it.threadKey, then add all the related messages for each thread. Each thread should contain the member details 
        items?.filter(it => !it.threadKey).forEach(item => {
            const memberId = item.memberId;
            const memberName = `${item.member.user.profile?.firstName} ${item.member.user.profile?.lastName}`;
            const photoBlobReferenceId = item.member.photoBlobReferenceId;
            const allMessagesForThread = [item, ...items?.filter(it => it.threadKey === item.id)];

            result.push({
                memberId,
                memberName,
                photoBlobReferenceId,
                messages: [allMessagesForThread.flat()]
            });
        });

        // Sort threads so the one with the most recent reply from staff is top
        result = result.sort((a, b) => {
            const aLastMessage = a.messages[0].slice(-1)[0];
            const bLastMessage = b.messages[0].slice(-1)[0];

            if (!aLastMessage || !bLastMessage) return 0;

            return new Date(bLastMessage.submittedDate).getTime() - new Date(aLastMessage.submittedDate).getTime();
        });

        // Sort the threads by the resolved status so that those with resolved false are at the top
        result = result.sort((a, b) => {
            const aLastMessage = a.messages[0].slice(-1)[0];
            const bLastMessage = b.messages[0].slice(-1)[0];

            if (!aLastMessage || !bLastMessage) return 0;

            return Number(aLastMessage.resolved) - Number(bLastMessage.resolved);
        });


        return result;
    }, [items]);

    // Filter based on the state dropdown.
    const threadsWithMembers = useMemo(() => {
        let ret = allThreadsWithMember;

        // Filter by message filter.
        switch (messageFilter) {
            // Return items that have one or more open messages.
            case 'open':
                ret = ret?.map(item => {
                    // Get the threads with at least one unresolved message.  These are considered open.
                    const openThreads = item.messages.filter(thread => !!thread.find(message => !message.resolved));
                    return {
                        ...item,
                        messages: openThreads,
                    } as MembersWithMessagesType;
                }).filter(item => item.messages.length > 0);
                break;

            // Return items that do not have any open messags.
            case 'closed':
                ret = ret?.map(item => {
                    // Get the threads with zero unresolved message.  These are considered closed.
                    const closedThreads = item.messages.filter(thread => !thread.find(message => !message.resolved));
                    return {
                        ...item,
                        messages: closedThreads,
                    } as MembersWithMessagesType;
                }).filter(item => item.messages.length > 0);
                break;

            // Return everything, no filtering needed.
            case 'all':
            default:
                break; // Do nothing.
        }

        // Filter by the subject.
        if (!!subjectFilter && subjectFilter !== 'all') {
            ret = ret?.map(item => {
                // Get the threads with at least one message matching the subject (we know they will all match in practice but this is how we filter to be similar to the state filter above).
                const matchingSubjectsThreads = item.messages.filter(thread => !!thread.find(message => message.queryMessageTypeId === subjectFilter));
                return {
                    ...item,
                    messages: matchingSubjectsThreads,
                } as MembersWithMessagesType;
            }).filter(item => item.messages.length > 0);
        }

        // Filtering by the search text -> Message Content
        let lowerMessageSearch = messageSearch.toLocaleLowerCase();
        if (lowerMessageSearch) {
            ret = ret?.map(item => {
                // Get the threads with at least one message matching the search (we know they will all match in practice but this is how we filter to be similar to the state filter above).
                const matchingSearchThreads = item.messages.filter(thread => thread.some(message => {
                    return message.contentsHtml.toLocaleLowerCase().indexOf(lowerMessageSearch) >= 0;
                }));

                return {
                    ...item,
                    messages: matchingSearchThreads,
                } as MembersWithMessagesType;
            }).filter(item => item.messages.length > 0);
        }

        // Filtering by the search text -> Member Name
        let lowerMemberSearch = memberSearch.toLocaleLowerCase();
        if (lowerMemberSearch) {
            ret = ret?.filter(item =>
                item.memberName.toLocaleLowerCase().indexOf(lowerMemberSearch) >= 0
            );
        }

        // Return the filtered results.
        return ret;
    }, [allThreadsWithMember, messageFilter, subjectFilter, messageSearch, memberSearch]);

    // New thread modal
    const [isOpen, setIsOpen] = useToggleState();

    // Redner the UI
    //
    return (
        <>
            <Banner>
                <Row>
                    <Col>
                        <h1>
                            {t('adminQueryMessagesOverview.title', 'All messages')}
                        </h1>
                    </Col>
                    <ConditionalFragment showIf={isLoading}>
                        <Col xs="auto">
                            <LoadingIndicator size="sm" />
                        </Col>
                    </ConditionalFragment>
                </Row>
                <Row>
                    <Col>
                        <SearchInput value={memberSearch} onChange={e => setMemberSearch(e.currentTarget.value)} placeholder={t('common.memberSearch', 'Search member details')} />
                    </Col>
                    <Col>
                        <SearchInput value={messageSearch} onChange={e => setMessageSearch(e.currentTarget.value)} placeholder={t('common.messageSearch', 'Search message content')} />
                    </Col>
                    <Col xs="auto">
                        <Input type="select" value={subjectFilter} onChange={e => setSubjectFilter(e.currentTarget.value)}>
                            <>
                                <option value="">{t('adminQueryMessagesOverview.subjectFilter.all', 'All subjects')}</option>
                                {queryMessageTypes?.map(queryMessageType => (
                                    <option value={queryMessageType.id} key={queryMessageType.id}>{queryMessageType.name}</option>
                                ))}
                            </>
                        </Input>
                    </Col>
                    <Col xs="auto">
                        <Input type="select" value={messageFilter} onChange={e => { setMessageFilter(e.currentTarget.value as any); }}>
                            <option value="open">{t('adminQueryMessagesOverview.messageFilter.open', 'Open')}</option>
                            <option value="closed">{t('adminQueryMessagesOverview.messageFilter.closed', 'Closed')}</option>
                            <option value="all">{t('adminQueryMessagesOverview.messageFilter.all', 'All messages')}</option>
                        </Input>
                    </Col>
                    <ConditionalFragment showIf={messageFilter !== 'open'}>
                        <Col xs="auto">
                            <Input type="select" value={timespanFilter} onChange={e => setTimespanFilter(parseInt(e.currentTarget.value))}>
                                <option value="0">{t('adminQueryMessagesOverview.timespanFilter.all', 'All time')}</option>
                                <option value="1">{t('adminQueryMessagesOverview.timespanFilter.oneMonth', 'One month')}</option>
                                <option value="3">{t('adminQueryMessagesOverview.timespanFilter.twoMonths', 'Three months')}</option>
                                <option value="6">{t('adminQueryMessagesOverview.timespanFilter.sixMonths', 'Six months')}</option>
                                <option value="12">{t('adminQueryMessagesOverview.timespanFilter.twelveMonths', 'Twelve months')}</option>
                            </Input>
                        </Col>
                    </ConditionalFragment>
                </Row>
            </Banner>

            <MainContainer>
                <AlertOnErrors
                    errors={[
                        loadErrors, saveFormErrors
                    ]}
                />

                <ListGroup>
                    {threadsWithMembers?.map(member => (
                        <QueryMessageThreadList member={member} profiles={profiles} queryMessageTypes={queryMessageTypes} />
                    ))}
                </ListGroup>

                <Row style={{ marginTop: '1rem', textAlign: 'center' }}>
                    <Col>
                        <Button color="primary" onClick={e => setIsOpen()}>
                            <FontAwesomeIcon icon="message" />
                            <> </>
                            {t('common.newMessage', 'New message')}
                        </Button>
                    </Col>
                </Row>

                <StyledModal isOpen={isOpen} toggle={() => setIsOpen()} size="lg">
                    <ModalHeader toggle={() => setIsOpen()}>
                        <CardTitle tag="h1">
                            {t('adminQueryMessagesOverview.messageModal.modal.message', 'Message')}
                        </CardTitle>
                    </ModalHeader>

                    <ModalBody>
                        <Row>
                            <Col>
                                <FormGroup>
                                    <Label htmlFor="queryMessageType">{t('common.subject', 'Subject')}</Label>
                                    <ValidatedInput name="queryMessageType" type="select" value={model?.queryMessageTypeId ?? ''} onChange={(e) => change({ queryMessageTypeId: e.currentTarget.value })} onBlur={e => validate('subject')} validationErrors={validationErrors['subject']}>
                                        <option value="">{t('common.pleaseSelectASubject', '(Please select a subject)')}</option>
                                        {queryMessageTypes?.map(queryMessageType => (
                                            <option value={queryMessageType.id} >{queryMessageType.name}</option>
                                        ))}
                                    </ValidatedInput>
                                </FormGroup>
                            </Col>

                            <Col>
                                <FormGroup>
                                    <Label htmlFor="member">{t('common.member', 'Member')}</Label>
                                    <ValidatedInput name="member" type="select" value={model?.memberId ?? ''} onChange={(e) => change({ memberId: e.currentTarget.value })} onBlur={e => validate('memberId')} validationErrors={validationErrors['memberId']}>
                                        <option value="">{t('common.pleaseSelectAMember', '(Please select a member)')}</option>
                                        {profiles?.filter(it => it.isMember)?.map(member => (
                                            <option value={member.id} >{member.firstName} {member.lastName} ({member.primaryEmail})</option>
                                        ))}
                                    </ValidatedInput>
                                </FormGroup>
                            </Col>
                        </Row>

                        <Row>
                            <FormGroup>
                                <Label htmlFor="contentsHtml" style={{
                                    fontSize: '2rem', color: '#fada00', margin: '1rem 0'
                                }}>{t('common.yourMessage', 'Your message')}</Label>
                                <Input type="textarea" rows={8} value={model?.contentsHtml ?? ''} onChange={(e) => change({ contentsHtml: e.currentTarget.value })} />
                            </FormGroup>
                        </Row>
                    </ModalBody>

                    <ModalFooter>
                        <ConditionalFragment showIf={!isLoading}>
                            <div className="text-center">
                                <ButtonAsync color="primary" isExecuting={isSaving} onClick={() => saveForm()}
                                    executingChildren={<><Spinner size="sm" /> {t('common.sendingMessage', 'Sending message...')}</>}>
                                    <FontAwesomeIcon icon="save" />
                                    <> </>
                                    {t('adminQueryMessagesOverview.messageModal.sendMessage.button', 'Send message')}
                                </ButtonAsync>
                                <> </>
                                <Button color="primary" outline onClick={e => change({ contentsHtml: '' })}>
                                    {t('common.clear', 'Clear')}
                                </Button>
                                <> </>
                                <Button color="primary" outline onClick={e => setIsOpen()}>
                                    {t('common.close', 'Close')}
                                </Button>
                            </div>
                        </ConditionalFragment>
                    </ModalFooter>
                </StyledModal>

                <ConditionalFragment showIf={!isLoading && !threadsWithMembers?.length}>
                    <NoResultsFound />
                </ConditionalFragment>
            </MainContainer>
        </>
    );
};