import gql from 'graphql-tag';
import { Guid } from 'guid-string';
import { ChildFieldsFragment } from '../generated/graphql';
import moment from "moment";

export const childFieldsFragment = gql`
    fragment childFields on Child {
        id
        firstName
        lastName
        archived
        genderId
        dateOfBirth
        memberId
        memberChildsRelationshipId
        registeredDate
        healthNoteHtml
        photoBlobReferenceId

    }
`;

export type Child = Omit<ChildFieldsFragment, '__typename'>;

/**
 * Default values.
 */
export const childDefaultValues = (): Partial<Child> => ({
    id: Guid.newGuid(),
    firstName: '',
    lastName: '',
    archived: false,
    genderId: undefined,
    dateOfBirth: moment().startOf('day').toISOString(),
    memberId: undefined,
    memberChildsRelationshipId: undefined,
    registeredDate: moment(),
    healthNoteHtml: '',
    photoBlobReferenceId: null,
});