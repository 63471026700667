import { gql, useLazyQuery } from "@apollo/client";
import { Guid } from "guid-string";
import React from "react";
import { mainApiConfig } from "../../../../configure/mainApiConfig";
import { AsyncLoadOptions, AsyncLoadResult } from "../../../../shared/abstractStore";
import { StaffQualificationsTabViewModelQuery, StaffQualificationsTabViewModelQueryVariables } from "../../generated/graphql";
import { blobReferenceFieldsFragment } from "../../models/BlobReference";
import { staffQualificationCheckFieldsFragment } from "../../models/StaffQualificationCheck";

export function useStaffQualifcationsTabViewModel(staffId: string | undefined | null, options: AsyncLoadOptions = {}): AsyncLoadResult<StaffQualificationsTabViewModelQuery> {
    // Query data we need from the api.
    const [load, { data, loading, error, refetch, }] = useLazyQuery<StaffQualificationsTabViewModelQuery, StaffQualificationsTabViewModelQueryVariables>(
        gql`
        query staffQualificationsTabViewModelQuery ($staffId: ID!) {
            staffQualificationChecks (staffId: $staffId) {
                ...staffQualificationCheckFields

                evidenceBlobReference {
                    ...blobReferenceFields
                }
            }
        }

        ${staffQualificationCheckFieldsFragment}
        ${blobReferenceFieldsFragment}
        `,
        {
            variables: {
                staffId: staffId ?? Guid.empty,
            },
            fetchPolicy: options.fetchPolicy ?? mainApiConfig.defaultFetchPolicy,
            pollInterval: options.pollInterval,
            onCompleted: (data) => {
                if (options.onCompleted) {
                    options.onCompleted(data);
                }
            }
        }
    );

    // Run the query (unless we are being lazy).
    React.useEffect(() => {
        if (!options.lazy) {
            load();
        }
    }, [options.lazy, load]);

    // Return the results.
    const refresh = React.useCallback(async () => { !refetch ? await load() : await refetch(); }, [refetch, load]);
    return React.useMemo(() => ({
        data: data ?? ({} as any),
        refresh: refresh,
        isLoading: loading,
        errors: error,
    }), [data, refresh, loading, error,]);
};