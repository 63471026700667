import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import { useMemo, useState } from "react";
import { ConditionalFragment } from "react-conditionalfragment";
import { useTranslation } from "react-i18next";
import { Button, Col, FormGroup, Label, Row, Table } from "reactstrap";
import { useReportDataSourceServiceForQueryMessagesViewModel } from "../../../api/main/dashboard/viewModels/useReportDataSourceServiceForQueryMessagesViewModel";
import { AlertOnErrors } from "../../../shared/alertOnErrors";
import { ValidatedISODateTimeInput } from "../../shared/isoDateTimeInput/ValidatedISODateTimeInput";
import { LoadingIndicator } from "../../shared/loadingIndicator/LoadingIndicator";
import { NoResultsFound } from "../../shared/noResultsFound/NoResultsFound";
import { QueryMessagesDonutChart } from "../charts/QueryMessagesDonutChart";

/**
 * QueriesTab for Dashboard
 */
export const QueriesTab = () => {
    const { t } = useTranslation();

    // Default date range: endDate is today and startDate is 60 days before today
    const defaultDateRange = useMemo(() => {
        return {
            startDate: moment().subtract(60, 'days').toISOString(),
            endDate: moment().toISOString()
        };
    }, []);

    // Handle date range state
    const [startDate, setStartDate] = useState(defaultDateRange.startDate);
    const [endDate, setEndDate] = useState(defaultDateRange.endDate);

    // Load the data
    const {
        data: {
            queryMessagesData
        }, isLoading, errors: loadErrors
    } = useReportDataSourceServiceForQueryMessagesViewModel(startDate, endDate);

    // Render the UI
    //
    return (
        <>
            {/* Alert on errors */}
            <AlertOnErrors
                errors={[loadErrors]}
            />

            {/* Date range selector */}
            <Row>
                <Col>
                    <FormGroup>
                        <Label htmlFor="startDate">{t('dashboard.queriesTab.startDate.label', 'Start date')}</Label>
                        <ValidatedISODateTimeInput name="startDate" type="date" value={startDate ?? ''} onChange={e => setStartDate(e.currentTarget.value)} onBlur={e => { }} validationErrors={{}} />
                    </FormGroup>
                </Col>

                <Col>
                    <FormGroup>
                        <Label htmlFor="endDate">{t('dashboard.queriesTab.endDate.label', 'End date')}</Label>
                        <ValidatedISODateTimeInput name="endDate" type="date" value={endDate ?? ''} onChange={e => setEndDate(e.currentTarget.value)} onBlur={e => { }} validationErrors={{}} />
                    </FormGroup>
                </Col>
            </Row>

            {/* Loading indicator */}
            <ConditionalFragment showIf={!!isLoading}>
                <LoadingIndicator />
            </ConditionalFragment>

            {/* Render the Chart and our Table of data */}
            <ConditionalFragment showIf={!isLoading && !!queryMessagesData}>
                <QueryMessagesDonutChart chartData={queryMessagesData} />

                {/* Export button */}
                <Row>
                    <Col>
                    </Col>

                    <Col xs="auto">
                        <Button color="link">
                            <a className="btn btn-outline-primary" href={`/api/exportreports/exportquerymessages?format=${encodeURIComponent('xlsx')}&startDate=${startDate}&endDate=${endDate}`} download>
                                <FontAwesomeIcon icon="download" />
                                <> </>
                                {t('dashboard.queriesTab.download', 'Export')}
                            </a>
                        </Button>
                    </Col>
                </Row>

                {/* Table data */}
                <Table responsive striped>
                    <thead>
                        <tr>
                            <th>{t('dashboard.queriesTab.fromDate.heading', 'From date')}</th>
                            <th>{t('dashboard.queriesTab.toDate.heading', 'To date')}</th>
                            <th>{t('dashboard.queriesTab.numberOfDays.heading', 'Number of days')}</th>
                            <th>{t('dashboard.queriesTab.numberOfQueries.heading', 'Number of query messages')}</th>
                            <th>{t('dashboard.queriesTab.repliesWithinOneDay.heading', 'Replies within 1 day')}</th>
                            <th>{t('dashboard.queriesTab.repliesWithinTwoDays.heading', 'Replies within 2 days')}</th>
                            <th>{t('dashboard.queriesTab.repliesWithinThreeDays.heading', 'Replies within 3 days')}</th>
                            <th>{t('dashboard.queriesTab.repliesWithinSevenDays.heading', 'Replies within 7 days')}</th>
                            <th>{t('dashboard.queriesTab.repliesOverAWeek.heading', 'Replies over a week')}</th>
                            <th>{t('dashboard.queriesTab.noReply.heading', 'No reply')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                {t('common.date', '{{date, DD/MM/YYYY}}', { date: queryMessagesData?.dateFrom })}
                            </td>

                            <td>
                                {t('common.date', '{{date, DD/MM/YYYY}}', { date: queryMessagesData?.dateTo })}
                            </td>

                            <td>
                                {queryMessagesData?.numberOfDays}
                            </td>

                            <td>
                                {(queryMessagesData?.repliedWithinOneDay ?? 0) + (queryMessagesData?.repliedWithinTwoDays ?? 0) + (queryMessagesData?.repliedWithinThreeDays ?? 0) + (queryMessagesData?.repliedWithinSevenDays ?? 0) + (queryMessagesData?.repliedLongerThanSevenDays ?? 0) + (queryMessagesData?.noReply ?? 0)}
                            </td>

                            <td>
                                {queryMessagesData?.repliedWithinOneDay}
                            </td>

                            <td>
                                {queryMessagesData?.repliedWithinTwoDays}
                            </td>

                            <td>
                                {queryMessagesData?.repliedWithinThreeDays}
                            </td>

                            <td>
                                {queryMessagesData?.repliedWithinSevenDays}
                            </td>

                            <td>
                                {queryMessagesData?.repliedLongerThanSevenDays}
                            </td>

                            <td>
                                {queryMessagesData?.noReply}
                            </td>
                        </tr>
                    </tbody>
                </Table>
            </ConditionalFragment>

            {/* No results found */}
            <ConditionalFragment showIf={!isLoading && !queryMessagesData}>
                <NoResultsFound />
            </ConditionalFragment>
        </>
    );
};