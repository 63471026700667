import gql from 'graphql-tag';
import { LocationDistanceFieldsFragment } from '../generated/graphql';

export const locationDistanceFieldsFragment = gql`
    fragment locationDistanceFields on LocationDistance {
        classLocationId
        originPointLongitude
        originPointLatitude
        classLocationLongitude
        classLocationLatitude
        distanceFromPoint
    }
`;

export type LocationDistance = Omit<LocationDistanceFieldsFragment, '__typename'>;
