import * as React from 'react';
import { useSaveInStore, SaveInStoreOptions } from '../../../shared/useSaveInStore';
import { useUpdateClassLocationMutation } from './useUpdateClassLocationMutation';
import { ClassLocation } from '../models/ClassLocation';
import { useCreateClassLocationMutation } from './useCreateClassLocationMutation';
import { ClassLocationCreateInput, ClassLocationUpdateInput } from '../generated/graphql';

/**
 * Get a callback to save a ClassLocation in the api using either a create or update.
 */
export function useSaveClassLocationMutation(options: SaveInStoreOptions<ClassLocation, string> = {}) {
    const [_create, createStatus] = useCreateClassLocationMutation();
    const create = React.useCallback((model: Partial<ClassLocation>) => _create(model as ClassLocationCreateInput), [_create]);

    const [_update, updateStatus] = useUpdateClassLocationMutation();
    const update = React.useCallback((id: string, changes: Partial<ClassLocation>) => _update(id, changes as ClassLocationUpdateInput), [_update]);

    return useSaveInStore(
        [create, createStatus],
        [update, updateStatus],
        options
    );
}
