import './uploadedFilePreview.scss';

import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { ConditionalFragment } from 'react-conditionalfragment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { BlobReference } from '../../../api/main/models/BlobReference';

export interface UploadedFilePreviewProps {
    blobReference: BlobReference | null | undefined,

    className?: string,
    style?: React.CSSProperties,
}

/**
 * Preview of an image uploaded by a user, suitable for use on an Edit form for example and made to look similar to other controls that would show in that context.
 */
export const UploadedFilePreview = (props: UploadedFilePreviewProps) => {
    const {
        blobReference,
        className,
        style,
    } = props;

    const { t } = useTranslation();

    return (
        <div className={`uploaded-file-preview ${className ?? ''}`} style={style}>
            <ConditionalFragment showIf={!!blobReference}>
                <a href={blobReference?.url} target="_blank" rel="noreferrer">
                    {blobReference?.filename || blobReference?.url}
                </a>
            </ConditionalFragment>
            <ConditionalFragment showIf={!blobReference}>
                <div className="uploaded-file-preview-no-image">
                    <div className="uploaded-file-preview-no-image-icon">
                        <FontAwesomeIcon icon="file" />
                    </div>
                    <p>
                        {
                            t('uploadedImagePreview.noFileText', 'No file has been uploaded yet')
                        }
                    </p>
                </div>
            </ConditionalFragment>
        </div>
        );
};
