import { HappyIcon } from "../../shared/Utilities/HappyIcon";

export const useStaffAbsenceTypeForIcons = (absenceType: string) => {
    switch (absenceType) {
        case "Sick - AWOL": return (<><HappyIcon icon="sick-awol" /> <> </></>);
        case "Sick - Notified": return (<><HappyIcon icon="sick-notified" /><> </></>);
        case "Annual Leave": return (<><HappyIcon icon="annual-leave" /><> </></>)
        }

    // If we arrive here then we don't know, so return undefined
    return undefined;
}