import * as React from 'react';
import { useSaveInStore, SaveInStoreOptions } from '../../../shared/useSaveInStore';
import { useUpdateChildAbsenceTypeMutation } from './useUpdateChildAbsenceTypeMutation';
import { ChildAbsenceType } from '../models/ChildAbsenceType';
import { useCreateChildAbsenceTypeMutation } from './useCreateChildAbsenceTypeMutation';
import { ChildAbsenceTypeCreateInput, ChildAbsenceTypeUpdateInput } from '../generated/graphql';

/**
 * Get a callback to save a ChildAbsenceType in the api using either a create or update.
 */
export function useSaveChildAbsenceTypeMutation(options: SaveInStoreOptions<ChildAbsenceType, string> = {}) {
    const [_create, createStatus] = useCreateChildAbsenceTypeMutation();
    const create = React.useCallback((model: Partial<ChildAbsenceType>) => _create(model as ChildAbsenceTypeCreateInput), [_create]);

    const [_update, updateStatus] = useUpdateChildAbsenceTypeMutation();
    const update = React.useCallback((id: string, changes: Partial<ChildAbsenceType>) => _update(id, changes as ChildAbsenceTypeUpdateInput), [_update]);

    return useSaveInStore(
        [create, createStatus],
        [update, updateStatus],
        options
    );
}
