import * as React from 'react';
import { useSaveInStore, SaveInStoreOptions } from '../../../shared/useSaveInStore';
import { useUpdateEmergencyContactMutation } from './useUpdateEmergencyContactMutation';
import { EmergencyContact } from '../models/EmergencyContact';
import { useCreateEmergencyContactMutation } from './useCreateEmergencyContactMutation';
import { EmergencyContactCreateInput, EmergencyContactUpdateInput } from '../generated/graphql';

/**
 * Get a callback to save a EmergencyContact in the api using either a create or update.
 */
export function useSaveEmergencyContactMutation(options: SaveInStoreOptions<EmergencyContact, string> = {}) {
    const [_create, createStatus] = useCreateEmergencyContactMutation();
    const create = React.useCallback((model: Partial<EmergencyContact>) => _create(model as EmergencyContactCreateInput), [_create]);

    const [_update, updateStatus] = useUpdateEmergencyContactMutation();
    const update = React.useCallback((id: string, changes: Partial<EmergencyContact>) => _update(id, changes as EmergencyContactUpdateInput), [_update]);

    return useSaveInStore(
        [create, createStatus],
        [update, updateStatus],
        options
    );
}
