import { ValidationErrors } from "pojo-validator";
import { useCallback, useEffect, useMemo } from "react";
import { ConditionalFragment } from "react-conditionalfragment";
import { useTranslation } from "react-i18next";
import { Button, Card, CardBody, CardFooter, Col, Collapse, ListGroup, Row, } from "reactstrap";
import { useToggleState } from "use-toggle-state";
import { ClassStage } from "../../../../api/main/models/ClassStage";
import { ClassSubStage } from "../../../../api/main/models/ClassSubStage";
import { LessonDocument, lessonDocumentDefaultValues } from "../../../../api/main/models/LessonDocument";
import { ModelArrayChanges } from "../../../../shared/useChanges";
import { CardHeaderCollapse } from "../../../shared/cardHeaderCollapse/CardHeaderCollapse";
import { ClassStageLessonDocumentUploadComponent } from "./ClassStageLessonDocumentUploadComponent";

export interface ClassStageLessonDocumentComponentProps {
    model: ClassSubStage | undefined,
    lessonDocumentsManager: ModelArrayChanges<LessonDocument, string>,
    classStage: ClassStage | null | undefined,

    validateLessonDocument: (model: LessonDocument) => boolean,
    lessonDocumentValidationErrors: (id: string) => ValidationErrors,

    lessonNumber: number | undefined;
}

/**
 * Component under ClassStages used for managing LessonDocuments
 */
export const ClassStageLessonDocumentComponent = (props: ClassStageLessonDocumentComponentProps) => {
    const { model, lessonDocumentsManager, classStage, validateLessonDocument, lessonDocumentValidationErrors, lessonNumber } = props;
    const { t } = useTranslation();

    // Add a new LessonDocument and link it to the parent model
    const addLessonDocument = useCallback(() => {
        lessonDocumentsManager.addFor({ ...lessonDocumentDefaultValues(), classStageId: classStage?.id, classSubStageId: model?.id, termLessonNumber: lessonNumber, isLessonPlan: true, name: 'Lesson plan' });
    }, [lessonDocumentsManager, lessonNumber, model, classStage]);
    const addSupportingLessonDocument = useCallback(() => {
        lessonDocumentsManager.addFor({ ...lessonDocumentDefaultValues(), classStageId: classStage?.id, classSubStageId: model?.id, termLessonNumber: lessonNumber, isLessonPlan: false, });
    }, [lessonDocumentsManager, lessonNumber, model, classStage]);

    // Handle toggling the CardCollapse
    const [isOpen, toggleOpen] = useToggleState(false);

    // Document counters
    const lessonPlanCount = useMemo(() => {
        return lessonDocumentsManager.model.filter(it => it.termLessonNumber === lessonNumber && it.classSubStageId === model?.id && it.isLessonPlan === true).length;
    }, [lessonDocumentsManager, model, lessonNumber]);
    const supportingDocsCount = useMemo(() => {
        return lessonDocumentsManager.model.filter(it => it.termLessonNumber === lessonNumber && it.classSubStageId === model?.id && it.isLessonPlan === false).length;
    }, [lessonDocumentsManager, model, lessonNumber]);
    const missingLessonPlanCounts = useMemo(() => {
        return lessonDocumentsManager.model.filter(it => it.termLessonNumber === lessonNumber && it.classSubStageId === model?.id && it.isLessonPlan === true && it.blobReferenceId !== null).length - 1;
    }, [lessonDocumentsManager, model, lessonNumber]);
 
    // If there are no lesson plans for a particular stage then the lessonPlanCount will 0, in that instance we will render the uploadComponent for each lesson within a stage, the user will still need to attach a file and save. Upon saving we only save the entries that have a file uploaded
    useEffect(() => {
        if (!lessonPlanCount) {
            addLessonDocument();
        }
    })

    // Render the UI
    //
    return (
        <>
            {
                <Card style={{margin: "10px 0"}}>
                        <CardHeaderCollapse isOpen={isOpen} toggle={toggleOpen}>
                            <Row>
                                <Col>
                                    {t('editClassStage.classStageLessonDocumentComponent.lessonPlan.heading', 'Lesson {{lessonNumber}}', { lessonNumber })}
                                    <div>
                                        {t('editClassStage.classStageLessonDocumentComponent.addLessonDocuments.subheading', 'Click to view lesson documents') }
                                    </div>
                                </Col>
                                <ConditionalFragment showIf={!!missingLessonPlanCounts}>
                                    <Col xs="auto">
                                        {t('editClassStage.classStageLessonDocumentComponent.warning.message', 'Missing lesson plan')}
                                    </Col>
                                </ConditionalFragment>
                            </Row>
                        </CardHeaderCollapse>

                        <Collapse isOpen={isOpen}>
                        <CardBody>
                            <h4>{t('editClassStage.classStageLessonDocumentComponent.lessonPlan.subheading', 'Lesson plan')}</h4>
                            <ListGroup>
                                {
                                    lessonDocumentsManager.model.filter(it => it.termLessonNumber === lessonNumber && it.classSubStageId === model?.id && it.isLessonPlan === true).map(lessonDocument => (
                                        <ClassStageLessonDocumentUploadComponent
                                            lessonDocument={lessonDocument}
                                            change={changes => lessonDocumentsManager.changeFor(lessonDocument.id, changes)}
                                            remove={() => lessonDocumentsManager.removeFor(lessonDocument.id)}
                                            validate={() => validateLessonDocument(lessonDocument)}
                                            validationErrors={lessonDocumentValidationErrors(lessonDocument.id)}
                                            key={lessonDocument.id}
                                        />
                                    ))
                                }
                            </ListGroup>

                                <ConditionalFragment showIf={!!supportingDocsCount}>
                                    <h4>{t('editClassStage.classStageLessonDocumentComponent.supportingDocuments.subheading', 'Supporting documents')}</h4>
                                    {
                                        lessonDocumentsManager.model.filter(it => it.termLessonNumber === lessonNumber && it.classSubStageId === model?.id && it.isLessonPlan === false).map(lessonDocument => (
                                            <ClassStageLessonDocumentUploadComponent
                                                lessonDocument={lessonDocument}
                                                change={changes => lessonDocumentsManager.changeFor(lessonDocument.id, changes)}
                                                remove={() => lessonDocumentsManager.removeFor(lessonDocument.id)}
                                                validate={() => validateLessonDocument(lessonDocument)}
                                                validationErrors={lessonDocumentValidationErrors(lessonDocument.id)}
                                                key={lessonDocument.id}
                                            />
                                        ))
                                    }
                                </ConditionalFragment>
                            </CardBody>

                            <CardFooter>
                                <Button color="primary" outline onClick={() => addSupportingLessonDocument()}>
                                    {t('editClassStage.classStageLessonDocumentComponent.addSupportingDocument', 'Add supporting document')}
                                </Button>
                            </CardFooter>
                        </Collapse>
                </Card>
            }
        </>
    );
};