export const locations: Array<string> = [
    "An Muileann gCearr",
    "An Ros",
    "Arklow",
    "Artane",
    "Ashbourne",
    "Athenry",
    "Athlone",
    "Bagenalstown",
    "Balally",
    "Balbriggan",
    "Baldoyle",
    "Ballina",
    "Ballincollig",
    "Ballinasloe",
    "Ballinrobe",
    "Ballinteer",
    "Ballyboden",
    "Ballyboughal",
    "Ballyfermot",
    "Ballyhaunis",
    "Ballymote",
    "Ballymun",
    "Bandon",
    "Bantry",
    "Beaumont",
    "Belturbet",
    "Birr",
    "Blackrock",
    "Blanchardstown",
    "Blessington",
    "Booterstown",
    "Boyle",
    "Bray",
    "Buncrana",
    "Bundoran",
    "Cabinteely",
    "Caherciveen",
    "Cahir",
    "Carlow",
    "Carrickmacross",
    "Carrick On Shannon",
    "Carrick On Suir",
    "Carrigaline",
    "Carrignavar",
    "Cashel",
    "Castlebar",
    "Castleblayney",
    "Castlerea",
    "Castleknock",
    "Cavan",
    "Celbridge",
    "Charleville",
    "Chapelizod",
    "Cherry Orchard",
    "Cherryville",
    "Cill Airne",
    "Clane",
    "Clifden",
    "Clondalkin",
    "Clonakilty",
    "Clones",
    "Clonmel",
    "Cluain Meala",
    "Cobh",
    "Confey",
    "Cork",
    "Cootehill",
    "Crookstown",
    "Crumlin",
    "Curragh",
    "Dalkey",
    "Darndale",
    "Derry",
    "Dollymount",
    "Donabate",
    "Donaghmede",
    "Donnybrook",
    "Donnycarney",
    "Drogheda",
    "Droichead Nua",
    "Dublin",
    "Dun Laoghaire",
    "Dunboyne",
    "Dundalk",
    "Dundrum",
    "Dungarvan",
    "Dunmanway",
    "Dunshaughlin",
    "Eadestown",
    "Edenderry",
    "Enfield",
    "Ennis",
    "Enniscorthy",
    "Fermoy",
    "Finglas",
    "Firhouse",
    "Foxrock",
    "Gaillimh",
    "Galway",
    "Garristown",
    "Glanmire",
    "Gorey",
    "Greenhills",
    "Greystones",
    "Hartstown",
    "Jobstown",
    "Kells",
    "Kenmare",
    "Kilcock",
    "Kildare",
    "Kilkenny",
    "Killester",
    "Kilmacthomas",
    "Kilmallock",
    "Kilquade",
    "Kilrush",
    "Kinsale",
    "Kinsealy-Drinan",
    "Knock",
    "Knocklyon",
    "Laytown",
    "Leixlip",
    "Lifford",
    "Listowel",
    "Letterkenny",
    "Limerick",
    "Little Bray",
    "Loch Garman",
    "Longford",
    "Loughrea",
    "Lucan",
    "Luimneach",
    "Lusk",
    "Macroom",
    "Malahide",
    "Mallow",
    "Marino",
    "Maynooth",
    "Midleton",
    "Miltown Malbay",
    "Mitchelstown",
    "Monaghan",
    "Monasterevin",
    "Monkstown",
    "Mount Merrion",
    "Mullingar",
    "Naas",
    "Navan",
    "Nenagh",
    "Nenagh Bridge",
    "Newbridge",
    "New Ross",
    "Newcastle West",
    "Newtown Trim",
    "Oldbawn",
    "Oldtown",
    "Palmerstown",
    "Portarlington",
    "Portlaoise",
    "Portmarnock",
    "Raheny",
    "Rathgar",
    "Rathmines",
    "Ratoath",
    "Ringsend",
    "Roscommon",
    "Roscrea",
    "Rush",
    "Rylane",
    "Sallins",
    "Sallynoggin",
    "Sandyford",
    "Sandymount",
    "Shankill",
    "Shannon",
    "Skerries",
    "Skibbereen",
    "Sligo",
    "Swords",
    "Tallaght",
    "Templeogue",
    "Terenure",
    "Tipperary",
    "Thurles",
    "Tra Mhor",
    "Tralee",
    "Tuam",
    "Tullamore",
    "Waterford",
    "Watergrasshill",
    "Westport",
    "Wexford",
    "Wicklow",
    "Youghal",
    "N91",
    "K56",
    "Y14",
    "D05",
    "A84",
    "H65",
    "N37",
    "R14",
    "Athy",
    "R21",
    "D16",
    "K32",
    "D13",
    "F26",
    "P31",
    "H53",
    "F31",
    "A41",
    "D10",
    "F35",
    "F56",
    "D09",
    "P72",
    "P75",
    "H14",
    "R42",
    "A94",
    "D15",
    "W91",
    "F52",
    "A98",
    "F93",
    "F94",
    "D18",
    "V23",
    "E21",
    "R93",
    "A81",
    "N41",
    "E32",
    "P43",
    "T34",
    "E25",
    "F23",
    "A75",
    "F45",
    "H12",
    "W23",
    "P56",
    "D20",
    "R51",
    "V93",
    "H71",
    "D22",
    "P85",
    "H23",
    "E91",
    "P24",
    "H16",
    "T12",
    "P14",
    "D12",
    "R56",
    "A96",
    "D17",
    "D03",
    "K36",
    "D04",
    "A92",
    "W12",
    "A86",
    "A91",
    "D14",
    "X35",
    "P47",
    "A85",
    "R45",
    "A83",
    "V95",
    "Y21",
    "P61",
    "D11",
    "D24",
    "A82",
    "R95",
    "X42",
    "V35",
    "V15",
    "P17",
    "K67",
    "F12",
    "V31",
    "F92",
    "V94",
    "Y35",
    "N39",
    "H62",
    "K78",
    "K45",
    "P12",
    "P51",
    "P25",
    "P67",
    "H18",
    "W34",
    "C15",
    "E45",
    "Y34",
    "V42",
    "A45",
    "R32",
    "D06",
    "F42",
    "E53",
    "P32",
    "V14",
    "K34",
    "P81",
    "F91",
    "E34",
    "E41",
    "X91",
    "V92",
    "H54",
    "R35",
    "T56",
    "F28",
    "A67",
    "P36",
    "H91",
    "A42",
    "T45",
    "Y25",
    "D08",
    "A63",
];