import { useCallback, useMemo } from "react";
import { ConditionalFragment } from "react-conditionalfragment";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Button, Col, ListGroup, ListGroupItem, Row } from "reactstrap";
import { useCourtesyClassCreditsForMembersChildViewModel } from "../../../../api/main/courtesyClassCredits/viewModels/useCourtesyClassCreditsForMembersChildViewModel";
import { Child } from "../../../../api/main/models/Child";
import { useScheduledClasses } from "../../../../api/main/scheduledClasses/useScheduledClasses";
import { AlertOnErrors } from "../../../../shared/alertOnErrors";
import { NoResultsFound } from "../../../shared/noResultsFound/NoResultsFound";
import { ChildAttendanceWithLocationAndStaff } from "../classesTab/classesTab";
import moment from "moment";
import { RoleInClass } from "../../../../api/main/models/constants/RoleInClass";
import { ClassesComponent } from "../classesTab/classesComponent";
import { HorizontalRule } from "../../../shared/horizontalRule/HorizontalRule";

export interface CourtesyClassCreditsTabProps {
    model: Child | undefined | null,
}

/**
 * CourtesyClassCredits for MembersChildTab
 */
export const CourtesyClassCreditsTab = (props: CourtesyClassCreditsTabProps) => {
    const {
        model
    } = props;
    const { t } = useTranslation();
    const navigate = useNavigate();

    // Load the data - returns only the unconsumed credits
    const {
        data: {
            items: _items,
            childAttendances,
            classLocations,
            profiles,
            classStages,
            classSubStages,
            classTypes
        }, isLoading: _isLoading, errors: loadErrors
    } = useCourtesyClassCreditsForMembersChildViewModel(model?.id);

    // Load the supporting data
    const {
        data: {
            items: scheduledClasses
        }, isLoading: isLoadingSupportingData, errors: supportingDataLoadErrors
    } = useScheduledClasses();
    const isLoading = _isLoading || isLoadingSupportingData;

    // Combine all data for ChildAttendance (CourtesyClasses) the child is registered in.
    const allCourtesyClasses = useMemo(() => childAttendances?.filter(it => moment(it.lessonDate).isAfter(moment().startOf('day'))).map(item => {
        const location = classLocations?.find(it => it.id === item.scheduledClass.classLocationId);

        // Primary teacher
        const primaryTeacher = item.scheduledClass.scheduledClassStaffs.find(it => it.roleInClass === RoleInClass.PrimaryTeacher && it.scheduledClassId === item.scheduledClassId);
        const primaryTeacherProfile = profiles?.find(it => it.id === primaryTeacher?.staffId);

        // Additional teachers
        const additionalTeachers = item.scheduledClass.scheduledClassStaffs.filter(it => it.roleInClass === RoleInClass.AdditionalTeacher && it.scheduledClassId === item.scheduledClassId);
        const additionalTeacherProfiles = additionalTeachers?.map(additionalTeacher => {
            const profile = profiles?.find(it => it.id === additionalTeacher.staffId);

            return {
                ...(profile ?? {}),

                profile,
            };
        });

        // Cub coordinators
        const cubCoordinators = item.scheduledClass.scheduledClassStaffs.filter(it => it.roleInClass === RoleInClass.Coordinator && it.scheduledClassId === item.scheduledClassId);
        const cubCoordinatorProfiles = cubCoordinators?.map(cubCoordinator => {
            const profile = profiles?.find(it => it.id === cubCoordinator.staffId);

            return {
                ...(profile ?? {}),

                profile,
            };
        });

        // NumberOfLessons for CurrentClassSubStage
        const numberOfLessons = classSubStages?.find(it => it.id === item.scheduledClass.currentClassSubStageId)?.numberOfLessons ?? 0;

        // Return the combined data
        return {
            ...item,
            location,
            primaryTeacherProfile,
            additionalTeacherProfiles,
            cubCoordinatorProfiles,
            numberOfLessons
        } as ChildAttendanceWithLocationAndStaff;
    }), [childAttendances, classLocations, classSubStages, profiles]);

    // Handle navigating to the CourtesyClassFinder
    const bookClass = useCallback((scheduledClassId: string, courtesyClassCreditId: string) => {
        // If we don't have the CourtesyClassCredit scheduledClassId, then return
        if (!scheduledClassId || !courtesyClassCreditId) {
            return;
        }

        // Get the ClassType of the cancelled class
        const classTypeId = scheduledClasses?.find(item => item.id === scheduledClassId)?.classTypeId;

        // Navigate to the courtesyClassFinder passing the childId and the classTypeId
        navigate(`/my/courtesy-class-finder/${model?.id}/${classTypeId}/${courtesyClassCreditId}`);
    }, [model?.id, navigate, scheduledClasses]);

    // Sort the credits by value high -> low
    const items = useMemo(() => {
        let ret = [...(_items ?? [])];

        // We don't want to show credits for cancelled classes to members
        ret = ret.filter(it => !it.name.startsWith("Courtesy credit for cancellation of class"));

        ret.sort((a, b) => b.financialValue - a.financialValue);
        return ret;
    }, [_items]);

    // Render the UI
    //
    return (
        <>
            <AlertOnErrors
                errors={[
                    loadErrors,
                    supportingDataLoadErrors
                ]}
            />

            <ConditionalFragment showIf={!isLoading}>
                <ConditionalFragment showIf={!!items?.length}>
                    <ListGroup style={{ marginBottom: '3rem' }}>
                        {items?.map(item => (
                            <ListGroupItem key={item.id}>
                                <Row>
                                    <Col>
                                        {item.name}
                                    </Col>

                                    <Col xs={12} sm="auto">
                                        <ConditionalFragment showIf={!item.isConsumed}>
                                            <Button color="primary" outline onClick={() => bookClass(item.scheduledClassId, item.id)}>
                                                {t('common.bookClass', 'Book class')}
                                            </Button>
                                        </ConditionalFragment>
                                    </Col>
                                </Row>
                            </ListGroupItem>
                        ))}
                    </ListGroup>
                </ConditionalFragment>

                <HorizontalRule />

                <div className="classes-tab" style={{ marginTop: '3rem' }}>
                    <div className="classes-tab-class-type">
                        <h2 style={{ margin: '0 auto', fontSize: '2rem', fontFamily: 'museosansrounded', fontWeight: '600' }}>My Courtesy Classes</h2>

                        {
                            classTypes?.map(classType => {
                                const myCourtesyClasses = allCourtesyClasses?.filter(it => it.scheduledClass?.classTypeId === classType.id);

                                return (
                                    <div key={classType.id}>
                                        <h3 className="classes-tab-class-type-header">
                                            {classType.name} classes
                                        </h3>

                                        <ConditionalFragment showIf={!myCourtesyClasses?.length}>
                                            <NoResultsFound />
                                        </ConditionalFragment>

                                        <ConditionalFragment showIf={!!myCourtesyClasses?.length}>
                                            <div className="classes-tab-class-type-class-classes">
                                                {
                                                    myCourtesyClasses?.map(item => (
                                                        <ClassesComponent
                                                            model={item as ChildAttendanceWithLocationAndStaff}
                                                            key={item.id}
                                                            classType={classType.name}
                                                            classStages={classStages}
                                                            classSubStages={classSubStages}
                                                            isCourtesyClass={true}
                                                            courtesyClassLessonDate={item.lessonDate}
                                                        />
                                                    ))
                                                }
                                            </div>
                                        </ConditionalFragment>
                                    </div>
                                );
                            })
                        }
                    </div>
                </div>
            </ConditionalFragment>

        </>
    );
};