import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useMemo, useState } from "react";
import { ConditionalFragment } from "react-conditionalfragment";
import { useTranslation } from "react-i18next";
import { LinkContainer } from "react-router-bootstrap";
import { useNavigate } from "react-router-dom";
import { Waypoint } from "react-waypoint";
import { Button, ButtonDropdown, ButtonGroup, CardSubtitle, CardTitle, Col, DropdownItem, DropdownMenu, DropdownToggle, Row } from "reactstrap";
import { useToggleStateArray } from "use-toggle-state";
import { dayOfWeekDisplayName } from "../../api/main/models/constants/DayOfWeek";
import { RoleInClass } from "../../api/main/models/constants/RoleInClass";
import { useScheduledClassesForArrangeCoverViewModel } from "../../api/main/scheduledClasses/viewModels/useScheduledClassesForArrangeCoverViewModel";
import { useCurrentTerm } from "../../api/main/terms/useCurrentTerm";
import { usePreferredListViewMode } from "../../globalState/preferredListViewMode/usePreferredListViewMode";
import { AlertOnErrors } from "../../shared/alertOnErrors";
import { useLocalStorage } from "../../shared/useLocalStorage";
import { useReplaceSearchParamsEffect, useSearchParams } from "../../shared/useURLSearchParams";
import { getScheduledClassSummary } from "../scheduledClass/getScheduledClassSummary";
import { ClassScheduleFilterState } from "../scheduledClass/schedule/ClassSchedule";
import { Banner } from "../shared/banner/Banner";
import { CardsOrTable } from "../shared/cardsOrTable/CardsOrTable";
import { CardsOrTableToggle } from "../shared/cardsOrTable/CardsOrTableToggle";
import { LoadingIndicator } from "../shared/loadingIndicator/LoadingIndicator";
import { MainContainer } from "../shared/mainContainer/MainContainer";
import { NoResultsFound } from "../shared/noResultsFound/NoResultsFound";
import { SearchInput } from "../shared/searchInput/SearchInput";
import { StickyToolbar } from "../shared/stickyToolbar/StickyToolbar";
import { TermSelector } from "../shared/termSelector/TermSelector";

/**
 * List of ScheduledClasses used to allow Admins to ArrangeCover for a ScheduledClass
 */
export const ArrangeCoverList = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    // Load the current term
    const {
        data: {
            model: currentTerm
        }, isLoading: isLoadingCurrentTerm, errors: currentTermLoadErrors
    } = useCurrentTerm();

    // Load the data
    const {
        data: {
            items: _allItems,
            classTypes,
            classLocations,
            classStages,
            classSubStages,
            profiles
        }, isLoading: isLoadingData, errors: loadErrors, fetchMore, hasMore
    } = useScheduledClassesForArrangeCoverViewModel({ pageSize: undefined });

    const isLoading = isLoadingData || isLoadingCurrentTerm;

    // Combine all the loaded data so each item includes its related details.
    const allItems = useMemo(() => _allItems?.map(item => {
        const classType = classTypes?.find(it => it.id === item.classTypeId);
        const classLocation = classLocations?.find(it => it.id === item.classLocationId);
        const currentClassStage = classStages?.find(it => it.id === item.currentClassStageId);
        const currentClassSubStage = classSubStages?.find(it => it.id === item.currentClassSubStageId);

        // Helpers - Used to enable the populating of the Profiles below
        const primaryTeacher = item.scheduledClassStaffs.find(item => item.roleInClass === RoleInClass.PrimaryTeacher)?.staffId;
        const additionalTeachers = item.scheduledClassStaffs.filter(item => item.roleInClass === RoleInClass.AdditionalTeacher).map(it => it.staffId);
        const cubCoordinators = item.scheduledClassStaffs.filter(item => item.roleInClass === RoleInClass.Coordinator).map(it => it.staffId);

        // Staff Profiles
        const primaryTeacherProfile = profiles?.find(it => it.id === primaryTeacher);
        const additionalTeachersProfiles = additionalTeachers.map(teacher => profiles?.find(it => it.id === teacher));
        const cubCoordinatorsProfiles = cubCoordinators.map(cubCoordinator => profiles?.find(it => it.id === cubCoordinator));

        return {
            ...item,

            classType,
            classLocation,
            currentClassStage,
            currentClassSubStage,
            primaryTeacherProfile,
            additionalTeachersProfiles,
            cubCoordinatorsProfiles
        };
    }), [_allItems, classTypes, classLocations, classStages, classSubStages, profiles]);

    const [isMenuOpen, toggleMenuOpen] = useToggleStateArray();

    // Use the preferred view mode for lists.
    const [viewMode] = usePreferredListViewMode();

    // Search.
    const { search: searchParam } = useSearchParams();
    const [search, setSearch] = useState<string>(searchParam ?? '');

    // Keep the URL up to date with the search text.
    useReplaceSearchParamsEffect({ search: search });

    // Allow filtering (persisted to local storage between sessions).
    const [filterState, setFilterState] = useLocalStorage<ClassScheduleFilterState>('classSchedule.filterState', {});

    // Filter by the search.
    const items = useMemo(() => {
        let ret = (allItems ?? []);

        if (filterState.termId) {
            ret = ret?.filter(item => item.termId === filterState.termId);
        }

        let lowerSearch = search.toLocaleLowerCase();
        if (lowerSearch) {
            // Just filtering by all string values that arent ids to start with. Most likely wont need them all for search.
            ret = ret.filter(item =>
                (item.classType?.name ?? '').toLocaleLowerCase().indexOf(lowerSearch) >= 0
                || (item.classLocation?.name ?? '').toLocaleLowerCase().indexOf(lowerSearch) >= 0
                || (item.currentClassStage?.name ?? '').toLocaleLowerCase().indexOf(lowerSearch) >= 0
                || (item.currentClassSubStage?.name ?? '').toLocaleLowerCase().indexOf(lowerSearch) >= 0
                || dayOfWeekDisplayName(item.dayOfWeek, t).toLocaleLowerCase().indexOf(lowerSearch) >= 0
                || t('arrangeCoverList.startTime.formmated', '{{hours, 00}}:{{minutes, 00}}', { hours: item.startTimeHours, minutes: item.startTimeMinutes }).toLocaleLowerCase().indexOf(lowerSearch) >= 0
                || t('arrangeCoverList.endTime.formmated', '{{hours, 00}}:{{minutes, 00}}', { hours: item.endTimeHours, minutes: item.endTimeMinutes }).toLocaleLowerCase().indexOf(lowerSearch) >= 0
                || (item.primaryTeacherProfile?.firstName ?? '').toLocaleLowerCase().indexOf(lowerSearch) >= 0
                || (item.primaryTeacherProfile?.lastName ?? '').toLocaleLowerCase().indexOf(lowerSearch) >= 0
                || item.additionalTeachersProfiles?.find(profile =>
                    (profile?.firstName ?? '').toLocaleLowerCase().indexOf(lowerSearch) >= 0
                    || (profile?.lastName ?? '').toLocaleLowerCase().indexOf(lowerSearch) >= 0
                || item.cubCoordinatorsProfiles?.find(profile =>
                    (profile?.firstName ?? '').toLocaleLowerCase().indexOf(lowerSearch) >= 0
                    || (profile?.lastName ?? '').toLocaleLowerCase().indexOf(lowerSearch) >= 0
            )));
        }

        return ret;

    }, [allItems, search, t, filterState]);

    // Set the default filter state to the current term.
    useEffect(() => {
        if (!filterState.termId) {
            setFilterState({ termId: currentTerm?.id });
        }
    }, [filterState, currentTerm?.id, setFilterState]);

    // Render the UI
    //
    return (
        <>
            <Banner fluid>
                <StickyToolbar>
                    <Row>
                        <Col>
                            <h1>
                                {t('arrangeCoverList.title', 'Arrange cover')}
                            </h1>
                        </Col>
                        <ConditionalFragment showIf={isLoading}>
                            <Col xs="auto">
                                <LoadingIndicator size="sm" />
                            </Col>
                        </ConditionalFragment>
                        <Col xs="auto">
                            <CardsOrTableToggle />
                        </Col>
                    </Row>
                    <Row>
                        <Col xs="auto">
                            <TermSelector selectedTermId={filterState.termId} setFilterState={({ termId: value }) => setFilterState({ termId: value })} restrictToTermsWithClasses={true} />
                        </Col>
                        <Col>
                            <SearchInput value={search} onChange={e => setSearch(e.currentTarget.value)} />
                        </Col>
                    </Row>
                </StickyToolbar>
            </Banner>

            <MainContainer fluid>
                <AlertOnErrors errors={[
                    loadErrors,
                    currentTermLoadErrors
                ]} />

                <CardsOrTable
                    viewMode={viewMode}
                    items={items}
                    tableHeadings={[
                        null, /* Handles the card specific function for which we don't want a table column. */
                        t('arrangeCoverList.dayOfWeek.heading', 'Day'),
                        t('arrangeCoverList.startTime.heading', 'Start time'),
                        t('arrangeCoverList.endTime.heading', 'End time'),
                        t('arrangeCoverList.classLocationId.heading', 'Location'),
                        t('arrangeCoverList.classTypeId.heading', 'Activity'),
                        t('arrangeCoverList.currentClassStageId.heading', 'Current class type'),
                        t('arrangeCoverList.currentClassSubStageId.heading', 'Current stage'),
                        t('arrangeCoverList.currentLessonNumber.heading', 'Current lesson number'),
                        t('arrangeCoverList.primaryTeacher.heading', 'Primary teacher'),
                        t('arrangeCoverList.additionalTeachers.heading', 'Additional teachers'),
                        t('arrangeCoverList.cubCoordinators.heading', 'Cub coordinators')
                    ]}
                    columns={[
                        // We handle the card layout as a specific column ignored from the table.
                        (item, view) => {
                            // This column doesn't show in the table.
                            if (view !== 'cards') {
                                return null;
                            }

                            return (
                                <>
                                    <CardTitle tag="h5">{getScheduledClassSummary(item, { classLocation: item.classLocation, })}</CardTitle>
                                    <CardSubtitle>
                                        {t(
                                            'arrangeCoverList.subtitle',
                                            '{{classTypeName}} / {{currentClassStageName}} / {{currentClassSubStageName}}',
                                            {
                                                classTypeName: item.classType?.name,
                                                currentClassStageName: item.currentClassStage?.name,
                                                currentClassSubStageName: item.currentClassSubStage?.name,
                                            }
                                        )}
                                    </CardSubtitle>
                                    <div>
                                        {t(
                                            'arrangeCoverList.timespan',
                                            '{{startTimeHours, 00}}:{{startTimeMinutes, 00}} to {{endTimeHours, 00}}:{{endTimeMinutes, 00}}',
                                            {
                                                startTimeHours: item.startTimeHours,
                                                startTimeMinutes: item.startTimeMinutes,
                                                endTimeHours: item.endTimeHours,
                                                endTimeMinutes: item.endTimeMinutes,
                                            }
                                        )}
                                    </div>
                                </>
                            );
                        },

                        // The remaining columns are for table mode only.

                        // Day
                        (item, view) => {
                            if (view !== 'table') {
                                return null;
                            }

                            return dayOfWeekDisplayName(item.dayOfWeek, t);
                        },

                        // Start time
                        (item, view) => {
                            if (view !== 'table') {
                                return null;
                            }

                            return t('arrangeCoverList.startTime.formmated', '{{hours, 00}}:{{minutes, 00}}', { hours: item.startTimeHours, minutes: item.startTimeMinutes });
                        },

                        // End time
                        (item, view) => {
                            if (view !== 'table') {
                                return null;
                            }

                            return t('arrangeCoverList.endTime.formmated', '{{hours, 00}}:{{minutes, 00}}', { hours: item.endTimeHours, minutes: item.endTimeMinutes });
                        },

                        // Location
                        (item, view) => {
                            if (view !== 'table') {
                                return null;
                            }

                            return item.classLocation?.name;
                        },

                        // Current stage
                        (item, view) => {
                            if (view !== 'table') {
                                return null;
                            }

                            return item.classType?.name;
                        },

                        // Current stage
                        (item, view) => {
                            if (view !== 'table') {
                                return null;
                            }

                            return item.currentClassStage?.name ?? '';
                        },


                        // Current stage -> formerly Current sub stage
                        (item, view) => {
                            if (view !== 'table') {
                                return null;
                            }

                            return item.currentClassSubStage?.name ?? '';
                        },

                        // Current lesson number
                        (item, view) => {
                            if (view !== 'table') {
                                return null;
                            }

                            return item.currentLessonNumber;
                        },

                        // Primary teacher
                        (item, view) => {
                            if (view !== 'table') {
                                return null;
                            }

                            return t('common.fullName', '{{firstName}} {{lastName}}', { firstName: item.primaryTeacherProfile?.firstName, lastName: item.primaryTeacherProfile?.lastName });
                        },

                        // Additional teachers
                        (item, view) => {
                            if (view !== 'table') {
                                return null;
                            }

                            return item.additionalTeachersProfiles.map(teacher => <p key={teacher?.id}>{t('common.fullName', '{{firstName}} {{lastName}}', { firstName: teacher?.firstName, lastName: teacher?.lastName })}</p>);
                        },

                        // Cub coordinators
                        (item, view) => {
                            if (view !== 'table') {
                                return null;
                            }

                            return item.cubCoordinatorsProfiles.map(cubCoordinator => <p key={cubCoordinator?.id}>{t('common.fullName', '{{firstName}} {{lastName}}', { firstName: cubCoordinator?.firstName, lastName: cubCoordinator?.lastName })}</p>);
                        }
                    ]}

                    buttons={(item) => (
                        <ButtonGroup>
                            <LinkContainer to={`edit/${item.id}`}>
                                <Button color="primary">
                                    <FontAwesomeIcon icon="edit" />
                                    <> {t('common.edit', 'Edit')}</>
                                </Button>
                            </LinkContainer>
                            <ButtonDropdown isOpen={isMenuOpen(item.id)} toggle={() => toggleMenuOpen(item.id)}>
                                <DropdownToggle color="primary" caret>
                                    <span className="visually-hidden">{t('common.menuDropdown', 'More')}</span>
                                </DropdownToggle>
                                <DropdownMenu end>
                                    <LinkContainer to={`delete/${item.id}`}>
                                        <DropdownItem className="text-danger">
                                            <FontAwesomeIcon icon="trash" />
                                            <> {t('common.delete', 'Delete')}</>
                                        </DropdownItem>
                                    </LinkContainer>
                                </DropdownMenu>
                            </ButtonDropdown>
                        </ButtonGroup>
                    )}
                    onItemClick={item => navigate(`edit/${item.id}`)}
                />

                <ConditionalFragment showIf={isLoading && !items?.length}>
                    <LoadingIndicator fullWidth />
                </ConditionalFragment>
                <ConditionalFragment showIf={!isLoading && !items?.length}>
                    <NoResultsFound search={search} />
                </ConditionalFragment>
                <ConditionalFragment showIf={!isLoading && hasMore()}>
                    <Waypoint key={items?.length ?? 0} onEnter={fetchMore} />
                    <LoadingIndicator fullWidth />
                </ConditionalFragment>
            </MainContainer>
        </>
    );
};