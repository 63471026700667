import { gql, useLazyQuery } from "@apollo/client";
import React from "react";
import { mainApiConfig } from "../../../../configure/mainApiConfig";
import { AsyncLoadOptions, AsyncLoadResult } from "../../../../shared/abstractStore";
import { EditGiftVoucherSupportingDataViewModelQuery, EditGiftVoucherSupportingDataViewModelQueryVariables } from "../../generated/graphql";

/**
 * Load a supporting data for EditGiftVoucher from the api
 */
export function useEditGiftVoucherSupportingDataViewModel(options: AsyncLoadOptions = {}): AsyncLoadResult<EditGiftVoucherSupportingDataViewModelQuery> {
    // Query the data we need from the api
    const [load, { data, loading, error, refetch }] = useLazyQuery<EditGiftVoucherSupportingDataViewModelQuery, EditGiftVoucherSupportingDataViewModelQueryVariables>(
        gql`
        query editGiftVoucherSupportingDataViewModelQuery {
            giftVouchers(includeArchived: false) {
                id
                serialNumber
            }
        }
        `,
        {
            fetchPolicy: options.fetchPolicy ?? mainApiConfig.defaultFetchPolicy,
            pollInterval: options.pollInterval,
            onCompleted: (data) => {
                if (options.onCompleted) {
                    options.onCompleted(data);
                }
            }
        }
    );

    // Run the query (unless we are being lazy).
    React.useEffect(() => {
        if (!options.lazy) {
            load();
        }
    }, [options.lazy, load]);

    // Return the results.
    const refresh = React.useCallback(async () => { !refetch ? await load() : await refetch(); }, [refetch, load]);
    return React.useMemo(() => ({
        data: data ?? ({} as any),
        refresh: refresh,
        isLoading: loading,
        errors: error
    }), [data, refresh, loading, error]);
};