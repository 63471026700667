import * as React from 'react';
import { useLazyQuery } from "@apollo/client";
import { gql } from 'graphql-tag';
import { Guid } from "guid-string";
import { mainApiConfig } from "../../../configure/mainApiConfig";
import { ScheduledClassChildQuery, ScheduledClassChildQueryVariables } from '../generated/graphql';
import { scheduledClassChildFieldsFragment } from "../models/ScheduledClassChild";
import { AsyncLoadOptions, AsyncLoadResult } from '../../../shared/abstractStore';

/**
 * Load a ScheduledClassChild from the api.
 */
export function useScheduledClassChild(id: string | undefined | null, options: AsyncLoadOptions = {}): AsyncLoadResult<ScheduledClassChildQuery> {
    const [load, { data, loading, error, refetch }] = useLazyQuery<ScheduledClassChildQuery, ScheduledClassChildQueryVariables>(
        gql`
        query scheduledClassChildQuery ($id: ID!) {
            model: scheduledClassChild(id: $id) {
                ...scheduledClassChildFields
            }
        }

        ${scheduledClassChildFieldsFragment}
        `,
        {
            variables: {
                id: id ?? Guid.empty,
            },
            fetchPolicy: options.fetchPolicy ?? mainApiConfig.defaultFetchPolicy,
            pollInterval: options.pollInterval,
            onCompleted: (data) => {
                if (options.onCompleted) {
                    options.onCompleted(data);
                }
            }
        }
    );

    // Run the query (unless we are being lazy).
    React.useEffect(() => {
        if (!options.lazy) {
            load();
        }
    }, [options.lazy, load])

    // Return the results.
    const refresh = React.useCallback(async () => { !refetch ? await load() : await refetch(); }, [refetch, load]);
    return React.useMemo(() => ({
        data: data ?? ({} as any),
        refresh: refresh,
        isLoading: loading,
        errors: error
    }), [data, refresh, loading, error]);
}