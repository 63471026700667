import { IdentityRoles } from "../../configure/security/IdentityRoles";
import { RouteEntry } from "../../shared/routes";
import { Dashboard } from "./Dashboard";

/**
 * Routes that should be added to configuration/routeConfig.ts to provide access to these components via routing.
 */
export const dashboardRoutes: Array<RouteEntry> = [
    { path: '/administration/dashboard', element: <Dashboard />, authorize: true, requireRole: IdentityRoles.Administration }
];
