import { useTranslation } from "react-i18next";
import { MainContainer } from "../../../shared/mainContainer/MainContainer";
import { PaymentsTab as ChildPaymentsTab } from "../../../children/edit/paymentsTab/PaymentsTab";
import { Child } from "../../../../api/main/models/Child";

export interface PaymentsTabProps {
    children: Array<Child> | undefined | null,
}

export const PaymentsTab = (props: PaymentsTabProps) => {
    const {
        children,
    } = props;

    const { t } = useTranslation();

    // Render the UI
    //
    return (
        <>
            <MainContainer fluid>
                <div>
                    {
                        children?.map(child => (
                            <div className="mb-4" key={child.id}>
                                <h5>{t('common.fullName', '{{firstName}} {{lastName}}', { firstName: child.firstName, lastName: child.lastName, })}</h5>

                                <ChildPaymentsTab
                                    model={child}
                                    startWithHistoryCollapsed={true}
                                />
                            </div>
                        ))
                    }
                </div>
            </MainContainer>
        </>
    );
};