import gql from 'graphql-tag';
import { Guid } from 'guid-string';
import { TeacherClassTypeFieldsFragment } from '../generated/graphql';

export const teacherClassTypeFieldsFragment = gql`
    fragment teacherClassTypeFields on TeacherClassType {
        id
        staffId
        classTypeId
        archived
    }
`;

export type TeacherClassType = Omit<TeacherClassTypeFieldsFragment, '__typename'>;

/**
 * Default values.
 */
export const teacherClassTypeDefaultValues = (): Partial<TeacherClassType> => ({
    id: Guid.newGuid(),
    staffId: undefined,
    classTypeId: undefined,
    archived: false,
});