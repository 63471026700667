import { ScheduledClassWithClassLocation } from "../../membersChildren/MembersChildrenList";

/**
 * Utility function that takes an Array of ScheduledClassWithClassLocation and returns the nextClass for that to run based on the current Day and Hour
 */
export const getNextClassForChild = (classes: Array<ScheduledClassWithClassLocation> | undefined | null) => {
    const now = new Date();
    const currentDay = now.getDay();
    const currentHour = now.getHours();
    let nextClass;

    // We want to sort the classes by the DayOfTheWeek and then by the StartTime so we end up with an array of classes in the order they run
    const sortedClasses = classes?.sort((a, b) => {
        if (a.dayOfWeek === b.dayOfWeek) {
            if (a.startTimeHours === b.startTimeHours) {
                return 0;
            } else if (a.startTimeHours < b.startTimeHours) {
                return -1;
            } else {
                return 1;
            }
        } else if (a.dayOfWeek < b.dayOfWeek) {
            return -1;
        } else {
            return 1;
        }
    }) ?? [];

    // We can then find the next class to run, if the class we are iterating over has a dayOfTheWeek that is higher than the current day, then this will be our next class.
    // Or if the dayOfTheWeek is the same as today, and the startTime is after the currentHour then we have found the next class.
    for (const scheduledClass of sortedClasses) {
        if (scheduledClass.dayOfWeek > currentDay || (scheduledClass.dayOfWeek === currentDay && scheduledClass.startTimeHours >= currentHour)) {
            nextClass = scheduledClass;
            break;
        }
    }

    // If the above loop doesn't find the class, then we will just default to the first class of the week for the member
    if (!nextClass) {
        nextClass = sortedClasses[0];
    }

    // Return the next class
    return nextClass;
};