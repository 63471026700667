import * as React from 'react';
import { useSaveInStore, SaveInStoreOptions } from '../../../shared/useSaveInStore';
import { useUpdateStaffQualificationCheckMutation } from './useUpdateStaffQualificationCheckMutation';
import { StaffQualificationCheck } from '../models/StaffQualificationCheck';
import { useCreateStaffQualificationCheckMutation } from './useCreateStaffQualificationCheckMutation';
import { StaffQualificationCheckCreateInput, StaffQualificationCheckUpdateInput } from '../generated/graphql';

/**
 * Get a callback to save a StaffQualificationCheck in the api using either a create or update.
 */
export function useSaveStaffQualificationCheckMutation(options: SaveInStoreOptions<StaffQualificationCheck, string> = {}) {
    const [_create, createStatus] = useCreateStaffQualificationCheckMutation();
    const create = React.useCallback((model: Partial<StaffQualificationCheck>) => _create(model as StaffQualificationCheckCreateInput), [_create]);

    const [_update, updateStatus] = useUpdateStaffQualificationCheckMutation();
    const update = React.useCallback((id: string, changes: Partial<StaffQualificationCheck>) => _update(id, changes as StaffQualificationCheckUpdateInput), [_update]);

    return useSaveInStore(
        [create, createStatus],
        [update, updateStatus],
        options
    );
}
