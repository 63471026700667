import { NavItem } from 'reactstrap';
import { AuthorizeContainer } from '../../../shared/authorizeRoute';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IdentityRoles } from '../../../configure/security/IdentityRoles';
import { NavLinkTrackActive } from '../../shared/navLinkTrackActive/NavLinkTrackActive';

/**
 * Primary navigation menu for the app. 
 */
export const HeaderNavigation = () => {
    const { t } = useTranslation();
    
    return (
        <>
            <AuthorizeContainer>
                <NavItem>
                    <NavLinkTrackActive to="/my">
                        {/*<FontAwesomeIcon icon="house-user" className="nav-icon" />*/}
                        <span data-route-group="member">{t('headerNavigation.memberArea.name', 'My Happy Hub')}</span>
                    </NavLinkTrackActive>
                </NavItem>
            </AuthorizeContainer>

            <AuthorizeContainer requireRole={IdentityRoles.Staff}>
                <NavItem>
                        <NavLinkTrackActive to="/staff">
                        <FontAwesomeIcon icon="address-card" className="nav-icon" />
                        <span>{t('headerNavigation.staff.name', 'Staff')}</span>
                    </NavLinkTrackActive>
                </NavItem>
            </AuthorizeContainer>

            <AuthorizeContainer requireRole={IdentityRoles.Administration}>
                <NavItem>
                    <NavLinkTrackActive to="/administration">
                        <FontAwesomeIcon icon="building" className="nav-icon" />
                        <span>{t('headerNavigation.administration.name', 'Office')}</span>
                    </NavLinkTrackActive>
                </NavItem>
            </AuthorizeContainer>
        </>
    );
};
