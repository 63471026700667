import { IdentityRoles } from "../../configure/security/IdentityRoles";
import { RouteEntry } from "../../shared/routes";
import { DeleteNoticeBoardItem } from "./DeleteNoticeBoardItem";
import { CreateNoticeBoardItem, EditNoticeBoardItem } from "./EditNoticeBoardItem";
import { NoticeBoardItems } from "./NoticeBoardItemsList";


/**
 * Routes that should be added to configuration/routeConfig.ts to provide access to these components via routing.
 */
export const noticeBoardItemsRoutes: Array<RouteEntry> = [
    { path: '/administration/class-management/notice-board/add', element: <CreateNoticeBoardItem />, authorize: true, requireRole: IdentityRoles.Administration, },
    { path: '/administration/class-management/notice-board/edit/:id', element: <EditNoticeBoardItem />, authorize: true, requireRole: IdentityRoles.Administration, },
    { path: '/administration/class-management/notice-board/delete/:id', element: <DeleteNoticeBoardItem />, authorize: true, requireRole: IdentityRoles.Administration, },
    { path: '/administration/class-management/notice-board', element: <NoticeBoardItems />, authorize: true, requireRole: IdentityRoles.Administration, },
];
