import gql from 'graphql-tag';
import { Guid } from 'guid-string';
import { PaymentDiscountFieldsFragment } from '../generated/graphql';

export const paymentDiscountFieldsFragment = gql`
    fragment paymentDiscountFields on PaymentDiscount {
        id
        paymentId
        childDiscountId
        generalDiscountId
        discountAmount
        archived
    }
`;

export type PaymentDiscount = Omit<PaymentDiscountFieldsFragment, '__typename'>;

/**
 * Default values.
 */
export const paymentDiscountDefaultValues = (): Partial<PaymentDiscount> => ({
    id: Guid.newGuid(),
    paymentId: undefined,
    childDiscountId: null,
    generalDiscountId: null,
    discountAmount: 0,
    archived: false,
});