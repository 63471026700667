import { ValidationErrors } from 'pojo-validator';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, FormGroup, Label, ListGroup } from 'reactstrap';
import { ClassLocationRequirementCheck, classLocationRequirementCheckDefaultValues } from '../../../../api/main/models/ClassLocationRequirementCheck';
import { ModelArrayChanges } from '../../../../shared/useChanges';
import { RequirementCheckComponent } from './RequirementCheckComponent';

export interface RequirementChecksTabProps {
    model: ClassLocationRequirementCheck | null | undefined,

    requirementChecksManager: ModelArrayChanges<ClassLocationRequirementCheck, string>,
    validateRequirementCheck: (model: ClassLocationRequirementCheck) => boolean,
    requirementCheckValidationErrors: (id: string) => ValidationErrors
}

/**
 * RequirementChecksTab for EditClassLocation
 */
export const RequirementChecksTab = (props: RequirementChecksTabProps) => {
    const { model, requirementChecksManager, validateRequirementCheck, requirementCheckValidationErrors } = props;
    const { t } = useTranslation();

    // Add a new RequirementCheck and link it to the parent model
    const addRequirementCheck = useCallback(() => {
        requirementChecksManager.addFor({ ...classLocationRequirementCheckDefaultValues(), classLocationId: model?.id });
    }, [requirementChecksManager, model]);

    // Render the UI
    //
    return (
        <>
            <FormGroup>
                <Label htmlFor="requirementChecks">
                    {t('editClassLocation.requirementChecksTab.headingLabel', 'Requirements that need to be checked regularly to ensure we can safely use the location:')}
                </Label>

                <ListGroup id="requirementChecks"> 
                    {
                        requirementChecksManager.model.map(item => (
                            <RequirementCheckComponent key={item.id}
                                model={item}
                                change={changes => requirementChecksManager.changeFor(item.id, changes)}
                                remove={() => requirementChecksManager.removeFor(item.id)}
                                validate={() => validateRequirementCheck(item)}
                                validationErrors={requirementCheckValidationErrors(item.id)}
                            />
                        ))
                    }
                </ListGroup>

                <div className="mt-2">
                    <Button color="primary" outline onClick={() => addRequirementCheck()}>
                        {t('editClassLocation.requirementChecksTab.addRequirementCheck', 'Add requirement')}
                    </Button>
                </div>
            </FormGroup>
        </>
    );
}