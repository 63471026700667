import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { ConditionalFragment } from "react-conditionalfragment";
import { useTranslation } from "react-i18next";
import { Alert, Button, ButtonGroup, Col, Row } from "reactstrap";
import { useToggleState } from "use-toggle-state";
import { useCurrentUserProfile } from "../../api/main/profiles/useCurrentUserProfile";
import { AlertOnErrors } from "../../shared/alertOnErrors";
import { Banner } from "../shared/banner/Banner";
import { LoadingIndicator } from "../shared/loadingIndicator/LoadingIndicator";
import { MainContainer } from "../shared/mainContainer/MainContainer";
import { StickyToolbar } from "../shared/stickyToolbar/StickyToolbar";
import { TwoValueSwitch } from "../shared/twoValueSwitch/TwoValueSwitch";

/**
 * Component that allows a User to export various datasets to Excel. Supports Marketing and Non-marketing uses.
 */
export const ExportToExcel = () => {
    const { t } = useTranslation();

    // Load the currentUserProfile - used to customize the download filename to the users name
    const {
        data: {
            model
        }, isLoading: isLoadingCurrentUser, errors: currentUserLoadErrors
    } = useCurrentUserProfile();

    const isLoading = isLoadingCurrentUser;

    // Toggle to know that the user has confirmed they want to continue.
    const [iveReadChecked, toggleIveRead] = useToggleState();

    // Handle the ExportChoice
    const [exportChoice, setExportChoice] = useState<'members' | 'children'>('members');

    // Render the UI
    //
    return (
        <>
            <Banner>
                <StickyToolbar>
                    <Row>
                        <Col>
                            <h1>
                                {t('exportToExcel.title', 'Export to Excel')}
                            </h1>
                        </Col>
                        <ConditionalFragment showIf={isLoading}>
                            <Col xs="auto">
                                <LoadingIndicator size="sm" />
                            </Col>
                        </ConditionalFragment>
                        <Col xs="auto">
                            <ButtonGroup>
                                <Button color="primary" outline={exportChoice !== 'members'} onClick={() => setExportChoice('members')}>
                                    {t('exportToExcel.exportChoice.mamaAndPapaBears', 'Mama and Papa bears')}
                                </Button>
                                <Button color="primary" outline={exportChoice !== 'children'} onClick={() => setExportChoice('children')}>
                                    {t('exportToExcel.exportChoice.cubs', 'Cubs')}
                                </Button>
                            </ButtonGroup>
                        </Col>
                    </Row>
                </StickyToolbar>
            </Banner>

            <MainContainer>
                <AlertOnErrors
                    errors={[
                        currentUserLoadErrors
                    ]}
                />

                <h5>{t('exportToExcel.before.title', 'Before you start')}</h5>
                <ul>
                    <li>{t('exportToExcel.before.p1', 'This page allows the exporting off Cubs, Mama and Papa Bears, and Class information.')}</li>
                    <li>{t('exportToExcel.before.p2', 'You can export data for marketing and non-marketing purposes.')}</li>
                    <li>{t('exportToExcel.before.p3', 'There may be a slight delay when exporting this data, as there is potentially a lot of data to generate.')}</li>
                    <li style={{ textDecoration: 'underline', color: 'red' }}>{t('exportToExcel.before.p4', 'It is important to note that any data downloaded for non-marketing uses, must not be used for marketing purposes.')}</li>
                </ul>

                <ConditionalFragment showIf={exportChoice === 'members'}>
                    <h5>{t('exportToExcel.whatsIncluded.mamaAndPapaBears.title', 'Mama and Papa Bears what\'s included:')}</h5>
                    <ul>
                        <li>{t('exportToExcel.whatsIncluded.mamaAndPapaBears.p1', 'Full name')}</li>
                        <li>{t('exportToExcel.whatsIncluded.mamaAndPapaBears.p2', 'Primary / secondary emails')}</li>
                        <li>{t('exportToExcel.whatsIncluded.mamaAndPapaBears.p3', 'Primary / secondary phones')}</li>
                        <li>{t('exportToExcel.whatsIncluded.mamaAndPapaBears.p4', 'Full address')}</li>
                        <li>{t('exportToExcel.whatsIncluded.mamaAndPapaBears.p5', 'Gender')}</li>
                        <li>{t('exportToExcel.whatsIncluded.mamaAndPapaBears.p6', 'Marketing preferences')}</li>
                        <li>{t('exportToExcel.whatsIncluded.mamaAndPapaBears.p7', 'Marketing preferences updated date')}</li>
                    </ul>
                </ConditionalFragment>

                <ConditionalFragment showIf={exportChoice === 'children'}>
                    <h5>{t('exportToExcel.whatsIncluded.cubs.title', 'Cubs what\'s included:')}</h5>
                    <ul>
                        <li>{t('exportToExcel.whatsIncluded.cubs.p1', 'Mama and Papa bear full name')}</li>
                        <li>{t('exportToExcel.whatsIncluded.cubs.p2', 'Mama and Papa bear marketing preferences')}</li>
                        <li>{t('exportToExcel.whatsIncluded.cubs.p3', 'Mama and Papa bear marketing preferences updated date')}</li>
                        <li>{t('exportToExcel.whatsIncluded.cubs.p4', 'Cubs full name')}</li>
                        <li>{t('exportToExcel.whatsIncluded.cubs.p5', 'Cubs gender')}</li>
                        <li>{t('exportToExcel.whatsIncluded.cubs.p6', 'Cubs enrolled Swim classes')}</li>
                        <li>{t('exportToExcel.whatsIncluded.cubs.p7', 'Cubs enrolled Music classes')}</li>
                        <li>{t('exportToExcel.whatsIncluded.cubs.p8', 'Cubs waiting list Music classes')}</li>
                        <li>{t('exportToExcel.whatsIncluded.cubs.p9', 'Cubs waiting list Music classes')}</li>

                    </ul>
                </ConditionalFragment>

                <Alert color="info">
                    <TwoValueSwitch
                        leftLabel=""
                        rightLabel={t('exportToExcel.iveReadChecked.rightText', 'I confirm I have read and understood the above information and understand how this data can be used.')}
                        checked={iveReadChecked}
                        onChange={checked => toggleIveRead(checked)}
                    />
                </Alert>

                <Row style={{ textAlign: 'center' }}>
                    <h5>{t('exportToExcel.exportMembers.header', 'Export')}</h5>
                    <Col>
                        <Button color="link" disabled={!iveReadChecked}>
                            <a className="btn btn-outline-primary" href={`/api/export${exportChoice}/Export/${model?.id}?format=${encodeURIComponent('xlsx')}&marketing=${encodeURIComponent('true')}`} download>
                                <FontAwesomeIcon icon="download" />
                                <> </>
                                {t('exportToExcel.modal.marketingUse', 'Marketing use')}
                            </a>
                        </Button>

                        <Button color="link" disabled={!iveReadChecked}>
                            <a className="btn btn-outline-primary" href={`/api/export${exportChoice}/Export/${model?.id}?format=${encodeURIComponent('xlsx')}`} download>
                                <FontAwesomeIcon icon="download" />
                                <> </>
                                {t('exportToExcel.modal.nonMarketingUse', 'Non marketing use')}
                            </a>
                        </Button>
                    </Col>
                </Row>
            </MainContainer>
        </>
    );
};