import { useLazyQuery } from "@apollo/client";
import { AsyncLoadOptions, AsyncLoadResult } from "../../../../shared/abstractStore";
import gql from "graphql-tag";
import { EditChildViewModelQuery, EditChildViewModelQueryVariables } from '../../generated/graphql';
import { mainApiConfig } from "../../../../configure/mainApiConfig";
import { Guid } from "guid-string";
import React from "react";
import { childFieldsFragment } from "../../models/Child";
import { emergencyContactFieldsFragment } from "../../models/EmergencyContact";
import { staffNoteFieldsFragment } from "../../models/StaffNote";
import { childDiscountFieldsFragment } from "../../models/ChildDiscount";
import { courtesyClassCreditFieldsFragment } from "../../models/CourtesyClassCredit";
import { scheduledClassChildEventFieldsFragment } from "../../models/ScheduledClassChildEvent";

/**
 * Get all model data required for the EditChild component
 * @param id
 * @param options
 */
export function useEditChildViewModel(id: string | undefined | null, options: AsyncLoadOptions = {}): AsyncLoadResult<EditChildViewModelQuery> {
    // Query the data we need from the api
    const [load, { data, loading, error, refetch }] = useLazyQuery<EditChildViewModelQuery, EditChildViewModelQueryVariables>(
        gql`
        query editChildViewModelQuery ($id: ID!) {
            model: child (id: $id) {
                ...childFields
            }
            
            courtesyClassCredits (childId: $id){
                ...courtesyClassCreditFields
            }

            emergencyContacts (targetId: $id) {
                ...emergencyContactFields
            }

            staffNotes (targetId: $id) {
                ...staffNoteFields
            }

            childDiscounts (childId: $id) {
                ...childDiscountFields
            }

            scheduledClassChildEvents (childId: $id) {
                ...scheduledClassChildEventFields
            }
        }

        ${childFieldsFragment}
        ${courtesyClassCreditFieldsFragment}
        ${emergencyContactFieldsFragment}
        ${staffNoteFieldsFragment}
        ${childDiscountFieldsFragment}
        ${scheduledClassChildEventFieldsFragment}
        `,
        {
            variables: {
                id: id ?? Guid.empty,
            },
            fetchPolicy: options.fetchPolicy ?? mainApiConfig.defaultFetchPolicy,
            pollInterval: options.pollInterval,
            onCompleted: (data) => {
                if (options.onCompleted) {
                    options.onCompleted(data);
                }
            }
        }
    );

    // Run the query (unless we are being lazy).
    React.useEffect(() => {
        if (!options.lazy) {
            load();
        }
    }, [options.lazy, load]);

    // Return the results.
    const refresh = React.useCallback(async () => { !refetch ? await load() : await refetch(); }, [refetch, load]);
    return React.useMemo(() => ({
        data: data ?? ({} as any),
        refresh: refresh,
        isLoading: loading,
        errors: error,
    }), [data, refresh, loading, error]);
}
