import { ConditionalFragment } from "react-conditionalfragment";
import { useTranslation } from "react-i18next";
import { Row } from "reactstrap";
import { useBlobReference } from "../../../../api/main/blobReferences/useBlobReference";
import { Profile } from "../../../../api/main/models/Profile";
import { useStaff } from "../../../../api/main/staffs/useStaff";
import { AlertOnErrors } from "../../../../shared/alertOnErrors";
import { HtmlDisplay } from "../../../../shared/htmlEditor";
import { LoadingIndicator } from "../../../shared/loadingIndicator/LoadingIndicator";
import placeholderImage from './happyCubsPlaceholderImage.png';
import "./staffBioComponent.scss";

export interface StaffBioComponentProps {
    staffProfile: Profile | undefined | null;
    role: 'PrimaryTeacher' | 'Coordinator' | 'AdditionalTeacher';
    classType: string | undefined | null;
}

/**
 * Component that can render a Staff Bio
 */
export const StaffBioComponent = (props: StaffBioComponentProps) => {
    const { staffProfile, classType, role } = props;
    const { t } = useTranslation();

    // Load the data
    const {
        data: {
            model: staffModel
        }, isLoading: _isLoading, errors: loadErrors
    } = useStaff(staffProfile?.id);

    // Load the image
    const {
        data: {
            model: staffProfileImage
        }, isLoading: isLoadingImage, errors: imageLoadErrors
    } = useBlobReference(staffModel?.photoBlobReferenceId);
    const isLoading = _isLoading || isLoadingImage;

    // Return the UI
    //
    return (
        <>
            <AlertOnErrors
                errors={[
                    loadErrors,
                    imageLoadErrors
                ]}
            />

            <div className="staff-bio-component">
                <Row className="staff-bio-component-header">
                    <h1>Meet your {role === 'PrimaryTeacher' ? 'teacher' : role === 'Coordinator' ? 'Coordinator' : 'additional teacher'}</h1>
                </Row>

                <Row>
                    <ConditionalFragment showIf={isLoading}>
                        <LoadingIndicator />
                    </ConditionalFragment>
                    <ConditionalFragment showIf={!!staffProfileImage}>
                        <img src={staffProfileImage?.url ?? ''} alt="Staff profile" className="staff-bio-component-image" />
                    </ConditionalFragment>

                    <ConditionalFragment showIf={!staffProfileImage}>
                        <img src={placeholderImage} alt="Staff profile" className="staff-bio-component-image" />
                    </ConditionalFragment>
                </Row>

                <Row className="staff-bio-component-details">
                    <h2 className="staff-bio-component-details-name">{t('common.firstName', '{{firstName}}', { firstName: staffProfile?.firstName })}</h2>

                    <h3 className="staff-bio-component-details-classType">{classType} {role === 'PrimaryTeacher' ? 'teacher' : role === 'Coordinator' ? 'Coordinator' : 'additional teacher'}</h3>
                </Row>

                <Row className="staff-bio-component-bio">
                    <ConditionalFragment showIf={!!staffModel?.bioHtml}>
                        <HtmlDisplay html={staffModel?.bioHtml ?? ''} />
                    </ConditionalFragment>

                    <ConditionalFragment showIf={!staffModel?.bioHtml}>
                        <p className="text-center">{t('common.noBio', 'No bio available')}</p>
                    </ConditionalFragment>
                </Row>
            </div>
        </>
    );
};