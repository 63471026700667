import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import { useCallback, useMemo } from "react";
import { ConditionalFragment } from "react-conditionalfragment";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Badge, Col, ListGroupItem, ListGroupItemHeading, Row } from "reactstrap";
import { useBlobReference } from "../../api/main/blobReferences/useBlobReference";
import { Profile } from "../../api/main/models/Profile";
import { QueryMessageType } from "../../api/main/models/QueryMessageType";
import { AlertOnErrors } from "../../shared/alertOnErrors";
import { MembersWithMessagesType } from "../adminQueryMessages/AdminQueryMessagesOverview";
import { ProfileImageComponent } from "../profileImageComponent/ProfileImageComponent";
import { LoadingIndicator } from "../shared/loadingIndicator/LoadingIndicator";

export interface QueryMessageThreadListProps {
    staffId?: string | undefined | null,
    isMemberView?: boolean,
    isStaffView?: boolean,
    member?: MembersWithMessagesType | undefined | null,
    profiles: Array<Profile> | undefined | null,
    queryMessageTypes: Array<QueryMessageType> | undefined | null;
}

export const QueryMessageThreadList = (props: QueryMessageThreadListProps) => {
    const navigate = useNavigate();
    const {
        staffId,
        isMemberView = false,
        isStaffView = false,
        member,
        profiles,
        queryMessageTypes
    } = props;
    const { t } = useTranslation();

    // Load the ProfileImage
    const {
        data: {
            model: profileImage
        }, isLoading, errors: profileImageLoadErrors
    } = useBlobReference(member?.photoBlobReferenceId);

    // Generate an Array of different MemberIds with messages
    const items = useMemo(() => {
        if (!member) return;

        let result = [...member?.messages] ?? [];

        return result;
    }, [member]);

    // Get the last message from this thread 
    const getLastMessage = useCallback((message: any) => {
        if (!message) return;

        if (message?.length) {
            return message.slice(-1)[0];
        } else {
            return message;
        }
    }, []);

    // Get the staff profile
    const getStaffProfile = useCallback((id: string | undefined) => {
        if (!id) return;

        const profile = profiles?.find(it => it.id === id);
        return `${profile?.firstName} ${profile?.lastName}`;
    }, [profiles]);

    // Get the age of the last message
    const getAgeOfLastMessage = useCallback((message: any) => {
        const daysSinceLastMessage = moment().diff(moment(message?.submittedDate), 'days');
        const hoursSinceLastMessage = moment().diff(moment(message?.submittedDate), 'hours');

        if (daysSinceLastMessage >= 1) {
            // Use days
            const ageOfMessage = moment().diff(moment(message?.submittedDate), 'days');
            return (
                <>
                    <Badge color="danger" pill>
                        <FontAwesomeIcon icon="clock" />
                        <> </>
                        {ageOfMessage} {t('common.daysAgoDays', '{{dayOrDays}} ago', { dayOrDays: ageOfMessage === 1 ? 'day' : 'days' })}
                    </Badge>
                </>
            );
        } else if (hoursSinceLastMessage >= 1) {
            // Use hours
            const ageOfMessage = moment().diff(moment(message?.submittedDate), 'hours');
            return (
                <>
                    <Badge color={ageOfMessage > 4 ? "warning" : "success"} pill>
                        <FontAwesomeIcon icon="clock" />
                        <> </>
                        {ageOfMessage} {t('common.daysAgoHours', '{{hourOrHours}} ago', { hourOrHours: ageOfMessage === 1 ? 'hour' : 'hours' })}
                    </Badge>
                </>
            );
        } else {
            // Use minutes
            const ageOfMessage = moment().diff(moment(message?.submittedDate), 'minutes');
            return (
                <>
                    <Badge color="success" pill>
                        <FontAwesomeIcon icon="clock" />
                        <> </>
                        {ageOfMessage} {t('common.minutesAgoMinutes', '{{minuteOrMinutes}} ago', { minuteOrMinutes: ageOfMessage === 1 ? 'minute' : 'minutes' })}
                    </Badge>
                </>
            );
        }
    }, [t]);

    // Render the UI
    // 
    return (
        <>
            <AlertOnErrors error={[
                profileImageLoadErrors,
            ]} />


            <ListGroupItem>
                {items?.map(message => {
                    const lastMessage = getLastMessage(message);
                    const threadKey = lastMessage.threadKey ? lastMessage.threadKey : lastMessage.id;
                    const threadMessage = items.flat().find(it => it.id === threadKey); // Used to navigate to the thread

                    return <Row style={{ borderLeft: isMemberView ? lastMessage?.isReplyFromStaff ? 'solid 4px green' : 'solid 4px red' : '' }} onClick={() => isMemberView ? navigate(`/my/messages/${member?.memberId}/${threadMessage?.id}`) : isStaffView ? navigate(`/staff/messages/${member?.memberId}/${threadMessage?.id}/${staffId}`) : navigate(`/administration/messages/${member?.memberId}/${threadMessage?.id}`)}>
                        <Row>
                            <Col xs="auto">
                                <ConditionalFragment showIf={!!isLoading}>
                                    <LoadingIndicator />
                                </ConditionalFragment>
                                <ProfileImageComponent imageUrl={profileImage?.url ?? null} small={true} isReplyFromStaff={lastMessage.isReplyFromStaff} />
                            </Col>
                            <Col className="d-flex flex-column justify-content-between">
                                {/* Heading here is the queryMessageType (subject) */}
                                <ConditionalFragment showIf={!isMemberView}>
                                    <ListGroupItemHeading>
                                        {t('common.fullNameSingle', '{{fullName}}', { fullName: member?.memberName })}
                                    </ListGroupItemHeading>

                                    <Row>
                                        <Col>
                                            {t('common.subject', 'Subject')}: {queryMessageTypes?.find(it => it.id === lastMessage.queryMessageTypeId)?.name}
                                        </Col>
                                    </Row>
                                </ConditionalFragment>

                                <ConditionalFragment showIf={!!isMemberView}>
                                    <ListGroupItemHeading>
                                        {t('common.subject', 'Subject')}: {queryMessageTypes?.find(it => it.id === lastMessage.queryMessageTypeId)?.name}
                                    </ListGroupItemHeading>
                                </ConditionalFragment>

                                {/* We want to check the last message */}
                                <Row>
                                    <Col>
                                        {lastMessage?.contentsHtml}
                                    </Col>
                                </Row>

                                <Row>
                                    <Col>
                                        <span className="text-muted">
                                            <ConditionalFragment showIf={!isMemberView}>
                                                {
                                                    lastMessage?.isReplyFromStaff ? t('common.repliedOn', 'Replied on') : t('common.receivedOn', 'Received on')
                                                }
                                            </ConditionalFragment>

                                            <ConditionalFragment showIf={!!isMemberView}>
                                                {
                                                    lastMessage?.isReplyFromStaff ? t('common.receivedOn', 'Received on') : t('common.sentOn', 'Sent on')
                                                }
                                            </ConditionalFragment>
                                            <> </>
                                            {t('common.dateTimeLong', '{{date, dddd, MMMM Do YYYY, HH:mm:ss}}', { date: moment(lastMessage?.submittedDate) })}
                                        </span>
                                        <ConditionalFragment showIf={!lastMessage?.isReplyFromStaff && !isMemberView}>
                                            <> </>
                                            {getAgeOfLastMessage(lastMessage)}
                                        </ConditionalFragment>
                                    </Col>
                                </Row>

                                <ConditionalFragment showIf={!!threadMessage?.assignedToStaffUserId && !isStaffView && !isMemberView}>
                                    <Row>
                                        <Col>
                                            <Badge color="secondary" pill>
                                                {t('queryMessageThreadList.assignedTo', 'Assigned to')} {getStaffProfile(threadMessage?.assignedToStaffUserId)}
                                            </Badge>
                                        </Col>
                                    </Row>
                                </ConditionalFragment>
                            </Col>

                            <ConditionalFragment showIf={!isMemberView}>
                                <Col xs="auto">
                                    <Badge color={lastMessage?.resolved ? 'success' : 'danger'} >{t('common.resolvedStatus', '{{status}}', { status: lastMessage?.resolved ? 'Closed' : 'Open' })}</Badge>
                                </Col>
                            </ConditionalFragment>
                        </Row>
                    </Row>;
                })}
            </ListGroupItem>
        </>
    );
};