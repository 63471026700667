import gql from 'graphql-tag';
import { Guid } from 'guid-string';
import { ECommerceLinkFieldsFragment } from '../generated/graphql';

export const eCommerceLinkFieldsFragment = gql`
    fragment eCommerceLinkFields on ECommerceLink {
        id
        name
        displayOrder
        url
        archived
        photoBlobReferenceId
        description
        classStageId
    }
`;

export type ECommerceLink = Omit<ECommerceLinkFieldsFragment, '__typename'>;

/**
 * Default values.
 */
export const eCommerceLinkDefaultValues = (): Partial<ECommerceLink> => ({
    id: Guid.newGuid(),
    name: '',
    displayOrder: 99,
    url: '',
    archived: false,
    photoBlobReferenceId: null,
    description: '',
    classStageId: undefined
});