import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import { useMemo, useState } from "react";
import { ConditionalFragment } from "react-conditionalfragment";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { LinkContainer } from "react-router-bootstrap";
import { Badge, Button, ButtonDropdown, ButtonGroup, CardSubtitle, CardTitle, Col, DropdownItem, DropdownMenu, DropdownToggle, Row } from "reactstrap";
import { useToggleStateArray } from "use-toggle-state";
import { useChildrenListViewModel } from "../../api/main/children/viewModels/useChildrenListViewModel";
import { usePreferredListViewMode } from "../../globalState/preferredListViewMode/usePreferredListViewMode";
import { AlertOnErrors } from "../../shared/alertOnErrors";
import { useReplaceSearchParamsEffect, useSearchParams } from '../../shared/useURLSearchParams';
import { getAgeFromDateOfBirth } from "../../utilities/getAgeFromDateOfBirth";
import { Banner } from "../shared/banner/Banner";
import { CardsOrTable } from "../shared/cardsOrTable/CardsOrTable";
import { CardsOrTableToggle } from "../shared/cardsOrTable/CardsOrTableToggle";
import { LoadingIndicator } from "../shared/loadingIndicator/LoadingIndicator";
import { MainContainer } from "../shared/mainContainer/MainContainer";
import { SearchInput } from "../shared/searchInput/SearchInput";
import { StickyToolbar } from "../shared/stickyToolbar/StickyToolbar";

/**
 * List of all Children
 */
export const ChildrenList = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    // Load data.
    const {
        data: {
            items: _allItems,
            profiles,
        }, isLoading, errors: loadingErrors } = useChildrenListViewModel();
    const [isMenuOpen, toggleMenuOpen] = useToggleStateArray();

    // Combine all the loaded data so that each item includes its related details
    const allItems = useMemo(() => _allItems?.map(item => {
        const profile = profiles?.find(it => it.id === item.memberId);

        return {
            ...item,
            profile
        };
    }), [_allItems, profiles]);

    // Use the preferred view mode for lists.
    const [viewMode] = usePreferredListViewMode();

    // Search.
    const { search: searchParam } = useSearchParams();
    const [search, setSearch] = useState<string>(searchParam ?? '');

    // Keep the URL up to date with the search text.
    useReplaceSearchParamsEffect({ search: search });

    // Filter by the search.
    const items = useMemo(() => {
        let ret = (allItems ?? []);

        let lowerSearch = search.toLocaleLowerCase();
        if (lowerSearch) {
            // Just filtering by all string values that arent ids to start with. Most likely wont need them all for search.
            ret = ret.filter(item =>
                item.firstName.toLocaleLowerCase().indexOf(lowerSearch) >= 0
                || item.lastName.toLocaleLowerCase().indexOf(lowerSearch) >= 0
                || (item.profile?.firstName ?? '').toLocaleLowerCase().indexOf(lowerSearch) >= 0
                || (item.profile?.lastName ?? '').toLocaleLowerCase().indexOf(lowerSearch) >= 0
                || (item.profile?.primaryPhone ?? '').toLocaleLowerCase().indexOf(lowerSearch) >= 0
                || (item.profile?.primaryEmail ?? '').toLocaleLowerCase()?.indexOf(lowerSearch) >= 0
            );
        }

        return ret;

    }, [allItems, search,]);

    // Render the UI
    //
    return (
        <>
            <Banner fluid>
                <StickyToolbar>
                    <Row>
                        <Col>
                            <h1>
                                {t('childrenList.title', 'Bear cubs')}
                            </h1>
                        </Col>
                        <ConditionalFragment showIf={isLoading}>
                            <Col xs="auto">
                                <LoadingIndicator size="sm" />
                            </Col>
                        </ConditionalFragment>
                        <Col xs="auto">
                            <CardsOrTableToggle />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <SearchInput value={search} onChange={e => setSearch(e.currentTarget.value)} />
                        </Col>
                        <Col xs="auto">
                            <Row>
                                <ButtonGroup>
                                    <LinkContainer to={'add'}>
                                        <Button color="primary">
                                            <FontAwesomeIcon icon="plus" /><> {t('childrenList.add', 'Add')}</>
                                        </Button>
                                    </LinkContainer>
                                </ButtonGroup>
                            </Row>
                        </Col>
                    </Row>
                </StickyToolbar>
            </Banner>
            <MainContainer fluid>
                <AlertOnErrors errors={loadingErrors} />
                <CardsOrTable
                    viewMode={viewMode}
                    items={items}
                    tableHeadings={[
                        null, /* Handles the card specific function for which we don't want a table column. */
                        t('childrenList.firstName.heading', 'First name'),
                        t('childrenList.lastName.heading', 'Last name'),
                        t('childrenList.dateOfBirth.heading', 'Date of birth'),
                        t('childrenList.member.heading', 'Member'),
                        t('childrenList.registeredDate.heading', 'Date of registration'),
                    ]}
                    columns={[
                        // We handle the card layout as a specific column ignored from the table.
                        (item, view) => {
                            // This column doesn't show in the table.
                            if (view !== 'cards') {
                                return null;
                            }
                            return (
                                <>
                                    <CardTitle tag="h5">{t('common.fullName', '{{firstName}} {{lastName}}', { firstName: item.firstName, lastName: item.lastName })}</CardTitle>
                                    <CardSubtitle>
                                        <Row>
                                            <Col>
                                                { t('common.parentGuardian', 'Parent / Guardian')}: {t('common.fullName', '{{firstName}} {{lastName}}', { firstName: item.profile?.firstName, lastName: item.profile?.lastName })}
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                {t('common.dateOfBirth.abbreviation', 'DOB')}: {t('common.date', '{{date, DD/MM/YYYY}}', { date: moment(item.dateOfBirth) })}
                                                <> </>
                                                <Badge>
                                                   {t('common.ageForDateOfBirth', 'Age {{years}} years {{months}} months', getAgeFromDateOfBirth(item.dateOfBirth))}
                                                </Badge>
                                            </Col>
                                        </Row>
                                    </CardSubtitle>
                                </>
                            );
                        },

                        // The remaining columns are for table mode only.

                        // firstName
                        (item, view) => {
                            if (view !== 'table') {
                                return null;
                            }

                            return item.firstName;
                        },

                        // lastName
                        (item, view) => {
                            if (view !== 'table') {
                                return null;
                            }

                            return item.lastName;
                        },

                        // Date of birth
                        (item, view) => {
                            if (view !== 'table') {
                                return null;
                            }

                            return (
                                <>
                                    <div>
                                        {t('common.date', '{{date, DD/MM/YYYY}}', { date: moment(item.dateOfBirth), })}
                                    </div>
                                    <div>
                                        <Badge>
                                            {t('common.ageForDateOfBirth', 'Age {{years}} years {{months}} months', getAgeFromDateOfBirth(item.dateOfBirth))}
                                        </Badge>
                                    </div>
                                </>
                            )
                        },

                        // member
                        (item, view) => {
                            if (view !== 'table') {
                                return null;
                            }

                            return t('common.fullName', '{{firstName}} {{lastName}}', { firstName: item.profile?.firstName, lastName: item.profile?.lastName });
                        },

                        // Date of registration
                        (item, view) => {
                            if (view !== 'table') {
                                return null;
                            }

                            return t('common.date', '{{date, DD/MM/YYYY}}', { date: moment(item.registeredDate), }); 
                        },
                    ]}

                    buttons={(item) => (
                        <ButtonGroup>
                            <LinkContainer to={`edit/${item.id}`}>
                                <Button color="primary">
                                    <FontAwesomeIcon icon="edit" />
                                    <> {t('common.edit', 'Edit')}</>
                                </Button>
                            </LinkContainer>
                            <ButtonDropdown isOpen={isMenuOpen(item.id)} toggle={() => toggleMenuOpen(item.id)}>
                                <DropdownToggle color="primary" caret>
                                    <span className="visually-hidden">{t('common.menuDropdown', 'More')}</span>
                                </DropdownToggle>
                                <DropdownMenu end>
                                    <LinkContainer to={`delete/${item.id}`}>
                                        <DropdownItem className="text-danger">
                                            <FontAwesomeIcon icon="trash" />
                                            <> {t('common.delete', 'Delete')}</>
                                        </DropdownItem>
                                    </LinkContainer>
                                </DropdownMenu>
                            </ButtonDropdown>
                        </ButtonGroup>
                    )}
                    onItemClick={item => navigate(`edit/${item.id}`)}
                />
            </MainContainer>
        </>
    );
};