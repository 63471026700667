import * as React from 'react';
import { useSaveInStore, SaveInStoreOptions } from '../../../shared/useSaveInStore';
import { useUpdateChildMutation } from './useUpdateChildMutation';
import { Child } from '../models/Child';
import { useCreateChildMutation } from './useCreateChildMutation';
import { ChildCreateInput, ChildUpdateInput } from '../generated/graphql';

/**
 * Get a callback to save a Child in the api using either a create or update.
 */
export function useSaveChildMutation(options: SaveInStoreOptions<Child, string> = {}) {
    const [_create, createStatus] = useCreateChildMutation();
    const create = React.useCallback((model: Partial<Child>) => _create(model as ChildCreateInput), [_create]);

    const [_update, updateStatus] = useUpdateChildMutation();
    const update = React.useCallback((id: string, changes: Partial<Child>) => _update(id, changes as ChildUpdateInput), [_update]);

    return useSaveInStore(
        [create, createStatus],
        [update, updateStatus],
        options
    );
}
