import { Row, Col, Input, FormGroup, Label, Card, CardBody, CardHeader, Button, ButtonGroup } from 'reactstrap';
import { AlertOnErrors } from '../../../shared/alertOnErrors';
import { LoadingIndicator } from '../../shared/loadingIndicator/LoadingIndicator';
import { useTranslation } from 'react-i18next';
import { MainContainer } from '../../shared/mainContainer/MainContainer';
import { StickyToolbar } from '../../shared/stickyToolbar/StickyToolbar';
import { ConditionalFragment } from 'react-conditionalfragment';
import { Banner } from '../../shared/banner/Banner';
import { useCallback, useEffect, useMemo } from 'react';
import { ScheduleCalendarView } from './ScheduleCalendarView';
import { ScheduleCalendarViewDimensions } from './ScheduledCalendarViewDimensions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from 'react-router-dom';
import { ScheduledClass } from '../../../api/main/models/ScheduledClass';
import { useLocalStorage } from '../../../shared/useLocalStorage';
import { LinkContainer } from 'react-router-bootstrap';
import { useClassScheduleViewModel } from '../../../api/main/scheduledClasses/viewModels/useClassScheduleViewModel';
import { RoleInClass } from '../../../api/main/models/constants/RoleInClass';
import { Guid } from 'guid-string';
import { useCurrentTerm } from '../../../api/main/terms/useCurrentTerm';
import { useTermsWithClasses } from '../../../api/main/terms/viewModels/useTermsWithClasses';
import { TermSelector } from '../../shared/termSelector/TermSelector';

/**
 * Structure of the filter state used for filtering.
 */
export interface ClassScheduleFilterState {
    classTypeId?: string | undefined | null,
    classStageId?: string | undefined | null,
    classSubStageId?: string | undefined | null,
    classLocationId?: string | undefined | null,
    staffId?: string | undefined | null,
    termId?: string | undefined | null,
    dayOfWeek?: string | undefined | null;
}
/**
 * Interactive schedule of all classes.
 */
export const ClassSchedule = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    // Load all the terms
    const {
        data: {
            items: allTerms,
        }, isLoading: isLoadingTerms, errors: termsLoadingErrors,
    } = useTermsWithClasses();

    // Load the current term
    const {
        data: {
            model: currentTerm
        }, isLoading: isLoadingCurrentTerm, errors: currentTermLoadingErrors,
    } = useCurrentTerm();

    // Allow filtering (persisted to local storage between sessions).
    const [filterState, setFilterState] = useLocalStorage<ClassScheduleFilterState>('classSchedule.filterState', {});
    const changeFilterState = useCallback((changes: Partial<ClassScheduleFilterState>) => setFilterState(prevState => ({
        ...prevState,
        ...changes,
    })), [setFilterState]);

    // Load data.
    const {
        data: {
            items: _allItems,
            classTypes,
            classLocations,
            classStages,
            classSubStages,
            profiles,
            scheduledClassStaffs,
            classLocationClassTypes
        },
        isLoading: isLoadingData, errors: loadingErrors,
    } = useClassScheduleViewModel(filterState?.termId ?? currentTerm?.id);
    const isLoading = isLoadingData || isLoadingTerms || isLoadingCurrentTerm;

    // Combine all the loaded data so each item includes its related details.
    const allItems = useMemo(() => _allItems?.map(item => {
        // Follow the various lookups for related data.
        const classType = classTypes?.find(it => it.id === item.classTypeId);
        const classLocation = classLocations?.find(it => it.id === item.classLocationId);
        const currentClassStage = classStages?.find(it => it.id === item.currentClassStageId);
        const currentClassSubStage = classSubStages?.find(it => it.id === item.currentClassSubStageId);
        const nextClassStage = classStages?.find(it => it.id === item.nextClassStageId);
        const nextClassSubStage = classSubStages?.find(it => it.id === item.nextClassSubStageId);

        // Work out the staff from profiles and scheduledClassStaff
        const myStaffLinks = scheduledClassStaffs.filter(it => it.scheduledClassId === item.id);
        const primaryTeacherLink = myStaffLinks.find(it => it.roleInClass === RoleInClass.PrimaryTeacher);

        const allStaff = myStaffLinks.map(link => {
            const profile = profiles.find(it => it.id === link.staffId);
            return {
                ...(profile ?? {}),

                scheduledClassStaff: link,
            };
        });
        const primaryTeacher = allStaff.find(it => it.scheduledClassStaff.id === primaryTeacherLink?.id);


        // Return everything as a single object.
        return {
            ...item,

            classType,
            classLocation,
            classStage: currentClassStage,
            classSubStage: currentClassSubStage,
            nextClassStage,
            nextClassSubStage,

            primaryTeacher,
            allStaff,
        };
    }).filter(item => !!item.classStage?.id) // Remove anything that doesn't have a stage in the term being displayed.
        , [_allItems, classTypes, classLocations, classStages, classSubStages,
            profiles, scheduledClassStaffs,
        ]);

    // Filter the items that can be filtered by other filter choices.
    const classStagesForFilter = useMemo(() => classStages?.filter(item => item.classTypeId === filterState.classTypeId), [classStages, filterState]);
    const classSubStagesForFilter = useMemo(() => classSubStages?.filter(item => item.classStageId === filterState.classStageId), [classSubStages, filterState]);

    // Filter the items to show based on the filter.
    const items = useMemo(() => {
        let ret = allItems;

        if (filterState.classTypeId) {
            ret = ret?.filter(item => item.classTypeId === filterState.classTypeId);
        }

        if (filterState.classStageId) {
            ret = ret?.filter(item => item.currentClassStageId === filterState.classStageId);
        }

        if (filterState.classSubStageId) {
            ret = ret?.filter(item => item.currentClassSubStageId === filterState.classSubStageId);
        }

        if (filterState.classLocationId) {
            ret = ret?.filter(item => item.classLocationId === filterState.classLocationId);
        }

        if (filterState.staffId) {
            // Special handling for "No primary teacher assigned" represented by Guid.empty.
            if (filterState.staffId === Guid.empty) {
                ret = ret?.filter(item => !item.primaryTeacher?.id);
            } else {
                // Otherwise we can find the staff member in any role, not just the primary teacher role.
                ret = ret?.filter(item => !!item.allStaff.find(link => link.id === filterState.staffId));
            }
        }

        if (filterState.termId) {
            ret = ret?.filter(item => item.termId === filterState.termId);
        }

        if (filterState.dayOfWeek) {
            ret = ret?.filter(item => item.dayOfWeek.toString() === filterState.dayOfWeek);
        }

        return ret;
    }, [allItems, filterState]);
    const resetFilters = useCallback(() => setFilterState({ termId: currentTerm?.id }), [setFilterState, currentTerm?.id]);

    // Filtering the ClassLocations -> If viewing a specific class type, only show the locations that have that class type.
    const filteredClassLocations = useMemo(() => {
        if (!classLocations || !classLocationClassTypes) {
            return;
        }

        // If we aren't filtering by class type, return all class locations.
        if (!filterState?.classTypeId) {
            return classLocations;
        }

        // Otherwise, return only the class locations that have the class type.
        return classLocations.filter(classLocation => {
            const matchingClassTypes = classLocationClassTypes.filter(classLocationClassType => {
                return classLocationClassType.classLocationId === classLocation.id && classLocationClassType.classTypeId === filterState.classTypeId;
            });

            return matchingClassTypes.length > 0;
        });
    }, [filterState, classLocations, classLocationClassTypes]);

    // Calendar view dimensions (persisted to local storage between sessions).
    const [groupDimensionKey, setGroupDimensionKey] = useLocalStorage<string>('classSchedule.groupDimensionKey', 'classType');
    const [rowDimensionKey, setRowDimensionKey] = useLocalStorage<string>('classSchedule.rowDimensionKey', 'classStage');
    const [columnDimensionKey, setColumnDimensionKey] = useLocalStorage<string>('classSchedule.columnDimensionKey', 'classLocation');
    const [colorDimensionKey, setColorDimensionKey] = useLocalStorage<string>('classSchedule.colorDimensionKey', 'classSubStage');
    const resetDimensions = useCallback(() => {
        setGroupDimensionKey('classType');
        setRowDimensionKey('classStage');
        setColumnDimensionKey('classLocation');
        setColorDimensionKey('classSubStage');
    }, [setGroupDimensionKey, setRowDimensionKey, setColumnDimensionKey, setColorDimensionKey,]);

    // Resolve the selected dimension ids into actual dimensions.
    const {
        groupDimension,
        rowDimension,
        columnDimension,
        colorDimension,
    } = useMemo(() => {
        // Get the selected dimensions by name, making sure we don't end up with any undefined choices for dimensions that don't support it by filling
        // in defaults if we end up with one.
        const groupDimension = ScheduleCalendarViewDimensions[groupDimensionKey] ?? undefined;
        const rowDimension = ScheduleCalendarViewDimensions[rowDimensionKey] ?? ScheduleCalendarViewDimensions['startTimeHour'];
        const columnDimension = ScheduleCalendarViewDimensions[columnDimensionKey] ?? ScheduleCalendarViewDimensions['dayOfWeek'];
        const colorDimension = ScheduleCalendarViewDimensions[colorDimensionKey] ?? ScheduleCalendarViewDimensions['classType'];


        return {
            groupDimension,
            rowDimension,
            columnDimension,
            colorDimension,
        };
    }, [groupDimensionKey, rowDimensionKey, columnDimensionKey, colorDimensionKey,]);

    // Showing and hiding UI elements (persisted to local storage between sessions).
    const [isFiltersVisible, _setIsFiltersVisible] = useLocalStorage('classSchedule.isFiltersVisible', false);
    const toggleFiltersVisible = useCallback(() => _setIsFiltersVisible(prevState => !prevState), [_setIsFiltersVisible]);
    const [isDimensionsVisible, _setIsDimensionsVisible] = useLocalStorage('isDimensionsVisible.isFiltersVisible', false);
    const toggleDimensionsVisible = useCallback(() => _setIsDimensionsVisible(prevState => !prevState), [_setIsDimensionsVisible]);

    // An event has been clicked, so open it.
    const onEventClick = useCallback((event: ScheduledClass) => {
        navigate(`/administration/class-management/classes/edit/${event.id}`);
    }, [navigate]);

    // If there is no term selected, select the current term.
    useEffect(() => {
        if (!filterState.termId) {
            setFilterState({termId: currentTerm?.id})
        }
    }, [filterState, currentTerm?.id, setFilterState]);

    // Render the UI.
    //
    return (
        <>
            <Banner fluid>
                <StickyToolbar>
                    <Row>
                        <Col xs={12} sm="auto">
                            <h1>
                                {t('classSchedule.title', 'Schedule examiner')}
                            </h1>
                            <h4>{allTerms?.find(it => it.id === filterState.termId)?.name }</h4>
                        </Col>
                        <Col>
                            <Button color="primary" outline={!isFiltersVisible} onClick={() => toggleFiltersVisible()}>
                                <FontAwesomeIcon icon="filter" />
                                <> </>
                                {t('classSchedule.toggleFilters', 'Filter')}
                            </Button>

                            <Button color="primary" outline={!isDimensionsVisible} onClick={() => toggleDimensionsVisible()}>
                                <FontAwesomeIcon icon="sliders" />
                                <> </>
                                {t('classSchedule.toggleDimensions', 'View settings')}
                            </Button>
                        </Col>
                        <ConditionalFragment showIf={isLoading}>
                            <Col xs="auto">
                                <LoadingIndicator size="sm" />
                            </Col>
                        </ConditionalFragment>
                    
                        <Col xs="" md="auto">
                            <ButtonGroup>
                                <LinkContainer to="/administration/class-management/classes/add">
                                    <Button color="primary">
                                        <FontAwesomeIcon icon="plus" /><> {t('classSchedule.add', 'Add a new class')}</>
                                    </Button>
                                </LinkContainer>
                            </ButtonGroup>
                        </Col>
                    </Row>

                    {/* Filters */}
                    <ConditionalFragment showIf={isFiltersVisible}>
                        <Card className="mb-2">
                            <CardHeader>
                                <h5>{t('classSchedule.filters.heading', 'Filter')}</h5>
                            </CardHeader>
                            <CardBody>
                                <Row>
                                    <Col xs="auto">
                                        <TermSelector selectedTermId={filterState.termId} setFilterState={({ termId: value }) => setFilterState({ termId: value })} restrictToTermsWithClasses={true} showPreviousTerms={true} />
                                    </Col>
                                    <Col xs="auto">
                                        <Input type="select" value={filterState.dayOfWeek ?? ''} onChange={e => changeFilterState({ dayOfWeek: e.currentTarget.value, })}>
                                            <option value="">{t('classSchedule.filter.dayOfWeek.all', '(All days)')}</option>
                                            <option value="0">{t('classSchedule.filter.dayOfWeek.sunday', 'Sunday')}</option>
                                            <option value="1">{t('classSchedule.filter.dayOfWeek.monday', 'Monday')}</option>
                                            <option value="2">{t('classSchedule.filter.dayOfWeek.tuesday', 'Tuesday')}</option>
                                            <option value="3">{t('classSchedule.filter.dayOfWeek.wednesday', 'Wednesday')}</option>
                                            <option value="4">{t('classSchedule.filter.dayOfWeek.thursday', 'Thursday')}</option>
                                            <option value="5">{t('classSchedule.filter.dayOfWeek.friday', 'Friday')}</option>
                                            <option value="6">{t('classSchedule.filter.dayOfWeek.saturday', 'Saturday')}</option>
                                        </Input>
                                    </Col>
                                    <Col>
                                        <Input type="select" value={filterState.classTypeId ?? ''} onChange={e => changeFilterState({ classTypeId: e.currentTarget.value, classStageId: '', classSubStageId: '', })}>
                                            <option value="">{t('classSchedule.filter.classTypes.all', '(All activities)')}</option>
                                            {
                                                classTypes?.map(item => (
                                                    <option key={item.id} value={item.id}>{item.name}</option>
                                                ))
                                            }
                                        </Input>
                                    </Col>
                                    <ConditionalFragment showIf={!!filterState.classTypeId}>
                                        <Col>
                                            <Input type="select" value={filterState.classStageId ?? ''} onChange={e => changeFilterState({ classStageId: e.currentTarget.value, classSubStageId: '', })}>
                                                <option value="">{t('classSchedule.filter.classStages.all', '(All class types)')}</option>
                                                {
                                                    classStagesForFilter?.map(item => (
                                                        <option key={item.id} value={item.id}>{item.name}</option>
                                                    ))
                                                }
                                            </Input>
                                        </Col>
                                    </ConditionalFragment>
                                    <ConditionalFragment showIf={!!filterState.classStageId}>
                                        <Col>
                                            <Input type="select" value={filterState.classSubStageId ?? ''} onChange={e => changeFilterState({ classSubStageId: e.currentTarget.value })}>
                                                <option value="">{t('classSchedule.filter.classSubStages.all', '(All stages)')}</option>
                                                {
                                                    classSubStagesForFilter?.map(item => (
                                                        <option key={item.id} value={item.id}>{item.name}</option>
                                                    ))
                                                }
                                            </Input>
                                        </Col>
                                    </ConditionalFragment>
                                    <Col>
                                        <Input type="select" value={filterState.classLocationId ?? ''} onChange={e => changeFilterState({ classLocationId: e.currentTarget.value })}>
                                            <option value="">{t('classSchedule.filter.classLocations.all', '(All locations)')}</option>
                                            {
                                                filteredClassLocations?.map(item => (
                                                    <option key={item.id} value={item.id}>{item.name}</option>
                                                ))
                                            }
                                        </Input>
                                    </Col>
                                    <Col>
                                        <Input type="select" value={filterState.staffId ?? ''} onChange={e => changeFilterState({ staffId: e.currentTarget.value })}>
                                            <option value="">{t('classSchedule.filter.staff.all', '(All staff)')}</option>
                                            <option value={Guid.empty} >{t('classSchedule.filter.staff.noPrimaryTeacher', '(No primary teacher assigned)')}</option>
                                            {
                                                profiles?.map(item => (
                                                    <option key={item.id} value={item.id}>{t('common.fullName', '{{firstName}} {{lastName}}', { firstName: item.firstName ?? '', lastName: item.lastName ?? '' })}</option>
                                                ))
                                            }
                                        </Input>
                                    </Col>
                                </Row>
                                <Button color="link" onClick={resetFilters}>
                                    {t('classSchedule.filters.reset', 'Reset filters')}
                                </Button>
                            </CardBody>
                        </Card>
                    </ConditionalFragment>


                    {/* Dimensions / View settings */}
                    <ConditionalFragment showIf={isDimensionsVisible}>
                        <Card>
                            <CardHeader>
                                <h5>{t('classSchedule.dimensions.heading', 'View settings')}</h5>
                            </CardHeader>
                            <CardBody>
                                <Row>
                                    <Col>
                                        <FormGroup>
                                            <Label htmlFor="groupDimensionKey">{t('classSchedule.groupDimensionKey.name', 'Groups')}</Label>
                                            <Input name="groupDimensionKey" type="select" value={groupDimensionKey ?? ''} onChange={e => setGroupDimensionKey(e.currentTarget.value)}>
                                                <option value="">{t('classSchedule.groupDimensionKey.noGrouping', '(No grouping)')}</option>
                                                {
                                                    Object.keys(ScheduleCalendarViewDimensions).map(key => (
                                                        <option key={key} value={key}>{ScheduleCalendarViewDimensions[key].titleFunction(t)}</option>
                                                    ))
                                                }
                                            </Input>
                                        </FormGroup>
                                    </Col>
                                    <Col>
                                        <FormGroup>
                                            <Label htmlFor="rowDimensionKey">{t('classSchedule.rowDimensionKey.name', 'Rows')}</Label>
                                            <Input name="rowDimensionKey" type="select" value={rowDimensionKey ?? ''} onChange={e => setRowDimensionKey(e.currentTarget.value)}>
                                                {
                                                    Object.keys(ScheduleCalendarViewDimensions).map(key => (
                                                        <option key={key} value={key}>{ScheduleCalendarViewDimensions[key].titleFunction(t)}</option>
                                                    ))
                                                }
                                            </Input>
                                        </FormGroup>
                                    </Col>
                                    <Col>
                                        <FormGroup>
                                            <Label htmlFor="columnDimensionKey">{t('classSchedule.columnDimensionKey.name', 'Columns')}</Label>
                                            <Input name="columnDimensionKey" type="select" value={columnDimensionKey ?? ''} onChange={e => setColumnDimensionKey(e.currentTarget.value)}>
                                                {
                                                    Object.keys(ScheduleCalendarViewDimensions).map(key => (
                                                        <option key={key} value={key}>{ScheduleCalendarViewDimensions[key].titleFunction(t)}</option>
                                                    ))
                                                }
                                            </Input>
                                        </FormGroup>
                                    </Col>
                                    <Col>
                                        <FormGroup>
                                            <Label htmlFor="colorDimensionKey">{t('classSchedule.colorDimensionKey.name', 'Colour coding')}</Label>
                                            <Input name="colorDimensionKey" type="select" value={colorDimensionKey ?? ''} onChange={e => setColorDimensionKey(e.currentTarget.value)}>
                                                {
                                                    Object.keys(ScheduleCalendarViewDimensions).map(key => (
                                                        <option key={key} value={key}>{ScheduleCalendarViewDimensions[key].titleFunction(t)}</option>
                                                    ))
                                                }
                                            </Input>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Button color="link" onClick={resetDimensions}>
                                    {t('classSchedule.dimensions.reset', 'Reset view settings')}
                                </Button>
                            </CardBody>
                        </Card>
                    </ConditionalFragment>
                </StickyToolbar>
            </Banner>
            <MainContainer fluid>
                <AlertOnErrors errors={[
                    loadingErrors,
                    termsLoadingErrors,
                    currentTermLoadingErrors,
                ]} />

                <ScheduleCalendarView
                    items={items ?? []}
                    groupDimension={groupDimension}
                    rowDimension={rowDimension}
                    columnDimension={columnDimension}
                    colorDimension={colorDimension}
                    onEventClick={onEventClick}
                />
            </MainContainer>
        </>
    );
};