import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ValidationErrors } from 'pojo-validator';
import { ValidateCallback } from 'pojo-validator-react';
import { ValidatedInput } from 'pojo-validator-reactstrap';
import { useTranslation } from 'react-i18next';
import { Badge, Button, Col, FormGroup, Input, Label, ListGroupItem, ModalBody, ModalHeader, Row } from 'reactstrap';
import { StaffNote } from '../../api/main/models/StaffNote';
import { HtmlDisplay, HtmlEditor } from '../../shared/htmlEditor';
import { noteTypeDisplayName, getNoteTypes, NoteType } from "../../api/main/models/constants/NoteType";
import moment from 'moment';
import { Profile } from '../../api/main/models/Profile';
import { TwoValueSwitch } from '../shared/twoValueSwitch/TwoValueSwitch';
import { StyledModal } from '../shared/styledModal/StyledModal';
import { useToggleState } from 'use-toggle-state';
import { useAsyncCallback } from 'react-use-async-callback';
import { ConditionalFragment } from 'react-conditionalfragment';

export interface StaffNoteComponentProps {
    model: StaffNote | undefined,
    staffProfiles: Array<Profile> | null | undefined,
    change: (changes: Partial<StaffNote>) => void;
    remove: () => void,

    validate: ValidateCallback,
    validationErrors: ValidationErrors,
    saveForm: (options?: { dontNavigate?: boolean; }) => Promise<void>,

    initialModalIsOpen?: boolean,
    modalHeading: string,
}

/**
 * Component under ClassLocations that lets the user edit an StaffNote
 */
export const StaffNoteComponent = (props: StaffNoteComponentProps) => {
    const {
        model,
        change,
        remove,
        validate,
        validationErrors,
        staffProfiles,
        saveForm,
        modalHeading,
        initialModalIsOpen = false
    } = props;

    const { t } = useTranslation();

    // Get profile of the updatedByUserId user so we can display the name
    const profile = staffProfiles?.find(it => it.userId === model?.updatedByUserId);

    // Note Modal
    const [isNotesModalOpen, toggleNotesModal] = useToggleState(initialModalIsOpen);

    // Handling saving the model and closing the modal
    const [handleSaveAndClose] = useAsyncCallback(async () => {
        await saveForm({ dontNavigate: true });
        toggleNotesModal();
    }, [saveForm, toggleNotesModal]);

    // Render the UI
    //
    return (
        <>
            <tr onDoubleClick={() => toggleNotesModal()}>
                <td>
                    <HtmlDisplay html={model?.contentsHtml ?? ''} />
                </td>
                <td>
                    {model?.showOnRegister ? (<FontAwesomeIcon icon="check" color="#198754" />) : (<FontAwesomeIcon icon="xmark" color="#dc3545" />)}
                </td>
                <td>
                    {t('common.fullName', '{{firstName}} {{lastName}}', { firstName: profile?.firstName ?? '', lastName: profile?.lastName ?? '' })}
                    <> </>
                    <ConditionalFragment showIf={model?.noteType === NoteType.Progress}>
                        <Badge color="primary">{t('common.progressNote', 'Progress note')}</Badge>
                    </ConditionalFragment>
                </td>
                <td>
                    {t('common.dateTime', '{{dateTime, DD/MM/YYYY HH:mm}}', { dateTime: moment(model?.updatedDate), })}
                </td>
                <td>
                    <Button color="primary" outline onClick={() => toggleNotesModal()}>
                        <FontAwesomeIcon icon="edit" />
                        <> </>
                        {t('staffNotesTab.staffNotesComponent.edit', 'Edit')}
                    </Button>
                </td>
            </tr>

            <StyledModal isOpen={isNotesModalOpen} toggle={() => toggleNotesModal()} size="lg">
                <ModalHeader toggle={() => toggleNotesModal()}>
                    {t('staffNotesTab.staffNotesComponent.modalHeading', 'Staff note for {{modalHeading}}', { modalHeading: modalHeading })}
                </ModalHeader>

                <ModalBody>
                    <ListGroupItem>
                        <Row>
                            <Col>
                                <FormGroup>
                                    <Label htmlFor="updatedDate">{t('staffNotesTab.staffNotesComponent.updatedDate', 'Updated at')}</Label>
                                    <Input name="updatedDate" type="text" value={t('common.dateTime', '{{dateTime, DD/MM/YYYY HH:mm}}', { dateTime: moment(model?.updatedDate), })} disabled />
                                </FormGroup>
                            </Col>
                            <Col>
                                <FormGroup>
                                    <Label htmlFor="updatedByUserId">{t('staffNotesTab.staffNotesComponent.updatedByUserId', 'Updated by')}</Label>
                                    <Input name="updatedByUserId" type="text" value={t('common.fullName', '{{firstName}} {{lastName}}', { firstName: profile?.firstName ?? '', lastName: profile?.lastName ?? '' })} disabled />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <FormGroup>
                                <Label htmlFor="contentsHtml">{t('staffNotesTab.staffNotesComponent.contentsHtml', 'Note')}</Label>
                                <HtmlEditor size="sm" value={model?.contentsHtml ?? ''} onChange={text => change({ contentsHtml: text })} />
                            </FormGroup>
                        </Row>

                        <Row>
                            <Col>
                                <FormGroup>
                                    <Label htmlFor="noteType">{t('staffNotesTab.staffNotesComponent.noteType', 'Type of note')}</Label>
                                    <ValidatedInput name="noteType" type="select" value={model?.noteType ?? ''} onChange={e => change({ noteType: e.currentTarget.value })} onBlur={e => validate('noteType')} validationErrors={validationErrors['noteType']}>
                                        {
                                            getNoteTypes().map(item => (
                                                <option key={item} value={item}>{noteTypeDisplayName(item, t)}</option>
                                            ))
                                        }
                                    </ValidatedInput>
                                </FormGroup>
                            </Col>
                            <Col>
                                <Label htmlFor="showOnRegister">{t('common.showOnRegister', 'Show on register')}</Label>
                                <TwoValueSwitch leftLabel="" rightLabel="" checked={model?.showOnRegister ?? false} onChange={(value) => change({ showOnRegister: value })} />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Button color="danger" outline onClick={() => remove()}>
                                    <FontAwesomeIcon icon="trash-alt" />
                                    <> </>
                                    {t('staffNotesTab.staffNotesComponent.delete', 'Delete note')}
                                </Button>
                            </Col>
                            <Col xs="auto">
                                <Button color="primary" onClick={() => handleSaveAndClose()}>
                                    <FontAwesomeIcon icon="save" />
                                    <> </>
                                    {t('staffNotesTab.staffNotesComponent.saveAndClose', 'Save and close')}
                                </Button>
                            </Col>
                        </Row>
                    </ListGroupItem>
                </ModalBody>
            </StyledModal>
        </>
    );
};