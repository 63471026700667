import * as React from 'react';
import { useLazyQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { mainApiConfig } from '../../../../configure/mainApiConfig';
import { staffQualificationCheckFieldsFragment } from '../../models/StaffQualificationCheck';
import { StaffQualificationChecksForNavigationAlertQuery, StaffQualificationChecksForNavigationAlertQueryVariables } from '../../generated/graphql';
import { AsyncLoadOptions, AsyncLoadResult } from '../../../../shared/abstractStore';
import { Guid } from 'guid-string';

/**
 * Get a list of StaffQualificationChecksForNavigationAlert from the api.
 * @param options
 */
export function useStaffQualificationChecksForNavigationAlert(staffId: string | undefined | null, options: AsyncLoadOptions = {}): AsyncLoadResult<StaffQualificationChecksForNavigationAlertQuery> {
    // Query data we need from the api.
    const [load, { data, loading, error, refetch, }] = useLazyQuery<StaffQualificationChecksForNavigationAlertQuery, StaffQualificationChecksForNavigationAlertQueryVariables>(
        gql`
        query staffQualificationChecksForNavigationAlertQuery ($staffId: ID!) {
            items: staffQualificationChecks (staffId: $staffId) {
                ...staffQualificationCheckFields
            }
        }

        ${staffQualificationCheckFieldsFragment}
        `,
        {

            variables: {
                staffId: staffId ?? Guid.empty
            },
            fetchPolicy: options.fetchPolicy ?? mainApiConfig.defaultFetchPolicy,
            pollInterval: options.pollInterval,
            onCompleted: (data) => {
                if (options.onCompleted) {
                    options.onCompleted(data);
                }
            }
        }
    );

    // Run the query (unless we are being lazy).
    React.useEffect(() => {
        if (!options.lazy) {
            load();
        }
    }, [options.lazy, load]);

    // Return the results.
    const refresh = React.useCallback(async () => { !refetch ? await load() : await refetch(); }, [refetch, load]);
    return React.useMemo(() => ({
        data: data ?? ({} as any),
        refresh: refresh,
        isLoading: loading,
        errors: error,
    }), [data, refresh, loading, error,]);
}