import { useTranslation } from "react-i18next";
import { ScheduledClassWithRelationships } from "./ScheduledTimetableViewDimensions";
import { dayOfWeekDisplayName } from "../../../api/main/models/constants/DayOfWeek";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./timetableEvent.scss";
import { ConditionalFragment } from "react-conditionalfragment";
import { RoleInClass, roleInClassDisplayName } from "../../../api/main/models/constants/RoleInClass";
import moment from "moment";

export interface TimetableEventProps {
    event: ScheduledClassWithRelationships,
    onClick?: () => void,

    /**
     * Index of the color to show using calendar-event-color-block-color-{colorIndex}.
     * This uses CSS classes to work, and the number of available CSS classes to work and will wrap through the colors if we exceed the
     * number of available classes (maintained in colorIndexCount below).
     */
    colorIndex?: number,

    isClassCancelled: boolean,
    isHoliday: boolean,
    isPrimaryTeacherSubstitute: boolean,
    isPrimaryTeacherAbsent: boolean,
    dateOfThisClass: Date,
}

/**
 * This should match the total number of calendar-event-color-block-color-{colorIndex} CSS classes available so we can keep things within that color map.
 * Anyone using this component that wants to use colors should make sure they do not pass a value greater than this.
 */
const colorIndexCount = 31;

/**
 * Component to represent an event (i.e. a Scheduled Class) in the ScheduleTimetableView.
 * @param props
 */
export const TimetableEvent = (props: TimetableEventProps) => {
    const {
        event,
        onClick,
        colorIndex: _colorIndex,
        isClassCancelled,
        isHoliday,
        isPrimaryTeacherSubstitute,
        isPrimaryTeacherAbsent,
        dateOfThisClass
    } = props;

    const { t } = useTranslation();

    // Convert the colorIndex we get passed in to one that is always within our range.
    const colorIndex = (_colorIndex ?? 0) % colorIndexCount;

    return (
        <div className={`calendar-event calendar-event-${isClassCancelled && 'cancelled'} calendar-event-${isHoliday && 'holiday'}`} onClick={onClick}>
            <ConditionalFragment showIf={!!isClassCancelled}>
                <div className="calendar-event-status">
                    {t('common.cancelled', 'Cancelled')}
                </div>
            </ConditionalFragment>
            <ConditionalFragment showIf={!!isHoliday}>
                <div className="calendar-event-status">
                    {t('common.termHoliday', 'Term holiday')}
                </div>
            </ConditionalFragment>
            <div className="calendar-event-event-time">
                <span className="calendar-event-event-time-day">
                    {dayOfWeekDisplayName(event.dayOfWeek, t)}
                    <> </>
                </span>
                <span className="calendar-event-event-time-timespan">
                    {t(
                        'scheduleTimetableView.event.time',
                        '{{startTimeHours, 00}}:{{startTimeMinutes, 00}} - {{endTimeHours, 00}}:{{endTimeMinutes, 00}}',
                        {
                            startTimeHours: event.startTimeHours,
                            startTimeMinutes: event.startTimeMinutes,
                            endTimeHours: event.endTimeHours,
                            endTimeMinutes: event.endTimeMinutes,
                        })}
                </span>
            </div>
            <div className="calendar-event-body">
                <div className={`calendar-event-color-block calendar-event-color-block-color-${colorIndex}`}>
                </div>
                <div className="calendar-event-description-block">
                    <div className="calendar-event-stage">
                        {t(
                            'scheduleTimetableView.event.type',
                            '{{classTypeName}}',
                            {
                                classTypeName: event.classType?.name,
                            }
                        )}
                        <br />
                        {t(
                            'scheduleTimetableView.event.stage',
                            '{{classStageName}} / {{classSubStageName}}',
                            {
                                classStageName: event.classStage?.name,
                                classSubStageName: event.classSubStage?.name,
                            }
                        )}
                    </div>
                    <div className="calendar-event-location">
                        {event.classLocation?.name}
                    </div>
                    <div className="text-muted">
                        {t('common.numberOfSpaces', '{{spaces}} {{pluralOrNot}} remaining', { spaces: event.numberOfSpacesForCurrentTerm, pluralOrNot: event.numberOfSpacesForCurrentTerm === 1 ? 'space' : 'spaces' })}
                    </div>
                    <div className="calendar-event-staff">
                        { // Primary teacher.
                            !!event.primaryTeacher?.id ? (
                                <>
                                    <div className={`calendar-event-staff-name calendar-event-staff-${event.primaryTeacher.scheduledClassStaff.roleInClass} calendar-event-staff-${isPrimaryTeacherSubstitute && 'substitute'}`}>
                                        {roleInClassDisplayName(event.primaryTeacher.scheduledClassStaff.roleInClass, t)}
                                        <> - </>
                                        {t('common.fullName', '{{firstName}} {{lastName}}', { firstName: event.primaryTeacher.firstName ?? '', lastName: event.primaryTeacher.lastName ?? '' })}
                                        <ConditionalFragment showIf={!!isPrimaryTeacherAbsent}>
                                            <> </>
                                            {t('common.absent', '(Absent)')}
                                        </ConditionalFragment>
                                    </div>
                                    <ConditionalFragment showIf={!!isPrimaryTeacherSubstitute} >
                                        <div className={`calendar-event-staff-name calendar-event-staff-${event.substitutePrimaryTeacher?.scheduledClassStaff.roleInClass}`}>
                                            {roleInClassDisplayName(event.primaryTeacher.scheduledClassStaff.roleInClass, t)}
                                            <> - </>
                                            {t('common.fullName', '{{firstName}} {{lastName}}', { firstName: event.substitutePrimaryTeacher?.firstName ?? '', lastName: event.substitutePrimaryTeacher?.lastName ?? '' })}
                                            <> </>
                                            {t('common.substitute', '(Substitute)')}
                                        </div>
                                    </ConditionalFragment>
                                </>
                            ) : (
                                <div className="calendar-event-staff-name calendar-event-staff-no-primary-teacher">
                                    <FontAwesomeIcon icon="warning" />
                                    <> </>
                                    {t('calendarEvent.noPrimaryTeacher', 'No primary teacher assigned')}
                                </div>
                            )
                        }
                        { // All other staff, except substitute PrimaryTeacher.
                            event.allStaff?.filter(it => it.scheduledClassStaff.roleInClass !== RoleInClass.PrimaryTeacher).map(profile => {
                                // If we couldn't load the profile, skip it.
                                if (!profile.id) {
                                    return null;
                                }

                                // If this is the primary teacher, we've already shown their details, so skip it.
                                if (profile.id === event.primaryTeacher?.id) {
                                    return null;
                                }

                                // Return the staff name.
                                return (
                                    <div className={`calendar-event-staff-name calendar-event-staff-${profile.scheduledClassStaff.roleInClass}`} key={profile.id}>
                                        <ConditionalFragment showIf={(profile?.scheduledClassStaff.isSubsitute && moment(dateOfThisClass).isBetween(profile?.scheduledClassStaff?.subsituteStartDate, profile?.scheduledClassStaff?.subsituteEndDate, undefined, '[]')) || !profile?.scheduledClassStaff?.isSubsitute}>
                                            {roleInClassDisplayName(profile.scheduledClassStaff.roleInClass, t)}
                                            <> </>
                                            {t('common.fullName', '{{firstName}} {{lastName}}', { firstName: profile.firstName ?? '', lastName: profile.lastName ?? '' })}
                                            <> </>
                                        </ConditionalFragment>
                                        <strong>
                                            {(profile?.scheduledClassStaff.isSubsitute && moment(dateOfThisClass).isBetween(profile?.scheduledClassStaff?.subsituteStartDate, profile?.scheduledClassStaff?.subsituteEndDate, undefined, '[]')) && t('common.substitute', '(Substitute)')}
                                        </strong>
                                        <strong>
                                            {profile?.absences.find(it => moment(dateOfThisClass).isBetween(it.startDate, it.endDate, undefined, '[]')) && t('common.absent', '(Absent)')}
                                        </strong>
                                    </div>
                                );
                            })
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};