import moment, { Moment } from "moment";
import { useCallback, useEffect, useMemo, useState } from "react";
import { ConditionalFragment } from "react-conditionalfragment";
import "./Timer.scss";

interface TimerProps {
    expiryDate: Moment,
    onExpired?: () => void,
}

export const Timer = (props: TimerProps) => {
    const {
        expiryDate,
        onExpired,
    } = props;

    // Timer is a ref so we can keep it up to date.
    const { minutes, seconds } = (() => {
        const diff = moment.duration(moment().diff(expiryDate));
        return { minutes: 0 - Math.floor(diff.minutes()), seconds: 0 - Math.floor(diff.seconds()) };
    })();

    // State used to force a re-render.
    const [, setRefreshTicks] = useState<number>(0);
    const forceRender = useCallback(() => setRefreshTicks(prevState => prevState + 1), [setRefreshTicks]);

    const [initialSeconds] = useState<number>(() => {
        const diff = moment.duration(moment().diff(expiryDate));
        return 0 - Math.floor(diff.minutes() * 60) + Math.floor(diff.seconds());
    });

    // Thresholds for changing the color of the ring
    const thresholds = useMemo(() => {
            return {
                warning: ((initialSeconds) * 0.3), // 30% remaining
                danger: ((initialSeconds) * 0.1), // 10% remaining
            };
    }, [initialSeconds]);

    // Colors for the ring
    const colorCodes = useMemo(() => {
        return {
            info: {
                color: 'green',
            },
            warning: {
                color: 'orange',
                threshold: thresholds?.warning,
            },
            danger: {
                color: 'red',
                threshold: thresholds?.danger,
            }

        };
    }, [thresholds]);

    // Path color
    const remainingPathColor = useMemo(() => {
        const totalSeconds = minutes * 60 + seconds;

        if (totalSeconds <= (thresholds?.danger || 0)) {
            return colorCodes.danger.color;
        } else if (totalSeconds <= (thresholds?.warning || 0)) {
            return colorCodes.warning.color;
        } else {
            return colorCodes.info.color;
        }
    }, [minutes, seconds, thresholds, colorCodes]);

    // Create the timer
    useEffect(() => {
        const timer = setInterval(() => {
            const now = moment();

            // If we have expired, clear the timer and raise the onExpired event.
            if (expiryDate <= now) {
                clearInterval(timer);
                onExpired?.();
            }

            // Otherwise, just force a re-render.
            forceRender();
        }, 1000);

        return () => clearInterval(timer);
    }, [onExpired, expiryDate, forceRender]);

    // Render the UI
    return (
        <div className="timer">
            <svg className="timer-svg" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
                <g className="timer-circle">
                    <circle className="timer-circle-path-elapsed" cx="50" cy="50" r="45"></circle>
                    <path
                        stroke-dasharray="283"
                        className={`timer-circle-path-remaining timer-circle-path-remaining-${remainingPathColor}`}
                        d="M 50, 50 m -45, 0 a 45,45 0 1,0 90,0 a 45,45 0 1,0 -90,0"
                    ></path>
                </g>
            </svg>
            <span className="timer-label">
                <ConditionalFragment showIf={minutes <= 0 && seconds <= 0}>
                    00:00
                </ConditionalFragment>

                <ConditionalFragment showIf={minutes >= 0 && seconds > 0}>
                    <p>
                        {minutes < 10 ? `0${minutes}` : minutes}:{seconds < 10 ? `0${seconds}` : seconds}
                    </p>
                </ConditionalFragment>
            </span>
        </div>
    );
};