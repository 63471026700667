
import { useEffect, useMemo, useState } from 'react';
import moment from 'moment';

/**
 * Component used for the calendar feature on StaffClasses
 * Sets the current date on load
 * Can return the next or previous day when passed in current date the user 
 * is viewing. Also, sets 14 days +/- from initial date.
 */
export const useDate = () => {
    const [chosenDay, setChosenDay] = useState<string>();
    const [minDate, setMinDate] = useState<string>();
    const [maxDate, setMaxDate] = useState<string>();

    // Traverse dates
    const calculcateNextDay = useMemo(() => {
        return (today: string) => {
            return moment(today).add(1, 'day').toISOString();
        };
    }, []);

    const calculcatePreviousDay = useMemo(() => {
        return (today: string) => {
            return moment(today).subtract(1, 'day').toISOString();
        };
    }, []);

    // Calculate initial values and min/max dates
    const today = useMemo(() => moment().startOf('day').toISOString(), []);
    const min = useMemo(() => moment().subtract(14, 'days').toISOString(), []);
    const max = useMemo(() => moment().add(14, 'days').toISOString(), []);

    useEffect(() => {
        setChosenDay(today);
        setMinDate(min);
        setMaxDate(max);
    }, [today, min, max]);

    return { chosenDay, minDate, maxDate, calculcateNextDay, calculcatePreviousDay };
};
