import { useTranslation } from 'react-i18next';
import { MainContainer } from '../../shared/mainContainer/MainContainer';
import { Banner } from '../../shared/banner/Banner';
import { useNavigate, useParams } from 'react-router-dom';
import { AlertOnErrors } from '../../../shared/alertOnErrors';
import { ConditionalFragment } from 'react-conditionalfragment';
import { LoadingIndicator } from '../../shared/loadingIndicator/LoadingIndicator';
import { Alert, Button, Col, Row } from 'reactstrap';
import { StickyToolbar } from '../../shared/stickyToolbar/StickyToolbar';
import { PaymentCheckoutDetails } from '../checkout/PaymentCheckoutDetails';
import { PaymentState } from '../../../api/main/models/constants/PaymentState';
import moment from 'moment';
import { usePaymentReceiptViewModel } from '../../../api/main/payments/viewModel/usePaymentReceiptViewModel';
import { ButtonAsync } from 'reactstrap-buttonasync';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { generatePdfAsBlob } from '../../../utilities/generatePdfAsBlob';
import { PaymentCheckoutReceiptPDF } from '../checkout/PaymentCheckoutReceiptPDF';
import { useMember } from '../../../api/main/members/useMember';
import { useCallback } from 'react';
import { useProfile } from '../../../api/main/profiles/useProfile';
import { useAsyncCallback } from 'react-use-async-callback';
import FileSaver from 'file-saver';

/**
 * Receipt for a payment that is visible by administrators.
 * @returns
 */
export const PaymentReceipt = () => {
    const { id } = useParams<{ id: string | undefined; }>();
    const navigate = useNavigate();
    const { t } = useTranslation();

    // Load the payment.
    const { data: modelData, isLoading: _isLoading, errors: loadErrors, } = usePaymentReceiptViewModel(id);
    const model = modelData?.model;

    const {
        data: {
            model: member
        }, isLoading: isLoadingMember
    } = useProfile(model?.memberId)
    const isLoading = _isLoading || isLoadingMember;

    // Generate a PDF as a blob.
    const generatePdf = useCallback(async (): Promise<Blob | undefined> => {
        if (!member) return;

        let blob = await generatePdfAsBlob(PaymentCheckoutReceiptPDF({
            model: model,
            member: member,
            termStartDate: t('common.dateLong', '{{date, Do MMMM YYYY}}', { date: moment(model?.term?.startDate) }),
            termEndDate: t('common.dateLong', '{{date, Do MMMM YYYY}}', { date: moment(model?.term?.endDate) }),
            giftVouchers: model?.giftVouchers,
        }));

        return blob;
    }, [model, member, t]);

    // Generate the PDF and open it in a new window for printing.
    const [generateAndOpenPdf, { isExecuting: isGeneratingPdfForOpen }] = useAsyncCallback(async () => {
        let blob = await generatePdf();

        if (!blob) return;

        let url = URL.createObjectURL(blob);
        window.open(url);
    }, [generatePdf]);

    // Generate the PDF and download it.
    const [generateAndDownloadPdf, { isExecuting: isGeneratingPdfForDownload }] = useAsyncCallback(async () => {
        let blob = await generatePdf();

        if (!blob) return;

        FileSaver.saveAs(blob, `Happy Cubs ${(model && model?.term?.name) || ''} receipt.pdf`);
    }, [generatePdf, model]);

    // UI
    return (
        <>
            <Banner>
                <StickyToolbar>
                    <Row>
                        <Col>
                            <h1>
                                {
                                    !model? ''
                                        : model?.paymentState === PaymentState.Paid ? t('paymentCheckoutReceipt.title.paid', 'Receipt')
                                        : t('paymentCheckoutReceipt.title.outstanding', 'Outstanding payment')
                                }
                            </h1>
                            <h3>{model?.name}</h3>
                        </Col>
                        <ConditionalFragment showIf={isLoading}>
                            <Col xs="auto">
                                <LoadingIndicator />
                            </Col>
                        </ConditionalFragment>
                    </Row>
                </StickyToolbar>
            </Banner>

            <MainContainer>
                <AlertOnErrors errors={[loadErrors]} />

                <PaymentCheckoutDetails data={modelData} />

                {
                    !model? null
                    : model?.paymentState === PaymentState.Paid ? (
                        <Alert color="success">
                            {t('paymentReceipt.paid.alert.text', 'This was paid on {{date, DD/MM/YYYY}}.', { date: moment(model?.paidDate) })}
                        </Alert>
                    ) : (
                        <Alert color="danger">
                            {t('paymentReceipt.outstanding.alert.text', 'This payment is still outstanding.')}
                        </Alert>
                    )
                }

                <Row>
                    <Col></Col>
                    <Col xs="auto">
                        <ButtonAsync color="primary" outline onClick={generateAndOpenPdf} isExecuting={isGeneratingPdfForOpen}
                            executingChildren={<><FontAwesomeIcon icon="spinner" spin /> {t('common.generatingPdf', 'Generating PDF...')}</>}>
                            <FontAwesomeIcon icon="print" /> {t('common.print', 'Print')}
                        </ButtonAsync>

                        <> </>

                        <ButtonAsync color="primary" outline onClick={generateAndDownloadPdf} isExecuting={isGeneratingPdfForDownload}
                            executingChildren={<><FontAwesomeIcon icon="spinner" spin /> {t('common.generatingPdf', 'Generating PDF...')}</>}>
                            <FontAwesomeIcon icon="file-download" /> {t('common.downloadPdf', 'Download PDF')}
                        </ButtonAsync>

                        <> </>

                        <Button color="primary" onClick={() => navigate(-1)}>
                            {t('common.back', 'Back')}
                        </Button>
                    </Col>
                </Row>
            </MainContainer>
        </>
    );
};