import moment from "moment";
import { useMemo } from "react";
import { ConditionalFragment } from "react-conditionalfragment";
import { Input } from "reactstrap";
import { useNextTerm } from "../../../api/main/terms/useNextTerm";
import { useTerms } from "../../../api/main/terms/useTerms";
import { LoadingIndicator } from "../loadingIndicator/LoadingIndicator";

export interface TermSelectorProps {
    selectedTermId: string | undefined | null,
    setFilterState: ({ termId }: { termId: string; }) => void,
    restrictToTermsWithClasses?: boolean,
    showPreviousTerms?: boolean,
    restrictToNextTerm?: boolean,
}

/**
 * TermSelector - A component that allows the user to select a term.
 * @param props
 * @returns
 */
export const TermSelector = (props: TermSelectorProps) => {
    const {
        selectedTermId,
        setFilterState,
        restrictToTermsWithClasses = false,
        showPreviousTerms = false,
        restrictToNextTerm = false
    } = props;

    // Load the data
    const {
        data: {
            items
        }, isLoading: _isLoading
    } = useTerms();

    // Load the next term
    const {
        data: {
            model: nextTerm
        }, isLoading: isLoadingNextTerm
    } = useNextTerm();
    const isLoading = _isLoading || isLoadingNextTerm;

    // Filter the Terms
    const terms = useMemo(() => {
        let ret = (items ?? []);

        if (restrictToTermsWithClasses) {
            ret = ret.filter(term => term.copiedClassesOnDate);
        }

        if (!showPreviousTerms) {
            ret = ret.filter(term => moment(term.endDate) >= moment());
        }

        if (restrictToNextTerm) {
            ret = ret.filter(term => term.id === nextTerm?.id);
        }

        return ret;
    }, [items, restrictToTermsWithClasses, showPreviousTerms, restrictToNextTerm, nextTerm]);

    // Render the UI
    //
    return (
        <>
            <ConditionalFragment showIf={!!isLoading}>
                <LoadingIndicator />
            </ConditionalFragment>

            <Input type="select" value={selectedTermId ?? ''} onChange={e => setFilterState({ termId: e.currentTarget.value })}>
                {terms?.map(term => (
                    <option value={term.id}>{term.name}</option>
                ))}
            </Input>
        </>
    );
};