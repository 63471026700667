import { useMutation } from "@apollo/react-hooks";
import { useAsyncCallback } from 'react-use-async-callback';
import gql from "graphql-tag";
import { AsyncActionStatus } from '../../../shared/abstractStore';
import { CreateGenderMutation, CreateGenderMutationVariables, GenderCreateInput } from '../generated/graphql';
import { genderFieldsFragment } from '../models/Gender';

/**
 * Get a callback to create a Gender in the api.
 */
export function useCreateGenderMutation(): [(model: GenderCreateInput) => Promise<void>, AsyncActionStatus] {
    const [mutationAction, { error }] = useMutation<CreateGenderMutation, CreateGenderMutationVariables>(
        gql`
            mutation createGenderMutation ($model: GenderCreateInput!) {
                createGender(model: $model) {
                    ...genderFields
                }
            }

            ${genderFieldsFragment}
        `
    );

    // Wrap the mutation in a standard callback.
    const [action, { isExecuting, errors: actionErrors }] = useAsyncCallback(async (model: GenderCreateInput) => {
        const result = await mutationAction({ variables: { model: model } });
        if (result.errors && result.errors.length) {
            throw result.errors;
        }
    }, [mutationAction]);

    return [action, { isExecuting: isExecuting, errors: error ? error.message : actionErrors }];
}
