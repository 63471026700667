import * as React from 'react';
import { useSaveInStore, SaveInStoreOptions } from '../../../shared/useSaveInStore';
import { useUpdateMemberMutation } from './useUpdateMemberMutation';
import { Member } from '../models/Member';
import { useCreateMemberMutation } from './useCreateMemberMutation';
import { MemberCreateInput, MemberUpdateInput } from '../generated/graphql';

/**
 * Get a callback to save a Member in the api using either a create or update.
 */
export function useSaveMemberMutation(options: SaveInStoreOptions<Member, string> = {}) {
    const [_create, createStatus] = useCreateMemberMutation();
    const create = React.useCallback((model: Partial<Member>) => _create(model as MemberCreateInput), [_create]);

    const [_update, updateStatus] = useUpdateMemberMutation();
    const update = React.useCallback((id: string, changes: Partial<Member>) => _update(id, changes as MemberUpdateInput), [_update]);

    return useSaveInStore(
        [create, createStatus],
        [update, updateStatus],
        options
    );
}
