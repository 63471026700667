import * as React from 'react';
import { useSaveInStore, SaveInStoreOptions } from '../../../shared/useSaveInStore';
import { useUpdateCancellationReasonMutation } from './useUpdateCancellationReasonMutation';
import { CancellationReason } from '../models/CancellationReason';
import { useCreateCancellationReasonMutation } from './useCreateCancellationReasonMutation';
import { CancellationReasonCreateInput, CancellationReasonUpdateInput } from '../generated/graphql';

/**
 * Get a callback to save a CancellationReason in the api using either a create or update.
 */
export function useSaveCancellationReasonMutation(options: SaveInStoreOptions<CancellationReason, string> = {}) {
    const [_create, createStatus] = useCreateCancellationReasonMutation();
    const create = React.useCallback((model: Partial<CancellationReason>) => _create(model as CancellationReasonCreateInput), [_create]);

    const [_update, updateStatus] = useUpdateCancellationReasonMutation();
    const update = React.useCallback((id: string, changes: Partial<CancellationReason>) => _update(id, changes as CancellationReasonUpdateInput), [_update]);

    return useSaveInStore(
        [create, createStatus],
        [update, updateStatus],
        options
    );
}
