import { Card, CardProps } from "reactstrap";
import "./accountCard.scss";


/**
 * Custom styled Card component used by the account pages such as Login, Register, ForgotPassword etc.
 * This exists only for styling purposes, it does not provide any special functionality.
 * @param props
 */
export const AccountCard = (props: CardProps) => {
    const {
        className,
        ...rest
    } = props;

    return (<Card className={`account-card ${className ?? ''}`} {...rest} />);
};