import { useLazyQuery } from "@apollo/client";
import gql from "graphql-tag";
import { useCallback, useEffect, useMemo } from "react";
import { mainApiConfig } from "../../../../configure/mainApiConfig";
import { AsyncLoadOptions, AsyncLoadResult } from "../../../../shared/abstractStore";
import { StagesInformationStaticViewModelQuery, StagesInformationStaticViewModelQueryVariables } from "../../generated/graphql";
import { classTypeFieldsFragment } from "../../models/ClassType";

export function useStagesInformationStaticViewModel(options: AsyncLoadOptions = {}): AsyncLoadResult<StagesInformationStaticViewModelQuery> {
    // Query data we need from the api.
    const [load, { data, loading, error, refetch }] = useLazyQuery<StagesInformationStaticViewModelQuery, StagesInformationStaticViewModelQueryVariables>(
        gql`
        query stagesInformationStaticViewModelQuery {
            classTypes :classTypesForAnonymousUser {
                ...classTypeFields
            }
        }

        ${classTypeFieldsFragment}
        `,
        {
            fetchPolicy: options.fetchPolicy ?? mainApiConfig.defaultFetchPolicy,
            pollInterval: options.pollInterval,
            onCompleted: (data) => {
                if (options.onCompleted) {
                    options.onCompleted(data);
                }
            }
        }
    );

    // Run the query (unless we are being lazy).
    useEffect(() => {
        if (!options.lazy) {
            load();
        }
    }, [options.lazy, load]);

    // Return the results.
    const refresh = useCallback(async () => { !refetch ? await load() : await refetch(); }, [refetch, load]);
    return useMemo(() => ({
        data: data ?? ({} as any),
        refresh: refresh,
        isLoading: loading,
        errors: error,
    }), [data, refresh, loading, error]);
};