import gql from 'graphql-tag';
import { Guid } from 'guid-string';
import { ScheduledClassChildEventFieldsFragment } from '../generated/graphql';

export const scheduledClassChildEventFieldsFragment = gql`
    fragment scheduledClassChildEventFields on ScheduledClassChildEvent {
        id
        eventDate
        eventType
        childId
        memberId
        scheduledClassId
        oldScheduledClassId
        userId
        termId
        isWaitlist
    }
`;

export type ScheduledClassChildEvent = Omit<ScheduledClassChildEventFieldsFragment, '__typename'>;

/**
 * Default values.
 */
export const scheduledClassChildEventDefaultValues = (): Partial<ScheduledClassChildEvent> => ({
    id: Guid.newGuid(),
    eventDate: new Date(),
    eventType: '',
    childId: undefined,
    memberId: undefined,
    scheduledClassId: undefined,
    oldScheduledClassId: undefined,
    userId: undefined,
    termId: undefined,
    isWaitlist: false,
});