import { ConditionalFragment } from "react-conditionalfragment";
import { useTranslation } from "react-i18next";
import { Col, ListGroup, Row, Spinner } from "reactstrap";
import { AlertOnErrors } from "../../shared/alertOnErrors";
import { Banner } from "../shared/banner/Banner";
import { LoadingIndicator } from "../shared/loadingIndicator/LoadingIndicator";
import { MainContainer } from "../shared/mainContainer/MainContainer";
import { StickyToolbar } from "../shared/stickyToolbar/StickyToolbar";
import { useEffect } from "react";
import { useBackgroundTasks } from "../../api/main/backgroundTasks/useBackgroundTasks";
import { BackgroundTaskComponent } from "./BackgroundTaskComponent";
import { useInitializeStandardTasksTaskMutation } from "../../api/main/backgroundTasks/useInitializeStandardTasksTaskMutation";
import { useAsyncCallback } from "react-use-async-callback";
import { ButtonAsync } from "reactstrap-buttonasync";

/**
 * Component that lets an administrator see what background tasks are scheduled.
 */
export const ScheduledBackgroundTasks = () => {
    const { t } = useTranslation();

    const {
        data: { items, },
        isLoading,
        errors: loadErrors,
        refresh,
    } = useBackgroundTasks();

    // While we're on this screen, we want to refresh the task status on a short interval as tasks are continually being processed.
    useEffect(() => {
        // Start an interval timer.
        const timer = setInterval(() => {
            refresh();
        }, 10 * 1000);

        // Cleanup function.
        return () => clearInterval(timer);
    }, [refresh]);

    const [initializeStandardTasksMutation, { errors: initializeStandardTasksMutationErrors }] = useInitializeStandardTasksTaskMutation();
    const [initializeStandardTasks, { isExecuting: isInitializingStandardTasks, errors: initializeStandardTasksErrors }] = useAsyncCallback(async () => {
        await initializeStandardTasksMutation();
        refresh();
    }, [initializeStandardTasksMutation, refresh]);


    // Render the UI
    //
    return (
        <>
            <Banner>
                <StickyToolbar>
                    <Row>
                        <Col>
                            <h1>
                                {t('scheduledBackgroundTasks.title', 'Scheduled automation')}
                            </h1>
                        </Col>
                        <ConditionalFragment showIf={isLoading}>
                            <Col xs="auto">
                                <LoadingIndicator size="sm" />
                            </Col>
                        </ConditionalFragment>
                        <Col xs="auto">
                            <ButtonAsync color="secondary" outline
                                isExecuting={isInitializingStandardTasks} executingChildren={<><Spinner size="sm" /><> </>{t('common.scheduling', 'Scheduling...')}</>}
                                onClick={() => initializeStandardTasks()}>
                                {t('scheduledBackgroundTasks.runStandardTasks', 'Schedule all standard tasks')}
                            </ButtonAsync>
                        </Col>
                    </Row>
                </StickyToolbar>
            </Banner>

            <MainContainer>
                <AlertOnErrors
                    errors={[
                        loadErrors,
                        initializeStandardTasksErrors, initializeStandardTasksMutationErrors,
                    ]}
                />

                <ListGroup>
                    {
                        items?.map(item => (
                            <BackgroundTaskComponent key={item.id} model={item} refresh={refresh} />
                        ))
                    }
                </ListGroup>
            </MainContainer>
        </>
    );
};