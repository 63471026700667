import moment from "moment";
import { ConditionalFragment } from "react-conditionalfragment";
import { useTranslation } from "react-i18next";
import { Card, CardBody, Col, Row } from "reactstrap";
import { StaffAbsence } from "../../../api/main/models/StaffAbsence";
import { StaffAbsenceType } from "../../../api/main/models/StaffAbsenceType";
import { HappyIcon } from "../../shared/Utilities/HappyIcon";
import { useStaffAbsenceStatusColorAndMessage } from "./useStaffAbsenceStatusColorAndMessage";
import { useStaffAbsenceTypeForIcons } from "./useStaffAbsenceTypeForIcons";

export interface HistoricalStaffAbsenceCardProps {
    model: StaffAbsence | null | undefined,
    staffAbsenceTypes: Array<StaffAbsenceType> | null | undefined,
}

/**
 * CardComponent for Historical StaffAbsences, ones which the dates have passed.
 */
export const HistoricalStaffAbsenceCard = (props: HistoricalStaffAbsenceCardProps) => {
    const { model, staffAbsenceTypes } = props;
    const { t } = useTranslation();

    // Get the color coding and message for better UI
    const { color, statusMessage } = useStaffAbsenceStatusColorAndMessage(model);

    // Get the icon to display along with the absenceType
    const absenceTypeName = staffAbsenceTypes?.find(it => it.id === model?.staffAbsenceTypeId)?.name;
    const icon = useStaffAbsenceTypeForIcons(absenceTypeName ?? '');

    // Render the UI
    //
    return (
        <Card color={color} outline style={{ marginBottom: '10px' }} key={model?.id}>
            <CardBody>
                <Row>
                    <Col>
                        <h4>
                            {t('common.date', '{{date, DD/MM/YYYY}}', { date: moment(model?.startDate) })}

                            <span style={{ fontSize: '14px' }}> {t('costaffTimeOffTabmmon.untilDate', 'until {{date, DD/MM/YYYY}}', { date: moment(model?.endDate) })}</span>
                        </h4>
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <ConditionalFragment showIf={!!icon}>
                            {icon}
                        </ConditionalFragment>
                        <> </>

                        {t('staffTimeOffTab.historicalStaffAbsenceCard.absenceType.value', '{{absenceTypeName}}', { absenceTypeName: absenceTypeName })}
                    </Col>

                    <Col xs="auto">
                        <HappyIcon icon="info" />
                        <> </>
                        {t('staffTimeOffTab.historicalStaffAbsenceCard.status.title', 'Status: ')}
                        <span className={`text-${color}`}>
                            {t('staffTimeOffTab.historicalStaffAbsenceCard.status.message', '{{status}}', { status: statusMessage })}
                        </span> 
                    </Col>
                </Row>
            </CardBody>
        </Card>
    );
};