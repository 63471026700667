import { ValidationErrors } from "pojo-validator";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Button, FormGroup, Label, ListGroup } from "reactstrap";
import { ClassStage } from "../../../../api/main/models/ClassStage";
import { WelcomePackDocument, welcomePackDocumentDefaultValues } from "../../../../api/main/models/WelcomePackDocument";
import { ModelArrayChanges } from "../../../../shared/useChanges";
import { ClassStageWelcomePackDocumentComponent } from "./ClassStageWelcomePackDocumentComponent";

export interface ClassStageWelcomePackDocumentsTabProps {
    model: ClassStage | null | undefined,

    welcomePackDocumentsManager: ModelArrayChanges<WelcomePackDocument, string>,
    validateWelcomePackDocument: (model: WelcomePackDocument) => boolean,
    welcomePackDocumentValidationErrors: (id: string) => ValidationErrors,
}

/**
 * WelcomePackDocumentsTab for ClassStages
 */
export const ClassStageWelcomePackDocumentsTab = (props: ClassStageWelcomePackDocumentsTabProps) => {
    const { model, welcomePackDocumentsManager, validateWelcomePackDocument, welcomePackDocumentValidationErrors } = props;
    const { t } = useTranslation();

    // Add a new ClassSubStage and link it to the parent model
    const addWelcomeDocument = useCallback(() => {
        welcomePackDocumentsManager.addFor({ ...welcomePackDocumentDefaultValues(), classStageId: model?.id });
    }, [welcomePackDocumentsManager, model]);

    // Render the UI
    //
    return (
        <>
            <FormGroup>
                <Label htmlFor="welcomePackDocuments">
                    {t('editClassStage.welcomePackDocumentsTab.headingLabel', 'Welcome documents for class types:')}
                </Label>

                <ListGroup id="welcomePackDocuments">
                    {
                        welcomePackDocumentsManager.model.map(item => (
                            <ClassStageWelcomePackDocumentComponent key={item.id}
                                model={item}
                                change={changes => welcomePackDocumentsManager.changeFor(item.id, changes)}
                                remove={() => welcomePackDocumentsManager.removeFor(item.id)}
                                validate={() => validateWelcomePackDocument(item)}
                                validationErrors={welcomePackDocumentValidationErrors(item.id)}
                            />
                        ))
                    }
                </ListGroup>

                <div className="mt-2">
                    <Button color="primary" outline onClick={() => addWelcomeDocument()}>
                        {t('editClassStage.welcomePackDocumentsTab.add', 'Add welcome pack document')}
                    </Button>
                </div>
            </FormGroup>
        </>
    );
};