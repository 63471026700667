import * as React from 'react';
import { useLazyQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { AsyncLoadResult, AsyncLoadOptions } from '../../../../shared/abstractStore';
import { mainApiConfig } from '../../../../configure/mainApiConfig';
import { EditClassLocationSupportingDataQuery } from '../../generated/graphql';
import { classTypeFieldsFragment } from '../../models/ClassType';
import { profileFieldsFragment } from '../../models/Profile';
import { amenityTypeFieldsFragment } from '../../models/AmenityType';

/**
 * Get a the supporting data needed for dropdowns etc. for the EditClassLocation component.
 * @param id
 * @param options
 */
export function useEditClassLocationSupportingData(options: AsyncLoadOptions = {}): AsyncLoadResult<EditClassLocationSupportingDataQuery> {
    // Query the data we need form the api.
    const [load, { data, loading, error, refetch }] = useLazyQuery<EditClassLocationSupportingDataQuery>(
        gql`
        query editClassLocationSupportingDataQuery {
            classTypes {
                ...classTypeFields
            }

            staffProfiles: profiles (isStaff: true) {
                ...profileFields
            }

            amenityTypes {
                ...amenityTypeFields
            }
        }

        ${classTypeFieldsFragment}
        ${profileFieldsFragment}
        ${amenityTypeFieldsFragment}
        `,
        {
            variables: {
            },
            fetchPolicy: options.fetchPolicy ?? mainApiConfig.defaultFetchPolicy,
            pollInterval: options.pollInterval,
            onCompleted: (data) => {
                if (options.onCompleted) {
                    options.onCompleted(data);
                }
            }
        }
    );

    // Run the query (unless we are being lazy).
    React.useEffect(() => {
        if (!options.lazy) {
            load();
        }
    }, [options.lazy, load])

    // Return the results.
    const refresh = React.useCallback(async () => { !refetch ? await load() : await refetch(); }, [refetch, load]);
    return React.useMemo(() => ({
        data: data ?? ({} as any),
        refresh: refresh,
        isLoading: loading,
        errors: error
    }), [data, refresh, loading, error]);
}
