import { useTranslation } from 'react-i18next';
import { Staff } from '../../../../api/main/models/Staff';
import { Form, Label, FormGroup } from 'reactstrap'; 
import { User } from '../../../../api/main/models/User';
import { HtmlEditor } from '../../../../shared/htmlEditor';


export interface CPDRecommendationsTabProps {
    staffModel: Staff | null | undefined,
    changeStaffModel: (staffChanges: Partial<Staff>) => void,

    userModel: User | null | undefined,
    changeUserModel: (changes: Partial<User>) => void,


    saveForm: () => Promise<void>,
    isCreate: boolean | null | undefined
}

/**
 * BioTab for EditStaff
 */
export const CPDRecommendationsTab = (props: CPDRecommendationsTabProps) => {
    const {
        staffModel,
        changeStaffModel,

        saveForm,
    } = props;
    const { t } = useTranslation();



    //Render the UI
    return (

        <Form onSubmit={e => { e.preventDefault(); saveForm(); }}>
            <FormGroup>
                <Label htmlFor="cPDRecommendationsHtml">{t('editStaff.cPDRecommendationsHtml', 'CPD Recommendation')}</Label>
                <HtmlEditor size="xl" value={staffModel?.cPDRecommendationsHtml ?? ''} onChange={text => changeStaffModel({ cPDRecommendationsHtml: text })} />
            </FormGroup>

        </Form>
    );

};