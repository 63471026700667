import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ValidationErrors } from "pojo-validator";
import { ValidateCallback } from "pojo-validator-react";
import { ValidatedInput } from "pojo-validator-reactstrap";
import { useTranslation } from "react-i18next";
import { useAsyncCallback } from "react-use-async-callback";
import { Button, ButtonGroup, Col, FormGroup, Input, InputGroup, Label, ListGroupItem, Row, Spinner } from "reactstrap";
import { ButtonAsync } from "reactstrap-buttonasync";
import { BlobUploadService } from "../../../../api/main/blobReferences/BlobUploadService";
import { useBlobReference } from "../../../../api/main/blobReferences/useBlobReference";
import { ECommerceLink } from "../../../../api/main/models/ECommerceLink";
import { AlertOnErrors } from "../../../../shared/alertOnErrors";
import { HtmlEditor } from "../../../../shared/htmlEditor";
import { getFullUrl } from "../../../../utilities/getFullUrl";
import { FileUploadButton } from "../../../shared/fileUploadButton/FileUploadButton";
import { UploadedImagePreview } from "../../../shared/uploadedImagePreview/UploadedImagePreview";

export interface ClassStageECommerceLinkComponentProps {
    model: ECommerceLink | undefined,
    change: (changes: Partial<ECommerceLink>) => void;
    remove: () => void,

    validate: ValidateCallback,
    validationErrors: ValidationErrors;
}

/**
 * Component under ClassStage that lets the user edit an ECommerceLink
 */
export const ClassStageECommerceLinkComponent = (props: ClassStageECommerceLinkComponentProps) => {
    const {
        model,
        change,
        remove,
        validate,
        validationErrors
    } = props;
    const { t } = useTranslation();

    // Contents file uploading
    const { data: { model: contentsFile }, errors: contentsFileLoadErrors } = useBlobReference(model?.photoBlobReferenceId);
    const [onUploadContentsFile, { errors: contentsFileUploadErrors, isExecuting: isUploadingContentsFile, }] = useAsyncCallback(async (files: FileList | null) => {
        if (!files) {
            return;
        }

        let uploadService: BlobUploadService = new BlobUploadService("/api/blobs");
        let result = await uploadService.upload(files);

        if (!!result) {
            change({ photoBlobReferenceId: result.id });
        }
    }, [change]);
    const [clearContentsFile, { isExecuting: isClearingContentsFile, }] = useAsyncCallback(async () => {
        change({ photoBlobReferenceId: null });
    }, [change]);

    // Handle validating the URL
    const openUrl = (url: string | undefined) => {
        const fullUrl = getFullUrl(url);

        // If we don't have a URL to work with do nothing.
        if (!fullUrl) { return; }

        // Open in a new window.
        window.open(fullUrl);
    }

    // Render the UI
    //
    return (
        <>
            <AlertOnErrors errors={[
                contentsFileLoadErrors,
                contentsFileUploadErrors
            ]} />

            <ListGroupItem>
                <Row>
                    <Col>
                        <FormGroup>
                            <Label htmlFor="name">{t('editClassStage.eCommerceLinkComponent.name.label', 'Name')}</Label>
                            <ValidatedInput
                                name="name"
                                type="text"
                                value={model?.name ?? ''}
                                onChange={e => change({ name: e.currentTarget.value })}
                                onBlur={e => validate('name')}
                                validationErrors={validationErrors['name']}
                            />
                        </FormGroup>
                    </Col>

                    <Col>
                        <FormGroup>
                            <Label htmlFor="url">{t('editClassStage.eCommerceLinkComponent.url.label', 'Link to product')}</Label>
                            <InputGroup>
                                <Input name="url" type="text" value={model?.url} onChange={e => change({ url: e.currentTarget.value })} />
                                <Button onClick={() => openUrl(model?.url)}>
                                    <FontAwesomeIcon icon={'link'} />
                                </Button>
                            </InputGroup>
                        </FormGroup>
                    </Col>

                    <Col>
                        <FormGroup>
                            <Label htmlFor="displayOrder">{t('editClassStage.eCommerceLinkComponent.displayOrder.label', 'Order')}</Label>
                            <ValidatedInput name="displayOrder" type="number" value={model?.displayOrder ?? ''} onChange={e => change({ displayOrder: e.currentTarget.valueAsNumber })} onBlur={e => validate('displayOrder')} validationErrors={validationErrors['displayOrder']} />
                        </FormGroup>
                    </Col>
                </Row>

                <Row>
                    <FormGroup>
                        <Label htmlFor="description">{t('editClassStage.eCommerceLinkComponent.description.label', 'Description')}</Label>
                        <HtmlEditor size="sm" value={model?.description ?? ''} onChange={text => change({ description: text })} />
                    </FormGroup>
                </Row>

                <Row>
                    <Col>
                        <FormGroup>
                            <Label htmlFor="fileUpload">{t('editClassStage.eCommerceLinkComponent.productImageUpload.label', 'Upload product image')}</Label>
                            <Row>
                                <Col>
                                    <UploadedImagePreview size="lg" src={contentsFile?.url ?? ''} />
                                    <Row>
                                        <Col>
                                            <ButtonGroup>
                                                <FileUploadButton
                                                    color={`primary`}
                                                    isExecuting={isUploadingContentsFile}
                                                    executingChildren={<><Spinner size="sm" /> {t('common.uploading', 'Uploading...')}</>}
                                                    onUpload={onUploadContentsFile}
                                                    outline={false}>
                                                    {t('editClassStage.uploadButtonText', 'Upload an image')}
                                                </FileUploadButton>
                                                <ButtonAsync color="primary"
                                                    outline
                                                    isExecuting={isClearingContentsFile}
                                                    type="button"
                                                    onClick={clearContentsFile}
                                                    executingChildren={<><Spinner size="sm" /> {t('editClassStage.clearingImage', 'Clearing image...')}</>}>
                                                    {t('editClassStage.clearImageButton', 'Clear image')}
                                                </ButtonAsync>
                                            </ButtonGroup>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </FormGroup>
                    </Col>
                </Row>

                <Row>
                    <Col xs='auto'>
                        <FormGroup>
                            <Label htmlFor="name">&nbsp;</Label>
                            <div>
                                <Button color="danger" outline onClick={() => remove()}>
                                    <FontAwesomeIcon icon="trash-alt" />
                                    <> </>
                                    {t('editClassStage.eCommerceLinkComponent.delete', 'Delete store product link')}
                                </Button>
                            </div>
                        </FormGroup>
                    </Col>
                </Row>
            </ListGroupItem>
        </>
    );
};