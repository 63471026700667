import gql from 'graphql-tag';
import { Guid } from 'guid-string';
import moment from 'moment';
import { ChildAbsenceTypeFieldsFragment } from '../generated/graphql';

export const childAbsenceTypeFieldsFragment = gql`
    fragment childAbsenceTypeFields on ChildAbsenceType {
        id
        name
        displayOrder
        archived
    }
`;

export type ChildAbsenceType = Omit<ChildAbsenceTypeFieldsFragment, '__typename'>;

/**
 * Default values.
 */
export const childAbsenceTypeDefaultValues = (): Partial<ChildAbsenceType> => ({
    id: Guid.newGuid(),
    name: '',
    displayOrder: moment().unix(),
    archived: false,
});