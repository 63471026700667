import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect } from "react";
import { ConditionalFragment } from "react-conditionalfragment";
import { useTranslation } from "react-i18next";
import { useAsyncCallback } from "react-use-async-callback";
import { Alert, Col, Row, Spinner } from "reactstrap";
import { ButtonAsync } from "reactstrap-buttonasync";
import { useStopReenrolmentMutation } from "../../api/main/stopReenrolments/useStopReenrolmentMutation";
import { useCurrentTerm } from "../../api/main/terms/useCurrentTerm";
import { AlertOnErrors } from "../../shared/alertOnErrors";
import { useLocalStorage } from "../../shared/useLocalStorage";
import { ClassScheduleFilterState } from "../scheduledClass/schedule/ClassSchedule";
import { Banner } from "../shared/banner/Banner";
import { LoadingIndicator } from "../shared/loadingIndicator/LoadingIndicator";
import { MainContainer } from "../shared/mainContainer/MainContainer";
import { StickyToolbar } from "../shared/stickyToolbar/StickyToolbar";
import { TermSelector } from "../shared/termSelector/TermSelector";

export const StopReenrolment = () => {
    const { t } = useTranslation();

    // Load the current term
    const {
        data: {
            model: currentTerm
        }, isLoading, errors: loadErrors
    } = useCurrentTerm();

    // Allow filtering (persisted to local storage between sessions).
    const [filterState, setFilterState] = useLocalStorage<ClassScheduleFilterState>('classSchedule.filterState', {});

    // Close reenrolments and send emails to all cubs who missed their place.
    const [stopReenrolments, { errors: stopReenrolmentsErrors }] = useStopReenrolmentMutation();

    const [closeReenrolments, { isExecuting: isStoppingReenrolments, errors: closeReenrolmentsErrors }] = useAsyncCallback(async () => {
        if (!filterState.termId) return;

        await stopReenrolments(filterState.termId);
    }, []);

    // Set the default filter state to the current term.
    useEffect(() => {
        if (!filterState.termId) {
            setFilterState({ termId: currentTerm?.id });
        }
    }, [filterState, currentTerm?.id, setFilterState]);

    return (
        <>
            <Banner fluid>
                <StickyToolbar>
                    <Row>
                        <Col>
                            <h1>
                                {t('stopReenrolment.title', 'Close reenrolment')}
                            </h1>
                        </Col>
                        <ConditionalFragment showIf={isLoading}>
                            <Col xs="auto">
                                <LoadingIndicator size="sm" />
                            </Col>
                        </ConditionalFragment>
                    </Row>

                    <Row>
                        <Col>
                            <TermSelector selectedTermId={filterState.termId} setFilterState={({ termId: value }) => setFilterState({ termId: value })} restrictToTermsWithClasses={true} showPreviousTerms={true} />
                        </Col>

                        <Col xs="auto">
                            <ButtonAsync color="primary" isExecuting={isStoppingReenrolments} onClick={() => closeReenrolments()}
                                executingChildren={<><Spinner size="sm" /> {t('common.closing', 'Closing...')}</>}>
                                <FontAwesomeIcon icon="stop" />
                                <> </>
                                {t('common.closeReenrolments', 'Close reenrolments')}
                            </ButtonAsync>
                        </Col>
                    </Row>
                </StickyToolbar>
            </Banner>

            <MainContainer fluid>
                <AlertOnErrors errors={[
                    loadErrors,
                    stopReenrolmentsErrors, closeReenrolmentsErrors
                ]} />

                <Alert color="warning">
                    <p>{t('stopReenrolment.warning.p1', 'Closing reenrolments will remove all cubs from classes and payments, in the selected term, that have not yet paid.')}</p>
                    <p>{t('stopReenrolment.warning.p2', 'It will then email each member to advise that their place have been opened up to the waiting lists.')}</p>
                </Alert>
            </MainContainer>
        </>
    );
};