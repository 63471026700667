import { useTranslation } from "react-i18next";
import { StaffsList } from "./StaffsList";

/**
 * StaffList filtered to just show teachers.
 */
export const TeachersList = () => {
    const { t } = useTranslation();

    return (
        <StaffsList
            title={t('teachersList.title', 'Teachers')}
            filterByStaffType={(item) => !!item.staff?.isTeacher}
            />
    );
};