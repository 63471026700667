import { RouteEntry } from "../../shared/routes";
import { DeleteUser } from "./DeleteUser";
import { CreateUser, EditUser } from "./EditUser";
import { UsersList } from "./UsersList";


export const userRoutes: Array<RouteEntry> = [
    { path: '/administration/users/add', element: <CreateUser />, authorize: true },
    { path: '/administration/users/edit/:id', element: <EditUser />, authorize: true },
    { path: '/administration/users/delete/:id', element: <DeleteUser />, authorize: true },
    { path: '/administration/users', element: <UsersList />, authorize: true },
];
