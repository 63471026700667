import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ValidationErrors } from 'pojo-validator';
import { ValidateCallback } from 'pojo-validator-react';
import { ValidatedInput } from 'pojo-validator-reactstrap';
import { useMemo } from 'react';
import { ConditionalFragment } from 'react-conditionalfragment';
import { useTranslation } from 'react-i18next';
import { Badge, Button, Col, FormGroup, Label, ListGroupItem, Row } from 'reactstrap';
import { ClassLocationRequirementCheck } from '../../../../api/main/models/ClassLocationRequirementCheck';
import { hasDatePassed } from '../../../../utilities/hasDatePassed';
import { ValidatedISODateTimeInput } from '../../../shared/isoDateTimeInput/ValidatedISODateTimeInput';

export interface RequirementCheckComponentProps {
    model: ClassLocationRequirementCheck | undefined,
    change: (changes: Partial<ClassLocationRequirementCheck>) => void;
    remove: () => void,

    validate: ValidateCallback,
    validationErrors: ValidationErrors
}

/**
 * Component under ClassLocations that lets the user edit a RequirementCheck
 */
export const RequirementCheckComponent = (props: RequirementCheckComponentProps) => {
    const { model, change, remove, validate, validationErrors } = props;

    const { t } = useTranslation();

    // Check if the review date is in the past.
    const isOverdue = useMemo(() => hasDatePassed(model?.reviewDate), [model]);

    // Render the UI
    //
    return (
        <ListGroupItem>
            <Row>
                <Col>
                    <FormGroup>
                        <Label htmlFor="name">{t('editClassLocation.classLocationComponent.name.label', 'Name')}</Label>
                        <ValidatedInput name="name" type="text" value={model?.name ?? ''} onChange={e => change({ name: e.currentTarget.value })} onBlur={e => validate('name')} validationErrors={validationErrors['name']} />
                    </FormGroup>
                </Col>
                <Col>
                    <FormGroup>
                        <Label htmlFor="reviewDate">
                            {t('editClassLocation.classLocationComponent.reviewDate.label', 'Next review date')}
                            <ConditionalFragment showIf={isOverdue}>
                                <> </>
                                <Badge color="danger">
                                    {t('editClassLocation.classLocationComponent.reviewDate.overdue', 'Overdue')}
                                </Badge>
                            </ConditionalFragment>
                        </Label>
                        <ValidatedISODateTimeInput name="reviewDate" type="date" value={model?.reviewDate ?? ''} onChange={e => change({ reviewDate: e.currentTarget.value })} onBlur={e => validate('reviewDate')} validationErrors={validationErrors['reviewDate']} />
                    </FormGroup>
                </Col>
                <Col xs="auto">
                    <FormGroup>
                        <Label htmlFor="name">&nbsp;</Label>
                        <div>
                            <Button color="danger" outline onClick={() => remove()}>
                                <FontAwesomeIcon icon="trash-alt" />
                                <> </>
                                {t('editClassLocation.classLocationComponent.button.delete', 'Delete check')}
                            </Button>
                        </div>
                    </FormGroup>
                </Col>
            </Row>
        </ListGroupItem>
    );
};