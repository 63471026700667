import * as React from 'react';
import { useSaveInStore, SaveInStoreOptions } from '../../../shared/useSaveInStore';
import { useUpdateClassLocationClassTypeMutation } from './useUpdateClassLocationClassTypeMutation';
import { ClassLocationClassType } from '../models/ClassLocationClassType';
import { useCreateClassLocationClassTypeMutation } from './useCreateClassLocationClassTypeMutation';
import { ClassLocationClassTypeCreateInput, ClassLocationClassTypeUpdateInput } from '../generated/graphql';

/**
 * Get a callback to save a ClassLocationClassType in the api using either a create or update.
 */
export function useSaveClassLocationClassTypeMutation(options: SaveInStoreOptions<ClassLocationClassType, string> = {}) {
    const [_create, createStatus] = useCreateClassLocationClassTypeMutation();
    const create = React.useCallback((model: Partial<ClassLocationClassType>) => _create(model as ClassLocationClassTypeCreateInput), [_create]);

    const [_update, updateStatus] = useUpdateClassLocationClassTypeMutation();
    const update = React.useCallback((id: string, changes: Partial<ClassLocationClassType>) => _update(id, changes as ClassLocationClassTypeUpdateInput), [_update]);

    return useSaveInStore(
        [create, createStatus],
        [update, updateStatus],
        options
    );
}
