import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ConditionalFragment } from "react-conditionalfragment";
import { useTranslation } from "react-i18next";
import { Button, Col, Row, Table } from "reactstrap";
import { useReportDataSourceServiceForPaymentsViewModel } from "../../../api/main/dashboard/viewModels/useReportDataSourceServiceForPaymentsViewModel";
import { AlertOnErrors } from "../../../shared/alertOnErrors";
import { LoadingIndicator } from "../../shared/loadingIndicator/LoadingIndicator";
import { NoResultsFound } from "../../shared/noResultsFound/NoResultsFound";
import { PaymentsBarChart } from "../charts/PaymentsBarChart";

/**
 * PaymentsTab for Dashboard
 */
export const PaymentsTab = () => {
    const { t } = useTranslation();

    // Load the data
    const {
        data: {
            paymentsData
        }, isLoading, errors: loadErrors
    } = useReportDataSourceServiceForPaymentsViewModel();

    // Render the UI
    //
    return (
        <>
            {/* Alert on errors */}
            <AlertOnErrors
                errors={[loadErrors]}
            />

            {/* Loading indicator */}
            <ConditionalFragment showIf={!!isLoading}>
                <LoadingIndicator />
            </ConditionalFragment>

            {/* Render the Chart and our Table of data */}
            <ConditionalFragment showIf={!isLoading && !!paymentsData}>
                <PaymentsBarChart chartData={paymentsData} />

                {/* Export button */}
                <Row>
                    <Col>
                    </Col>

                    <Col xs="auto">
                        <Button color="link">
                            <a className="btn btn-outline-primary" href={`/api/exportreports/exportpayments?format=${encodeURIComponent('xlsx')}`} download>
                                <FontAwesomeIcon icon="download" />
                                <> </>
                                {t('dashboard.paymentsTab.download', 'Export')}
                            </a>
                        </Button>
                    </Col>
                </Row>

                {/* Table data */}
                <Table responsive striped>
                    <thead>
                        <tr>
                            <th>{t('dashboard.paymentsTab.termName.heading', 'Term')}</th>
                            <th>{t('dashboard.paymentsTab.totalPaid.heading', 'Total paid')}</th>
                            <th>{t('dashboard.paymentsTab.totalUnpaid.heading', 'Total unpaid')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            paymentsData?.map(paymentData => (
                                <tr key={paymentData.termName}>
                                    <td>
                                        {paymentData.termName}
                                    </td>

                                    <td>
                                        {t('common.money.euro', '€{{value, 0.00}}', { value: paymentData?.totalAmountPaid })}
                                    </td>

                                    <td>
                                        {t('common.money.euro', '€{{value, 0.00}}', { value: paymentData?.totalAmountUnpaid })}
                                    </td>
                                </tr>
                            ))
                        }
                    </tbody>
                </Table>
            </ConditionalFragment>

            {/* No results found */}
            <ConditionalFragment showIf={!isLoading && !paymentsData}>
                <NoResultsFound />
            </ConditionalFragment>
        </>
    );
};