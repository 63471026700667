import { gql, useMutation } from "@apollo/client";
import { useAsyncCallback } from "react-use-async-callback";
import { AsyncActionStatus } from "../../../shared/abstractStore";
import { MoveScheduledClassChildMutation, MoveScheduledClassChildMutationVariables } from "../generated/graphql";

export function useMoveScheduledClassChildMutation(): [(scheduledClassChildId: string, newScheduledClassId: string, endOfTerm: boolean) => Promise<{ success: boolean, warningMessage: string, errorMessage: string; }>, AsyncActionStatus]  {
    const [mutationAction, { error }] = useMutation<MoveScheduledClassChildMutation, MoveScheduledClassChildMutationVariables>(
        gql`
            mutation moveScheduledClassChildMutation ($scheduledClassChildId: ID!, $newScheduledClassId: ID!, $endOfTerm: Boolean!) {
                moveScheduledClassChild(scheduledClassChildId: $scheduledClassChildId, newScheduledClassId: $newScheduledClassId, endOfTerm: $endOfTerm) {
                    success
                    warningMessage
                    errorMessage
                }
            }
        `
    );

    // Wrap the mutation in a standard callback.
    const [action, { isExecuting, errors: actionErrors }] = useAsyncCallback(async (scheduledClassChildId: string, newScheduledClassId: string, endOfTerm: boolean) => {
        const result = await mutationAction({ variables: { scheduledClassChildId: scheduledClassChildId, newScheduledClassId: newScheduledClassId, endOfTerm: endOfTerm, } });
        if (result.errors && result.errors.length) {
            throw result.errors;
        }

        return result.data?.moveScheduledClassChild ?? { success: false, errorMessage: 'Failed to move cub.', warningMessage: '' };
    }, [mutationAction]);

    return [action, { isExecuting: isExecuting, errors: error ? error.message : actionErrors }];
}