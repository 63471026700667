import { Guid } from "guid-string";
import i18next from "i18next";
import { TFunction } from "react-i18next";
import { ClassLocation } from "../../../api/main/models/ClassLocation";
import { ClassStage } from "../../../api/main/models/ClassStage";
import { ClassSubStage } from "../../../api/main/models/ClassSubStage";
import { ClassType } from "../../../api/main/models/ClassType";
import { dayOfWeekDisplayName } from "../../../api/main/models/constants/DayOfWeek";
import { Profile } from "../../../api/main/models/Profile";
import { ScheduledClass } from "../../../api/main/models/ScheduledClass";
import { ScheduledClassStaff } from "../../../api/main/models/ScheduledClassStaff";
import { uniqueItemsArray } from "../../../utilities/uniqueItemsArray";
import { uniqueItemsArrayById } from "../../../utilities/uniqueItemsByIdArray";

/**
 * Extended ScheduledClass data structure that we want to receive so we can work with it.
 */
export interface ScheduledClassWithRelationships extends ScheduledClass {
    classType?: ClassType | undefined,
    classStage?: ClassStage | undefined,
    classSubStage?: ClassSubStage | undefined,
    classLocation?: ClassLocation | undefined,

    primaryTeacher?: Partial<Profile> & { scheduledClassStaff: ScheduledClassStaff },
    allStaff?: Array<Partial<Profile> & { scheduledClassStaff: ScheduledClassStaff; }>,
    numberOfSpacesForCurrentTerm?: number | undefined,
}

/**
 * Configuration for one of the dimensions in the view.
 */
export interface ScheduledCalendarViewDimension {
    titleFunction: (t: TFunction) => string,
    groupsFunction: (items: Array<ScheduledClassWithRelationships>) => Array<{ id: string, name: string, }>,
    filterFunction: (filterById: string, item: ScheduledClassWithRelationships) => boolean,
    getIdFunction: (item: ScheduledClassWithRelationships) => string,
}

/**
 * Some default dimension types.
 * NOTE this does not prevent anyone from providing a custom ScheduleCalendarViewDimension but are here
 * for common use.
 */
export const ScheduleCalendarViewDimensions: { [key: string]: ScheduledCalendarViewDimension } = {
    /**
     * Group by ClassType.
     */
    classType: {
        titleFunction: (t) => t('scheduleCalendarViewDimensions.classType.title', 'Activity'),
        groupsFunction: (items) => {
            // Get all the ones used in the data.
            const allItems = items.map(item => item.classType).filter(item => !!item).map(item => item as ClassType);

            // Make a unique list.
            let ret = uniqueItemsArrayById(allItems);

            // Order them as we want them shown.
            ret.sort((a, b) => {
                if (a.displayOrder === b.displayOrder) {
                    if (a.name === b.name) {
                        return 0;
                    } else if (a.name < b.name) {
                        return -1;
                    } else {
                        return 1;
                    }
                } else if (a.displayOrder < b.displayOrder) {
                    return -1;
                } else {
                    return 1;
                }
            });

            return ret;
        },
        filterFunction: (filterById, item) => item.classTypeId === filterById,
        getIdFunction: (item) => item.classTypeId,
    } as ScheduledCalendarViewDimension,

    /**
     * Group by ClassStage.
     */
    classStage: {
        titleFunction: (t) => t('scheduleCalendarViewDimensions.classStage.title', 'Class type'),
        groupsFunction: (items) => {
            // Get all the ones used in the data.
            const allItems = items.map(item => item.classStage).filter(item => !!item).map(item => item as ClassStage);

            // Make a unique list.
            let ret = uniqueItemsArrayById(allItems);

            // Order them as we want them shown.
            ret.sort((a, b) => {
                if (a.displayOrder === b.displayOrder) {
                    if (a.name === b.name) {
                        return 0;
                    } else if (a.name < b.name) {
                        return -1;
                    } else {
                        return 1;
                    }
                } else if (a.displayOrder < b.displayOrder) {
                    return -1;
                } else {
                    return 1;
                }
            });

            return ret;
        },
        filterFunction: (filterById, item) => item.currentClassStageId === filterById,
        getIdFunction: (item) => item.currentClassStageId,
    } as ScheduledCalendarViewDimension,

    /**
     * Group by ClassSubStage.
     */
    classSubStage: {
        titleFunction: (t) => t('scheduleCalendarViewDimensions.classSubStage.title', 'Stage'),
        groupsFunction: (items) => {
            // Get all the ones used in the data.
            const allItems = items.map(item => item.classSubStage).filter(item => !!item).map(item => item as ClassSubStage);

            // Make a unique list.
            let ret = uniqueItemsArrayById(allItems);

            // Order them as we want them shown.
            ret.sort((a, b) => {
                if (a.displayOrder === b.displayOrder) {
                    if (a.name === b.name) {
                        return 0;
                    } else if (a.name < b.name) {
                        return -1;
                    } else {
                        return 1;
                    }
                } else if (a.displayOrder < b.displayOrder) {
                    return -1;
                } else {
                    return 1;
                }
            });

            return ret;
        },
        filterFunction: (filterById, item) => item.currentClassSubStageId === filterById,
        getIdFunction: (item) => item.currentClassSubStageId,
    } as ScheduledCalendarViewDimension,

    /**
     * Group by ClassLocation.
     */
    classLocation: {
        titleFunction: (t) => t('scheduleCalendarViewDimensions.classLocation.title', 'Location'),
        groupsFunction: (items) => {
            // Get all the ones used in the data.
            const allItems = items.map(item => item.classLocation).filter(item => !!item).map(item => item as ClassLocation);

            // Make a unique list.
            let ret = uniqueItemsArrayById(allItems);

            // Order them as we want them shown.
            ret.sort((a, b) => {
                if (a.name === b.name) {
                    return 0;
                } else if (a.name < b.name) {
                    return -1;
                } else {
                    return 1;
                }
            });

            return ret;
        },
        filterFunction: (filterById, item) => item.classLocationId === filterById,
        getIdFunction: (item) => item.classLocationId,
    } as ScheduledCalendarViewDimension,

    /**
     * Group by DayOfWeek
     */
    dayOfWeek: {
        titleFunction: (t) => t('scheduleCalendarViewDimensions.dayOfWeek.title', 'Day'),
        groupsFunction: (items) => {
            // Get all the ones used in the data.
            const allItems = items.map(item => item.dayOfWeek);

            // Make a unique list.
            const t: any = (k: string, m?: string, o?: object) => i18next.t(k, m, o);
            let ret = uniqueItemsArray(allItems).map(dayOfWeek => ({ id: dayOfWeek.toString(), name: dayOfWeekDisplayName(dayOfWeek, t), }));

            // Order them as we want them shown.
            ret.sort((a, b) => {
                // Special handling is here to treat Sunday as a 7 instead of a 0 for the purpose of ordering so it appears on the right of Saturday
                const aValue = a.id === '0' ? '7' : a.id;
                const bValue = b.id === '0' ? '7' : b.id;

                if (aValue === bValue) {
                    return 0;
                } else if (aValue < bValue) {
                    return -1;
                } else {
                    return 1;
                }
            });

            return ret;
        },
        filterFunction: (filterById, item) => item.dayOfWeek.toString() === filterById,
        getIdFunction: (item) => item.dayOfWeek.toString(),
    } as ScheduledCalendarViewDimension,

    /**
     * Group by the hour of the start time.
     */
    startTimeHour: {
        titleFunction: (t) => t('scheduleCalendarViewDimensions.startTimeHour.title', 'Start time'),
        groupsFunction: (items) => {
            // Get all the ones used in the data.
            const allItems = items.map(item => item.startTimeHours);

            // Make a unique list.
            let ret = uniqueItemsArray(allItems)
                .map(hours => ({ id: hours.toString(), name: i18next.t('common.time', '{{hours, 00}}:{{minutes, 00}}', { hours, minutes: 0, }) }));

            // Order them as we want them shown.
            ret.sort((a, b) => {
                if (a.name === b.name) {
                    return 0;
                } else if (a.name < b.name) {
                    return -1;
                } else {
                    return 1;
                }
            });

            return ret;
        },
        filterFunction: (filterById, item) => item.startTimeHours.toString() === filterById,
        getIdFunction: (item) => item.startTimeHours.toString(),
    } as ScheduledCalendarViewDimension,

    primaryTeacher: {
        titleFunction: (t) => t('scheduleCalendarViewDimensions.primaryTeacher.title', 'Primary teacher'),
        groupsFunction: (items) => {
            // Get all the ones used in the data.
            const allItems = items
                .map(item => {
                    const profile = item.primaryTeacher;
                    return ({
                        id: profile?.id ?? Guid.empty,
                        name: profile?.id ? i18next.t('common.fullName', '{{firstName}} {{lastName}}', { firstName: profile?.firstName ?? '', lastName: profile?.lastName ?? '' })
                            : i18next.t('scheduleCalendarViewDimensions.primaryTeacher.noPrimaryTeacher', '(No primary teacher assigned)')
                    });
                });
            
            // Make a unique list.
            let ret = uniqueItemsArrayById(allItems)

            // Order them as we want them shown.
            ret.sort((a, b) => {
                if (a.name === b.name) {
                    return 0;
                } else if (a.name < b.name) {
                    return -1;
                } else {
                    return 1;
                }
            });

            return ret;
        },
        filterFunction: (filterById, item) => (item.primaryTeacher?.id ?? Guid.empty) === filterById,
        getIdFunction: (item) => item.primaryTeacher?.id ?? Guid.empty,
    } as ScheduledCalendarViewDimension,
}