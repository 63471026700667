import { useTranslation } from 'react-i18next';
import { ValidateCallback } from 'pojo-validator-react';
import { ValidatedInput } from 'pojo-validator-reactstrap';
import { ValidationErrors } from 'pojo-validator';
import * as React from 'react';
import { Row, Col, Form, Label, FormGroup, Spinner, FormText } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ButtonAsync } from 'reactstrap-buttonasync';
import { Profile } from '../../../api/main/models/Profile';
import { User } from '../../../api/main/models/User';
import { RoleGroup } from '../../../api/main/models/RoleGroup';
import { ConditionalFragment } from 'react-conditionalfragment';

export interface MainTabProps {
    model: Profile | null | undefined,
    change: (changes: Partial<Profile>) => void,
    storeModel: Profile & { user: User | undefined | null; } | null | undefined,
    validate: ValidateCallback,
    validationErrors: ValidationErrors,
    resendInviteEmail: (email: string) => Promise<boolean>,
    setHasResentInviteEmail: (value: boolean) => void,
    isResendingInviteEmail: boolean,
    genders: Array<{ id: string, name: string, }> | undefined | null,

    userModel: User | null | undefined,
    changeUserModel: (changes: Partial<User>) => void,

    saveForm: () => Promise<void>,
    isCreate: boolean | null | undefined,

    roleGroups: Array<RoleGroup> | undefined | null,
    roleGroupModel: { id: string; } | null | undefined,
    changeRoleGroupModel: (changes: Partial<RoleGroup>) => void;
    currentUserRoles: Array<string> | undefined | null;
}

/**
 * MainTab for EditStaff
 */
export const MainTab = (props: MainTabProps) => {
    const {
        model,
        change,
        storeModel,
        validate,
        validationErrors,
        resendInviteEmail,
        setHasResentInviteEmail,
        isResendingInviteEmail,
        genders,
        userModel,
        changeUserModel,
        saveForm,
        roleGroups,
        roleGroupModel,
        changeRoleGroupModel,
        currentUserRoles
    } = props;
    const { t } = useTranslation();

    //Render the UI
    return (

        <Form onSubmit={e => { e.preventDefault(); saveForm(); }}>
            <h4>{t('editStaff.personalDetails.heading', 'Personal details')}</h4>
            <Row>
                <Col>
                    <FormGroup>
                        <Label htmlFor="firstName">{t('editStaff.firstName', 'First name')}</Label>
                        <ValidatedInput name="firstName" type="text" value={model?.firstName ?? ''} onChange={e => change({ firstName: e.currentTarget.value })} onBlur={e => validate('firstName')} validationErrors={validationErrors['firstName']} />
                    </FormGroup>
                </Col>
                <Col>
                    <FormGroup>
                        <Label htmlFor="lastName">{t('editStaff.lastName', 'Last name')}</Label>
                        <ValidatedInput name="lastName" type="text" value={model?.lastName ?? ''} onChange={e => change({ lastName: e.currentTarget.value })} onBlur={e => validate('lastName')} validationErrors={validationErrors['lastName']} />
                    </FormGroup>
                </Col>
            </Row>
            <FormGroup>
                <Label htmlFor="genderId">{t('editStaff.genderId', 'Gender')}</Label>
                <ValidatedInput name="genders" type="select" value={model?.genderId ?? ''} onChange={e => change({ genderId: e.currentTarget.value })} onBlur={e => validate('genderId')} validationErrors={validationErrors['genderId']}>
                    <option value="">{t('editStaff.genders.pleaseSubmit', '(Please select a gender)')}</option>
                    {
                        genders?.map(item => (
                            <option key={item.id} value={item.id}>{item.name}</option>
                        ))
                    }
                </ValidatedInput>
            </FormGroup>

            <h4>{t('editStaff.contactDetails.heading', 'Contact details')}</h4>
            <Row>
                {/* Phones and emails on the left */}
                <Col>
                    <FormGroup>
                        <Label htmlFor="email">{t('editStaff.mainTab.email', 'Primary email')}</Label>
                        <Row>
                            <Col>
                                <ValidatedInput name="email" type="email" value={model?.primaryEmail ?? ''}
                                    onChange={e => {
                                        changeUserModel({ email: e.currentTarget.value });
                                        change({ primaryEmail: e.currentTarget.value });
                                    }}
                                    onBlur={e => validate('email')} validationErrors={validationErrors['email']} />
                            </Col>
                            {
                                storeModel && !storeModel.user?.emailConfirmed && userModel?.email === storeModel.user?.email ? (
                                    <Col xs="auto">
                                        <ButtonAsync type="button" color="primary" outline onClick={async e => { e.preventDefault(); await resendInviteEmail(userModel?.email ?? ''); setHasResentInviteEmail(true); }}
                                            isExecuting={isResendingInviteEmail}
                                            executingChildren={<><Spinner size="sm" />{t('editStaff.resendingInvite', 'Resending invite...')}</>}>
                                            <FontAwesomeIcon icon="envelope" />
                                            <> {t('editStaff.resendInvite', 'Resend invite')}</>
                                        </ButtonAsync>
                                    </Col>
                                ) : null
                            }
                        </Row>
                        {
                            storeModel && !storeModel.user?.emailConfirmed && userModel?.email === storeModel.user?.email ? (
                                <FormText>
                                    {t('editStaff.userHasNotAcceptedInvite', 'This user has not accepted the invite.  You can change their email if required or resend the invite.')}
                                </FormText>
                            ) : storeModel && !storeModel.user?.emailConfirmed && userModel?.email !== storeModel.user?.email ? (
                                <FormText>
                                    {t('editStaff.userHasNotAcceptedInviteAndEmailChanged', 'This user has not yet accepted the invite, a new invite will be sent to the new email you have supplied.')}
                                </FormText>
                            ) : storeModel && storeModel.user?.emailConfirmed && userModel?.email !== storeModel.user.email ? (
                                <FormText color="warning">
                                    {t('editStaff.userHasAcceptedInviteAndEmailChanged', 'This user will be asked to confirm the email change before it takes affect.  The old email address will be shown in the system until the change has been confirmed.')}
                                </FormText>
                            ) : storeModel && storeModel.user?.emailConfirmed && userModel?.email === storeModel.user.email ? (
                                <FormText>
                                    {t('editStaff.userHasAcceptedInviteAndEmailNotChanged', 'If you change this user\'s email they will be sent an email to confirm the change before it takes affect.')}
                                </FormText>
                            ) : null
                        }
                    </FormGroup>
                    <FormGroup>
                        <Label htmlFor="secondaryEmail">{t('editStaff.seconderyEmail', 'Secondary email')}</Label>
                        <ValidatedInput name="secondaryEmail" type="email" value={model?.secondaryEmail ?? ''} onChange={e => change({ secondaryEmail: e.currentTarget.value })} onBlur={e => validate('secondaryEmail')} validationErrors={validationErrors['secondaryEmail']} />
                    </FormGroup>
                    <FormGroup>
                        <Label htmlFor="primaryPhone">{t('editStaff.primaryPhone', 'Primary phone number')}</Label>
                        <ValidatedInput name="primaryPhone" type="tel" value={model?.primaryPhone ?? ''} onChange={e => change({ primaryPhone: e.currentTarget.value })} onBlur={e => validate('primaryPhone')} validationErrors={validationErrors['primaryPhone']} />
                    </FormGroup>
                    <FormGroup>
                        <Label htmlFor="secondaryPhone">{t('editStaff.secondaryPhone', 'Secondary phone number')}</Label>
                        <ValidatedInput name="secondaryPhone" type="tel" value={model?.secondaryPhone ?? ''} onChange={e => change({ secondaryPhone: e.currentTarget.value })} onBlur={e => validate('secondaryPhone')} validationErrors={validationErrors['secondaryPhone']} />
                    </FormGroup>

                    <ConditionalFragment showIf={!!roleGroups && roleGroups?.length > 1 && !!currentUserRoles?.includes('AdminAndFinance')}>
                        <FormGroup>
                            <Label htmlFor="roleGroup">{t('editUserBase.roleGroup', 'Security group')}</Label>
                            <ValidatedInput name="roleGroup" type="select" value={roleGroupModel?.id ?? ''} onChange={e => changeRoleGroupModel({ id: e.currentTarget.value })} onBlur={e => validate('roleGroup')} validationErrors={validationErrors['roleGroup']}>
                                {
                                    roleGroups?.map(item => (
                                        <option key={item.id} value={item.id}>{item.name}</option>
                                    ))
                                }
                            </ValidatedInput>
                        </FormGroup>
                    </ConditionalFragment>
                </Col>

                {/* Address on the right */}
                <Col xs={12} md="">
                    <FormGroup>
                        <Label htmlFor="addressLine1">{t('editStaff.addressLine1', 'Address line 1')}</Label>
                        <ValidatedInput name="addressLine1" type="text" value={model?.addressLine1 ?? ''} onChange={e => change({ addressLine1: e.currentTarget.value })} onBlur={e => validate('addressLine1')} validationErrors={validationErrors['addressLine1']} />
                    </FormGroup>
                    <FormGroup>
                        <Label htmlFor="addressLine2">{t('editStaff.addressLine2', 'Address line 2')}</Label>
                        <ValidatedInput name="addressLine2" type="text" value={model?.addressLine2 ?? ''} onChange={e => change({ addressLine2: e.currentTarget.value })} onBlur={e => validate('addressLine2')} validationErrors={validationErrors['addressLine2']} />
                    </FormGroup>
                    <FormGroup>
                        <Label htmlFor="city">{t('editStaff.city', 'City')}</Label>
                        <ValidatedInput name="city" type="text" value={model?.city ?? ''} onChange={e => change({ city: e.currentTarget.value })} onBlur={e => validate('city')} validationErrors={validationErrors['city']} />
                    </FormGroup>
                    <FormGroup>
                        <Label htmlFor="county">{t('editStaff.county', 'County')}</Label>
                        <ValidatedInput name="county" type="text" value={model?.county ?? ''} onChange={e => change({ county: e.currentTarget.value })} onBlur={e => validate('county')} validationErrors={validationErrors['county']} />
                    </FormGroup>
                    <FormGroup>
                        <Label htmlFor="postcode">{t('editStaff.postcode', 'Postcode')}</Label>
                        <ValidatedInput name="postcode" type="text" value={model?.postcode ?? ''} onChange={e => change({ postcode: e.currentTarget.value })} onBlur={e => validate('postcode')} validationErrors={validationErrors['postcode']} />
                    </FormGroup>
                    <FormGroup>
                        <Label htmlFor="country">{t('editStaff.country', 'Country')}</Label>
                        <ValidatedInput name="country" type="text" value={model?.country ?? ''} onChange={e => change({ country: e.currentTarget.value })} onBlur={e => validate('country')} validationErrors={validationErrors['country']} />
                    </FormGroup>
                </Col>
            </Row>
        </Form>
    );

};

